import {useWindowDimensions} from "react-native-web";
import {useEffect, useState} from "react";

export const useReportMetricCardDivider = (parentContainerRef, maxCards=6, lineChartData=[]) => {
    let {width: windowWidth} = useWindowDimensions();

    const [maxCardsPerRow, setMaxCardsPerRow] = useState(6);
    const [lineChartWidth, setLineChartWidth] = useState("100%");

    useEffect(() => {
        calculate();

        setTimeout(() => {
            calculate();
        }, 800);

    }, [windowWidth, lineChartData]);

    const getDivisors = (n) => {
        let divisors = [];

        for (let i = 1; i <= n; i++) {
            if (n % i === 0) {
                divisors.push(i);
            }
        }

        return divisors;
    }

    const calculate = () => {
        let {width} = parentContainerRef.current.getBoundingClientRect();

        let divisors = getDivisors(maxCards);
        for (let divisor of divisors) {
            let cardsPerRow = maxCards / divisor;
            let idealLength = 210 * cardsPerRow + 30 * (cardsPerRow - 1);
            if ((width - 60 - 10) >= idealLength) {
                setMaxCardsPerRow(cardsPerRow);
                break;
            }
        }
        if (width < lineChartData.length * 40 + 100 + 450) {
            setLineChartWidth(width - 150);
        } else {
            setLineChartWidth(width - 450);
        }
    }

    return {lineChartWidth, maxCardsPerRow};
}