import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import useMobileAccess from "../../hooks/useMobileAccess";
import styles from "./ManageCoursePolls.module.css";
import CoursePollSearchForm from "./CoursePollSearchForm";
import CoursePollTable from "./CoursePollTable";
import { useLoading } from "../../utils/LoadingContext";

export default function ManageCoursePollsPage() {
  const { courseId } = useParams();
  const [filter, setFilter] = useState({});
  const [pollData, setPollData] = useState([]);
  const [courseMetaData, setCourseMetaData] = useState({});
  const [dataReady, setDataReady] = useState(false);
  const { getPollsFromCourse } = PollApiCalls();
  const { getCourseById } = CourseApiCalls();
  const history = useHistory();
  const {setLoading} = useLoading();

  const getPollInformation = async (courseId) => {
    setDataReady(false);
    setLoading(true);
    const res = await getPollsFromCourse(courseId);
    res.sort(function (a, b) {
      return new Date(Date.parse(b.updatedTimeSK)) - new Date(Date.parse(a.updatedTimeSK));
    });
    setPollData(res);
    setLoading(false);
    setDataReady(true);
  };
  
  const getCourseInfo = async (courseId) => {
    setDataReady(false);
    setLoading(true);
    const result = await getCourseById(courseId);
    setCourseMetaData(result.result);
    setLoading(false);
    setDataReady(true);
  };
  useEffect(() => {
    getPollInformation(courseId);
    getCourseInfo(courseId);
  }, [courseId]);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
  }, []);

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: 'Date', accessor: 'updatedDatePK', width: isMobileScreen ? 100 : 120 },
    { Header: 'Poll Title', accessor: 'pollTitle', width: 250 },
    { Header: 'Poll Status', accessor: 'pollSortKey', width: isMobileScreen ? 120 : 140 },
    { Header: 'Questions', accessor: 'totalQuestions', width: isMobileScreen ? 100 : 120 },
    // { Header: 'Edit', accessor: 'pollKey', width: 100, id: 'edit' },
    // { Header: 'Publish', accessor: 'pollKey', width: 100 }
    { Header: 'Action', accessor: 'pollKey', width: 100, id: 'action' }
  ];

  const clickCreate = () => {
    history.push({ pathname: "/create" });
  };

  return (
    <>
      {pollData && pollData.length !== 0 && dataReady && (
        <>
          <div className={styles.container}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div className={styles.titleBoxTitle}>All Polls - {courseMetaData.courseCode} : {courseMetaData.courseName}</div>
            </div>
            <CoursePollSearchForm handleSubmit={handleSubmit} />
            <CoursePollTable columns={columns} data={pollData} globalFilterObj={filter} getPollInformation={getPollInformation} />
          </div>
        </>
      ) }
      { (!pollData || pollData.length === 0) && dataReady && (
        <>
          <div className={styles.c2}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxTitle}>You have not created any polls.</div>
            </div>
            <div className={styles.text}>Press the button below to create a poll.</div>
            <div className={styles.center}>
              <button
                className={styles.createButton}
                onClick={() => clickCreate()}
              >
                Create Poll
              </button>
            </div>

          </div>
        </>
      )}
    </>
  );
}