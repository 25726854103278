import {useFetchWrapper} from "../hooks/useFetchWrapper";
import {REPORT_API_URL} from "../utils/properties";
import {useLocalStorageNew} from "../hooks/useLocalStorageNew";

export const ReportApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    const [,getSession] = useLocalStorageNew("session", {});

    return {
        getInstitutionWiseReport,
        getStandaloneWiseReport,
        getCourseWiseReport,
        getActivityWiseReport,
        getUserWiseReport,
        getUserStandaloneWiseReport,
    };

    function getInstitutionWiseReport(startMonth, endMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/instReport?startMonth=${startMonth}&endMonth=${endMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }

    function getStandaloneWiseReport(startMonth, endMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/standaloneReport?startMonth=${startMonth}&endMonth=${endMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }

    function getCourseWiseReport(courseId, startMonth, endMonth, isPersonal=false) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/${courseId}/courseReport?startMonth=${startMonth}&endMonth=${endMonth}&isPersonal=${isPersonal}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }


    function getActivityWiseReport(pollKey, curMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/${pollKey}/pollReport?curMonth=${curMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }

    function getUserWiseReport(startMonth, endMonth) {
        let {idpType="", intType=""} = getSession();
        let url = `${REPORT_API_URL}/report/userReport?startMonth=${startMonth}&endMonth=${endMonth}`;
        if (!!idpType && !!intType) {
            url = `${url}&idpType=${idpType}&intType=${intType}`
        }
        return fetchWrapper.get({
            url: url,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }


    function getUserStandaloneWiseReport(startMonth, endMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/userStandaloneReport?startMonth=${startMonth}&endMonth=${endMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }
}