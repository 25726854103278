import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { Spin } from "../../components/standardization/YuJaLoading";
import {
    EXPAND_WINDOW_LABEL,
    MCSSoptUpper,
    MINIMIZE_WINDOW_LABEL,
    POLL_SHARE_MODE,
    POLL_TYPE,
    RESPONSES,
    RESPONSES_HEADER,
    RESPONSES_SUBTITLE_PREFIX,
    TEXTBOX
} from "../../utils/constants";
import TabParticipantsLive from "./TabParticipantsLive";
import { ReactComponent as DropDown } from "./images/DROPDOWN_ICON.svg";
import { ReactComponent as DropUp } from "./images/DROPUP_ICON.svg";
import { ReactComponent as NoResponsesIcon } from "./images/NO_RESPONSES.svg";
import { ReactComponent as ShiftNext } from "./images/SHIFT_Q_NEXT.svg";
import { ReactComponent as ShiftPrev } from "./images/SHIFT_Q_PREV.svg";
import "./styles.css";

export default function ViewResponses({data=[], loading=true, initialized=false, pollData={}, currQues, setCurrQues, localState, collapsed}) {
    // const location = useLocation();
    const [minimized, setMinimized] = useState(true); // !location?.state?.expandedAnalytics
    const [chartData, setChartData] = useState({});
    const gradeEnabled = currQues <= pollData?.questions?.length ? !!pollData?.questions[currQues - 1]?.weightage : false;

    useEffect(() => {
        if (data.length >= currQues) {
            setChartData(data[currQues - 1]);
        } else {
            setChartData({});
        }
    }, [currQues, data]);


    return(<div style={{marginBottom: "15px"}}>
        {minimized && 
            <div className="smallPanelBodyNew" style={{width: "100%"}}>
                <span className="smallPanelTitle" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>Responses</span>
                <DropDown style={{cursor: "pointer"}} onMouseDown={e => e.preventDefault()} onClick={() => setMinimized(false)} tabIndex={collapsed ? -1 : 0} aria-label={EXPAND_WINDOW_LABEL}/>
            </div>
        }
        {!minimized &&
        <div className="smallPanelBody2" style={{width: "100%"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span className="smallPanelTitle" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>{RESPONSES_HEADER}</span>
                <DropUp style={{cursor: "pointer"}} onMouseDown={e => e.preventDefault()} onClick={() => setMinimized(true)} tabIndex={collapsed ? -1 : 0} aria-label={MINIMIZE_WINDOW_LABEL}/>
            </div>
            <span className="smallPanelSubtitle" tabIndex={collapsed ? -1 : 0} role={TEXTBOX} >{RESPONSES_SUBTITLE_PREFIX}{pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? "" : MCSSoptUpper[16] + currQues + " "}{RESPONSES}</span>
            <div className="pollCodePanelBody">
            <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}>
                {initialized && (!chartData || !Object.entries(chartData).length || chartData.total === 0 || !gradeEnabled || localState === "SHARED_ALL") &&
                    <>
                        <NoResponsesIcon style={{maxWidth: "100%", maxHeight: "100%"}}/>
                        <span tabIndex={collapsed ? -1 : 0} role={TEXTBOX} >No responses found</span>
                        {[POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData?.poll?.pollShareMode) && pollData.questions.length > 1 &&
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", bottom: "0"}} className="shiftQText">
                                <ShiftPrev style={{marginRight: "10px", cursor: "pointer"}} onClick={() => setCurrQues(currQues === 1 ? 1 : currQues-1)}/>Question {currQues}
                                <ShiftNext style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => setCurrQues(currQues === pollData.questions.length ? currQues : currQues+1)}/>
                            </div>
                        }
                    </>
                }
                {initialized && (!!chartData && !!Object.entries(chartData).length && chartData.total !== 0) && (!!pollData?.questions && !!pollData?.questions?.length) && gradeEnabled && localState !== "SHARED_ALL" &&
                    <TabParticipantsLive
                        chartData={chartData}
                        minimized={false}
                        pollData={pollData}
                        currQues={currQues}
                        setCurrQues={setCurrQues}
                        localState={localState}
                        collapsed={collapsed}
                    />
                }
            </Spin>
            </div>
        </div>}
    </div>);
}
