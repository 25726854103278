import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
    addQuestion,
    deleteQuestion,
    handleQuestionOrderChange,
    questionCodeToName
} from "../../utils/questionUtils";
import {
    ADD_QUESTION_TEXT,
    BUTTON, CREATE_QUESTION_PROFILE_MODAL_TITLE,
    DELETE_BTN,
    DUPLICATE_BTN, IMPORT_BANK_TEXT, QUES_BANK_SAVE,
    QUES_LABEL_1,
    QUES_LABEL_2,
    QUES_LABEL_3, QUESTION_DASHBOARD, QUESTION_DEFAULT_OPTIONS, QUESTION_TYPES,
    TEXTBOX
} from "../../utils/constants";
import {ReactComponent as DuplicateIcon} from "../../images/duplicate.svg";
import {ReactComponent as DeleteIcon} from "../../images/delete.svg";
import YuJaButton from "../../components/standardization/YuJaButton";
import {ReactComponent as PlusIcon} from "../../images/plus.svg";
import React, {useState} from "react";
import useMobileAccess from "../../hooks/useMobileAccess";
import {PollApiCalls} from "../../apis/PollApiCalls";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";
import {usePPTWebView} from "../../hooks/usePPTWebView";
import {Spin} from "../../components/standardization/YuJaLoading";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {notifyError, stripHTMLTags} from "../../utils/helpers";
import {SAVE_BANK_WARNING_MESSAGE} from "../../utils/toast-message-constants";
import CreateQuestionProfileModal from "../../components/modals/CreateQuestionProfileModal";
import QuestionProfileModal from "../../components/modals/QuestionProfileModal";

export default function PPTCreatePollSideBar({
                                                 pollKey,
                                                 questions,
                                                 setQuestions,
                                                 currQueDisplay,
                                                 setCurrQueDisplay,
                                                 userSettings,
                                                 changePollKey,
                                             }) {
    const {
        checkQueImagesIsLoading,
        setQuestionLoading,
        resetImages,
        setImages
    } = useImageLoaderManager(questions, setQuestions);
    const { duplicateImagesOfQuestion } = PollApiCalls();

    const [showButton, setShowButton] = useState(true);
    const [showDragIcon, setShowDragIcon] = useState(null);
    const [importingModal, setImportingModal] = useState(false);
    const [profileSettingModalShow, setProfileSettingModalShow]= useState(false);
    const isNarrowScreen = useMobileAccess(1200);

    const {parseToken} = usePPTWebView();

    const handleDragStart = () => {
        // setShowButton(false);
    };

    const handleDragEnd = () => {
        setShowButton(true);
    };


    const clickDuplicate = async (selectedQuestion, e) => {
        e.stopPropagation();
        const {institutionId="", userId=""} = parseToken();

        if (!institutionId || !userId) {
            return;
        }

        const {serialNo} = selectedQuestion;

        const questionsCopy = JSON.parse(JSON.stringify(questions));
        let duplicatedQuestion = JSON.parse(JSON.stringify(selectedQuestion));
        let newQuestions = [];
        for (const q of questionsCopy) {
            if (q.serialNo === serialNo) {
                newQuestions.push({...q, checked: false});
                duplicatedQuestion.serialNo = q.serialNo + 1;
                duplicatedQuestion.checked = true;
                newQuestions.push(duplicatedQuestion);
            } else if (q.serialNo > serialNo) {
                newQuestions.push({ ...q, serialNo: q.serialNo + 1, checked: false });
            } else {
                newQuestions.push({ ...q, checked: false });
            }
        }


        const resourceMapping = setQuestionLoading(duplicatedQuestion);
        setQuestions(newQuestions);
        setCurrQueDisplay(serialNo + 1);

        if (!resourceMapping || !Object.entries(resourceMapping).length) {
            return;
        }

        //duplicate images of the question
        duplicateImagesOfQuestion(pollKey, selectedQuestion, resourceMapping).then((returnedMapping) => {
            //error
            if (!returnedMapping) {
                resetImages(Object.values(resourceMapping));
                return;
            }

            //set imageURL
            setImages(returnedMapping);
        });
    };

    const clickDelete = (selectedQuestion, e) => {
        e.stopPropagation();
        const {serialNo} = selectedQuestion;
        const newQuestions = deleteQuestion(selectedQuestion, questions);
        setCurrQueDisplay(serialNo === 1 ? 1 : serialNo - 1);
        setQuestions(newQuestions);
    };

    const clickNewQuestion = (e) => {
        e.stopPropagation();
        const newQuestions = addQuestion(questions, QUESTION_TYPES.MCSS.name, userSettings);
        setQuestions(newQuestions);
        setCurrQueDisplay(newQuestions.length);
    };

    const questionBankCheckbox = () => {
        let show = false;
        questions.forEach((question) => {
            if(question.queTitle !== "") {
                show = true;
            }
            else if (question.hasOwnProperty("image") && question.image !== "") {
                show = true;
            }
            else if (question.questionType === QUESTION_TYPES.MCSS.name && JSON.stringify(question.optionsMap) !== QUESTION_DEFAULT_OPTIONS.MCSS) {
                show = true;
            }
        })
        return show;
    }

    const handleCheckboxChange = () => {
        if(questionBankCheckbox()){
            setProfileSettingModalShow(true);
        }
        else {
            notifyError(SAVE_BANK_WARNING_MESSAGE);
        }
    }


    return (
        <div
            style={{
                padding: 10,
                boxShadow: "-1px 0 4px 0 rgba(0,0,0,.1)",
                width: 250,
                display: "flex",
                flexDirection: "column",
                gap: 15,
            }}
        >

            <CreateQuestionProfileModal setModalShow={setProfileSettingModalShow} show={profileSettingModalShow}
                                        questions={questions} pollKey={pollKey}/>
            <QuestionProfileModal
                show={importingModal}
                setModalShow={setImportingModal}
                setQuestions={setQuestions}
                questions={questions}
                setCurrQueDisplay={setCurrQueDisplay}
                pollKey={pollKey}
                changePollKey={changePollKey}
                userSettings={userSettings}
            />
            <div style={{display: "flex", width: "100%", height: "45px",}}>
                <div style={{
                    fontWeight: "700",

                    display: "flex",
                    fontSize: "16px",
                    lineHeight: "15px",
                    width: "100%", alignItems: "center",
                    borderTopLeftRadius: "10.2px",
                    borderTopRightRadius: "10.2px",
                    borderBottom: "0.5px solid #42296E",
                    color: "#1D1B20"
                }} tabIndex={0} role={TEXTBOX}>
                    {QUESTION_DASHBOARD}
                </div>
            </div>

            <DragDropContext
                onDragStart={handleDragStart}
                onDragEnd={(result) => {
                    const {destination = {}} = result;
                    const {index} = destination;

                    const updatedQuestions = handleQuestionOrderChange(result, questions);
                    if (updatedQuestions) {
                        setQuestions(updatedQuestions);
                        setCurrQueDisplay(index + 1);
                    }
                }}
            >
                <Droppable droppableId="droppable-sidebar">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            {...provided.dropHandleProps}
                            style={{overflowY: "auto"}}
                        >
                            {questions.map((question, index) => {
                                const {serialNo, questionType, queTitle} = question;
                                return (
                                    <div
                                        key={index}
                                        onClick={(event) => setCurrQueDisplay(serialNo)}
                                    >
                                        <Draggable
                                            key={String(serialNo)}
                                            draggableId={String(serialNo)}
                                            index={index}
                                        >
                                            {(draggableProvided) => (
                                                <div
                                                    className={`question-bar-container ${serialNo === currQueDisplay ? "is-cur" : ""}`}
                                                    {...draggableProvided.draggableProps}
                                                    {...draggableProvided.dragHandleProps}
                                                    ref={draggableProvided.innerRef}
                                                    aria-label={QUES_LABEL_1 + serialNo + " " + questionCodeToName(questionType) + QUES_LABEL_2 + QUES_LABEL_3}
                                                    onMouseEnter={() => setShowDragIcon(index)}
                                                    onMouseLeave={() => setShowDragIcon(null)}
                                                    role={TEXTBOX}
                                                    onMouseDown={() => document.getElementById("dummyInputBox").focus()}
                                                >
                                                    <div style={{flex: 1, paddingLeft: 10}}>
                                                        <div style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            wordBreak: "keep-all",
                                                            width: 120,
                                                            fontSize: 13
                                                        }}>
                                                            {!!queTitle
                                                                ? stripHTMLTags(queTitle)
                                                                : `Question ${serialNo}`}
                                                        </div>
                                                        <div className="sidebar-question-type"
                                                             style={{fontSize: 13}}>
                                                            {questionCodeToName(questionType)}
                                                        </div>
                                                    </div>
                                                    {!checkQueImagesIsLoading(question) &&
                                                        <>
                                                            <DuplicateIcon
                                                                title={"Duplicate Question"}
                                                                alt="duplicate icon"
                                                                onClick={(e) => clickDuplicate(question, e)}
                                                                style={{height: 20, width: 20}}
                                                                tabIndex={0}
                                                                aria-label={DUPLICATE_BTN + (index + 1)}
                                                                role={BUTTON}
                                                            />
                                                            <DeleteIcon
                                                                title={"Delete Question"}
                                                                alt="delete icon"
                                                                onClick={(e) => clickDelete(question, e)}
                                                                tabIndex={0}
                                                                aria-label={DELETE_BTN + (index + 1)}
                                                                role={BUTTON}
                                                                style={{height: 20, width: 20}}
                                                            />
                                                        </>
                                                    }

                                                    {checkQueImagesIsLoading(question) &&
                                                        <>
                                                            <div style={{width: 20, height: 20}}>
                                                                <Spin size={"medium"} spinning={true} style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}/>
                                                            </div>
                                                            <div style={{width: 20, height: 20}}>
                                                                <Spin size={"medium"} spinning={true} style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}/>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div style={{flex: 1, display: "flex", width: "100%", flexDirection: "column"}}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", gap: 10}}>
                    <YuJaButton
                        title={IMPORT_BANK_TEXT}
                        onClick={() => setImportingModal(true)}
                        style={{fontSize: 13}}
                    >
                        <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {IMPORT_BANK_TEXT}
                    </YuJaButton>

                    <YuJaButton title={ADD_QUESTION_TEXT} onClick={clickNewQuestion} style={{fontSize: 13}}>
                        <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {ADD_QUESTION_TEXT}
                    </YuJaButton>
                </div>
            </div>

            <div style={{display: "flex", gap: 10, flexDirection: "column", height: 25, padding: "0px 20px", fontSize: 14}}>
                <YuJaCheckbox
                    ariaLabel={QUES_BANK_SAVE + (profileSettingModalShow ? "checked" : "unchecked")}
                    checked={profileSettingModalShow}
                    onClick={handleCheckboxChange}
                    label={CREATE_QUESTION_PROFILE_MODAL_TITLE}
                    color={"#0C7086"}
                />
            </div>
        </div>
    );
}