import React, { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "react-native-web";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import { NOT_GRADED, TEXTBOX, UNGRADED } from "../../utils/constants";
import styles from "./YuJaDropdown.module.css";
import { useYuJaEventBus } from "./YuJaEventBusProvider";
import { YuJaTooltip } from "./YuJaTooltip";


export const YuJaDropdown = ({ data=[], onChange, onOpen=()=>{}, ariaLabel, isInvalid=false, errorMsg="", name, id, arrowSize=null, value, placeholder, getOptionLabel, getOptionValue, containerStyle={}, style={}, textStyle={}, itemStyle={}, isNotificationDropdown=false, isAttendanceDropdown= false, isEditNotificationDropdown=false, ...props}) => {
    const windowDimensions = useWindowDimensions();
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(props?.defaultOpen || false);
    const [padding, setPadding] = useState(10);
    const smallMenuHeight = 100; 
    const bigMenuHeight = 200; 
    const [menuHeight, setMenuHeight] = useState(bigMenuHeight);
    const actualValInputRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();

    const open = () => {
        if (props.disabled) {
            return;
        }
        const {height: windowHeight} = windowDimensions;
        setIsOpen(!isOpen);
        if (props?.setOpen) {
            props.setOpen(!isOpen);
        }
        const {height, top, } = dropdownRef.current?.getBoundingClientRect();
        if (top + height + bigMenuHeight > windowHeight) {
            setMenuHeight(smallMenuHeight);
        } else {
            setMenuHeight(bigMenuHeight);
        }
    }

    const onClick = (item) => {
        setIsOpen(false);
        if (props?.setOpen) {
            props.setOpen(false);
        }
        const input = actualValInputRef.current;
        if (input) {
            input.value = item.value;
        }
        if (onChange) {
            onChange(item);
        }
    }

    const getLabel =(item) => {
        if (!item) {
            return "";
        }
        if (getOptionLabel) {
            return getOptionLabel(item);
        }

        return item.label;
    }

    const getValue =(item) => {
        if (!item) {
            return "";
        }
        if (getOptionValue) {
            return getOptionValue(item);
        }

        return item.value;
    }

    useEffect(() => {
        if (props?.defaultOpen) {
            setIsOpen(true);
            if (props?.setOpen) {
                props.setOpen(true);
            }
        }

        if (style.padding) {
            setPadding(style.padding);
            return;
        }
        const inputFontSize = parseFloat(getComputedStyle(dropdownRef.current).fontSize);
        if (inputFontSize > 14) {
            setPadding(inputFontSize * 10 / 14);
        }
    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        for (const node of menuRef.current?.children) {
            const textNode = node?.children?.length ? node.children[0] : null;
            if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
                textNode.title = textNode.innerText;
            }
        }
        onOpen();
    }, [isOpen]);

    useEffect(() => {
        if (!isNotificationDropdown || !isOpen) {
            return;
        }

        document.querySelector(".details-content")?.addEventListener("scroll", () => {
            setIsOpen(false);
            if (props?.setOpen) {
                props.setOpen(false);
            }
        });

        return () => {
            document.querySelector(".details-content")?.removeEventListener("scroll", () => {
                setIsOpen(false);
                if (props?.setOpen) {
                    props.setOpen(false);
                }
            });
        };
    }, [isNotificationDropdown, isOpen]);

    const handleKeyDown = (e, index) => {
        e.preventDefault();
    
        if (e.key === "ArrowDown" && index < data.length - 1 && isOpen) {
            document.getElementById("YuJaDropdown"+(index+1)).focus();
        }
    
        if (e.key === "ArrowUp" && index > 0 && isOpen) {
            document.getElementById("YuJaDropdown"+(index-1)).focus();
        }
    };

    const handleTabDown = (e) => {        
        if(e.key === "ArrowDown") {
            e.preventDefault();
            setIsOpen(true);
            if (props?.setOpen) {
                props.setOpen(true);
            }
            document.getElementById("YuJaDropdown0").focus();
        }
    };

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && menuRef.current && !dropdownRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
                if (props?.setOpen) {
                    props.setOpen(false);
                }
            }
        };

        if (isOpen) {
            addClickEventHandler(handleGlobalClick);
        }

        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);

    return (
        <div className={isNotificationDropdown ? styles.notificationContainer : styles.container} style={containerStyle}>
            <div
                ref={dropdownRef}
                className={`${styles.presenter} ${isOpen ? styles.opened : ""} ${isInvalid ? styles.invalid : ""} ${props.disabled ? styles.disabled : ""}`}
                onClick={open}
                style={{...style, ...{padding: padding}}}
                tabIndex={0}
                aria-label={ariaLabel}
                onKeyDown={(e) => handleTabDown(e)}
                role={TEXTBOX}
                id={id}
            >
                <span className={styles.presenterText} style={{...textStyle}}>{isAttendanceDropdown && value === UNGRADED ? value : value ? getLabel(data.find(item => getValue(item) === value)) : placeholder}</span>
                {!isOpen && <ArrowDown style={{width: arrowSize? arrowSize : "18px", height: arrowSize? arrowSize : "18px"}}/> }
                {isOpen && <ArrowUp style={{width: arrowSize? arrowSize : "18px", height: arrowSize? arrowSize : "18px"}}/>}
                {isInvalid && errorMsg &&
                    <div className={styles.iconContainer}>
                        <YuJaTooltip placement={"right"} text={errorMsg} tooltipStyle={{maxWidth: 300}}>
                            <div className={styles.invalidIcon}/>
                        </YuJaTooltip>
                    </div>
                }
            </div>
            <input ref={actualValInputRef} defaultValue={value} name={name} style={{display: "none"}} id={value} aria-label="dropdown"/>
            {isOpen &&
                <>
                    <div
                        ref={menuRef}
                        className={`
                            ${isNotificationDropdown ? styles.notificationMenuList : styles.menuList}
                            ${isNotificationDropdown && (isEditNotificationDropdown ? styles.notificationMenuListEdit : styles.notificationMenuListNew)}
                        `}
                        style={{ maxHeight: menuHeight }}
                    >
                        {data.map((item, idx) => (
                            <>
                            <div
                                key={idx}
                                id={"YuJaDropdown" + idx}
                                className={`${styles.menuItem} ${getValue(item) === value ? styles.selected : ""}`}
                                style={{...itemStyle, ...{padding: padding}}}
                                onClick={() => onClick(item)}
                                onKeyDown={(e) => handleKeyDown(e, idx)}
                                tabIndex={0}
                                role={TEXTBOX}
                            >
                                <span className={styles.menuItemText}>{getLabel(item)}</span>
                            </div>
                            </>
                        ))}
                    </div>
                </>
            }
        </div>

    );
}