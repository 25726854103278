import React, { useEffect, useState } from "react";
// import { BsXCircle } from "react-icons/bs";
import { useWindowDimensions } from "react-native-web";
import { useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import { useSessionValidation } from "../../hooks/useSessionValidation";
import { ReactComponent as YuJaLogo } from "../../images/YuJa_Logo_Login.svg";
import { ReactComponent as UnsupportedIcon } from "../../images/Unsupported.svg";
import {
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    DEFAULT_PPT_AUTO_SHARE,
    POLL_TYPE,
    PRESENTATION_QUESTION_INTERNAL_ERROR_TEXT,
    PRESENTATION_QUESTION_INTERNAL_ERROR_TITLE,
    PRESENTATION_QUESTION_PAGE_TITLE, PRESENTATION_QUESTION_POLL_UNAVAILABLE_TEXT,
    PRESENTATION_QUESTION_POLL_UNAVAILABLE_TITLE,
    RELEASE_POLL_SURVEY_PPT_ERROR,
    UNSUPPORTED_ACTIVITY,
    USER_SETTINGS
} from "../../utils/constants";
import { getDefaultUserSettings } from "../../utils/helpers";
import useQuery from "../../utils/queryparam";
import styles from "./Presentation.module.css";
import QuestionDetail from "./QuestionDetail";


export default function PresentationQuestionPage() {
    const window = useWindowDimensions();
    const { getPollRecordFromUniqueCode } = PollApiCalls();
    const { loadUser } = UserApiCalls();
    const { checkExpired, parseToken } = useSessionValidation();
    const { pollCode, serialNo } = useParams();
    let query = useQuery();

    const [isLoading, setIsLoading] = useState(true);
    const [pollAvailable, setPollAvailable] = useState(true);
    const [isValidPollType, setIsValidPollType] = useState(true);
    const [internalError, setInternalError] = useState(false);

    const [poll, setPoll] = useState({});
    const [question, setQuestion] = useState({});
    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());

    useEffect(() => {
        let token = query.get("token");

        if (!token || checkExpired(token)) {
            setIsLoading(false);
            setPollAvailable(false);
            return;
        }

        const { institutionId, userId } = parseToken(token);

        loadUser(institutionId, userId).then(res => {
            const { settings = {} } = res;
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
            } else {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true";
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
            } else {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.PPT_AUTO_SHARE)) {
                settings[USER_SETTINGS.PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
            } else {
                settings[USER_SETTINGS.PPT_AUTO_SHARE] = settings[USER_SETTINGS.PPT_AUTO_SHARE] === true || settings[USER_SETTINGS.PPT_AUTO_SHARE] === "true";
            }
            console.log("User settings: ", settings);
            setUserSettings(settings);
        });

        getPollRecordFromUniqueCode(pollCode, token).then(pollDetail => {
            if (!pollDetail || !pollDetail.poll) {
                setInternalError(true);
                setIsLoading(false);
                return;
            }
            const { poll, questions } = pollDetail;
            const { index1Pk } = poll;
            const ownerId = index1Pk.substring("U#".length);

            if (ownerId !== userId) {
                setPollAvailable(false);
                setIsLoading(false);
                return;
            }

            if (poll?.pollType !== POLL_TYPE.MERGED_POLL && poll?.pollType !== POLL_TYPE.ATTENDANCE) {
                setIsValidPollType(false);
                setIsLoading(false);
                return;
            }

            setPoll(poll);
            const question = questions.find(q => q.serialNo === parseInt(serialNo));
            setQuestion(!!question ? question : {});
            setIsLoading(false);

        }).catch((error) => {
            console.log(error);
            setInternalError(true);
            setIsLoading(false);
        });
    }, []);

    return (
        <div className={styles.container}>
            {/* {isLoading &&
                <>
                    <div className={styles.titleContainer}>
                        {window.width < 1000 ?
                            <YuJaLogo style={{ marginRight: "5px", width: 60, height: 60, alignSelf: 'center' }} />
                            : <YuJaLogo style={{ marginRight: "5px", width: 95, height: 95, alignSelf: 'center' }} />
                        }
                        <div className={styles.title} style={{ fontSize: window.width < 1000 ? 32 : 40 }}>{PRESENTATION_QUESTION_PAGE_TITLE}</div>
                    </div>
                    <Spin size="large" wrapperClassName="globalSpin" spinning={true} />
                </>
            } */}

            {!isLoading && (!pollAvailable || internalError || !isValidPollType) &&
                <>
                    <div className={styles.titleContainer}>
                        {window.width < 1000 ?
                            <YuJaLogo style={{ marginRight: "5px", width: 60, height: 60, alignSelf: 'center' }} />
                            : <YuJaLogo style={{ marginRight: "5px", width: 95, height: 95, alignSelf: 'center' }} />
                        }
                        <div className={styles.title} style={{ fontSize: window.width < 1000 ? 32 : 40 }}>{PRESENTATION_QUESTION_PAGE_TITLE}</div>
                    </div>
                    <div className={styles.resultBox} style={{ width: window.width < 650 ? '80%' : 600 }}>
                        {/* {window.width < 1000 ?
                            <BsXCircle style={{ width: 60, height: 60 }} />
                            : <BsXCircle style={{ width: 90, height: 90 }} />
                        } */}
                        {window.width < 1000 ?
                            <UnsupportedIcon style={{ width: 180, height: 180 }} />
                            : <UnsupportedIcon style={{ width: 270, height: 270 }} />
                        }
                        <div className={styles.resultTitle} style={{ fontSize: window.width < 1000 ? 23 : 32 }}>
                            {!pollAvailable && PRESENTATION_QUESTION_POLL_UNAVAILABLE_TITLE}
                            {!isValidPollType && UNSUPPORTED_ACTIVITY}
                            {internalError && PRESENTATION_QUESTION_INTERNAL_ERROR_TITLE}
                        </div>
                        <div className={styles.description} style={{ fontSize: window.width < 1000 ? 16 : 24 }}>
                            {!pollAvailable && PRESENTATION_QUESTION_POLL_UNAVAILABLE_TEXT}
                            {!isValidPollType && RELEASE_POLL_SURVEY_PPT_ERROR}
                            {internalError && PRESENTATION_QUESTION_INTERNAL_ERROR_TEXT}
                        </div>
                    </div>
                </>
            }
            {/* !isLoading &&  */}
            {pollAvailable && !internalError && isValidPollType &&
                <QuestionDetail
                    isLoading={isLoading}
                    poll={poll} setPoll={setPoll}
                    question={question} setQuestion={setQuestion}
                    userSettings={userSettings}
                />
            }

        </div>
    );
}