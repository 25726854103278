import {
    ACT_ITEMS,
    ACTION_HEADER,
    DEFAULT_PAGINATION_SIZE,
    NO_RESULTS_FOUND,
    REPORT_COURSE_ACTIVITY_TABLE_HEADERS,
    REPORT_COURSE_TABLE_SEARCH_BOX_PLACEHOLDER,
    REPORT_COURSE_TABLE_VIEW_POLL_REPORT_LABEL,
    SEARCH_POLL,
    SEARCH_TEXT,
    TEXTBOX,
} from "../../utils/constants";

import classes from "./Report.module.css"
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import React, {useEffect, useState} from "react";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";

import YuJaButton from "../../components/standardization/YuJaButton";
import {useGlobalFilter, usePagination, useTable} from "react-table";
import styles from "../ManagePollsPage/ManagePolls.module.css";
import {Table} from "react-bootstrap";
import {YuJaKebabMenu} from "../../components/standardization/YuJaKebabMenu";
import NormalPagination from "../../components/pagination/NormalPagination";
import {useHistory} from "react-router-dom";

const columns = [
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.POLL_INDEX.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.POLL_INDEX.name, width: 120},
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.name, width: 500 },
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.name, width: 180 },
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.name, width: 120 },
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.ACTION.description, accessor: "", width: 90 }
];


const columns2 = [
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.ATTENDANCE_INDEX.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.ATTENDANCE_INDEX.name, width: 120},
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.name, width: 500 },
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.name, width: 180 },
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.description, accessor: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.name, width: 120 },
    { Header: REPORT_COURSE_ACTIVITY_TABLE_HEADERS.ACTION.description, accessor: "", width: 90 }
];

const indexHeaders =  [
    REPORT_COURSE_ACTIVITY_TABLE_HEADERS.POLL_INDEX.description,
    REPORT_COURSE_ACTIVITY_TABLE_HEADERS.ATTENDANCE_INDEX.description
];

export default function TabActivities({data, setData, isPoll=true}) {
    let history = useHistory();

    const [keyword, setKeyword] = useState();
    const [pageIdx, setPageIdx] = useState(0);
    const [filter, setFilter] = useState({});

    const [sortSelected, setSortSelected] = useState(0);
    const [sortIndex, setSortIndex] = useState(1);
    const [sortTitle, setSortTitle] = useState(-1);
    const [sortDate, setSortDate] = useState(-1);
    const [sortParticipantCount, setSortParticipantCount] = useState(-1);



    const clickSearch = () => {
        setFilter({keyword});
        setPageIdx(0);
    }

    useEffect(() => {
        setGlobalFilter(filter);
    }, [data, filter]);

    const filterCustomize = (rows, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword.toLowerCase()) > -1) {
                return true;
            }

            return false;
        });
    };


    const checkDisplayDownArrow = (Header) => {
        if (indexHeaders.includes(Header) && sortIndex === -1 && sortSelected === 0) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.description && sortTitle === -1 && sortSelected === 1) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.description && sortParticipantCount === -1 && sortSelected === 2) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.description && sortDate === -1 && sortSelected === 3) {
            return true;
        }
        return false;
    }

    const checkDisplayUpArrow= (Header) => {
        if (indexHeaders.includes(Header) && sortIndex === 1 && sortSelected === 0) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.description && sortTitle === 1 && sortSelected === 1) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.description && sortParticipantCount === 1 && sortSelected === 2) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.description && sortDate === 1 && sortSelected === 3) {
            return true;
        }
        return false;
    }

    const checkDisplayGreyArrow = (Header) => {
        if (indexHeaders.includes(Header) && sortSelected !== 0) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.description && sortSelected !== 1) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.description && sortSelected !== 2) {
            return true;
        }
        else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.description && sortSelected !== 3) {
            return true;
        }
        return false;
    }

    const handleSort = (Header) => {
        if (indexHeaders.includes(Header)) {
            sortIndexHelper();
            setSortSelected(0);
        } else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.TITLE.description) {
            sortTitleHelper();
            setSortSelected(1);
        } else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.PARTICIPANT_COUNT.description){
            sortParticipantCountHelper();
            setSortSelected(2);
        } else if (Header === REPORT_COURSE_ACTIVITY_TABLE_HEADERS.DATE.description){
            sortDateHelper();
            setSortSelected(3);
        }
    };



    const sortIndexHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortIndex === 1){
                copy = copy.sort(function (a, b) {
                    return b.index - a.index;
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.index - b.index;
                });
            }
            setData(copy);
        }
        setSortIndex(-1 * sortIndex);
    }

    const sortTitleHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortTitle === 1){
                copy = copy.sort(function (a, b) {
                    return b.pollTitle.localeCompare(a.pollTitle);
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.pollTitle.localeCompare(b.pollTitle);
                });
            }
            let idx = 1;
            for (const item of copy) {
                item.index = idx++;
            }

            setData(copy);
        }
        setSortTitle(-1 * sortTitle);
    }

    const sortParticipantCountHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortParticipantCount === 1){
                copy = copy.sort(function (a, b) {
                    return b.participantCount - a.participantCount;
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.participantCount - b.participantCount;
                });
            }
            let idx = 1;
            for (const item of copy) {
                item.index = idx++;
            }

            setData(copy);
        }
        setSortParticipantCount(-1 * sortParticipantCount);
    }

    const sortDateHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortDate === 1){
                copy = copy.sort(function (a, b) {
                    return b.GSI4SK.localeCompare(a.GSI4SK);
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.GSI4SK.localeCompare(b.GSI4SK);
                });
            }
            let idx = 1;
            for (const item of copy) {
                item.index = idx++;
            }

            setData(copy);
        }
        setSortDate(-1 * sortDate);
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize},
        setGlobalFilter,
    } = useTable({
        columns: isPoll ? columns : columns2,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_PAGINATION_SIZE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);


    const onClickViewActivityReport = (pollKey, pollCode) => {
        if (!!pollKey) {
            document.getElementsByTagName("main")[0].scrollTo(0, 0);
            history.push({
                pathname: `/report/activity/${pollKey}/${pollCode}`,
                // state: { reportStartDate, reportEndDate}
            });
        }
    }

    return (
        <>
            <div className={classes.tableSearchBox}>
                <YuJaTextBox
                    label={SEARCH_POLL}
                    containerStyle={{fontSize: 15, marginLeft: 10, width: "70%"}}
                    placeholder={REPORT_COURSE_TABLE_SEARCH_BOX_PLACEHOLDER}
                    onChange={e => setKeyword(e.target.value)}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                />
                <YuJaButton onClick={clickSearch}>
                    <div style={{fontSize: 14}}>{SEARCH_TEXT}</div>
                </YuJaButton>
            </div>
            <div className={classes.tableWrapper}>
                <Table hover {...getTableProps()} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps({
                                            style: {
                                                width: column.width,
                                                textAlign: column.render('Header') === ACTION_HEADER ? 'center' : 'left'
                                            }
                                        })}
                                    >
                                            <span
                                                tabIndex={0}
                                                role={TEXTBOX}
                                                className={indexHeaders.includes(column.render('Header')) ? classes.firstCell : classes.tableCell}
                                            >
                                                {column.render('Header')}
                                                {checkDisplayDownArrow(column.render('Header')) &&
                                                    <SortDown
                                                        tabIndex={0}
                                                        role={TEXTBOX}
                                                        aria-label={column.render('Header') + "descending order"}
                                                        style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => handleSort(column.render('Header'))}
                                                    />
                                                }

                                                {checkDisplayUpArrow(column.render('Header')) &&
                                                    <SortUp
                                                        tabIndex={0}
                                                        role={TEXTBOX}
                                                        aria-label={column.render('Header') + "ascending order"}
                                                        style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => handleSort(column.render('Header'))}
                                                    />
                                                }

                                                {checkDisplayGreyArrow(column.render('Header')) &&
                                                    <SortGrey
                                                        role={TEXTBOX}
                                                        tabIndex={0}
                                                        aria-label={column.render('Header') + "ascending order"}
                                                        style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => handleSort(column.render('Header'))}
                                                    />

                                                }

                                            </span>


                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page && page.length !== 0 &&
                        page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === ACTION_HEADER) {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div aria-label={ACT_ITEMS + row.cells.at(index - 4).value}
                                                         style={{display: "flex", justifyContent: "center"}}>
                                                        <YuJaKebabMenu>
                                                            <YuJaKebabMenu.Menu>
                                                                <YuJaKebabMenu.Item
                                                                    onClick={() => onClickViewActivityReport(cell.row.original.pollKey, cell.row.original.pollCode)}
                                                                >
                                                                    {REPORT_COURSE_TABLE_VIEW_POLL_REPORT_LABEL}
                                                                </YuJaKebabMenu.Item>
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </div>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td role={TEXTBOX}>
                                                    <div
                                                        tabIndex={0}
                                                        className={indexHeaders.includes(cell.column.render('Header')) ? classes.firstCell : classes.tableCell}>
                                                        {cell.value}
                                                    </div>
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            );
                        })
                    }


                    {(!page || page.length === 0) &&
                        <tr>
                            <td colSpan="5" className={styles.noData} style={{boxShadow: "none"}}>
                                <div
                                    style={{
                                        justifyContent: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 5,
                                        padding: 20
                                    }}
                                >
                                    <NoResultIllustration/>
                                    <div style={{
                                        fontSize: "18px",
                                        marginTop: "10px",
                                        fontWeight: 600
                                    }}> {NO_RESULTS_FOUND} </div>
                                </div>
                            </td>
                        </tr>

                    }
                    </tbody>
                </Table>
            </div>
            {page && pageCount > 1 &&
                <div style={{flex: 1, display: "flex", alignItems: "flex-end"}}>
                    <NormalPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                        paddingTop="0px"
                    />
                </div>
            }
        </>
    );
}