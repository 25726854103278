import {useHistory, useParams} from "react-router-dom";
import classes from "./Report.module.css";
import {
    REPORT_ACTIVITY_PIE_CHART_BROWSER_LABEL,
    REPORT_ACTIVITY_PIE_CHART_PLATFORM_LABEL, REPORT_ACTIVITY_TABLE_DESC, REPORT_ACTIVITY_TABLE_TITLE,
    REPORT_ACTIVITY_TITLE,
    REPORT_ACTIVITY_TITLE_DESC,
    REPORT_BROWSER_TYPES,
    REPORT_FINISHED_METRIC_TITLE, REPORT_INCOMPLETE_METRIC_TITLE,
    REPORT_PARTICIPANT_METRIC_TITLE,
    REPORT_PLATFORM_TYPES,
    REPORT_TOTAL_QUE_METRIC_TITLE
} from "../../utils/constants";
import {ReactComponent as TOTAL_QUE_ICON}  from "./images/MetricIconTotalQue-activity.svg";
import {ReactComponent as PARTICIPANT_ICON}  from "./images/MetricIconParticipant-activity.svg";
import {ReactComponent as FINISHED_ICON}  from "./images/MetricIconFinished.svg";
import {ReactComponent as INCOMPLETE_ICON}  from "./images/MetricIconIncomplete.svg";
import {ReactComponent as TABLE_ICON}  from "./images/ChartIcon.svg";
import {ReactComponent as GRADE_BOOK_ICON}  from "./images/GradeBookIcon.svg";

import {
    getMetricBGColorByCount,
    getMetricColorByCount, getShortMonthYear,
    getTrendByCount, getYearMonthStr,
    isCountIncreased
} from "./ReportHelper";
import React, {useEffect, useRef, useState} from "react";
import {PollApiCalls} from "../../apis/PollApiCalls";
import {ReportApiCalls} from "../../apis/ReportApiCalls";
import TabQuestions from "./TabQuestions";
import YuJaButton from "../../components/standardization/YuJaButton";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import ReportDoughnutChart from "./ReportDoughnutChart";
import {useReportMetricCardDivider} from "../../hooks/useReportMetricCardDivider";
import {removePrefix} from "../../utils/helpers";

export default function ReportActivityPage(props) {
    const { pollKey, pollCode } = useParams();

    const {getPollDataFromKey} = PollApiCalls();
    const {getActivityWiseReport} = ReportApiCalls();
    let history = useHistory();
    const [, getSession] = useLocalStorageNew("session", {});

    const [poll, setPoll] = useState({});
    const [curDate, ] = useState(() =>{
        let currentDate = new Date();
        currentDate.setDate(1);
        return getYearMonthStr(currentDate);
    });
    const [overviewCount, setOverviewCount] = useState({});
    const [lastMonthCount, setLastMonthCount] = useState({});
    const [browserCount, setBrowserCount] = useState([]);
    const [platformCount, setPlatformCount] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [browserNoData, ] = useState(() => {
        let data = [];
        for (const key in REPORT_BROWSER_TYPES) {
            data.push({name: REPORT_PLATFORM_TYPES[key], value: 1, fill: "#E1E1E1"});
        }
        return data;
    });
    const [platformNoData, ] = useState(() => {
        let data = [];
        for (const key in REPORT_PLATFORM_TYPES) {
            data.push({name: REPORT_PLATFORM_TYPES[key], value: 1, fill: "#E1E1E1"});
        }
        return data;
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [pollLoading, setPollLoading] = useState(false);

    const containerRef = useRef();
    let {maxCardsPerRow} = useReportMetricCardDivider(containerRef);
    const [isActivityOwner, setIsActivityOwner] = useState(false);


    const BROWSER_DIAGRAM_COLORS = ["#4F75CB", "#E2726E", "#6DA186", "#81CCC9", "#EBBE79", "#E992D8"];
    const PLATFORM_DIAGRAM_COLORS = ["#E992D8", "#928FDC", "#E19278", "#EBBE79"];

    const DATE_FORMAT_OPTION = {
        weekday: 'long',  // Full name of the day
        year: 'numeric',  // Full year
        month: 'long',    // Full name of the month
        day: 'numeric',   // Day of the month
        hour: '2-digit',  // 2-digit hour
        minute: '2-digit', // 2-digit minute
        second: '2-digit', // 2-digit second
        hour12: true       // 12-hour format with AM/PM
    }


    useEffect(() => {
        props.setPageIdentifier(REPORT_ACTIVITY_TITLE);
    }, []);

    useEffect(() => {
        setPollLoading(true);
        getPollDataFromKey(pollKey).then(res => {
            let poll = !!res ? res : {};
            let {ownerId=""} = poll;
            setPoll(poll);
            let {userId=""} = getSession();
            setIsActivityOwner(removePrefix(ownerId) === userId);
        }).finally(() => {
            setPollLoading(false);
        });

        setDataLoading(true);
        getActivityWiseReport(pollKey, curDate).then(result => {
            const {
                questionCount=0, participantCount=0, finishedCount=0, incompleteCount=0,
                chromeCount=0, firefoxCount=0, edgeCount=0, safariCount=0, mobileAppCount=0, otherBrowserCount=0,
                appleCount=0, androidCount=0, windowsCount=0, otherPlatformCount=0,
                lastMonthCount={},
                questions=[],
            } = result;

            setOverviewCount({questionCount, participantCount, finishedCount, incompleteCount});
            setLastMonthCount(lastMonthCount);
            setQuestions(questions);

            let browserCount = [];
            browserCount.push({name: REPORT_BROWSER_TYPES.CHROME, value: chromeCount});
            browserCount.push({name: REPORT_BROWSER_TYPES.FIREFOX, value: firefoxCount});
            browserCount.push({name: REPORT_BROWSER_TYPES.EDGE, value: edgeCount});
            browserCount.push({name: REPORT_BROWSER_TYPES.SAFARI, value: safariCount});
            browserCount.push({name: REPORT_BROWSER_TYPES.MOBILE_APP, value: mobileAppCount});
            browserCount.push({name: REPORT_BROWSER_TYPES.OTHERS, value: otherBrowserCount});
            browserCount = browserCount.sort((a, b) => b.value - a.value);
            let idx = 0;
            for (const item of browserCount) {
                item.fill = BROWSER_DIAGRAM_COLORS[idx % 4];
                idx++;
            }
            setBrowserCount(browserCount);

            let platformCount = [];
            platformCount.push({name: REPORT_PLATFORM_TYPES.APPLE, value: appleCount});
            platformCount.push({name: REPORT_PLATFORM_TYPES.ANDROID, value: androidCount});
            platformCount.push({name: REPORT_PLATFORM_TYPES.WINDOWS, value: windowsCount});
            platformCount.push({name: REPORT_PLATFORM_TYPES.OTHERS, value: otherPlatformCount});
            platformCount = platformCount.sort((a, b) => b.value - a.value);
            idx = 0;
            for (const item of platformCount) {
                item.fill = PLATFORM_DIAGRAM_COLORS[idx % 4];
                idx++;
            }
            setPlatformCount(platformCount);
        }).finally(() => {
            setDataLoading(false);
        });

    }, [pollKey]);

    const viewGradeBook = () => {
        history.push({
            pathname: "/gradeBook",
            state: {pollKey: pollKey, uniqueCode: pollCode, pollType: poll.pollType}
        });
    }

    const viewReleasePoll = () => {
        if (!isActivityOwner) {
            return;
        }
        history.push({
            pathname: `/poll/share/${pollKey}/${pollCode}`,
        });
    }

    return (
        <div className={classes.parentContainer} ref={containerRef}>
            {!pollLoading &&
                <div className={classes.titleCard} >
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <span className={classes.title} tabIndex={0}>{REPORT_ACTIVITY_TITLE}</span>
                        <span style={{height: 15}}/>
                        <span
                            onClick={viewReleasePoll}
                            className={isActivityOwner ? classes.clickableCourseInfoLabel : classes.courseInfoLabel}
                            tabIndex={0}
                        >{poll.pollTitle}</span>
                        <span style={{height: 15}}/>
                        <span className={classes.description} tabIndex={0}>{REPORT_ACTIVITY_TITLE_DESC}</span>
                    </div>
                    <div style={{flex: 1}}/>
                    <div style={{display: "flex", flexDirection: "column"}}>
                    <span className={classes.description} tabIndex={0}>
                        <span className={classes.regularLabel} style={{marginRight: 5}}>Update on:</span>
                        {new Date(poll.updatedTimeSK).toLocaleString('en-US', DATE_FORMAT_OPTION)}.
                    </span>
                        <span style={{height: 20}}/>
                        {!!isActivityOwner &&
                            <YuJaButton style={{alignSelf: "flex-end"}} onClick={viewGradeBook}>
                                <GRADE_BOOK_ICON/> View Gradebook
                            </YuJaButton>
                        }
                    </div>
                </div>
            }

            {!!pollLoading &&
                <div className={classes.titleCard}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <span className={classes.title} tabIndex={0}>{REPORT_ACTIVITY_TITLE}</span>
                        <span style={{height: 15}}/>
                        <Skeleton
                            borderRadius={10} height={16} inline={true} width={"100%"}
                            className={classes.cardNumberSkeleton}
                            containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <span style={{height: 15}}/>
                        <span className={classes.description} tabIndex={0}>{REPORT_ACTIVITY_TITLE_DESC}</span>
                    </div>
                    <div style={{flex: 1}}/>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Skeleton borderRadius={10} height={16} inline={true} width={300}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>
                </div>
            }


            {/*part1: metric card*/}
            {!dataLoading &&
                <div
                    className={classes.metricCardsContainer}
                    style={{
                        gridTemplateColumns: maxCardsPerRow === 6 ? '4fr 4fr 4fr 4fr 5fr 5fr' : `repeat(${maxCardsPerRow}, 1fr)`
                    }}
                >
                    {/*TOTAL QUESTION*/}
                    <div className={`${classes.metricCard} ${classes.firstMetricCard}`} style={{flex: 4}}>
                        <TOTAL_QUE_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel} tabIndex={0}>{REPORT_TOTAL_QUE_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.questionCount ? overviewCount.questionCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.questionCount)}
                        </div>
                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.questionCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.questionCount)}}>
                            {isCountIncreased(lastMonthCount.questionCount) === false ? "-" : "+"}
                            {!!lastMonthCount.questionCount ? lastMonthCount.questionCount : 0}
                        </span>
                            {` in ${getShortMonthYear(curDate)}`}
                        </div>
                    </div>
                    {/*PARTICIPANT*/}
                    <div className={`${classes.metricCard} ${classes.secondMetricCard}`} style={{flex: 4}}>
                        <PARTICIPANT_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel}
                                     tabIndex={0}>{REPORT_PARTICIPANT_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.participantCount ? overviewCount.participantCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.participantCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.participantCount)}}
                             tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.participantCount)}}>
                            {isCountIncreased(lastMonthCount.participantCount) === false ? "-" : "+"}
                            {!!lastMonthCount.participantCount ? lastMonthCount.participantCount : 0}
                        </span>
                            {` in ${getShortMonthYear(curDate)}`}
                        </div>
                    </div>

                    {/*FINISHED*/}
                    <div className={`${classes.metricCard} ${classes.thirdMetricCard}`} style={{flex: 4}}>
                        <FINISHED_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel} tabIndex={0}>{REPORT_FINISHED_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.finishedCount ? overviewCount.finishedCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.finishedCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.finishedCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.finishedCount)}}>
                            {isCountIncreased(lastMonthCount.finishedCount) === false ? "-" : "+"}
                            {!!lastMonthCount.finishedCount ? lastMonthCount.finishedCount : 0}
                        </span>
                            {` in ${getShortMonthYear(curDate)}`}
                        </div>
                    </div>
                    {/*INCOMPLETE*/}
                    <div className={`${classes.metricCard} ${classes.fourthMetricCard}`} style={{flex: 4}}>
                        <INCOMPLETE_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel}
                                     tabIndex={0}>{REPORT_INCOMPLETE_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.incompleteCount ? overviewCount.incompleteCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.incompleteCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.incompleteCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.incompleteCount)}}>
                            {isCountIncreased(lastMonthCount.incompleteCount) === false ? "-" : "+"}
                            {!!lastMonthCount.incompleteCount ? lastMonthCount.finishedCount : 0}
                        </span>
                            {` in ${getShortMonthYear(curDate)}`}
                        </div>
                    </div>

                    {/*PLATFORM*/}
                    <ReportDoughnutChart title={REPORT_ACTIVITY_PIE_CHART_PLATFORM_LABEL} countData={platformCount} emptyCountData={platformNoData}/>

                    {/*BROWSER*/}
                    <ReportDoughnutChart title={REPORT_ACTIVITY_PIE_CHART_BROWSER_LABEL} countData={browserCount} emptyCountData={browserNoData}/>
                </div>
            }



            {!!dataLoading &&
                <div
                    className={classes.metricCardsContainer}
                    style={{
                        gridTemplateColumns: maxCardsPerRow === 6 ? '4fr 4fr 4fr 4fr 5fr 5fr' : `repeat(${maxCardsPerRow}, 1fr)`
                    }}
                >
                    {/*TOTAL QUESTION*/}
                    <div className={`${classes.metricCard} ${classes.firstMetricCard}`} style={{flex: 4}}>
                        <TOTAL_QUE_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>
                    {/*PARTICIPANT*/}
                    <div className={`${classes.metricCard} ${classes.secondMetricCard}`} style={{flex: 4}}>
                        <PARTICIPANT_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>

                    {/*FINISHED*/}
                    <div className={`${classes.metricCard} ${classes.thirdMetricCard}`} style={{flex: 4}}>
                        <FINISHED_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>
                    {/*INCOMPLETE*/}
                    <div className={`${classes.metricCard} ${classes.fourthMetricCard}`} style={{flex: 4}}>
                        <INCOMPLETE_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>

                    {/*PLATFORM*/}
                    <div className={classes.metricCard} style={{flex: 5, justifyContent: "center"}}>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                                  style={{margin: "auto"}}
                        />
                    </div>


                    {/*BROWSER*/}
                    <div className={classes.metricCard} style={{flex: 5, justifyContent: "center"}}>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                                  style={{margin: "auto"}}
                        />
                    </div>
                </div>
            }



            {/* part2: table*/}
            {!dataLoading &&
                <div className={classes.tableCardContainer} style={{height: "fit-content"}}>
                    <div className={classes.cardContainerHead}>
                        <TABLE_ICON/>
                        {REPORT_ACTIVITY_TABLE_TITLE}
                    </div>

                    <div className={classes.cardContainerBody}>
                        <div className={classes.description}>{REPORT_ACTIVITY_TABLE_DESC}</div>
                        <TabQuestions data={questions} setData={setQuestions}/>
                    </div>
                </div>
            }

            {!!dataLoading &&
                <div className={classes.tableCardContainer} style={{height: 530}}>

                    <div className={classes.cardContainerHead}>
                        <TABLE_ICON/>
                        {REPORT_ACTIVITY_TABLE_TITLE}
                    </div>

                    <div className={classes.cardContainerBody}>
                        <Skeleton borderRadius={10} height={30} inline={true} width={350}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <Skeleton borderRadius={10} height={30} inline={true} width={300}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <Skeleton borderRadius={10} height={30} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />

                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                            <div style={{display: "grid", gridTemplateColumns: "15% 25% 15% 15% 15% 15%"}}>
                                <Skeleton borderRadius={10} height={30} width={"30%"} style={{position: "relative"}}/>
                                <Skeleton borderRadius={10} height={30} width={"80%"} style={{position: "relative"}}/>
                                <Skeleton borderRadius={10} height={30} width={"30%"} style={{position: "relative"}}/>
                                <Skeleton borderRadius={10} height={30} width={"30%"} style={{position: "relative"}}/>
                                <Skeleton borderRadius={10} height={30} width={"30%"} style={{position: "relative"}}/>
                                <Skeleton borderRadius={10} height={30} width={"30%"} style={{position: "relative"}}/>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "15% 25% 15% 15% 15% 15%"}}>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}/>
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}/>

                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>

                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`}
                                          style={{marginTop: 15}}/>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
            }
        </div>
    );
}