import React from "react";
import CancelIcon from "../../images/cancel.svg";
import FITBAddIcon from "../../images/new_fitb_add.svg";
import FITBAddIconHover from "../../images/new_fitb_add_hover.svg";
import { stringifyNumber } from "../../utils/helpers";
import {BUTTON, FITB_ADD, FITB_ANSWER_LENGTH, FITB_DELETE, TYPE_CORRECT_ANSWER_TEXT} from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { FITB_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";

export default function FITBAnswer({
  answerKey,
  answerValue,
  handleAddAnswer,
  handleUpdateAnswer,
  handleDeleteAnswer,
  index,
  questionBuilder=false,
  disabled=false
}) {
  return (
      <div style={{display: "flex", alignItems: "center", minWidth: "35%", gap: 5}}>
          <YuJaTextBox
              label={answerKey + " Type Correct Answer"}
              name={"question-text"}
              placeholder={
                  index === 0
                      ? TYPE_CORRECT_ANSWER_TEXT
                      : stringifyNumber(index + 1) + " Answer"
              }
              maxLength={FITB_ANSWER_LENGTH}
              value={answerValue}
              disabled={questionBuilder && disabled}
              onChange={(e) => {
                  if (e.target.value.length <= FITB_ANSWER_LENGTH) {
                      handleUpdateAnswer(answerKey, index, e.target.value);
                  } else {
                      notifyError(FITB_ANSWER_LENGTH_EXCEEDED);
                      handleUpdateAnswer(answerKey, index, e.target.value.substring(0, FITB_ANSWER_LENGTH));
                  }
              }}
              containerStyle={{fontSize: questionBuilder ? 16 : 18, padding: "5px", flex: 1}}
              before={<span className="fitb-bold"
                            style={{marginLeft: 10, fontSize: questionBuilder ? 16 : 18}}>{answerKey}:{" "}</span>}
              after={
                  <>
                      {(!questionBuilder || !disabled) &&
                          <img
                              aria-label={FITB_ADD + answerKey}
                              tabIndex={0}
                              role={BUTTON}
                              src={FITBAddIcon}
                              alt="fitb add"
                              title={"Add more Answer"}
                              className="cursor-pointer"
                              onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddAnswer(answerKey);
                              }}
                              style={{width: "24px", fill: "blue", float: "right", marginLeft: 5}}
                              onMouseOver={(e) => {
                                  e.currentTarget.src = FITBAddIconHover
                              }}
                              onMouseOut={(e) => {
                                  e.currentTarget.src = FITBAddIcon
                              }}
                          />
                      }
                  </>
              }
              showRemaining={true}
          />
          {(!questionBuilder || !disabled) &&
              <img
                  aria-label={FITB_DELETE + answerKey}
                  onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteAnswer(answerKey, index);
                  }}
                  src={CancelIcon}
                  alt="delete icon"
                  tabIndex={0}
                  role={BUTTON}
                  title={"Delete Answer"}
                  className="questions-page-icons cursor-pointer"
              />
          }
      </div>
  );
}
