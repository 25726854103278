import {TEXTBOX} from "../../utils/constants";
import React, {useState} from "react";
import {YuJaImageViewer} from "../../components/standardization/YuJaImageViewer";

export const OptionImageViewer = ({optIdx="", optionsImageMap={}, isSelected=false}) => {
    const [showViewModal, setShowViewModal] = useState(false);
    const imageURL = optionsImageMap[optIdx]?.imageURL ? optionsImageMap[optIdx].imageURL : "";

    const handleClose = () => {
        setShowViewModal(false);
    };

    const onClickImage = (e) => {
        e.stopPropagation();
        setShowViewModal(true);
    };

    return (
        <>
            <YuJaImageViewer showViewer={showViewModal} onHandleClose={handleClose} imageURL={imageURL} imageAlt={`Option ${optIdx} image preview`}/>
            {!!imageURL &&
                <div
                    className="MCSS-opt-image-container"
                    style={isSelected ? {border: "2px solid #FFFFFF"} : {}}
                    onClick={onClickImage}
                >
                    <img
                        alt={`Option ${optIdx} image preview`}
                        src={`${imageURL}`}
                        style={ { maxWidth: "100%", maxHeight: "100%", cursor: "pointer"}}
                    />
                </div>
            }
        </>
    );
}