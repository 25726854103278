import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthenticationApiCalls } from "../apis/AuthenticationApiCalls";
import ResponsiveSidebar from "../components/ResponsiveSidebar";
import { useCookie } from "../hooks/useCookie";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import useMobileAccess from "../hooks/useMobileAccess";
import { ReactComponent as LogOut } from "../images/LogoutIcon.svg";
import { ReactComponent as Setting } from "../images/SettingNavibarIcon.svg";
import { ReactComponent as Support } from "../images/SupportIcon.svg";
import { ReactComponent as YuJaLogo } from "../images/YuJa_Logo.svg";
import { ReactComponent as NavbarArrow } from "../images/navbar_arrow.svg";
import {
  ACCOUNT_CARD_LOGOUT_TEXT,
  ACCOUNT_CARD_SETTING_TEXT,
  ACCOUNT_CARD_SUPPORT_TEXT,
  BUTTON,
  CREATE_POLL_PATH,
  GRADEBOOK_PATH,
  GRADED_POLL,
  HOME_ICON,
  HOME_PATH,
  IDENTITY_PROVIDER_TYPES,
  MENU_LIST,
  NAVBAR_ENGAGE, OPEN_IN_NEW_TAB,
  ROLES
} from "../utils/constants";

import letterA from "../images/letterA.svg";
import letterB from "../images/letterB.svg";
import letterC from "../images/letterC.svg";
import letterD from "../images/letterD.svg";
import letterE from "../images/letterE.svg";
import letterF from "../images/letterF.svg";
import letterG from "../images/letterG.svg";
import letterH from "../images/letterH.svg";
import letterI from "../images/letterI.svg";
import letterJ from "../images/letterJ.svg";
import letterK from "../images/letterK.svg";
import letterL from "../images/letterL.svg";
import letterM from "../images/letterM.svg";
import letterN from "../images/letterN.svg";
import letterO from "../images/letterO.svg";
import letterP from "../images/letterP.svg";
import letterQ from "../images/letterQ.svg";
import letterR from "../images/letterR.svg";
import letterS from "../images/letterS.svg";
import letterT from "../images/letterT.svg";
import letterU from "../images/letterU.svg";
import letterV from "../images/letterV.svg";
import letterW from "../images/letterW.svg";
import letterX from "../images/letterX.svg";
import letterY from "../images/letterY.svg";
import letterZ from "../images/letterZ.svg";
import { ReactComponent as NewWindowIcon } from "../images/links/new_window.svg";
import { ReactComponent as NewWindowIconBlue } from "../images/links/new_window_blue.svg";
import layoutStyles from "../layouts/Layout.module.css";
import { newPageLog, roleValuetoDisplay } from "../utils/helpers";
import NewSidebar from "./NewSidebar";
import YuJaHelpModal from "./modals/YujaHelpModal.js";
import { YuJaTooltip } from "./standardization/YuJaTooltip.js";
import "./styles.css";


function NavBarUnit({ children, showCourse = false }) {
  return (
    <div className={showCourse ? "navbar-unit active" : "navbar-unit"} style={{display: "flex", justifyContent: "end", width: "100%", height: "100%"}}>
      <span className="navDropdownText" style={{display: "flex", alignItems: "center"}}>{children}</span>
      {/* <img src={dropdownArrow} alt="Dropdown arrow" /> */}
    </div>
  );
}

export default function YuJaNavbar({ isViewer = false, isCreator = false, sidebarCollapsed, countdownContent, pollKeyCode, pollType, pageIdentifier }) {
  const [, getSession] = useLocalStorageNew("session", {});
  const [, getCookie] = useCookie("name", "Guest");
  const [showModal, setShowModal] = useState(false);
  const session = getSession();
  const { logout } = AuthenticationApiCalls();
  const history = useHistory();
  const anonymous = !session.userName;
  const [imgUrl, setImgUrl] = useState("");
  // const userName = anonymous ? getCookie() : session.userName;
  var userName;
  if (anonymous) {
    userName = getCookie();
  } else {
    if (session.firstName && session.lastName) {
      userName = session.firstName + " " + session.lastName;
    } else if (session.firstName) {
      userName = session.firstName;
    } else {
      userName = session.userName;
    }
  }
  var email = session.email; 
  var role = session.role
  var names = userName.split(" ");
  var StringToImage = {
    "A": letterA,
    "B": letterB,
    "C": letterC,
    "D": letterD,
    "E": letterE,
    "F": letterF,
    "G": letterG,
    "H": letterH,
    "I": letterI,
    "J": letterJ,
    "K": letterK,
    "L": letterL,
    "M": letterM,
    "N": letterN,
    "O": letterO,
    "P": letterP,
    "Q": letterQ,
    "R": letterR,
    "S": letterS,
    "T": letterT,
    "U": letterU,
    "V": letterV,
    "W": letterW,
    "X": letterX,
    "Y": letterY,
    "Z": letterZ
};
  var initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  const isNarrow = useMobileAccess(1200);
  const [collapsed, setCollapsed] = useState(true);
  const [showLogoutBtn, setShowLogoutBtn] = useState(!!session.userName);
  // const sideBarToggle = {
  //   cursor: "pointer",
  //   margin: "10px",
  // };

  useEffect(() => {
    newPageLog("ViewerNavbar.js");
    if(session.idpType && session.idpType === IDENTITY_PROVIDER_TYPES.LMS) {
        setShowLogoutBtn(false);
    }
}, []);

  const sideNavbarStyle = {
    position: "absolute",
    left: "0px",
    top: "0px",
    minWidth: isNarrow ? "240px" : "60px",
    zIndex: 2,
    overflowX: "hidden",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
    height: "100vh",
  };


  const logoutFunction = () => {
    if (window.location.pathname.includes(CREATE_POLL_PATH)) {
      history.push({
        pathname: CREATE_POLL_PATH,
        state: {pollType: GRADED_POLL, fromNavbar: true, previous: CREATE_POLL_PATH, logout: true}
      })
    }
    else {
      logout();
    }
  }

  return (
    // (!!session.gradedLink && window.location === window.parent.location) ? <></> :
    <div id="viewer-navbar-container" style={{ wordBreak: "break-word"}}>
      <YuJaHelpModal showModal={showModal} setShowModal={setShowModal} userName={userName} userEmail={email} />
      <Container className="max-width sticky-top" id="navBar" style={{padding: 0}}>
          <Navbar id={isNarrow ? "viewer-navbar" : "first-navbar"}>
            <Container fluid id="first-nav-container" style={countdownContent && window.innerWidth >= window.innerHeight && window.innerHeight < 480 ? {padding: "0 20px 0 10px"} : {paddingRight: 20}}>
              {!isNarrow && <>
                {!session.gradedLink &&
                  <LinkContainer to={HOME_PATH}>
                    <Navbar.Brand className="p-2 logo-icon navbar-engage" aria-label={HOME_ICON}>
                      <YuJaLogo />
                      {(sidebarCollapsed || window?.location?.pathname.includes(GRADEBOOK_PATH)) && <h1 className="navbar-text" style={{ marginLeft: "8px", marginBottom: "0px" }}>{window?.location?.pathname.includes(GRADEBOOK_PATH) ? MENU_LIST.GRADEBOOK.name : NAVBAR_ENGAGE}</h1>}
                      {(sidebarCollapsed || window?.location?.pathname.includes(GRADEBOOK_PATH)) && <NavbarArrow style={{ margin: "8px", marginRight: "0px" }} />}
                      <h1 id="engagePageIdentifier" className="navbar-text" style={{ marginLeft: "8px", marginBottom: "0px" }}>{pageIdentifier}</h1>
                    </Navbar.Brand>
                  </LinkContainer>
                }
                {!!session.gradedLink &&
                  <Navbar.Brand className="p-2 logo-icon navbar-engage" aria-label={HOME_ICON}>
                    <YuJaLogo />
                  </Navbar.Brand>
                }
              </>}
              <Navbar.Collapse id="basic-navbar-nav" style={{flex: 0}}>
                <Nav style={{flexGrow: 0}}>
                  {!session.gradedLink && isNarrow ? <>
                    <ResponsiveSidebar
                      className={`${layoutStyles.LeftSideBarContainer}`}
                      sidebarStyle={sideNavbarStyle}
                      // setShowSideBar={setShowSideBar}
                      collapsed={collapsed}
                      setCollapsed={setCollapsed}
                    >
                      <NewSidebar sideBarCollapseAble={false} isViewer={isViewer} isCreator={isCreator} isMobile={true} collapsed={collapsed} setCollapsed={setCollapsed} />
                    </ResponsiveSidebar>
                  </> : <></>}
                </Nav>
              </Navbar.Collapse>
              <Nav className="justify-content-center">
                {countdownContent}
              </Nav>
              <Nav className="justify-content-end">
                {window.location !== window.parent.location && <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    {isNarrow && 
                      <YuJaTooltip text={OPEN_IN_NEW_TAB} placement="bottom">
                        <NewWindowIconBlue aria-label={OPEN_IN_NEW_TAB} role={BUTTON} style={{width: "33px", height: "33px", cursor: "pointer", marginRight: "8px"}} onMouseDown={e => e.preventDefault()} onClick={() => {
                          const engageSession = encodeURIComponent(JSON.stringify(getSession()));
                          const path = window.location.pathname;
                          const dest = encodeURIComponent(path.includes(CREATE_POLL_PATH) && pollKeyCode ? `/edit/${pollKeyCode}` : path);
                          const pollTypeParam = encodeURIComponent(pollType ? pollType : "");
                          window.open(`/portal?engageSession=${engageSession}&dest=${dest}${`&pollType=${pollTypeParam}`}`);
                        }}/>
                      </YuJaTooltip>
                    }
                    {!isNarrow && 
                      <YuJaTooltip text={OPEN_IN_NEW_TAB} placement="bottom">
                        <NewWindowIcon aria-label={OPEN_IN_NEW_TAB} role={BUTTON} style={{width: "33px", height: "33px", cursor: "pointer", marginRight: "8px"}} onMouseDown={e => e.preventDefault()} onClick={() => {
                          const engageSession = encodeURIComponent(JSON.stringify(getSession()));
                          const path = window.location.pathname;
                          const dest = encodeURIComponent(path.includes(CREATE_POLL_PATH) && pollKeyCode ? `/edit/${pollKeyCode}` : path);
                          const pollTypeParam = encodeURIComponent(pollType ? pollType : "");
                          window.open(`/portal?engageSession=${engageSession}&dest=${dest}${`&pollType=${pollTypeParam}`}`);
                        }}
                      />
                      </YuJaTooltip>
                    }
                </div>} <>
                      <NavDropdown
                          className = {"navDropdown"}
                          title={(
                              <NavBarUnit tabIndex={0}>
                                  <img src={StringToImage[initials[0]] ? StringToImage[initials[0]] : StringToImage["G"]} style={{width: 33, height:33, marginRight: 10}} />
                              </NavBarUnit>
                          )}
                      >
                          {/* <NavDropdown.Item tabIndex={0} onClick={logoutFunction} style={{ fontSize: "15px", display: "flex"}}>
                            <span style={{display: "flex", alignItems: "center", gap: 5, width: 150}}><BiLogOut size={25} />Logout</span>
                          </NavDropdown.Item> */}
                      <div style={{position: "relative", right: "50px", width: "100px"}}>
                      <ul role="menu" tabIndex={0} style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 5px 0px", padding: "8px 0px", position: "absolute", right: 5,  backgroundColor: "#FFFFFF", borderRadius: "5px", display: "flex",  alignItems: "center", flexDirection: "column", width: 180, height: "fit-content"}} > 
                      <img src={StringToImage[initials[0]] ? StringToImage[initials[0]] : StringToImage["G"]} style={{width: 45, height:45, margin: "20px 10px"}} />
                      <div title={userName} style={{textAlign: "center", fontWeight: 700, maxWidth: "175px",fontSize: 20, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingLeft: "10px", paddingRight: "10px"}}>{userName} </div>
                      <div style={{color: "rgb(111, 111, 111)", fontSize: "14px"}}> { roleValuetoDisplay(role)} </div>
                      <div title={email} style={{overflow: "hidden", maxWidth: "175px", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", paddingLeft: "10px", paddingRight: "10px"}}> {email} </div>
                      <hr style={{width: "100%"}}/>
                        <NavDropdown.Item tabIndex={0} onClick={() => {setShowModal(true)}} style={{ fontSize: "15px", display: "flex"}}>
                            <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><Support size={24} style={{width: 24 , height: 24}}/>{ACCOUNT_CARD_SUPPORT_TEXT}</span>
                        </NavDropdown.Item>
                        {!anonymous && !session.gradedLink && <>
                        <NavDropdown.Item tabIndex={0} onClick={() => { history.push({pathname: role === ROLES.VIEWER.value?  "/student/settings": "/admin/platformsetting", state: {User: true}});}} style={{ fontSize: "15px", display: "flex", marginTop: 10}}>
                            <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><Setting size={24} style={{width: 24 , height: 24}} />{ACCOUNT_CARD_SETTING_TEXT}</span>
                        </NavDropdown.Item>
                        </>}
                        {!anonymous && showLogoutBtn && <>
                        <hr style={{width: "100%"}}/>
                        <NavDropdown.Item tabIndex={0} onClick={logoutFunction} style={{ fontSize: "15px", display: "flex"}}>
                            <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><LogOut size={24} style={{width: 24 , height: 24}}/>{ACCOUNT_CARD_LOGOUT_TEXT}</span>
                        </NavDropdown.Item>
                        </>}
                      </ul>
                      </div>
                      </NavDropdown> 
                  </>
              </Nav>
            </Container>
          </Navbar>
      </Container>
    </div>
  );
}
