// import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native-web";
import { useParams } from "react-router-dom";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import WordCloudResult from "../JoinQuizPage/WordCloudResult";

export default function MobileResultPage() {
    const { getQuestionResult, getClassResult} = ResponseApiCalls();
    const { pollKey, pollCode, serialNo } = useParams();
    // const [isLoading, setIsLoading] = useState(false);
    const [questionResult, setQuestionResult] = useState([]);
    const window = useWindowDimensions();

    useEffect(() => {
        const meta = document.createElement('meta');
        meta.setAttribute('content', 'initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
        meta.setAttribute('name', 'viewport');
        document.head.appendChild(meta);

        const getData = async () => {
            getClassResult(pollKey, pollCode, {serialNo : serialNo} , null, true).then((result) => {
                if (result.length > 0) {
                    setQuestionResult(result);
                } else {
                    setQuestionResult([]);
                }
            });
        };
        // setIsLoading(true);
        getData();
        // setIsLoading(false);

        const interval = setInterval(() => {
            getData();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <WordCloudResult
            data={questionResult}
            customStyle={{
                // width: window.width,
                height: window.height,
                tooltip: false
            }}
        />
    );
}