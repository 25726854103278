import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { UserApiCalls } from "../../apis/UserApiCalls";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { CREATE_QUESTION_PROFILE_MODAL_INPUT, CREATE_QUESTION_PROFILE_MODAL_TEXT, CREATE_QUESTION_PROFILE_MODAL_TITLE, MODAL_CANCEL_TEXT, MODAL_SAVE_TEXT, POLL_TITLE_LENGTH } from "../../utils/constants";
import { notifyError, validateText } from "../../utils/helpers";
import { trimQuestion } from "../../utils/questionUtils";
import { QUESTION_BANK_CHARACTER_LIMIT_EXCEEDED, QUESTION_BANK_TITLE_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../standardization/YuJaButton";

export default function CreateQuestionProfileModal({setModalShow, show, questions, pollKey}) {
    const [profileName, setProfileName] = useState(""); 
    const pollTitleLimit = POLL_TITLE_LENGTH;
    const {saveQuestionProfile} = UserApiCalls(); 
    const handleClose = () =>{
        setProfileName(""); 
        setModalShow(false); 
    };

    const handleProfileNameChange = (e) => {
        if (e.target.value.length <= pollTitleLimit) {
            const input = e.currentTarget;
            setProfileName(input.value);
        }
        else {
            notifyError(QUESTION_BANK_CHARACTER_LIMIT_EXCEEDED); 
            return;
        }
    }

    const handleSave = () => {
        if (!validateText(profileName, POLL_TITLE_LENGTH)) {
            notifyError(QUESTION_BANK_TITLE_ERROR);
            return false;
        }

        const trimmedQs = trimQuestion(questions);
        let pollToAdd = {
            questions: trimmedQs,
            ProfileTitle: profileName,
            pollKey: pollKey
        };
        saveQuestionProfile(pollToAdd, true);
        setModalShow(false);
    }


    return (
        <Modal
            id="form-modal"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
        >
            <ModalClose onClick={handleClose} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                    {CREATE_QUESTION_PROFILE_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{fontSize: "13px",  fontStyle: "normal", fontWeight: "400", lineHeight: "13px", color: "#000000", marginBottom: "20px"}}> {CREATE_QUESTION_PROFILE_MODAL_TEXT} 
                </div>
                <label style={{fontSize: "13px",  fontStyle: "normal", fontWeight: "700", lineHeight: "13px", color: "#000000", marginBottom: "5px"}}> {CREATE_QUESTION_PROFILE_MODAL_INPUT} </label>
                <Form.Control value={profileName} type="text" style={{ width: "100%" }} onChange={handleProfileNameChange} /> 
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type="secondary" onClick={handleClose}>{MODAL_CANCEL_TEXT}</YuJaButton>
                <YuJaButton onClick={handleSave}>{MODAL_SAVE_TEXT}</YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}