import React, {useEffect, useRef, useState} from "react";
import classes from "./Report.module.css";

export const LineChartTooltip = (props) => {
    const { active, payload, label, setActiveLabel, labelArr=[]} = props;
    const tooltipRef = useRef();
    const POSITION = {FIRST: "first", MIDDLE: "middle", LAST: "last"};
    const [activePosition, setActivePosition] = useState(POSITION.MIDDLE);
    const [offsetX, setOffsetX] = useState(0);

    useEffect(() => {
        if (!tooltipRef.current) {
            return;
        }

        if (active) {
            let {width} = tooltipRef.current.getBoundingClientRect();
            if (setActiveLabel) {
                setActiveLabel(label);
            }
            let activeIdx = labelArr.indexOf(label);
            if (activeIdx === 0) {
                setActivePosition(POSITION.FIRST);
                setOffsetX(- 50);
            } else if (activeIdx === labelArr.length - 1) {
                setActivePosition(POSITION.LAST);
                setOffsetX(- width + 20);
            } else {
                setActivePosition(POSITION.MIDDLE);
                setOffsetX(-(width / 2) - 10);
            }
        } else {
            if (setActiveLabel) {
                setActiveLabel("");
            }
        }
    }, [active, label]);

    if (active && payload && payload.length) {
        return (
            <div
                ref={tooltipRef}
                className={`${classes.chartTooltips} ${classes[activePosition]}`}
                style={{transform: `translate(${offsetX}px, 0px)`}}
            >
                <span className={classes.chartTooltipsLabel} >{label}</span>
                {payload.map((item, index) =>
                    <div className={classes.chartTooltipsRow} key={index}>
                        <div className={classes.chartTooltipsDiagram} style={{backgroundColor: item.color}}/>
                        {`${item.name} - ${item.value}`}
                    </div>
                )}
            </div>
        );
    }

    return <></>;
};