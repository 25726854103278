import React, { useRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from "../images/calendarIcon.svg";
import { APPLY, ATTENDANCE, CLEAR, MERGED_MODE, MODAL_CANCEL_TEXT, SCHEDULED, SHARED_BY_QUESTION, SHARED_ENTIRELY, SURVEY, TEXTBOX, UNFINISHED, UNFINISHED_CHOOSER } from '../utils/constants';
import YuJaButton from './standardization/YuJaButton';
import { notifyError } from '../utils/helpers';
import { INVALID_FILTER_END_DATE_ERROR,  } from '../utils/toast-message-constants';

export default function FilterMenu({ show, setShow, onApply, filterOptions, style={}, isChooser=false, isPPT=false, hasDateRange=true }) {
    const [optionFilter1, setOptionFilter1] = useState(filterOptions?.optionFilter1);
    const [optionFilter2, setOptionFilter2] = useState(filterOptions?.optionFilter2);
    const [optionFilter3, setOptionFilter3] = useState(filterOptions?.optionFilter3);
    const [dateFilter1, setDateFilter1] = useState(filterOptions?.dateFilter1);
    const [dateFilter2, setDateFilter2] = useState(filterOptions?.dateFilter2);
    const [dateFilterDisplay1, setDateFilterDisplay1] = useState(new Date());
    const [dateFilterDisplay2, setDateFilterDisplay2] = useState(new Date());

    const dateFilter1Ref = useRef(null);
    const dateFilter2Ref = useRef(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // console.log(name, value)
        switch (name) {
            case filterOptions?.optionFilter1Name:
                if (optionFilter1.includes(value)) {
                    setOptionFilter1(optionFilter1.filter(option => option !== value));
                } else {
                    setOptionFilter1([...optionFilter1, value]);
                }
                break;
            case filterOptions?.optionFilter2Name:
                if (optionFilter2.includes(value)) {
                    setOptionFilter2(optionFilter2.filter(option => option !== value));
                } else {
                    setOptionFilter2([...optionFilter2, value]);
                }
                break;
            case filterOptions?.optionFilter3Name:
                if (optionFilter3.includes(value)) {
                    setOptionFilter3(optionFilter3.filter(option => option !== value));
                } else {
                    setOptionFilter3([...optionFilter3, value]);
                }
                break;
            // case filterOptions?.dateFilter1Name:
            //     setDateFilter1(value);
            //     break;
            // case filterOptions?.dateFilter2Name:
            //     setDateFilter2(value);
            //     break;
            default:
                console.log('Error: Invalid filter name');
                break;
        }
    };

    const handleCancel = () => {
        setOptionFilter1(filterOptions?.optionFilter1);
        setOptionFilter2(filterOptions?.optionFilter2);
        setOptionFilter3(filterOptions?.optionFilter3);
        setDateFilter1(filterOptions?.dateFilter1);
        setDateFilter2(filterOptions?.dateFilter2);
        if (!filterOptions || !filterOptions.dateFilter1) {
            setDateFilterDisplay1(new Date());
        }
        if(!filterOptions || !filterOptions.dateFilter2) {
            setDateFilterDisplay2(new Date());
        }
        setShow(false);
    };

    const handleClear = () => {
        if (filterOptions?.optionFilter1Name) setOptionFilter1([]);
        if (filterOptions?.optionFilter2Name) setOptionFilter2([]);
        if (filterOptions?.optionFilter3Name) setOptionFilter3([]);
        if (filterOptions?.dateFilter1Name) setDateFilter1("");
        if (filterOptions?.dateFilter2Name) setDateFilter2("");
        onApply({
            optionFilter1: [],
            optionFilter2: [],
            optionFilter3: [],
            dateFilter1: "",
            dateFilter2: ""
        });
        setDateFilterDisplay1(new Date());
        setDateFilterDisplay2(new Date());
    };

    const handleApply = () => {
        if (!!hasDateRange && dateFilter1 > dateFilter2) {
            notifyError(INVALID_FILTER_END_DATE_ERROR);
            return;
        }
        onApply({
            optionFilter1: optionFilter1,
            optionFilter2: optionFilter2,
            optionFilter3: optionFilter3,
            dateFilter1: dateFilter1,
            dateFilter2: dateFilter2
        });
        setShow(false);
    };

    const CustomInput = React.forwardRef((props, ref) => {
        return (
          <div
              className="react-datepicker__input-container"
              onClick={() => props.onClick()}
          >
            <input type="text" tabIndex={-1} placeholder={"DD/MM/YYYY"} className="datepickerinputStart react-datepicker-ignore-onclickoutside" value={dateFilter1} readOnly={true}/>
                <div
                    role={TEXTBOX}
                    tabIndex={0}
                    aria-label={filterOptions.dateFilter1Name}
                    style={{ position: "absolute", cursor: "pointer", width: "225px", height: "36px", top: "0px" }}
                />
                <CalendarIcon style={{ transform: "translate(-30px, 8px)", cursor: "pointer", position: "absolute" }}/>
            </div>
        );
    });

    const CustomInputEnd = React.forwardRef((props, ref) => {
        return (
            <div
                className="react-datepicker__input-container"
                onClick={() => props.onClick()}
            >
                <input tabIndex={-1} type="text" placeholder={"DD/MM/YYYY"} className="datepickerinputStart react-datepicker-ignore-onclickoutside" value={dateFilter2} readOnly={true} />
                <div role={TEXTBOX} tabIndex={0} aria-label={filterOptions.dateFilter2Name}

                    style={{ position: "absolute", width: "225px", cursor: "pointer", height: "36px", top: "0px" }}
                />
                <CalendarIcon style={{ transform: "translate(-30px, 8px)", cursor: "pointer", position: "absolute" }}/>
            </div>
        );
      });


    return (<>
        {show &&
            <div
                // ref={ref}
                className="filter-menu"
                style={style}
            >
                <div className="option-filters">
                    {filterOptions?.optionFilter1Name &&
                        <div className="option-filter-column">
                            {/* Option Filter 1 */}
                            <div className="option-title">{filterOptions.optionFilter1Name}</div>
                            {filterOptions.optionsFilter1.map((option, index) => (
                                <div key={index} className="option-row">
                                    <input
                                        type="checkbox"
                                        name={filterOptions.optionFilter1Name}
                                        id={`optionFilter1-${index}`}
                                        className="option-checkbox"
                                        value={option}
                                        tabIndex={0}
                                        aria-label={`checkbox ${option}`}
                                        checked={optionFilter1.includes(option)}
                                        onChange={handleInputChange}
                                        disabled={optionFilter2 &&
                                            ((option === SURVEY && optionFilter2.includes(SCHEDULED))
                                                || (option === ATTENDANCE &&
                                                    (optionFilter2.includes(UNFINISHED)
                                                        || optionFilter2.includes(SCHEDULED)
                                                        || optionFilter2.includes(SHARED_BY_QUESTION)))
                                                || (isPPT && option === MERGED_MODE && optionFilter2.includes(SHARED_ENTIRELY)))}
                                        // aria-label={filterOptions.optionFilter1Name}
                                    />
                                    <label htmlFor={`optionFilter1-${index}`} className="option-txt">
                                        {option}
                                    </label>
                                </div>
                            ))}
                        </div>
                    }
                    {filterOptions?.optionFilter2Name &&
                        <div className="option-filter-column">
                            {/* Option Filter 2 */}
                            <div className="option-title">{filterOptions.optionFilter2Name}</div>
                            {filterOptions.optionsFilter2.map((option, index) => (
                                <div key={index} className="option-row">
                                    <input
                                        type="checkbox"
                                        name={filterOptions.optionFilter2Name}
                                        id={`optionFilter2-${index}`}
                                        className="option-checkbox"
                                        value={option}
                                        tabIndex={0}
                                        aria-label={`checkbox ${option}`}
                                        checked={optionFilter2.includes(option)}
                                        onChange={handleInputChange}
                                        disabled={optionFilter1 &&
                                            ((option === SCHEDULED && optionFilter1.includes(SURVEY))
                                                || (optionFilter1.includes(ATTENDANCE)
                                                    && (option === UNFINISHED
                                                        || option === SHARED_BY_QUESTION
                                                        || option === SCHEDULED))
                                                || (isPPT && optionFilter1.includes(MERGED_MODE) && option === SHARED_ENTIRELY))}
                                        // aria-label={filterOptions.optionFilter2Name}
                                    />
                                    <label htmlFor={`optionFilter2-${index}`} className="option-txt">
                                        {isChooser && option === UNFINISHED ? UNFINISHED_CHOOSER : option}
                                    </label>
                                </div>
                            ))}
                        </div>
                    }
                    {filterOptions?.optionFilter3Name &&
                        <div className="option-filter-column">
                            {/* Option Filter 3 */}
                            <div className="option-title">{filterOptions.optionFilter3Name}</div>
                            {filterOptions.optionsFilter3.map((option, index) => (
                                <div key={index} className="option-row">
                                    <input
                                        type="checkbox"
                                        tabIndex={0}
                                        aria-label={`checkbox ${option}`}
                                        name={filterOptions.optionFilter3Name}
                                        id={`optionFilter3-${index}`}
                                        className="option-checkbox"
                                        value={option}
                                        checked={optionFilter3.includes(option)}
                                        onChange={handleInputChange}
                                        // aria-label={filterOptions.optionFilter3Name}
                                    />
                                    <label htmlFor={`optionFilter3-${index}`} className="option-txt">
                                        {option}
                                    </label>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="date-filters">
                    {/* Date Filter 1 */}
                    {filterOptions?.dateFilter1Name && <div className="date-filter-column">
                        {/* <label htmlFor="dateFilter1">{filterOptions.dateFilter1Name}</label>
                        <input
                            type="date"
                            name="dateFilter1"
                            id="dateFilter1"
                            value={dateFilter1}
                            onChange={handleInputChange}
                        /> */}
                        <div className="date-picker-title">{filterOptions.dateFilter1Name}</div>
                        <DatePicker
                            showTimeInput
                            disabledKeyboardNavigation
                            ref={dateFilter1Ref}
                            displayFormat='DD-MM-YYYY'
                            className='date-picker-input'
                            placeholder='DD/MM/YYYY'
                            onChange={value => {
                                if (!!value) {
                                    setDateFilterDisplay1(value);
                                    setDateFilter1(value.toISOString().split('T')[0]);
                                } else {
                                    setDateFilter1("");
                                }
                            }}
                            style={{fontSize: 20}}
                            selected={dateFilterDisplay1}

                            shouldCloseOnSelect={true}
                            customInput={<CustomInput/>}
                            customTimeInput={<div style={{height: 11}}> </div>}
                            strictParsing={true}
                            maxDate={new Date()}
                        />
                    </div>}
                    {/* Date Filter 2 */}
                    {filterOptions?.dateFilter2Name && <div className="date-filter-column">
                        {/* <label htmlFor="dateFilter2">{filterOptions.dateFilter2Name}</label>
                        <input
                            type="date"
                            name="dateFilter2"
                            id="dateFilter2"
                            value={dateFilter2}
                            onChange={handleInputChange}
                        /> */}
                        <div className="date-picker-title">{filterOptions.dateFilter2Name}</div>
                        <DatePicker
                            showTimeInput
                            disabledKeyboardNavigation
                            ref={dateFilter2Ref}
                            displayFormat='DD-MM-YYYY'
                            className='date-picker-input'
                            placeholder='DD/MM/YYYY'
                            onChange={value => {
                                if (!!value) {
                                    setDateFilterDisplay2(value);
                                    setDateFilter2(value.toISOString().split('T')[0]);
                                } else {
                                    setDateFilter2("");
                                }
                            }}
                            selected={dateFilterDisplay2}
                            customInput={<CustomInputEnd />}
                            customTimeInput={<div style={{height: 11}}> </div>}
                            shouldCloseOnSelect={true}
                            strictParsing={true}
                            maxDate={new Date()}
                        />
                    </div>}
                </div>
                <div className="filter-buttons">
                    <YuJaButton type="secondary" style={{fontSize: 13}} onClick={handleCancel}>{MODAL_CANCEL_TEXT}</YuJaButton>
                    <YuJaButton type="delete" style={{fontSize: 13}} onClick={handleClear}>{CLEAR}</YuJaButton>
                    <YuJaButton style={{fontSize: 13}} onClick={handleApply}>{APPLY}</YuJaButton>
                </div>
            </div>
        }
    </>);
}
