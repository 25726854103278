import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { DELETE_MODAL_BODY_TEXT, DELETE_MODAL_TITLE, DELETE_TEXT, DISCARD_CHANGES_TEXT, DISCARD_MODAL_BODY_TEXT, MODAL, MODAL_CANCEL_TEXT, TEXTBOX } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import "./modal.css";

export default function DeleteConfirmationModal({ show, setShow, handleSubmit, isNewQuestion }) {
    const [disabledDeleteQuestion, setDisabledDeleteQuestion] = useState(false);

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ModalClose onClick={() => setShow(false)} className="modal-close" />
                <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0}
                    aria-label={"Set Poll Time Limit" + MODAL}
                    role={TEXTBOX}
                >
                    {isNewQuestion ? DELETE_MODAL_TITLE : DISCARD_CHANGES_TEXT}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isNewQuestion ? DELETE_MODAL_BODY_TEXT : DISCARD_MODAL_BODY_TEXT}
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type="secondary" onClick={() => setShow(false)}>{MODAL_CANCEL_TEXT}</YuJaButton>
                <YuJaButton
                    disabled={disabledDeleteQuestion}
                    type="delete"
                    onClick={async () => {
                        setDisabledDeleteQuestion(true);
                        await handleSubmit();
                        setDisabledDeleteQuestion(false);
                    }}
                >
                    {isNewQuestion ? DELETE_TEXT : DISCARD_CHANGES_TEXT}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}
