import {CI_COORDINATE_SIZE, QUESTION_TYPES} from "../../utils/constants";
import {decodeCIParam} from "../../utils/questionUtils";
import {React, useEffect, useRef, useState} from "react";
import {ReactComponent as COORDINATE} from "../../images/coordiante.svg";
import "../styles.css";

export default function CIImageAnswerViewer({question}) {
    const {questionType, selectedOption} = question
    const [questionImageURL, setQuestionImageURL] = useState("");
    const imageRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const onLoad = () => {
        setIsLoaded(true);
    }

    useEffect(() => {
        setQuestionImageURL(question.imageURL);
    }, [question]);

    return (
        <>
            {questionImageURL && (
                <div style={{ position: "relative"}}>
                    <img ref={imageRef} className="img-container-ci-poll" tabIndex={0} src={questionImageURL} onLoad={onLoad} alt="Question Image" />
                    {questionType === QUESTION_TYPES.CI.name
                        && isLoaded
                        && !!selectedOption
                        && selectedOption.hasOwnProperty("x")
                        && selectedOption.hasOwnProperty("y")
                        &&
                        <COORDINATE
                            style={{
                                position: "absolute",
                                top: `${decodeCIParam(selectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                left: `${decodeCIParam(selectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                            }}
                        />

                    }
                </div>

            )}
        </>
    )
}