import React, {useEffect} from "react";
import FormModal from "./FormModal";
import {useHistory} from "react-router-dom";
import { useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import { HOME_PATH } from "../../utils/constants";
import { Spin } from "../../components/standardization/YuJaLoading";
import { Form, Modal } from "react-bootstrap";
import "antd/dist/antd.min.css";
import 'rc-datepicker/lib/style.css';
import "./modal.css";
import {newPageLog} from "../../utils/helpers.js";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import YuJaButton from "../standardization/YuJaButton";

export default function WarningModal({code, setModalShow, show, usecase, setModalVisible}) {
    const history = useHistory(); 
    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession(); 
    const [setGuestSession, getGuestSession] = useLocalStorageNew("guestSession", {}); 
    const isLoading = false; 
    const [setWarning, ] = useLocalStorageNew("warning", {});


    const handleClose = () =>{
        setModalShow(false)
        modalConfig.clearForm();
    };

    useEffect(() => {
      newPageLog("WarningModal.js");
    }, []);

    
    const clearForm = () => {
        if (usecase === "3") {
            if (session.authorization && session.userId) {
                history.push(HOME_PATH)
              }
              else {
                history.push("/qr/" + code);
              }
        }
        else {
            setModalShow(false);
        }
    }

    const handlePublishFromModal = () => {
        if (usecase === "1") {
            setWarning(true);
            history.push({pathname: "/join/" + code})
        }
        else if (usecase === "2") {
            setWarning(true);
            setModalShow(false);
            history.push({pathname: "/join/" + code})
        }
        else {
            const guestsession = getGuestSession();
            guestsession["$"+ code] = {
                ...guestsession["$"+ code],
                Authenticated: "true"
        };
        setGuestSession(guestsession); 
            setModalShow(false);
            setModalVisible(true);
        }
    }

    const modalConfig = {
        title: "Important: Gradebook Sync Disabled",
        submitButtonText: "Proceed Anyways",
        clearForm: clearForm,
        submit: handlePublishFromModal,

        form: [
            {
                label: "This Poll is linked to your LMS Gradebook. We highly recommend you re-access this Poll within your LMS to ensure that grade results are synced with your LMS course. ",
                isMulti: false,
                type: "warning",
            },
        ],
    };
    return (
    <Modal
      id="form-modal"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={isLoading ? "static" : true}

    >   
    <Spin tip="Loading..." size="large" spinning={isLoading}>
        <ModalClose onClick={handleClose} className="modal-close" />
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          
        >
        <Modal.Title
          className="warning-title"
          id="contained-modal-title-vcenter"
        >
          {modalConfig.title}
        </Modal.Title>


        </Modal.Header>
        <Modal.Body>
        <Form className="customized-form" onSubmit={e => e.preventDefault()}>
        {modalConfig.form.map((formItem, idx) => {
            return (
         <Form.Group style={{ marginBottom: "0.938rem" }} key={idx}>
         <div className="form-check-label">{formItem.label}</div>
         </Form.Group>
            );
        })}
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <YuJaButton type={"secondary"} onClick={handleClose}>Close</YuJaButton>
          <YuJaButton
              onClick={(e) => {
                e.preventDefault();
                  modalConfig.submit();
              }}
          >
              {modalConfig.submitButtonText}
          </YuJaButton>

        </Modal.Footer>
        </Spin>
    </Modal>
    )
}
