import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CourseApiCalls } from '../../apis/CourseApiCalls';
import { PollApiCalls } from "../../apis/PollApiCalls";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import GradientButton from "../../public/buttons/GradientButton";
import {
    AUDIENCE_RESTRICTION,
    EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE,
    INVALID_POLL_CODE_ERROR,
    JOIN_USING_CODE_QR_TITLE,
    JOIN_USING_CODE_TITLE,
    POLL_CODE_LENGTH,
    SCAN_QR,
    TEXTBOX
} from "../../utils/constants";
import { formatErrorLog, newPageLog, notifyError } from "../../utils/helpers";
import { ENTER_VALID_CODE_ERROR, USER_ACCESS_POLL_ERROR } from "../../utils/toast-message-constants";
import stylesDashboard from "./../DashboardPage/DashboardPage.module.css";
import styles from "./Home.module.css";
import { ReactComponent as JoinIcon } from "./images/join_icon.svg";
import { ReactComponent as JoinUsingCodeIcon } from "./images/join_using_code_icon.svg";

export default function JoinUsingCode() {
    const history = useHistory();
    const isMobile = useMobileAccess(950);

    const { getPollRecordFromUniqueCode } = PollApiCalls();
    const { checkMemberAccess } = CourseApiCalls();    

    const [setWarning, ] = useLocalStorageNew("warning", {});
    const [_, getSession] = useLocalStorageNew("session", {});
    const session = getSession(); 
    const gradedLink = session.gradedLink;

    const [code, setCode] = useState(null); 
    const [pollCode, setPollCode] = useState(['', '', '', '', '', '']);

    const handleChange = (index, event) => {
        if (event.keyCode === 13) {
            handleSubmit();
            return;
        }
        if (event.keyCode === 8) {//backspace
            if (document.getElementById(`poll-code-input-${index - 1}`) !== null) {
              document.getElementById("poll-code-input-" + (index - 1)).select();
            }
            return
          }
    }; 

    const handleClick = (index, event) => {
        let inputValue = event.target.value;

        // Remove non-numeric characters
        inputValue = inputValue.replace(/[^0-9]/g, '');

        // Update the state
        const newPollCode = [...pollCode];
        newPollCode[index] = inputValue;
        setPollCode(newPollCode);

        if (inputValue.trim() === "") {
            return;
          }
      
          if (document.getElementById(`poll-code-input-${index + 1}`) !== null) {
            document.getElementById("poll-code-input-" + (index + 1)).select();
          }
    };

    const handlePaste = (index, event) => {
        event.preventDefault();
        let inputValue = event.clipboardData.getData('Text').replace(/[^0-9]/g, '');
        const newPollCode = [...pollCode];
        let i;
        for (i = 0; i < inputValue.length; i++) {
            if (index + i == POLL_CODE_LENGTH) break;
            newPollCode[index + i] = inputValue[i];
        }
        setPollCode(newPollCode);
        document.getElementById("poll-code-input-" + Math.min(index + i, POLL_CODE_LENGTH - 1)).select();
    };

    useEffect(() => {
        newPageLog("JoinUsingCode.js");
    }, []);

    const handleSubmit = async () => {
        let code = "" + document.getElementById("poll-code-input-0").value
            + document.getElementById("poll-code-input-1").value
            + document.getElementById("poll-code-input-2").value
            + document.getElementById("poll-code-input-3").value
            + document.getElementById("poll-code-input-4").value
            + document.getElementById("poll-code-input-5").value;
        if (!code || code.trim().length !== 6) {
            notifyError(ENTER_VALID_CODE_ERROR);
            formatErrorLog(ENTER_VALID_CODE_ERROR);
            return;
        }
        const result = await getPollRecordFromUniqueCode(code);
        if (!result || !result.poll) {
            notifyError(INVALID_POLL_CODE_ERROR);
            formatErrorLog(INVALID_POLL_CODE_ERROR);
            return;
        }

        const {audienceRestriction=null, index2Pk: courseId, lmsHomeUrl, syncLms=true} = result.poll;
        if (audienceRestriction === AUDIENCE_RESTRICTION.UNRESTRICTED) {
            setWarning(false);
            history.push({pathname: "/join/" + code});
            return;
        }

        if (audienceRestriction ? audienceRestriction === AUDIENCE_RESTRICTION.REGISTERED : !!courseId) {
            if (!!lmsHomeUrl) {
                if (!gradedLink && syncLms) {
                    formatErrorLog(EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE);
                    history.push(`/lti/${code}/guide`);
                } else {
                    setWarning(false);
                    history.push({pathname: "/join/" + code});
                }
                return;
            }

            const accessibleResult = await checkMemberAccess(courseId.substring(2));
            console.log(accessibleResult)
            if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
                notifyError(USER_ACCESS_POLL_ERROR);
                formatErrorLog("User cannot access the poll");
                return USER_ACCESS_POLL_ERROR;
            }
            setWarning(false);
            history.push({pathname: "/join/" + code});
        } else { // no need to check NAME_REQUIRED or LOGGED_IN as the viewer must be logged in to be able to access this page
            setWarning(false);
            history.push({pathname: "/join/" + code});
        }
    }

    const handleJoinQR = () => {
        history.push("/scan");
    };

    return (
        <div className={stylesDashboard.panelContainer} style={{height: "37%", marginTop: "30px", marginBottom: "30px"}}>
            <div tabIndex={0} className={stylesDashboard.panelTitle} role={TEXTBOX}>
                <JoinUsingCodeIcon style={{marginRight: "10px"}}/> {isMobile ? JOIN_USING_CODE_QR_TITLE : JOIN_USING_CODE_TITLE}
            </div>
            <div className={styles.panelBodyJoinPoll}>
                <div>
                    <JoinIcon className={styles.joinPollIcon}/>
                </div>
                <div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", maxWidth: "400px"}}>
                        <div tabIndex={0}>Enter the activity code below:</div>
                        <div className={styles.pollInputContainer}>
                            <YuJaTextBox id="poll-code-input-0" label={"Text box 1 for the poll code"}  value={pollCode[0]} containerStyle={{fontSize: 20, padding: "12px 5px"}} inputAlign={"center"} inputMode="numeric" maxLength="1" useValue = {true} onKeyUp={(e) => {handleChange(0, e)}} onChange={(e) => {handleClick(0, e)}} onPaste={e => handlePaste(0, e)}/>
                            <YuJaTextBox id="poll-code-input-1" label={"Text box 2 for the poll code"} value={pollCode[1]} containerStyle={{fontSize: 20, padding: "12px 5px"}} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} onKeyUp={(e) => {handleChange(1, e)}} onChange={(e) => {handleClick(1, e)}} onPaste={e => handlePaste(1, e)}/>
                            <YuJaTextBox id="poll-code-input-2" label={"Text box 3 for the poll code"} value={pollCode[2]} containerStyle={{fontSize: 20, padding: "12px 5px"}} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} onKeyUp={(e) => {handleChange(2, e)}} onChange={(e) => {handleClick(2, e)}} onPaste={e => handlePaste(2, e)}/>
                            <YuJaTextBox id="poll-code-input-3" label={"Text box 4 for the poll code"} value={pollCode[3]} containerStyle={{fontSize: 20, padding: "12px 5px"}} inputAlign={"center"} inputMode="numeric" maxLength="1"  useValue = {true} onKeyUp={(e) => {handleChange(3, e)}} onChange={(e) => {handleClick(3, e)}} onPaste={e => handlePaste(3, e)}/>
                            <YuJaTextBox id="poll-code-input-4" label={"Text box 5 for the poll code"} value={pollCode[4]} containerStyle={{fontSize: 20, padding: "12px 5px"}} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} onKeyUp={(e) => {handleChange(4, e)}} onChange={(e) => {handleClick(4, e)}} onPaste={e => handlePaste(4, e)}/>
                            <YuJaTextBox id="poll-code-input-5" label={"Text box 6 for the poll code"} value={pollCode[5]} containerStyle={{fontSize: 20, padding: "12px 5px"}} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} onKeyUp={(e) => {handleChange(5, e)}} onChange={(e) => {handleClick(5, e)}} onPaste={e => handlePaste(5, e)}/>
                        </div>
                        <GradientButton onClick={handleSubmit}>
                            <p className={styles.joinPollButton}>Join Activity</p>
                        </GradientButton>
                        {
                            isMobile && <><div className="or-line" style={{marginTop: "5%", marginBottom: "5%"}}>
                            OR
                          </div>
                          <GradientButton onClick={handleJoinQR}>
                            <p className={styles.joinPollButton}>{SCAN_QR}</p>
                         </GradientButton>
                          </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
