import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { browserName, fullBrowserVersion, isAndroid, osName, osVersion } from 'react-device-detect';
import { useWindowDimensions } from "react-native-web";
import { useHistory, useParams } from "react-router-dom";
import { CourseApiCalls } from '../../apis/CourseApiCalls';
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { Spin } from "../../components/standardization/YuJaLoading";
import { useJoinQuizWebsocketManager } from "../../hooks/useJoinQuizWebsocketManager";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as NoResult } from "../../images/NoActivityFound.svg";
import { YuJaGlobalState } from "../../utils/LoadingContext";
import {
    ANONYMOUS_JOIN_SETTING_TEXT,
    AUDIENCE_RESTRICTION,
    DEPRECATED_POLL_MESSAGE,
    DEPRECATED_POLL_TITLE,
    EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE,
    HOME_PATH,
    INVALID_POLL_CODE_ERROR,
    JOIN_ATTENDANCE,
    JOIN_POLL,
    JOIN_POLL_SURVEY_ERROR,
    LOG_TYPES,
    PLATFORM_TYPES,
    POLL_DISABLE_ANONYMOUS_INFO,
    POLL_DISABLE_GUEST_INFO,
    POLL_SHARE_MODE,
    POLL_STATE,
    POLL_TYPE,
    VIEWER_USER_TYPE,
    WEBSOCKET_MESSAGE_POST_HEARTBEAT_ACTION,
    WEBSOCKET_POST_HEARTBEAT_INTERVAL,
    WEBSOCKET_STUDENT
} from "../../utils/constants";
import {
    formatErrorLog,
    getInstitutionId,
    newPageLog,
    notifyError,
    notifyInfo,
    removePrefix,
    updateLogAttributes,
    updateLogger
} from "../../utils/helpers";
import { WSS_BASE_URL } from "../../utils/properties";
import { USER_ACCESS_POLL_INFO } from "../../utils/toast-message-constants";
import JoinQuizPageQRNew from "./JoinQuizPageQRNew";

export default function JoinQuizParentNew({ setCountdownContent, setPageIdentifier }) {
    const windowSize = useWindowDimensions();
    const { pollUniqueCode } = useParams();
    const [param, setParam] = useState({});
    const {setMissedResponseMap, missedResponseMap, isCookieEnabled, missedReactionMap, setMissedReactionMap} = useContext(YuJaGlobalState);
    const { getPollRecordFromUniqueCode, getPollQuestions, getAudienceResponseByUser, reattemptPoll, handleSubmitIndividualResponse, handleSubmitMultiResponse, updateReaction } = ResponseApiCalls();
    const [visibility, setVisibility] = useState(null);
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);
    const [, getSession] = useLocalStorageNew("session", {});
    const [, getHostResource] = useLocalStorageNew(window.location.hostname, {});
    const [setGuestSession, getGuestSession] = useLocalStorageNew("guestSession", {});
    const [setMissedResponseStorage, getMissedResponseStorage] = useLocalStorageNew("missedResponseMap", {});
    const [setMissedReactionMapStorage, getMissedReactionMapStorage] = useLocalStorageNew("missedReactionMap", {});
    const [, getWarning] = useLocalStorageNew("warning", {});
    const [loadingDelay, setLoadingDelay] = useState(false);

    const pollInfoRef = useRef({});
    const completedResponsesRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [ pollClosed, setPollClosed] = useState(null);
    const history = useHistory();

    const { checkMemberAccess } = CourseApiCalls();
    const {getSetting} = InstitutionApiCalls();
    const session = getSession();

    const gradedLink = session.gradedLink;
    const [authenticationPassed, setAuthenticationPassed] = useState(false);
    const [noActivityFound, setNoActivityFound] = useState(false);

    const getWebSocketUrl = () => {
        if (!Object.entries(pollInfoRef.current).length) {
            return '';
        }

        const {pk} = pollInfoRef.current;
        const pollKey = removePrefix(pk);

        let browserType = browserName + " " + fullBrowserVersion;
        let platform = "";
        if(isAndroid) {
            platform = PLATFORM_TYPES.Android;
        } else {
            platform = osName + " " + osVersion;
        }
        let sessionFirstName = getSession().firstName ? getSession().firstName : "";
        let sessionLastName = getSession().lastName ? getSession().lastName : "";
        let sessionUserName = getSession().userName ? getSession().userName : getGuestSession()["$"+pollUniqueCode]?.userName;
        let sessionUserId = getSession().userId ? getSession().userId : getGuestSession()["$"+pollUniqueCode]?.userId;
        if(!!sessionFirstName && !!sessionLastName) {
            sessionUserName = sessionFirstName + " " + sessionLastName;
        }
        if(getGuestSession()["$"+pollUniqueCode]?.userType === VIEWER_USER_TYPE.AUTO_GUEST) {
            sessionUserName = "Guest";
        }
        let institutionId = getHostResource().institutionId;
        let url;
        if(sessionUserId !== "") {
            url = `${WSS_BASE_URL}?pollKey=${pollKey}&pollCode=${pollUniqueCode}&userName=${sessionUserName}&userId=${sessionUserId}&role=${WEBSOCKET_STUDENT}&browserType=${browserType}&platform=${platform}&institutionId=${institutionId}`;
        } else {
            url = `${WSS_BASE_URL}?pollKey=${pollKey}&pollCode=${pollUniqueCode}&userName=${sessionUserName}&role=${WEBSOCKET_STUDENT}&browserType=${browserType}&platform=${platform}&institutionId=${institutionId}`;
        }

        return url;
    }


    const handleAfterWebsocketOpen = async () => {
        //1. resubmit missedResponses if require
        const missedResponseMapVal = isCookieEnabled ? getMissedResponseStorage() : missedResponseMap;
        if (!!missedResponseMapVal[pollUniqueCode] && !!Object.entries(missedResponseMapVal[pollUniqueCode]).length) {
            const {pollCode, pollKey, pollType, gradeEnabled, pollShareMode, response, syncLms}= missedResponseMapVal[pollUniqueCode];
            //share-each
            if (pollShareMode === POLL_SHARE_MODE.SHARE_EACH) {
                await handleSubmitIndividualResponse({pollKey, pollType, uniqueCode: pollCode, syncLms}, gradeEnabled, response);
                const copy = JSON.parse(JSON.stringify(missedResponseMapVal));
                delete copy[pollUniqueCode];
                if (isCookieEnabled) {
                    setMissedResponseStorage(copy);
                } else {
                    setMissedResponseMap(copy);
                }
            } else if (pollShareMode === POLL_SHARE_MODE.SHARE_ALL
                || pollShareMode === POLL_SHARE_MODE.SCHEDULE) { //share-all or schedule
                const {curQuestionNo, questions, responses, ...pollInfo} = missedResponseMapVal[pollUniqueCode];
                await handleSubmitMultiResponse(pollInfo, curQuestionNo, questions, responses)
                const copy = JSON.parse(JSON.stringify(missedResponseMapVal));
                delete copy[pollUniqueCode];
                if (isCookieEnabled) {
                    setMissedResponseStorage(copy);
                } else {
                    setMissedResponseMap(copy);
                }
            }
        }

        //2. re-update reaction if require
        const missedReactionMapVal = isCookieEnabled ? getMissedReactionMapStorage() : missedReactionMap;
        if (!!missedReactionMapVal[pollUniqueCode] && !!Object.entries(missedReactionMapVal[pollUniqueCode]).length) {
            const { pollKey, reactionMap, attemptsCount, currSerialNo}= missedReactionMapVal[pollUniqueCode];
            await updateReaction(pollKey, pollUniqueCode, reactionMap, attemptsCount, currSerialNo);
            const copy = JSON.parse(JSON.stringify(missedReactionMapVal));
            delete copy[pollUniqueCode];
            if (isCookieEnabled) {
                setMissedReactionMapStorage(copy);
            } else {
                setMissedReactionMap(copy);
            }
        }

        //3. re-get the poll information if poll is not ended
        if (pollClosed) {
            const {poll={}} = await getPollRecordFromUniqueCode(pollUniqueCode);

            //1. backward data compatible
            if (!!poll.uniqueCodeExpTime && Date.now() > poll.uniqueCodeExpTime) {
                return;
            }

            //2. poll is end
            let pollEnd = poll.liveQuestionTime + (poll.pollTimeLimit * 1000) + parseInt(getTimeOffset());
            if((poll.pollState === POLL_STATE.SHARED_ALL && pollEnd <= Date.now())
                || poll.pollState === POLL_STATE.STOPPED_ALL
            ) {
                return;
            }
        }
        joinPoll(1);
    }

    const {websocketMsg, websocketState, buildConnection, closeConnection, sendMsg} = useJoinQuizWebsocketManager(getWebSocketUrl, handleAfterWebsocketOpen);

    const Authentication = async (reauthenticate=false) => {
        const result = await getPollRecordFromUniqueCode(pollUniqueCode);
        if(!result || !result.poll) {
            notifyError(INVALID_POLL_CODE_ERROR);
            setNoActivityFound(true);
            return false;
        }

        pollInfoRef.current = result.poll ? result.poll : {};
        const { audienceRestriction = null, index2Pk: courseId, lmsHomeUrl, syncLms = true } = result.poll;
        if (audienceRestriction === AUDIENCE_RESTRICTION.UNRESTRICTED) { // no need to authenticate
            return true;
        }

        if (audienceRestriction ? audienceRestriction === AUDIENCE_RESTRICTION.REGISTERED : !!courseId) { // viewer has to be enrolled in the course
            //check if the poll is an LMS embedded poll and the viewer has been logged in from LMS
            if (!!lmsHomeUrl) {
                if (!gradedLink && syncLms) {
                    formatErrorLog(EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE);
                    history.push(`/lti/${pollUniqueCode}/guide`);
                    return false;
                }
                return true;
            }


            const accessibleResult = await checkMemberAccess(courseId.substring(2));
            if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
                notifyInfo(USER_ACCESS_POLL_INFO, reauthenticate);
                if (session.authorization && session.userId) {
                    history.push(HOME_PATH);
                } else {
                    history.push({
                        pathname: `/qr/${pollUniqueCode}`, 
                        state: { reauthenticate: reauthenticate }
                    });
                }
                return false;
            }
        }

        if (audienceRestriction === AUDIENCE_RESTRICTION.LOGGED_IN && !session.authorization) { // viewer has to login
            notifyInfo(POLL_DISABLE_GUEST_INFO, reauthenticate);
            history.push({
                pathname: `/qr/${pollUniqueCode}`, 
                state: { reauthenticate: reauthenticate }
            });
            return false;
        }

        if ((audienceRestriction ?
                audienceRestriction === AUDIENCE_RESTRICTION.NAME_REQUIRED :
                (pollInfoRef.current.hasOwnProperty(ANONYMOUS_JOIN_SETTING_TEXT) && pollInfoRef.current[ANONYMOUS_JOIN_SETTING_TEXT] === true)) &&
            (!getSession().userId && !getGuestSession()["$"+pollUniqueCode]?.userName)
        ) { // viewer has to provide name
            notifyInfo(POLL_DISABLE_ANONYMOUS_INFO, reauthenticate);
            history.push({
                pathname: `/qr/${pollUniqueCode}`, 
                state: { reauthenticate: reauthenticate }
            });
            return false;
        }

        return true;
    }

    const joinPoll = async (attemptsCount, isReset=false) => {
        const now = new Date();
        const result = await getPollQuestions(pollUniqueCode, now);
        if (!result || !Object.entries(result).length) {
            return;
        }


        const {questionAnswer, userResponse, startTime, endTime} = result;
        const obj = { ...result };
        // console.log(obj.pollType);
        if (obj.pollType === POLL_TYPE.ATTENDANCE) {
            // console.log("attendance");
            // console.log(obj.geofence, obj.syncLms);
            obj.geofence = !!obj.geofence ? JSON.parse(obj.geofence) : {};
            obj.hasGeofence = obj.geofence && obj.geofence.hasOwnProperty("lat") && obj.geofence.hasOwnProperty("lng") && obj.geofence.hasOwnProperty("rad");
            // console.log(obj.geofence, obj.hasGeofence);
        }
        obj.questions.sort(function (a, b) {
            return a.serialNo - b.serialNo;
        });
        if (!isReset) {
            obj.liveQuestionTime = result.liveQuestionTime + parseInt(getTimeOffset());
        }
        obj.liveQuestionResponse = questionAnswer;
        if (!!startTime) {
            obj.startTime = result.startTime;
        }
        if (!!endTime) {
            obj.endTime = result.endTime;
        }
        // if(obj.userType === VIEWER_USER_TYPE.MANUAL_GUEST) {
        obj.userName = obj.userId;
        // }

        let curQuestionNo;
        if (!!userResponse) {
            updateLogAttributes(userResponse);
            if (!isNaN(userResponse?.curQuestionNo)) curQuestionNo = parseInt(userResponse.curQuestionNo);
            if (!isNaN(userResponse?.attemptsCount) && attemptsCount === 1) attemptsCount = parseInt(userResponse.attemptsCount);
            obj.pollJoinTime = userResponse.pollJoinTime;
        }

        let questionNo;
        if (isNaN(curQuestionNo)) {
            questionNo = !!obj.liveQuestionResponse.length ? obj.liveQuestionResponse.length - 1 : 0;
        } else {
            questionNo = !!curQuestionNo ? curQuestionNo - 1 : 0;
        }
        obj.attemptsCount = attemptsCount;
        obj.pollQuestionIdx = questionNo;
        obj.institutionId = getInstitutionId(result.index5Pk);
        // console.log(obj);
        setParam(obj);
        if (pollClosed) {
            setPollClosed(false);
        }
        const guestSession = getGuestSession();
        guestSession["$"+pollUniqueCode] = {
            ...guestSession["$"+pollUniqueCode],
            GuestId: obj.userName
        };
        setGuestSession(guestSession);

        updateLogger(LOG_TYPES.INFO + "User " + obj.userId + " has joined poll " + pollUniqueCode);
    };

    const handleReattempt = async () => {
        setLoading(true);
        const reattemptResult = await reattemptPoll(param.institutionId, pollUniqueCode, getSession().userId, param.recordAttempt, new Date());
        await joinPoll(reattemptResult.Restart ? 1 : param.attemptsCount + 1);
        setLoading(false);
    };

    const handleReset = async () => {
        const res = await Authentication(true); // Reauthenticate to ensure the user still has access to the poll
        if (res) {
            setAuthenticationPassed(true);
        } else {
            setAuthenticationPassed(false);
            return;
        }

        await joinPoll(param.attemptsCount, true);
    };

    const handleVisibilityChange = useCallback(() => {
        console.log(document.visibilityState);
        if (document.visibilityState === "hidden") {
            setVisibility(false);
        } else if (document.visibilityState === "visible") {
            setVisibility(true);
        }
    }, []);

    useEffect(() => {
        setLoadingDelay(true);
        newPageLog("JoinQuizParent.js");
        setTimeout(() => setLoadingDelay(false), 2000);
    }, []);


    //===========================================step 2. join poll=======================================
    useEffect(() => {
        if (pollClosed === null) {
            return;
        }

        if (pollClosed) {
            setParam({
                pollState: POLL_STATE.CLOSED,
                pollTitle: pollInfoRef.current?.pollTitle,
                pollType: pollInfoRef.current?.pollType
            });
            if (websocketState !== WebSocket.OPEN || websocketState !== WebSocket.CONNECTING) {
                buildConnection();
            }

            setLoading(false);
        } else {
            joinPoll(1).then(() => {
                buildConnection();
                setLoading(false);
            })
        }
    }, [pollClosed]);
    //===========================================step 2. join poll end=======================================

    useEffect(() => {
        window.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            window.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    useEffect(() => {
        const handleWindowResize = () => {
            setPageIdentifier(pollInfoRef.current?.pollType === POLL_TYPE.ATTENDANCE ? JOIN_ATTENDANCE : JOIN_POLL);
        };

        handleWindowResize();
    }, [windowSize]);

    useEffect(() => {
        const interval = setInterval( () => {
            const {pk} = pollInfoRef.current;
           if (websocketState === WebSocket.OPEN) {
               sendMsg({action: WEBSOCKET_MESSAGE_POST_HEARTBEAT_ACTION, pollCode: pollUniqueCode, pollKey: removePrefix(pk)});
           }
        }, WEBSOCKET_POST_HEARTBEAT_INTERVAL);

        return () => clearInterval(interval);
    }, [websocketState]);


    //===========================================step 1. start verification=======================================
    useEffect(async () => {
        //1. authenticate
        const Authenticated  = getGuestSession()["$"+pollUniqueCode]?.Authenticated;
        if (!Authenticated) {
           const res = await Authentication();
           if (res) {
               //authentication passed
               const guestsession = getGuestSession();
               guestsession["$"+pollUniqueCode] = {
                   ...guestsession["$"+pollUniqueCode],
                   Authenticated: "true"
               };
               setGuestSession(guestsession);
               setAuthenticationPassed(true);
               if(window.navigator.cookieEnabled) {
                   localStorage.removeItem("warning");
               }
           } else {
               return;
           }
        } else {
            setAuthenticationPassed(true);
        }

        //2. check if viewer has taken the poll
        if (!Object.entries(pollInfoRef.current).length) {
            const pollData = await getPollRecordFromUniqueCode(pollUniqueCode);
            pollInfoRef.current = pollData.poll ? pollData.poll : {};
        }
        const poll = pollInfoRef.current;
        if (!poll?.pollType || poll.pollType === POLL_TYPE.GRADED_POLL || poll.pollType === POLL_TYPE.SURVEY) { // cannot join graded poll or survey
            if (window.location === window.parent.location) { // not embedded
                notifyError(JOIN_POLL_SURVEY_ERROR);
                history.push("/");
                return;
            }
            // embedded
            history.push("/disabled/poll-survey", { fromJoinPage: true });
            return;
        }

        let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+pollUniqueCode]?.userId;
        let {data=[]} = await getAudienceResponseByUser(sessionUserName, pollUniqueCode);
        completedResponsesRef.current = data;
        // data = data.filter(response => (response.uniqueCodePK === "UC#" + pollUniqueCode));
        let userTakenPollTemp = false;
        if(data.length !== 0) {
            userTakenPollTemp = true;
        }

        setPageIdentifier(poll.pollType === POLL_TYPE.ATTENDANCE ? JOIN_ATTENDANCE : JOIN_POLL);

        if (!!poll.uniqueCodeExpTime && Date.now() > poll.uniqueCodeExpTime) {//backward data compatible
            setPollClosed(true);
        } else if(!userTakenPollTemp) {// viewer hasn't taken the poll
            let pollEnd = poll.liveQuestionTime + (poll.pollTimeLimit * 1000);
            if((poll.pollState === POLL_STATE.SHARED_ALL && pollEnd <= Date.now())
                || poll.pollState === POLL_STATE.STOPPED_ALL
            ) {
                setPollClosed(true);
            } else if (poll.pollState === POLL_STATE.SCHEDULED && new Date(poll.endTime).getTime() + parseInt(getTimeOffset()) <= Date.now()) {
                setPollClosed(true);
            }else {
                setPollClosed(false);
            }
        } else {
            setPollClosed(false);
        }
    }, []);
    //===========================================step 1.verification end=======================================



    useEffect(() => {
        return () => {
            closeConnection();
        }
    }, []);





    return (
        <Spin size="large" wrapperClassName="globalSpin" spinning={false} tip="Loading...">
            {authenticationPassed &&
                <JoinQuizPageQRNew
                    param={param}
                    websocketState={websocketState}
                    websocketMsg={websocketMsg}
                    handleReattempt={handleReattempt}
                    handleReset={handleReset}
                    setPollClosed={setPollClosed}
                    visibility={visibility}
                    loadingDelay={loadingDelay}
                    setCountdownContent={setCountdownContent}
                />
            }
            {noActivityFound && 
                <div className="col-md-12" style={{ height: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ marginTop: "90px", height: "631px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <NoResult />
                        <div style={{ fontSize: "20px", marginTop: "50px" }}>
                            {DEPRECATED_POLL_TITLE}
                        </div>
                        <h4 className="page-sub-title" style={{ color: "#666666", fontSize: "15px", marginTop: "25px" }}>
                            {DEPRECATED_POLL_MESSAGE}
                        </h4>
                    </div>
                </div>
            }
        </Spin>
    );

}
