import {
    AUTHORIZATION_TYPE,
    ERROR_CODES,
    HTTP_STATUS,
    NETWORK_ISSUE,
    UNAUTHORIZED_ERROR
} from "../utils/constants";
import { formatAPILog, notifyError } from "../utils/helpers";
import { useLocalStorageNew } from "./useLocalStorageNew";
import { YuJaGlobalState } from "../utils/LoadingContext";
import { useContext } from "react";
import useQuery from "../utils/queryparam";
import {usePPTWebView} from "./usePPTWebView";

export const useFetchWrapper = () => {
    const[setSession, getSession] = useLocalStorageNew("session", {});
    const[setTimeOffset, ] = useLocalStorageNew("timeOffset", 0);
    const {isPPTPage, getToken} = usePPTWebView();
    const query = useQuery();
    const {setIsTokenExpiredModalShowUp} = useContext(YuJaGlobalState);


    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    };

    function request(method) {
        return async ({url, body = {}, authorizeRequired = true, isJsonContent=true, headers = {}, token = '', silent=false}) => {
            formatAPILog(url, body);
            const requestOptions = {
                headers: authHeader(headers, authorizeRequired, isJsonContent, token),
                method
            };
            if (isJsonContent) {
                if (Object.entries(body).length > 0) {
                    requestOptions.body = JSON.stringify(body);
                }
            } else {
                requestOptions.body = body;
            }
            let response
            try {
                response = await fetch(url, requestOptions);
            } catch (e) {
                console.log(e);
                throw Error(NETWORK_ISSUE);
            }

            return handleResponse(response, silent);

        }
    }

    // helper functions
    function authHeader(headers, authorizeRequired, isJsonContent, token) {
        // return auth header with jwt if user is logged in and request is to the api url
        let combinedHeaders;
        if (isJsonContent) {
            combinedHeaders = Object.assign(headers, {"Content-Type": "application/json"});
        } else {
            combinedHeaders = {...headers};
        }

        const session = getSession();

        if (!token) {
            token = isPPTPage() ? getToken() : session.authorization;
        }
        const isLoggedIn = !!token;
        if (isLoggedIn && authorizeRequired) {
            combinedHeaders.Authorization = `${AUTHORIZATION_TYPE} ${token}`;
        }

        return combinedHeaders;
    }

    function handleResponse(response, silent) {
        const responseDateStr = response.headers.get('date');
        if (responseDateStr) {
            const serverTimeMillisGMT = Date.parse(new Date(Date.parse(responseDateStr)).toUTCString());
            const localMillisUTC = Date.parse(new Date().toUTCString());
            const offset = localMillisUTC - serverTimeMillisGMT;
            setTimeOffset(offset);
        }

        if(response.headers.get('Content-Type').includes("text/html")) {
            return response.text();
        }

        return response.json().then(async resultBody => {
            if (response.ok) {
                return resultBody;
            }

            console.log(JSON.stringify(resultBody));
            if(resultBody.code && resultBody.code === ERROR_CODES.EXPIRED_SESSION) {
                setIsTokenExpiredModalShowUp(true);
                return;
            }

            else if (HTTP_STATUS.UNAUTHORIZED === response.status || HTTP_STATUS.AUTHORIZE_DENY === response.status) {
                if (!silent) notifyError(UNAUTHORIZED_ERROR);
                return resultBody;
            }

            throw Error(JSON.stringify(resultBody));
        });
    }
}