import React from "react";
import "./addButton.css";

export default function AddButton({ text, onClick, floatVal }) {
  return (
    <div style={{
      position: 'absolute', left: '50%',
      transform: 'translate(-50%, 50%)',
      float : floatVal
  }}>
      <button id="add-button-container" title={`${text}`} onClick={onClick} style={{ float : floatVal, padding : "7.5px"}}>
        <div id="add-button-text">+ {text}</div>
      </button>
    </div>
  );
}
