import React, { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { PollApiCalls } from "../../apis/PollApiCalls";
import PublishModal from "../CreateQuizPage/PublishModal";
import { isBefore, removePrefix } from "../../utils/helpers";
import styles from "./ManageCoursePolls.module.css";
import { DEFAULT_ROWS_PER_PAGE } from "../../utils/constants";
import { DELETE_CONFIRMATION } from "../../utils/constants";

export default React.memo(PollTable);
function PollTable({ data = [], columns = [], globalFilterObj = {}, getPollInformation }) {

    const history = useHistory();
    const [publishedPoll, setPublishedPol] = useState({});
    const [publishModalShow, setPublishModalShow] = useState(false);
    const { publishPoll, softDeletePoll } = PollApiCalls();

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredStartDate = globalFilterValue.startDate;
        const filteredEndDate = globalFilterValue.endDate;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            // if (values.courseCode && values.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
            //     return true;
            // }

            return false;
        }).filter(r => {
            if (!filteredStartDate && !filteredEndDate) {
                return true;
            }
            if (!filteredStartDate) {
                return isBefore(r.values.updatedDatePK, filteredEndDate);
            }
            if (!filteredEndDate) {
                return isBefore(filteredStartDate, r.values.updatedDatePK);
            }
            const values = r.values;
            return values.updatedDatePK && isBefore(filteredStartDate, r.values.updatedDatePK) && isBefore(r.values.updatedDatePK, filteredEndDate);
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [globalFilterObj, setGlobalFilter])

    const onGo = async (pollKey) => {
        let key = removePrefix(pollKey);
        const res = await publishPoll({ 'pollKey': key });
        if (res) {
            const { uniqueCode, expirationTime } = res;
            const trimmedUniqueCode = removePrefix(uniqueCode);
            setPublishedPol({
                uniqueCode: trimmedUniqueCode,
                expirationTime: expirationTime,
                pollKey: key
            });
            setPublishModalShow(true);
        }
        // let path = '/poll/' + key + "/" + removePrefix(res.uniqueCode);
        // history.push({ pathname: path });
    }

    const onView = (pollKey, uniqueCode) => {
        let key = removePrefix(pollKey);
        let pollCode = uniqueCode.substring(7);
        history.push(`/poll/published/${key}/${pollCode}`);
    }
    const clickOkay = () => {
        history.push(`/poll/share/${publishedPoll.pollKey}/${publishedPoll.uniqueCode}`);
    }

    const onDelete = async (pollKey) => {
        let key = removePrefix(pollKey);
        console.log("DELETE POLL")
        if (!window.confirm(DELETE_CONFIRMATION)) {
            return;
        }
        const res = await softDeletePoll(key);
        if (res.success) {
            getPollInformation();
        }
    }

    const onEdit = (pollKey) => {
        console.log("EDIT POLL")
        console.log(pollKey)
        history.push({ pathname: "/edit/" + removePrefix(pollKey) });
    }

    const handleClick = (index) => {
        document.getElementById(`menu-${index}`).style.display = "none";
        document.getElementById(`options-${index}`).style.display = "inline";
    }

    return (
        <>
            <PublishModal
                show={publishModalShow}
                setModalShow={setPublishModalShow}
                pollCode={publishedPoll}
                // old design
                handleClickOkay={clickOkay}
                // old design
                isGenerated={true}
            />

            <div className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }
                                    })}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.Header === 'Action') {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div className={`${styles.tableBtn} ${styles.tableMenuBtn}`} id={`menu-${index}`}
                                                        onClick={() => {handleClick(index);}} />
                                                    <div id={`options-${index}`} style={{display:"none"}}>
                                                        {cell.row.values['pollSortKey'] === 'Draft' ?
                                                            <div className={`${styles.tableBtn} ${styles.tableEditBtn}`}
                                                                onClick={() => {onEdit(cell.value);}} /> : <div></div>}
                                                        {cell.row.values['pollSortKey'] === 'Draft' ?
                                                            <div className={`${styles.tableBtn} ${styles.tableDeleteBtn}`}
                                                                onClick={() => {onDelete(cell.value);}} /> : <div></div>}
                                                        {cell.row.values['pollSortKey'] === 'Draft' ?
                                                            <div className={`${styles.tableBtn} ${styles.tablePublishBtn}`}
                                                                onClick={() => {onGo(cell.value);}} /> :
                                                                <div className={`${styles.tableBtn} ${styles.tableViewBtn}`}
                                                                    onClick={() => {onView(cell.value, cell.row.values['pollSortKey'])}} />}
                                                    </div>
                                                </td>
                                            );
                                        } else if (cell.column.Header === 'Poll Status') {
                                            return (

                                                // Uncomment below lins to show poll code
                                                // <td {...cell.getCellProps()}>
                                                //      {cell.value.startsWith('PUB#UC#') ? cell.value.replace('PUB#UC#', '') : cell.value}
                                                //  </td>
                                                <td {...cell.getCellProps()}>
                                                    {cell.value.startsWith('PUB#UC#') ? "Published" : cell.value}
                                                </td>

                                            );
                                        } else {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.value}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="5" className={styles.noData}>No Results Found</td></tr>}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                    <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                    {pageOptions.map((idx, i) => {
                        return (
                            <Pagination.Item
                                key={i}
                                active={pageIndex === idx}
                                onClick={() => gotoPage(idx)}>
                                {idx + 1}
                            </Pagination.Item>
                        );
                    })}
                    <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                    <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                </Pagination>

            </div>
        </>
    );
}