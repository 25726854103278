import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import GradientButton from "../../public/buttons/GradientButton";
import { GRADED_POLL, PERCENTAGE_LABEL, POLL_TITLE_LABEL, POLL_TYPE, TEXTBOX, VIEW_RESULTS_LABEL } from "../../utils/constants";
import { ReactComponent as RecentResultIcon } from "../DashboardPage/images/gradient_table_icon.svg";
import useMobileAccess from './../../hooks/useMobileAccess';
import stylesDashboard from "./../DashboardPage/DashboardPage.module.css";
import styles from "./Home.module.css";
import { ReactComponent as NoRecentResults } from "./images/no_recent_results_icon.svg";


export default function StudentRecentResults({data=[], loading=true, initialized=false}) {
    const history = useHistory();
    const isMobile = useMobileAccess(950);

    const viewResult = (poll) => {
        history.push({
            pathname: "/student/polls",
            state : {
                pollKey : poll.pk.substring(2),
                uniqueCode: poll.index5Sk.substring(3),
                pollType: poll.pollType === POLL_TYPE.ATTENDANCE ? POLL_TYPE.ATTENDANCE : GRADED_POLL,
            }
        });
    }

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);


    return (
        <div className={stylesDashboard.panelContainer} style={{height: "61%", marginBottom: "30px"}}>
            <div tabIndex={0} className={stylesDashboard.panelTitle} role={TEXTBOX}>
                <RecentResultIcon style={{marginRight: "10px"}}/> Recent Results
            </div>
            {!loading ?
            <div className={styles.resultsBody}>
                {/* <Spin size={isMobile? "medium" : "large"} wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}> */}
                    {initialized && (!data || !data.length) &&
                        <div>
                            <NoRecentResults/>
                            <p className={styles.noRecentResultsTitle}>No recent results found</p>
                        </div>
                    }
                    {initialized && (!!data && !!data.length) &&
                        <Table responsive={true} className={stylesDashboard.recentResultTable} >
                            <thead>
                                <tr>
                                    <th tabIndex={0} style={{width: "50%", textAlign: "left"}}>Activity Title</th>
                                    <th tabIndex={0} style={{width: "20%"}}>Percentage</th>
                                    <th tabIndex={0} style={{width: "30%"}}>Results</th>
                                </tr>
                            </thead>
                            <tbody >
                            {JSON.parse(JSON.stringify(data)).splice(0, 15).map((poll, index) =>
                                <tr key={index} role={"row"}>
                                    <td tabIndex={0} aria-label={POLL_TITLE_LABEL + poll.pollTitle} className={stylesDashboard.omitableText} style={{textAlign: "left"}}>{poll.pollTitle}</td>
                                    <td tabIndex={0} aria-label={PERCENTAGE_LABEL + (poll.studentResult !== null ? poll.studentResult+"%" : "No score!")} > {poll.studentResult !== null ? poll.studentResult+"%" : "N/A"}</td>
                                    <td >
                                        <GradientButton
                                            tabIndex={0}
                                            label={VIEW_RESULTS_LABEL + poll.pollTitle} 
                                            style={{maxWidth: "60px", margin: "auto", marginTop: "5px", marginBottom: "5px"}}
                                            onClick={() => viewResult(poll)}
                                        >
                                            View
                                        </GradientButton>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    }
                {/* </Spin> */}
            </div>
            : windowSize[0] >= 960 && windowSize[1] <= 780 ? 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "60% 20% 20%"}}> 
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6vw" style = {{position: "relative", marginTop:"40px"}} /> 
            <Skeleton borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"40px"}} />
            <Skeleton borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"40px"}} />
            </div>
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "62% 20.5% 17.5%"}}>
            <Skeleton borderRadius={10} height = {14.56005859375} width = "8vw"  style={{ position: "relative", marginTop:"2.5vh"}}/> 
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}}  />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "6.5vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "6vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            </div>
            </SkeletonTheme>
            : windowSize[0] >= 960 && windowSize[1] <= 860 ? 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "60% 20% 20%"}}> 
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6vw" style = {{position: "relative", marginTop:"40px"}} /> 
            <Skeleton borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"40px"}} />
            <Skeleton borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"40px"}} />
            </div>
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "62% 20.5% 17.5%"}}>
            <Skeleton borderRadius={10} height = {14.56005859375} width = "8vw"  style={{ position: "relative", marginTop:"2.5vh"}}/> 
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}}  />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "6.5vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "6vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            </div>
            </SkeletonTheme>
            
            : <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "60% 20% 20%"}}> 
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6vw" style = {{position: "relative", marginTop:"40px"}} /> 
            <Skeleton borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"40px"}} />
            <Skeleton borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"40px"}} />
            </div>
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "62% 20.5% 17.5%"}}>
            <Skeleton borderRadius={10} height = {14.56005859375} width = "8vw"  style={{ position: "relative", marginTop:"2.5vh"}}/> 
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}}  />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "6.5vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "6vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"2.5vh"}}/>
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            <Skeleton borderRadius={10} height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2.5vh"}} />
            </div>
            </SkeletonTheme>}
        </div>
    );
}
