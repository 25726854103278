import { useState, useEffect } from "react";
import styles from "./IntegrationPageParent.module.css";
import ADFSSamlForm from "./SAML/ADFSSamlForm";
import AzureSamlForm from "./SAML/AzureSamlForm";
import DCAMSamlForm from "./SAML/DCAMSamlForm";
import GoogleSamlForm from "./SAML/GoogleSamlForm";
import ShibbolethSamlForm from "./SAML/ShibbolethSamlForm";
import { YuJaDropdown } from "../../../components/standardization/YuJaDropdown";
import { useLoading } from "../../../utils/LoadingContext";
import {
  INTEGRATION_TYPES,
  CREATION_ROLES,
  LMS_PLACEHOLDER,
  INTEGRATION_TYPES_DETAILS,
  INTEGRATION_TYPES_LOWERCASE,
  TYPE_PLACEHOLDER,
  MENU_LIST,
  TEXTBOX,
  ROLES
} from "../../../utils/constants";
import { InstitutionApiCalls } from "../../../apis/InstitutionApiCalls";
import { PublicApiCall } from "../../../apis/PublicApiCall";
import { ResponseApiCalls } from "../../../apis/ResponseApiCalls";
import YuJaButton from "../../../components/standardization/YuJaButton";
import defaultConfig from "./SSOConfig.json";
import GeneralLTIPage from "./LTI/GeneralLTIPage";
import CrossIntegration from "./CrossIntegration";
import { Tab, Tabs } from "react-bootstrap";
import GeneralLMSAPIPage from "./LTI/GeneralLMSAPIPage";
import PPTInstallGuidePage from "../../PPTPlugin/PPTInstallGuidePage";
import { useLocalStorageNew } from "../../../hooks/useLocalStorageNew";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function IntegrationPageParent({ setPageIdentifier }) {
  const [, getSession] = useLocalStorageNew("session", {});
  const { role = '' } = getSession();

  const { getSSODetails } = InstitutionApiCalls();
  const [ssoResult, setSSOResult] = useState();
  const [dataReady, setDataReady] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(INTEGRATION_TYPES.LTI);
  const [configJson, setConfigJson] = useState("");
  const { setLoading } = useLoading();
  const { saveSSODetails, saveLMSConfig } = InstitutionApiCalls();
  const { getInstitutionDetails } = PublicApiCall();
  const { getShortName } = ResponseApiCalls();
  const [showChromeExts, setShowChromeExts] = useState(false);
  const [ssoURL, setSSOURL] = useState("");
  const [nameIdFormat, setNameIdFormat] = useState("");
  const [remoteLogoutUrl, setRemoteLogouUrl] = useState("");
  const [certificateFingerPrint, setCertificateFingerPrint] = useState("");
  const [logoutUrl, setLogoutUrl] = useState("");
  const [signatureEnabled, setSignatureEnabled] = useState(false);
  const [givenNameAttribute, setGiveNameAttribute] = useState("");
  const [familyNameAttribute, setFamilyNameAttribute] = useState("");
  const [emailAttribute, setEmailAttribute] = useState("");
  const [roleAttribute, setRoleAttribute] = useState("");
  const [itManagerMapping, setITManagerMapping] = useState("");
  const [instructorMapping, setInstructorMapping] = useState("");
  const [linkageAttribute, setLinkageAttribute] = useState("");
  const [fallbackToEmail, setFallbackToEmail] = useState(true);
  const [selectedMenuLTI, setSelectedMenuLTI] = useState("Canvas");
  const [blackboardForm, setBlackboardForm] = useState({
    studentRole: "",
    instructorRole: "",
    graderRole: "",
    courseBuilderRole: "",
    teachingAssistantRole: "",
    guestRole: "",
    adminRole: "",
    otherRole: "",
    autoProvisionUser: false,
    autoSyncData: false,
    autoSyncCourseData: false,
    autoProvisionCourse: false,
    iss: "",
    clientId: "",
    oidcEndpoint: "",
    jwksEndpoint: "",
    oAuth2Endpoint: "",
    deploymentId: "",
    autoLinkCourse: false,
    ltiCourseLinkageAttri: "",
    autoLinkUser: false,
    ltiUserLinkageAttribute: "",
    domain: "",
    lmsApiLink: "",
    apiAccessToken: "",
    appKey: "",
    appSecret: "",
    clientIDAPI: "",
    fallbackToCourseCodeLms: false
  });

  useEffect(() => {
    setPageIdentifier(MENU_LIST.INTEGRATIONS.name);
  }, []);

  const [canvasForm, setCanvasForm] = useState({
    "adminRole": "",
    "teacherRole": "",
    "teachingAssistantRole": "",
    "designerRole": "",
    "studentRole": "",
    "observerRole": "",
    "autoProvisionUser": false,
    "autoSyncData": false,
    "autoSyncCourseData": false,
    "autoProvisionCourse": false,
    "iss": "",
    "clientId": "",
    "oidcEndpoint": "",
    "jwksEndpoint": "",
    "oAuth2Endpoint": "",
    "deploymentId": "",
    "autoLinkCourse": false,
    "ltiCourseLinkageAttri": "",
    "autoLinkUser": false,
    "ltiUserLinkageAttribute": "",
    domain: "",
    lmsApiLink: "",
    apiAccessToken: "",
    apiCourseLinkageAttr: "",
    apiUserLinkageAttr: "",
    fallbackToCourseCodeLms: false
  });

  const [d2lForm, setD2lForm] = useState({
    studentRole: "",
    instructorRole: "",
    graderRole: "",
    courseBuilderRole: "",
    teachingAssistantRole: "",
    guestRole: "",
    adminRole: "",
    otherRole: "",
    autoProvisionUser: false,
    autoSyncData: false,
    autoSyncCourseData: false,
    autoProvisionCourse: false,
    iss: "",
    clientId: "",
    oidcEndpoint: "",
    jwksEndpoint: "",
    oAuth2Endpoint: "",
    deploymentId: "",
    autoLinkCourse: false,
    ltiCourseLinkageAttri: "",
    autoLinkUser: false,
    ltiUserLinkageAttribute: "",
    domain: "",
    lmsApiLink: "",
    apiAccessToken: "",
    apiCourseLinkageAttr: "",
    apiUserLinkageAttr: "",
    fallbackToCourseCodeLms: false
  });

  const [moodleForm, setMoodleForm] = useState({
    studentRole: "",
    instructorRole: "",
    graderRole: "",
    courseBuilderRole: "",
    teachingAssistantRole: "",
    guestRole: "",
    adminRole: "",
    otherRole: "",
    autoProvisionUser: false,
    autoSyncData: false,
    autoSyncCourseData: false,
    autoProvisionCourse: false,
    iss: "",
    clientId: "",
    oidcEndpoint: "",
    jwksEndpoint: "",
    oAuth2Endpoint: "",
    deploymentId: "",
    autoLinkCourse: false,
    ltiCourseLinkageAttri: "",
    autoLinkUser: false,
    ltiUserLinkageAttribute: "",
    domain: "",
    lmsApiLink: "",
    apiAccessToken: "",
    apiCourseLinkageAttr: "",
    apiUserLinkageAttr: "",
    fallbackToCourseCodeLms: false
  });

  const optionDataLTI = [INTEGRATION_TYPES_LOWERCASE.CANVAS, INTEGRATION_TYPES_LOWERCASE.BLACKBOARD, INTEGRATION_TYPES_LOWERCASE.MOODLE, INTEGRATION_TYPES.D2L];
  const optionDataLMSAPI = [INTEGRATION_TYPES_LOWERCASE.MOODLE, INTEGRATION_TYPES_LOWERCASE.CANVAS, INTEGRATION_TYPES_LOWERCASE.D2L, INTEGRATION_TYPES_LOWERCASE.BLACKBOARD];
  const optionData = [
    { label: INTEGRATION_TYPES_DETAILS.LTI, value: INTEGRATION_TYPES.LTI },
    { label: INTEGRATION_TYPES_DETAILS.ADFS, value: INTEGRATION_TYPES.ADFS },
    { label: INTEGRATION_TYPES_DETAILS.SHIBBOLETH, value: INTEGRATION_TYPES.SHIBBOLETH },
    { label: INTEGRATION_TYPES_DETAILS.DCAM, value: INTEGRATION_TYPES.DCAM },
    { label: INTEGRATION_TYPES_DETAILS.GOOGLE, value: INTEGRATION_TYPES.GOOGLE },
    { label: INTEGRATION_TYPES_DETAILS.AZURE, value: INTEGRATION_TYPES.AZURE },
    { label: INTEGRATION_TYPES_DETAILS.LMS_API, value: INTEGRATION_TYPES.LMS_API },
  ];


  const handleIntegrationSelect = (item) => {
    setSelectedMenu(item.value);
    if (item.value === INTEGRATION_TYPES.LMS_API) {
      setSelectedMenuLTI(INTEGRATION_TYPES_LOWERCASE.MOODLE); // temporary till the time we support API for all the LMS
    } else {
      setSelectedMenuLTI(INTEGRATION_TYPES_LOWERCASE.CANVAS); // temporary till the time we support API for all the LMS
    }
  };

  const getIntegrationType = () => {
    if (selectedMenu === INTEGRATION_TYPES.ADFS) { return INTEGRATION_TYPES.ADFS }
    else if (selectedMenu === INTEGRATION_TYPES.SHIBBOLETH) { return INTEGRATION_TYPES.SHIBBOLETH }
    else if (selectedMenu === INTEGRATION_TYPES.DCAM) { return INTEGRATION_TYPES.DCAM }
    else if (selectedMenu === INTEGRATION_TYPES.GOOGLE) { return INTEGRATION_TYPES.GOOGLE }
    else if (selectedMenu === INTEGRATION_TYPES.AZURE) { return INTEGRATION_TYPES.AZURE }
  };

  const handleLMSChange = (item) => {
    setSelectedMenuLTI(item);
  };

  const handleSave = () => {
    if (configJson === "") {
      setConfigJson(defaultConfig);
    }
    configJson.samlSsoUrl = ssoURL;
    configJson.nameIdFormat = nameIdFormat;
    configJson.remoteLogoutUrl = remoteLogoutUrl;
    configJson.certificateFingerPrint = certificateFingerPrint;
    configJson.logoutUrl = logoutUrl;
    configJson.signatureEnabled = signatureEnabled;
    configJson.givenNameAttribute = givenNameAttribute;
    configJson.familyNameAttribute = familyNameAttribute;
    configJson.emailAttribute = emailAttribute;
    configJson.roleAttribute = roleAttribute;
    configJson.itManagerMapping = itManagerMapping;
    configJson.instructorMapping = instructorMapping;
    configJson.linkageAttribute = linkageAttribute;
    configJson.fallbackToEmail = fallbackToEmail;
    setConfigJson(configJson);
    setLoading(true);
    saveSSODetails(configJson, getIntegrationType());
    setLoading(false);
  };

  const handleSaveBlackboard = async () => {
    if (blackboardForm.adminRole === undefined || blackboardForm.adminRole === "" || !(blackboardForm.adminRole in CREATION_ROLES)) {
      blackboardForm.adminRole = CREATION_ROLES.IT_MANAGER.value;
    }
    if (blackboardForm.instructorRole === undefined || blackboardForm.instructorRole === "" || !(blackboardForm.instructorRole in CREATION_ROLES)) {
      blackboardForm.instructorRole = CREATION_ROLES.CREATOR.value;
    }
    if (blackboardForm.teachingAssistantRole === undefined || blackboardForm.teachingAssistantRole === "" || !(blackboardForm.teachingAssistantRole in CREATION_ROLES)) {
      blackboardForm.teachingAssistantRole = CREATION_ROLES.CREATOR.value;
    }
    if (blackboardForm.graderRole === undefined || blackboardForm.graderRole === "" || !(blackboardForm.graderRole in CREATION_ROLES)) {
      blackboardForm.graderRole = CREATION_ROLES.CREATOR.value;
    }
    if (blackboardForm.courseBuilderRole === undefined || blackboardForm.courseBuilderRole === "" || !(blackboardForm.courseBuilderRole in CREATION_ROLES)) {
      blackboardForm.courseBuilderRole = CREATION_ROLES.CREATOR.value;
    }
    if (blackboardForm.studentRole === undefined || blackboardForm.studentRole === "" || !(blackboardForm.studentRole in CREATION_ROLES)) {
      blackboardForm.studentRole = CREATION_ROLES.VIEWER.value;
    }
    if (blackboardForm.otherRole === undefined || blackboardForm.otherRole === "" || !(blackboardForm.otherRole in CREATION_ROLES)) {
      blackboardForm.otherRole = CREATION_ROLES.VIEWER.value;
    }
    if (blackboardForm.guestRole === undefined || blackboardForm.guestRole === "" || !(blackboardForm.guestRole in CREATION_ROLES)) {
      blackboardForm.guestRole = CREATION_ROLES.VIEWER.value;
    }
    saveLMSConfig(blackboardForm, INTEGRATION_TYPES.BLACKBOARD);
  };

  const handleSaveCanvas = async () => {
    if (canvasForm.adminRole === undefined || canvasForm.adminRole === "" || !(canvasForm.adminRole in CREATION_ROLES)) {
      canvasForm.adminRole = CREATION_ROLES.IT_MANAGER.value;
    }
    if (canvasForm.teacherRole === undefined || canvasForm.teacherRole === "" || !(canvasForm.teacherRole in CREATION_ROLES)) {
      canvasForm.teacherRole = CREATION_ROLES.CREATOR.value;
    }
    if (canvasForm.teachingAssistantRole === undefined || canvasForm.teachingAssistantRole === "" || !(canvasForm.teachingAssistantRole in CREATION_ROLES)) {
      canvasForm.teachingAssistantRole = CREATION_ROLES.CREATOR.value;
    }
    if (canvasForm.designerRole === undefined || canvasForm.designerRole === "" || !(canvasForm.designerRole in CREATION_ROLES)) {
      canvasForm.designerRole = CREATION_ROLES.CREATOR.value;
    }
    if (canvasForm.studentRole === undefined || canvasForm.studentRole === "" || !(canvasForm.studentRole in CREATION_ROLES)) {
      canvasForm.studentRole = CREATION_ROLES.VIEWER.value;
    }
    if (canvasForm.observerRole === undefined || canvasForm.observerRole === "" || !(canvasForm.observerRole in CREATION_ROLES)) {
      canvasForm.observerRole = CREATION_ROLES.VIEWER.value;
    }
    saveLMSConfig(canvasForm, INTEGRATION_TYPES.CANVAS);
  };

  const handleSaveD2L = async () => {
    if (d2lForm.adminRole === undefined || d2lForm.adminRole === "" || !(d2lForm.adminRole in CREATION_ROLES)) {
      d2lForm.adminRole = CREATION_ROLES.IT_MANAGER.value;
    }
    if (d2lForm.instructorRole === undefined || d2lForm.instructorRole === "" || !(d2lForm.instructorRole in CREATION_ROLES)) {
      d2lForm.instructorRole = CREATION_ROLES.CREATOR.value;
    }
    if (d2lForm.studentRole === undefined || d2lForm.studentRole === "" || !(d2lForm.studentRole in CREATION_ROLES)) {
      d2lForm.studentRole = CREATION_ROLES.VIEWER.value;
    }
    saveLMSConfig(d2lForm, INTEGRATION_TYPES.D2L);
  };

  const handleSaveMoodle = async () => {
    if (moodleForm.adminRole === undefined || moodleForm.adminRole === "" || !(moodleForm.adminRole in CREATION_ROLES)) {
      moodleForm.adminRole = CREATION_ROLES.IT_MANAGER.value;
    }
    if (moodleForm.instructorRole === undefined || moodleForm.instructorRole === "" || !(moodleForm.instructorRole in CREATION_ROLES)) {
      moodleForm.instructorRole = CREATION_ROLES.CREATOR.value;
    }
    if (moodleForm.studentRole === undefined || moodleForm.studentRole === "" || !(moodleForm.studentRole in CREATION_ROLES)) {
      moodleForm.studentRole = CREATION_ROLES.VIEWER.value;
    }
    saveLMSConfig(moodleForm, INTEGRATION_TYPES.MOODLE);
  };

  const handleSaveLTI = () => {
    if (selectedMenuLTI === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {
      handleSaveBlackboard();
    }
    else if (selectedMenuLTI === INTEGRATION_TYPES_LOWERCASE.CANVAS) {
      handleSaveCanvas();
    }
    else if (selectedMenuLTI === INTEGRATION_TYPES_LOWERCASE.MOODLE) {
      handleSaveMoodle();
    }
    else if (selectedMenuLTI === INTEGRATION_TYPES.D2L) {
      handleSaveD2L();
    }
  };

  useEffect(() => {
    let shortname = getShortName();
    if (shortname !== "") {
      getInstitutionDetails(shortname).then(res => {
        if (!!res && !!res.showChromeExtensions) {
          setShowChromeExts(res.showChromeExtensions);
        }
      });
      getSSODetails().then(result => {
        setSSOResult(result);
        setDataReady(true);
      });
    }
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.btnCenterAlign}>
        <YuJaButton id="integrationSaveBtn" index={1} onClick={(selectedMenu === INTEGRATION_TYPES.LTI || selectedMenu === INTEGRATION_TYPES.LMS_API) ? handleSaveLTI : handleSave}>Save Changes</YuJaButton>
      </div>
      <div className={styles.body}>
        <div className={styles.titleBox}>
          <div className={styles.titleBoxIcon} />
          <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Integrations</div>
        </div>
        <div className={styles.mainDiv}>
          <Tabs >
            {[ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value].includes(role) &&
              <Tab eventKey="integration" title="Integration">
                <div className={styles.platformTitle}>Choose Integration Type</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <YuJaDropdown
                    containerStyle={{ width: 400 }}
                    data={optionData}
                    value={selectedMenu}
                    placeholder={TYPE_PLACEHOLDER}
                    onChange={handleIntegrationSelect}
                  />
                  {(selectedMenu === INTEGRATION_TYPES.LTI) &&
                    <YuJaDropdown
                      containerStyle={{ width: 400, marginLeft: "15px" }}
                      data={optionDataLTI}
                      value={selectedMenuLTI}
                      placeholder={LMS_PLACEHOLDER}
                      getOptionLabel={item => item}
                      getOptionValue={item => item}
                      onChange={handleLMSChange}
                    />
                  }
                  {(selectedMenu === INTEGRATION_TYPES.LMS_API) &&
                    <YuJaDropdown
                      containerStyle={{ width: 400, marginLeft: "15px" }}
                      data={optionDataLMSAPI}
                      value={selectedMenuLTI}
                      placeholder={LMS_PLACEHOLDER}
                      getOptionLabel={item => item}
                      getOptionValue={item => item}
                      onChange={handleLMSChange}
                    />
                  }
                </div>
                {dataReady &&
                  <div className={styles.fullWidthClass}>
                    {selectedMenu === INTEGRATION_TYPES.ADFS &&
                      <ADFSSamlForm
                        setConfigJson={setConfigJson} handleSave={handleSave}
                        ssoURL={ssoURL} setSSOURL={setSSOURL}
                        nameIdFormat={nameIdFormat} setNameIdFormat={setNameIdFormat}
                        remoteLogoutUrl={remoteLogoutUrl} setRemoteLogouUrl={setRemoteLogouUrl}
                        certificateFingerPrint={certificateFingerPrint}
                        setCertificateFingerPrint={setCertificateFingerPrint}
                        logoutUrl={logoutUrl} setLogoutUrl={setLogoutUrl}
                        setSignatureEnabled={setSignatureEnabled}
                        givenNameAttribute={givenNameAttribute} setGiveNameAttribute={setGiveNameAttribute}
                        familyNameAttribute={familyNameAttribute}
                        setFamilyNameAttribute={setFamilyNameAttribute}
                        emailAttribute={emailAttribute} setEmailAttribute={setEmailAttribute}
                        roleAttribute={roleAttribute} setRoleAttribute={setRoleAttribute}
                        itManagerMapping={itManagerMapping} setITManagerMapping={setITManagerMapping}
                        instructorMapping={instructorMapping} setInstructorMapping={setInstructorMapping}
                        linkageAttribute={linkageAttribute} setLinkageAttribute={setLinkageAttribute}
                        fallbackToEmail={fallbackToEmail} setFallbackToEmail={setFallbackToEmail}
                        ssoResult={ssoResult}
                      />
                    }
                    {selectedMenu === INTEGRATION_TYPES.SHIBBOLETH &&
                      <ShibbolethSamlForm
                        setConfigJson={setConfigJson} handleSave={handleSave}
                        ssoURL={ssoURL} setSSOURL={setSSOURL}
                        nameIdFormat={nameIdFormat} setNameIdFormat={setNameIdFormat}
                        remoteLogoutUrl={remoteLogoutUrl} setRemoteLogouUrl={setRemoteLogouUrl}
                        certificateFingerPrint={certificateFingerPrint}
                        setCertificateFingerPrint={setCertificateFingerPrint}
                        logoutUrl={logoutUrl} setLogoutUrl={setLogoutUrl}
                        setSignatureEnabled={setSignatureEnabled}
                        givenNameAttribute={givenNameAttribute} setGiveNameAttribute={setGiveNameAttribute}
                        familyNameAttribute={familyNameAttribute}
                        setFamilyNameAttribute={setFamilyNameAttribute}
                        emailAttribute={emailAttribute} setEmailAttribute={setEmailAttribute}
                        roleAttribute={roleAttribute} setRoleAttribute={setRoleAttribute}
                        itManagerMapping={itManagerMapping} setITManagerMapping={setITManagerMapping}
                        instructorMapping={instructorMapping} setInstructorMapping={setInstructorMapping}
                        linkageAttribute={linkageAttribute} setLinkageAttribute={setLinkageAttribute}
                        fallbackToEmail={fallbackToEmail} setFallbackToEmail={setFallbackToEmail}
                      />
                    }
                    {selectedMenu === INTEGRATION_TYPES.DCAM &&
                      <DCAMSamlForm
                        setConfigJson={setConfigJson} handleSave={handleSave}
                        ssoURL={ssoURL} setSSOURL={setSSOURL}
                        nameIdFormat={nameIdFormat} setNameIdFormat={setNameIdFormat}
                        remoteLogoutUrl={remoteLogoutUrl} setRemoteLogouUrl={setRemoteLogouUrl}
                        certificateFingerPrint={certificateFingerPrint}
                        setCertificateFingerPrint={setCertificateFingerPrint}
                        logoutUrl={logoutUrl} setLogoutUrl={setLogoutUrl}
                        setSignatureEnabled={setSignatureEnabled}
                        givenNameAttribute={givenNameAttribute} setGiveNameAttribute={setGiveNameAttribute}
                        familyNameAttribute={familyNameAttribute}
                        setFamilyNameAttribute={setFamilyNameAttribute}
                        emailAttribute={emailAttribute} setEmailAttribute={setEmailAttribute}
                        roleAttribute={roleAttribute} setRoleAttribute={setRoleAttribute}
                        itManagerMapping={itManagerMapping} setITManagerMapping={setITManagerMapping}
                        instructorMapping={instructorMapping} setInstructorMapping={setInstructorMapping}
                        linkageAttribute={linkageAttribute} setLinkageAttribute={setLinkageAttribute}
                        fallbackToEmail={fallbackToEmail} setFallbackToEmail={setFallbackToEmail}
                      />
                    }
                    {selectedMenu === INTEGRATION_TYPES.GOOGLE &&
                      <GoogleSamlForm
                        setConfigJson={setConfigJson} handleSave={handleSave}
                        ssoURL={ssoURL} setSSOURL={setSSOURL}
                        nameIdFormat={nameIdFormat} setNameIdFormat={setNameIdFormat}
                        remoteLogoutUrl={remoteLogoutUrl} setRemoteLogouUrl={setRemoteLogouUrl}
                        certificateFingerPrint={certificateFingerPrint}
                        setCertificateFingerPrint={setCertificateFingerPrint}
                        logoutUrl={logoutUrl} setLogoutUrl={setLogoutUrl}
                        setSignatureEnabled={setSignatureEnabled}
                        givenNameAttribute={givenNameAttribute} setGiveNameAttribute={setGiveNameAttribute}
                        familyNameAttribute={familyNameAttribute}
                        setFamilyNameAttribute={setFamilyNameAttribute}
                        emailAttribute={emailAttribute} setEmailAttribute={setEmailAttribute}
                        roleAttribute={roleAttribute} setRoleAttribute={setRoleAttribute}
                        itManagerMapping={itManagerMapping} setITManagerMapping={setITManagerMapping}
                        instructorMapping={instructorMapping} setInstructorMapping={setInstructorMapping}
                        linkageAttribute={linkageAttribute} setLinkageAttribute={setLinkageAttribute}
                        fallbackToEmail={fallbackToEmail} setFallbackToEmail={setFallbackToEmail}
                      />
                    }
                    {selectedMenu === INTEGRATION_TYPES.AZURE &&
                      <AzureSamlForm
                        setConfigJson={setConfigJson} handleSave={handleSave}
                        ssoURL={ssoURL} setSSOURL={setSSOURL}
                        nameIdFormat={nameIdFormat} setNameIdFormat={setNameIdFormat}
                        remoteLogoutUrl={remoteLogoutUrl} setRemoteLogouUrl={setRemoteLogouUrl}
                        certificateFingerPrint={certificateFingerPrint}
                        setCertificateFingerPrint={setCertificateFingerPrint}
                        logoutUrl={logoutUrl} setLogoutUrl={setLogoutUrl}
                        setSignatureEnabled={setSignatureEnabled}
                        givenNameAttribute={givenNameAttribute} setGiveNameAttribute={setGiveNameAttribute}
                        familyNameAttribute={familyNameAttribute}
                        setFamilyNameAttribute={setFamilyNameAttribute}
                        emailAttribute={emailAttribute} setEmailAttribute={setEmailAttribute}
                        roleAttribute={roleAttribute} setRoleAttribute={setRoleAttribute}
                        itManagerMapping={itManagerMapping} setITManagerMapping={setITManagerMapping}
                        instructorMapping={instructorMapping} setInstructorMapping={setInstructorMapping}
                        linkageAttribute={linkageAttribute} setLinkageAttribute={setLinkageAttribute}
                        fallbackToEmail={fallbackToEmail} setFallbackToEmail={setFallbackToEmail}
                      />
                    }
                    {selectedMenu === INTEGRATION_TYPES.LTI &&
                      <GeneralLTIPage
                        selectedMenu={selectedMenuLTI} canvasForm={canvasForm}
                        blackboardForm={blackboardForm}
                        moodleForm={moodleForm} d2lForm={d2lForm}
                        setCanvasForm={setCanvasForm} setBlackboardForm={setBlackboardForm}
                        setMoodleForm={setMoodleForm} setD2lForm={setD2lForm}
                      />
                    }
                    {selectedMenu === INTEGRATION_TYPES.LMS_API &&
                      <GeneralLMSAPIPage
                        selectedMenu={selectedMenuLTI} canvasForm={canvasForm}
                        blackboardForm={blackboardForm}
                        moodleForm={moodleForm} d2lForm={d2lForm}
                        setCanvasForm={setCanvasForm} setBlackboardForm={setBlackboardForm}
                        setMoodleForm={setMoodleForm} setD2lForm={setD2lForm}
                      />
                    }
                    { !dataReady &&
                      <div style={{ marginLeft: "25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto" }}>
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                          <Skeleton height={14.56005859375} width="8vw" style={{ position: "relative", marginTop: "25px" }} />
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Skeleton height={10} width="3vw" style={{ position: "relative", marginTop: "25px" }} />
                          </div>
                          <Skeleton height={14.56005859375} width="5vw" style={{ position: "relative", marginTop: "3vh" }} />
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Skeleton height={10} width="3vw" style={{ position: "relative", marginTop: "3vh" }} />
                          </div>
                          <Skeleton height={14.56005859375} width="6vw" style={{ position: "relative", marginTop: "3vh" }} />
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Skeleton height={10} width="3vw" style={{ position: "relative", marginTop: "3vh" }} />
                          </div>
                          <Skeleton height={14.56005859375} width="7vw" style={{ position: "relative", marginTop: "3vh" }} />
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Skeleton height={10} width="3vw" style={{ position: "relative", marginTop: "3vh" }} />
                          </div>
                          <Skeleton height={14.56005859375} width="9vw" style={{ position: "relative", marginTop: "3vh" }} />
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Skeleton height={10} width="3vw" style={{ position: "relative", marginTop: "3vh" }} />
                          </div>
                          <Skeleton height={14.56005859375} width="8vw" style={{ position: "relative", marginTop: "3vh" }} />
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Skeleton height={10} width="3vw" style={{ position: "relative", marginTop: "3vh" }} />
                          </div>
                        </SkeletonTheme> </div>
                    }
                  </div>
              }
              </Tab>
            }
            {[ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value].includes(role) && showChromeExts &&
              <Tab eventKey="extIntegration" title="External Integration">
                <CrossIntegration />
              </Tab>
            }

            <Tab eventKey="PPTIntegration" title="PowerPoint Integration">
              <PPTInstallGuidePage />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>

  );
}
