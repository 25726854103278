import React from "react";
import { Radio } from "../../components/radio/Radio";
import { RADIAL_BTN_FALSE, RADIAL_BTN_TRUE, TEXTBOX } from "../../utils/constants";
import "../styles.css";
import styles from "./TFQuestion.module.css"

export default function TFQuestionOption({
  option,
  selectedQuestion,
  correctAnswers,
  handleCorrectAnswerChange,
  index,
  pollType,
  questionBuilder=false,
  disabled=false
}) {
  const {weightage} = selectedQuestion;
  const gradeEnable = !!weightage;

  return (

    <div className="question-row" style={{display: "flex", alignItems: "center"}}>
        <Radio
          ariaLabel={option === "True" ? RADIAL_BTN_TRUE : RADIAL_BTN_FALSE}
          value={option}
          selected={correctAnswers}
          onChange={(value) => handleCorrectAnswerChange(value)}
          index={index}
          pollType={pollType}
          disabled={questionBuilder && disabled}
          gradeEnable={gradeEnable}
        />
          <span
            style={{ width: "100%" }}
            className={questionBuilder ? styles.questionBuilderSubText : styles.subText}
            tabIndex={0}
            role={TEXTBOX}
          >
            {option}
          </span>
    </div>
  );
}
