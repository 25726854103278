import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import {
    API_TOKEN_OVERWRITE_MODAL_DESC_BLACKBOARD,
    API_TOKEN_OVERWRITE_MODAL_DESC_CANVAS_MOODLE, API_TOKEN_OVERWRITE_MODAL_DESC_D2L,
    API_TOKEN_OVERWRITE_MODAL_TITLE_BLACKBOARD,
    API_TOKEN_OVERWRITE_MODAL_TITLE_CANVAS_MOODLE, API_TOKEN_OVERWRITE_MODAL_TITLE_D2L,
    BUTTON,
    CLOSE_MODAL,
    INTEGRATION_TYPES_LOWERCASE,
    MODAL,
    MODAL_CONFIRM_TEXT,
    MODAL_GO_BACK_TEXT,
    TEXTBOX
} from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaTextBox } from "../standardization/YuJaTextBox";

export default function OverrideModal({config, show, AlertType=MODAL_CONFIRM_TEXT, handleclose=null, lmsType=INTEGRATION_TYPES_LOWERCASE.MOODLE}) {

    const [okDisabled, setOkDisabled] = useState(config.okDisabled);


    const checkOnChange = (ele) => {
        if(ele.target.value === "" ||  ele.target.value.trim() === "") {
            setOkDisabled(true);
        } else {
            setOkDisabled(false);
        }
    };

    return (
        <Modal
            id="form-modal"
            show={show}
            onHide={!handleclose ? config.close : handleclose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
        >
            <ModalClose tabIndex={0} aria-label={CLOSE_MODAL} role={BUTTON} onClick={handleclose ? handleclose : config.close} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title tabIndex={0} role={TEXTBOX} aria-label={config.title + MODAL} className="modal-title" id="contained-modal-title-vcenter">
                    {(lmsType === INTEGRATION_TYPES_LOWERCASE.MOODLE || lmsType === INTEGRATION_TYPES_LOWERCASE.CANVAS) && API_TOKEN_OVERWRITE_MODAL_TITLE_CANVAS_MOODLE}
                    {lmsType === INTEGRATION_TYPES_LOWERCASE.D2L && API_TOKEN_OVERWRITE_MODAL_TITLE_D2L}
                    {lmsType === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD && API_TOKEN_OVERWRITE_MODAL_TITLE_BLACKBOARD}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(lmsType === INTEGRATION_TYPES_LOWERCASE.MOODLE || lmsType === INTEGRATION_TYPES_LOWERCASE.CANVAS) &&
                    <>
                        <div role={TEXTBOX} className="form-subtext">{API_TOKEN_OVERWRITE_MODAL_DESC_CANVAS_MOODLE}</div>
                        <YuJaTextBox placeholder="Enter access token" name="accessToken" key="accessToken" onChange={checkOnChange}/>
                    </>
                }
                {lmsType === INTEGRATION_TYPES_LOWERCASE.D2L &&
                    <>
                        <div role={TEXTBOX} className="form-subtext">{API_TOKEN_OVERWRITE_MODAL_DESC_D2L}</div>
                        <YuJaTextBox placeholder="Enter app key" name="accessToken" key="accessToken" onChange={checkOnChange} />
                    </>

                }
                {lmsType === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD &&
                    <>
                        <div role={TEXTBOX} className="form-subtext">{API_TOKEN_OVERWRITE_MODAL_DESC_BLACKBOARD}</div>
                        <YuJaTextBox placeholder="Enter app secret" name="accessToken" key="accessToken" onChange={checkOnChange} />
                    </>

                }
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type="secondary" onClick={config.close}>
                    {config.cancelText ? config.cancelText : MODAL_GO_BACK_TEXT}
                </YuJaButton>
                <YuJaButton
                    onClick={(e) => {
                        e.preventDefault();
                        const accessTokenElement = document.getElementsByName("accessToken")[0];
                        if(accessTokenElement != null) {
                            config.submit(accessTokenElement.value);
                        }
                    }}
                    type={AlertType=== MODAL_CONFIRM_TEXT ? "primary" : "delete"}
                    disabled = {okDisabled}
                >
                    {config.okText}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}