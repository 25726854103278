import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as Megaphone } from "../../images/megaphone.svg";
import {
    ALLOW_LOCATION_ACCESS_SUPPORT_LINK,
    ATTENDANCE_DESCRIPTION,
    ATTENDANCE_LOCATION_ACCURACY_WARNING,
    ATTENDANCE_PAGE_STATE, ATTENDANCE_TEXT_INPUT_BOX_PLACEHOLDER,
    ATTENTION_TEXT,
    CHECK_IN_BLOCKED_TEXT_END,
    CHECK_IN_BLOCKED_TEXT_HYPERLINK,
    CHECK_IN_BLOCKED_TEXT_START,
    CHECK_IN_TEXT,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    FIXED_ANSWER,
    IFRAME_PERMISSION_ERROR_MESSAGE,
    INSIDE_GEOFENCE_TEXT,
    LMS_GEOFENCED_ATTENDANCE,
    LOCATION_MESSAGE,
    OUTSIDE_GEOFENCE_ATTENTION_TEXT,
    OUTSIDE_GEOFENCE_TEXT,
    POLL_TITLE_LABEL,
    PRIVACY_POLICY_TEXT,
    PRIVACY_POLICY_URL,
    QUESTION_TITLE,
    SA_ANSWER_LENGTH,
    START_TEXT,
    SUBMIT_TEXT,
    TAKEN_ATTENDANCE_TEXT,
    TEXTBOX,
    THANK_YOU_TEXT,
    UNGRADED_ATTENDANCE_TEXT,
    VIEWER_LOCATION_TIMEOUT,
    WAITING_ROOM_ATTENDANCE_TEXT
} from "../../utils/constants";
import {
    handleUserAns,
    isCircleOverlap,
    newPageLog,
    notifyError, stripHTMLTags
} from "../../utils/helpers";
import CountdownTimer from "./CountdownTimer";
import NetworkStatus from "./NetworkStatus";
import waiting from "./animations/attendanceWaiting.json";
import thankYou from "./animations/thankYou.json";
import { ReactComponent as CheckinMap } from "./images/checkin_map.svg";
import { ReactComponent as InsideGeoFence } from "./images/inside_geofence.svg";
import { ReactComponent as LmsGeofence } from "./images/lms_geofence.svg";
import { ReactComponent as OutsideGeoFence } from "./images/outside_geofence.svg";
import loadingGif from "../../images/LoadingAnimation.gif";
import "./styles.css";
import styles from "./styles.module.css";
import parse from "html-react-parser";

export default React.memo(AttendancePage, (prevProps, nextProps) => {
    return (prevProps.pollInfo === nextProps.pollInfo && prevProps.currentQuestion === nextProps.currentQuestion);
});

function AttendancePage({
    pollInfo,
    currentQuestion = {},
    state,
    // setState,
    stopped,
    setStopped,
    changeCurQue,
    websocketState,
    attendancePageState,
    setAttendancePageState,
    handleReset
}) {
    const isMobile = useMobileAccess(800);
    const isNarrow = useMobileAccess(1200);
    const { pollTitle, hasGeofence, geofence, syncLms } = pollInfo;
    const { question={},
        questionResponse={},
        questionEndTime=""
    } = currentQuestion;
    const { queTitle="", timeLimit=DEFAULT_ATTENDANCE_TIME_LIMIT } = question;
    const { selectedOption=null, isCorrect=null } = questionResponse;
    const [isResponseCorrect, setIsResponseCorrect] = useState(isCorrect);
    const [locationData, setLocationData] = useState(questionResponse?.locationData ? questionResponse.locationData : {}); // { inGeofence: false, message: "", accuracy: 0 }
    const [setFirstLocate, getFirstLocate] = useLocalStorageNew("firstLocate", true);
    const [_, getSession] = useLocalStorageNew("session", {});
    const { handleSubmitIndividualResponse } = ResponseApiCalls();
    const [isTimeOut, setIsTimeOut] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [curAnswer, setCurAnswer] = useState(FIXED_ANSWER.UNANSWERED);
    const [isAnsweredState, setIsAnsweredState] = useState(!!selectedOption);
    const options = {
        enableHighAccuracy: true, // true for high accuracy or false for low accuracy
        maximumAge: 0, // maximum cached position age
        timeout: 8000, // maximum time to wait for a response
    };

    // BEGIN DOCUMENT FORMATTING
    useEffect(() => {
        newPageLog("AttendancePage.js");
        if (isNarrow && document.getElementById("viewer-navbar-container") !== null) {
            document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
        }

        setFirstLocate(true);
        // enableInput();
    }, []);

    if (document.getElementById("root") !== null) {
        document.getElementById("root").style.backgroundImage = "none";
    }
    // END DOCUMENT FORMATTING

    // const enableInput = () => {
    //     if (document.getElementById("sub-btn")) {
    //         document.getElementById("sub-btn").style.display = "block";
    //     }

    //     if (document.getElementById("ATTENDANCE-IN")) {
    //         document.getElementById("ATTENDANCE-IN").disabled = false;
    //     }
    // };

    // const disableInput = () => {
    //     if (document.getElementById("sub-btn")) {
    //         document.getElementById("sub-btn").style.display = "none";
    //     }

    //     document.getElementById("ATTENDANCE-IN").disabled = true;
    // };

    // useEffect(() => {
    //     if (isCorrect !== null) {
    //         setIsResponseCorrect(isCorrect);
    //     }
    // }, [isCorrect]);

    // useEffect(() => {
    //     if (attendancePageState === ATTENDANCE_PAGE_STATE.THANK_YOU && questionResponse?.locationData && JSON.stringify(locationData) === "") {
    //         setLocationData(questionResponse.locationData);
    //     }
    // }, [questionResponse, attendancePageState]);

    useEffect(() => {
        if (attendancePageState === ATTENDANCE_PAGE_STATE.CHECK_IN || attendancePageState === ATTENDANCE_PAGE_STATE.QUESTION) {
            if (stopped || isTimeOut) {
                timeUp();
                return;
            }

            //reset state
            const { selectedOption=null, isCorrect=null, locationData: locationDataArial={} } = questionResponse;
            setIsResponseCorrect(isCorrect);
            if (!!locationDataArial.inGeofence) {
             setLocationData(locationDataArial);
            }
            setIsTimeOut(false);
            setCurAnswer(FIXED_ANSWER.UNANSWERED);
            setIsAnsweredState(!!selectedOption);
        }

    }, [attendancePageState, state, stopped, isTimeOut]);

    useEffect(() => {
        if ((attendancePageState === ATTENDANCE_PAGE_STATE.CHECK_IN || attendancePageState === ATTENDANCE_PAGE_STATE.QUESTION)
            && hasGeofence
            && window.location !== window.parent.location) {
            setAttendancePageState(ATTENDANCE_PAGE_STATE.LMS_GEOFENCE);
        }
    }, [attendancePageState, hasGeofence, window]);

    useEffect(() => {
        const intervalRef = setInterval(() => {
            if (attendancePageState === ATTENDANCE_PAGE_STATE.LMS_GEOFENCE) {
                handleReset();
            }
        }, 10000);

        return () => clearInterval(intervalRef);
    }, [attendancePageState]);

    // useEffect(() => {
    //     if (isResponseCorrect === null) {
    //         return;
    //     }

    //     setAttendancePageState(ATTENDANCE_PAGE_STATE.THANK_YOU);
    // }, [isResponseCorrect]);

    const handleType = (e) => {
        e.stopPropagation();

        if (e.target.value.toString().length === 0) {
            document.getElementById("SA-IN").value = "";
            setCurAnswer(FIXED_ANSWER.UNANSWERED);
        } else {
            const ans = {};
            ans["0"] = e.target.value.toString();
            setCurAnswer(ans);
        }
        // document.getElementById("sub-btn").style.display = e.target.value === "" ? "none" : "block";
    };

    const timeUp = async () => {
        // disableInput();
        if (isAnsweredState) {
            setStopped(false);
            setIsTimeOut(false);
            setAttendancePageState(ATTENDANCE_PAGE_STATE.THANK_YOU);
            return;
        }

        await handleSub();
        setStopped(false);
        setIsTimeOut(false);
    };

    const handleSub = async () => {
        let copy = curAnswer;
        const isCorr = handleUserAns(question, copy);
        setIsAnsweredState(true);
        setIsResponseCorrect(isCorr);
        setIsSubmitting(true);
        try {
            await handleSubmitResponse(copy, isCorr);
        } finally {
            setIsSubmitting(false);
        }
        const curQueCopy = JSON.parse(JSON.stringify(currentQuestion));
        curQueCopy.questionResponse = { selectedOption: copy, isCorrect: isCorr };
        changeCurQue(curQueCopy);
        setAttendancePageState(ATTENDANCE_PAGE_STATE.THANK_YOU);
        setCurAnswer(FIXED_ANSWER.UNANSWERED);
        // handleMCTFHighlight();
    };

    const handleSubmitResponse = async (ansIn, isCorr) => {
        const liveQuestion = question;
        const answer = {
            serialNo: liveQuestion.serialNo,
            questionType: liveQuestion.questionType,
            queTitle: liveQuestion.queTitle,
            optionsMap: liveQuestion.optionsMap,
            isCorrect: isCorr,
            attemptsCount: 1,
            selectedOption: ansIn,
            correctAnswers: liveQuestion.correctAnswers,
        };
        if (hasGeofence) {
            answer.locationData = locationData;
        }

        await handleSubmitIndividualResponse(pollInfo, true, answer);
    };

    const success = (position) => {
        const { latitude, longitude, accuracy } = position.coords;
        const inGeofence = geofence ? isCircleOverlap({ lat: latitude, lng: longitude, rad: accuracy}, geofence) : true;
        console.log("Latitude: ", latitude, "Longitude: ", longitude, "Accuracy: ", accuracy, "In Geofence: ", inGeofence);
        if (!locationData?.accuracy || inGeofence !== locationData.inGeofence) {
            setLocationData({ accuracy: accuracy, inGeofence: inGeofence, message: inGeofence ? LOCATION_MESSAGE.SUCCESS_IN_GEOFENCE : LOCATION_MESSAGE.SUCCESS_OUTSIDE_GEOFENCE });
        }
    };

    const error = (err) => {
        if (err.message === IFRAME_PERMISSION_ERROR_MESSAGE) {
            notifyError(LOCATION_MESSAGE.IFRAME_PERMISSION_ERROR);
            setLocationData({ inGeofence: false, message: LOCATION_MESSAGE.IFRAME_PERMISSION_ERROR });
            return;
        }
        switch (err.code) {
            case err.PERMISSION_DENIED:
                setLocationData({ inGeofence: false, message: LOCATION_MESSAGE.PERMISSION_DENIED });
                break;
            // case err.POSITION_UNAVAILABLE:
            // case err.TIMEOUT:
            // case err.UNKNOWN_ERROR:
            default:
                setLocationData({ inGeofence: false, message: LOCATION_MESSAGE.UNKNOWN_ERROR });
                break;
        }
    };

    function geoFindMe() {
        if (!navigator.geolocation) {
            setLocationData({ inGeofence: false, message: LOCATION_MESSAGE.BROWSER_NOT_SUPPORTED });
        } else {
            if (getFirstLocate()) {
                setLocationData({ message: LOCATION_MESSAGE.LOCATING });
                setFirstLocate(false);
            }

            // navigator.geolocation.getCurrentPosition(() => { }, () => { }, {});
            navigator.geolocation.getCurrentPosition(success, error, options);
            // navigator.geolocation.watchPosition(success, error, options);
        }
    }

    useEffect(() => {
        if (!hasGeofence || attendancePageState !== ATTENDANCE_PAGE_STATE.CHECK_IN) {
            return;
        }

        geoFindMe();

        const locationInterval = setInterval(() => {
            if (!locationData?.inGeofence || !locationData?.accuracy) {
                geoFindMe();
            }
        }, VIEWER_LOCATION_TIMEOUT);

        return () => clearInterval(locationInterval);
    }, [hasGeofence, attendancePageState]);

    // useEffect(() => {
    //     console.log(locationData);
    // }, [locationData]);

    return (
        <div id="everything" className={styles.attendanceLayout}>
            {attendancePageState === ATTENDANCE_PAGE_STATE.QUESTION && hasGeofence && !locationData.inGeofence &&
                <div className={styles.attendanceAttentionContainer}>
                    <Megaphone  style={{flexShrink: 0}}/>
                    <div style={{flexGrow: 1}}>
                        <strong >{ATTENTION_TEXT}</strong>
                        {OUTSIDE_GEOFENCE_ATTENTION_TEXT}
                    </div>
                </div>
            }
            <div className={styles.attendanceHeaderContainer}>
                <div id="header" className="d-flex justify-content-between" style={{ height: 90, alignItems: "center", gap: 15 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState} />
                    </div>
                    <div
                        tabIndex={0}
                        aria-label={POLL_TITLE_LABEL + pollTitle}
                        role={TEXTBOX}
                        className={styles.attendanceTitle}
                    >{pollTitle}</div>
                    {(attendancePageState === ATTENDANCE_PAGE_STATE.CHECK_IN || attendancePageState === ATTENDANCE_PAGE_STATE.QUESTION) &&
                        (!!locationData?.message && locationData.message !== LOCATION_MESSAGE.LOCATING) && hasGeofence && !isNarrow ?
                        (locationData?.inGeofence ?
                            <div className="attendanceInsideGeofenceContainer">
                                <InsideGeoFence />
                                {INSIDE_GEOFENCE_TEXT}
                            </div> : <div className="attendanceOutsideGeofenceContainer">
                                <OutsideGeoFence />
                                {OUTSIDE_GEOFENCE_TEXT}
                            </div>
                        ) : <div style={{ display: "flex", visibility: "hidden" }}><NetworkStatus refreshAlignFirst={true} websocketState={websocketState} /></div>
                    }
                </div>
            </div>
            {/* {locationData?.message &&
                    <NormalBox boxType="light" className={`${styles.locationContainer}`}>
                    <div style={{ display: "flex", justifyContent: "space-between", height: "60px" }}>
                        <div tabIndex={0} role={TEXTBOX} className="poll-title">{locationData.message}</div>
                        {!isAnsweredState && !locationData.inGeofence &&
                        <div tabIndex={0} role={BUTTON} className={styles.refreshLocation} onClick={geoFindMe}>{REFRESH_LOCATION}</div>
                        }
                    </div>
                    </NormalBox>
                } */}
            <div className={styles.attendanceBodyContainer}>
                {attendancePageState === ATTENDANCE_PAGE_STATE.LMS_GEOFENCE && <>
                    <div className="attendance-lms-geofence-icon">
                        <LmsGeofence />
                    </div>
                    <div className="attendance-lms-geofence-text">
                        {LMS_GEOFENCED_ATTENDANCE}
                    </div>
                    <YuJaButton
                        id="start-btn"
                        onClick={() => window.open(`/portal?engageSession=${encodeURIComponent(JSON.stringify(getSession()))}&dest=${encodeURIComponent(window.location.pathname)}`)}
                        style={{
                            fontSize: 14,
                            fontWeight: 400,
                            width: 125,
                            height: 35,
                            borderRadius: 5,
                            padding: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center"
                        }}
                    >
                        {START_TEXT}
                    </YuJaButton>
                </>}
                {attendancePageState === ATTENDANCE_PAGE_STATE.WAITING && <>
                    {/* <Lottie
                        options={{ loop: true, autoplay: true, animationData: waiting, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }}
                        width={window.innerWidth < 480 ? 180 : 300}
                        height={window.innerWidth < 480 ? 180 : 300}
                        isClickToPauseDisabled={true}
                    /> */}
                    <div style={{height: window.innerWidth < 480 ? "180px" : "300px", width: window.innerWidth < 480 ? "180px" : "300px", alignSelf: "center"}}>
                        <img src={require("../../images/waitingRoom.gif")} alt="loading..." style={{ height: "100%", width: "100%" }} />
                    </div>
                    <Row>
                        <Col className="center">
                            <div tabIndex={0} role={TEXTBOX} className="waiting-room-bottom" style={{ marginTop: "30px" }}>
                                {WAITING_ROOM_ATTENDANCE_TEXT}
                            </div>
                        </Col>
                    </Row>
                    {hasGeofence && <Row>
                        <Col className="center">
                            <div tabIndex={0} role={TEXTBOX} className="waiting-room-bottom-bottom" style={{ marginTop: "10px" }}>
                                {ATTENDANCE_LOCATION_ACCURACY_WARNING}
                            </div>
                        </Col>
                    </Row>}
                </>}
                {attendancePageState === ATTENDANCE_PAGE_STATE.CHECK_IN && <>
                    {questionEndTime && 
                        <CountdownTimer
                            duration={timeLimit}
                            questionEndTime={questionEndTime}
                            setIsTimeOut={setIsTimeOut}
                            state={state}
                            forAttendance={true}
                        />
                    }
                    <div className="attendance-check-in-icon">
                        <CheckinMap width={isNarrow ? 165 : 220} height={isNarrow ? 120: 160} />
                    </div>
                    <div className="attendance-check-in-text">
                        {locationData?.message === LOCATION_MESSAGE.PERMISSION_DENIED ? <>
                            <div>{CHECK_IN_BLOCKED_TEXT_START}<div onClick={() => window.open(ALLOW_LOCATION_ACCESS_SUPPORT_LINK)} className="href">{CHECK_IN_BLOCKED_TEXT_HYPERLINK}</div></div>
                            <div>{CHECK_IN_BLOCKED_TEXT_END}</div>
                            {/* </> : locationData?.message === LOCATION_MESSAGE.SUCCESS_IN_GEOFENCE || locationData?.message === LOCATION_MESSAGE.SUCCESS_OUTSIDE_GEOFENCE ? <> */}
                        </> : <div>{locationData?.message}</div>}
                    </div>
                    <YuJaButton
                        id="sub-btn"
                        onClick={() => setAttendancePageState(ATTENDANCE_PAGE_STATE.QUESTION)}
                        style={{
                            fontSize: 14,
                            fontWeight: 400,
                            width: 145,
                            height: 35,
                            padding: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center"
                        }}
                        disabled={locationData?.message === LOCATION_MESSAGE.LOCATING}
                    >
                        {CHECK_IN_TEXT}
                    </YuJaButton>
                </>}
                {attendancePageState === ATTENDANCE_PAGE_STATE.QUESTION && <>
                    {questionEndTime && 
                        <CountdownTimer
                            duration={timeLimit}
                            questionEndTime={questionEndTime}
                            setIsTimeOut={setIsTimeOut}
                            state={state}
                            forAttendance={true}
                        />
                    }
                    <div className="attendance-description">
                        {ATTENDANCE_DESCRIPTION}
                    </div>
                    <div tabIndex={0} aria-label={QUESTION_TITLE + stripHTMLTags(queTitle)} className="attendance-ques-title">
                        {parse(queTitle)}
                    </div>
                    <YuJaTextBox
                        // disabled={isAnsweredState}
                        id={`ATTENDANCE-IN`}
                        name="attendance-in"
                        placeholder={ATTENDANCE_TEXT_INPUT_BOX_PLACEHOLDER}
                        maxLength={SA_ANSWER_LENGTH}
                        style={{ boxSizing: "border-box" }}
                        value={!curAnswer || curAnswer === FIXED_ANSWER.UNANSWERED ? "" : curAnswer["0"]}
                        onChange={handleType}
                        containerStyle={{ width: isMobile ? "75%" : "50%", border: "2.5px solid #D0D0D0", alignItems: "center", alignSelf: "center", padding: "10px" }}
                    />
                    {/* {(!isAnsweredState) && */}
                        <YuJaButton
                            id="sub-btn"
                            onClick={handleSub}
                            disabled={isSubmitting}
                            style={{
                                fontSize: 14,
                                width: 75,
                                height: 35,
                                marginTop: isMobile ? "20px" : "5vh",
                                padding: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                            }}
                        >
                            {isSubmitting ? <img src={loadingGif} alt={"loading..."} style={{height: 31}}/> : SUBMIT_TEXT}
                        </YuJaButton>
                    {/* } */}
                </>}
                {attendancePageState === ATTENDANCE_PAGE_STATE.THANK_YOU && <>
                    <Lottie
                        options={{ loop: false, autoplay: true, animationData: thankYou, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }}
                        height={225}
                        width={225}
                        isClickToPauseDisabled={true}
                    />
                    <div className="attendance-thank-you">
                        {THANK_YOU_TEXT}
                    </div>
                    <div className="attendance-thank-you-status">
                        {UNGRADED_ATTENDANCE_TEXT}
                    </div>
                </>}
                {attendancePageState === ATTENDANCE_PAGE_STATE.CLOSED && <>
                    <Lottie
                        options={{ loop: true, autoplay: true, animationData: waiting, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }}
                        width={window.innerWidth < 480 ? 180 : 300}
                        height={window.innerWidth < 480 ? 180 : 300}
                        isClickToPauseDisabled={true}
                    />
                    <Row>
                        <Col className="center">
                            <div tabIndex={0} role={TEXTBOX} className="waiting-room-bottom" style={{ marginTop: "30px" }}>
                                {TAKEN_ATTENDANCE_TEXT}
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col className="center">
                            <div tabIndex={0} role={TEXTBOX} className="waiting-room-subtitle" style={{ marginTop: "10px" }}>
                                {ABSENT_ATTENDANCE_TEXT}
                            </div>
                        </Col>
                    </Row> */}
                </>}
            </div>
            {(attendancePageState === ATTENDANCE_PAGE_STATE.CHECK_IN || (attendancePageState === ATTENDANCE_PAGE_STATE.QUESTION && hasGeofence && isNarrow)) &&
                <div className={styles.attendanceFooterContainer}>
                    {attendancePageState === ATTENDANCE_PAGE_STATE.CHECK_IN ? 
                        <div className="attendancePrivacyPolicyText" onClick={() => window.open(PRIVACY_POLICY_URL)}>
                            {PRIVACY_POLICY_TEXT}
                        </div> :
                        (locationData?.inGeofence ? <div className="attendanceInsideGeofenceContainer">
                            <InsideGeoFence />
                            {INSIDE_GEOFENCE_TEXT}
                        </div> : <div className="attendanceOutsideGeofenceContainer">
                            <OutsideGeoFence />
                            {OUTSIDE_GEOFENCE_TEXT}
                        </div>)
                    }
                </div>
            }
        </div>
    );
}
