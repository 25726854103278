import React, { useState} from "react";
import { Draggable } from "react-beautiful-dnd";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import CancelIcon from "../../images/cancel.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import {
  CREATE_OPT_RADIAL,
  DELETE_OPT_BTN,
  MC_ANSWER_LENGTH,
  OPTION_DELETE_ALT_TEXT,
  OPTION_DELETE_TITLE,
  TEXTBOX,
  TEXT_BOX,
} from "../../utils/constants";
import {notifyError, notifyInfo} from "../../utils/helpers";
import { updateAnswers, updateCorrectAnswers } from "../../utils/questionUpdate";
import { addOptionToQuestion } from "../../utils/questionUtils";
import {
  ADD_OPTIONS_INFO,
  MC_CI_ANSWER_LENGTH_EXCEEDED
} from "../../utils/toast-message-constants";
import "../styles.css";
import UploadOptionImage from "../UploadOptionImage";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";

export default function MCQuestionOption({
    pollKey,
    changePollKey,
    optionData,
    selectedQuestion,
    questions,
    setQuestions,
    questionBankId,
    index,
    pollType,
    maxSize,
    warningShowed,
    setWarningShowed,
    questionBuilder=false,
    disabled=false,
}) {
  const {isNarrow} = useCheckMainContainerNarrow();
  const {weightage, optionsImageMap={}} = selectedQuestion;
  const gradeEnable = !!weightage;
  const [showDragIcon, setShowDragIcon] = useState(false);
  const [isDraggable, setIsDraggable] = useState(!questionBuilder);
  const { choice, optionValue } = optionData;
  const { serialNo, optionsMap, correctAnswers } = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;



  const handleAnswerChange = (input, itemKey) => {
    setQuestions(
      updateAnswers(input, itemKey, selectedQuestionIndex, questions)
    );
  };
  const handleCorrectAnswerChange = (input) => {
    setQuestions(updateCorrectAnswers(input, selectedQuestionIndex, questions));
  };

  const handleDeleteOption = () => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let updatedQuestion = {...q};
        let newOptionsMap = {};
        Object.entries(q.optionsMap).forEach(([key, value]) => {
          if (key !== choice) {
            if (key > choice) {
              const newKey = String.fromCharCode(key.charCodeAt(0) - 1);
              newOptionsMap[newKey] = value;
            } else {
              newOptionsMap[key] = value;
            }
          }
        });

        updatedQuestion.optionsMap = newOptionsMap;
        updatedQuestion.orrectAnswers = choice === q.correctAnswers[0] ? [] : q.correctAnswers;


        if (!!q.optionsImageMap) {
          let newOptionsImageMap = {};
          Object.entries(q.optionsImageMap).forEach(([key, value]) => {
            if (key !== choice) {
              if (key > choice) {
                const newKey = String.fromCharCode(key.charCodeAt(0) - 1);
                newOptionsImageMap[newKey] = value;
              } else {
                newOptionsImageMap[key] = value;
              }
            }
          });
          updatedQuestion.optionsImageMap = newOptionsImageMap;
        }

        // updatedQuestion["image" + choice] = null;
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push({ ...q });
      }
    });
    setQuestions(newQuestions);
  };

  const handlePaste = (e) => {
    if(e.target.value.length <= MC_ANSWER_LENGTH) {
      if (index + 1 === maxSize) {
        const newQuestions = addOptionToQuestion(
          selectedQuestion,
          questions
        );
        if (newQuestions) {
          setQuestions(newQuestions);
        } else if(!warningShowed) {
          setWarningShowed(true);
          notifyInfo(ADD_OPTIONS_INFO);
        }
      }
    }
    else {
      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
    }
  }

  return (
    <Draggable
      draggableId={choice}
      index={index}
      key={choice}
      isDragDisabled={!isDraggable}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1} >
          <div
              style={{display: "flex", flex: 1, alignItems: "center", gap: isNarrow || questionBuilder ? 5 : 10}}
              onMouseEnter={() => setShowDragIcon(true)}
              onMouseLeave={() => setShowDragIcon(false)}
          >
            {!questionBuilder &&
                <div
                    {...provided.dragHandleProps}
                    style={{
                      width: (questionBuilder && disabled) ? 0 : 15,
                      flexShrink: 0,
                    }}
                    title={(questionBuilder && disabled) ? "" : "Drag to Reorder"}
                    tabIndex={-1}
                >
                  {showDragIcon &&
                      <img src={DragDropIcon} alt="drag drop icon" width={15} height={25}/>
                  }
                </div>

            }
            <span style={{padding: 2, fontSize: isNarrow || questionBuilder ? 16 : 20}}>{choice.toUpperCase()}</span>
            {gradeEnable &&
                <YuJaTextBox
                    disabled={questionBuilder && disabled}
                    label={TEXT_BOX + choice.toUpperCase() + " " + (choice === correctAnswers[0] ? "selected" : "unselected")}
                    containerStyle={{
                      flex: 1,
                      fontSize: isNarrow || questionBuilder ? 16 : 20,
                      padding: isNarrow || questionBuilder ? 8 : 12, border: "1.13px solid #858687"}}
                  placeholder={`Option ${choice.toUpperCase()}`}
                  value={optionValue}
                  name={"question-text"}
                  maxLength={MC_ANSWER_LENGTH}
                  onPaste={(e) => {handlePaste(e)}}
                  before={
                    <YuJaRadioBox
                        aria-label={CREATE_OPT_RADIAL + choice.toUpperCase() + " " + (choice === correctAnswers[0] ? "selected" : "unselected")}
                        size={isNarrow || questionBuilder ? 18 : 24}
                        onClick={() =>handleCorrectAnswerChange(choice)}
                        checked={choice === correctAnswers[0]}
                        disabled={disabled}
                        name={"optionRadio"}
                        style={{ zIndex: 10, marginRight: isNarrow || questionBuilder ? 5 : 10}}
                    />
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    if(e.target.value.length <= MC_ANSWER_LENGTH) {
                      handleAnswerChange(e.target.value, choice);
                      if (index + 1 === maxSize) {
                        const newQuestions = addOptionToQuestion(
                          selectedQuestion,
                          questions
                        );
                        if (newQuestions) {
                          setQuestions(newQuestions);
                          setTimeout(() => {
                            if (questionBuilder) {
                              const questionBuilderMain = document.querySelector(".questionBuilderMain");
                              questionBuilderMain?.scrollTo({ top: questionBuilderMain?.scrollHeight, behavior: "smooth" });
                            } else {
                              const mcqOptionsContainer = document.getElementById("mcq-options-container");
                              mcqOptionsContainer?.scroll({ top: mcqOptionsContainer?.scrollHeight, behavior: "smooth" });
                            }
                          }, 10);
                        } else if (!warningShowed) {
                          setWarningShowed(true);
                          notifyInfo(ADD_OPTIONS_INFO);
                        }
                      }
                    }
                    else {
                      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                    }
                  }}
                  showRemaining={true}
                />
            }
            {!gradeEnable &&
                <YuJaTextBox
                  disabled={questionBuilder && disabled}
                  label={TEXT_BOX + choice.toUpperCase()}
                  containerStyle={{ width: "100%", fontSize: isNarrow || questionBuilder ? 16 : 20,  padding: isNarrow || questionBuilder ? 8 : 12, border: "1.13px solid #858687"}}
                  placeholder={`Option ${choice.toUpperCase()}`}
                  value={optionValue}
                  name={"question-text"}
                  maxLength={MC_ANSWER_LENGTH}
                  onPaste={(e) => {handlePaste(e)}}
                  onChange={(e) => {
                    e.preventDefault();
                    if(e.target.value.length <= MC_ANSWER_LENGTH) {
                      handleAnswerChange(e.target.value, choice);
                      if (index + 1 === maxSize) {
                        const newQuestions = addOptionToQuestion(
                          selectedQuestion,
                          questions
                        );
                        if (newQuestions) {
                          setQuestions(newQuestions);
                          setTimeout(() => {
                            const mcqOptionsContainer = document.getElementById("mcq-options-container");
                            mcqOptionsContainer?.scroll({ top: mcqOptionsContainer.scrollHeight, behavior: "smooth" });
                          }, 10);
                        } else if (!warningShowed) {
                          setWarningShowed(true);
                          notifyInfo(ADD_OPTIONS_INFO);
                        }
                      }
                    }
                    else {
                      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                    }
                  }}
                  showRemaining={true}
              />
            }
            <div style={{display: "flex", gap: isNarrow || questionBuilder ? 5 : 10, alignItems: "center"}}>
              <UploadOptionImage
                  optionsImageMap={optionsImageMap}
                  pollKey={pollKey}
                  changePollKey={changePollKey}
                  questionBuilder={questionBuilder}
                  disabled={disabled}
                  questions={questions}
                  setQuestions={setQuestions}
                  selectedQuestion={selectedQuestion}
                  optionData={optionData}
                  questionBankId={questionBankId}
                  setIsDraggable={setIsDraggable}
              />

              {(!questionBuilder || !disabled) && Object.keys(optionsMap).length > 1 &&
                  <div style={{width : isNarrow || questionBuilder ? 20 : 30}}>
                    <img
                        aria-label={DELETE_OPT_BTN + choice.toUpperCase() + " button " + (choice === correctAnswers[0] ? "selected" : "unselected")}
                        tabIndex={0}
                        role={TEXTBOX}
                        onClick={handleDeleteOption}
                        src={CancelIcon}
                        alt={OPTION_DELETE_ALT_TEXT}
                        title={OPTION_DELETE_TITLE}
                        className="cursor-pointer"
                        width={isNarrow || questionBuilder ? 20 : 30}
                        height={isNarrow || questionBuilder ? 20 : 30}
                    />
                  </div>
              }
            </div>


          </div>

        </div>
      )}
    </Draggable>
  );
}
