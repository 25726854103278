import React from "react";
import styles from "./D2LAuthTokenPage.module.css";
import {YUJA_ENGAGE_CAN_ACCESS_D2L_API, YOU_MAY_NOW_CLOSE_THIS_TAB} from "../../utils/constants";
export default function D2LAuthTokenPage() {

    // const [isError, setIsError] = useState();
    // const {storeD2LAuthToken} = AuthenticationApiCalls();
    // let query = useQuery();
    // useEffect(() => {
    //     // Fetch the Request URL here
    //     const requestPath = window.location.pathname;
    //     const fullURL = window.location.href;
    //     console.log(requestPath);
    //     console.log(fullURL);
    //     console.log(query);
    //     if (query.has("error") || query.has("error_description")) {
    //         setIsError(true);
    //     } else {
    //         const x_a = query.get("x_a");
    //         const x_b = query.get("x_b");
    //         const x_c = query.get("x_c");
    //         const x_state = query.get("x_state");
    //         console.log("Printing return values " + x_a + " " + x_b + " " + x_c + " " + x_state);
    //         storeD2LAuthToken(x_a, x_b, x_c, x_state);
    //         setIsError(false);
    //     }
    // }, []);

    return (
        <div className="col-md-12" style={{marginTop: "8em", textAlign:"center"}}>
            <h3 className={styles.titleContainer}> 
                {YUJA_ENGAGE_CAN_ACCESS_D2L_API}
            </h3>
            <h4 className="page-sub-title" style={{color: "#666666"}}>
                {YOU_MAY_NOW_CLOSE_THIS_TAB}
            </h4>
        </div>
    );
}