import styles from "./YuJaCheckbox.module.css";
import {BsCheckLg} from "react-icons/bs";
import {useEffect, useRef, useState} from "react";
import { TEXTBOX } from "../../utils/constants";


export const YuJaCheckbox = ({checked=false, id="", disabled=false, label="", ariaLabel="", color, onClick, size=24, ...props}) => {
    const actualBoxRef = useRef(null);
    const {style, ...inputProps} = props;
    const [inputVal, setInputVal] = useState(checked);

    const onClickEvent = (e) => {
        if (disabled) {
            return
        }

        const box = actualBoxRef.current;
        if (box) {
            box.click();
        }
    }

    const onChangeFunc = (e) => {
        setInputVal(e.target.value);
        if (onClick) {
            onClick(e);
        }
    }

    useEffect(() => {
        setInputVal(checked);
    }, [checked])

    return (
        <div className={styles.container} style={style}>
            <div
                style={{width: size, height: size, border: color ? `1.5px solid ${color}`: ""}}
                className={`${styles.box} ${checked ? styles.checked : styles.unchecked} ${disabled ? styles.disabled : ""}`}
                onClick={onClickEvent}
                tabIndex={0}
                id={id}
                aria-label={ariaLabel}
                role={TEXTBOX}
            >
                <input ref={actualBoxRef} name={props.name} type={"checkbox"} style={{display: "none"}} checked={inputVal} {...inputProps} onChange={onChangeFunc} aria-label={label}/>
                {checked &&
                    <BsCheckLg size={size * 0.7} fill={"#FFFFFF"}/>
                }
            </div>
            {label &&
                <span className={`${styles.label} ${disabled ? styles.disabledLabel : ""}`}>{label}</span>
            }
        </div>

    )
}