// import 'rc-datepicker/lib/style.css';
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { COURSE_SEARCH_PLACEHOLDER, CREATE_COURSE, SEARCH_BOX_COURSE, SEARCH_TEXT } from '../../utils/constants';
import styles from "./ManageCourses.module.css";

export default function CourseSearchForm({ handleSubmit, openCourseModal }) {
    const [keyword, setKeyword] = useState("");
    const [date, setDate] = useState("");

    const clickSearch = () => {
        let formattedDate = date ? date.split("-").reverse().join("/") : "";
        handleSubmit({ keyword: keyword, date: formattedDate });
    }

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }

    return (
        <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <YuJaTextBox
                    label={SEARCH_BOX_COURSE}
                    containerStyle={{fontSize: 13, border: "1px solid #D0D0D0", color: "#898989", padding: 9}}
                    placeholder={COURSE_SEARCH_PLACEHOLDER}
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                />

                {/* Old date picker */}
                {/* <Form.Control
                    className={styles.searchBoxDateInput}
                    type="date"
                    placeholder="Date"
                    onChange={e => setDate(e.target.value)}
                    value={date}
                >
                </Form.Control> */}
                
                {/* New date picker */}
                {/* <DatePickerInput
                    displayFormat='DD-MM-YYYY'
                    style={{padding : "20px"}}
                    placeholder="DD-MM-YYYY"
                    onChange={value => {
                        if(!isNaN(value)){
                            setDate(value.toISOString().split('T')[0]);
                        } else {
                            setDate("");
                        }
                    }}
                    value={date}
                /> */}

                <YuJaButton onClick={clickSearch} style={{fontSize: 14, margin: "0 10px"}}>{SEARCH_TEXT}</YuJaButton>
                <YuJaButton onClick={openCourseModal} style={{fontSize: 14, minWidth: 145}}><PlusIcon style={{width: 15, height: 15, marginRight: 5}}/>{CREATE_COURSE}</YuJaButton>
            </Form>
        </div>
    );
}
