import { useEffect } from "react";
import { AuthenticationApiCalls } from "../apis/AuthenticationApiCalls";
import useQuery from "../utils/queryparam";

export default function LTI3LoginComponent() {
  const { loginCANVAS, loginBB, loginMOODLE, loginD2L } = AuthenticationApiCalls();
  let query = useQuery();
  useEffect(() => {
    if (query.get("secret") && query.get("intType")) {
      if (query.get("intType").toLowerCase() === "blackboard") {
        loginBB(query.get("secret"), query.get("intType"), query.get("reqType"), query.get("redirectURL"));
      } else if (query.get("intType").toLowerCase() === "canvas") {
        loginCANVAS(query.get("secret"), query.get("intType"), query.get("reqType"), query.get("redirectURL"));
      } else if (query.get("intType").toLowerCase() === "moodle") {
        loginMOODLE(query.get("secret"), query.get("intType"), query.get("reqType"), query.get("redirectURL"));
      } else if (query.get("intType").toLowerCase() === "d2l") {
        loginD2L(query.get("secret"), query.get("intType"), query.get("reqType"), query.get("redirectURL"));
      }
    }
  }, []);

  return null;
}
