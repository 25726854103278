import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useCountdown } from "../../hooks/useCountdown";
import useMobileAccess from "../../hooks/useMobileAccess";
import waitingRoomBackground from "../../images/waitingRoomBackground.svg";
import waitingRoomBackgroundMobile from "../../images/waitingRoomBackgroundMobile.svg";
import { GET_READY_LABEL, GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION, POLL_TITLE_LABEL, SECONDS_LABEL, TEXTBOX } from "../../utils/constants";
import { newPageLog } from "../../utils/helpers";
import NetworkStatus from "./NetworkStatus";
import "./styles.css";

export default function GetReady({question, handleGetReadyComplete, pollTitle, websocketState }) {
  const {timeReadyDuration, questionStartTime} = question;
  const { getReturnValues, resetCountdown } = useCountdown(new Date(questionStartTime).getTime());
  const [days, hours, minutes, seconds] = getReturnValues;
  // console.log(current);
  // const {timeReadyDuration} = current;
  const isMobile = useMobileAccess();
  const isNarrow = useMobileAccess(1200);
  const [keyNo, setKeyNo] = useState(0);
  // console.log(timeReadyDuration, keyNo);
  useEffect(() => {
    newPageLog("GetReady.js");
    if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
    // if(document.getElementById("viewer-navbar") !== null) {
    //   document.getElementById("viewer-navbar").style.backgroundColor = "";
    // }

    // document.body.classList.add("get-ready-background");
    // return () => {
    //   document.body.classList.remove("get-ready-background");
    // };
  }, []);

  const renderCounter = () => {
    if (seconds <= 0 && document.getElementById('root') !== null) {
      document.getElementById('root').style.backgroundImage = "none";
    } else {
      // document.getElementById("root").style.backgroundImage = `url(${window.innerWidth < 480 ? waitingRoomBackgroundMobile : waitingRoomBackground})`;
      // document.getElementById('root').style.backgroundPositionX= `${window.innerWidth < 480 ? "": "-200px"}`; 
      // document.getElementById('root').style.backgroundPositionY= `${window.innerWidth < 480 ? "": "105px"}`;
      // document.getElementById('root').style.backgroundRepeat = `${window.innerWidth < 480 ? "": "no-repeat"}`;
      // document.getElementById('root').style.backgroundColor = "#FCFBFF";
    }

    if (seconds === 1 && document.getElementById('get-ready-title') !== null) {
      document.getElementsByClassName('get-ready-title')[0].style.color = '#0A820E';
    }

    return (
        <div className="timer">
          <div tabIndex={0} role={TEXTBOX} aria-label={GET_READY_LABEL + (seconds-1) + SECONDS_LABEL} className="get-ready-timer-value">{seconds - 1}</div>
        </div>
    );
  }

  useEffect(() => {
    resetCountdown(questionStartTime);
  }, [questionStartTime]);

  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      handleGetReadyComplete();
    }
  }, [seconds]);

  useEffect(() => {
    setKeyNo(keyNo + 1);
  }, [timeReadyDuration]);

  return (
    <div className="gr-style" style={{ backgroundImage: window.innerWidth < 480 ? `url(${waitingRoomBackgroundMobile})` : `url(${waitingRoomBackground})`, backgroundPositionX: window.innerWidth >= 480 ? "-310px" : "", backgroundPositionY: window.innerWidth >=  480 ? "80px" : "", backgroundColor: "#FCFBFF", backgroundRepeat: "no-repeat"}}>
      <div className="waiting-room-title-container" style={{height: "fit-content" , marginTop: window.innerWidth < 480 ? 24 : "50px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}> 
      <span tabIndex={0} aria-label={POLL_TITLE_LABEL + pollTitle} role={TEXTBOX} className="waiting-room-title" style={{wordBreak:"break-word", paddingLeft: !isNarrow? "170px" : "10px", textAlign: "center", flex: "1"}}>{pollTitle}</span>
      <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState}/> </div>
      </div>
      <Container className="get-ready-background">
      <Row>
          <Col>
            <div role={TEXTBOX} tabIndex={0} className="get-ready-sub-title" id="title" style={{marginTop: "30px"}}>
              {GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION}
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: "10px"}}>
          <Col>
            <div className="timer-wrapper">
              <CountdownCircleTimer
                size={isNarrow ? 190 : 256}
                isPlaying
                key={"countdown-" + keyNo}
                duration={timeReadyDuration}
                colors={["#0277AD", "#0277AD", "#0277AD", "#0277AD", "#0277AD", "#0277AD", "#0277AD", "#0277AD", "#F1B843", "#F1B843", "#F1B843", "#0A820E"]}
                colorsTime={[11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]}
                // onComplete={handleGetReadyComplete}
                strokeWidth={20}
              >
                {renderCounter}
              </CountdownCircleTimer>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
