import React from "react";
import YuJaButton from "../../components/standardization/YuJaButton";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import {ADD_BLANK_TEXT, BLANKS, PREVIEW_TEXT, QUESTION_TYPES, TEXTBOX} from "../../utils/constants";
import "../styles.css";
import CreateQuestionGuidance from "../CreateQuestionGuidance";

export default function FITBRow({ desc, text, children, showButtons=false, handleAddBlank, handlePreview, questionBuilder=false, disabled=false, hasError=false, hasImage}) {
  return (
    <>
      <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        {!questionBuilder &&
            <>
                <div style={{display: "flex", alignItems: "center", gap: 20}}>
                    {desc &&
                        <>
                          <span role={TEXTBOX} tabIndex={0} className="fitb-desc no-padding">
                              <CreateQuestionGuidance questionType={QUESTION_TYPES.FITB.name}/>
                          </span>
                        </>
                    }
                    {text && <span role={TEXTBOX} tabIndex={0} className="fitb-titles no-padding" style={{fontSize: "20px"}}>{text}</span>}

                    {!hasImage && showButtons &&
                        <div style={{display: "flex", alignItems: "center", gap: 10, flex: 1}}>
                            <span style={{flex: 1}}/>
                            <YuJaButton
                                title={PREVIEW_TEXT}
                                onClick={handlePreview}
                                style={{
                                    width: "73px",
                                    height: "30px",
                                    fontSize: "14px",
                                    alignItems: "center",
                                    display: "flex"
                                }}
                            >
                                {PREVIEW_TEXT}
                            </YuJaButton>
                            <YuJaButton
                                title={ADD_BLANK_TEXT}
                                onClick={handleAddBlank}
                                style={{
                                    width: "82px",
                                    height: "30px",
                                    fontSize: "14px",
                                    alignItems: "center",
                                    display: "flex",
                                    whiteSpace: "nowrap",
                                    justifyContent: "center"
                                }}
                            >
                                {ADD_BLANK_TEXT}
                            </YuJaButton>
                        </div>
                    }
                </div>

                {!!hasImage && showButtons &&
                    <div style={{display: "flex", alignItems: "center", gap: 10}}>
                        <span style={{flex: 1}}/>
                        <YuJaButton
                            title={PREVIEW_TEXT}
                            onClick={handlePreview}
                            style={{
                                width: "73px",
                                height: "30px",
                                fontSize: "14px",
                                alignItems: "center",
                                display: "flex"
                            }}
                        >
                            {PREVIEW_TEXT}
                        </YuJaButton>
                        <YuJaButton
                            title={ADD_BLANK_TEXT}
                            onClick={handleAddBlank}
                            style={{
                                width: "82px",
                                height: "30px",
                                fontSize: "14px",
                                alignItems: "center",
                                display: "flex",
                                whiteSpace: "nowrap",
                                justifyContent: "center"
                            }}
                        >
                            {ADD_BLANK_TEXT}
                        </YuJaButton>
                    </div>
                }
            </>



        }
        {children}
      </div>
      {questionBuilder &&
          <>
            <div className="questionBuilderSubTitle" style={{marginBottom: 10, marginTop: hasError ? 40 : 25}}>{BLANKS}</div>
            <YuJaButton disabled={disabled} style={{ width: "100%", height: "40px" }} onClick={handleAddBlank}>
              <PlusIcon style={{ width: 15, height: 15, marginRight: 5 }} />{ADD_BLANK_TEXT}
            </YuJaButton>
          </>
      }
    </>
  );
}
