import {useRef, useState} from "react";
import {useYuJaEventBus} from "./YuJaEventBusProvider";
import {Editor} from '@tinymce/tinymce-react';

import classes from "./YuJaRichContentEditBox.module.css"
export const YuJaRichContentEditBox = ({value, setValue, placeholder, previewStyle={}}) => {
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();
    const [isFocused, setIsFocused] = useState(false);
    const [previewPadding, setPreviewPadding] = useState(10);
    const containerRef = useRef();
    const previewBoxRef = useRef();
    const editorRef = useRef();



    // useEffect(() => {
    //     const handleGlobalClick = (event) => {
    //         // Check if the click occurred outside of the dropdown
    //         if (isFocused && containerRef.current && !containerRef.current.contains(event.target) && !containerRef.current.contains(event.target)) {
    //             setIsFocused(false);
    //
    //         }
    //     };
    //
    //     if (isFocused) {
    //         addClickEventHandler(handleGlobalClick);
    //     }
    //
    //     return () => {
    //         removeClickEventHandler(handleGlobalClick);
    //     };
    // }, [isFocused]);


    // useEffect(() => {
    //     if (isFocused) {
    //         return;
    //     }
    //
    //     if (previewStyle.padding) {
    //         setPreviewPadding(previewStyle.padding);
    //         return;
    //     }
    //
    //     const inputFontSize = parseFloat(getComputedStyle(previewBoxRef.current).fontSize);
    //     if (inputFontSize <= 13) {
    //         setPreviewPadding(9);
    //     }
    //
    //     if (inputFontSize > 16) {
    //         setPreviewPadding(inputFontSize * 10 / 16);
    //     }
    // }, [isFocused, previewStyle]);
    const onChange = (value, editor) => {
        setValue(value, editor.getContent({ format: 'text' }));
    }

    const onPaste = (e) => {
        // e.preventDefault();
        // let pasteText = escapeHTML(e.clipboardData.getData("text"));
        // setValue(pasteText, pasteText);
        // let body = editorRef.current.getBody();
    }


    return (
        <div
            ref={containerRef}
            className={classes.parentContainer}
            // onClick={() => setIsFocused(true)}
        >
            {/*{!isFocused &&*/}
            {/*    <div*/}
            {/*        ref={previewBoxRef}*/}
            {/*        className={classes.previewBox}*/}
            {/*        style={{...previewStyle, padding: previewPadding}}*/}
            {/*    >*/}
            {/*        {value}*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{!!isFocused &&*/}
                <Editor
                    value={value}
                    onInit={(_evt, editor) => editorRef.current = editor}
                    onEditorChange={onChange}
                    tinymceScriptSrc={'/assets/libs/tinymce/tinymce.min.js'}
                    // initialValue={props.initialValue}
                    init={{
                        selector: 'textarea',
                        paste_as_text: true,
                        resize: false,
                        menubar: false,
                        branding: false,
                        elementpath: false,
                        placeholder: placeholder,
                        forced_root_block : 'div',
                        autoresize_bottom_margin: 0,
                        plugins: [
                            'advlist',
                            'autolink',
                            'lists',
                            'anchor',
                            'visualblocks',
                            'fullscreen',
                            'table',
                            'preview',
                            'help',
                            'wordcount',
                            'autoresize',
                            'paste'
                        ],
                        toolbar:
                            'undo|redo|' +
                            'bold|italic|underline|forecolor|backcolor|' +
                            'removeformat',

                        content_style: `body { font-family: url('/assets/fonts/SF-Pro.ttf'); font-size:16px; } 
                        span { width:100% } 
                        img {max-width: 100%; height: auto} 
                        ::-webkit-scrollbar { width: 10px !important; height: 10px !important; }

                        /* Track */
                        ::-webkit-scrollbar-track { background-color: transparent !important; }

                        /* Handle */
                        ::-webkit-scrollbar-thumb {
                            background-color: transparent !important;
                            border-radius: 6px !important;
                            border: 0px solid transparent !important;
                            background-clip: padding-box !important;
                        }

                        :hover::-webkit-scrollbar-thumb { background-color: #e8e8e8 !important; }

                        /* Handle on hover */
                        ::-webkit-scrollbar-thumb:hover { background: #bababa !important; }

                        ::-webkit-scrollbar-thumb:active { background: #7c7c7c !important; }`,

                    }}
                />
            {/*}*/}
        </div>
    );
}