/*global chrome*/
import React, {useEffect, useState} from "react";
import { useLocalStorageNew } from "../../../hooks/useLocalStorageNew";
import { isChrome } from "react-device-detect";
import { ACCESS, COMPLETED_CONNECT, COMPLETED_STEP, CONNECT_BTN, CONNECT_STEP, CONNECT_SUBTITLE, EXT_CONNECTION_ERROR, GET_CHROME_EXT_MESSAGE, INSTALL_BTN, INSTALL_STEP, INSTALL_SUBTITLE, INTEGRATED_PRODUCTS, INTEG_SUBTITLE, LOGOUT_CHROME_EXT_MESSAGE, MENTIMETER, MENTIMETER_EXTENSION_ID, MENTIMETER_STEPS, MENTIMETER_SUPPORT_URL, MENTIMETER_URL, MIGRATE_QUES, PE_STEPS, PE_SUPPORT_URL, PE_URL, POLL_EVERYWHERE, POLL_EVERYWHERE_EXTENSION_ID, SESSION, TEXTBOX, TOPHAT, TOPHAT_EXTENSION_ID, TOPHAT_STEPS, TOPHAT_SUPPORT_URL, TOPHAT_URL } from "../../../utils/constants";
import styles from "./CrossIntegration.module.css";
import YuJaButton from "../../../components/standardization/YuJaButton";
import {YuJaDropdown} from "../../../components/standardization/YuJaDropdown";
import {ReactComponent as Checkmark} from "../../../images/ext_integration_checkmark.svg";
import THExt from "../../../images/tophat.svg";
import MExt from "../../../images/mentimeter.svg";
import PEExt from "../../../images/polleverywhere.svg";
import Extension from "../../../images/extension.svg";
import { notifyError } from "../../../utils/helpers";

export default function CrossIntegration() {
    const [hasConnection, setHasConnection] = useState(false);
    const [extInstalled, setExtInstalled] = useState(false);
    const [, getSession] = useLocalStorageNew(SESSION, {});
    const [currPlatform, setCurrPlatform] = useState(MENTIMETER);
    const session = getSession();
    
    const getStepTitle = () => {
        return ACCESS + currPlatform + MIGRATE_QUES;
    };

    const getExtUrl = () => {
        if(currPlatform === TOPHAT) {
            return TOPHAT_URL;
        }
        else if(currPlatform === MENTIMETER) {
            return MENTIMETER_URL;
        }
        else if(currPlatform === POLL_EVERYWHERE) {
            return PE_URL;
        }
    };

    const getExtSupport = () => {
        if(currPlatform === TOPHAT) {
            return TOPHAT_SUPPORT_URL;
        }
        else if(currPlatform === MENTIMETER) {
            return MENTIMETER_SUPPORT_URL;
        }
        else if(currPlatform === POLL_EVERYWHERE) {
            return PE_SUPPORT_URL;
        }
    };
    
    const getPlatformSteps = () => {
        if(currPlatform === TOPHAT) {
            return TOPHAT_STEPS
        }
        else if(currPlatform === MENTIMETER) {
            return MENTIMETER_STEPS
        }
        else if(currPlatform === POLL_EVERYWHERE) {
            return PE_STEPS;
        }
    };

    const getPlatformImage = () => {
        if(currPlatform === TOPHAT) {
            return THExt;
        }
        else if(currPlatform === MENTIMETER) {
            return MExt;
        }
        else if(currPlatform === POLL_EVERYWHERE) {
            return PEExt;
        }
    };
    
    const steps = [
        {
            title: INSTALL_STEP,
            subtitle: INSTALL_SUBTITLE,
            button: INSTALL_BTN,
            completed: COMPLETED_STEP,
            img: Extension
        }, 
        {
            title: CONNECT_STEP,
            subtitle: CONNECT_SUBTITLE,
            button: CONNECT_BTN,
            completed: COMPLETED_CONNECT,
            img: Extension
        }, 
        {
            title: getStepTitle(),
            substeps: getPlatformSteps(),
            img: getPlatformImage()
        }
    ];

    const getExtensionId = () => {
        if(currPlatform === TOPHAT) {
            return TOPHAT_EXTENSION_ID;
        }
        else if(currPlatform === MENTIMETER) {
            return MENTIMETER_EXTENSION_ID;
        }
        else if(currPlatform === POLL_EVERYWHERE) {
            return POLL_EVERYWHERE_EXTENSION_ID;
        }
    };

    useEffect(() => {
        // 1. Get authorization token and userId from chrome extension
        try {
            chrome.runtime.sendMessage(getExtensionId(), {info: GET_CHROME_EXT_MESSAGE}, function(response) {
                // 2. If no response, or authorization token is invalid - ask user to connect again
                if(response) {
                    setExtInstalled(true);
                    if(response.authToken !== session.authorization || response.userId !== session.userId) {
                        chrome.runtime.sendMessage(getExtensionId(), {logout: LOGOUT_CHROME_EXT_MESSAGE}, function(response) {})
                        setHasConnection(false);
                    }
                    else {
                        setHasConnection(true);
                    }

                } else {
                    setHasConnection(false);
                    setExtInstalled(false);
                }
            })
        }
        catch(e) {
            setHasConnection(false);
            setExtInstalled(false);
        }
    }, [currPlatform]);
    
    const sendConnection = () => {
        chrome.runtime.sendMessage(getExtensionId(), {session: session}, function(response) {
            if(response && response.authToken && response.userId && response.authToken === session.authorization && response.userId === session.userId) {
                setHasConnection(true);
                setExtInstalled(true);
            } else {
                setHasConnection(false);
                notifyError(EXT_CONNECTION_ERROR);
            }
        });
    };
    
    const stepCompleted = (index) => {
        if(index === 0 && isChrome && extInstalled) {
            return true;
        }
        else if (index === 1 && hasConnection) {
            return true;
        }
        else {
            return false;
        }
    };

    return (
        <div>
            <div tabIndex={0} role={TEXTBOX} style={{marginTop: "10px"}}>{INTEG_SUBTITLE}</div>
            <div tabIndex={0} role={TEXTBOX} style={{display: "flex", alignItems: "center", marginBottom: "30px", marginTop: "20px"}}>
                Select Product
                <YuJaDropdown containerStyle={{width: "370px", marginLeft: "20px"}} value={currPlatform} data={INTEGRATED_PRODUCTS} onChange={(item) => {setCurrPlatform(item.value)}}></YuJaDropdown>
            </div>
            {steps.map((step, index) => {
                return (<>
                    {!stepCompleted(index) && <div className={styles.containerStep} style={index === 2 ? {height: "214px"} : {}} key={index}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={styles.stepNo}>{index+1}</div>
                            <div tabIndex={0} role={TEXTBOX} style={{marginLeft: "15px", fontWeight: "700"}}>{step.title}</div>
                            <div className={styles.line}></div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", height: "100%"}}>
                        <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column", paddingTop: "10px", height: "100%"}}>
                        {step.subtitle && <div tabIndex={0} role={TEXTBOX}>{step.subtitle}</div>}
                        {step.button && step.button === "Install Extension" &&
                            <YuJaButton onClick={() => (window.open(getExtUrl()))}>{step.button}</YuJaButton>
                        }
                        {step.button && step.button === "Connect" &&
                            <YuJaButton onClick={sendConnection}>{step.button}</YuJaButton>
                        }
                        {step.substeps && step.substeps.map((substep) => {
                                return <div key={substep} tabIndex={0} role={TEXTBOX}>{substep}</div>
                            })
                        }
                        </div>
                        <div>
                            <img src={step.img} className={index === 2 ? styles.extImage : styles.defaultImg} alt={"Chrome Extension Image Step " + index}/>
                            {index === 0 && <div>Not sure how to install the plugin? <a href={getExtSupport()} target="_blank">Click here</a>.</div>}
                        </div>
                        </div>
                    </div>}
                    {stepCompleted(index) && <div className={styles.completedContainer} key={index}>
                        <div>{step.completed}</div>
                        <Checkmark/>
                    </div>}
                </>)
            })}

        </div>
    );
};
