import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ACTIVATE_TIME, ATTENDANCE_OPTION_TIME_TEXT, ATTENDANCE_TIMED_DESCRIPTION, ATTENDANCE_TIME_LIMIT_MODAL_CONFIRM, ATTENDANCE_TIME_LIMIT_MODAL_TITLE, DEFAULT_ATTENDANCE_MANUAL_END, DEFAULT_ATTENDANCE_TIME_LIMIT, MODAL, PARTICIPANT_TIME_INTRO, PARTICIPANT_TIME_INTRO_ATTEDNANCE, POLL_LENGTH_SETTING_DESCRIPTION, POLL_TIME_LIMIT_MODAL_CANCEL, POLL_TIME_LIMIT_MODAL_CONFIRM, POLL_TIME_LIMIT_MODAL_SUBTITLE, POLL_TIME_LIMIT_MODAL_TITLE, POLL_TYPE, SURVEY_OPTION_TIME_TEXT, SURVEY_TIMED_DESCRIPTION, SURVEY_TIME_LIMIT_MODAL_CONFIRM, SURVEY_TIME_LIMIT_MODAL_TITLE, TEXTBOX, USER_SETTINGS } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import "./modal.css";
import { timeSecondsToCustomNew } from "../../utils/questionUtils";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";

export default function PollTimeLimitModal({ show, setShow, disabled, pollTimeLimit, userSettings, handleSubmit, pollType, manualEnd = DEFAULT_ATTENDANCE_MANUAL_END, attendanceDuration = DEFAULT_ATTENDANCE_TIME_LIMIT }) {
    const [, getSession] = useLocalStorageNew("session", {});
    const [timeLimit, setTimeLimit] = useState(0);
    const [attendanceTimeLimitH, setAttendanceTimeLimitH] = useState("");
    const [attendanceTimeLimitM, setAttendanceTimeLimitM] = useState("");

    const [optionSelected, setOptionSelected] = useState(true);
    // const handlePollTimeChangeH = (e) => {
    //     setPollTimeLimitH(e.target.value);
    // }

    // const handlePollTimeChangeM = (e) => {
    //     setPollTimeLimitM(e.target.value);
    // }

    const handleAttendanceTimeChangeH = (e) => {
        let hoursStr = e.target.value;
        if (!hoursStr) {
            setAttendanceTimeLimitH("");
            return;
        }
        const isValidNumber = /^\d+$/.test(hoursStr);
        if (!isValidNumber) {
            return;
        }
        hoursStr = hoursStr.length <= 2 ? hoursStr : hoursStr.substring(0,2);

        if (parseInt(hoursStr) > 23) {
            hoursStr = '23';
        }
        setAttendanceTimeLimitH(hoursStr);
    };

    const handleAttendanceTimeonBlurH = (e) => {
        let hours = attendanceTimeLimitH;
        const isValidNumber = /^\d+$/.test(attendanceTimeLimitH);
        if(isValidNumber && parseInt(attendanceTimeLimitH) > 0) {
            if(hours.length === 1) {
                hours = "0" + hours;
                setAttendanceTimeLimitH(hours);
            }
            if(parseInt(hours, 10) === 0){
                setAttendanceTimeLimitH("00");
            }
        } else {
            setAttendanceTimeLimitH("00");
        }
    }

    const handleAttendanceTimeChangeM = (e) => {
        let minStr = e.target.value;
        if (!minStr) {
            setAttendanceTimeLimitM("");
            return;
        }
        const isValidNumber = /^\d+$/.test(minStr);
        if (!isValidNumber) {
            return;
        }
        minStr = minStr.length <= 2 ? minStr : minStr.substring(0,2);

        if (parseInt(minStr) > 59) {
            minStr = '59';
        }
        setAttendanceTimeLimitM(minStr);
    };

    const handleAttendanceTimeonBlurM = (e) => {
        let minStr = attendanceTimeLimitM;
        const isValidNumber = /^\d+$/.test(attendanceTimeLimitM);
        if(isValidNumber && parseInt(attendanceTimeLimitM) > 0) {
            if(minStr.length === 1) {
                minStr = "0" + minStr;
                setAttendanceTimeLimitM(minStr);
            }
            if(parseInt(minStr, 10) === 0) {
                setAttendanceTimeLimitM("00");
            }
        } else {
            minStr = "00";
            setAttendanceTimeLimitM("00");
        }
    }

    useEffect(() => {
        if (!userSettings || !show) return;
        const duration = pollTimeLimit ? pollTimeLimit : userSettings[USER_SETTINGS.POLL_DURATION];
        setAttendanceTimeLimitH(String(Math.floor(duration / 3600)).padStart(2, '0'));
        setAttendanceTimeLimitM(String(Math.floor((duration % 3600) / 60)).padStart(2, '0'));
    }, [pollTimeLimit, userSettings, show]);

    useEffect(() => {
        if (!show || pollType !== POLL_TYPE.ATTENDANCE) return;
        setOptionSelected(manualEnd);
    }, [show, pollType, manualEnd, attendanceDuration]);

    useEffect(() => {
        setTimeLimit((attendanceTimeLimitH * 3600) + (attendanceTimeLimitM * 60));
    }, [attendanceTimeLimitH, attendanceTimeLimitM]);

    useEffect(() => {
        if (pollType === POLL_TYPE.ATTENDANCE) {
            const { settings = {} } = getSession();
            if (settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
                setAttendanceTimeLimitH(timeSecondsToCustomNew(settings[USER_SETTINGS.ATTENDANCE_DURATION]).split(":")[0]);
                setAttendanceTimeLimitM(timeSecondsToCustomNew(settings[USER_SETTINGS.ATTENDANCE_DURATION]).split(":")[1]);
            }
        }
    }, [show])


    const onSubmit = () => {
        if (pollType === POLL_TYPE.MERGED_POLL) {
            handleSubmit(timeLimit, optionSelected);
        } else {
            let date = new Date(Date.now() + (attendanceTimeLimitH * 3600 * 1000) + (attendanceTimeLimitM * 60 * 1000));
            handleSubmit(date, optionSelected);
        }
    }

    return (
        <Modal show={show} onHide={() => { setShow(false); setOptionSelected(true); }}>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ModalClose onClick={() => setShow(false)} className="modal-close" />
                <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0}
                    aria-label={"Set Poll Time Limit" + MODAL}
                    role={TEXTBOX}
                >
                    {pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_TIME_LIMIT_MODAL_TITLE : pollType === POLL_TYPE.MERGED_POLL ? POLL_TIME_LIMIT_MODAL_TITLE : SURVEY_TIME_LIMIT_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pollType === POLL_TYPE.MERGED_POLL ? <>
                    <div tabIndex={0} role={TEXTBOX} className="activatePollText">{POLL_TIME_LIMIT_MODAL_SUBTITLE}</div>
                    <div style={{ width: "100%", background: "white", display: "flex", marginTop: "25px", gap: 5 }}>
                        <div tabIndex={0} role={TEXTBOX} aria-label={ACTIVATE_TIME} style={{ color: "#000",  fontSize: "13.5px", fontWeight: "400", display: "flex", marginTop: "2px" }}>
                            {PARTICIPANT_TIME_INTRO}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input
                                aria-label={attendanceTimeLimitH + " hours"}
                                role={TEXTBOX}
                                className="custom-input-setting"
                                type="text"
                                maxLength={2}
                                value={attendanceTimeLimitH}
                                placeholder="HH"
                                onChange={handleAttendanceTimeChangeH}
                                onBlur={handleAttendanceTimeonBlurH}
                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                            />
                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}> Hours </div>
                        </div>
                        <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input
                                aria-label={attendanceTimeLimitM + " minutes"}
                                role={TEXTBOX}
                                className="custom-input-setting"
                                type="text"
                                maxLength={2}
                                value={attendanceTimeLimitM}
                                placeholder="MM"
                                onChange={handleAttendanceTimeChangeM}
                                onBlur={handleAttendanceTimeonBlurM}
                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                            />
                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}> Minutes </div>
                        </div>

                        <div tabIndex={0} role={TEXTBOX} aria-label={ACTIVATE_TIME} style={{ color: "#000",  fontSize: "13.5px", fontWeight: "400", display: "flex", marginTop: "2px" }}>
                            {POLL_LENGTH_SETTING_DESCRIPTION}
                        </div>
                    </div>
                </> : <>
                    <div className="activatePollText" style={{ fontWeight: 400 }}>
                        {pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_TIMED_DESCRIPTION : SURVEY_TIMED_DESCRIPTION}
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <input
                            type="checkbox"
                            checked={optionSelected}
                            onChange={() => setOptionSelected(!optionSelected)}
                            style={{ width: "15px", height: "15px", alignSelf: "center" }}
                        />
                        <div tabIndex={0} role={TEXTBOX} className="activatePollText">
                            {pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_OPTION_TIME_TEXT : SURVEY_OPTION_TIME_TEXT}
                        </div>
                    </div>
                    {/* {!optionSelected && <div style={{ paddingRight: "22px" }}>
                        <DatePickerComponentNew endDate={endDate} setEndDate={setEndDate} showStart={false} />
                    </div>} */}
                    {!optionSelected &&
                     <div style={{ display: "flex", gap: "5px" }}>
                     <span role={TEXTBOX} tabIndex={0} style={{ marginTop: "2px", fontSize: 14}}> {PARTICIPANT_TIME_INTRO_ATTEDNANCE}</span>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                            <input
                                aria-label={attendanceTimeLimitH + " hours"}
                                role={TEXTBOX}
                                className="custom-input-setting"
                                type="text"
                                maxLength={2}
                                value={attendanceTimeLimitH}
                                placeholder="HH"
                                onChange={handleAttendanceTimeChangeH}
                                onBlur={handleAttendanceTimeonBlurH}
                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                            />
                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}> Hours </div>
                        </div>
                        <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input
                                aria-label={attendanceTimeLimitM + " minutes"}
                                role={TEXTBOX}
                                className="custom-input-setting"
                                type="text"
                                maxLength={2}
                                value={attendanceTimeLimitM}
                                placeholder="MM"
                                onChange={handleAttendanceTimeChangeM}
                                onBlur={handleAttendanceTimeonBlurM}
                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                            />
                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}> Minutes </div>
                        </div>
                        </div>
                        }
                </>}
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton disabled={disabled} type="secondary" onClick={() => { setShow(false); setOptionSelected(true); }}>{POLL_TIME_LIMIT_MODAL_CANCEL}</YuJaButton>
                <YuJaButton disabled={disabled} onClick={onSubmit}>
                    {
                        pollType === POLL_TYPE.ATTENDANCE
                            ? ATTENDANCE_TIME_LIMIT_MODAL_CONFIRM
                            : pollType === POLL_TYPE.MERGED_POLL ? POLL_TIME_LIMIT_MODAL_CONFIRM : SURVEY_TIME_LIMIT_MODAL_CONFIRM
                    }
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}