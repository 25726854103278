import {useContext, useEffect, useState} from "react";
import {YuJaGlobalState} from "../utils/LoadingContext";
import {useWindowDimensions} from "react-native-web";
import {COLLAPSED_SIDE_BAR_WIDTH, EXPANDED_SIDE_BAR_WIDTH} from "../utils/constants";

export const useCheckMainContainerNarrow = () => {
    const {sidebarCollapsed=false} = useContext(YuJaGlobalState);
    let {width} = useWindowDimensions();
    const [isNarrow, setIsNarrow] = useState(false);
    // const isNarrow = windowWidth - (sidebarCollapsed ? COLLAPSED_SIDE_BAR_WIDTH : EXPANDED_SIDE_BAR_WIDTH)  < 1200;
    useEffect(() => {
        setIsNarrow(width - (sidebarCollapsed ? COLLAPSED_SIDE_BAR_WIDTH : EXPANDED_SIDE_BAR_WIDTH)  < 1200)
    }, [width, sidebarCollapsed]);


    return {isNarrow};
}
