import QRCode from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as ShareIcon } from "../../images/SHARE_ICON.svg";
import { ReactComponent as StopIcon } from "../../images/STOP_POLL_ICON.svg";
import ArrowIcon from "../../images/arrow_right.svg";
import { ReactComponent as Timer } from "../../images/green_timer.svg";
import { ReactComponent as Megaphone } from "../../images/megaphone.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as ThumbDown } from "../../images/openEndedThumbDown.svg";
import { ReactComponent as ThumbUp } from "../../images/openEndedThumbUp.svg";
import { ReactComponent as RedTimer } from "../../images/red_timer.svg";
import {
    ATTENDANCE,
    ATTENDANCE_END_TITLE,
    ATTENTION_TEXT,
    BLANK_REGEX,
    BLANK_REGEX_NEW,
    CLOSE,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    FIXED_ANSWER,
    NO_RESPONSES_TEXT,
    OPEN,
    OPEN_ENDED_DISLIKE_SORT,
    OPEN_ENDED_LIKE_SORT,
    OPEN_ENDED_TIME_SORT,
    POLL_SHARE_MODE,
    POLL_STATE,
    POLL_TYPE,
    QUESTION,
    QUESTION_IMAGE_ID,
    QUESTION_TYPES,
    RELEASE,
    SORT_BY_TEXT,
    STOP,
    USER_SETTINGS
} from "../../utils/constants";
import { convertTime } from "../../utils/helpers";
import { HOST_URL, } from "../../utils/properties";
import useQuery from "../../utils/queryparam";
import { questionCodeToName } from "../../utils/questionUtils";
import ClassResultRank from "../JoinQuizPage/ClassResultRank";
import WordCloudResult from "../JoinQuizPage/WordCloudResult";
import styles from "./Presentation.module.css";
import QuestionOptions from "./QuestionOptions";
import ViewActivityFeed from "../PublishedPollPage/ViewActivityFeed";
import parse from "html-react-parser";


export default function QuestionDetail({
    isLoading,
    poll, setPoll,
    question, setQuestion,
    userSettings
}) {
    const { getClassResult, getShortName, getActiveStudentsInPoll } = ResponseApiCalls();
    const { shareQuestionPresentation, stopQuestionPresentation, updatePollDetails, updateQuestionDetails } = PollApiCalls();
    const { pollCode, serialNo } = useParams();
    let query = useQuery();
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);

    const { pk="", pollType=POLL_TYPE.MERGED_POLL, pollTitle, totalQuestions } = poll;
    const { queTitle, questionType, weightage, optionsMap, optionsImageMap={}, hasBlankRestriction = false } = question;
    const pollKey = pk.substring("P#".length);

    const countDownRef = useRef();
    const [initialized, setInitialized] = useState(false);
    const [localState, setLocalState] = useState("START POLL");
    const [disabledButton, setDisabledButton] = useState(false);
    const [activeStudents, setActiveStudents] = useState({});

    const prevQuestionResultsRef = useRef([]);
    const intervalIdRef = useRef(0);
    const unanswered = useRef(0);
    const [newItems, setNewItems] = useState([]);
    const [questionResult, setQuestionResult] = useState([]);
    const [optionData, setOptionData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [questionImageURL, setQuestionImageURL] = useState("");
    const [isVideo, setIsVideo] = useState(false);
    const [queTimeLimit, setQueTimeLimit] = useState(0);
    const [queEnd, setQueEnd] = useState(0);
    const [pptAutoShare, setPPTAutoShare] = useState(null);
    const [manualEnd, setManualEnd] = useState(null);
    const [attendanceDuration, setAttendanceDuration] = useState(DEFAULT_ATTENDANCE_TIME_LIMIT);

    const [sortOption, setSortOption] = useState(OPEN_ENDED_TIME_SORT);
    const [openEndedData, setOpenEndedData] = useState(null);
    const [originalOpenEnded, setOriginalOpenEneded] = useState(null);

    useEffect(() => {
        if (!poll || !question || isLoading) {
            return;
        }

        const manualEnd = poll.hasOwnProperty("manualEnd") ? poll.manualEnd : userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END];
        const attendanceDuration = poll.hasOwnProperty("attendanceDuration") ? poll.attendanceDuration : userSettings[USER_SETTINGS.ATTENDANCE_DURATION];
        const defaultPptAutoShare = poll.pollType === POLL_TYPE.ATTENDANCE ? userSettings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] : userSettings[USER_SETTINGS.PPT_AUTO_SHARE];
        const pptAutoShare = poll.hasOwnProperty("pptAutoShare") ? poll.pptAutoShare : defaultPptAutoShare;
        setManualEnd(manualEnd);
        setAttendanceDuration(attendanceDuration);
        setPPTAutoShare(pptAutoShare);


        if (!!question.directLinkEvp) {
            // console.log(currQues.directLinkEvp)
            setQuestionImageURL(question.directLinkEvp);
            setIsVideo(true);
        } else if (!!question.imageURL) {
            setQuestionImageURL(question.imageURL);
            setIsVideo(false);
        } else {
            setQuestionImageURL("");

        }

        const { liveQuestionNo = 0, pollState = '', liveQuestionTime = 0, endTime } = poll;
        const { timeLimit } = question;
        // console.log(pollType, manualEnd, timeLimit);
        if (localState === "START POLL") {
            setQueTimeLimit(attendanceDuration);
            if (pollType === POLL_TYPE.ATTENDANCE) {
                if (!manualEnd) {
                    setQueEnd(Date.now() + attendanceDuration * 1000);
                }
            } else {
                setQueEnd(Date.now() + timeLimit * 1000);
            }
            // return;
        }
        if (initialized) {
            return;
        }
        setInitialized(true);
        // console.log(`liveQuestionNo: ${liveQuestionNo}, serialNo: ${serialNo}, ${liveQuestionNo != serialNo}`);
        if (liveQuestionNo != serialNo) {
            // console.log("different question");
            return;
        }
        if (pollState === POLL_STATE.STOPPED) {
            setLocalState("START POLL");
            return;
        }
        let queEndPoint = pollType === POLL_TYPE.ATTENDANCE ? new Date(endTime).getTime() : liveQuestionTime + timeLimit * 1000;
        const currentTime = new Date().getTime();
        // console.log(`liveQuestionTime: ${liveQuestionTime}, currentTime: ${currentTime}, queEndPoint: ${queEndPoint}`);
        // console.log(liveQuestionTime > currentTime, queEndPoint > currentTime, pollType.toUpperCase() === POLL_TYPE.SURVEY);
        if (liveQuestionTime > currentTime) {
            console.log("GET_READY");
            setLocalState("GET_READY");
        } else if ((pollType === POLL_TYPE.ATTENDANCE && pollState === POLL_STATE.SHARED && manualEnd)
            || queEndPoint > currentTime
            || (pollType === POLL_TYPE.MERGED_POLL && !timeLimit)
            // || pollType.toUpperCase() === POLL_TYPE.SURVEY
        ) {
            console.log("SHARED");
            setLocalState("SHARED");
        } else {
            console.log("TIMEUP/START POLL");
            return;
        }
        // if (pollType === POLL_TYPE.ATTENDANCE && !manualEnd) {
        setQueTimeLimit(timeLimit);
        // }
        setQueEnd(queEndPoint);
        countDownRef.current?.start();
    }, [poll, question, localState, isLoading]);

    useEffect(() => {
        console.log(`localState: ${localState}`);
        if (localState === "SHARED" || localState === "GET_READY") {
            countDownRef.current?.start();
        }
    }, [localState]);

    const handleShare = async () => {
        if (disabledButton) {
            return;
        }
        setDisabledButton(true);
        let token = query.get("token");

        const endDate = manualEnd ? null : new Date(new Date().getTime() + attendanceDuration * 1000);
        let timeLimit = endDate ? attendanceDuration : null;
        if (pollType === POLL_TYPE.ATTENDANCE) {
            // console.log(`manualEnd: ${manualEnd}, endDate: ${endDate}, timeLimit: ${timeLimit}`);
            if (endDate) {
                const pollDetails = {};
                pollDetails.pollShareMode = POLL_SHARE_MODE.SHARE_EACH; // should be set to shareEach when inserted
                pollDetails.endTime = endDate;
                // console.log(`updatePollDetails: ${pollKey}, ${pollCode}, ${pollDetails}`);
                await updatePollDetails(pollKey, pollCode, pollDetails, true);
                if (timeLimit && timeLimit !== question.timeLimit) {
                    // console.log(`updateQuestionDetails: ${pollKey}, ${pollCode}, ${serialNo}, ${timeLimit}`);
                    await updateQuestionDetails(pollKey, pollCode, serialNo, {timeLimit: timeLimit});
                }
            } else {
                const pollDetails = {};
                pollDetails.pollShareMode = POLL_SHARE_MODE.SHARE_EACH; // should be set to shareEach when inserted
                pollDetails.endTime = "";
                // console.log(`updatePollDetails: ${pollKey}, ${pollCode}, ${pollDetails}`);
                await updatePollDetails(pollKey, pollCode, pollDetails, true);
            }

            // setAttendanceEndtime(endDate);
            setPoll({...poll, endTime: endDate});
            setQuestion({...question, timeLimit: timeLimit});
            // setQueTimeLimit(timeLimit);
            // setQueEnd(endDate ? endDate.getTime() : 0);
        }

        shareQuestionPresentation(pollKey, pollCode, serialNo, pollType, token).then((res) => {
            let quesTimeLimit = pollType === POLL_TYPE.ATTENDANCE && timeLimit ? timeLimit : question.timeLimit;
            let quesEnd = res.liveQuestionTime + parseInt(getTimeOffset()) + quesTimeLimit * 1000;
            // console.log(`quesTimeLimit: ${quesTimeLimit}, quesEnd: ${quesEnd}`);
            setQueTimeLimit(quesTimeLimit);
            setQueEnd(quesEnd);
            if (pollType === POLL_TYPE.ATTENDANCE) {
                setLocalState('SHARED');
            } else {
                setLocalState('GET_READY');
            }
            countDownRef.current.start();
        }).finally(() => {
            setDisabledButton(false);
        });
    };

    const handleStop = () => {
        if (disabledButton) {
            return;
        }
        setDisabledButton(true);
        let token = query.get("token");
        stopQuestionPresentation(pollKey, pollCode, serialNo, pollType, token).then(() => {
            setQueEnd(0);
            setLocalState("TIMEUP");
            setTimeout(() => {
                setLocalState("START POLL");
            }, 2000);
        }).finally(() => {
            setDisabledButton(false);
        });
    };

    useEffect(() => {
        if (!pollType || isLoading) {
            return;
        }
        if (pptAutoShare) {
            handleShare();
        }
    }, [isLoading, pptAutoShare, manualEnd, pollType]);

    //get result
    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name || questionType === QUESTION_TYPES.RK.name) {
            getClassResult(pollKey, pollCode, question, null, true).then((result) => {
                    console.log(`fetch class result: ${JSON.stringify(result)}`)
                if (Object.entries(result).length > 0 ) {
                    setQuestionResult(result);
                    if (questionType === QUESTION_TYPES.SA.name) {
                        updateNewItems(result)
                    } else if (questionType === QUESTION_TYPES.FITB.name) {
                        updateFITBItems(result);
                    } else if (questionType === QUESTION_TYPES.RK.name) {
                        setOptionData(result);
                    }
                }
            });
        } else if (questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.TF.name || questionType === QUESTION_TYPES.CI.name) {
            getClassResult(pollKey, pollCode, question).then((result) => {
                setOptionsByData(result);
            });
        } else if (questionType === QUESTION_TYPES.OE.name) {
            getClassResult(pollKey, pollCode, question, null, 'all').then((result) => {
                if(result && result.responses) {
                    setQuestionResult(result.responses);
                }
                if (result && result.openEnded && Object.keys(result.openEnded).length >= 1) {
                setOriginalOpenEneded(result.openEnded);
                setOpenEndedData(Object.entries(result.openEnded))
                handleSort();
                }
            })
        }
        getActiveStudentsInPoll(pollKey, pollCode).then(resp => {
            const {students=[]} = resp;
            const lastHeartbeat = Date.now();
            resp.students = students.map(i => {
                i.lastHeartbeat = lastHeartbeat;
                return i;
            });
            setActiveStudents(resp);
        });

        intervalIdRef.current = setInterval(() => {
            if (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name || questionType === QUESTION_TYPES.RK.name) {
                getClassResult(pollKey, pollCode, question, null, true).then((result) => {
                        console.log(`fetch class result: ${JSON.stringify(result)}`)
                    if (Object.entries(result).length > 0 ) {
                        setQuestionResult(result);
                        if (questionType === QUESTION_TYPES.SA.name) {
                            updateNewItems(result);
                        } else if (questionType === QUESTION_TYPES.FITB.name) {
                            updateFITBItems(result);
                        } else if (questionType === QUESTION_TYPES.RK.name) {
                            setOptionData(result);
                        }
                    }
                });
            } else if (questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.TF.name || questionType === QUESTION_TYPES.CI.name) {
                getClassResult(pollKey, pollCode, question).then((result) => {
                    setOptionsByData(result);
                });
            } else if (questionType === QUESTION_TYPES.OE.name) {
                getClassResult(pollKey, pollCode, question, null, 'all').then((result) => {
                    if(result && result.responses) {
                        setQuestionResult(result.responses);
                    }
                    if (result && result.openEnded && Object.keys(result.openEnded).length >= 1) {
                    setOriginalOpenEneded(result.openEnded);
                    setOpenEndedData(Object.entries(result.openEnded))
                    handleSort();
                    }
                })
            }

            getActiveStudentsInPoll(pollKey, pollCode).then(resp => {
                const {students=[]} = resp;
                const lastHeartbeat = Date.now();
                resp.students = students.map(i => {
                    i.lastHeartbeat = lastHeartbeat;
                    return i;
                });
                setActiveStudents(resp);
            });
        } , 5000);

        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        }
    }, [isLoading]);


    const handleSort = () => {
        if (sortOption === OPEN_ENDED_TIME_SORT && originalOpenEnded) {
          setOpenEndedData(Object.entries(originalOpenEnded));
        } else if (sortOption === OPEN_ENDED_LIKE_SORT && Object.keys(originalOpenEnded).length > 1) {
          let entries = Object.entries(originalOpenEnded);
          entries.sort(([, valueA], [, valueB]) => {
            return valueB["1"] - valueA["1"];
          });
          setOpenEndedData(entries);
        } else if (sortOption === OPEN_ENDED_DISLIKE_SORT && Object.keys(openEndedData).length > 1) {
          let entries = Object.entries(originalOpenEnded);
          entries.sort(([, valueA], [, valueB]) => {
            return valueB["2"] - valueA["2"];
          });
          setOpenEndedData(entries);
        }
      };


      useEffect(() => {
        handleSort();
  
      }, [sortOption]);

    function setOptionsByData(res) {
        //remove unanswered res
        let filteredData = [];
        res.forEach((r) => {
            if (r.ans !== "Unanswered") {
                filteredData.push({...r});
            } else {
                unanswered.current = r.people;
            }
        });

        let optionArrData = [];
        let tempTotalData = 0;
        filteredData.forEach((result) => {
            if (questionType === QUESTION_TYPES.MCSS.name) {
                optionArrData.push({ index: result.ans.split(". ")[0], value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
            } else if (questionType === QUESTION_TYPES.CI.name) {
                if (result.ans === FIXED_ANSWER.OTHER) {
                    optionArrData.push({ index: FIXED_ANSWER.OTHER, value: result.people});
                } else {
                    optionArrData.push({ index: result.ans.split(". ")[0].toUpperCase(), value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
                }
            }else if (questionType === QUESTION_TYPES.TF.name) {
                optionArrData.push({ index: result.ans, value: result.people });
            } else {
                optionArrData.push({ index: result.ans, value: result.people});
            }
            tempTotalData += result.people
        })
        setTotalCount(tempTotalData);
        setOptionData(optionArrData);
    }


    function updateNewItems(arr1) {
        const list = [];

        const prevList = prevQuestionResultsRef.current;
        if (arr1.length >= prevList.length) {
            const smallerLength = Math.min(arr1.length, prevList.length);
            const length_diff = Math.abs(arr1.length - prevList.length);
            for (let i = 0; i < smallerLength; i++) {
                if (arr1[i + length_diff] !== FIXED_ANSWER.UNANSWERED && prevList[i]["0"] != FIXED_ANSWER.UNANSWERED && arr1[i + length_diff]["0"] !== prevList[i]["0"]) {
                    list.push(arr1[i + length_diff]);
                }
            }
            const remainingArray = arr1.length >= prevList.length ? arr1 : prevList;
            for (let i = 0; i < length_diff; i++) {
                if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(remainingArray[i]);
                }
            }
        }
        prevQuestionResultsRef.current = arr1;
        setNewItems(list);
    }


    function updateFITBItems(arr1) {
        if (!arr1) {
            return;
        }

        const list = [];
        const prevList = prevQuestionResultsRef.current;
        if (arr1.length > prevList.length) {
            const smallerLength = Math.min(arr1.length, prevList.length);
            const length_diff = Math.abs(arr1.length - prevList.length);

            for (let i = 0; i < smallerLength; i++) {
                if (arr1[i + length_diff] != FIXED_ANSWER.UNANSWERED && prevList[i] != FIXED_ANSWER.UNANSWERED){
                    if (Object.keys(arr1[i + length_diff]).length !== Object.keys(prevList[i]).length) {
                        list.push(arr1[i + length_diff]);
                    }
                    else {
                        const keys = Object.keys(arr1[i + length_diff]);
                        let valuesMatch = true;

                        for (let j = 0; j < keys.length; j++) {
                            const key = keys[j];
                            if (arr1[i + length_diff][key] !== prevList[i][key]) {
                                valuesMatch = false;
                                break;
                            }
                        }

                        if (!valuesMatch) {
                            list.push(arr1[i + length_diff]);
                        }
                    }
                }
            }

            const remainingArray = arr1.length >= prevList.length ? arr1 : prevList;
            for (let i = 0; i < length_diff; i++) {
                if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(remainingArray[i]);
                }
            }
        }
        prevQuestionResultsRef.current = arr1;
        setNewItems(list);
    }

    const renderer = ({ days, hours, minutes, seconds }) => {
        if ((pollType === POLL_TYPE.ATTENDANCE && manualEnd)
            // || (pollType.toUpperCase() === POLL_TYPE.SURVEY && localState !== "GET_READY")
        ) {
            return null;
        }

        const hoursView = ('00' + ((days*24)+hours)).slice(-2);
        const minutesView = ('00' + minutes).slice(-2);
        const secondsView = ('00' + seconds).slice(-2);
        const urgent = days === 0 && hours === 0 && minutes === 0 && seconds < 4;

        const time = (seconds + minutes * 60 + hours * 3600 + days * 86400);

        const showTimer = true;
        // const showTimer = (time > queTimeLimit)
        //     || (pollType === POLL_TYPE.ATTENDANCE && time <= ATTENDANCE_COUNTDOWN_START)
        //     || (pollType !== POLL_TYPE.ATTENDANCE)
        //     || localState === "START POLL";

        console.log(`showTimer: ${showTimer}`);
        console.log(`time: ${time}, queTimeLimit: ${queTimeLimit}`);
        // if (time > queTimeLimit) {
        //     setLocalState("GET_READY");
        // }
        
        if (localState === "GET_READY" && time <= queTimeLimit) {
            setLocalState("SHARED");
        }

        return (
            <>
                {showTimer &&
                    <div className={styles.gradientWrapQuesType} style={{height: 38}}>
                        <div className={styles.questionPanelQuestionTypeContainer}>
                            {localState === "GET_READY" ?
                                <div className="pollPanelTimerText">
                                    <Timer style={{marginRight: "10px"}}/>
                                    Get Ready: {time - queTimeLimit - 1}
                                </div> :
                            // {(localState === "SHARED" || localState === "START POLL" || localState === "TIMEUP") &&
                                <div className={`pollPanelTimerText ${urgent ? 'urgent' : ''}`}>
                                    {urgent ? <RedTimer className="timerMargin"/> :<Timer className="timerMargin"/>}{hoursView}:{minutesView}:{secondsView}
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
        );
    };

    function timeUp() {
        if (
            (pollType === POLL_TYPE.ATTENDANCE && manualEnd)
            || (pollType === POLL_TYPE.MERGED_POLL && !queTimeLimit)
            // || pollType.toUpperCase() === POLL_TYPE.SURVEY
        ) {
            return;
        }

        setQueEnd(0);
        setLocalState("TIMEUP");
        setTimeout(() => {
            setLocalState("START POLL");
        }, 2000);
        setTimeout(() => {
            if(intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        }, 5000);
    }


    return (<div style={{ width: "100%", height: "100%", padding: 40 }}>
        <div className={styles.pollDetailContainer}>
            {pollType === POLL_TYPE.ATTENDANCE && localState === "SHARED" && !!queEnd && !manualEnd &&
                <div style={{ backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", fontSize: "14.5px", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "6.25px", width: "100%" }}>
                    <Megaphone style={{ marginRight: "10px" }} />
                    <strong style={{ marginRight: "5px" }}>{ATTENTION_TEXT}</strong>
                    {Date.now() < new Date(queEnd) ? <>{ATTENDANCE_END_TITLE}<strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(queEnd)}</strong></> : (<>
                    </>)}
                </div>
            }
            <div className={styles.pollTitlePanelContainer} style={pollType === POLL_TYPE.ATTENDANCE && localState === "SHARED" && !!queEnd && !manualEnd ? { marginTop: "1.5%" } : {}}>
                <div>
                    <div className={styles.pollPanelSubtitle}>Activity Title</div>
                    {isLoading ?
                        <Skeleton borderRadius={10} width={150 + 200 * Math.random()} height={28} style={{ marginTop: 10 }} /> :
                        <div className={styles.pollPanelTitle}>{pollTitle}</div>
                    }
                </div>
                {isLoading ? <Skeleton borderRadius={10} width={195} height={52} /> : <>
                    {!pptAutoShare && localState === "START POLL" &&
                        <div className="gradientWrapPoll" onClick={handleShare} style={{ cursor: disabledButton ? "not-allowed" : "pointer" }}>
                            <span className="gradientBtnPublish" tabIndex={0}>
                                <ShareIcon style={{ marginRight: "10px" }} />{pollType === POLL_TYPE.ATTENDANCE ? OPEN + ATTENDANCE : RELEASE + QUESTION + serialNo}
                            </span>
                        </div>
                    }
                    {(pollType === POLL_TYPE.ATTENDANCE ? manualEnd : !pptAutoShare) && (localState === "GET_READY" || localState === "SHARED") &&
                        <div className="gradientWrapPoll" onClick={handleStop} style={{ cursor: disabledButton ? "not-allowed" : "pointer" }}>
                            <span className="gradientBtnStop" tabIndex={0}>
                                <StopIcon style={{ marginRight: "10px" }} />{pollType === POLL_TYPE.ATTENDANCE ? CLOSE + ATTENDANCE : STOP + QUESTION + serialNo}
                            </span>
                        </div>
                    }
                    {(pollType === POLL_TYPE.ATTENDANCE ? manualEnd : !pptAutoShare) && localState === "TIMEUP" &&
                        <div className="grayedGradientWrap" style={{ opacity: 0.5, width: 195 }}>
                            <span className="grayedGradientBtnStop">
                                <StopIcon style={{ marginRight: "10px" }} />{pollType === POLL_TYPE.ATTENDANCE ? CLOSE + ATTENDANCE : STOP + QUESTION + serialNo}
                            </span>
                        </div>
                    }
                </>}
            </div>
            <div style={{display: "flex", width: "100%", flex: 1, minHeight: 0, justifyContent:"space-between", marginTop: "1.5%"}}>
                <div className={styles.QRCodePanel}>
                    {isLoading ?
                        <Skeleton borderRadius={10} width={128} height={128} style={{margin: "10px"}}/> :
                        <QRCode
                            bgColor={"#f6f6f6"}
                            style={{ margin: "10px" }}
                            renderAs="canvas"
                            value={`https://${getShortName()}.${HOST_URL}/qr/${pollCode}`}
                        />
                    }
                    <div className={styles.QRCodeText}>{`#${pollCode}`}</div>
                    <div style={{marginTop: "30px", flex: 1}}> <ViewActivityFeed data={activeStudents} localState={localState} collapsed={false} pollType={pollType} isPresentation={true}/> </div>
                </div>
                <div className={styles.questionPanel}>
                    {pollType !== POLL_TYPE.ATTENDANCE && <div className={styles.questionPanelTitle}>
                        {isLoading && <Skeleton borderRadius={10} height={38} containerClassName={styles.skeletonRowWrapper} />}
                        {!isLoading && <>
                            <div className={styles.questionPanelCurSerialNo}>{serialNo}</div>
                            <div className={styles.questionPanelTotalCount}>of {totalQuestions}</div>
                            <div className={styles.gradientWrapQuesType}>
                                <div className={styles.questionPanelQuestionTypeContainer}>
                                    {questionCodeToName(questionType)}
                                </div>
                            </div>
                        </>}
                        {!isLoading && pollType === POLL_TYPE.MERGED_POLL && !!weightage &&
                            <div className={styles.gradientWrapQuesType}>
                                <div className={styles.questionPanelQuestionTypeContainer}>
                                    {`${weightage} points`}
                                </div>
                            </div>
                        }
                        <div style={{flex: 1}}/>
                        {!isLoading && (!!queEnd &&
                            <Countdown
                                ref={countDownRef}
                                className="pollPanelTimerText"
                                autoStart={false}
                                date={queEnd}
                                key={queEnd}
                                renderer={renderer}
                                onComplete={timeUp}>
                            </Countdown>
                            // : <div className={styles.gradientWrapQuesType}>
                            //     <div className={styles.questionPanelQuestionTypeContainer}>
                            //         <div className="pollPanelTimerText urgent">
                            //             <RedTimer className="timerMargin"/>00:00:00
                            //         </div>
                            //     </div>
                            // </div>
                        )}
                    </div>}
                    <div className={pollType === POLL_TYPE.ATTENDANCE ? styles.attendanceQuestionPanelQueTitle : styles.questionPanelQueTitle}>
                        {isLoading && <Skeleton borderRadius={10} width={100 + 100 * Math.random()} />}
                        {!isLoading && (questionType === QUESTION_TYPES.FITB.name ? queTitle.replace(hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX, "______") : parse(queTitle))}
                        {!isLoading && pollType === POLL_TYPE.ATTENDANCE && (!!queEnd &&
                            <Countdown
                                ref={countDownRef}
                                className="pollPanelTimerText"
                                autoStart={false}
                                date={queEnd}
                                key={queEnd}
                                renderer={renderer}
                                onComplete={timeUp}>
                            </Countdown>
                            // : <div className={styles.gradientWrapQuesType}>
                            //     <div className={styles.questionPanelQuestionTypeContainer}>
                            //         <div className="pollPanelTimerText urgent">
                            //             <RedTimer className="timerMargin"/>00:00:00
                            //         </div>
                            //     </div>
                            // </div>
                        )}
                    </div>
                    <div style={{display: "flex", width: "100%", flex: 1}}>
                        {/*Question option*/}
                        <div style={{width: '65%', marginRight: 10}}>
                            {(questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.TF.name || questionType === QUESTION_TYPES.CI.name) &&
                                <QuestionOptions options={optionData} setOptions={setOptionData} total={totalCount} serialNo={serialNo} optionsImageMap={optionsImageMap}/>
                            }
                            {questionType === QUESTION_TYPES.RK.name &&
                                <>
                                    {(!optionData || Object.entries(optionData).length === 0) &&
                                        <div className={styles.chartContainerSharePoll} >
                                            {optionsMap.filter(e => !!e).map((entry, index) => (
                                                <div className={styles.optionContainer} key={index}>
                                                    <div className={styles.option} id={"Option" + index}>
                                                        <div id={"OptionPart1" + index} className="questionPanelOptionsTextMC" tabIndex={0} aria-label={entry} style={{textAlign: "left"}}>
                                                            Rank {index + 1}: {entry}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {!!optionData && Object.entries(optionData).length > 0 &&
                                        <ClassResultRank
                                            loading={false}
                                            chartData={optionData}
                                        />
                                    }


                                </>
                            }

                            {questionType === QUESTION_TYPES.MH.name &&
                                <div style={{ display: "flex", overflow: "auto" }}>
                                    <div >
                                        {optionsMap.map((element, index) => (
                                            <div key={"Normal" + index} id={"NormalOption" + index} className="questionPanelOptionsContainer" style={{ marginBottom: "0px", gap: "10px" }}>
                                                {element.length > 1 ?
                                                    <>
                                                        <div className="questionPanelAnswersText" id={"NormalPart1" + index} style={{ marginRight: "0px", border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1 }}>{element[0]}</div>
                                                        <img
                                                            src={ArrowIcon}
                                                            alt="Arrow icon"
                                                            className="questions-page-icons cursor-pointer"
                                                            style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                                                        /> </> : <> <div className="questionPanelAnswersText" id={"NormalPart1" + index} style={{ marginRight: "0px", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1 }}></div>
                                                        <img
                                                            className="questions-page-icons cursor-pointer"
                                                            style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                                                        /> </>}
                                                <div className="questionPanelAnswersText" id={"NormalPart2" + index} style={{ border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1 }}>{element.length > 1 ? element[1] : element[0]}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            {questionType === QUESTION_TYPES.FITB.name &&
                                <>
                                    {questionResult.map((item, idx) => {
                                        if (item !== "unanswered") {
                                            const combinedString = Object.keys(item)
                                                .map((key) => `"${item[key]}"`)
                                                .join(", ");

                                            return (
                                                <div
                                                    className={`questionPanelAnswersText ${newItems.includes(item) ? "resultAnimation" : ""
                                                    }`}
                                                    style={{
                                                        marginTop: "20px",
                                                        backgroundColor: "#f4f4f4",
                                                        width: "fit-content",
                                                        padding: "15px",
                                                    }}
                                                    key={idx}
                                                >
                                                    {combinedString}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </>
                            }
                            {questionType === QUESTION_TYPES.OE.name &&
                                <div style={{ border: "1px solid #E8EDF1", borderRadius: "7.5px", padding: "20px", maxHeight: '600px', overflowY: "auto" }}>
                                    {questionResult && Object.entries(questionResult).length && openEndedData && openEndedData.filter(entry => questionResult[entry[0]] && questionResult[entry[0]] !== FIXED_ANSWER.UNANSWERED).length ? <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "end", width: "100%", flex: 1 }}>
                                                <div style={{ fontSize: 16, color: "#0000000" }}> {SORT_BY_TEXT}</div>
                                                <YuJaDropdown
                                                    data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                                                    getOptionLabel={item => item}
                                                    getOptionValue={item => item}
                                                    onChange={option => setSortOption(option)}
                                                    value={sortOption}
                                                    containerStyle={{ width: 130 }}
                                                    style={{ padding: "7px 10px", fontSize: 15, border: "1px solid #6F6F6F", color: "#4C4C4C" }}
                                                />
                                            </div>
                                        </div>
                                        {openEndedData?.map(([key, value], index) =>
                                            questionResult && questionResult[key] !== FIXED_ANSWER.UNANSWERED && questionResult[key] ? (
                                                <div
                                                    style={{
                                                        marginTop: "20px",
                                                        border: "1px solid #E8EDF1",
                                                        color: "#000", borderRadius: "7.4px",
                                                        minHeight: '94px', padding: 10, wordBreak: "break-word"
                                                    }}
                                                >
                                                    {`${questionResult[key]["0"]}`}
                                                    <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between", height: 35, width: 165, marginTop: 10, padding: "5px 20px" }}>
                                                        <div style={{ display: "flex", gap: '5px' }}>

                                                            <div>  <ThumbUp /> </div>
                                                            <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {value[1]} </div>
                                                        </div>

                                                        <div style={{ display: "flex", gap: '5px' }}>
                                                            <div>  <ThumbDown /></div>
                                                            <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {value[2]} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : <></>
                                        )}
                                    </> :
                                        <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                                            <NoResultIllustration width={192} height={100} />
                                            <div style={{ fontSize: 18, marginTop: 10, fontWeight: 600 }}>
                                                {NO_RESPONSES_TEXT}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {questionType === QUESTION_TYPES.SA.name && pollType !== POLL_TYPE.ATTENDANCE &&
                                <>
                                    {questionResult.map((item) =>
                                        item !== FIXED_ANSWER.UNANSWERED ? (
                                            <div
                                                className= {`questionPanelAnswersText ${newItems.includes(item) ? 'resultAnimation' : ''}`}
                                                style={{ marginTop: "20px", backgroundColor: "#f4f4f4", width: "fit-content", padding: "15px"}}
                                            >
                                                {`"${item["0"]}"`}
                                            </div>
                                        ) : <></>
                                    )}
                                </>
                            }

                            {questionType === QUESTION_TYPES.WC.name &&
                                <WordCloudResult
                                    data={questionResult}
                                    pollType={pollType}
                                />
                            }

                        </div>
                        {/*Question Image*/}
                        {!!questionImageURL && !!isVideo &&
                            <div className={styles.imgContainerShare} id={QUESTION_IMAGE_ID} style={{height:"100%", padding: 10}}>
                                <iframe
                                    allowFullScreen
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        mixBlendMode: "normal",
                                        boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)"
                                    }}
                                    src={questionImageURL}
                                />
                            </div>

                        }

                        {!!questionImageURL && !isVideo && (
                            <div className={styles.imgContainerShare} id={QUESTION_IMAGE_ID}>
                                <div >
                                    <img
                                        style={{ maxWidth: "100%", maxHeight: "100%", mixBlendMode: "normal",
                                            boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)"
                                        }}
                                        src={questionImageURL} alt="Question" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center", gap: 5, visibility: isLoading ? "hidden" : "visible"}}>
                        <div className={styles.descriptionText}>Scan the QR Code</div>
                        <div className={styles.descriptionText}>OR</div>
                        <div className={styles.descriptionText}>Join as a participant at:&nbsp;
                            <span style={{textDecoration: "underline", color: "blue"}}>{`https://${getShortName()}.${HOST_URL}`}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}