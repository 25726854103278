// import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./EnrolledCourses.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";

export default function EnrolledCoursesSearchForm({ handleSubmit }) {
    const [keyword, setKeyword] = useState("");
    const [date, setDate] = useState("");

    const clickSearch = () => {
        let formattedDate = date ? date.split("-").reverse().join("/") : "";
        handleSubmit({ keyword: keyword, date: formattedDate });
    }

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }

    return (
        <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <Form.Control
                    className={styles.searchBoxKeywordInput}
                    placeholder="Find by Course Code or Name"
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                    aria-label="Find by Course Code or Name"
                >
                </Form.Control>

                {/* Old date picker */}
                {/* <Form.Control
                    className={styles.searchBoxDateInput}
                    type="date"
                    placeholder="Date"
                    onChange={e => setDate(e.target.value)}
                    value={date}
                >
                </Form.Control> */}
                
                {/* New date picker */}
                {/* <DatePickerInput
                    displayFormat='DD-MM-YYYY'
                    style={{padding : "20px"}}
                    placeholder="DD-MM-YYYY"
                    onChange={value => {
                        if(!isNaN(value)){
                            setDate(value.toISOString().split('T')[0]);
                        } else {
                            setDate("");
                        }
                    }}
                    value={date}
                /> */}
                <YuJaButton onClick={clickSearch}>Search</YuJaButton>
            </Form>
        </div>
    );
}
