import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import EditCourseModal from "../../components/modals/EditCourseModal";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import NormalPagination from "../../components/pagination/NormalPagination";
import { YuJaKebabMenu } from "../../components/standardization/YuJaKebabMenu";
import { ReactComponent as ViewIcon } from "../../images/View.svg";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import { ReactComponent as EditIcon } from "../../images/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import {ReactComponent as NoResultIllustration} from "../../images/no_result.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ACTION_HEADER, ACT_ITEMS, COURSE_CHARACTER_LIMIT, COURSE_CODE, COURSE_NAME, DEFAULT_ROWS_PER_PAGE, DELETE_COURSE, DELETE_COURSE_MODAL_BODY, EDIT_TEXT, ENROLLED_MEMBERS, MODAL_CANCEL_TEXT, MODAL_DELETE_TEXT, NO_RESULTS_FOUND, TEXTBOX, VIEW_TEXT } from "../../utils/constants";
import styles from "./ManageCourses.module.css";

export default React.memo(CourseTable);
function CourseTable({ data = [], columns = [], pageIdx=0, globalFilterObj = {}, getCourses, sortCourseName, sortCourseCode, courseNameSort, courseCodeSort, enrolledMemberSort, sortEnrolledMembers}) {
    // const {loading} = useLoading();
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [scrollableContainer, setScrollableContainer] = useState(false);
    const [currentCourseId, setCurrentCourseId] = useState("");
    const { deleteCourse } = CourseApiCalls();
    const [currentRow, setCurrentRow] = useState(null);
    const [sortSelected, setSortSelected] = useState(-1); // sorted by date but not displayed

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        // const filteredDate = globalFilterValue.date;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.courseName && values.courseName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (values.courseCode && values.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        });
        // .filter(r => {
        //     if (!filteredDate) {
        //         return true;
        //     }
        //     const values = r.values;
        //     return values.startDate && values.startDate >= filteredDate;
        // });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);

    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
        } else if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }
    }, [data, pageIdx, pageOptions]);

    useEffect(() => {
        const container = document.getElementById("manage-course-page-container");
        setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
    }, [data]);

    const handleSort = (column) => {
        if (column.render('Header') === COURSE_NAME) {
            sortCourseName();
            setSortSelected(0);
        } else if(column.render('Header') === COURSE_CODE) {
            sortCourseCode();
            setSortSelected(1);
        }
        else if(column.render('Header') === ENROLLED_MEMBERS) {
            sortEnrolledMembers();
            setSortSelected(2);
        }
    };

    const onView = (courseId) => {
        history.push(`/course/content/${courseId}`);
    }

    const onDelete = (courseId) => {
        setCurrentCourseId(courseId);
        setShowDeleteModal(true);
        // if (!window.confirm("Are you sure you want to delete this course?")) {
        //     return;
        // }
        // const res = await deleteCourse(courseId);
        // if (res.success) {
        //     getCourses(pageIndex);
        // }
    }

    const handleDelete = async () => {
        const res = await deleteCourse(currentCourseId);
        if (res.success) {
            getCourses(pageIndex);
        }
    };

    const onEdit = (rowData, courseId) => {
        setCurrentRow(rowData);
        setCurrentCourseId(courseId)
        setModalShow(true);
    }

    const loadCourse = () => {
        getCourses(pageIndex);
    }

    const deleteCourseConfig = {
        title: DELETE_COURSE,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        }, 
    };

    const checkDisplayDownArrow = (Header) => {
        if (Header === COURSE_NAME && courseNameSort === 1 && sortSelected === 0) {
            return true;
        }
        else if (Header === COURSE_CODE && courseCodeSort === 1 && sortSelected === 1) {
            return true; 
        }
        else if (Header === ENROLLED_MEMBERS && enrolledMemberSort === 1 && sortSelected === 2) {
            return true; 
        }
        return false; 
    }

    const checkDisplayUpArrow= (Header) => {
        if (Header === COURSE_NAME && courseNameSort === -1 && sortSelected === 0) {
            return true;
        }
        else if (Header === COURSE_CODE && courseCodeSort === -1 && sortSelected === 1) {
            return true; 
        }
        else if (Header === ENROLLED_MEMBERS && enrolledMemberSort === -1 && sortSelected === 2) {
            return true; 
        }
        return false; 
    }

    const checkDisplayGreyDownArrow = (Header) => {
        if(Header === COURSE_NAME && courseNameSort === 1){
            return true; 
        } else if (Header === COURSE_CODE && courseCodeSort === 1) {
            return true;
        }  else if (Header === ENROLLED_MEMBERS && enrolledMemberSort === 1) {
            return true; 
        }
        
        return false;
    }

    const checkDisplayGreyUpArrow = (Header) => {
        if(Header === COURSE_NAME && courseNameSort === -1){
            return true; 
        } else if (Header === COURSE_CODE && courseCodeSort === -1) {
            return true;
        }
        else if (Header === ENROLLED_MEMBERS && enrolledMemberSort === -1) {
            return true; 
        }
        return false;
    }

    return (
        <>
            <div className={styles.tableContainer}>
                <div className={styles.tableWrapper}>
                    <Table hover {...getTableProps()}>
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }
                                    })}>
                                        <div className={column.render('Header') === ACTION_HEADER ? styles.actionCell : column.render('Header') === COURSE_NAME ? styles.nameCell : styles.tableCell}>{column.render('Header')}
                                            {checkDisplayDownArrow(column.render('Header')) ?
                                                <SortDown tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                                : checkDisplayUpArrow(column.render('Header')) ?
                                                    <SortUp tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/>
                                                    : checkDisplayGreyDownArrow(column.render('Header')) ?
                                                        <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                                        : checkDisplayGreyUpArrow(column.render('Header')) ?
                                                            <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/>
                                                            : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === ACTION_HEADER) {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div role={TEXTBOX} aria-label={ACT_ITEMS + row.cells.at(index-4).value} style={{display: "flex", justifyContent: "center"}}>
                                                        <YuJaKebabMenu>
                                                            <YuJaKebabMenu.Menu>
                                                                <YuJaKebabMenu.Item onClick={() => onView(cell.row.values['courseId'])}>
                                                                    <ViewIcon style={{width: 18, marginRight: 5}}/>{VIEW_TEXT}
                                                                </YuJaKebabMenu.Item>
                                                                <YuJaKebabMenu.Item onClick={() => onEdit(cell.row.values, cell.row.values['courseId'])}>
                                                                    <EditIcon style={{width: 18, marginRight: 5}}/>{EDIT_TEXT}
                                                                </YuJaKebabMenu.Item>
                                                                <YuJaKebabMenu.Item onClick={() => onDelete(cell.value)}>
                                                                    <DeleteIcon style={{width: 18, marginRight: 5}}/> {MODAL_DELETE_TEXT}
                                                                </YuJaKebabMenu.Item>
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </div>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td tabIndex={0} aria-label={cell.column.Header + " " + cell.value}
                                                    title={cell.value && cell.value.length > COURSE_CHARACTER_LIMIT ? cell.value : ""}
                                                    {...cell.getCellProps({style: {wordBreak: 'break-word'}})}
                                                >
                                                    <span className={cell.column.Header === COURSE_NAME ? styles.nameCell : styles.tableCell}>
                                                        {cell.value && cell.value.length > COURSE_CHARACTER_LIMIT ?
                                                            cell.value.substring(0, COURSE_CHARACTER_LIMIT) + "..." :
                                                            cell.value
                                                        }
                                                    </span>
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="3" className={styles.noData} style={{boxShadow: "none"}}><div style={{ height:500, justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div></td></tr>}
                        </tbody>
                    </Table>
                </div>

                {page && page.length !== 0 &&
                    <div className={styles.paginationContainer}>
                        <NormalPagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                        />
                    </div>
                }
            </div>
            <EditCourseModal
                show={modalShow}
                setModalShow={setModalShow}
                rowData={currentRow}
                courseId={currentCourseId}
                loadCourse={loadCourse}
            />
            <YuJaGeneralAlert show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={DELETE_COURSE_MODAL_BODY}
                config={deleteCourseConfig}
                AlertType={MODAL_DELETE_TEXT}/>
            {/* <Modal
				id="delete-modal"
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				// dialogClassName="delete-modal"
            >
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setShowDeleteModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0}>
                            {DELETE_COURSE}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
                        <div tabIndex={0}>{DELETE_COURSE_MODAL_BODY}</div>
					</Modal.Body>
                    <Modal.Footer>
						<button className="modal-button-cancel" tabIndex={0} onClick={() => setShowDeleteModal(false)}>
							{MODAL_CANCEL_TEXT}
						</button>
						<button
                            tabIndex={0}
							className="modal-button-submit"
							onClick={handleDelete}
						>
							{MODAL_DELETE_TEXT}
						</button>
					</Modal.Footer>
				</Spin>
			</Modal> */}
        </>
    );
}
