import React from "react";
import { Modal, Table } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTable } from "react-table";
import { ReactComponent as ICIcon } from "../../images/clickable_purple.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_purple.svg";
import { ReactComponent as PollTitleIcon } from "../../images/links/questionbank_active_icon.svg";
import { ReactComponent as MHIcon } from "../../images/matching_purple.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_purple.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIcon.svg";
import { ReactComponent as SAIcon } from "../../images/sa_purple.svg";
import { ReactComponent as TFIcon } from "../../images/tf_purple.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_purple.svg";
import {
    BUTTON,
    DEFAULT_ROWS_PER_PAGE,
    MODAL_CLOSE_TEXT,
    POLL_TITLE_HEADER,
    QUESTION_BANK_MODAL,
    QUESTION_TYPES,
    QUES_BANK_CLOSE,
    TEXTBOX,
    VIEW_POLL_MODAL_QUE_TITLE_HEADER,
    VIEW_POLL_MODAL_TITLE
} from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import styles from "./QuestionProfiles.module.css";

export default function ViewPollModal({setModalShow, show, pollData, dataReady}) {
    const {pollTitle, questions=[]} = pollData;
    const handleClose = () =>{

        setModalShow(false);
    };





    const QuestionsTable = ({data=[]}) => {
        const columns = [
            { Header: VIEW_POLL_MODAL_QUE_TITLE_HEADER, accessor: 'queTitle', textAlign: "left", width: 200 },
            // { Header: VIEW_POLL_MODAL_QUE_TYPE_HEADER, accessor: 'questionType', textAlign: "center", width: 100 },
        ];


        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            rows,
        } = useTable({columns, data});


        return (
            <div className={styles.tableContainer} style={{border: "0.25px solid #646464", borderRadius: "5px", marginTop: "20px"}}>
                <Table hover {...getTableProps({style: {minHeight: rows.length ===0 ? "100%" : "", marginBottom: "0px"}})}>
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            textAlign: column.textAlign
                                        }
                                    })}>
                                      <PollTitleIcon style={{marginLeft: 3, width: "24px", height: "24px"}}/>  {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className={styles.tablebody} {...getTableBodyProps({style: {overflowY: "auto", height: "100%", marginBottom: "0px"}})}>
                        {rows.map((row, index) => {
                                prepareRow(row)
                                // console.log(row)
                                return (
                                    <tr key={index} {...row.getRowProps()} >
                                        { row.cells.map((cell, index) => {

                                                return (
                                                    <td
                                                        key={index}
                                                        tabIndex={0}
                                                        aria-label={VIEW_POLL_MODAL_QUE_TITLE_HEADER + " header " + cell.value}
                                                        role={TEXTBOX}
                                                        {...cell.getCellProps({style: {wordBreak: 'break-word', fontSize: "16px",  color: "#000000", textAlign: "left"}} )}
                                                    >
                                                        <span style={{display: "flex", alignItems: "center"}}>
                                                            {cell.row.original.questionType === QUESTION_TYPES.MCSS.name &&
                                                                <MCSSIcon alt="MCSS Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            {cell.row.original.questionType === QUESTION_TYPES.SA.name &&
                                                                <SAIcon alt="SA Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            {cell.row.original.questionType === QUESTION_TYPES.TF.name &&
                                                                <TFIcon alt="TF Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            {cell.row.original.questionType === QUESTION_TYPES.FITB.name &&
                                                                <FITBIcon alt="FITB Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            {cell.row.original.questionType === QUESTION_TYPES.CI.name &&
                                                                <ICIcon alt="CI Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            {cell.row.original.questionType === QUESTION_TYPES.WC.name &&
                                                                <WCIcon alt="WC Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            {cell.row.original.questionType === QUESTION_TYPES.MH.name &&
                                                                <MHIcon alt="MH Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            {cell.row.original.questionType === QUESTION_TYPES.RK.name &&
                                                                <RKIcon alt="RK Icon" title={QUESTION_TYPES[cell.row.original.questionType].description} style={{ width: "24px", height: "24px" }} />
                                                            }
                                                            <span style={{width: 10}}/>
                                                            <span
                                                                title={cell.value}
                                                                style={{flex: 1, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}
                                                            >
                                                                {cell.value}
                                                            </span>
                                                        </span>
                                                    </td>
                                                );

                                        })}
                                    </tr>
                                )
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }
    
    return (
        <Modal
            id="form-modal"
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            dialogClassName= {"qp-modal"}
        >

            <ModalClose tabIndex={0} role={BUTTON} aria-label={QUES_BANK_CLOSE} onClick={handleClose} className="modal-close" style={{width: "24px", height: "24px"}}/>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title tabIndex={0} role={TEXTBOX} aria-label={QUESTION_BANK_MODAL} className="modal-title" id="contained-modal-title-vcenter">
                    {VIEW_POLL_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>

                    {dataReady &&
                        <>
                            <div
                                tabIndex={0}
                                role={TEXTBOX}
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                    fontSize: "16px",
                                    lineHeight: "16px",
                                    fontWeight: "bold",
                                    color: "#000000",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                                title={pollTitle}
                            >
                                {POLL_TITLE_HEADER}:  <span style={{fontWeight: 400}}>{pollTitle}</span>
                            </div>
                            <QuestionsTable data={questions}/>
                        </>
                    }
                    {!dataReady &&
                        <div className={styles.skeletonTableContainer}>
                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                                <div className={styles.skeletonHeaderContainer2}>
                                    <div className={styles.skeletonHeaderCell}>{VIEW_POLL_MODAL_QUE_TITLE_HEADER}</div>
                                </div>
                                <div className={styles.skeletonBodyContainer}>
                                    {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                                        return (<div className={styles.skeletonBodyRow2} key={idx}>
                                            <Skeleton height={20}  borderRadius={10} inline={true}
                                                      width={`${6 + Math.random() * 3}vw`}
                                                      className={styles.skeletonBodyCell}
                                                      containerClassName={styles.skeletonBodyCellWrapper}/>
                                        </div>)
                                    })}
                                </div>
                            </SkeletonTheme>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton aria-label={QUES_BANK_CLOSE} onClick={handleClose} type={"secondary"}>{MODAL_CLOSE_TEXT}</YuJaButton>
            </Modal.Footer>

        </Modal>
    );
}