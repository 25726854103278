import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { useLoading } from "../../utils/LoadingContext";
import {
    ACTION_HEADER,
    ACTION_ID,
    ACTIVITY_CHOOSER,
    ATTENDANCE_OPTION,
    DATE_HEADER,
    MERGED_MODE,
    NO_ACTIVITIES_FOUND,
    POLL_CHOOSER_CONFIRM_CONTENT,
    POLL_KEY,
    POLL_SHARE_MODE_ACCESSOR,
    POLL_STATUS_HEADER,
    POLL_TITLE,
    POLL_TYPE,
    TITLE_HEADER,
    UPDATED_DATE_PK
} from "../../utils/constants";
import { getPollStatus, getPriority } from "../../utils/helpers";
import styles from "./PollChooser.module.css";
import PollChooserSearchForm from "./PollChooserSearchForm";
import PollChooserTable from "./PollChooserTable";
import { ReactComponent as NoResultIllustration } from "../../images/NoActivityFound.svg";

export default function PollChooser() {
  const { courseId } = useParams();
  const [filter, setFilter] = useState({});
  const [pollData, setPollData] = useState([]);
  const [courseMetaData, setCourseMetaData] = useState({});
  const [dataReady, setDataReady] = useState(false);
  const { getPollsFromCourse } = PollApiCalls();
  const { getCourseById } = CourseApiCalls();
  const {setLoading} = useLoading();
  const [secret, setSecret] = useState("");
  const [intType, setIntType] = useState("");
  const location = useLocation();
  const [dateSort, setDateSort] = useState(0);
  const [titleSort, setTitleSort] = useState(1);
  const [modeSort, setModeSort] = useState(1);
  const [statusSort, setStatusSort] = useState(1);
  const [pollTypeFilter, setPollTypeFilter] = useState(POLL_TYPE.MERGED_POLL);

  useEffect(() => {
    if (location.state && location.state.secret) {
      setSecret(location.state.secret);
      setIntType(location.state.intType);
    }
  }, [location.state]);

  const getPollInformation = async (courseId) => {
    setDataReady(false);
    setLoading(true);
    let res = await getPollsFromCourse(courseId);
    const now = Date.now();
    res = res.filter(p => !!p.pollType && [POLL_TYPE.ATTENDANCE, POLL_TYPE.MERGED_POLL].includes(p.pollType))
        .filter(poll => (poll.uniqueCodeExpTime ? now <= poll.uniqueCodeExpTime : true))
        .sort(function (a, b) {
          return new Date(Date.parse(b.updatedTimeSK)) - new Date(Date.parse(a.updatedTimeSK));
        });
    setPollData(res); // we just need graded polls which are not expired as of now for LMS
    setLoading(false);
    setDataReady(true);
  };
  
  const getCourseInfo = async (courseId) => {
    // setDataReady(false);
    setLoading(true);
    const result = await getCourseById(courseId);
    setCourseMetaData(result?.result);
    setLoading(false);
    // setDataReady(true);
  };
  useEffect(() => {
    getPollInformation(courseId);
    getCourseInfo(courseId);
  }, [courseId]);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
    // setPageIndex(0);
  }, []);

  // const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: DATE_HEADER, accessor: UPDATED_DATE_PK, width: 90 },
    { Header: TITLE_HEADER, accessor: POLL_TITLE, width: 160 },
    // { Header: 'Joining Code', accessor: 'pollSortKey', width: 130 },
    // { Header: POLL_TYPE_HEADER, accessor: POLL_TYPE_ACCESSOR, width: 90},
    // { Header: QUESTIONS_HEADER, handleSubmitaccessor: TOTAL_QUESTIONS, width: 80 },
    { Header: POLL_STATUS_HEADER, accessor: POLL_SHARE_MODE_ACCESSOR, width: 100 },
    // { Header: 'Edit', accessor: 'pollKey', width: 100, id: 'edit' },
    // { Header: 'Publish', accessor: 'pollKey', width: 100 }
    { Header: ACTION_HEADER, accessor: POLL_KEY, width: 70, id: ACTION_ID }
  ];

  const sortDateHelper = () => {
    if(!!pollData) {
      if(dateSort === 0){
        setPollData(pollData.sort(function (a, b) {
            return new Date(Date.parse(a.updatedTimeSK)) - new Date(Date.parse(b.updatedTimeSK));
          }));
          setDateSort(1);
        }
        else {
          setPollData(pollData.sort(function (a, b) {
            return new Date(Date.parse(b.updatedTimeSK)) - new Date(Date.parse(a.updatedTimeSK));
          }));
          setDateSort(0);
        }
      }
  }

  const sortTitleHelper = () => {
    if(!!pollData) {
        setPollData(pollData.sort(function (a, b) {
            const nameA = a.pollTitle?.toLowerCase();
            const nameB = b.pollTitle?.toLowerCase();

            const priorityA = getPriority(nameA);
            const priorityB = getPriority(nameB);

            if (priorityA < priorityB) return -1 * titleSort;
            if (priorityA > priorityB) return 1 * titleSort;

            if (nameA < nameB) return -1 * titleSort;
            if (nameA > nameB) return 1 * titleSort;
            return 0;
          }));
          setTitleSort(-titleSort);
      }
  }

  const sortModeHelper = () => {
    if(!!pollData) {
        setPollData(pollData.sort(function (a, b) {
            const nameA = a.pollType?.toLowerCase();
            const nameB = b.pollType?.toLowerCase();
      
            if (nameA < nameB) return -1 * modeSort;
            if (nameA > nameB) return 1 * modeSort;
            return 0;
          }));
          setModeSort(-modeSort);
      }
  }

  const sortStatusHelper = () => {
    if(!!pollData) {
        setPollData(pollData.sort(function (a, b) {
            const nameA = getPollStatus(a.pollShareMode, a?.isValid).toLowerCase();
            const nameB = getPollStatus(b.pollShareMode, b?.isValid).toLowerCase();

            if (nameA < nameB) return -1 * statusSort;
            if (nameA > nameB) return 1 * statusSort;
            return 0;
          }));
          setStatusSort(-statusSort);
      }
  }

  useEffect(() => {
    setFilter({...filter, mode: pollTypeFilter});
  }, [pollTypeFilter]);

  return (
    <>
      {pollData && pollData.length !== 0 && dataReady && (
        <>
          <div className={styles.container} id="poll-chooser-container">
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div className={styles.titleBoxTitle}>{ACTIVITY_CHOOSER}</div>
            </div>
            <div className={styles.pollTypeRow}>
              <div
                className={pollTypeFilter === POLL_TYPE.MERGED_POLL ? styles.pollTypeOptionActive : styles.pollTypeOption}
                onClick={() => setPollTypeFilter(POLL_TYPE.MERGED_POLL)}
              >
                {MERGED_MODE}
              </div>
              <div
                className={pollTypeFilter === POLL_TYPE.ATTENDANCE ? styles.pollTypeOptionActive : styles.pollTypeOption}
                onClick={() => setPollTypeFilter(POLL_TYPE.ATTENDANCE)}
              >
                {ATTENDANCE_OPTION}
              </div>
            </div>
            <div style={{padding: "0 5%"}}>
              <PollChooserSearchForm handleSubmit={handleSubmit} pollTypeFilter={pollTypeFilter} />
              <div className={styles.noteContainer}>
                {/* <div className={styles.notePrefix}>{NOTE_PREFIX}</div> */}
                <div className={styles.noteText}>{POLL_CHOOSER_CONFIRM_CONTENT}</div>
              </div>
              <PollChooserTable columns={columns} data={pollData} globalFilterObj={filter} getPollInformation={getPollInformation} secret={secret} intType={intType} courseMetaData={courseMetaData} Datesort={sortDateHelper} dateSort={dateSort} Titlesort={sortTitleHelper} titleSort={titleSort} Modesort={sortModeHelper} modeSort={modeSort} Statussort={sortStatusHelper} statusSort={statusSort} />
            </div>
          </div>
        </>
      ) }
      { (!pollData || pollData.length === 0) && dataReady && (
        <>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "center", height: "90vh"}}> 
              <NoResultIllustration style={{ width: "100%", maxHeight: "100%"}} />
              <div className={styles.titleBoxTitle} style={{marginTop: 20}}>{NO_ACTIVITIES_FOUND}</div>
              </div>
        </>
      )}
    </>
  );
}