import "antd/dist/antd.min.css";
import 'rc-datepicker/lib/style.css';
import { default as React, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { Spin } from "../../components/standardization/YuJaLoading";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { BUTTON, CLOSE_MODAL, COURSE_CODE_PLACEHOLDER, COURSE_NAME_PLACEHOLDER, MODAL, REQUIRED_FIELD_ERROR, TEXT_BOX_MODAL, TEXTBOX } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaTextBox } from "../standardization/YuJaTextBox";
import "./modal.css";

export default function CreateCourseModal({setModalShow, show, getCourses }) {
  const [courseName, setCourseName] = useState("");
  // const [subject, setSubject] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [courseNameError, setCourseNameError] = useState("");
  // const [subjectError, setSubjectError] = useState("");
  const [courseCodeError, setCourseCodeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { saveCourse } = CourseApiCalls();
  const history = useHistory();
  const isNarrow = useMobileAccess(1100);

  const clearForm = () => {
    setCourseName("");
    // setSubject("");
    setCourseCode("");
    // setStartDate("");
    // setInstructorsName("");
    clearError();
    setModalShow(false)
  };

  const clearError = () => {
    setCourseNameError("");
    // setSubjectError("");
    // setStartDateError("");
    setCourseCodeError("");
    // setInstructorsNameError("");
  };

  const addCourse = async () => {
    if (validateForm()) {

      const body = {};
      body.courseName = courseName;
      // body.subject = subject;
      // body.startDate = format(startDate);
      body.courseCode = courseCode;
      // body.instructorsName = instructorsName;
      setIsLoading(true);
      const courseRecord = await saveCourse(body);
      setIsLoading(false);
      setModalShow(false);
      clearForm();
      if (!!courseRecord) {
        getCourses();
      }
    }
  };

  const format = (input) => {
    const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    if (!input || !input.match(pattern)) {
      return null;
    }
    return input.replace(pattern, '$3/$2/$1');
  };

  const validateForm = () => {
    clearError();
    let returnVal = true;
    if (!courseName) {
      setCourseNameError(REQUIRED_FIELD_ERROR);
      returnVal = false;
    }

    // if (!subject) {
    //   setSubjectError(REQUIRED_FIELD_ERROR);
    //   returnVal = false;
    // }

    // if (!startDate) {
    //   setStartDateError(REQUIRED_FIELD_ERROR);
    //   returnVal = false;
    // }

    if (!courseCode) {
      setCourseCodeError(REQUIRED_FIELD_ERROR);
      returnVal = false;
    }

    // if (!instructorsName) {
    //   setInstructorsNameError(REQUIRED_FIELD_ERROR);
    //   returnVal = false;
    // }

    return returnVal;
  };

  const modalConfig = {
    title: "Create Course",
    submitButtonText: "Create Course",
    subText: "Please complete course details and click create course.",
    submit: addCourse,
    clearForm: clearForm,

    form: [
      {
        label: "Course Code",
        value: courseCode,
        setFunction: setCourseCode,
        error: courseCodeError,
        placeHolderText: COURSE_CODE_PLACEHOLDER,
        type: "text",
      },

      {
        label: "Course Name",
        value: courseName,
        setFunction: setCourseName,
        error: courseNameError,
        placeHolderText: COURSE_NAME_PLACEHOLDER,
        type: "text",
      },

      // {
      //   label: "Subject",
      //   value: subject,
      //   setFunction: setSubject,
      //   error: subjectError,
      //   type: "text",
      // },

      // {
      //   label: "Instructor's Name",
      //   value: instructorsName,
      //   setFunction: setInstructorsName,
      //   error: instructorsNameError,
      //   type: "text",
      // },

      // {
      //   label: "Start Date",
      //   value: startDate,
      //   setFunction: setStartDate,
      //   error: startDateError,
      //   type: "date",
      // },
    ],
  };

  const handleClose = () =>{
    setModalShow(false);
    modalConfig.clearForm();
  };

  return (
      <Modal
      id="form-modal"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={isLoading ? "static" : true}
      dialogClassName= {"ac-modal"}
    >
      <Spin tip="Loading..." size="large" spinning={isLoading}>
        <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={handleClose} className="modal-close" />
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title
            className="modal-title"
            id="contained-modal-title-vcenter"
            tabIndex={0}
            aria-label={modalConfig.title + MODAL}
            role={TEXTBOX}
          >
            {modalConfig.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <Form className="customized-form" onSubmit={e => e.preventDefault()}>
              <div style={{ marginBottom: "25px" }}>
                <Form.Text tabIndex={0} role={TEXTBOX} className="form-subtext">
                  {modalConfig.subText}
                </Form.Text>
              </div>
              {modalConfig.form.map((formItem, idx) => {
                return (
                    <Form.Group style={{ marginBottom: "25px" }} key={idx}>
                      <Form.Label className="form-label" style={{margin: 0}} >
                        {formItem.label}
                      </Form.Label>
                          <YuJaTextBox
                              label={TEXT_BOX_MODAL + formItem.label}
                              containerStyle={{width: "90%", color: "#4D5D4D"}}
                              value={formItem.value || ""}
                              onChange={(e) => formItem.setFunction(e.target.value)}
                              type={formItem.type}
                              isInvalid={!!formItem.error}
                              errorMsg={formItem.error}
                              placeholder={formItem.placeHolderText}
                          />
                      <Form.Control.Feedback type="invalid">
                        {formItem.error}
                      </Form.Control.Feedback>
                    </Form.Group>
                );

            })}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <YuJaButton type="secondary"  onClick={handleClose}>Cancel</YuJaButton>
          <YuJaButton
              onClick={(e) => {
                e.preventDefault();
                if(validateForm()) {
                  modalConfig.submit();
                }
              }}
          >{modalConfig.submitButtonText}</YuJaButton>
        </Modal.Footer>
      </Spin>
    </Modal>
  );
}
