import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import QRCode from "qrcode.react";
import { HOST_URL } from "../../utils/properties";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function PublishModalRightPane({uniqueCode}) {
  const isMobile = useMobileAccess();
  const hostname = window.location.hostname;
  let shortName = '';
        if (hostname.includes(HOST_URL)) {
            shortName = hostname.indexOf('.') >= 0 ?
                hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
                hostname;
        } else {
            shortName = hostname.indexOf('.') >= 0 ?
                hostname.substring(0, hostname.indexOf('.')) :
                hostname;
        }
  const INST_URL = shortName + '.' + HOST_URL;
  return (
    <Container>
      <Row>
        <Col>
          <span className="scan-qr-text">Scan QR Code</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <QRCode
            bgColor={"#f6f6f6"}
            style={{ margin: "10px" }}
            renderAs="canvas"
            size={isMobile ? 180 : 250}
            value={INST_URL + "/qr/" + uniqueCode}
          />
        </Col>
      </Row>
    </Container>
  );
}
