import React, { useEffect, useState } from "react";
import { UserApiCalls } from "../../apis/UserApiCalls";
import YuJaButton from "../../components/standardization/YuJaButton";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { DEFAULT_TOAST_MESSAGE_DURATION, TEXTBOX, USER_SETTINGS, MENU_LIST, SAVE_CHANGES_TEXT } from "../../utils/constants";
import styles from "./platform.module.css";
import UserSettingTab from "./UserSettingTab";

export default function ViewerSettings({ setPageIdentifier }) {
    const [, getSession] = useLocalStorageNew("session", {});
    const [userSettings,  setUserSettings] = useState(() => {
        const {settings={}} = getSession();
        if (!settings.hasOwnProperty(USER_SETTINGS.TOAST_MESSAGE_DURATION)) {
            settings[USER_SETTINGS.TOAST_MESSAGE_DURATION] = DEFAULT_TOAST_MESSAGE_DURATION.toString();
        }
        return settings;
    });

    useEffect(() => {
        if(document.getElementById("userLastElem") != null) {
            document.getElementById("userLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }

        setPageIdentifier(MENU_LIST.SETTINGS_STUDENT.name);
        
        const handleWindowResize = () => {
          setPageIdentifier(MENU_LIST.SETTINGS_STUDENT.name);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const {saveSettings} = UserApiCalls();

    const handleSaveUserSettings = async () => {
        saveSettings(userSettings);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.btnCenterAlign}>
                <YuJaButton id="settingsSaveBtn" index={1} onClick={handleSaveUserSettings}>{SAVE_CHANGES_TEXT}</YuJaButton>
            </div>
            <div className={styles.body}>
                <div className={styles.titleBox}>
                    <div className={styles.titleBoxIcon} />
                    <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Settings</div>
                </div>
                <div className={styles.container}>
                    <UserSettingTab userSettings={userSettings} setUserSettings={setUserSettings} isViewer={true} />
                </div>
            </div>
        </div>
    )
};