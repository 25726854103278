import { useReactTable, getCoreRowModel, getPaginationRowModel, getExpandedRowModel, getFilteredRowModel,} from '@tanstack/react-table'
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { DEFAULT_ROWS_PER_PAGE, FEEDBACK_DATE_HEADER, FEEDBACK_SATISFACTION_HEADER, SATISFACTION_LABEL, TEXTBOX, SATISFACTION_GOOD_TEXT, SATISFACTION_POOR_TEXT, SATISFACTION_SATISFIED_TEXT, SATISFAcTION_EXCELLENT_TEXT, FEEDBACK_DETAIL_HEADER, ALL_CATEGORY, FEEDBACK_TITLE_HEADER, FEEDBACK_CATEGORY_HEADER, FEEDBACK_FILE_HEADER, ALL_SATISFACTION, FEEDBACK_STUDENT_HEADER, FEEDBACK_DETAIL_TEXT, NO_RESULTS_FOUND} from "../../utils/constants";
import styles from "./FeedBack.module.css";
import {ReactComponent as ArrowDown} from "../../images/YuJa_arrow_down_icon.svg";
import {ReactComponent as ArrowUp} from "../../images/YuJa_arrow_up_icon.svg";
import NormalPagination from "../../components/pagination/NormalPagination";
import { useMemo } from "react";
import { flexRender } from "@tanstack/react-table";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import {ReactComponent as NoResultIllustration} from "../../images/no_result.svg";
import useHostname from "../../hooks/useHostname";


export default React.memo(FeedbackTable);
function FeedbackTable({ data = [], columns = [], pageIdx=0, globalFilterObj = {}, dateSort,  dateSortData, nameSort, nameSortData, pollKey}) {

    const [globalFilter, setGlobalFilter] = useState("");
    const [selectedRow, setSelectedRow] = useState("");
    const [sortSelected, setSortSelected] = useState(0);
    const [isSubrowclosing, setIsSubrowClosing] = useState(false);

    const hostResource = useHostname(window.location.hostname);
    const {institutionId = ""} = hostResource;

    const renderDate = (date) => {
        if (!date) {
            return "";
        }
        const datetimeObject = new Date(date);
        const day = datetimeObject.getDate().toString().padStart(2, '0');
        const month = (datetimeObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = datetimeObject.getFullYear();
    
        // Format the Date object into the desired format (dd-mm-yyyy)
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }

    const pageOptions = useMemo(() => {
        let options = [];
        // console.log(responses.length, DEFAULT_ROWS_PER_PAGE);
        let nPages = Math.ceil(data.length / DEFAULT_ROWS_PER_PAGE);
        // console.log(nPages);
        for (let i = 0; i < nPages; i++) options.push(i);
        return options;
    }, [data]);

    const filterCustomize = (row, columnId, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return true;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredDate = globalFilterValue.date;
        const filteredCategory = globalFilterValue.category;
        const filteredSatisfaction = globalFilterValue.satisfaction; 
        if (filteredKeyword && ( (!row.getValue(FEEDBACK_TITLE_HEADER) || !row.getValue(FEEDBACK_DETAIL_HEADER)) || (row.getValue(FEEDBACK_TITLE_HEADER).toLowerCase().indexOf(filteredKeyword) <= -1 && row.getValue(FEEDBACK_DETAIL_HEADER).toLowerCase().indexOf(filteredKeyword) <= -1))) {
                return false;
        }
        if (filteredDate && (!row.getValue(FEEDBACK_DATE_HEADER) || renderDate(row.getValue(FEEDBACK_DATE_HEADER)) !== filteredDate)) {
            return false;
        }
        if (filteredCategory && filteredCategory !== ALL_CATEGORY && (row.getValue(FEEDBACK_CATEGORY_HEADER) !== filteredCategory || !row.getValue(FEEDBACK_CATEGORY_HEADER))) {
            return false;
        }
        if  (filteredSatisfaction && filteredSatisfaction !== ALL_SATISFACTION && (row.getValue(FEEDBACK_SATISFACTION_HEADER) != getSatisfactionFromText(filteredSatisfaction) || !row.getValue(FEEDBACK_SATISFACTION_HEADER))) {
            return false; 
        }
        return true; 
            
    }

    const getSatisfactionFromText = (filteredSatisfaction) => {
        if(filteredSatisfaction === SATISFACTION_POOR_TEXT) {
            return 0;
        }
        else if (filteredSatisfaction === SATISFACTION_SATISFIED_TEXT) {
            return 1; 
        }
        else if (filteredSatisfaction === SATISFACTION_GOOD_TEXT) {
            return 2;
        }
        else if (filteredSatisfaction === SATISFAcTION_EXCELLENT_TEXT) {
            return 3; 
        }

    }

    const handleSort = (header) => {
        if(selectedRow) {
            selectedRow.getToggleExpandedHandler()();
            setSelectedRow("");
        }
        if (header === FEEDBACK_DATE_HEADER) {
            dateSort();
            setSortSelected(0);
        }
        else if(header === FEEDBACK_STUDENT_HEADER) {
            nameSort();
            setSortSelected(1);
        }
    };

    const collapseDetail = (row) => {
        if(selectedRow !== "") {
            selectedRow.getToggleExpandedHandler()();
        } 
        row.getToggleExpandedHandler()(); 
        setSelectedRow(row);
    }

    const downloadFile = (fileName, fileURL) => {

        fetch(fileURL)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
    
            link.download = fileName;
    
            document.body.appendChild(link);
    
            link.click();
    
            // Remove the link from the document body
            document.body.removeChild(link);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
          });
      };


    const {
		getHeaderGroups,
		getRowModel,
		setPageIndex,
		getCanPreviousPage,
		previousPage,
		getCanNextPage,
		nextPage,
		getPageCount,
		getState,
	} = useReactTable({
		columns: columns,
		data: data,
        state: {
            globalFilter : globalFilter,
          },
		paginateExpandedRows: false,
		autoResetPage: true,
        maxLeafRowFilterDepth: 0,
        getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
        onGlobalFilterChanged: setGlobalFilter,
        globalFilterFn: filterCustomize,
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: (row) => {
			return row.subRows;
		},
	});

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);

    return (
        <>
            <div className={styles.tableContainer} style={{borderRadius: "0px", display: "flex", flexDirection: "column", height: "100%"}}>
                <Table hover>
                    <thead>
                        {getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id} style={{backgroundColor: "#F7F6FB"}}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...{
                                        key: column.id,
                                        style: {width: column.getSize(), backgroundClip: "padding-box", textAlign: "left"}
                                    }}>
                                        <span style={{ paddingLeft: column.column.columnDef.header === FEEDBACK_STUDENT_HEADER ? 10 : 0 }}>
                                            {flexRender(column.column.columnDef.header, column.getContext())}
                                            {((column.column.columnDef.header === FEEDBACK_DATE_HEADER && dateSortData === 0) || (column.column.columnDef.header === FEEDBACK_STUDENT_HEADER && nameSortData === 1)) && <Down tabIndex={0} role={TEXTBOX} aria-label={column.column.columnDef.header + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={() => { handleSort(column.column.columnDef.header) }} />}
                                            {((column.column.columnDef.header === FEEDBACK_DATE_HEADER && dateSortData === 1) || (column.column.columnDef.header === FEEDBACK_STUDENT_HEADER && nameSortData === -1)) && <Up tabIndex={0} role={TEXTBOX} aria-label={column.column.columnDef.header + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={() => { handleSort(column.column.columnDef.header) }} />}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {getRowModel().rows.length!== 0 ? getRowModel().rows.map((row) => {
                            return (
                                <tr>
                                    {row.depth === 0 && row.getVisibleCells().map((cell) => {
                                        if (cell.column.columnDef.header === FEEDBACK_DATE_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} >
                                                    <div style={{textAlign: "left", fontSize: 15}}> {renderDate(cell.getValue())} </div>
                                                </td>
                                                ) 
                                        }
                                        else if (cell.column.columnDef.header === FEEDBACK_SATISFACTION_HEADER) {
                                            return (
                                                <td> 
                                                     <div style={{display: "flex", justifyContent: "left"}}>
                                                    {cell.getValue() == '0' && <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFACTION_POOR_TEXT} className={`feedback-modal-satisfaction`} style={{color: "#CD2F22", border: "1.46px solid #CD2F22", width: 75, fontSize: 15, backgroundColor: "rgba(205, 47, 34, 0.08)", borderRadius: "3.645px", cursor:"default"}}><div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}} > {SATISFACTION_POOR_TEXT} </div></div>}
                                                    {cell.getValue() == '1' &&  <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFACTION_SATISFIED_TEXT} className={`feedback-modal-satisfaction`} style={{color: "#CA9536", border: "1.46px solid #CA9536", width: 75, fontSize: 15,  backgroundColor: "rgba(202, 149, 54, 0.08)", borderRadius: "3.645px", cursor:"default"}}> <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}> {SATISFACTION_SATISFIED_TEXT} </div> </div>}
                                                    {cell.getValue() == '2' && <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFACTION_GOOD_TEXT} className={`feedback-modal-satisfaction`} style={{color: "#61C180", border: "1.46px solid #61C180", width: 75, fontSize: 15, backgroundColor: "rgba(97, 193, 128, 0.08)", borderRadius: "3.645px", cursor:"default"}}> <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}} > {SATISFACTION_GOOD_TEXT} </div> </div>}
                                                    {cell.getValue() == '3' && <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFAcTION_EXCELLENT_TEXT} className={`feedback-modal-satisfaction`} style={{color: "#0B8910", border: "1.46px solid #0B8910", width: 75, fontSize: 15, backgroundColor: "rgba(11, 137, 16, 0.08)", borderRadius: "3.645px", cursor:"default"}}> <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}> {SATISFAcTION_EXCELLENT_TEXT} </div> </div> }
                                                    </div>
                                                </td>
                                            )
                                        }
                                        else if (cell.column.columnDef.header === FEEDBACK_DETAIL_HEADER) {
                                            return (
                                                <td> 
                                                    <div style={{display: "flex", justifyContent: "left", marginTop: "12.52px", marginBottom: "12.52px"}} aria-label="detail drop down">
                                                    {!row.getIsExpanded() &&
                                                    <div className={styles.feedbackDetailDropdown} style={{width: 90}} onClick={() => collapseDetail(row)} tabIndex={0}>
                                                          <div style={{color: "#42296E", fontSize: 15, cursor: "pointer"}}> {FEEDBACK_DETAIL_HEADER}  </div> <ArrowDown style={{width: 22.5, height: 22.5, cursor: "pointer"}} />
                                                         
                                                    </div>
                                                    } 
                                                    { row.getIsExpanded() && 
                                                    <div className={styles.feedbackDetailDropdownSelected} style={{width: 90, backgroundColor: "#42296E",}} onClick={() => {setIsSubrowClosing(true); setTimeout(() => {row.getToggleExpandedHandler()(); setIsSubrowClosing(false)}, 400); setSelectedRow("");} } tabIndex={0}>
                                                     <div style={{ color: "#FFF", fontSize: 15,  cursor: "pointer"}}> {FEEDBACK_DETAIL_HEADER} </div> <ArrowUp style={{width: 22.5, height: 22.5, cursor: "pointer"}} /> 
                                                     </div>
                                                    }
                                                    </div>
                                                </td>
                                            )
                                        } else if (cell.column.columnDef.header === FEEDBACK_FILE_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX}>
                                                    {cell.getValue() && <div className={styles.feedbackFile} onClick={() => {downloadFile(cell.getValue(), row.original.fileURL)}} style={{textAlign: "left", color: "#3366CC", fontSize: 15, cursor: "pointer"}}> {cell.getValue()} </div> }
                                                </td>
                                                )
                                        }
                                        else {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX}>
                                                    <div style={{ textAlign: "left", color: "#272833", fontSize: 15, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", paddingLeft: cell.column.columnDef.header === FEEDBACK_STUDENT_HEADER ? 10 : 0 }}> {cell.getValue()} </div>
                                                </td>
                                            )
                                        }
                                    })} 
                                    {row.depth === 1 &&
                                        <td colSpan={7} className={`${styles.FeedbackSubrow} ${isSubrowclosing ? styles.FeedbackSubrowClosing : ""}`}>
                                            <div tabIndex={0} role={TEXTBOX} aria-label={"feedback detail" + row.original} style={{ height: "100%", width: "100%", textAlign: "center", wordBreak: "break-word", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "0 10px" }}>
                                                <div style={{ borderBottom: "0.5px solid #C5C5C5", width: "100%", marginTop: "24px", color: "#454545", fontSize: 15, paddingBottom: "9px", textAlign: "left" }}> {FEEDBACK_DETAIL_TEXT} </div>
                                                <div style={{ marginTop: "9px", paddingBottom: "23px", textAlign: "left", wordBreak: "break-word", width: "100%" }}> {row.original} </div>
                                            </div>
                                        </td>
                                    }
                                </tr>
                            )
                        }
                        ) : <tr><td colSpan="7" className={styles.noData} style={{boxShadow: "none"}}><div style={{height: 400, justifyContent: "center" , display: "flex", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}>{NO_RESULTS_FOUND} </div> </div></td></tr>}
                    </tbody>
                    </Table>
                    <div style={{flex: 1, width: 100}}> </div>
                    {getRowModel().rows.length!== 0 ?
                    <div style={{marginTop: "40px"}}>
                    <NormalPagination
					pageCount={getPageCount()}
					pageIndex={getState().pagination.pageIndex}
					pageOptions={pageOptions}
					canPreviousPage={getCanPreviousPage()}
					canNextPage={getCanNextPage()}
					previousPage={previousPage}
					nextPage={nextPage}
					gotoPage={setPageIndex}
				    />
                    </div>
                    : null }
            </div>
        </>
    );
}