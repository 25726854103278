import { React, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { useCookie } from "../../hooks/useCookie";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as GoBack } from "../../images/goBack.svg";
import { useLoading } from "../../utils/LoadingContext";
import { ACTIVITY_CODE, AUDIENCE_RESTRICTION, EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE, INVALID_POLL_CODE_ERROR, JOIN_POLL, JOIN_POLL_DESCRIPTION, JOIN_POLL_MODAL_NAME_REQUIRED_LABEL, LOGIN_BUTTON_TEXT, LOGIN_PAGE_FOOTER_TEXT, LOGIN_PATH, POLL_CODE_LENGTH, POLL_DISABLE_ANONYMOUS_WARNING, POLL_DISABLE_GUEST_WARNING, REQUIRED_FIELD_ERROR, SCAN_QR, VIEWER_USER_TYPE, WELCOME_YUJA_ENGAGE, YUJA_SUPPORT_LINK } from "../../utils/constants";
import { formatErrorLog, newPageLog, notifyError } from "../../utils/helpers";
import { USER_ACCESS_POLL_ERROR } from "../../utils/toast-message-constants";
import defaultImage from "./images/main.jpg";
import Logo from "./images/yujalogo.svg";
import "./styles.css";

export default function GuestLoginPage() {
  const { setUserNameToLocalStorage, getPollRecordFromUniqueCode} = ResponseApiCalls();
  const { checkMemberAccess } = CourseApiCalls();
  const location = useLocation();
  const { setLoading } = useLoading();
  const [name, setName] = useState("");
  const [setCookie, , ] = useCookie("name", "Guest");
  let history = useHistory();
  let errorRed = "0px 0px 2px 2px #C93E3E";
  const isMobile = useMobileAccess(480);
  const isNarrow = useMobileAccess(950);
  const hostResource = useHostname(window.location.hostname);
  const [imgUrl, setImgUrl] = useState("");
  const [inputCodeInvalid, setInputCodeInvalid] = useState(false);

  const [setWarning, ] = useLocalStorageNew("warning", {});

  const [pollCode, setPollCode] = useState(['', '', '', '', '', '']);
  const [scanQRRequired, setScanQRRequired] = useState(true);
  const [showLoginJoin, setShowLoginJoin] = useState(false);
  const [, getSession] = useLocalStorageNew("session", {});
  const session = getSession(); 

  useEffect(() => {
    const { imageURL } = hostResource;
    setImgUrl(imageURL ? imageURL : defaultImage);
  }, [hostResource]);

  useEffect(() => {
    newPageLog("GuestLoginPage.js");
  }, [])

  const checkAuthorization = async (pollUniqueCode) => {
    const result = await getPollRecordFromUniqueCode(pollUniqueCode);
    if (!result || !result.poll) {
      notifyError(INVALID_POLL_CODE_ERROR);
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return;
    }

    const { index2Pk: courseId, lmsHomeUrl, syncLms = true, audienceRestriction = null } = result.poll;
    if ((audienceRestriction && audienceRestriction === AUDIENCE_RESTRICTION.UNRESTRICTED && !session.authorization) || location?.state?.reauthenticate) {
      return;
    }

    if ((audienceRestriction === AUDIENCE_RESTRICTION.LOGGED_IN || audienceRestriction === AUDIENCE_RESTRICTION.REGISTERED || !!courseId) && !session.authorization) {
      notifyError(POLL_DISABLE_GUEST_WARNING);
      return;
    }
    
    if (audienceRestriction ? audienceRestriction === AUDIENCE_RESTRICTION.REGISTERED : !!courseId) {
      if (!(await checkUserAccess(courseId))) {
        return;
      }
      if (!!lmsHomeUrl && (!!session.gradedLink || !syncLms)) {
        setWarning(false);
        setUserNameToLocalStorage(pollUniqueCode, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
        history.push(`/join/${pollUniqueCode}`);
        return;
      }
      if (!!lmsHomeUrl) {// if it's a LMS embedded poll and current user is not LMS user then redirect to guide page
        formatErrorLog(EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE);
        history.push(`/lti/${pollUniqueCode}/guide`);
        return;
        // } else {
        //   notifyError(JOIN_COURSE_POLL_ANONYMOUSLY_ERROR);
        //   return;
      }
    }

    if (audienceRestriction === AUDIENCE_RESTRICTION.NAME_REQUIRED && !session.authorization && !name) {
      // notifyError(POLL_DISABLE_ANONYMOUS_WARNING);
      return;
    }

    if (session.authorization && session.userId) {
      // if (await checkUserAccess(courseId)) {
        setWarning(false);
        setUserNameToLocalStorage(pollUniqueCode, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
        history.push(`/join/${pollUniqueCode}`);
      // }
    }
  }

  const checkUserAccess = async (courseId) => {
    if (courseId == null) {
        return true;
    }

    const accessibleResult = await checkMemberAccess(courseId.substring(2));
    console.log(accessibleResult)
    if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
        notifyError(USER_ACCESS_POLL_ERROR);
        formatErrorLog("User cannot access the poll");
        return false; 
    }

    return true;
  }

  const validateCode = (code, warning=true) => {
    let returnVal = true;
    setInputCodeInvalid(false);
    if (!code) {
      if (warning) {
        document.getElementById("pErr").textContent = REQUIRED_FIELD_ERROR;
        setInputCodeInvalid(true);
      }
      returnVal = false;
    } else if (code.length !== POLL_CODE_LENGTH) {
      if (warning) {
        document.getElementById("pErr").textContent = INVALID_POLL_CODE_ERROR;
        setInputCodeInvalid(true);
      }
      returnVal = false;
    }
    if (!returnVal && warning) {
      const codeInputs = document.getElementsByName("guest-login-poll-input");
      for (const element of codeInputs) {
        element.style.boxShadow = errorRed;
      }
    }
    return returnVal;
  };

  const handleClick = (index, event) => {
    let inputValue = event.target.value;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Update the state
    const newPollCode = [...pollCode];
    newPollCode[index] = inputValue;
    setPollCode(newPollCode);
    if (inputValue.trim() === "") {
      return;
    }

    if (document.getElementById(`poll-code-input-${index + 1}`) !== null) {
      document.getElementById("poll-code-input-" + (index + 1)).select();
    }
}

  const handleJoin = async () => {
    let code = "" + document.getElementById("poll-code-input-0").value
        + document.getElementById("poll-code-input-1").value
        + document.getElementById("poll-code-input-2").value
        + document.getElementById("poll-code-input-3").value
        + document.getElementById("poll-code-input-4").value
        + document.getElementById("poll-code-input-5").value;


    if (!validateCode(code)) {
     return;
    }

    const result = await getPollRecordFromUniqueCode(code);
    if (!result || !result.poll) {
      notifyError(INVALID_POLL_CODE_ERROR);
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return;
    }

    const { index2Pk: courseId, audienceRestriction = null } = result.poll;
    if ((audienceRestriction === AUDIENCE_RESTRICTION.LOGGED_IN || audienceRestriction === AUDIENCE_RESTRICTION.REGISTERED || !!courseId) && !session.authorization) {
      formatErrorLog(POLL_DISABLE_GUEST_WARNING);
      notifyError(POLL_DISABLE_GUEST_WARNING);
      // history.push(`/qr/${code}`);
      return;
    }

    if ((audienceRestriction ? audienceRestriction === AUDIENCE_RESTRICTION.REGISTERED : !!courseId) && !(await checkUserAccess(courseId))) {
      // formatErrorLog(POLL_DISABLE_GUEST_WARNING);
      // notifyError(POLL_DISABLE_GUEST_WARNING);
      return;
    }

    let nameTrimmed = name.trim();
    if ((audienceRestriction ?
      audienceRestriction === AUDIENCE_RESTRICTION.NAME_REQUIRED :
      (result.poll.hasOwnProperty("anonymousJoin") && result.poll["anonymousJoin"] === true)) &&
    nameTrimmed === "") {
      formatErrorLog(POLL_DISABLE_ANONYMOUS_WARNING);
      notifyError(POLL_DISABLE_ANONYMOUS_WARNING);
      return;
    }

    if (name) {
      setCookie(name);
      setUserNameToLocalStorage(code, name, VIEWER_USER_TYPE.MANUAL_GUEST);
    } else {
      setUserNameToLocalStorage(code);
    }

    history.push(`/join/${code}`);
  };

  const handleScan = async () => {
    history.push(`/scan`);
  };

  const handleName = (event) => {
    setName(event.target.value);
  }

  const handleChange = (event) => {
    if (event.keyCode === 13) {
      handleJoin();
      return;
    }
    const index = parseInt(event.target.id.substring("poll-code-input-".length));
    if (event.keyCode === 8) {//backspace
      if (document.getElementById(`poll-code-input-${index - 1}`) !== null) {
        document.getElementById("poll-code-input-" + (index - 1)).select();
      }
      return
    }
  };

  const handlePaste = (index, event) => {
    event.preventDefault();
    let inputValue = event.clipboardData.getData('Text').replace(/[^0-9]/g, '');
    const newPollCode = [...pollCode];
    let i;
    for (i = 0; i < inputValue.length; i++) {
      if (index + i == POLL_CODE_LENGTH) break;
      newPollCode[index + i] = inputValue[i];
    }
    setPollCode(newPollCode);
    document.getElementById("poll-code-input-" + Math.min(index + i, POLL_CODE_LENGTH - 1)).select();
  };

  const loginJoin = async () => {
    let code = "" + document.getElementById("poll-code-input-0").value
        + document.getElementById("poll-code-input-1").value
        + document.getElementById("poll-code-input-2").value
        + document.getElementById("poll-code-input-3").value
        + document.getElementById("poll-code-input-4").value
        + document.getElementById("poll-code-input-5").value; 
    
    if (!validateCode(code)) {
          return;
    }
    const result = await getPollRecordFromUniqueCode(code);
    if (!result || !result.poll) {
      notifyError(INVALID_POLL_CODE_ERROR);
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return;
    }

    setWarning(false);
    history.push("/", {
      from: `/join/${code}`,
      pollCode: code,
    });
  };

  // const anonJoin = async (pollUniqueCode) => {
  //   setLoading(true);
  //   const result = await getPollRecordFromUniqueCode(pollUniqueCode);
  //   if (!result || !result.poll) {
  //     setLoading(false);
  //     return;
  //   }

  //   const {index2Pk: courseId} = result.poll;
  //   if (!!courseId) {
  //     setLoading(false);
  //     return;
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log(currentUrl);
    const code = currentUrl.split('/').pop();

    if (code && code !== "qr") {
      setPollCode(code);
    }
    if (validateCode(code, false)) {
      // anonJoin(code);
      setScanQRRequired(false);
      setShowLoginJoin(true);
      checkAuthorization(code);
    }
  }, []);


  return (
    <>
      <Row style={{height: "100%", width: "100%", margin: 0, padding: 0}}>
        <div className="left-login-wrapper" style={{backgroundImage: !isMobile && isNarrow && (window.innerHeight > 480 || window.innerHeight > window.innerWidth) ? `url(${imgUrl})` : ""}}>
            <Container className="guest-login-page-container" style={{display: "flex", flexDirection: "column"}}>
            {scanQRRequired && <GoBack style={{marginLeft: "18px", cursor: "pointer"}} onClick={() => {window.location.href = LOGIN_PATH}}/> }

              <Row><Col><span className="d-flex justify-content-center"><img src={Logo} className="guest-login-logo" alt="YuJa Logo" /></span></Col></Row>

              <Row style={{margin: "2% 0% 5% 0%"}}><Col><span className="guest-login-title d-flex justify-content-center" style={{fontSize: "18px", color: "#000"}}>{WELCOME_YUJA_ENGAGE}</span></Col></Row>

              <Row><Col><span className="guest-login-subtitle d-flex justify-content-center">{JOIN_POLL_DESCRIPTION}</span></Col></Row>

              <Container style={{padding: "0px", width: !isMobile && isNarrow ? ((window.innerHeight < 480 || window.innerHeight < window.innerWidth) ? "70%" : "60%") : "90%"}}>
                <Form className="name-cont">
                  <label className="guest-login-input-title" style={{paddingLeft: 0, paddingRight: 0}}>{JOIN_POLL_MODAL_NAME_REQUIRED_LABEL}</label>
                  <YuJaTextBox value={name} onChange={handleName} onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleJoin();
                  } 
              }}/>
                </Form>

                <Form className="poll-cont">
                  <label className="guest-login-input-title" style={{paddingLeft: 0, paddingRight: 0}}>{ACTIVITY_CODE}</label>
                  <div className="input-code-container">
                    <YuJaTextBox id="poll-code-input-0" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ?"": isNarrow? "50px": "65px", maxHeight: isMobile ? "": isNarrow ? "60px" : "70px" }} isInvalid={inputCodeInvalid} inputAlign={"center"} inputMode="numeric" maxLength="1"  useValue = {true} value={pollCode[0]} onChange={(e) => {handleClick(0, e)}} onKeyUp={(e) => {handleChange(e)}} onPaste={e => handlePaste(0, e)}/>
                    <YuJaTextBox id="poll-code-input-1" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px",maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow? "60px": "70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[1]} onChange={(e) => {handleClick(1, e)}} onKeyUp={(e) => {handleChange(e)}} onPaste={e => handlePaste(1, e)}/>
                    <YuJaTextBox id="poll-code-input-2" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow? "60px" :"70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[2]}  onChange={(e) => {handleClick(2, e)}} onKeyUp={(e) => {handleChange(e)}} onPaste={e => handlePaste(2, e)}/>
                    <YuJaTextBox id="poll-code-input-3" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow? "60px" :"70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"} inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[3]} onChange={(e) => {handleClick(3, e)}} onKeyUp={(e) => {handleChange(e)}} onPaste={e => handlePaste(3, e)}/>
                    <YuJaTextBox  id="poll-code-input-4" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px" : "65px", maxHeight: isMobile? "" : isNarrow? "60px" :"70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[4]} onChange={(e) => {handleClick(4, e)}} onKeyUp={(e) => {handleChange(e)}} onPaste={e => handlePaste(4, e)}/>
                    <YuJaTextBox  id="poll-code-input-5" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow ? "60px" : "70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[5]} onChange={(e) => {handleClick(5, e)}} onKeyUp={(e) => {handleChange(e)}} onPaste={e => handlePaste(5, e)}/>
                  </div>
                  <Row><p id="pErr" className="err" /></Row>
                </Form>

                <div className="d-flex justify-content-center">
                  <YuJaButton onClick={handleJoin} style={{width: '100%', padding: '15px', backgroundColor: "#42296E", color: "#FFF", fontSize: "18px"}}>{JOIN_POLL}</YuJaButton>
                </div>
                {scanQRRequired &&
                <>
                <div className="or-line">
                  OR
                </div>
                <div className="d-flex justify-content-center" style={{fontSize: "18px",  color: "#42296E"}}>
                  <div onClick={handleScan} style={{textDecorationLine: "underline", marginLeft: "4px"}}>{SCAN_QR}</div>
                </div>
                </>
                }

                {showLoginJoin && <div style={{textDecorationLine: "underline", fontSize: "18px",  color: "#42296E", marginTop: "20px", textAlign: "center", cursor: "pointer"}} onClick={() => {loginJoin()}} > {LOGIN_BUTTON_TEXT} </div> }

              </Container>
              <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: isNarrow && (window.innerHeight > 480 || window.innerHeight > window.innerWidth) ? "center" : "left",flex: "1" }}>
                <div style={{flex: "1"}}> </div>
              <p className="guest-login-issues d-flex justify-content-center" style={{fontSize: "14px", textAlign: "center"}}>Having Issues? Contact&nbsp;<b onClick={() => window.open(YUJA_SUPPORT_LINK)} style={{cursor: "pointer"}}>YuJa Support</b></p>
                <p className="mobile-login-footer" style={{ fontSize: "14px",  color: "#000", width: "100%" , textAlign: "center" }}>{LOGIN_PAGE_FOOTER_TEXT}</p>
              </div>


            </Container>
          </div>
        {!isNarrow && (window.innerHeight > 480 || window.innerHeight > window.innerWidth) && (
          <div className="right-img-wrapper">
            <div style={{backgroundImage: `url(${imgUrl})`}} className="bg-img-container"/>
          </div>
        )}
      </Row>
    </>
  );
}
