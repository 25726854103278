import { React, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Rnd } from "react-rnd";
import ArrowIcon from "../../images/arrow_right.svg";
import {
    ASCII_CODE_A,
    ASCII_CODE_SMALL_A,
    BLANK_REGEX,
    BLANK_REGEX_NEW,
    DEFAULT_IMAGE_ALT,
    POLL_TYPE,
    QUESTION_TYPES, TEXTBOX
} from "../../utils/constants";
import { decodeCIParam } from "../../utils/questionUtils";
import "./styles.css";
import parse from 'html-react-parser';

export default function TabQuestion({question, pollType, institutionId, pollKey, forGradebook=false}) {
    const { correctAnswers, optionsMap, queTitle, serialNo, questionType, imageAlt, hasBlankRestriction = false, weightage, optionsImageMap={} } = question;
    const gradeEnabled = !!weightage && pollType !== POLL_TYPE.SURVEY;
    const [isVideo, setIsVideo] = useState(false);

    const [questionImageURL, setQuestionImageURL] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const imageRef = useRef(null);
    // const {pollKey} = useParams();
    const TFelements = ['True', 'False'];

    let keys = null;
    if (correctAnswers) {
        keys = Object.keys(correctAnswers); 
    }
    
    if (!gradeEnabled) {
        keys = null;
    }

    const isCorrectAnswer = (val) => {
        return (gradeEnabled && !!correctAnswers && correctAnswers.includes(val)) ? "MCSS-ans-c" : "MCSS-ans";
    };

    const renderTitle = () => {

        if (questionType === QUESTION_TYPES.FITB.name) {
            return (<><span className="question-title-FITB" style={{whiteSpace: "pre-line"}}>{queTitle.replace(hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX, "______")}</span></>)
        }
        return (<span className="question-title">{parse(queTitle)}</span>);
    };

    useEffect(() => {
        if(question.directLinkEvp) {
            setQuestionImageURL(question.directLinkEvp);
            setIsVideo(true);
        } else {
            setQuestionImageURL(question.imageURL);
            setIsVideo(false);
        }
    }, [serialNo, institutionId, pollKey, question]);

    const onLoad = () => {
        setIsLoaded(true);
    }

    return (<>
        <div className={forGradebook ? "question-tab-body-gb" : "question-tab-body"} style={{display: "flex", flexDirection: "column", rowGap: 10}}>
            <div tabIndex={0}>{renderTitle()}</div>

            {questionImageURL && (
              <div className="question-image-container" >
                <div style={{position: "relative", maxHeight: "100%", maxWidth: "100%"}}>
                    {!isVideo && <img ref={imageRef} className="question-image" src={questionImageURL} tabIndex={0} aria-label={imageAlt ? imageAlt : DEFAULT_IMAGE_ALT} onLoad={onLoad} alt="Question" />}
                    {isVideo && <iframe allowFullScreen style={{maxWidth: "min(40vw, 720px)", maxHeight: "min(22.5vw, 405px)"}} src={questionImageURL} alt="Question" />}
                    {questionType === QUESTION_TYPES.CI.name && isLoaded &&
                        optionsMap.map((area, idx) =>
                            <Rnd
                                key={idx}
                                id={`option-area-${idx}`}
                                size={{
                                    width: decodeCIParam(area.width, imageRef.current.width),
                                    height: decodeCIParam(area.height, imageRef.current.height)
                                }}
                                position={{
                                    x: decodeCIParam(area.x, imageRef.current.width),
                                    y: decodeCIParam(area.y, imageRef.current.height)
                                }}
                                enableResizing={false}
                                disableDragging={true}
                                bounds={"parent"}
                            >
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "solid #009883 1.5px",
                                    fontWeight: "400",
                                    fontSize: "15px",
                                    color: "#009883",
                                    borderRadius: "4px",
                                    backgroundColor: "rgba(246, 255, 254, 0.9)",
                                    boxShadow: "0px 0px 5px 0px #00000026"
                                }}>{String.fromCharCode(ASCII_CODE_A + idx)}</div>
                            </Rnd>
                        )
                    }
                </div>
              </div>
            )}

            {questionType === QUESTION_TYPES.MCSS.name && (
                <>
                    <div style={{height: 20}}/>
                    {Object.values(optionsMap).map((value, index) => {
                        const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + index);
                        const optIdxDisplay = String.fromCharCode(ASCII_CODE_A + index);

                        return (
                            <label tabIndex={0} key={index} className={isCorrectAnswer(optIdx)} style={{display: "flex", alignItems: "center", gap: 10}}>
                                <sapn style={{width: 20, flexShrink: 0}}>{optIdxDisplay}.</sapn>
                                {optionsImageMap.hasOwnProperty(optIdx) && !!optionsImageMap[optIdx].imageURL &&
                                    <div className="MCSS-opt-image-container" style={{width: 40, height: 40, borderRadius: 3}} >
                                        <img
                                            tabIndex={0}
                                            role={TEXTBOX}
                                            alt={`Option ${optIdx} image preview`}
                                            src={`${optionsImageMap[optIdx].imageURL}`}
                                            style={ { maxWidth: "100%", maxHeight: "100%"}}
                                        />
                                    </div>
                                }
                                <span>{[Object.values(optionsMap)[index]]}</span>
                            </label>
                        )})}
                </>
            )}

            {questionType === QUESTION_TYPES.TF.name && (
                <>
                    {TFelements.map((value, index) => {
                        return <label tabIndex={0} key={index}
                                      className={isCorrectAnswer(TFelements[index])}>{[TFelements[index]]}</label>
                    })}
                </>
            )}

            {questionType === QUESTION_TYPES.FITB.name && (
                <>
                    {keys ? keys.map((value, index) => {
                        return (
                            <Row key={index} className="FITB-row">
                                <div style={{width: "20%", margin: "0px", paddingRight: "0px"}}>
                                    <label tabIndex={0} key={index} className="FITB-ans-blank">{value}: </label>
                                </div>
                                <div style={{width: "80%", margin: "0px", paddingLeft: "0px"}}>
                                    {correctAnswers[value].map((value, index) => {
                                        return <span tabIndex={0} key={index} className={"FITB-ans"}>{value}</span>
                                    })}
                                </div>
                            </Row>
                        )
                    }) : <></>}
                </>
            )}

            {questionType === QUESTION_TYPES.SA.name && (
                <>
                    {correctAnswers.map((value, index) => {
                        return <label key={index} className="MCSS-ans">{value}</label>
                    })}
                </>
            )}

            {questionType === QUESTION_TYPES.RK.name && (
                <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                      {optionsMap.map((entry, index) => (
                        <div style={{display: "flex", alignItems: "center"}} key={index} tabIndex={0} aria-label={entry}>
                                    <div style={{fontSize: "16px",  wordBreak: "break-word"}}>
                                        Rank {index + 1}: {entry}
                                    </div>
                        </div>
                    ))}
                </div>
            )}

            {questionType === QUESTION_TYPES.CI.name && (
                <>
                    {optionsMap.map((option, index) => {
                        return (
                            <label key={index} tabIndex={0} 
                                   className={isCorrectAnswer(index)} style={{height: "fit-content"}}>
                                {`${String.fromCharCode(ASCII_CODE_A + index)}.   ${option.text}`}
                            </label>
                        );
                    })}
                </>
            )}
            {questionType === QUESTION_TYPES.MH.name && (
                <div> 
                <div> 
                {correctAnswers.map((element, index) => (
                <>
                {element.length > 1 ? 
                <div key={"Correct" + index} id={"CorrectOption" + index} className="questionPanelOptionsContainer" style={{marginBottom: "0px", gap: "20px"}}> 
                    <> 
                    <div tabIndex={0} className="questionPanelAnswersText" id={"CorrectPart1" + index} style={{marginRight: "0px", border: "3px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[0]}</div>
                    <img
                                 src={ArrowIcon}
                                 alt="Arrow icon"
                                 className="questions-page-icons cursor-pointer"
                                 style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                             />
                    </> 
                    <div tabIndex={0} className="questionPanelAnswersText" id={"CorrectPart2" + index} style={{border: "3px solid green", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[1]}</div>
                </div>
                : null }
                </>
            ))}
            </div>
            </div> 
                
            )
            }

        </div>
    </>);
}
