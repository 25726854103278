import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import PollCodeIcon from "../../images/poll_code.svg";
import CopyIcon from "../../images/copy.svg";
import { notifySuccess, copyTextToClipboard } from "../../utils/helpers";
import useMobileAccess from "../../hooks/useMobileAccess";
import { CLIPBOARD_COPY_SUCCESS } from "../../utils/toast-message-constants";
import { UNIQUECODE_TEXT } from "../../utils/constants";

export default function PublishModalLeftPane({ uniqueCode }) {
  const isMobile = useMobileAccess();

  const handleCopyClick = () => {
    copyTextToClipboard(uniqueCode);
    notifySuccess(CLIPBOARD_COPY_SUCCESS.replace(UNIQUECODE_TEXT, uniqueCode));
  };

  return (
    <Container style={{ position: "relative" }}>
      <Row>
        <Col>
          {/* <span className="join-link-text">
            Join at{" "}
            <Link to="/">
              <span className="modal-link">YuJaEngage.com</span>
            </Link>
          </span> */}
        </Col>
      </Row>
      <Row style={isMobile ? { height: "6.25rem" } : { height: "16.25rem" }}>
        <Col>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%"
            }}
          >
            <img
              style={{ marginBottom: "16px", marginRight: "2px" }}
              src={PollCodeIcon}
              alt="poll code"
            />
            <span className="poll-code-text">
              Poll Code: <span style={{ fontWeight: 500 }}>{uniqueCode}</span>
            </span>

            <img
              onClick={handleCopyClick}
              style={{ marginBottom: "12px", marginLeft: "5px" }}
              className="cursor-pointer"
              src={CopyIcon}
              alt="copy"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
