import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as NoResult } from '../../images/NoActivityFound.svg';
import { UNSUPPORTED_ACTIVITY, UNSUPPORTED_ACTIVITY_JOIN_DESC, UNSUPPORTED_ACTIVITY_RELEASE_DESC } from '../../utils/constants';

export default function DisabledActivityPage() {
    const location = useLocation();

    return (
        <div className="col-md-12" style={{ height: "100%", display: "flex", alignItems: "center", flexDirection: "column", padding: 25 }}>
            <div style={{ marginTop: 90, height: "631px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <NoResult />
                <div style={{ fontSize: 20, marginTop: 50 }}>
                    {UNSUPPORTED_ACTIVITY}
                </div>
                <h4 style={{ color: "#666666", fontSize: 15, textAlign: "center", marginTop: 25 }}>
                    {location?.state?.fromReleasePage && UNSUPPORTED_ACTIVITY_RELEASE_DESC}
                    {location?.state?.fromJoinPage && UNSUPPORTED_ACTIVITY_JOIN_DESC}
                </h4>
            </div>
        </div>
    );
}