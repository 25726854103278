import {ReactComponent as CloseIcon} from "../../images/Close.svg";
import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaImageViewer.module.css"
import {useWindowDimensions} from "react-native-web";

export const YuJaImageViewer = ({imageURL="", imageAlt="", onHandleClose, showViewer, }) => {
    const {width, height} = useWindowDimensions();
    const isNarrow = width < 600;
    const isLandScape = width > height;

    const timeOutRef = useRef(0);
    const [showTopBar, setShowTopBar] = useState(false);

    useEffect(() => {
        if (showViewer) {
            setShowTopBar(true);
            timeOutRef.current = setTimeout(() => {
                setShowTopBar(false);
            }, 3000);
        }

        return () => {
            clearTimeout(timeOutRef.current);
        }
    }, [showViewer]);

    const onMove = () => {
        console.log("test");
        clearTimeout(timeOutRef.current);
        setShowTopBar(true);
        timeOutRef.current = setTimeout(() => {
            setShowTopBar(false);
        }, 3000);
    }

    return (
        <>
            {showViewer &&
                <div
                    onClick={e => e.stopPropagation()}
                    className={`${styles.container} ${isLandScape ? styles.landscapeView : styles.profileView}`}
                    onMouseMove={onMove}
                >
                    <div className={styles.mask} onClick={onHandleClose}/>
                    <img
                        tabIndex={0}
                        alt={imageAlt}
                        src={`${imageURL}`}
                        style={ { maxWidth: "100%", maxHeight: "100%", background: "#FFF" }}
                    />
                    <div className={styles.topBar} style={{opacity: showTopBar ? 1: 0}}>
                        <div style={{flex: 1}}/>
                        <CloseIcon style={{width: isNarrow ? 20 : 40, height: isNarrow ? 20 : 40, cursor: "pointer"}} onClick={onHandleClose}/>
                    </div>

                </div>
            }

        </>
    );
}