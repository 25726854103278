import React from "react";
import { Modal } from "react-bootstrap";
import "./modal.css";

export default function GenericModal(props) {

  const {id, size = "lg", content, centered = false, ...rest } = props;

  return (
    <Modal
      {...rest}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
      id={id}
    >
      {content}
    </Modal>
  );
}
