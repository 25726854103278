import React from "react";
import { useHistory } from "react-router-dom";

import "./styles.css";
import YuJaButton from "../../components/standardization/YuJaButton";

//reference: https://codepen.io/ricardpriet/pen/qVZxNo

export default function Page404() {
  let history = useHistory();

  return (
    <div className="no-match-page-container">
      <h1 className="centered-title">Oops! That page can’t be found.</h1>
      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
          <div className="link-container">
              <YuJaButton onClick={() => {history.goBack()}}>Go Back</YuJaButton>
              <div style={{flex: 1}}/>
              <YuJaButton onClick={() => {history.push("/")}}>Go to Home Page</YuJaButton>
          </div>
      </section>

    </div>
  );
}
