import { ContentState, Editor, EditorState, Modifier } from "draft-js";
import "draft-js/dist/Draft.css";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { BLANK_REGEX, BLANK_REGEX_NEW, CHARACTERS_REMAINING, FITB_EMPTY_BLANK_ERROR, FITB_EMPTY_TITLE_ERROR, FITB_INPUT, FITB_LONG_BLANK_ERROR, FITB_QUESTION_LENGTH, FITB_QUESTION_TITLE, QUESTION_LENGTH, TEXTBOX } from "../../utils/constants";
import { customSlice, notifyError } from "../../utils/helpers";
import {
  updateCorrectAnswers,
  updateTitleInput,
} from "../../utils/questionUpdate";
import { checkBlankCountChange, checkBlankNameChange, checkBlankNameExceed, getBlanks } from "../../utils/questionUtils";
import { FITB_BLANK_COUNT_ERROR, FITB_BLANK_NAME_ERROR, FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED } from "../../utils/toast-message-constants";
import FITBRow from "./FITBRow";
import "./draftEditorStyle.css";

export default function FITBQuestionTitle({
  questions,
  setQuestions,
  selectedQuestion,
  editorState,
  setEditorState,
  compositeDecorator,
  // previewModalShow,
  setPreviewModalShow,
  hoverDisplay=false,
  questionBuilder=false,
  // customStyleFn=null,
  disabled=false,
  hasBlankRestriction=false
}) {
  const { queTitle='', serialNo=1, correctAnswers: blanks, imageURL, directLinkEvp } = selectedQuestion;
  const blankVariableRegex = /Blank [\d]+/;
  const indexToChange = serialNo - 1;
  const [hasFocus, setHasFocus] = useState(false);
  // const [keyCounter, setKeyCounter] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const blankRegex = hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX;

  const setTitleAndCorrectAns = (newTitle) => {
    if (!hoverDisplay) {
      let newQuestions = updateTitleInput(newTitle, indexToChange, questions);
      newQuestions = updateCorrectAnswers(
        arrangeBlanks(newTitle.match(blankRegex)),
        indexToChange,
        newQuestions
      );
      setQuestions(newQuestions);
    }
  };

  const arrangeBlanks = (blankKeys) => {
    let newBlanks = {};
    let maxKeyCounter = 0;

    if (blankKeys) {
      blankKeys.forEach((key) => {
        key = key.replaceAll(")", "").replaceAll("(", "");

        if (blanks[key]) {
          newBlanks[key] = blanks[key];
        } else {
          newBlanks[key] = [""];
        }

        if (blankVariableRegex.test(key)) {
          const keyNumbers = key.match(/\d+/);

          if (keyNumbers.length === 1) {
            const keyNumber = Number(keyNumbers[0]);
            if (maxKeyCounter < keyNumber) {
              maxKeyCounter = keyNumber;
            }
          } else {
            throw new Error(
              "Blank key cannot include more than one number substring."
            );
          }
        }
      });
    }
    // setKeyCounter(maxKeyCounter + 1);
    return newBlanks;
  };

  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      customSlice(text, QUESTION_LENGTH - currentContent.getPlainText('').replace(blankRegex, '').length)
    );
    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  const handleAddBlank = () => {
    const currentContent = editorState.getCurrentContent();
    let newBlankNumber = 1;
    const currentText = currentContent.getPlainText();

    if (currentText !== "") {
      const currentBlanks = currentText.match(blankRegex);

      if (currentBlanks) {
        const blankNumbers = currentBlanks.map(match => {
          const matches = match.match(/\d+/);
          return matches ? parseInt(matches[0], 10) : null;
        }).filter(number => number !== null);

        while (blankNumbers.includes(newBlankNumber)) {
          newBlankNumber++;
        }
      }
    }
    const textToInsert = "(Blank " + String(newBlankNumber) + ")";
    const currentContentLength = currentContent.getPlainText('').replace(blankRegex, '').length;
    if (currentContentLength <= FITB_QUESTION_LENGTH){
      const newEditorState = insertText(textToInsert, editorState);
      setEditorState(newEditorState);
      setTitleAndCorrectAns(newEditorState.getCurrentContent().getPlainText());
      setErrorMsg(null);
    } else {
      notifyError(FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED);
    }
  };

  const handlePreview = () => {
    setPreviewModalShow(true); 
  }

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      // console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }
    return length;
  }

  const handleBeforeInput = (e) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').replace(blankRegex, '').length;
    const selectedTextLength = getLengthOfSelectedText();

    // console.log(e);
    if (questionBuilder && disabled) {
      const oldTitle = editorState.getCurrentContent().getPlainText();
      const newTitle = oldTitle.slice(0, editorState.getSelection().getStartOffset()) + e + oldTitle.slice(editorState.getSelection().getEndOffset());
      // console.log(oldTitle, newTitle);
      if (checkBlankCountChange(oldTitle, newTitle, hasBlankRestriction)) {
        notifyError(FITB_BLANK_COUNT_ERROR);
        return 'handled';
      }
      if (checkBlankNameChange(oldTitle, newTitle, hasBlankRestriction)) {
        notifyError(FITB_BLANK_NAME_ERROR);
        return 'handled';
      }
    }

    if (currentContentLength - selectedTextLength > FITB_QUESTION_LENGTH - 1) {
      // notifyError(FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED);
      // console.log('you can type max 150 characters');
      return 'handled';
    }
  }

  const handlePastedText = (pastedText, html) => {
    const selectedText = editorState.getCurrentContent().getPlainText('');
    const rawText = (selectedText ? selectedText : "") + pastedText;

    if (questionBuilder && disabled) {
      const oldTitle = editorState.getCurrentContent().getPlainText();
      const newTitle = oldTitle.slice(0, editorState.getSelection().getStartOffset()) + pastedText + oldTitle.slice(editorState.getSelection().getEndOffset());
      // console.log(oldTitle, newTitle);
      if (checkBlankCountChange(oldTitle, newTitle, hasBlankRestriction)) {
        notifyError(FITB_BLANK_COUNT_ERROR);
        return 'handled';
      }
      if (checkBlankNameChange(oldTitle, newTitle, hasBlankRestriction)) {
        notifyError(FITB_BLANK_NAME_ERROR);
        return 'handled';
      }
    }

    if (rawText.replace(blankRegex, '').length > FITB_QUESTION_LENGTH) {
      // notifyError(FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED);
    }

    const newEditorState = insertText(pastedText, editorState);
    changeTitle(newEditorState);
    // setEditorState(
    //     EditorState.createWithContent(
    //         ContentState.createFromText(rawText.slice(0, QUESTION_LENGTH)),
    //         compositeDecorator
    //     )
    // );
    // console.log(editorState);

    // Important! Tell draft-js you already handled the pasted text
    return 'handled';
  }

  const handleKeyCommand = (command) => {
    if ((command === 'backspace' || command === 'delete') && questionBuilder && disabled) {
      const oldTitle = editorState.getCurrentContent().getPlainText();
      const newTitle = oldTitle.slice(0, editorState.getSelection().getStartOffset() - (editorState.getSelection().getStartOffset() === editorState.getSelection().getEndOffset() ? 1 : 0)) + oldTitle.slice(editorState.getSelection().getEndOffset());
      if (checkBlankCountChange(oldTitle, newTitle, hasBlankRestriction)) {
        notifyError(FITB_BLANK_COUNT_ERROR);
        return 'handled';
      }
      if (checkBlankNameChange(oldTitle, newTitle, hasBlankRestriction)) {
        notifyError(FITB_BLANK_NAME_ERROR);
        return 'handled';
      }
    }
    return 'not-handled';
  };

  const checkError = (title) => {
    if (queTitle === title) return; // If the title is the same as the previous title, return

    if (title.length === 0) { // Check if the question is empty
      setErrorMsg(FITB_EMPTY_TITLE_ERROR);
      return;
    }

    // const oldBlanks = getBlanks(queTitle, true);
    // const newBlanks = getBlanks(title, true);
    if (checkBlankNameExceed(getBlanks(title, false))) { // Check if blank name exceeds the limit
      setErrorMsg(FITB_LONG_BLANK_ERROR);
      return;
    }
    
    if (getBlanks(title, true).length === 0) { // Check if the question has at least 1 blank
      setErrorMsg(FITB_EMPTY_BLANK_ERROR);
      return;
    }

    setErrorMsg(null); // If no error, set error message to null
  };

  const changeTitle = (editorSt) => {
    if (editorSt) {
      if (hasBlankRestriction) {
        checkError(editorSt.getCurrentContent().getPlainText());
      }
      setEditorState(editorSt);
      setTitleAndCorrectAns(editorSt.getCurrentContent().getPlainText());
    } else {
      setEditorState(editorState);
      if (!questionBuilder) {
        setTitleAndCorrectAns(editorState.getCurrentContent().getPlainText());
      }
    }
  }

  useEffect(() => {
    changeTitle();
  }, []);

  // const addBlankStyle = {
  //   fontFamily: "Poppins",
  //   fontStyle: "normal",
  //   fontWeight: 400,
  //   fontSize: "16px",
  //   width: "106.213px",
  //   height: "51.95px",
  //   padding: "7.5px",
  //   float: "right",
  //   margin: "0px"
  // };

  useEffect(() => {
    // console.log(editorState);
    if (hasFocus) { // Show cursor in the end of the title 
      const emptyState = EditorState.createWithContent(ContentState.createFromText(""), compositeDecorator);
      const newEditorState = insertText(queTitle, emptyState); // insert question title to editor
      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createWithContent(ContentState.createFromText(queTitle), compositeDecorator));
    }
  }, [serialNo]);

  return (
      <FITBRow
          desc={FITB_QUESTION_TITLE}
          handleAddBlank={handleAddBlank}
          handlePreview={handlePreview}
          showButtons={true}
          questionBuilder={questionBuilder}
          disabled={disabled}
          hasError={!!errorMsg}
          hasImage={!!imageURL || !!directLinkEvp}
      >
        <div style={{fontSize: questionBuilder ? "16px" : "18px", color: "#535353", fontWeight: "400", width: "100%", padding: "0px"}}>
          <div
              style={{
                border: hasFocus ? "1px solid transparent" : "1px solid #535353",
                borderRadius: 12,
                padding: questionBuilder ? "6px 6px 15px 6px" : 13,
                boxShadow: hasFocus ? "0 0 0 2px #42296E" : ""
              }}

          >
            <Editor
              readOnly={questionBuilder && disabled}
              handleKeyCommand={handleKeyCommand}
              // customStyleMap={questionBuilder ? {fontSize: "10px"} : {}}
              // customStyleFn={customStyleFn}
              ariaLabel={FITB_INPUT}
              role={TEXTBOX}
              editorState={editorState}
              onChange={changeTitle}
              placeholder= {"Question"}
              spellCheck={true}
              handleBeforeInput={handleBeforeInput}
              handlePastedText={handlePastedText}
              onFocus={() => setHasFocus(true)}
              onBlur={() => setHasFocus(false)}
            />
          </div>
          <div className="fitb-title-footer">
            <div className="error-display">
              {errorMsg}
            </div>
            {!questionBuilder && <div className="fitb-char-count">
              {FITB_QUESTION_LENGTH - (editorState ? editorState.getCurrentContent().getPlainText().replace(blankRegex, '').length : 0)}{CHARACTERS_REMAINING}
            </div>}
          </div>
        </div>
      </FITBRow>
  );
}
