import styles from "./PPTInstallGuidePage.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import React, {useEffect, useState} from "react";
import {S3_PUBLIC_BUCKET_API_URL, S3_PUBLIC_BUCKET_NAME} from "../../utils/properties";
import {
    PPT_INSTALL_DESCRIPTION,
    PPT_INSTALL_STEP1_BUTTON_TEXT,
    PPT_INSTALL_STEP1_SUB_1,
    PPT_INSTALL_STEP1_TITLE,
    PPT_INSTALL_STEP2_SUB_1,
    PPT_INSTALL_STEP2_SUB_2,
    PPT_INSTALL_STEP2_SUB_3,
    PPT_INSTALL_STEP2_TITLE,
    PPT_INSTALL_STEP3_SUB_1,
    PPT_INSTALL_STEP3_SUB_2,
    PPT_INSTALL_STEP3_SUB_3,
    PPT_INSTALL_STEP3_TITLE,
    PPT_INSTALL_STEP4_SUB_1,
    PPT_INSTALL_STEP4_SUB_2,
    PPT_INSTALL_STEP4_SUB_3,
    PPT_INSTALL_STEP4_TITLE,
    PPT_PLUGIN_INSTALL_ZIP_FILE_NAME,
} from "../../utils/constants";

import { GrWindows } from "react-icons/gr";


export default function PPTInstallGuidePage() {

    const [downloadLink, setDownloadLink] = useState("");

    useEffect(() => {
        const url = `${S3_PUBLIC_BUCKET_API_URL}/s3?key=${S3_PUBLIC_BUCKET_NAME}/${PPT_PLUGIN_INSTALL_ZIP_FILE_NAME}`;
        const requestOptions = {method: "GET"};
        fetch(url, requestOptions).then((response) => {
            return response.blob();
        })
            .then((blob) => URL.createObjectURL(blob))
            .then((href) => {
                setDownloadLink(href);
            });
    }, []);

    const handleDownload = () => {
        if (downloadLink) {
        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = PPT_PLUGIN_INSTALL_ZIP_FILE_NAME;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.container}>
                    <div style={{height: 10}}/>
                    <div className={styles.description}>{PPT_INSTALL_DESCRIPTION}</div>
                    <div style={{height: 30}}/>
                    <div className={styles.stepContainer}>
                        <div className={styles.step}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className={styles.stepNo}>1</div>
                                <div className={styles.stepTitle}>{PPT_INSTALL_STEP1_TITLE}</div>
                                <div className={styles.stepLine}/>
                            </div>
                            <span>{PPT_INSTALL_STEP1_SUB_1}</span>
                            <YuJaButton onClick={handleDownload}><GrWindows style={{marginRight: 5}}/>{PPT_INSTALL_STEP1_BUTTON_TEXT}</YuJaButton>
                        </div>

                        <div className={styles.step}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className={styles.stepNo}>2</div>
                                <div className={styles.stepTitle}>{PPT_INSTALL_STEP2_TITLE}</div>
                                <div className={styles.stepLine}/>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: 6}}>
                                <span>{PPT_INSTALL_STEP2_SUB_1}</span>
                                <span>{PPT_INSTALL_STEP2_SUB_2}</span>
                                <span>{PPT_INSTALL_STEP2_SUB_3}</span>
                            </div>
                        </div>

                        <div className={styles.step}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className={styles.stepNo}>3</div>
                                <div className={styles.stepTitle}>{PPT_INSTALL_STEP3_TITLE}</div>
                                <div className={styles.stepLine}/>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: 6}}>
                                <span>{PPT_INSTALL_STEP3_SUB_1}</span>
                                <span>{PPT_INSTALL_STEP3_SUB_2}</span>
                                <span>{PPT_INSTALL_STEP3_SUB_3}</span>
                            </div>
                        </div>

                        <div className={styles.step}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className={styles.stepNo}>4</div>
                                <div className={styles.stepTitle}>{PPT_INSTALL_STEP4_TITLE}</div>
                                <div className={styles.stepLine}/>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", gap: 6}}>
                                <span>{PPT_INSTALL_STEP4_SUB_1}</span>
                                <span>{PPT_INSTALL_STEP4_SUB_2}</span>
                                <span>{PPT_INSTALL_STEP4_SUB_3}</span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}