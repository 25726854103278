import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { Spin } from "../../components/standardization/YuJaLoading";
import { useCookie } from "../../hooks/useCookie";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import {
    JOIN_POLL_MODAL_CANCEL, JOIN_POLL_MODAL_ENTER_POLL, JOIN_POLL_MODAL_NAME_REQUIRED_LABEL, JOIN_POLL_MODAL_TITLE,
    POLL_DISABLE_ANONYMOUS_WARNING,
    VIEWER_USER_TYPE
} from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaTextBox } from "../standardization/YuJaTextBox";


export default function AnonyJoinPollNameRequiredModal({ modalShow, setModalShow, name, setName, pollCode, nameRequired = false }) {
    const isLoading = false;
    const history = useHistory();
    const {setUserNameToLocalStorage} = ResponseApiCalls();
    const [setCookie, , ] = useCookie("name", "Guest");

    const handleJoin = async () => {
        let nameTrimmed = name.trim();
        if (nameRequired && nameTrimmed === "") {
            notifyError(POLL_DISABLE_ANONYMOUS_WARNING);
            return;
        } else {
            setCookie(nameTrimmed);
            setUserNameToLocalStorage(pollCode, nameTrimmed, VIEWER_USER_TYPE.MANUAL_GUEST);
            history.push("/join/" + pollCode);
        }
    };

    const handleClose = () =>{
        setName("");
        setModalShow(false);
    };

    return (
        <Modal
            id="form-modal"
            show={modalShow}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={isLoading ? "static" : true}
        >
            <Spin tip="Loading..." size="large" spinning={isLoading}>
                <ModalClose onClick={handleClose} className="modal-close" />
                <Modal.Header
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Modal.Title className="warning-title" id="contained-modal-title-vcenter">{JOIN_POLL_MODAL_TITLE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="customized-form" onSubmit={e => e.preventDefault()}>
                        <Form.Group style={{ marginBottom: "0.938rem" }}>
                            <Form.Label className="form-label">{JOIN_POLL_MODAL_NAME_REQUIRED_LABEL}</Form.Label>
                            <YuJaTextBox
                                containerStyle={{width: "90%", fontSize: 15}}
                                value={name || ""}
                                onChange={(e) => setName(e.target.value)}
                                placeholder={"Please enter name"}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="join-poll-modal-footer">
                    <div style={{flex: 1}}/>
                    <YuJaButton type="secondary"  onClick={handleClose}>{JOIN_POLL_MODAL_CANCEL}</YuJaButton>
                    <YuJaButton onClick={handleJoin}>{JOIN_POLL_MODAL_ENTER_POLL}</YuJaButton>
                </Modal.Footer>
            </Spin>
        </Modal>)
}
