import 'rc-datepicker/lib/style.css';
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import FilterMenu from '../../components/FilterMenu';
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from '../../hooks/useMobileAccess';
import { ReactComponent as Filter } from "../../images/filter.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    END_DATE,
    FILTER_POLLS,
    POLL_TYPE,
    READY_TO_SHARE,
    SEARCH_BOX_PLACEHOLDER,
    SEARCH_POLL,
    SEARCH_TEXT,
    START_DATE,
    STATUS_LABEL,
    UNFINISHED
} from "../../utils/constants";
import styles from "./PollChooser.module.css";

export default function PollChooserSearchForm({ handleSubmit, pollTypeFilter, collapsed=false }) {
    const filterRef = useRef();
    const filterMenuRef = useRef();
    const isNarrow = useRef(useMobileAccess(1200));
    const [keyword, setKeyword] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // const [modeFilter, setModeFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [oldCollapsed, setOldCollapsed] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [filterMenuDimensions, setFilterMenuDimensions] = useState({});
    const [filterMenuLocation, setFilterMenuLocation] = useState({x: 0, y: 0});
    const {setLoading} = useLoading();

    useEffect(() => {
        setStatusFilter([]);
    }, [pollTypeFilter]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && filterMenuRef.current && !filterMenuRef.current.contains(event.target) && !filterRef.current.contains(event.target)) {
                setShowFilterMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showFilterMenu && (oldCollapsed !== collapsed || filterMenuDimensions.width === 0 || JSON.stringify(filterMenuDimensions) !== JSON.stringify(filterMenuRef?.current?.getBoundingClientRect()))) {
            console.log(isNarrow.current, collapsed, filterRef?.current?.getBoundingClientRect(), filterMenuRef?.current?.getBoundingClientRect());
            setFilterMenuDimensions(filterMenuRef?.current?.getBoundingClientRect());
            setOldCollapsed(collapsed);
            resetFilterMenuPosition();
        }
    }, [collapsed, filterMenuRef?.current?.getBoundingClientRect()]);

    const clickSearch = ({key=keyword, start=startDate, end=endDate, status=statusFilter}) => {
        let formattedStartDate = start ? start.split("-").reverse().join("-") : "";
        let formattedEndDate = end ? end.split("-").reverse().join("-") : "";
        handleSubmit({
            keyword: key,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            mode: pollTypeFilter,
            status: status
        });
    };

    const clickFilter = () => {
        // if (disabled) {
        //     notifyError(LOADING_FILTER_ERROR);
        //     return;
        // }
        setShowFilterMenu(!showFilterMenu);
    };

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            setLoading(true);
            clickSearch({});
            setLoading(false);
        }
    };

    const resetFilterMenuPosition = () => {
        const filterRect = filterRef?.current?.getBoundingClientRect();
        // const filterMenuRect = filterMenuRef?.current?.getBoundingClientRect();
        // console.log(filterRect, filterMenuRect, window.scrollX, window.scrollY);
        // const x = filterRect?.left + window.scrollX - ( window.innerWidth <= 1200 ? 0 : ((filterMenuRect?.width - filterRect?.width) / 2 + 20 - (filterMenuRect?.width / 2) + (collapsed ? 60 : 240)));
        const x = -filterRect?.width - 10;
        // const y = filterRect?.bottom + window.scrollY - 52 + (5);
        const y = 25;
        // console.log(x, y);
        setFilterMenuLocation({x, y});
    };

    const onApply = (filterOptions) => {
        // setModeFilter(filterOptions.optionFilter1);
        setStatusFilter(filterOptions.optionFilter2);
        setStartDate(filterOptions.dateFilter1);
        setEndDate(filterOptions.dateFilter2);
        clickSearch({
            start: filterOptions.dateFilter1,
            end: filterOptions.dateFilter2,
            // mode: filterOptions.optionFilter1,
            status: filterOptions.optionFilter2
        });
    };

    return (
        <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <div ref={filterRef} style={{ position: "relative", display: "inline", marginRight: 15 }}>
                    <Filter className={`${styles.focusIcon}`} tabIndex={0} onMouseDown={e => e.preventDefault()} aria-label={FILTER_POLLS} onClick={clickFilter} style={{ width: 32, height: 32 }} />
                </div>
                <div style={{ position: "relative" }}>
                    <div
                        ref={filterMenuRef}
                        style={{
                            position: "absolute",
                            top: filterMenuLocation.y + "px",
                            left: filterMenuLocation.x + "px",
                        }}
                    >
                        <FilterMenu
                            show={showFilterMenu}
                            setShow={setShowFilterMenu}
                            onApply={onApply}
                            filterOptions={{
                                // optionFilter1Name: POLL_TYPE_HEADER,
                                // optionFilter1: modeFilter,
                                // optionsFilter1: [GRADED_MODE, SURVEY, ATTENDANCE],
                                optionFilter2Name: pollTypeFilter === POLL_TYPE.ATTENDANCE ? null : STATUS_LABEL,
                                optionFilter2: statusFilter,
                                optionsFilter2: [UNFINISHED, READY_TO_SHARE],
                                dateFilter1Name: START_DATE,
                                dateFilter1: startDate,
                                dateFilter2Name: END_DATE,
                                dateFilter2: endDate
                            }}
                            isChooser={true}
                        />
                    </div>
                </div>
                {/* <Form.Control
                    className={styles.searchBoxKeywordInput}
                    placeholder="Find by Poll Title"
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                /> */}
                <YuJaTextBox
                    label={SEARCH_POLL}
                    containerStyle={{fontSize: 12}}
                    placeholder={SEARCH_BOX_PLACEHOLDER}
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                />
                {/* <Form.Control
                    className={styles.searchBoxDateInput}
                    type="date"
                    placeholder="Start Date"
                    onChange={e => setStartDate(e.target.value)}
                    value={startDate}
                >
                </Form.Control>
                <Form.Control
                    className={styles.searchBoxDateInput}
                    type="date"
                    placeholder="End Date"
                    onChange={e => setEndDate(e.target.value)}
                    value={endDate}
                >
                </Form.Control> */}
                <YuJaButton onClick={clickSearch} style={{ marginLeft: 15 }}><div style={{ fontSize: 14 }}>{SEARCH_TEXT}</div></YuJaButton>
            </Form>
        </div>
    );
}