import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { COURSE_API_URL } from "../utils/properties";
import { notifySuccess, notifyError, formatAPIErrorLog, formatAPIResponseLog, logger } from "../utils/helpers";
import { useLoading } from "../utils/LoadingContext";
import { COURSE_DELETE_ERROR, COURSE_DELETE_SUCCESS, COURSE_UPDATE_SUCCESS, GET_COURSE_ERROR, INVALID_COURSE_CODE_ERROR, INVALID_COURSE_NAME_ERROR, LOAD_COURSE_ERROR, SAVE_COURSE_ERROR, SAVE_COURSE_MEMEBER_ERROR, SAVE_COURSE_MEMEBER_SUCCESS, SAVE_COURSE_SUCCESS, SERVER_ERROR, UPDATE_COURSE_ERROR } from "../utils/toast-message-constants";
import { INVALID_COURSE_CODE, INVALID_COURSE_NAME } from "../utils/constants";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
export const CourseApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    const {setLoading} = useLoading();
    const [, getSession] = useLocalStorageNew("session", {});
    const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});

    return {
        saveCourse,
        updateCourse,
        deleteCourse,
        getCourseByUser,
        getCourseByStudent,
        getCourseById,
        saveCourseMembers,
        checkMemberAccess,
        checkCourseCodeExist, 
    }

    // Function to Save a course  
    // returns "success: true" and the data of the course
    function saveCourse(courseBody) {
        setLoading(true);
        return fetchWrapper.post(
            {
                url: `${COURSE_API_URL}/course/save`,
                body: courseBody
            }
        ).then((result) => {
            console.log(result);
            if (result.success) {
                notifySuccess(SAVE_COURSE_SUCCESS);
            } else {
                notifyError(result.message);
            }
            return result;
        }, (error) => {
            console.log(error);
            if (error?.errorMessage === INVALID_COURSE_CODE) {
                notifyError(INVALID_COURSE_NAME_ERROR);
            } else if (error?.errorMessage === INVALID_COURSE_NAME) {
                notifyError(INVALID_COURSE_CODE_ERROR);
            } else {
                notifyError(SAVE_COURSE_ERROR);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function updateCourse(courseBody, courseId) {
        return fetchWrapper.put(
            {
                url: `${COURSE_API_URL}/course/${courseId}`,
                body: courseBody
            }
        ).then((result) => {
            console.log(result);
            if (result.success) {
                notifySuccess(COURSE_UPDATE_SUCCESS);
            } else {
                notifyError(result.message);
            }
            return result;
        }, (error) => {
                notifyError(UPDATE_COURSE_ERROR);
                console.log(error);
            });
    }

    

    // Function to Delete a course  
    // returns "success: true" and the data of the course
    function deleteCourse(courseId) {
        return fetchWrapper.delete(
            {
                url: `${COURSE_API_URL}/course/${courseId}`,
            }
        ).then((result) => {
            console.log(result);
            if (result.success) {
                notifySuccess(COURSE_DELETE_SUCCESS);
            } else {
                notifyError(result.message);
            }
            return result;
        }, (error) => {
                notifyError(COURSE_DELETE_ERROR);
                console.log(error);
            });
    }

    // Function to get all the courses a user is in
    // returns array of course data 
    function getCourseByUser(idpType, intType) {
        let getUrl;
        if(idpType && intType) {
            getUrl = "/course/courses/user/all/" + idpType + "/" + intType;
        } else {
            getUrl = "/course/courses/user"
        }

        return fetchWrapper.get(
            {
                url: `${COURSE_API_URL}` + getUrl,
            }
        ).then((result) => {
            console.log(result);
            if (!result.success && result.message !== "Unauthorized") {
                notifyError(result.message);
            }
            return result.result;
        }, (error) => {
                notifyError(LOAD_COURSE_ERROR);
                console.log(error);
            })
    }


    // Function to get all the courses a user is in
    // returns array of course data 
    function getCourseByStudent(silent, idpType, intType) {
        if (!silent) setLoading(true);
        let getUrl;
        if (idpType && intType) {
            getUrl = "/course/student/" + idpType + "/" + intType;
        } else {
            getUrl = "/course/student/idp/int"; // passing static string so that it does not call any other API with similar path
        }
        return fetchWrapper.get(
            {
                url: `${COURSE_API_URL}` + getUrl,
            }
        ).then((result) => {
            console.log(result);
            return result.result;
        }, (error) => {
                notifyError(LOAD_COURSE_ERROR);
                console.log(error);
            }).finally(() => {
                if (!silent) setLoading(false);
            });
    }


    // Function to get course details
    // returns   the data of the course
    function getCourseById(courseId) {
        const session = getSession();
        let getURL = COURSE_API_URL + "/course/" + courseId;
        if(session.idpType && session.intType) {
            getURL = getURL + "/" + session.idpType + "/" + session.intType;
        } else {
            getURL = getURL + "/idp/int";
        }
        return fetchWrapper.get(
            {
                url: getURL,
            }
        ).then((result) => {
            console.log(result);
            if (!result.success) {
                notifyError(result.message);
            }
            return result;
        }, (error) => {
                notifyError(GET_COURSE_ERROR);
                console.log(error);
            });
    }

    function checkCourseCodeExist(courseCode) {
        setLoading(true);

        let institutionId = getHostResource().institutionId;

        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }

        return fetchWrapper.get({
                url: `${COURSE_API_URL}/course/${institutionId}/existed?courseCode=${courseCode}`,
        }).then(res => {
            if (res.success) {
                return res.result;
            } else {
                notifyError(res.errorMessage);
                return true;
            }
        }).catch(err => {
            notifyError(SERVER_ERROR);
            return true;
        }).finally(() => {
            setLoading(false);
        });
    }


    function saveCourseMembers(courseId, memberIdArr) {
        setLoading(true);
        return fetchWrapper.put({
                url: `${COURSE_API_URL}/course/${courseId}/members`,
                body: {members: memberIdArr}
        }).then(() => {
            notifySuccess(SAVE_COURSE_MEMEBER_SUCCESS);
            return true;
        }).catch((error) => {
            notifyError(SAVE_COURSE_MEMEBER_ERROR);
            console.log(error);
            return false;
        }).finally(() => {
            setLoading(false);
        });
    }

    function checkMemberAccess(courseId) {
        return fetchWrapper.get({
            url: `${COURSE_API_URL}/course/access/${courseId}`,
            body: {},
            silent: true
        }).then((result) => {
            logger.courseId = (courseId.length === 36 ? "C#" + courseId : logger.courseId);
            formatAPIResponseLog("checkMemberAccess", result);
            return result;
        }).catch((error) => {
            console.log(error);
            formatAPIErrorLog("checkMemberAccess", error);
            return false;
        });
    }
}