import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import styles from "./platform.module.css";
import {useHistory} from "react-router-dom";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import {ROLES, TEXTBOX, UPLOAD_IMAGE_ERROR} from "../../utils/constants";
import {notifyError} from "../../utils/helpers";
import useHostname from "../../hooks/useHostname";
import defaultImage from "../LoginPage/images/main.jpg";
import YuJaButton from "../../components/standardization/YuJaButton";

export default function BrandingTab({imgUrl, setImgUrl}) {
    const history = useHistory();
    const [, getSession] = useLocalStorageNew("session", {});
    const {imageURL} = useHostname(window.location.hostname);
    const imageSuffixArr = ['.png', '.jfif', '.pjpeg', '.jpg', '.jpeg', '.pjp'];
    const [fileUploaded, setFileUploaded] = useState("");
    const [file, setFile] = useState("");

    useEffect(() => {
        const {role = ''} = getSession();
        if (role !== ROLES.ADMINISTRATOR.value && role !== ROLES.IT_MANAGER.value) {
            history.push({
                pathname: "/"
            });
            return;
        }


        setImgUrl(imageURL ? imageURL : defaultImage);
    }, []);

    const upload = (e) => {
        const suffix = e.target.value.substring(e.target.value.lastIndexOf("."));
        if (!imageSuffixArr.includes(suffix)) {
            e.target.value = null;
            notifyError(UPLOAD_IMAGE_ERROR);
        }
        setFileUploaded(e.target.files[0].name);
        setFile(URL.createObjectURL(e.target.files[0]));
    };

    return (
        <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
        <div className={styles.bodyBranding}>
            <div className={styles.titleBox}>
                <div className={styles.titleBoxIconBranding} />
                <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Branding Image</div>
            </div>
            <div className={styles.containerBranding}>
                <Form style={{width: "100%"}}>
                    <Form.Group controlId="formFile" style={{width: "100%"}}>
                        <div style={{display: "flex", flexDirection: "row", height: "400px", width: "100%", gap: 20}}>
                            <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                <label tabIndex={0} role={TEXTBOX} className={styles.brandingTitle} for="file-input">Choose Login Background Image</label>
                                <input id="file-input" type="file"  accept="image/png, image/jpeg" onChange={upload}/>
                                <YuJaButton id="brandingLastElem" htmlFor="file-input" style={{marginTop: "15px"}}>Browse</YuJaButton>
                                <div style={{marginTop: "10px"}}> {fileUploaded} </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center", flex: 1}}>
                                <img className={styles.preview} src={file ? file : imgUrl} alt="branding image preview"/>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        </div>
        </div>
    );
};
