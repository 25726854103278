import { useParams} from "react-router-dom";
import styles from "./PPTInsertCompletedPage.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import {ReactComponent as Success} from "./images/success.svg";
import {usePPTWebView} from "../../hooks/usePPTWebView";
import {PublicApiCall} from "../../apis/PublicApiCall";
import {useState} from "react";
import {fillFormatText} from "../../utils/helpers";
import {
    PPT_COMPLETE_PAGE_GO_BACK_BUTTON_TEXT,
    PPT_COMPLETE_PAGE_INSERT_SUCCESSFULLY_DESCRIPTION,
    PPT_COMPLETE_PAGE_INSERT_SUCCESSFULLY_FORMAT
} from "../../utils/constants";

export default function PPTInsertCompletedPage() {
    const { addedSlideCount } = useParams();
    const {getAppId, goPollsPage} = usePPTWebView();

    const {postPPTMessage} = PublicApiCall();

    const [isProcessing, setIsProcessing] = useState(false);


    const goPresentation = () => {
        setIsProcessing(true);
        const messageObj = {};
        messageObj.action = "activate";
        postPPTMessage(getAppId(), JSON.stringify(messageObj)).finally(() => {
            setIsProcessing(false);
        });
    }

    return (
        <div className={styles.container}>
            <div style={{flex: 1}}/>
            <Success/>
            <div style={{height: 50}}/>
            <span className={styles.title}>
                {fillFormatText(PPT_COMPLETE_PAGE_INSERT_SUCCESSFULLY_FORMAT, {count: addedSlideCount - 1})}
            </span>
            <div style={{height: 10}}/>
            <span className={styles.description}>
               {PPT_COMPLETE_PAGE_INSERT_SUCCESSFULLY_DESCRIPTION}
            </span>
            <div style={{height: 20}}/>
            <div style={{display:"flex"}}>
                <YuJaButton onClick={goPollsPage}>{PPT_COMPLETE_PAGE_GO_BACK_BUTTON_TEXT}</YuJaButton>
               {/* <div style={{width: 30}}/>
                <YuJaButton onClick={goPresentation} disabled={isProcessing}>Go to Presentation</YuJaButton>*/}
            </div>
            <div style={{flex: 2}}/>
        </div>
    );
}