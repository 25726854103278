import styles from "./PPTPollsPage.module.css"
import {ReactComponent as LOGO} from "../../images/YuJa_Logo_Login.svg";

export default function PPTSplashScreen() {
    return (
        <div className={styles.splashContainer}>
            <div style={{flex: 1}}/>
            <span className={styles.splashTitle}><LOGO style={{marginRight: 10}}/>YuJa Engage</span>
            <div style={{flex: 2}}/>
        </div>
    );
}