import React, { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { PublicApiCall } from "../../apis/PublicApiCall";
import { UserApiCalls } from "../../apis/UserApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTooltip } from "../../components/standardization/YuJaTooltip";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import { ReactComponent as NoResultIllustration } from "../../images/NoActivityFound.svg";
import {
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POINTS,
    DEFAULT_POLL_LMS_SYNC, DEFAULT_POLL_MAX_ATTEMPTS, DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_TIME_LIMIT,
    DELETE_ACTIVITY,
    DELETE_POLL_MODAL_BODY,
    DRAFT_STATUS,
    MODAL_CANCEL_TEXT,
    MODAL_DELETE_TEXT,
    NO_POLLS_TEXT,
    POLL_KEY,
    POLL_SHARE_MODE,
    POLL_SHARE_MODE_ACCESSOR,
    POLL_SORT_KEY,
    POLL_STATE,
    POLL_TITLE, POLL_TYPE,
    POLL_TYPE_ACCESSOR,
    PPT_DEFAULT_ROWS_PER_PAGE,
    PPT_POLLS_DATE_ACCESSOR,
    PPT_POLLS_TABLE_ACTION_HEADER,
    PPT_POLLS_TABLE_DATE_HEADER,
    PPT_POLLS_TABLE_QUESTION_HEADER,
    PPT_POLLS_TABLE_SELECT_HEADER,
    PPT_POLLS_TABLE_TITLE_HEADER,
    PPT_POLLS_TABLE_TYPE_HEADER,
    PPT_POLLS_TBLE_STATUS_HEADER,
    PPT_WEBVIEW_ADDING_SLIDES_ERROR1,
    PPT_WEBVIEW_ADD_POLL_TO_SLIDES_BUTTON,
    PPT_WEBVIEW_POLL_TABLE_GUIDE_LINE,
    PPT_WEBVIEW_REFRESH_BUTTON,
    SURVEY,
    TEXTBOX,
    TOTAL_QUESTIONS,
    USER_SETTINGS, QUESTION_TYPES
} from "../../utils/constants";
import {
    getDefaultUserSettings,
    notifyError,
    removePrefix,
    removePrefixNew, stripHTMLTags
} from "../../utils/helpers";
import { DELETE_ACTIVE_POLL_ERROR, RELEASE_INVALID_POLL_ERROR } from "../../utils/toast-message-constants";
import PollSearchForm from "../ManagePollsPage/PollSearchForm";
import addingSlides from "./animations/addingSlides.json";
import styles from "./PPTPollsPage.module.css";
import PPTPollsTable from "./PPTPollsTable";

export default function PPTPollsPage() {
    const {getAppId, goInsertWaitingPage, parseToken} = usePPTWebView();
    const {postPPTMessage} = PublicApiCall();
    const { loadUser } = UserApiCalls();
    const {getPublishedPollDataFromKey, copyPollToUser, softDeletePublishedPoll, softDeleteDraftPoll, updatePollDetails, getPollsPPT, publishPoll} = PollApiCalls();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [selectedPoll, setSelectedPoll] = useState({});
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [filter, setFilter] = useState({});

    const [pollKey, setPollKey] = useState("");
    const [pollUniqueCode, setPollUniqueCode] = useState("");
    const [pollType, setPollType] = useState("");

    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());

    const options = {
        loop: true,
        autoplay: true,
        animationData: addingSlides,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            // viewBox: "200 200 320 320",
            // filterSize: {
            // width: '200%',
            // height: '200%',
            // x: '-50%',
            // y: '-50%',
            // }
        }
    };

    useEffect(() => {
        loadPolls();
    }, []);


    useEffect(() => {
        const {institutionId, userId} = parseToken();
        loadUser(institutionId, userId).then(res => {
            const { settings = {} } = res;

            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
                settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
                settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            }
            settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
                settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
                settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
                settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
            }
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
            }
            settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
            }
            settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
            setUserSettings(settings);
        })
    }, []);

    const loadPolls = () => {
        setDataReady(false);
        getPollsPPT().then((res) => {
            const sortedArr = res
                .filter(p => !!p.pollType && [POLL_TYPE.ATTENDANCE, POLL_TYPE.MERGED_POLL].includes(p.pollType))
                .filter(i => i.pollShareMode !== POLL_SHARE_MODE.SHARE_ALL && i.pollShareMode !== POLL_SHARE_MODE.SCHEDULE)
                .filter(p => p.isValid !== false)
                .sort((a, b) => a.updatedTimeSK.localeCompare(b.updatedTimeSK))
                .reverse();
            setData(sortedArr);
        }).finally(() => {
            setDataReady(true);
        });
    }

    const insertPoll = async () => {
        if (!selectedPoll || !Object.entries(selectedPoll).length) {
            notifyError(PPT_WEBVIEW_ADDING_SLIDES_ERROR1);
            return;
        }

        setIsAdding(true);
        let messageObj;
        if (selectedPoll[POLL_SORT_KEY] === DRAFT_STATUS) {
            const pollToAdd = JSON.parse(JSON.stringify(selectedPoll));
            pollToAdd.pollKey = removePrefixNew(pollToAdd.pollKey);
            pollToAdd.pollShareMode = POLL_SHARE_MODE.SHARE_EACH;

            if (!pollToAdd.pollTimeLimit) {
                pollToAdd.pollTimeLimit = userSettings[USER_SETTINGS.POLL_DURATION];
            }
            // if (!pollToAdd.getReadyAllTimeLimit) pollToAdd.getReadyAllTimeLimit = userSettings[USER_SETTINGS.POLL_COUNTDOWN];
            if (!pollToAdd.lmsAttempt) {
                pollToAdd.lmsAttempt = userSettings[USER_SETTINGS.POLL_LMS_SYNC];
            }
            if (!pollToAdd.recordAttempt) {
                pollToAdd.recordAttempt = userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS];
            }
            if (!pollToAdd.maxAttempts) {
                pollToAdd.maxAttempts = userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS];
            }
            let questions = pollToAdd.questions;
            for (let i = 0; i < questions.length; i++) {
                if (!questions[i].weightage) {
                    questions[i].weightage = userSettings[USER_SETTINGS.QUESTION_POINTS];
                }
                if (!questions[i].timeLimit) {
                    questions[i].timeLimit = userSettings[USER_SETTINGS.QUESTION_DURATION];
                }
                if (!questions[i].getReadyTimeLimit) {
                    questions[i].getReadyTimeLimit = userSettings[USER_SETTINGS.QUESTION_COUNTDOWN];
                }
            }
            pollToAdd.questions = questions;
            const result = await publishPoll(pollToAdd, true);
            if (!result || !result.uniqueCode) {
                notifyError(RELEASE_INVALID_POLL_ERROR);
                setIsAdding(false);
                return;
            }
            const {uniqueCode} = result;

            const res = await getPublishedPollDataFromKey(pollToAdd.pollKey, uniqueCode);
            const {questions: publishedQuestions} = res;
            let processedQuestions = publishedQuestions.map(q => {
                q.queTitle = q.questionType !== QUESTION_TYPES.FITB.name ? stripHTMLTags(q.queTitle) : q.queTitle;
                return q;
            });


            messageObj = {
                pollKey: pollToAdd.pollKey,
                pollType: selectedPoll.pollType,
                pollCode: uniqueCode,
                pollTitle: selectedPoll.pollTitle,
                questions: processedQuestions
            };
        } else {
            const {pollKey, pollSortKey} = selectedPoll;
            const purePollKey = removePrefixNew(pollKey);
            const pollCode = removePrefixNew(pollSortKey);
            const res = await getPublishedPollDataFromKey(purePollKey, pollCode);
            const {poll, questions, isValid=true} = res;
            if (!isValid) {
                notifyError(RELEASE_INVALID_POLL_ERROR);
                setIsAdding(false);
                return;
            }

            if (!res.pollShareMode || res.pollShareMode === POLL_SHARE_MODE.UNKNOWN) {
                await updatePollDetails(purePollKey, pollCode, {pollShareMode: POLL_SHARE_MODE.SHARE_EACH}, true);
            }

            let processedQuestions = questions.map(q => {
                q.queTitle = q.questionType !== QUESTION_TYPES.FITB.name ? stripHTMLTags(q.queTitle) : q.queTitle;
                return q;
            });


            messageObj = {pollKey: purePollKey, pollType: poll.pollType,  pollCode, pollTitle: poll.pollTitle, questions: processedQuestions};
        }

        messageObj.action = "insert";
        postPPTMessage(getAppId(), JSON.stringify(messageObj)).finally(() => {
            setIsAdding(false);
            goInsertWaitingPage();
        });
    };

    const onDuplicate = async (item) => {
        let res;
        if (item[POLL_SORT_KEY] === DRAFT_STATUS) {
            res = await copyPollToUser({'pollKey': removePrefixNew(item.pollKey), 'pollType': item[POLL_TYPE_ACCESSOR]});
        } else {
            res = await copyPollToUser({'pollKey': removePrefixNew(item.pollKey), 'pollType': item[POLL_TYPE_ACCESSOR], 'pollCode': removePrefixNew(item[POLL_SORT_KEY])});
        }
        if (!res) {
            return;
        }
        const pollToAdd = {
            pollKey: removePrefix(res.pollKeys.substring(1, res.pollKeys.length - 1)),
            pollShareMode: POLL_SHARE_MODE.UNKNOWN,
            pollTimeLimit: item.pollTimeLimit ? item.pollTimeLimit : userSettings[USER_SETTINGS.POLL_DURATION],
        };
        await publishPoll(pollToAdd, true);
        loadPolls();
    }

    const onDeleteDraft = (pollKey, pollType) => {
        setPollKey(pollKey);
        setPollType(pollType);
        setShowDeleteModal(true);
    }



    const onDeletePublished = (pollKey, uniqueCode, pollType, poll) => {
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setPollType(pollType);
        const pollState = poll?.pollState;
        const pollShareMode = poll?.pollShareMode;
        const liveQuestionTime = poll?.liveQuestionTime;
        const timeLimit = poll?.pollTimeLimit;
        const start = new Date(poll?.startTime);
        const end = new Date(poll?.endTime);


        const now = new Date();
        if ((pollShareMode === POLL_SHARE_MODE.SCHEDULE && !!start && start < now && !!end && now < end) ||
            (pollShareMode === POLL_SHARE_MODE.SHARE_ALL && !!pollType && !!liveQuestionTime &&
                ((pollType === SURVEY && pollState === POLL_STATE.SHARED_ALL) || ((+now) < (liveQuestionTime + (timeLimit * 1000))))
            )) {
            notifyError(DELETE_ACTIVE_POLL_ERROR);
            return;
        }
        setShowDeleteModal(true);
        // let pollCode = uniqueCode.substring(7);
        // const res = await softDeletePublishedPoll(key, pollCode, pollType);
        // if (res.success) {
        //     getPollInformation(pageIndex);
        // }
    }


    const deletePollConfig = {
        title: DELETE_ACTIVITY,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT,
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        },
    };


    const handleDelete = async () => {
        console.log(pollKey, pollUniqueCode, pollType);
        let key = removePrefixNew(pollKey);
        try {
            setDataReady(false);
            setShowDeleteModal(false);
            if (pollUniqueCode) { // delete published
                console.log("DELETE PUBLISHED POLL");
                let pollCode = pollUniqueCode.substring(7);
                const res = await softDeletePublishedPoll(key, pollCode, pollType);
                if (res.success) {
                    loadPolls();
                }
            } else { // delete draft
                console.log("DELETE DRAFT POLL");
                const res = await softDeleteDraftPoll(key, pollType);
                if (res.success) {
                    loadPolls();
                }
            }
        } finally {
            setDataReady(true);
        }
    };


    const columns = [
        { Header: PPT_POLLS_TABLE_SELECT_HEADER, accessor: PPT_POLLS_TABLE_SELECT_HEADER, width: 50},
        { Header: PPT_POLLS_TABLE_TITLE_HEADER, accessor: POLL_TITLE, width: 200},
        { Header: PPT_POLLS_TABLE_DATE_HEADER, accessor: PPT_POLLS_DATE_ACCESSOR, width: 120 },
        { Header: PPT_POLLS_TABLE_TYPE_HEADER, accessor: POLL_TYPE_ACCESSOR, width: 100},
        { Header: PPT_POLLS_TABLE_QUESTION_HEADER, accessor: TOTAL_QUESTIONS, width: 80 },
        { Header: PPT_POLLS_TBLE_STATUS_HEADER, accessor: POLL_SHARE_MODE_ACCESSOR, width: 120 },
        { Header: PPT_POLLS_TABLE_ACTION_HEADER, accessor: POLL_KEY, width: 80, id: 'action' }
      ];


      const handleSubmit = useCallback(({ ...filterObj }) => {
        setFilter(filterObj);
        setPageIndex(0);
      }, []);
    return (
        <div className={styles.tableParentContainer} id="manage-poll-page-container">
            <YuJaGeneralAlert
                show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={DELETE_POLL_MODAL_BODY}
                config={deletePollConfig}
                AlertType={MODAL_DELETE_TEXT}/>

            <div className={styles.controllerContainer}>
                <span className={styles.description}>{PPT_WEBVIEW_POLL_TABLE_GUIDE_LINE}</span>
                <YuJaButton style={{padding: "5px 10px", width: 140}} onClick={loadPolls} disabled={!dataReady}>{PPT_WEBVIEW_REFRESH_BUTTON}</YuJaButton>
                {(!selectedPoll || !Object.entries(selectedPoll).length) &&
                    <YuJaTooltip placement={"bottom"} text={PPT_WEBVIEW_ADDING_SLIDES_ERROR1}>
                        <span>
                            <YuJaButton
                                style={{padding: "5px 10px", width: 140}}
                                onClick={insertPoll}
                                disabled={true}
                            >
                                {PPT_WEBVIEW_ADD_POLL_TO_SLIDES_BUTTON}
                            </YuJaButton>
                        </span>
                    </YuJaTooltip>
                }
                {selectedPoll && !!Object.entries(selectedPoll).length &&
                    <YuJaButton
                        style={{padding: "5px 10px", width: 140}}
                        onClick={insertPoll}
                        disabled={isAdding}
                    >
                        {PPT_WEBVIEW_ADD_POLL_TO_SLIDES_BUTTON}
                    </YuJaButton>
                }
            </div>
            <div style={{height: 30}}/>
            {dataReady && data && data.length !== 0 &&
                <>
                    <PollSearchForm handleSubmit={handleSubmit} isPPT={true} />
                    <PPTPollsTable
                        columns={columns}
                        data={data}
                        setData={setData}
                        pageIdx={pageIndex}
                        globalFilterObj={filter}
                        setSelectedPoll={setSelectedPoll}
                        selectedPoll={selectedPoll}
                        onDuplicate={onDuplicate}
                        onDeleteDraft={onDeleteDraft}
                        onDeletePublished={onDeletePublished}
                    />
                </>
            }
            {dataReady && data && data.length === 0 &&
                <>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "631px" }}>
                    <NoResultIllustration style={{ width: "100%", maxHeight: "100%", marginRight: "15px" }} />
                    <div role={TEXTBOX} style={{ marginTop: "50px", fontSize: 15, marginBottom: 25 }}>{NO_POLLS_TEXT}</div>
                </div>    
                </>
            }
            {!dataReady && (
        <>
            <PollSearchForm handleSubmit={handleSubmit} />
            <div className={styles.skeletonTableContainer}>
              <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                <div className={styles.skeletonHeaderContainer}>
                  <div className={styles.skeletonHeaderCell}> </div>
                  <div className={styles.skeletonHeaderCell} style={{width: "100%", height: "100%", justifyContent: "start", alignItems: "center"}}>{PPT_POLLS_TABLE_TITLE_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{PPT_POLLS_TABLE_DATE_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{PPT_POLLS_TABLE_TYPE_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{PPT_POLLS_TABLE_QUESTION_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{PPT_POLLS_TBLE_STATUS_HEADER}</div>
                  <div className={styles.skeletonActionHeaderCell}>{PPT_POLLS_TABLE_ACTION_HEADER}</div>
                </div>
                <div className={styles.skeletonBodyContainer}>
                  {Array.apply(null, Array(PPT_DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                    return (<div className={styles.skeletonBodyRow}>
                      <div> </div>
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${6 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} style={{display: "flex", width: "100%", height: "100%", justifyContent: "start", alignItems: "center"}}/>
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${3 + (Math.random()*7)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${idx % 2 === 0 ? 3.5 : 3}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${1 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                    </div>)
                  })}
                </div>
              </SkeletonTheme>
            </div>
        </>
      )}
            {/* <Table >
                <thead>
                <tr>
                    <th width={50}/>
                    <th width={200} style={{textAlign: "start"}}>
                        <span className={styles.sortColumnLayout} style={{justifyContent: "flex-start"}} onClick={sortTitleHelper}>
                            Title
                            {sortedBy === sortedByEnum.TITLE && titleSort === 1 &&
                                <Up tabIndex={0} aria-label={"Title ascending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.TITLE && titleSort === 1 &&
                                <UpGrey tabIndex={0} aria-label={"Title ascending order"} style={{width: 15}}/>
                            }

                            {sortedBy === sortedByEnum.TITLE && titleSort === -1 &&
                                <Down tabIndex={0} aria-label={"Title descending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.TITLE && titleSort === -1 &&
                                <DownGrey tabIndex={0} aria-label={"Title descending order"} style={{width: 15}}/>
                            }
                        </span>
                    </th>
                    <th width={120}>
                        <span className={styles.sortColumnLayout} onClick={sortDateHelper}>
                           Date
                            {sortedBy === sortedByEnum.DATE && dateSort === 1 &&
                                <Up tabIndex={0} aria-label={"Date ascending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.DATE && dateSort === 1 &&
                                <UpGrey tabIndex={0} aria-label={"Date ascending order"} style={{width: 15}}/>
                            }

                            {sortedBy === sortedByEnum.DATE && dateSort === -1 &&
                                <Down tabIndex={0} aria-label={"Date descending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.DATE && dateSort === -1 &&
                                <DownGrey tabIndex={0} aria-label={"Date descending order"} style={{width: 15}}/>
                            }
                        </span>
                    </th>
                    <th width={100}>
                        <span className={styles.sortColumnLayout} onClick={sortTypeHelper}>
                           Type
                            {sortedBy === sortedByEnum.TYPE && typeSort === 1 &&
                                <Up tabIndex={0} aria-label={"Type ascending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.TYPE && typeSort === 1 &&
                                <UpGrey tabIndex={0} aria-label={"Type ascending order"} style={{width: 15}}/>
                            }

                            {sortedBy === sortedByEnum.TYPE && typeSort === -1 &&
                                <Down tabIndex={0} aria-label={"Type descending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.TYPE && typeSort === -1 &&
                                <DownGrey tabIndex={0} aria-label={"Type descending order"} style={{width: 15}}/>
                            }
                        </span>

                    </th>
                    <th width={80}>Questions</th>
                    <th width={120}>
                        <span className={styles.sortColumnLayout} onClick={sortStatusHelper}>
                            Status
                            {sortedBy === sortedByEnum.STATUS && statusSort === 1 &&
                                <Up tabIndex={0} aria-label={"Status ascending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.STATUS && statusSort === 1 &&
                                <UpGrey tabIndex={0} aria-label={"Status ascending order"} style={{width: 15}}/>
                            }

                            {sortedBy === sortedByEnum.STATUS && statusSort === -1 &&
                                <Down tabIndex={0} aria-label={"Status descending order"} style={{width: 15}}/>
                            }
                            {sortedBy !== sortedByEnum.STATUS && statusSort === -1 &&
                                <DownGrey tabIndex={0} aria-label={"Status descending order"} style={{width: 15}}/>
                            }
                        </span>
                    </th>
                    <th width={80}>Action</th>
                </tr>
                </thead>
            </Table>


            <div className={styles.normalContainer} style={{position: "relative",}}>
                {!!data && !!data.length &&
                    <div style={{position: "absolute", width: '100%', height: "100%", overflowY: "scroll"}}>
                        <Table >
                            <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td width={50} >
                                        <YuJaRadioBox
                                            aria-label={RADIAL_LABEL + (selectedPoll.pollKey === item.pollKey ? "selected" : "unselected")}
                                            checked={selectedPoll.pollKey === item.pollKey}
                                            onClick={() => setSelectedPoll(item)}
                                        />
                                    </td>
                                    <td width={200} style={{textAlign: "start"}}>
                                        {item.pollType === POLL_TYPE.ATTENDANCE &&
                                            <span >{item.pollTitle}</span>
                                        }
                                        {item.pollType !== POLL_TYPE.ATTENDANCE &&
                                            <span
                                                className={styles.pollTitleLink}
                                                title={item.pollTitle}
                                                onClick={() => {
                                                    if (item[POLL_SORT_KEY] === DRAFT_STATUS) {
                                                        goPollDetailPage(removePrefixNew(item.pollKey));
                                                    } else {
                                                        goPollDetailPage(removePrefixNew(item.pollKey), removePrefixNew(item[POLL_SORT_KEY]));
                                                    }
                                                }}
                                            >
                                                {item.pollTitle}
                                            </span>
                                        }
                                    </td>
                                    <td width={120}>{!!item.updatedTimeSK ? item.updatedTimeSK.substring(0, item.updatedTimeSK.indexOf("T")) : ""}</td>
                                    <td width={100}>
                                        {item.pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE : item.pollType}
                                    </td>
                                    <td width={80}>{item.totalQuestions}</td>
                                    <td width={120}>
                                        {item[POLL_SORT_KEY] === DRAFT_STATUS && <span>{POLL_STATUS_ENUM.UNFINISHED}</span>}
                                        {item.pollShareMode === POLL_SHARE_MODE.UNKNOWN && <span>{POLL_STATUS_ENUM.READY_TO_RELEASE}</span>}
                                        {item.pollShareMode === POLL_SHARE_MODE.SHARE_EACH && <span>{POLL_STATUS_ENUM.SHARE_EACH}</span>}
                                        {item.pollShareMode === POLL_SHARE_MODE.SHARE_ALL &&
                                            <span>
                                                {item.pollType === GRADED_POLL && POLL_STATUS_ENUM.SHARE_ALL_GRADED_POLL}
                                                {item.pollType === SURVEY && POLL_STATUS_ENUM.SHARE_ALL_SURVEY}
                                            </span>
                                        }
                                        {item.pollShareMode === POLL_SHARE_MODE.SCHEDULE && <span>{POLL_STATUS_ENUM.SCHEDULE}</span>}
                                    </td>
                                    <td width={80}>
                                    <span style={{display: "flex", justifyContent: "center"}}>
                                        <YuJaKebabMenu>
                                            <YuJaKebabMenu.Menu>
                                                {(item[POLL_SORT_KEY] === DRAFT_STATUS || item.pollShareMode === POLL_SHARE_MODE.UNKNOWN) &&
                                                    <YuJaKebabMenu.Item onClick={() => goEditPage(removePrefixNew(item.pollKey))}>
                                                        <EditIcon style={{width: 18, marginRight: 5}}/>{EDIT_TEXT}
                                                    </YuJaKebabMenu.Item>
                                                }
                                                <YuJaKebabMenu.Item
                                                    onClick={() => {
                                                        item[POLL_SORT_KEY] === DRAFT_STATUS ?
                                                            onDeleteDraft(item.pollKey, item[POLL_TYPE_ACCESSOR]) :
                                                            onDeletePublished(item.pollKey, item[POLL_SORT_KEY], item[POLL_TYPE_ACCESSOR], item)
                                                    }}
                                                >
                                                    <DeleteIcon style={{width: 18, marginRight: 5}}/>{MODAL_DELETE_TEXT}
                                                </YuJaKebabMenu.Item>
                                                <YuJaKebabMenu.Item onClick={() => onDuplicate(item)}>
                                                    <CopyIcon style={{ width: 18, marginRight: 5 }} />{DUPLICATE_TEXT}
                                                </YuJaKebabMenu.Item>
                                            </YuJaKebabMenu.Menu>
                                        </YuJaKebabMenu>
                                    </span>
                                    </td>

                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                }

                {dataReady && (!data || !data.length) &&
                    <div style={{flex: 1, flexDirection: "column", display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1}}/>
                        <NoResultIllustration style={{maxWidth: "100%", maxHeight: "100%", marginRight: "15px"}}/>
                        <div style={{height: 20}}/>
                        <span>
                            No polls found. Please&nbsp;
                            <span style={{fontWeight: "700", color: "#42296E", cursor: "pointer"}} onClick={goCreatePollPage}>Create Poll</span>
                            &nbsp;or&nbsp;
                            <span style={{fontWeight: "700", color: "#42296E", cursor: "pointer"}} onClick={goCreateSurveyPage}>Create Survey</span>
                            &nbsp;first.
                        </span>
                        <div style={{flex: 2}}/>
                    </div>
                }

                {(!dataReady || isAdding) &&
                    <div className={styles.animationContainer}>
                        <div style={{flex: 1}}/>
                        <Spin size="large" wrapperClassName="globalSpin" spinning={true} tip="Loading..."/>
                        <div style={{flex: 3}}/>
                    </div>
                }
            </div> */}
        </div>
    );
}