import React, {useEffect, useState} from "react";
import styles from "./Box.module.css"

export default React.memo(NormalBox)
function NormalBox({children, boxType='light', className}) {
    const [classNamesState, setClassNamesState] = useState(className ? className.split(' ') : []);

    useEffect(() => {
        const clsNames = [className];

        switch (boxType) {
            case 'lightBlue': {
                clsNames.push(styles.lightBlue);
                break;
            }
            default: {
                clsNames.push(styles.light);
            }

        }
        clsNames.push();
        setClassNamesState(clsNames);

    }, [boxType, className]);
    
    return (
        <div className={classNamesState.join(" ")}>{children}</div>
    );
}