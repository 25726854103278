import { CompositeDecorator, ContentState, EditorState } from "draft-js";
// import createStyles from "draft-js-custom-styles";
import React, {useContext, useEffect, useMemo, useState} from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Lottie from "react-lottie";
import { PollApiCalls } from "../../apis/PollApiCalls";
import AddQuestionConfirmationModal from "../../components/modals/AddQuestionConfirmationModal";
import DeleteConfirmationModal from "../../components/modals/DeleteConfirmationModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import CIQuestionOption from "../../questions/CI/CIQuestionOption";
import FITBAnswer from "../../questions/FITB/FITBAnswer";
import FITBQuestionTitle from "../../questions/FITB/FITBQuestionTitle";
import MCQuestionOption from "../../questions/MCSS/MCQuestionOption";
import RKQuestion from "../../questions/RK/RKQuestion";
import SAQuestion from "../../questions/SA/SAQuestion";
import TFQuestionOption from "../../questions/TF/TFQuestionOption";
import {
    BLANK_REGEX,
    BLANK_REGEX_NEW,
    CHARACTERS_REMAINING,
    CI_ADD_OPTION_TEXT,
    CORRECT_ANSWER,
    DISABLED_ATTENDANCE_BUILDER_CAPTION,
    DISABLED_QUESTION_BUILDER_CAPTION,
    ENTER_QUESTION_TITLE,
    FITB_QUESTION_LENGTH,
    IMAGE,
    MCSS_ADD_OPTION_TEXT,
    MEDIA,
    MH_ADD_OPTION_TEXT,
    OPTIONS,
    POLL_SHARE_MODE,
    POLL_TYPE,
    QUESTION_BUILDER_ADD_QUESTION_BUTTON,
    QUESTION_BUILDER_ADD_TOOLTIP,
    QUESTION_BUILDER_BUTTON,
    QUESTION_BUILDER_CAPTION,
    QUESTION_BUILDER_DELETE_BUTTON,
    QUESTION_BUILDER_DISCARD_BUTTON,
    QUESTION_BUILDER_SCROLL_DOWN_BUTTON,
    QUESTION_BUILDER_SCROLL_UP_BUTTON,
    QUESTION_BUILDER_UPDATE_QUESTION_BUTTON, QUESTION_LENGTH,
    QUESTION_TITLE,
    QUESTION_TITLE_CHARACTER_LIMIT,
    QUESTION_TYPE,
    QUESTION_TYPES,
    VIDEO_ERROR_CI
} from "../../utils/constants";
import { findWithRegex, intToChar, notifyError, notifyInfo, notifySuccess } from "../../utils/helpers";
import {
    handleAddAnswer,
    handleDeleteAnswer,
    handleUpdateAnswer,
    updateCorrectAnswers,
    updateQuestionType,
    updateTitleInput
} from "../../utils/questionUpdate";
import {
    addQuestion,
    addRankToQuestion,
    checkSameQuestions, convertMHOptionMap,
    getBlankRestriction,
    questionError,
    trimQuestion
} from "../../utils/questionUtils";
import {
    ADD_OPTIONS_INFO,
    ADD_QUESTION_LOADING_ERROR,
    ADD_QUESTION_SUCCESS,
    DELETE_QUESTION_SUCCESS,
    DISCARD_QUESTION_SUCCESS,
    QUESTION_CHARACTER_LIMIT_EXCEEDED
} from "../../utils/toast-message-constants";
import addQuestionAnimation from "./animations/addQuestion.json";
import scrollDownAnimation from "./animations/seeMore.json";
import { ReactComponent as DisabledBuilderIcon } from "./images/DisabledBuilderIcon.svg";
import "./styles.css";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";
import {useWindowDimensions} from "react-native-web";
import {YuJaRichContentEditBox} from "../../components/standardization/YuJaRichContentEditBox";
import UploadQuestionImageNew from "../../questions/UploadQuestionImageNew";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import {useToaster} from "react-hot-toast";

export default function QuestionBuilder({
                                            pollKey,
                                            pollCode,
                                            hasResponses, // (hasResponses && (qs.length === originalData?.questions?.length || currentQuestion !== qs.length))
                                            isSharing,
                                            data,
                                            setData,
                                            originalData, setOriginalData,
                                            defaultView, setDefaultView,
                                            firstAdd, setFirstAdd,
                                            currentQuestion, setCurrentQuestion,
                                            selectedQuestion, setSelectedQuestion,
                                            geofence, setGeofence,
                                            mapInitialized, setMapInitialized,
                                            syncLms, setSyncLms,
                                            // resetData, setResetData,
                                            shareMode,
                                            startDate,
                                            userSettings,
                                            // initialized,
                                            loading,
                                            collapsed,
                                            handleUpdateQuestion
}) {

    const {toasts} = useToaster();
    const {questions:originalQuestions=[]} = originalData;
    const {questions: qs=[]} = data;

    const setQs = (qs) => {
        let qsCopy = JSON.parse(JSON.stringify(qs));
        setData({ ...data, questions: qsCopy });
    }

    let {width: windowWidth} = useWindowDimensions();
    // const [loaded, setLoaded] = useState(false);
    const { addQuestionToLivePoll } = PollApiCalls();
    // const [qs, setQs] = useState([]);
    const [questionType, setQuestionType] = useState(QUESTION_TYPES.MCSS.name);
    const [hasBlankRestriction, setHasBlankRestriction] = useState(false);
    // const [questionTitle, setQuestionTitle] = useState("");
    // const [questionOptions, setQuestionOptions] = useState({});
    const [pollType, setPollType] = useState(POLL_TYPE.MERGED_POLL);
    const [questionBuilderState, setQuestionBuilderState] = useState("");
    const options = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: addQuestionAnimation,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
        };
    }, [addQuestionAnimation]);
    const scrollOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: scrollDownAnimation,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
        };
    }, [scrollDownAnimation]);
    const [discardModalShow, setDiscardModalShow] = useState(false);
    const [addQuestionModalShow, setAddQuestionModalShow] = useState(false);
    const [disabledEditQuestion, setDisabledEditQuestion] = useState(false);
    const [showSeeMore, setShowSeeMore] = useState(false);
    const [scrollDown, setScrollDown] = useState(true);
    const [warningShowed, setWarningShowed] = useState(false);
    const [, getLocalGeofence] = useLocalStorageNew("geofence", {});
    const [, getLocalSyncLms] = useLocalStorageNew("syncLms", {});
    const [questionMainWidth, setQuestionMainWidth] = useState("100%");
    // console.log(getLocalGeofence(), geofence, getLocalSyncLms(), syncLms);
    const {checkAnyQuestionIsLoading} = useImageLoaderManager(qs, setQs);

    // START FITB
    const BlankStrategy = (contentBlock, callback) => {
        findWithRegex(hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX, contentBlock, callback);
    };

    const BlankSpan = (props) => {
        return (
            <span
                style={{
                    color: "#029ce2",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontSize: "18px"
                }}
                data-offset-key={props.offsetKey}
            >
                {props.children}
            </span>
        );
    };

    const [compositeDecorator,] = useState(new CompositeDecorator([
        {
            strategy: BlankStrategy,
            component: BlankSpan,
        },
    ]));

    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromText(qs[currentQuestion - 1]?.queTitle || ""),
            compositeDecorator
        )
    );

    // const [firstStyle, setFirstStyle] = useState(true);
    // const {styles, customStyleFn} = createStyles(['font-size']);
    // const addFontSize = styles.fontSize.add;

    // useEffect(() => {
    //     if (editorState && firstStyle && questionType === QUESTION_TYPES.FITB.name) {
    //         console.log("setting FITB font size to 10");
    //         setEditorState(styles.fontSize.add(editorState, 5));
    //         setFirstStyle(false);
    //     }
    // }, [editorState, firstStyle, questionType]);
    // END FITB

    useEffect(() => {
        console.log(`isSharing:${isSharing}, hasResponses:${hasResponses}, selectedQuestion:${selectedQuestion}`)
        if (isSharing) {
            // go add question (disabled button )
            setQuestionBuilderState("DISABLED");
            return;
        }

        const {pollShareMode, endTime} = originalData.poll;
        if (originalData.pollShareMode === POLL_SHARE_MODE.UNKNOWN) {
            //go question edit
            setQuestionBuilderState(selectedQuestion ? "EDIT" : "ENABLED");
        } else if (originalData.pollShareMode === POLL_SHARE_MODE.SHARE_ALL) {
            // go add question (disabled button )
            setQuestionBuilderState("DISABLED");
        } else if (pollShareMode === POLL_SHARE_MODE.SCHEDULE) {
            if (new Date() > new Date(endTime)) {
                // if is end go add question (disabled button )
                setQuestionBuilderState("DISABLED");
            } else {
                //go question edit
                setQuestionBuilderState(selectedQuestion ? "EDIT" : "ENABLED");
            }

        } else {//share each
            if (hasResponses) {
                // go add question
                setQuestionBuilderState(pollType === POLL_TYPE.ATTENDANCE ? "DISABLED" : "ENABLED");
            } else {
                //go question edit
                setQuestionBuilderState(selectedQuestion ? "EDIT" : "ENABLED");
            }
        }
    }, [isSharing, hasResponses, selectedQuestion, pollType]);

    useEffect(() => {
        if (!data) return;
        const { poll={} } = data;
        setPollType(poll.pollType);
    }, [data]);

    useEffect(() => {
        const dataCopy = JSON.parse(JSON.stringify(data));
        setHasBlankRestriction(getBlankRestriction(dataCopy?.questions));
    }, [data]);

    // useEffect(() => {
    //     if (!qs || qs.length === 0 || JSON.stringify(data?.questions) === JSON.stringify(qs)) {
    //         return;
    //     }
    //     const qsCopy = JSON.parse(JSON.stringify(qs));
    //     setData({ ...data, questions: qsCopy });
    // }, [qs]);

    useEffect(() => {
        if (!qs || qs.length < currentQuestion) return;
        let qType = qs[currentQuestion - 1]?.questionType || QUESTION_TYPES.MCSS.name;
        setQuestionType(qType);
        // setQuestionTitle(qs[currentQuestion - 1]?.queTitle || "");
        // const q = initialQuestionData(qType, userSettings, true);
        // setOptionsMap(qs[currentQuestion - 1]?.optionsMap || q.optionsMap);
        // setCorrectAnswers(qs[currentQuestion - 1]?.correctAnswers || q.correctAnswers);
    }, [qs, currentQuestion]);

    useEffect(() => { // set default view if shareMode is SHARE_ALL or SCHEDULE and current date is greater than startDate
        if (shareMode === POLL_SHARE_MODE.SHARE_ALL || (shareMode === POLL_SHARE_MODE.SCHEDULE && new Date() > new Date(startDate))) {
            setDefaultView(true);
        }
    }, [shareMode, startDate]);

    const handleAddQuestion = () => {
        if (loading) {
            notifyError(ADD_QUESTION_LOADING_ERROR);
            return;
        }
        if (defaultView) {
            setDefaultView(false);
        }
        const { questions=[] } = data;
        if (firstAdd) {
            let newQs = addQuestion(questions, QUESTION_TYPES.MCSS.name, userSettings, true);
            setQs(newQs);
            setCurrentQuestion(newQs.length);
            setFirstAdd(false);
        } else {
            setCurrentQuestion(questions.length);
            setQs(questions);
        }
        setSelectedQuestion(true);
    };

    const handleQuestionTypeUpdate = (newQuestionType) => {
        const q = qs[currentQuestion - 1];

        if(newQuestionType.name === QUESTION_TYPES.CI.name && q.directLinkEvp) {
            notifyError(VIDEO_ERROR_CI);
            return;
        }

        if (newQuestionType.name === QUESTION_TYPES.FITB.name) {
            // setQuestionTitle("");
        }

        let questions = updateQuestionType(newQuestionType.description, currentQuestion - 1, qs, questionType, userSettings, true);
        setQs(questions);
        // setData({ ...data, questions: questions });
        setQuestionType(newQuestionType.name);
    };

    const handleQuestionTitleUpdate = (val, plainVal) => {
        if (plainVal.length > QUESTION_TITLE_CHARACTER_LIMIT) {
            let toastObj = toasts.find(toastObj => toastObj.message === QUESTION_CHARACTER_LIMIT_EXCEEDED);
            if (!toastObj) {
                notifyError(QUESTION_CHARACTER_LIMIT_EXCEEDED);
            }
        }

        let newQuestions = JSON.parse(JSON.stringify(qs));
        let newQuestion = newQuestions[currentQuestion - 1];
        newQuestion["queTitle"] = newQuestion.questionType === QUESTION_TYPES.FITB.name ? plainVal : val;
        setQs(newQuestions);
    };

    // START MCSS
    const handleAddOptionMCSS = () => {
        if (hasResponses) {
            return;
        }
        if (qs[currentQuestion - 1]?.optionsMap && Object.keys(qs[currentQuestion - 1].optionsMap).length >= 26) {
            if (!warningShowed) {
                setWarningShowed(true);
                notifyInfo(ADD_OPTIONS_INFO);
            }
            return;
        }
        let newOptions = {...qs[currentQuestion - 1]?.optionsMap};
        newOptions[intToChar(Object.keys(newOptions).length)] = "";
        console.log(newOptions);
        let newQuestions = [...qs];
        let newQuestion = { ...newQuestions[currentQuestion - 1] };
        newQuestion["optionsMap"] = newOptions;
        newQuestions[currentQuestion - 1] = newQuestion;
        setQs(newQuestions);
        setTimeout(() => {
            const questionBuilderMain = document.querySelector(".questionBuilderMain");
            if (!questionBuilderMain) {
                return;
            }
            questionBuilderMain.scrollTo({ top: questionBuilderMain.scrollHeight, behavior: "smooth" });
        }, 10);
    };

    const handleAddOptionRK = () => {
        const curQueObj = qs[currentQuestion - 1];
        const newQuestions = addRankToQuestion(
            curQueObj,
            qs
        );
        if (newQuestions) {
            setQs(newQuestions);
        } else if (!warningShowed) {
            setWarningShowed(true);
            notifyInfo(ADD_OPTIONS_INFO);
        }

        setTimeout(() => {
            const questionBuilderMain = document.querySelector(".questionBuilderMain");
            if (!questionBuilderMain) {
                return;
            }
            questionBuilderMain.scrollTo({ top: questionBuilderMain.scrollHeight, behavior: "smooth" });
        }, 10);
    }


    const handleAnswerOrderChange = (result) => {
        if (!result.destination) return;
    
        const sourceKey = qs[currentQuestion-1].questionType === QUESTION_TYPES.CI.name ? result.source.index : intToChar(result.source.index);
        const destinationKey = qs[currentQuestion-1].questionType === QUESTION_TYPES.CI.name ? result.destination.index : intToChar(result.destination.index);
    
        let newQuestions = [];
    
        qs.forEach((q) => {
            if (q.serialNo === currentQuestion) {
                let updatedQuestion = {...q};

                let newOptionsMap = JSON.parse(JSON.stringify(q.optionsMap));
                newOptionsMap[sourceKey] = q.optionsMap[destinationKey];
                newOptionsMap[destinationKey] = q.optionsMap[sourceKey];
                updatedQuestion.optionsMap = newOptionsMap;

                let newCorrectAns = q.correctAnswers;
                const correctAnswer = q.correctAnswers[0];
                if (sourceKey === correctAnswer) {
                    newCorrectAns = [destinationKey];
                } else if (destinationKey === correctAnswer) {
                    newCorrectAns = [sourceKey];
                }
                updatedQuestion.correctAnswers = newCorrectAns;

                if (!!q.optionsImageMap) {
                    let newOptionsImageMap = {...q.optionsImageMap};
                    newOptionsImageMap[sourceKey] = q.optionsImageMap[destinationKey];
                    newOptionsImageMap[destinationKey] = q.optionsImageMap[sourceKey];
                    if (!newOptionsImageMap[sourceKey]) {
                        delete newOptionsImageMap[sourceKey];
                    }
                    if (!newOptionsImageMap[destinationKey]) {
                        delete newOptionsImageMap[destinationKey];
                    }
                    updatedQuestion.optionsImageMap = newOptionsImageMap;
                }
                newQuestions.push(updatedQuestion);
            } else {
            newQuestions.push(q);
          }
        });
        setQs(newQuestions);
    };
    // END MCSS

    // START BUILDER FOOTER
    useEffect(() => {
        const questionBuilderMain = document.querySelector(".questionBuilderMain");
        if (questionBuilderMain) {
            setShowSeeMore(questionBuilderMain.scrollHeight > questionBuilderMain.clientHeight);
            setQuestionMainWidth(questionBuilderMain.offsetWidth - 40);
        }
    }, [qs, currentQuestion, selectedQuestion, questionBuilderState]);

    useEffect(() => {
        // if (!loaded) {
        //     console.log("not loaded; true");
        //     setScrollDown(true);
        //     return;
        // }
        
        const questionBuilderMain = document.querySelector(".questionBuilderMain");
        if (!questionBuilderMain) {
            // console.log("no element; true");
            setScrollDown(true);
            return;
        }

        // console.log("initial", questionBuilderMain.scrollHeight, questionBuilderMain.scrollTop, questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop), questionBuilderMain.clientHeight, questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop) > questionBuilderMain.clientHeight);
        setScrollDown((questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop)) > questionBuilderMain.clientHeight);
        questionBuilderMain.addEventListener("scroll", () => {
            // console.log("scroll", questionBuilderMain.scrollHeight, Math.floor(questionBuilderMain.scrollTop), questionBuilderMain.scrollHeight - Math.floor(questionBuilderMain.scrollTop), questionBuilderMain.clientHeight, questionBuilderMain.scrollHeight - Math.floor(questionBuilderMain.scrollTop) !== questionBuilderMain.clientHeight);
            // if (scrollDown === (questionBuilderMain.scrollHeight - questionBuilderMain.scrollTop) !== questionBuilderMain.clientHeight) return;
            // console.log("scroll", questionBuilderMain.scrollHeight, questionBuilderMain.scrollTop, questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop), questionBuilderMain.clientHeight, questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop) > questionBuilderMain.clientHeight);
            setScrollDown((questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop)) - questionBuilderMain.clientHeight >= 3 );
        });

        return () => {
            questionBuilderMain.removeEventListener("scroll", () => {});
        };
    }, [selectedQuestion, currentQuestion, qs, questionBuilderState]);

    const handleScroll = () => {
        const questionBuilderMain = document.querySelector(".questionBuilderMain");
        if (questionBuilderMain) {
            if (scrollDown) {
                questionBuilderMain.scrollTo({
                    top: questionBuilderMain.scrollTop + questionBuilderMain.clientHeight,
                    behavior: "smooth"
                });
            } else {
                // scroll smoothly
                questionBuilderMain.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    };

    const handleDiscard = () => {
        if (qs.length !== originalData?.questions?.length && currentQuestion === qs.length) { // new question            
            setFirstAdd(true);
            setCurrentQuestion(qs.length - 1);
            setData({ ...originalData, questions: qs.slice(0, qs.length - 1) });
            notifySuccess(DELETE_QUESTION_SUCCESS);
        } else { // existing question
            const newQs = qs.map((q, i) => {
                if (i === currentQuestion - 1) {
                    return originalData.questions[i];
                }
                return q;
            });
            setData({ ...originalData, questions: newQs });
            // setQuestionTitle(originalData.questions[currentQuestion - 1]?.queTitle || "");
            setQuestionType(originalData.questions[currentQuestion - 1]?.questionType || QUESTION_TYPES.MCSS.name);
            notifySuccess(DISCARD_QUESTION_SUCCESS);
        }
        setDiscardModalShow(false);
    };

    const handleAddUpdate = async (updatedQuestion) => {
        if (qs.length !== originalData?.questions?.length && currentQuestion === qs.length) {
            await handleAddQuestionToLivePoll();
        } else {
            await handleUpdateQuestion(updatedQuestion);
            // if (res) {
            //     notifySuccess(EDIT_QUESTION_SUCCESS);
            // }
            setDisabledEditQuestion(false);
        }
        // setResetData(resetData + 1); // uncheck to refresh poll details in SharePollPage
        setAddQuestionModalShow(false);
    };

    const handleAddQuestionToLivePoll = async () => {
        const trimmedQs = trimQuestion(qs);
        let currentQue = trimmedQs[currentQuestion - 1];
        if (currentQue.questionType === QUESTION_TYPES.MH.name) {
            currentQue.optionsMap = convertMHOptionMap(currentQue.correctAnswers);
        }

        const res = await addQuestionToLivePoll(pollKey, pollCode, {...currentQue, pollType: pollType});
        if (!res) {
            setDisabledEditQuestion(false);
            return;
        }
        let newQs = JSON.parse(JSON.stringify(originalData.questions));
        newQs.push(currentQue);
        const originalDataCopy = JSON.parse(JSON.stringify(originalData));
        setOriginalData({ ...originalDataCopy, questions: newQs });
        setQs(newQs);
        setFirstAdd(true);
        // setQuestionType(QUESTION_TYPES.MCSS.name);
        // setQuestionTitle("");
        notifySuccess(ADD_QUESTION_SUCCESS);
        setDisabledEditQuestion(false);
    };

    // const handleUpdateQuestion1 = async () => {
    //     if (pollType === POLL_TYPE.ATTENDANCE) {
    //         let pollDetailsToUpdate = {};
    //         if (getLocalSyncLms() !== syncLms) {
    //             pollDetailsToUpdate["syncLms"] = syncLms;
    //             setLocalSyncLms(syncLms);
    //         }
    //         if (JSON.stringify(getLocalGeofence()) !== JSON.stringify(geofence)) {
    //             pollDetailsToUpdate["geofence"] = geofence;
    //             setLocalGeofence(geofence);
    //         }
    //         await updatePollDetails(pollKey, pollCode, pollDetailsToUpdate, true);
    //         setOriginalData({ ...originalData, geofence: geofence, syncLms: syncLms });
    //     }
    //     if (pollType !== POLL_TYPE.ATTENDANCE || !checkSameQuestions(originalData.questions[currentQuestion - 1], qs[currentQuestion - 1])) {
    //         const trimmedQs = trimQuestion(qs);
    //         const res = await updateQuestionDetails(pollKey, pollCode, currentQuestion, trimmedQs[currentQuestion - 1]);
    //         if (!res) {
    //             return;
    //         }
    //         let newQs = [...originalData.questions];
    //         newQs[currentQuestion - 1] = trimmedQs[currentQuestion - 1];
    //         setQs(trimmedQs);
    //         setOriginalData({ ...originalData, questions: newQs });
    //     }
    //     notifySuccess(pollType === POLL_TYPE.ATTENDANCE ? EDIT_QUESTION_SUCCESS : EDIT_QUESTION_SUCCESS);
    // };

    // END BUILDER FOOTER

    return (
        <>
            {questionBuilderState === "ENABLED" &&
                <div className="questionBuilderDefaultContainer">
                    <div className="lottie-container-w-shadow" onClick={handleAddQuestion}>
                        <Lottie
                            options={options}
                            height={150}
                            width={150}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                    <div className="questionBuilderDefaultCaption" tabIndex ={collapsed? -1 : 0}>{QUESTION_BUILDER_CAPTION}</div>
                    <YuJaButton
                        onClick={handleAddQuestion}
                        index={collapsed? -1 : 0}
                    >
                        {QUESTION_BUILDER_BUTTON}
                    </YuJaButton>
                </div>
            }

            {questionBuilderState === "DISABLED" &&
                <div
                    className="questionBuilderDefaultContainer"
                    style={{width: windowWidth > 1350 ? 350 : 300, height: "100%"}}
                >
                    <DisabledBuilderIcon style={{marginBottom: 20}} />
                    <div className="questionBuilderDefaultCaption" tabIndex={collapsed ? -1 : 0}>
                        {pollType === POLL_TYPE.ATTENDANCE ? DISABLED_ATTENDANCE_BUILDER_CAPTION : DISABLED_QUESTION_BUILDER_CAPTION}
                    </div>
                    {pollType !== POLL_TYPE.ATTENDANCE &&
                        <YuJaButton
                            disabled={true}
                            onClick={handleAddQuestion}
                            index={collapsed ? -1 : 0}
                        >
                            {QUESTION_BUILDER_BUTTON}
                        </YuJaButton>
                    }
                </div>
            }

            {questionBuilderState === "EDIT" &&
                <div className="questionBuilderContainer" style={{width: windowWidth > 1350 ? 350 : 300}}>
                    <DeleteConfirmationModal
                        show={discardModalShow}
                        setShow={setDiscardModalShow}
                        handleSubmit={handleDiscard}
                        isNewQuestion={qs.length !== originalData?.questions?.length && currentQuestion === qs.length}
                    />
                    <AddQuestionConfirmationModal
                        show={addQuestionModalShow}
                        setShow={setAddQuestionModalShow}
                        handleSubmit={handleAddUpdate}
                        isNewQuestion={qs.length !== originalData?.questions?.length && currentQuestion === qs.length}
                    />
                    <div className="questionBuilderMain" style={{display: "flex", flexDirection: "column"}}> {/* onLoad={() => setLoaded(true)} */}
                        {pollType !== POLL_TYPE.ATTENDANCE && <>
                            {(qs.length === originalData?.questions?.length || currentQuestion !== qs.length) && <div className="questionBuilderAddQuestionRow">
                                <div className="questionBuilderAddQuestionText">
                                    {QUESTION_BUILDER_ADD_TOOLTIP}
                                </div>
                                <div className="lottie-container-w-shadow questionBuilderAddMiniLottie" onClick={handleAddQuestion}>
                                    <Lottie
                                        options={options}
                                        height={30}
                                        width={30}
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            </div>}
                            <div className="questionBuilderSubTitle" style={{ marginTop: "0px" }}>{QUESTION_TYPE}</div>
                            <YuJaDropdown
                                data={Object.values(QUESTION_TYPES)}
                                getOptionLabel={option => option.description}
                                getOptionValue={option => option.name}
                                value={questionType}
                                textStyle={{ wordBreak: "break-word" }}
                                onChange={handleQuestionTypeUpdate}
                                style={{
                                    fontSize: 14, padding: "16px 15px", gap: 5, height: "40px", borderRadius: "3px", borderColor: "#909090", width: "100%"
                                }}
                                // className="questionBuilderDropdown"
                            />
                        </>}

                        {qs[currentQuestion - 1]?.questionType !== QUESTION_TYPES.FITB.name && <>
                            <div className="questionBuilderSubTitle" style={{marginTop: pollType === POLL_TYPE.ATTENDANCE ? 0 : 25}}>{QUESTION_TITLE}</div>
                            <YuJaRichContentEditBox
                                placeholder={ENTER_QUESTION_TITLE}
                                setValue={handleQuestionTitleUpdate}
                                value={qs[currentQuestion - 1].queTitle}
                            />
                            {/*<YuJaTextBox*/}
                            {/*    placeholder={ENTER_QUESTION_TITLE}*/}
                            {/*    value={questionTitle}*/}
                            {/*    onChange={handleQuestionTitleUpdate}*/}
                            {/*    style={{ width: "100%" }}*/}
                            {/*    containerStyle={{ marginBottom: 21 }}*/}
                            {/*    maxLength={QUESTION_TITLE_CHARACTER_LIMIT}*/}
                            {/*    showRemaining={true}*/}
                            {/*/>*/}
                            {/* <div className="questionTitleCharacterLimit">
                                {MAX + QUESTION_TITLE_CHARACTER_LIMIT}
                            </div> */}
                        </>}

                        {pollType !== POLL_TYPE.ATTENDANCE && <>
                            <div className="questionBuilderSubTitle" style={{ marginTop: qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.FITB.name ? 25 : 4 }}>{questionType === QUESTION_TYPES.CI.name ? IMAGE : MEDIA}</div>
                            <div className="d-flex justify-content-center">
                                <UploadQuestionImageNew
                                    selectedQuestion={qs[currentQuestion - 1]}
                                    questions={qs}
                                    setQuestions={setQs}
                                    pollKey={pollKey}
                                    questionBuilder={true}
                                    savePoll={handleAddUpdate}
                                />
                            </div>
                        </>}

                        {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.MCSS.name && <>
                            <div className="questionBuilderSubTitle" style={{marginTop: 10}}>{OPTIONS}</div>
                            <DragDropContext
                                // onDragStart={handleDragStart}
                                onDragEnd={(result) => {
                                    // handleDragEnd();
                                    handleAnswerOrderChange(result);
                                }}
                            >
                                <Droppable droppableId="droppable-multiple-choice-question">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            {...provided.dropHandleProps}
                                            className=""
                                            id="mcq-options-container"
                                            style={{  marginBottom: 10, display: "flex", flexDirection: "column", gap: 10 }}
                                        >
                                            {qs[currentQuestion - 1]?.optionsMap && Object.entries(qs[currentQuestion - 1]?.optionsMap).map(([choice, optionValue], index) => {
                                                return (
                                                    <div key={index}>
                                                        <MCQuestionOption
                                                            optionData={{ choice: choice, optionValue: optionValue }}
                                                            selectedQuestion={qs[currentQuestion - 1]}
                                                            questions={qs}
                                                            setQuestions={setQs}
                                                            pollKey={pollKey}
                                                            index={index}
                                                            pollType={pollType}
                                                            maxSize={Object.entries(qs[currentQuestion - 1]?.optionsMap).length}
                                                            warningShowed={warningShowed}
                                                            setWarningShowed={setWarningShowed}
                                                            questionBuilder={true}
                                                        />
                                                    </div>);
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <div style={{height: 20}}/>
                            <YuJaButton  style={{width: questionMainWidth, height: "40px", fontSize: 14}} index={collapsed? -1 : 0} onClick={handleAddOptionMCSS}>
                                <PlusIcon style={{ width: 15, height: 15, marginRight: 5 }} />{MCSS_ADD_OPTION_TEXT}
                            </YuJaButton>
                        </>}

                        {pollType === POLL_TYPE.ATTENDANCE && <>
                            <div className="questionBuilderSubTitle" style={{marginTop: 4, marginBottom: "-10px"}}>{CORRECT_ANSWER}</div>
                            <SAQuestion
                                geofence={geofence} setGeofence={setGeofence}
                                mapInitialized={mapInitialized} setMapInitialized={setMapInitialized}
                                syncLms={syncLms} setSyncLms={setSyncLms}
                                questions={qs} setQuestions={setQs}
                                selectedQuestion={qs[currentQuestion - 1]}
                                pollType={pollType}
                                questionBuilder={true}
                                editable={!hasResponses}
                                userSettings={userSettings}
                            />
                        </>}

                        {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.TF.name && <>
                            {qs[currentQuestion - 1]?.optionsMap && qs[currentQuestion - 1].optionsMap.map((option, index) => {
                                return (
                                    <div key={index}>
                                        <TFQuestionOption
                                            option={option}
                                            selectedQuestion={qs[currentQuestion - 1]}
                                            correctAnswers={qs[currentQuestion - 1]?.correctAnswers}
                                            handleCorrectAnswerChange={(input) => setQs(updateCorrectAnswers(input, currentQuestion - 1, qs))}
                                            index={index}
                                            pollType={pollType}
                                            questionBuilder={true}
                                        />
                                    </div>
                                );
                            })}
                        </>}

                        {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.FITB.name && <>
                            <div className="questionBuilderSubTitle" style={{ marginTop: 10, display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                <>{QUESTION_TITLE}</>
                                <div className="fitb-char-count">
                                    {FITB_QUESTION_LENGTH - (qs[currentQuestion - 1]?.queTitle || "").length}
                                    {CHARACTERS_REMAINING}
                                </div>
                            </div>
                            <FITBQuestionTitle
                                questions={qs}
                                setQuestions={setQs}
                                selectedQuestion={qs[currentQuestion - 1]}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                compositeDecorator={compositeDecorator}
                                setPreviewModalShow={() => {}}
                                questionBuilder={true}
                                hasBlankRestriction={hasBlankRestriction}
                                // customStyleFn={customStyleFn}
                            />

                            {/* <div className="questionBuilderSubTitle">{BLANKS}</div>
                <YuJaButton style={{width: "100%", height: "40px"}} onClick={handleAddBlank}>
                    <PlusIcon style={{ width: 15, height: 15, marginRight: 5 }} />{ADD_BLANK_TEXT}
                </YuJaButton> */}
                            {!!qs[currentQuestion - 1]?.weightage && Object.keys(qs[currentQuestion - 1]?.correctAnswers).length !== 0 && (
                                <div style={{ display: 'flex', flexWrap: "wrap", flexDirection: "column", padding: "10px 0", gap: 8 }}>
                                    {qs[currentQuestion - 1]?.correctAnswers && Object.keys(qs[currentQuestion - 1]?.correctAnswers).map((key, i) => (
                                        <>
                                            {i !== 0 &&
                                                <div style={{ borderTop: "2px dashed grey"}}/>
                                            }
                                            {qs[currentQuestion - 1].correctAnswers[key] && qs[currentQuestion - 1]?.correctAnswers[key].map((value, i) => (
                                                <FITBAnswer
                                                    key={i}
                                                    index={i}
                                                    answerKey={key}
                                                    answerValue={value}
                                                    handleAddAnswer={(key) => handleAddAnswer(key, qs[currentQuestion - 1].correctAnswers, currentQuestion - 1, qs, setQs)}
                                                    handleUpdateAnswer={(key, index, text) => handleUpdateAnswer(key, index, text, qs[currentQuestion - 1].correctAnswers, currentQuestion - 1, qs, setQs)}
                                                    handleDeleteAnswer={(key, index) => handleDeleteAnswer(key, index, qs[currentQuestion - 1].correctAnswers, currentQuestion - 1, qs, setQs, editorState, setEditorState, compositeDecorator)}
                                                    questionBuilder={true}

                                                />
                                            ))}
                                        </>
                                    ))}
                                </div>
                            )}
                        </>}

                        {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.CI.name && <>
                            <div className="questionBuilderSubTitle" style={{marginTop: "4px"}}>{OPTIONS}</div>
                            {!isSharing && !hasResponses &&
                                <div className="addCIOptionText">{CI_ADD_OPTION_TEXT}</div>
                            }
                            <DragDropContext onDragEnd={handleAnswerOrderChange}>
                                <Droppable droppableId="droppable-multiple-choice-question">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            {...provided.dropHandleProps}
                                            // className="droppable-multiple-choice-question-options"
                                            style={{  display: "flex", flexDirection: "column", gap: 10 }}
                                        >
                                            {typeof qs[currentQuestion - 1]?.optionsMap === "object" && qs[currentQuestion - 1].optionsMap.map((option, index) => {
                                                return (<div key={index}>
                                                    <CIQuestionOption
                                                        optionData={option}
                                                        selectedQuestion={qs[currentQuestion - 1]}
                                                        questions={qs}
                                                        setQuestions={setQs}
                                                        index={index}
                                                        pollType={pollType}
                                                        questionBuilder={true}

                                                    />
                                                </div>);
                                            })}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </>}

                        {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.MH.name && <>
                            <div className="questionBuilderSubTitle" style={{marginTop: "4px"}}>{OPTIONS}</div>
                            <div className="addMHOptionText">{MH_ADD_OPTION_TEXT}</div>
                        </>}

                        {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.OE.name &&
                            <> </>
                        }

                        {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.RK.name &&
                            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                                <RKQuestion
                                    questions={qs}
                                    setQuestions={setQs}
                                    selectedQuestion={qs[currentQuestion - 1]}
                                    questionBuilder={true}
                                    showAddOptionBtn={false}
                                />
                                <div style={{height: 20}}/>

                                <YuJaButton style={{width: questionMainWidth, height: "40px", fontSize: 14}} index={collapsed? -1 : 0} onClick={handleAddOptionRK}>
                                    <PlusIcon style={{ width: 15, height: 15, marginRight: 5 }} />{MCSS_ADD_OPTION_TEXT}
                                </YuJaButton>
                            </div>

                        }
                    </div>

                    {showSeeMore &&
                        <div className="questionBuilderScrollRow">
                            <div className="questionBuilderScrollButtonWrapper" style={{ width: scrollDown ? 125 : 150 }}>
                                <div className="questionBuilderScrollButton" onClick={handleScroll}>
                                    {scrollDown ? QUESTION_BUILDER_SCROLL_DOWN_BUTTON : QUESTION_BUILDER_SCROLL_UP_BUTTON}
                                    <div style={scrollDown ? {} : { transform: "rotateX(180deg)" }}>
                                        <Lottie
                                            options={scrollOptions}
                                            height={20}
                                            width={20}
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="questionBuilderFooter">
                        <YuJaButton
                            disabled={
                                disabledEditQuestion || checkAnyQuestionIsLoading()
                                    || (
                                        (qs.length === originalData?.questions?.length || currentQuestion !== qs.length)
                                        && (pollType !== POLL_TYPE.ATTENDANCE || (getLocalSyncLms() === syncLms && JSON.stringify(getLocalGeofence()) === JSON.stringify(geofence)))
                                        && checkSameQuestions(qs, originalQuestions, currentQuestion)
                                    )
                            }
                            wrapperStyle={{fontSize: 12}}
                            onClick={() => setDiscardModalShow(true)}
                            type="delete"
                            index={collapsed? -1 : 0}
                        >
                            {qs.length !== originalData?.questions?.length && currentQuestion === qs.length ? QUESTION_BUILDER_DELETE_BUTTON : QUESTION_BUILDER_DISCARD_BUTTON}
                        </YuJaButton>
                        <YuJaButton
                            index={collapsed? -1 : 0}
                            wrapperStyle={{fontSize: 12}}
                            disabled={
                                disabledEditQuestion || checkAnyQuestionIsLoading()
                                || (
                                    (qs.length === originalData?.questions?.length || currentQuestion !== qs.length)
                                    && (pollType !== POLL_TYPE.ATTENDANCE || (getLocalSyncLms() === syncLms && JSON.stringify(getLocalGeofence()) === JSON.stringify(geofence)))
                                    && checkSameQuestions(qs, originalQuestions, currentQuestion)
                                )
                            }
                            onClick={() => {
                                setDisabledEditQuestion(true);
                                let {quesError, elemIndex} = questionError(qs[currentQuestion - 1], pollType);
                                if (quesError) {
                                    notifyError(quesError);
                                    setDisabledEditQuestion(false);
                                    return;
                                }
                                if (qs.length !== originalData?.questions?.length && currentQuestion === qs.length) {
                                    setAddQuestionModalShow(true)
                                    setDisabledEditQuestion(false);
                                } else {
                                    handleAddUpdate();
                                }
                            }}
                        >
                            {qs.length !== originalData?.questions?.length && currentQuestion === qs.length ? QUESTION_BUILDER_ADD_QUESTION_BUTTON : QUESTION_BUILDER_UPDATE_QUESTION_BUTTON}
                        </YuJaButton>
                    </div>
                </div>
            }
        </>
    );
}
