import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import YuJaButton from "../../components/standardization/YuJaButton";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import {
    MENU_LIST,
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    DEFAULT_CLASS_RESULT_TIME_LIMIT,
    DEFAULT_ENTRIES_PER_PARTICIPANT,
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_LOGIN_METHODS,
    DEFAULT_POINTS,
    DEFAULT_POLL_BREAK_TIME_LIMIT,
    DEFAULT_POLL_LMS_SYNC,
    DEFAULT_POLL_MAX_ATTEMPTS,
    DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_PPT_AUTO_SHARE,
    DEFAULT_TIME_LIMIT,
    DEFAULT_TOAST_MESSAGE_DURATION,
    PLATFORM_SETTINGS,
    PLATFORM_SETTING_SAVE_ERROR_MSG,
    ROLES,
    TEXTBOX,
    USER_SETTINGS,
    DEFAULT_ATTENDANCE_SAVED_LOCATIONS
} from "../../utils/constants";
import {notifyError, notifySuccess} from "../../utils/helpers";
import {
    ATTENDANCE_TIME_LIMIT_MIN_ERROR,
    POLL_TIME_LIMIT_MIN_ERROR,
    SAVE_SETTINGS_SUCCESS
} from "../../utils/toast-message-constants";
import BrandingTab from "./BrandingTab";
import PlatformSettingsTab from "./PlatformSettingTab";
import UserSettingsTab from "./UserSettingTab";
import styles from "./platform.module.css";
import { useLocation } from "react-router-dom";
import useHostResource from "../../hooks/useHostResource";

export default function PlatformSetting({ setPageIdentifier }) {
    const [, getSession] = useLocalStorageNew("session", {});
    const [platformSettings, setPlatformSettings] = useState({});
    const [displayKey, setDisplayKey] = useState("platform");
    const [loading, setLoading] = useState(true);
    const {uploadImg} = InstitutionApiCalls();
    const {updateImageURL, updatePlatformSetting} = useHostResource(window.location.hostname);
    const session = getSession();
    const [imgUrl, setImgUrl] = useState("");
    const {role = ROLES.ANONYMOUS.value } = session;
    const location = useLocation();
    const [userSettings,  setUserSettings] = useState(() => {
        const {settings={}} = session;
        if (!settings.hasOwnProperty(USER_SETTINGS.TOAST_MESSAGE_DURATION)) {
            settings[USER_SETTINGS.TOAST_MESSAGE_DURATION] = DEFAULT_TOAST_MESSAGE_DURATION.toString();
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
            settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
            settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
            settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_ENTRIES)) {
            settings[USER_SETTINGS.QUESTION_ENTRIES] = DEFAULT_ENTRIES_PER_PARTICIPANT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION)) {
            settings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] = DEFAULT_CLASS_RESULT_TIME_LIMIT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
            settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
            settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
            settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
            settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
            settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
            settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.SURVEY_RECORD_ATTEMPTS)) {
            settings[USER_SETTINGS.SURVEY_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.SURVEY_MAX_ATTEMPTS)) {
            settings[USER_SETTINGS.SURVEY_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_BREAK_DURATION)) {
            settings[USER_SETTINGS.POLL_BREAK_DURATION] = DEFAULT_POLL_BREAK_TIME_LIMIT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
            settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
            settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.PPT_AUTO_SHARE)) {
            settings[USER_SETTINGS.PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE)) {
            settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS)) {
            settings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] = DEFAULT_ATTENDANCE_SAVED_LOCATIONS;
        }

        return settings;
    });
    const {getSetting, updateSetting} = InstitutionApiCalls();
    const {saveSettings} = UserApiCalls();

    const fetchData = () => {
        getSetting().then((result) => {
            if (result !== null) {
                setPlatformSettings(result);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        fetchData();

        if(document.getElementById("platformLastElem") != null) {
            document.getElementById("platformLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }

        if(document.getElementById("brandingLastElem") != null) {
            document.getElementById("brandingLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }

        if(document.getElementById("userLastElem") != null) {
            document.getElementById("userLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }
    }, []);

    useEffect(async () => {
        if (location && location.state) {
          if (location.state.User ) {
            setDisplayKey("user");
          } 
        }
      }, []);

    const handleSavePlatformSettings = async () => {
        if (platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true" && platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN) {
            notifyError(PLATFORM_SETTING_SAVE_ERROR_MSG)
            return;
        }
        await updateSetting(platformSettings);
        updatePlatformSetting(platformSettings);
    };

    const handleSaveUserSettings = async () => {
        saveSettings(userSettings);
    };

    const fetchImage = (url) => {
        setImgUrl(url);
        updateImageURL(url);
    };

    const handleSubmit = async () => {
        const imageData = document.querySelector('input[type="file"]').files[0];
        if (!imageData || !imageData.name) {
            return;
        }

        await uploadImg(imageData, fetchImage);
    };

    const handleSave = () => {
        if (userSettings && userSettings[USER_SETTINGS.POLL_DURATION] < 60) {
            notifyError(POLL_TIME_LIMIT_MIN_ERROR);
            return; 
        }
        if (userSettings
            && !userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END]
            && userSettings[USER_SETTINGS.ATTENDANCE_DURATION] < 300
        ) {
            notifyError(ATTENDANCE_TIME_LIMIT_MIN_ERROR);
            return; 
        }

        setLoading(true);
        Promise
            .all([handleSavePlatformSettings(), handleSaveUserSettings(), handleSubmit()])
            .then(() => {
                notifySuccess(SAVE_SETTINGS_SUCCESS);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleSelect = (key) => {
        setDisplayKey(key);
      };

    useEffect(() => {
        setPageIdentifier(MENU_LIST.SETTINGS.name);
    }, []);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.btnCenterAlign}>
                <YuJaButton index={1} id="settingsSaveBtn" onClick={handleSave}>Save All Changes</YuJaButton>
            </div>
            <div className={styles.body}>
                <div className={styles.titleBox}>
                    <div className={styles.titleBoxIcon} />
                    <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Settings</div>
                </div>
                <div className={styles.container}>
                    {role === ROLES.CREATOR.value &&
                        <UserSettingsTab userSettings={userSettings} setUserSettings={setUserSettings}/>
                    }

                    {(role === ROLES.ADMINISTRATOR.value || role === ROLES.IT_MANAGER.value) &&
                        <Tabs activeKey={displayKey} onSelect={handleSelect} defaultActiveKey={"platform"}>
                            <Tab eventKey="platform" title="Platform">
                                <PlatformSettingsTab
                                    platformSettings={platformSettings}
                                    setPlatformSettings={setPlatformSettings}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            </Tab>
                            <Tab eventKey="user" title="User">
                                <UserSettingsTab userSettings={userSettings} setUserSettings={setUserSettings}/>
                            </Tab>
                            <Tab eventKey="branding" title="Branding">
                                <BrandingTab imgUrl={imgUrl} setImgUrl={setImgUrl}/>
                            </Tab>
                        </Tabs>
                    }
                </div>
            </div>
        </div>
    )

}