import React from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { MODAL, RELEASE_QUESTION_CANCEL, RELEASE_QUESTION_CONFIRM, RELEASE_QUESTION_MODAL_BODY_TEXT, RELEASE_QUESTION_MODAL_TITLE, TEXTBOX } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import "./modal.css";

export default function ReleaseQuestionModal({ show, setShow, handleSubmit }) {
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ModalClose onClick={() => setShow(false)} className="modal-close" />
                <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0}
                    aria-label={"Set Poll Time Limit" + MODAL}
                    role={TEXTBOX}
                >
                    {RELEASE_QUESTION_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {RELEASE_QUESTION_MODAL_BODY_TEXT}
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton
                    type="secondary"
                    onClick={() => setShow(false)}
                >
                    {RELEASE_QUESTION_CANCEL}
                </YuJaButton>
                <YuJaButton
                    onClick={handleSubmit}
                >
                    {RELEASE_QUESTION_CONFIRM}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}