import React from "react";
import {ReactComponent as INCREASE}  from "./images/IncreaseIcon.svg";
import {ReactComponent as DECREASE}  from "./images/DecreaseIcon.svg";
import {ReactComponent as NO_CHANGE}  from "./images/NoChangeIcon.svg";

export const getMetricBGColorByCount = (count) => {
    if (!count) {
        return "#e8e8e8";
    }

    return count > 0 ? "#dbe7e1" : "#f8dcdb";
}

export const getMetricColorByCount = (count) => {
    if (!count) {
        return "#4c4c4c";
    }

    return count > 0 ? "#008400" : "#DA2C37";
}


export const getTrendByCount = (count) => {
    if (!count) {
        return <NO_CHANGE tabIndex={0}/>;
    }

    return count > 0 ? <INCREASE tabIndex={0}/> : <DECREASE tabIndex={0}/>;
}


export const getFullMonthYear = (yearMonth) => {
    const date = new Date(`${yearMonth}-01T00:00:00`);
    return `${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()}`
}

export const getShortMonthYear = (yearMonth) => {
    const date = new Date(`${yearMonth}-01T00:00:00`);
    return `${date.toLocaleString('default', {month: 'short'})} ${date.getFullYear()}`
}

export const isCountIncreased = (count) => {
    if (!count) {
        return null;
    }

    return count > 0;
}

export const getYearMonthStr = (date) => {
    return `${date.getFullYear()}-${date.toLocaleString('default', {month: '2-digit'})}`
}
