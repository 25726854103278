import React, { useEffect, useState } from "react";
import FormModal from "./FormModal";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { REQUIRED_FIELD_ERROR } from "../../utils/constants";

export default function EditCourseModal({ setModalShow, show, rowData, courseId, loadCourse }) {
    const [courseName, setCourseName] = useState("");
    const [currentCourseId, setCurrentCourseId] = useState("");
    // const [subject, setSubject] = useState("");
    const [courseCode, setCourseCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [instructorsName, setInstructorsName] = useState("");
    const [courseNameError, setCourseNameError] = useState("");
    // const [subjectError, setSubjectError] = useState("");
    const [startDateError, setStartDateError] = useState("");
    const [courseCodeError, setCourseCodeError] = useState("");
    const [instructorsNameError, setInstructorsNameError] = useState("");
    const { updateCourse } = CourseApiCalls();
    const [isLoading,] = useState(false);

    useEffect(() => {
        if (rowData !== null) {
            setCourseName(rowData.courseName);
            setCourseCode(rowData.courseCode);
            setCurrentCourseId(courseId);
            // setSubject(rowData.subject)
            // setStartDate(unformat(rowData.startDate));
            // setInstructorsName(rowData.instructorsName);
        }
    }, [rowData]);

    const clearForm = () => {
        setCourseName("");
        setCourseCode("");
        clearError();
        // setSubject("");
        // setStartDate("");
        // setInstructorsName("");
    };

    const clearError = () => {
        setCourseCodeError("");
        setCourseNameError("");
        // setSubjectError("");
        // setStartDateError("");
        // setInstructorsNameError("");
    };

    // const format = (input) => {
    //     const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    //     if (!input || !input.match(pattern)) {
    //         return null;
    //     }
    //     return input.replace(pattern, '$3/$2/$1');
    // };

    // const unformat = (input) => {
    //     const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    //     if (!input || !input.match(pattern)) {
    //         return null;
    //     }
    //     return input.replace(pattern, '$3-$2-$1');
    // };

    const validateForm = () => {
        clearError();
        let returnVal = true;
        if (!courseName) {
            setCourseNameError(REQUIRED_FIELD_ERROR);
            returnVal = false;
        }

        // if (!subject) {
        //     setSubjectError(REQUIRED_FIELD_ERROR);
        //     returnVal = false;
        // }

        // if (!startDate) {
        //     setStartDateError(REQUIRED_FIELD_ERROR);
        //     returnVal = false;
        // }

        if (!courseCode) {
            setCourseCodeError(REQUIRED_FIELD_ERROR);
            returnVal = false;
        }

        // if (!instructorsName) {
        //     setInstructorsNameError(REQUIRED_FIELD_ERROR);
        //     returnVal = false;
        // }

        return returnVal;
    };

    const submitEditCourse = async () => {
        if (validateForm()) {
            const body = {};
            body.courseCode = courseCode;
            body.courseName = courseName;
            // body.subject = subject;
            // body.startDate = format(startDate);
            // body.instructorsName = instructorsName;
            const courseRecord = await updateCourse(body, currentCourseId);
            setModalShow(false);
            if (courseRecord.success) {
                loadCourse();
            }
        }
    };

    const modalConfig = {
        title: "Update Course",
        submitButtonText: "Update Course",
        subText: "Please complete course details and click update course.",
        submit: submitEditCourse,
        clearForm: clearForm,

        form: [
            {
                label: "Course Code",
                value: courseCode,
                setFunction: setCourseCode,
                error: courseCodeError,
                type: "text",
            },

            {
                label: "Course Name",
                value: courseName,
                setFunction: setCourseName,
                error: courseNameError,
                type: "text",
            },

            // {
            //     label: "Instructor's Name",
            //     value: instructorsName,
            //     setFunction: setInstructorsName,
            //     error: instructorsNameError,
            //     type: "text",
            // },

            // {
            //     label: "Start Date",
            //     value: startDate,
            //     setFunction: setStartDate,
            //     error: startDateError,
            //     type: "date",
            // },

            // {
            //     label: "Subject",
            //     value: subject,
            //     setFunction: setSubject,
            //     error: subjectError,
            //     type: "text",
            // },
        ],
    };

    return (
        <>
            <FormModal
                show={show}
                isLoading={isLoading}
                setModalShow={setModalShow}
                modalConfig={modalConfig}
            />
        </>
    );
}
