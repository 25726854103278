import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import { useLoading } from "../utils/LoadingContext";
import { BANK_TITLE_TEXT, LOG_TYPES } from "../utils/constants";
import { logger, notifyError, notifySuccess, updateLogger } from "../utils/helpers";
import { USER_API_URL } from "../utils/properties";
import {
    DELETE_BANK_ERROR_MESSAGE,
    DELETE_BANK_SUCCESS_MESSAGE,
    DELETE_BANK_VIDEO_SUCCESS_MESSAGE,
    DELETE_SELECTED_USERS_ERROR,
    DELETE_SELECTED_USERS_SUCCESS,
    DELETE_USER_ERROR,
    DELETE_USER_SUCCESS,
    LOAD_USERS_ERROR,
    LOAD_USER_ROSTER_ERROR,
    QUESTION_BANK_SHARE_SUCCESS,
    RETRIEVE_BANK_ERROR_MESSAGE,
    SAVE_BANK_SUCCESS,
    SAVE_SETTINGS_ERROR,
    SAVE_SETTINGS_SUCCESS,
    SAVE_USER_ERROR,
    SAVE_USER_SUCCESS,
    SERVER_ERROR,
    SHARE_QUESTION_BANK_ERROR,
    UPDATE_BANK_ERROR_MESSAGE,
    UPDATE_BANK_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS
} from "../utils/toast-message-constants";
import {usePPTWebView} from "../hooks/usePPTWebView";

export const UserApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    const {isPPTPage, parseToken} = usePPTWebView();
    const { setLoading } = useLoading();
    const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});
    const [setSession, getSession] = useLocalStorageNew("session", {});
    let institutionId = getHostResource().institutionId;
    let userId = getSession()?.userId;
    let idpType = getSession()?.idpType;
    let intType = getSession()?.intType;

    return {
        loadUser,
        loadUsers,
        saveUser,
        deleteUser,
        deleteSelectedUsers,
        checkUserExist,
        loadUserRoster,
        saveSettings,
        saveQuestionProfile,
        getQuestionProfile,
        updateQuestionProfile,
        getQuestionProfileId,
        deleteUserVideo,
        deleteQuestionProfile,
        shareQuestionBankToUsers,
        saveUserLogs,
    }

    function loadUser(instId, userId) {
        return fetchWrapper.get({
            url: `${USER_API_URL}/user/${instId}/${userId}`
        }).then((res) => {
            if (res.success) {
                return res.result;
            } else {
                return {};
            }
        }).catch(err => {
            console.log(err);
            return {};
        });
    }

    function loadUsers(silent=false) {
        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }
        if (!silent) {
            setLoading(true);
        }
        return fetchWrapper.get({url: `${USER_API_URL}/user/${institutionId}/users`})
        .then(res => {
                if (res.success) {
                    return res.result;
                } else {
                    notifyError(res.errorMessage);
                    return [];
                }
            }).catch(err => {
                console.log(err);
                notifyError(LOAD_USERS_ERROR);
                return [];
            }).finally(() => {
                if (!silent) {
                    setLoading(false);
                }
            });
    }

    function saveUser(userObj, isNew) {
        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }

        if (isNew) {
            return fetchWrapper.post({
                url: `${USER_API_URL}/user/${institutionId}`,
                body: userObj
            }).then(res => {
                if (res.success) {
                    notifySuccess(SAVE_USER_SUCCESS);
                    return true;
                } else {
                    notifyError(res.errorMessage);
                    return false;
                }
            }).catch(err => {
                console.log(err);
                notifyError(SAVE_USER_ERROR);
                return false;
            });
        } else {
            let updateUrl = '';
            let instId = institutionId;
            if(userObj.institutionId.length > 36) {
                instId = userObj.institutionId.replaceAll('#', '_');
            }
            updateUrl = `${USER_API_URL}/user/${instId}/${userObj.userId}`; // for lms
            return fetchWrapper.put({
                url: updateUrl,
                body: userObj
            }).then(res => {
                if (res.success) {
                    notifySuccess(UPDATE_USER_SUCCESS);
                    return true;
                } else {
                    notifyError(res.errorMessage);
                    return false;
                }
            }).catch(err => {
                console.log(err);
                notifyError(UPDATE_USER_ERROR);
                return false;
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    function deleteUser(userId, instId) {
        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }

        let deleteUrl = '';
        if(instId) {
            instId = instId.replaceAll('#', '_');
            deleteUrl = `${USER_API_URL}/user/${instId}/${userId}`; // for lms
        } else {
            deleteUrl = `${USER_API_URL}/user/${institutionId}/${userId}`; 
        }
        setLoading(true);
        return fetchWrapper.delete({
            url: deleteUrl,
        }).then(res => {
            if (res.success) {
                notifySuccess(DELETE_USER_SUCCESS);
                return true;
            } else {
                notifyError(res.errorMessage);
                return false;
            }
        }).catch(err => {
            console.log(err);
            notifyError(DELETE_USER_ERROR);
            return false;
        }).finally(() => {
            setLoading(false);
        });
    }

    function deleteSelectedUsers (userIds) {
        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }

        setLoading(true);
        return fetchWrapper.delete({
            url: `${USER_API_URL}/user/${institutionId}/users`,
            body: { userIds: userIds }
        }).then(res => {
            if (res.success) {
                notifySuccess(DELETE_SELECTED_USERS_SUCCESS);
                return true;
            } else {
                notifyError(res.errorMessage);
                return false;
            }
        }).catch(err => {
            console.log(err);
            notifyError(DELETE_SELECTED_USERS_ERROR);
            return false;
        }).finally(() => {
            setLoading(false);
        });
    }

    function checkUserExist ({ userName = "", email = "" }) {
        if (!userName) {
            return;
        }

        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }
        setLoading(true);
        return fetchWrapper.get({
            url: `${USER_API_URL}/user/${institutionId}/existed?userName=${userName}`
        }).then(res => {
            if (res.success) {
                return res.result;
            } else {
                notifyError(res.errorMessage);
                return true;
            }
        }).catch(err => {
            console.log(err);
            notifyError(SERVER_ERROR);
            return true;
        }).finally(() => {
            setLoading(false);
        });

    }

    function loadUserRoster() {
        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }
        return fetchWrapper.get({url: `${USER_API_URL}/user/${institutionId}/users/roster`})
            .then(res => {
                if (res.success) {
                    return res.result;
                } else {
                    notifyError(res.errorMessage);
                    return [];
                }
            }).catch(err => {
                console.log(err);
                notifyError(LOAD_USER_ROSTER_ERROR);
                return [];
            });
    }


    function saveSettings(settingsObj) {
        const bodyObj = {}
        bodyObj.settings = settingsObj;
        if(window.location.hostname.includes('localhost')) {
            institutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";
        }

        if (intType) {
            bodyObj.intType = intType;
        }

        if (idpType) {
            bodyObj.idpType = idpType;
        }

        return fetchWrapper.put({
            url: `${USER_API_URL}/user/${institutionId}/${userId}/settings`,
            body: bodyObj
        }).then(res => {
            if (res.success) {
                const session = getSession();
                session.settings = settingsObj;
                setSession(session);
            } else {
                notifyError(res.errorMessage);
                return false;
            }
        }).catch(err => {
            console.log(err);
            notifyError(SAVE_SETTINGS_ERROR);
            return false;
        });

    }

    function saveQuestionProfile(body, showsuccess) {
        let instId, uId;
        if (isPPTPage()) {
            let {userId="", institutionId=""} = parseToken();
            instId = institutionId;
            uId = userId;
        } else {
            instId = getHostResource().institutionId;
            uId = getSession()?.userId;
        }

        const {ProfileTitle} = body;
        return fetchWrapper.put({
            url: `${USER_API_URL}/user/${instId}/${uId}/questionprofile/save`,
            body: body 
        }).then((res) => {
            if(showsuccess){
                notifySuccess(SAVE_BANK_SUCCESS.replace(BANK_TITLE_TEXT, ProfileTitle));  
            }
            return res.result;
        }).catch((error) => {
                notifyError(UPDATE_BANK_ERROR_MESSAGE);
            });
    }

    function updateQuestionProfile(body, showsuccess) {
        let institutionId = getHostResource().institutionId;
        let userId = getSession()?.userId;
        const {ProfileTitle} = body;
        return fetchWrapper.put({
            url: `${USER_API_URL}/user/${institutionId}/${userId}/questionprofile/update`,
            body: body 
        }).then((res) => {
            if(showsuccess){
                notifySuccess(UPDATE_BANK_SUCCESS.replace(BANK_TITLE_TEXT, ProfileTitle));  
            }
            return res.result;
        }).catch((error) => {
                notifyError(UPDATE_BANK_ERROR_MESSAGE);
            });
    }

    function getQuestionProfile() {
        let instId, uId;
        if (isPPTPage()) {
            let {userId="", institutionId=""} = parseToken();
            instId = institutionId;
            uId = userId;
        } else {
            instId = institutionId;
            uId = userId;
        }
        return fetchWrapper.get({
            url: `${USER_API_URL}/user/${instId}/${uId}/questionprofile/get`,
        }).then(res => {
            // console.log(res.result)
            if (res.success) {
                return res.result;
            } else {
                notifyError(res.errorMessage);
                return [];
            }
        }).catch(err => {
            console.log(err);
            notifyError(err.errorMessage);
            return [];
        });
    }

    function shareQuestionBankToUsers(users, questionBankId) {
        let bodyObj = {};
        bodyObj.users = users.map(item => item.value);
        bodyObj.questionProfileId= questionBankId;
        let userId = getSession()?.userId;
        let institutionId = getHostResource().institutionId;
        bodyObj.userId = userId; 
        bodyObj.institutionId = institutionId; 
        setLoading(true);
        return fetchWrapper.put({
            url: `${USER_API_URL}/user/${institutionId}/${userId}/questionbank/share/users`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(QUESTION_BANK_SHARE_SUCCESS);
            return result;
        }).catch((error) => {
            notifyError(SHARE_QUESTION_BANK_ERROR);
            console.log(error);
            return null;
        }).finally(() => {
            setLoading(false);
        });
    }

    function getQuestionProfileId(questionProfileId) {
        let institutionId = getHostResource().institutionId;
        let userId = getSession()?.userId;
        return fetchWrapper.get({
            url: `${USER_API_URL}/user/${institutionId}/${userId}/questionprofile/get/${questionProfileId}`,
        }).then((res) => {
            return res.result;
        }).catch((error) => {
                notifyError(RETRIEVE_BANK_ERROR_MESSAGE);
            });
    }

    function deleteUserVideo(questionProfileId, serialNo) {
        let institutionId = getHostResource().institutionId;
        let userId = getSession()?.userId;
        return fetchWrapper.delete({
            url: `${USER_API_URL}/user/${institutionId}/${userId}/video/delete/${questionProfileId}/${serialNo}`,
        }).then((res) => {
            if (res.success) {
                notifySuccess(DELETE_BANK_VIDEO_SUCCESS_MESSAGE);
            }
            return "success";
        }).catch((error) => {
                notifyError(DELETE_BANK_ERROR_MESSAGE);
            });
    }

    function deleteQuestionProfile(questionProfileId) {
        let institutionId = getHostResource().institutionId;
        let userId = getSession()?.userId;
        return fetchWrapper.delete({
            url: `${USER_API_URL}/user/${institutionId}/${userId}/questionprofile/delete/${questionProfileId}`,
        }).then((res) => {
            if (res.success) {
                notifySuccess(DELETE_BANK_SUCCESS_MESSAGE);
            }
            return res;
        }).catch((error) => {
                notifyError(DELETE_BANK_ERROR_MESSAGE);
            });
    }

    function saveUserLogs() {
        if(logger.collectUserLogs) {
            updateLogger(LOG_TYPES.INFO + "END OF LOGS!");
            return fetchWrapper.post({
                url: `${USER_API_URL}/user/saveLogs`,
                body: logger
            }).then((res) => {
                logger.messages = "";
                return res;
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}