import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { AuthenticationApiCalls } from "../../apis/AuthenticationApiCalls";
import RosterSyncConfirmModal from "../../components/modals/RosterSyncConfirmModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as AddUser } from "../../images/adduserIcon.svg";
import {
  ADD_MEMBERS_TEXT,
  ALLOW_API_ACCESS_TEXT,
  ALL_ROLES,
  ALL_ROLE_LABEL,
  DEFAULT_ROWS_PER_PAGE,
  EMAIL,
  FIRST_NAME,
  INTEGRATION_TYPES,
  LAST_NAME,
  MODAL_CANCEL_TEXT,
  ROLE,
  ROLES,
  ROSTER_SYNC_MODAL_CHECKBOX_TEXT,
  ROSTER_SYNC_MODAL_MSG,
  ROSTER_SYNC_MODAL_OK_TEXT,
  ROSTER_SYNC_MODAL_TITLE,
  SEARCH_BOX,
  TOTAL_INSTRUCTORS,
  TOTAL_IT_MANAGERS,
  TOTAL_STUDENTS,
  TOTAL_USERS,
  USERNAME,
  VIEW_COURSE_LABEL
} from "../../utils/constants";
import { getPriority, notifyError, notifySuccess } from "../../utils/helpers";
import { API_CONFIG_ERROR, GENERIC_ROSTER_SYNC_ERROR, GET_COURSE_ERROR, LOGIN_VIA_LMS, SUCCESS_SYNC } from "../../utils/toast-message-constants";
import styles from "./CourseContent.module.css";
import CourseMemberTable from "./CourseMemberTable";

export default function CourseMembersContent({courseMembers, addMembers, roles=[], courseData, getCourse, courseMembersLoading=false}) {
  const [filter, setFilter] = useState({});
  const [keyword, setKeyword] = useState("");
  const [memberData, setMemberData] = useState([]);
  const [memberDataLoading, setMemberDataLoading] = useState(true);
  const [userNameSort, setUserNameSort] = useState(1);
  const [firstNameSort, setFirstNameSort] = useState(1);
  const [lastNameSort, setLastNameSort] = useState(1);
  const [roleSort, setRoleSort] = useState(1);
  const [emailSort, setEmailSort] = useState(1);
  const [roleFilter, setRoleFilter] = useState(ALL_ROLES);
  const { createUserRoster, getD2LAuthURL } = AuthenticationApiCalls();
  const [setSession, getSession] = useLocalStorageNew("session", {});
  const [isLMSLogin, setIsLMSLogin] = useState(true);
  const [alertShow, setAlertShow] = useState(false);
  const [alertContent,] = useState(ROSTER_SYNC_MODAL_MSG);
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [rosterCheckbox, setRosterCheckbox] = useState(false);

  useEffect(() => {
    setMemberDataLoading(true);
  }, []);

  useEffect(() => {
    setMemberDataLoading(true);
    const copy = [...courseMembers];
    copy.sort(function (a, b) {
      return a.firstName.trim().toLowerCase() - b.firstName.trim().toLowerCase();
    });
    setMemberData(copy);
    setMemberDataLoading(false);
    const session = getSession();
    if(session.isYuJaAuto) {
      setIsLMSLogin(false);
    }
  }, [courseMembers]);

  useEffect(() => {
    if(isConfirmationPending) {
      const session = getSession();
      createUserRoster(courseData.index3Pk.split('#')[3], session?.intType, true)
      .then(() => {
        getCourse();
        console.log("User roster synced successfully!");
        notifySuccess(SUCCESS_SYNC);
        setIsConfirmationPending(false);
        setRosterCheckbox(false);
      })
      .catch((error) => {
        console.error("Error syncing user roster:", error);
        setIsConfirmationPending(false);
        setRosterCheckbox(false);
        if (JSON.parse(error.message).errorCode === "Y015") {
          notifyError(GET_COURSE_ERROR);
        } else if (JSON.parse(error.message).errorCode === "Y030") {
          notifyError(API_CONFIG_ERROR);
        } else if (JSON.parse(error.message).errorCode === "Y031") {
          notifyError(GENERIC_ROSTER_SYNC_ERROR);
        } else {
          notifyError(GENERIC_ROSTER_SYNC_ERROR);
        }
      });
    }
  }, [isConfirmationPending]);

  const clickSearch = () => {
    setFilter({keyword: keyword, role: roleFilter});
  };

  const enterKeyup = (e) => {
    if (e.key === 'Enter') {
      clickSearch();
    }
  };

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: 'Username', accessor: 'userName', width: isMobileScreen ? 100 : 120 },
    { Header: 'First Name', accessor: 'firstName', width: isMobileScreen ? 100 : 120 },
    { Header: 'Last Name', accessor: 'lastName', width: isMobileScreen ? 120 : 140 },
    { Header: 'Email', accessor: 'email', width: isMobileScreen ? 160 : 200 },
    { Header: 'Role', accessor: 'role', width: isMobileScreen ? 100 : 120 },
  ];

  const sortUserName = () => {
    if (!!memberData) {
      setMemberData(memberData.sort(function (a, b) {
        const nameA = a.userName?.toLowerCase();
        const nameB = b.userName?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * userNameSort;
        if (priorityA > priorityB) return 1 * userNameSort;

        if (nameA < nameB) return -1 * userNameSort;
        if (nameA > nameB) return 1 * userNameSort;
        return 0;
      }));
      setUserNameSort(-userNameSort);
    }
  };

  const sortFirstName = () => {
    if (!!memberData) {
      setMemberData(memberData.sort(function (a, b) {
        const nameA = a.firstName?.toLowerCase();
        const nameB = b.firstName?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * firstNameSort;
        if (priorityA > priorityB) return 1 * firstNameSort;

        if (nameA < nameB) return -1 * firstNameSort;
        if (nameA > nameB) return 1 * firstNameSort;
        return 0;
      }));
      setFirstNameSort(-firstNameSort);
    }
  };

  const sortLastName = () => {
    if (!!memberData) {
      setMemberData(memberData.sort(function (a, b) {
        const nameA = a.lastName?.toLowerCase();
        const nameB = b.lastName?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * lastNameSort;
        if (priorityA > priorityB) return 1 * lastNameSort;

        if (nameA < nameB) return -1 * lastNameSort;
        if (nameA > nameB) return 1 * lastNameSort;
        return 0;
      }));
      setLastNameSort(-lastNameSort);
    }
  };

  const sortRole = () => {
    if (!!memberData) {
      setMemberData(memberData.sort(function (a, b) {
        const nameA = a.role?.toLowerCase();
        const nameB = b.role?.toLowerCase();

        if (nameA < nameB) return -1 * roleSort;
        if (nameA > nameB) return 1 * roleSort;
        return 0;
      }));
      setRoleSort(-roleSort);
    }
  }

  const sortEmail = () => {
    if (!!memberData) {
      setMemberData(memberData.sort(function (a, b) {
        const nameA = a.email?.toLowerCase();
        const nameB = b.email?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * emailSort;
        if (priorityA > priorityB) return 1 * emailSort;

        if (nameA < nameB) return -1 * emailSort;
        if (nameA > nameB) return 1 * emailSort;

        return 0;
      }));
      setEmailSort(-emailSort);
    }
  };

  const syncUserRoster = () => {
    const session = getSession();

    if (courseData.index3Pk && courseData.index3Pk.trim() !== '') {
      const [, , , tpCourseId] = courseData.index3Pk.split('#');

      const intType = session?.intType;

      if (tpCourseId && intType) {
        setAlertShow(true);
      } else {
        console.error("Unable to sync user roster because of tpCourseId or intType not found");
        notifyError(LOGIN_VIA_LMS);
      }
    } else {
      console.error("Unable to sync user roster because index3pk not found");
      notifyError(GENERIC_ROSTER_SYNC_ERROR);
    }
  };

  const d2lAllowApiAccess = () => {
      getD2LAuthURL();
  };

  const alertConfig = {
    title: ROSTER_SYNC_MODAL_TITLE,
    okText: ROSTER_SYNC_MODAL_OK_TEXT,
    cancelText: MODAL_CANCEL_TEXT,
    checkBoxText: ROSTER_SYNC_MODAL_CHECKBOX_TEXT,
    submit: async () => {
      setAlertShow(false);
      setIsConfirmationPending(true);
    },
    close:  async () => {
      setAlertShow(false);
    },
  };

  return (
    <>
        <>
        <RosterSyncConfirmModal
          show={alertShow}
          setModalShow={setAlertShow}
          content={alertContent}
          config={alertConfig}
          rosterCheckbox={rosterCheckbox}
          setRosterCheckbox={setRosterCheckbox}
        />
            <div className={styles.cardsContainer}>
              <Card className={styles.card}>
                  {memberDataLoading ?
                    <Skeleton height={30}  borderRadius={10} inline={true} width={`${2 + (Math.random() * 3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} /> :
                    <div className={styles.cardNumber}>{memberData.length}</div>
                  }
                  <div className={styles.cardCaption}>{TOTAL_USERS}</div>
              </Card>
              <Card className={styles.card}>
                  {memberDataLoading ?
                    <Skeleton height={30}  borderRadius={10} inline={true} width={`${2 + (Math.random() * 3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} /> :
                    <div className={styles.cardNumber}>{memberData.filter(e => e.role === ROLES.IT_MANAGER.value).length}</div>
                  }
                  <div className={styles.cardCaption}>{TOTAL_IT_MANAGERS}</div>
              </Card>
              <Card className={styles.card}>
                  {memberDataLoading ?
                    <Skeleton height={30}  borderRadius={10} inline={true} width={`${2 + (Math.random() * 3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} /> :
                    <div className={styles.cardNumber}>{memberData.filter(e => e.role === ROLES.CREATOR.value).length}</div>
                  }
                  <div className={styles.cardCaption}>{TOTAL_INSTRUCTORS}</div>
              </Card>
              <Card className={styles.card}>
                  {memberDataLoading ?
                    <Skeleton height={30}  borderRadius={10} inline={true} width={`${2 + (Math.random() * 3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} /> :
                    <div className={styles.cardNumber}>{memberData.filter(e => e.role === ROLES.VIEWER.value).length}</div>
                  }
                  <div className={styles.cardCaption}>{TOTAL_STUDENTS}</div>
              </Card>
            </div>
          <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
              <Form.Control
                aria-label={SEARCH_BOX}
                className={styles.searchBoxKeywordInput}
                placeholder={VIEW_COURSE_LABEL}
                onChange={e => setKeyword(e.target.value)}
                onKeyUp={enterKeyup}
                value={keyword}
              />
              <YuJaDropdown
                  ariaLabel={roleFilter + ALL_ROLE_LABEL}
                  data={roles}
                  getOptionLabel={item => item}
                  getOptionValue={item => item}
                  onChange={option => setRoleFilter(option)}
                  value={roleFilter}
                  containerStyle={{marginLeft: 10, width: 230}}
                  style={{padding: "8px 15px"}}
              />
              <YuJaButton style={{margin: "0px 10px", fontSize: 14}} onClick={clickSearch}>Search</YuJaButton>
              <YuJaButton style={{fontSize: 14, minWidth: 165}} onClick={addMembers}> <div style={{display: "flex", gap: "5px", alignItems: "center"}}> <AddUser /> {ADD_MEMBERS_TEXT} </div></YuJaButton>
            </Form>
          {courseData && courseData.index3Pk && courseData.index3Pk.trim() !== '' && courseData.index3Pk.trim().includes(INTEGRATION_TYPES.D2L) && isLMSLogin && <YuJaButton style={{ margin: "10px 10px", fontSize: 14, minWidth: 135 }} onClick={d2lAllowApiAccess}>{ALLOW_API_ACCESS_TEXT}</YuJaButton>}
          {courseData && courseData.index3Pk && courseData.index3Pk.trim() !== '' && isLMSLogin && <YuJaButton style={{ margin: "10px", fontSize: 14, minWidth: 135 }} onClick={syncUserRoster}>Sync User Roster</YuJaButton>}
          </div>
          {memberDataLoading ||courseMembersLoading ?
            <div className={styles.skeletonTableContainer}>
              <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                <div className={styles.skeletonHeaderContainer}>
                  <div className={styles.skeletonHeaderCell}>{USERNAME}</div>
                  <div className={styles.skeletonHeaderCell}>{FIRST_NAME}</div>
                  <div className={styles.skeletonHeaderCell}>{LAST_NAME}</div>
                  <div className={styles.skeletonHeaderCell}>{EMAIL}</div>
                  <div className={styles.skeletonHeaderCell}>{ROLE}</div>
                </div>
                <div className={styles.skeletonBodyContainer}>
                  {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                    return (<div className={styles.skeletonBodyRow}>
                      <Skeleton height={20} inline={true}  borderRadius={10} width={`${4 + Math.random()*3}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton height={20} inline={true}  borderRadius={10} width={`${3 + (Math.random()*2)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton height={20} inline={true}  borderRadius={10} width={`${3 + Math.random()*2}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton height={20} inline={true}  borderRadius={10} width={`${6 + Math.random()*5}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton height={20} inline={true}  borderRadius={10} width={`${3 + Math.random()*2}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                    </div>)
                  })}
                </div>
              </SkeletonTheme>
            </div> :
            <div className={`${styles.memberTableContainer} ${styles.blankContentLayout}`}>
              <CourseMemberTable columns={columns} data={memberData} globalFilterObj={filter} sortUserName={sortUserName} sortFirstName={sortFirstName} sortLastName={sortLastName} sortRole={sortRole} sortEmail={sortEmail} userNameSort={userNameSort} firstNameSort={firstNameSort} lastNameSort={lastNameSort} roleSort={roleSort} emailSort={emailSort} />
            </div>
          }
        </>
    </>
  );
}