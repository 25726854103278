// import { useLocation } from "react-router-dom";
import { useFetchWrapper } from "../hooks/useFetchWrapper";
// import { useLoading } from "../utils/LoadingContext";
import { INTEGRATION_API_URL } from "../utils/properties";

export const LTIApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    // const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});
    // const location = useLocation();
    // const {setLoading} = useLoading();
    return {
        gradebookSync
    }

    // Function that syncs the score to the LMS
    function gradebookSync(pollCode, userId, gradePercentage, callback=()=>{}) {
        console.log(pollCode, userId, gradePercentage);
        return fetchWrapper.post({
            url:`${INTEGRATION_API_URL}/lti/gradebook/score`,
            body: {
                pollCode: pollCode,
                userId: userId,
                gradePercentage: gradePercentage
            }
        }).then(result => {
            // console.log(result);
            callback();
            console.log("Gradebook Sync Done!");
            return true;
        }).catch(err => {
            console.log("Error in Gradebook Sync", err);
            return false;
        })
    }
}
