import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";

export default function OpenInNewTab () {
    const [setSession, ] = useLocalStorageNew("session", {});
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get('engageSession')) {
            const engageSession = JSON.parse(queryParams.get('engageSession'));
            setSession(engageSession);
        }
        if(queryParams.get('dest')) {
            const destinationURL = queryParams.get('dest');
            console.log(destinationURL);
            const pollType = queryParams.get('pollType');
            console.log("pollType in queryParams", pollType);
            if (pollType) {
                history.push({
                    pathname: destinationURL,
                    state: {
                        pollType: pollType
                    }
                });
            } else {
                history.push(destinationURL);
            }
        }
    }, []);

    return (
        <></>
    );
}
