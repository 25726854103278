import React, {useEffect, useRef, useState} from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import EntriesInputBox from "../components/EntriesInputBox";
import PointsInputBox from "../components/PointsInputBox";
import TimeLimitInputBox from "../components/TimeLimitInputBox";
import "../components/buttons/genButton.css";
import { CreatePollDropDown } from "../components/standardization/CreatePollDropDown";
import { Spin } from "../components/standardization/YuJaLoading";
import { YuJaTextBox } from "../components/standardization/YuJaTextBox";
import { YuJaTooltip } from "../components/standardization/YuJaTooltip";
import { useImageLoaderManager } from "../hooks/useImageLoaderManager";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import { ReactComponent as InfoIcon } from "../images/Info.svg";
import {
    ALT_TEXT_LABEL,
    ALT_TEXT_TOOL_TIP, IMAGE_ALT_TEXT,
    OE_POINTS_DESCRIPTION,
    POLL_TITLE_LENGTH,
    POLL_TYPE,
    QUESTION_LENGTH,
    QUESTION_TEXT,
    QUESTION_TYPES,
    RK_POINTS_DESCRIPTION,
    TEXTBOX,
    UPLOAD_MEDIA_TEXT,
    VIDEO_ERROR_CI,
    WC_POINTS_DESCRIPTION
} from "../utils/constants";
import {
    notifyError, scrollToBottom, stripHTMLTags
} from "../utils/helpers";
import {
  updateEntries,
  updateQuestionType,
  updateTimeLimit,
  updateTitleInput,
  updateWeightage
} from "../utils/questionUpdate";
import { initialQuestionData, questionCodeToType } from "../utils/questionUtils";
import {
  QUESTION_CHARACTER_LIMIT_EXCEEDED
} from "../utils/toast-message-constants";
import BaseQuestionImage from "./BaseQuestionImage";
import BaseQuestionTitle from "./BaseQuestionTitle";
import CIQuestion from "./CI/CIQuestion";
import ClickableImage from "./CI/ClickableImage";
import FITBQuestion from "./FITB/FITBQuestion";
import MCQuestion from "./MCSS/MCQuestion";
import MHQuestion from "./MH/MHQuestion";
import RKQuestion from "./RK/RKQuestion";
import SAQuestion from "./SA/SAQuestion";
import TFQuestion from "./TF/TFQuestion";
import CreateQuestionGuidance from "./CreateQuestionGuidance";
import UploadQuestionImageNew from "./UploadQuestionImageNew";
import {useCheckMainContainerNarrow} from "../hooks/useCheckMainContainerNarrow";
import {useToaster} from "react-hot-toast";

export default function BaseQuestion({
                                       selectedQuestion={},
                                       setQuestions,
                                       questions,
                                       currQueDisplay,
                                       changeCurrQueDisplay,
                                       pollType,
                                       pollKey,
                                       changePollKey,
                                       questionBankId = null,
                                       isSaving = false,
                                       isBank=false,
                                       isEditPage = false,
                                       userSettings={},
                                       geofence,
                                       setGeofence,
                                       syncLms,
                                       setSyncLms,
                                       generateDefaultPollTitle=null,
                                       pollCode=null,
                                       savePoll=null,
                                       hasBlankRestriction,
                                       dataloading=false
                                     }) {
  const { questionType=QUESTION_TYPES.MCSS.name, queTitle="", serialNo=1, imageAlt="", weightage=0, timeLimit=0, entries=1} = selectedQuestion;
  let pointsTooltip = undefined;
  if (questionType === QUESTION_TYPES.RK.name) {
    pointsTooltip = RK_POINTS_DESCRIPTION;
  } else if (questionType === QUESTION_TYPES.WC.name) {
    pointsTooltip = WC_POINTS_DESCRIPTION;
  } else if (questionType === QUESTION_TYPES.OE.name) {
    pointsTooltip = OE_POINTS_DESCRIPTION;
  }

    const {isNarrow} = useCheckMainContainerNarrow();

    const {toasts} = useToaster();

    const indexToChange = serialNo - 1;
    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession();
    const [savingText, setSavingText] = useState("saving");
    const [setQues,] = useLocalStorageNew("questions", []);
    const divRef = useRef();
    const [questionTypeOpen, setQuestionTypeOpen] = useState(false);
    const [shownQuestionType, setShownQuestionType] = useState(questionType);
    const [directLinkEvp, setDirectLinkEvp] = useState(selectedQuestion.directLinkEvp);
    const [mapInitialized, setMapInitialized] = useState(false);

    const {checkImageIsLoading} = useImageLoaderManager(questions, setQuestions);

  const handleTimeLimitUpdate = (v) => {
      if (!v) {
          v = 0;
      } else {
          v = parseInt(v);
      }
      setQuestions(updateTimeLimit(v, indexToChange, questions));
  };

  const handleWeightageUpdate = (v) => {
      if (!v) {
          v = 0;
      } else {
          v = parseInt(v);
      }
      setQuestions(updateWeightage(v, indexToChange, questions));
  };

    const handleEntriesUpdate = (v) => {
        if (!v) {
            v = 0;
        } else {
            v = parseInt(v);
        }
        setQuestions(updateEntries(v, indexToChange, questions));
    };


    const handleTitleUpdate = (val, plainVal) => {
        if (plainVal.length <= QUESTION_LENGTH) {
            setQuestions(updateTitleInput(questionType === QUESTION_TYPES.FITB.name ? plainVal : val, indexToChange, questions));
        } else {
            let toastObj = toasts.find(toastObj => toastObj.message === QUESTION_CHARACTER_LIMIT_EXCEEDED);
            if (!toastObj) {
                notifyError(QUESTION_CHARACTER_LIMIT_EXCEEDED);
            }
        }


        // if(e.target.value.length <= QUESTION_LENGTH) {
        //     setQuestions(updateTitleInput(e.target.value, indexToChange, questions));
        // }
        // else {
        //     notifyError(QUESTION_CHARACTER_LIMIT_EXCEEDED);
        //     setQuestions(updateTitleInput(e.target.value.substring(0, QUESTION_LENGTH), indexToChange, questions));
        // }
    };

  
  const handleAltTextUpdate = (e) => {
    handleChangeAltText(e.target.value);
  };

  useEffect(()=> {
    if (selectedQuestion && selectedQuestion.directLinkEvp) {
      setDirectLinkEvp(selectedQuestion.directLinkEvp)
    }
  }, [selectedQuestion])

  
  const handleChangeAltText = (altText) => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        const updatedQuestion = {...q, imageAlt: altText};
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push({ ...q });
      }
    });
    setQuestions(newQuestions);
  };


  const handleQuestionTypeUpdate = (qType) => {
    // setSelectedQuestionType(questionNameToCode(qType));
    if(qType === QUESTION_TYPES.CI.description && selectedQuestion.directLinkEvp) {
      notifyError(VIDEO_ERROR_CI);
    }
    else {
      const updatedQuestions = updateQuestionType(qType, indexToChange, questions, questionType, userSettings);
      setQuestions(updatedQuestions);
    }
};

  useEffect(() => {
    if(isSaving) {
      const savingTimer = setTimeout(() => {
        setSavingText("Saved"); 
      }, 4000);

      return () => clearTimeout(savingTimer);
    }
    if (!isSaving) {
      setSavingText("Saving"); 
    }
  }, [isSaving])

  

  useEffect(() => {
    setShownQuestionType(questionType);
  }, [questionType, currQueDisplay]);


  return (
    <div
      className="create-question-body"
      style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: "min(2%, 20px)",
          borderRadius: "9px",
          border: "1px solid #858687",
          flex: 1,
          overflow: "auto"
      }}
    >
      {pollType !== POLL_TYPE.ATTENDANCE &&
          <div className="question-type-title" style={{ display: "flex", gap: 10, justifyContent: "space-between", marginBottom: 15 }}>
            <div style={{ display: "flex", columnGap: 10, rowGap: 5, alignItems: "center", flexWrap: "wrap" }}>
              {!dataloading &&
                  <>
                      <span tabIndex={0} role={TEXTBOX} style={{whiteSpace: "nowrap", fontSize: isNarrow ? 18 : undefined}}>
                          Question {selectedQuestion.serialNo}
                      </span>
                      <CreatePollDropDown
                          options={Object.values(QUESTION_TYPES)}
                          questionType={questionType}
                          shownQuestionType={shownQuestionType}
                          setShownQuestionType={setShownQuestionType}
                          isCreateOpen={questionTypeOpen}
                          setIsCreateOpen={setQuestionTypeOpen}
                          handleQuestionTypeUpdate={handleQuestionTypeUpdate}
                      />
                      <div style={{display: "flex", alignItems: "center", gap: 10,}}>
                          <div style={{display: "flex", alignItems: "center", gap: 5}}>
                              <span style={{fontSize: isNarrow ? 14 : 16}}>Points</span>
                              <PointsInputBox
                                  // unZero={shownQuestionType === QUESTION_TYPES.MH.name}
                                  containerStyle={{padding: 2}}
                                  style={{textAlign: "center", width: 50, fontSize: isNarrow ? 14 : 16}}
                                  value={`${selectedQuestion.hasOwnProperty("weightage") ? weightage : ""}`}
                                  disabled={!selectedQuestion.hasOwnProperty("weightage") || [QUESTION_TYPES.WC.name, QUESTION_TYPES.RK.name, QUESTION_TYPES.OE.name].includes(questionType)}
                                  onBlur={handleWeightageUpdate}
                                  tooltipText={pointsTooltip}
                                  tooltipStyle={{ maxWidth: 400 }}
                              />
                          </div>

                          <div style={{color: "#6F6F6F"}}>|</div>
                          <div style={{display: "flex", alignItems: "center", gap: 5}}>
                              <span style={{fontSize: isNarrow ? 14 : 16}}>Timer</span>
                              <TimeLimitInputBox
                                  value={`${selectedQuestion.hasOwnProperty("timeLimit") ? timeLimit : ""}`}
                                  onBlur={handleTimeLimitUpdate}
                                  disabled={!selectedQuestion.hasOwnProperty("timeLimit")}
                                  containerStyle={{padding: 2,}}
                                  style={{textAlign: "center", width: 60, fontSize: isNarrow ? 14 : 16}}
                              />
                          </div>

                          {shownQuestionType === QUESTION_TYPES.WC.name &&
                              <>
                                  <div style={{color: "#6F6F6F"}}>|</div>
                                  <div style={{display: "flex", alignItems: "center", gap: 5}}>
                                      <span style={{fontSize: isNarrow ? 14 : 16}}>Entries</span>
                                      <EntriesInputBox
                                          containerStyle={{padding: 2}}
                                          style={{textAlign: "center", width: 50, fontSize: isNarrow ? 14 : 16}}
                                          value={`${entries}`}
                                          onBlur={handleEntriesUpdate}
                                      />
                                  </div>
                              </>
                          }
                      </div>
                      {isSaving &&
                          <div style={{display: "flex", alignItems: "center"}}>
                              <img src={require("../images/saving.gif")} alt="loading..." style={{height: "32px"}}/>
                              <div style={{fontSize: "14px", color: "#000"}}> {savingText} </div>
                          </div>
                      }
                  </>
              }
                {dataloading &&
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                        <Skeleton width={280} height={38} borderRadius={10}
                                  style={{position: "relative", marginTop: 20}}/>
                    </SkeletonTheme>
              }
            </div>

          </div>
      }
      <div style={{display: "flex", width: "100%", flex: 1, gap: 15}}>
          {/*============= question area begin =========================*/}
          <div style={{flex: 1}}>
              {shownQuestionType !== QUESTION_TYPES.FITB.name && <>
                <BaseQuestionTitle
                  handleTitleUpdate={handleTitleUpdate}
                  questionTitle={queTitle}
                  dataloading={dataloading}
                  placeholder={QUESTION_TEXT}
                  editable={true}
                />
                {!dataloading &&
                    <CreateQuestionGuidance questionType={questionCodeToType(shownQuestionType).name}/>
                }
              </>}
              {pollType !== POLL_TYPE.ATTENDANCE
                  && shownQuestionType !== QUESTION_TYPES.CI.name
                  && !selectedQuestion.imageURL
                  && !selectedQuestion.directLinkEvp &&
                    <div className="d-flex justify-content-center">
                      <UploadQuestionImageNew
                          selectedQuestion={selectedQuestion}
                          questions={questions}
                          setQuestions={setQuestions}
                          // currQueDisplay={currQueDisplay}
                          // institutionId={institutionId}
                          pollKey={pollKey}
                          changePollKey={changePollKey}
                          questionBankId={questionBankId}
                          isEditPage={isEditPage}
                          generateDefaultPollTitle={generateDefaultPollTitle}
                          pollCode={pollCode}
                          savePoll={savePoll}
                          dataloading={dataloading}
                      />
                    </div>
              }
              <div className="margin-vertical-auto" style={{height: "auto"}}>
                  {shownQuestionType === QUESTION_TYPES.MCSS.name &&
                      <MCQuestion
                        pollKey={pollKey}
                        changePollKey={changePollKey}
                        setQuestions={setQuestions}
                        questions={questions}
                        selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                        pollType={pollType}
                        dataloading={dataloading}
                        questionBankId={questionBankId}
                      />
                  }
                  {shownQuestionType === QUESTION_TYPES.TF.name &&
                        <TFQuestion
                            setQuestions={setQuestions}
                            questions={questions}
                            selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                            pollType={pollType}
                        />
                  }
                  {shownQuestionType === QUESTION_TYPES.FITB.name &&
                      <FITBQuestion
                          setQuestions={setQuestions}
                          questions={questions}
                          selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                          pollType={pollType}
                          hoverDisplay={questionType !== shownQuestionType}
                          hasBlankRestriction={hasBlankRestriction}
                      />
                  }
                  {pollType === POLL_TYPE.ATTENDANCE && shownQuestionType === QUESTION_TYPES.SA.name &&
                      <SAQuestion
                          questions={questions} setQuestions={setQuestions}
                          selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                          pollType={pollType}
                          geofence={geofence} setGeofence={setGeofence}
                          syncLms={syncLms} setSyncLms={setSyncLms}
                          mapInitialized={mapInitialized} setMapInitialized={setMapInitialized}
                          userSettings={userSettings}
                    />
                  }
                  {shownQuestionType === QUESTION_TYPES.OE.name &&
                    <> </>
                }

                  {shownQuestionType === QUESTION_TYPES.CI.name &&
                      <CIQuestion
                          setQuestions={setQuestions}
                          questions={questions}
                          selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                          currQueDisplay={currQueDisplay}
                          pollType={pollType}
                          pollKey={pollKey}
                          questionBankId={questionBankId}
                      />
                  }
                  {shownQuestionType === QUESTION_TYPES.MH.name &&
                      <div className="droppable-multiple-choice-question-options" style={{marginLeft: "-30px"}} ref={divRef}>
                          <MHQuestion
                              setQuestions={setQuestions}
                              questions={questions}
                              selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                              scrollToBottom={() => scrollToBottom(divRef)}
                          />
                      </div>
                  }
                  {shownQuestionType === QUESTION_TYPES.RK.name &&
                      <RKQuestion
                          setQuestions={setQuestions}
                          questions={questions}
                          selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                      />
                  }
            </div>
          </div>
          {/*============= question area end =========================*/}

          {/*============= image area begin =========================*/}
          {shownQuestionType === QUESTION_TYPES.CI.name &&
            <div  style={{backgroundColor: "#FAF5F9", flex: 1, padding: "20px",  maxHeight: "100%", height: "fit-content", marginBottom: "20px", borderRadius: "4px"}}>
                <Spin spinning={checkImageIsLoading(selectedQuestion)}>
                    <UploadQuestionImageNew
                        selectedQuestion={selectedQuestion}
                        shownQuestionType={shownQuestionType}
                        questions={questions}
                        setQuestions={setQuestions}
                        pollKey={pollKey}
                        changePollKey={changePollKey}
                        questionBankId={questionBankId}
                        isEditPage={isEditPage}
                        generateDefaultPollTitle={generateDefaultPollTitle}
                        pollCode={pollCode}
                        savePoll={savePoll}
                        clickable={true}
                    />

                    {!!selectedQuestion.imageURL && !selectedQuestion.directLinkEvp && !checkImageIsLoading(selectedQuestion) &&
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: "4px",
                                width: "100%",
                                height: "300px",
                                alignItems: "center",
                                gap: "10px",
                                justifyContent: "center",
                                marginTop: "20px",
                                backgroundColor: "#C8C7C7"
                            }}
                        >
                            <ClickableImage
                                questions={questions}
                                setQuestions={setQuestions}
                                selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                                imageAlt={imageAlt}
                            />
                        </div>
                    }
                    <div style={{display: "flex", width: "100%", marginTop: 5}}>
                        <div
                            style={{display: "flex", flexDirection: "column", gap: "5px", width: "100%", marginTop: "5px"}}>
                            <div style={{fontSize: 15, display: "flex", gap: "2px", fontWeight: 700}}>
                                <span role={TEXTBOX} tabIndex={0}> {IMAGE_ALT_TEXT} </span>
                                <YuJaTooltip text={ALT_TEXT_TOOL_TIP} tooltipStyle={{maxWidth: 400}} placement="top">
                                     <span role={TEXTBOX} aria-label={"Tooltip text" + ALT_TEXT_TOOL_TIP}>
                                            <InfoIcon style={{width: 20, height: 20}}/>
                                     </span>
                                </YuJaTooltip>
                            </div>
                            <YuJaTextBox
                                value={imageAlt}
                                maxLength={POLL_TITLE_LENGTH}
                                onChange={handleAltTextUpdate}
                                placeholder={"Describe the image"}
                                aria-label={ALT_TEXT_LABEL}
                                tabIndex={0}
                                containerStyle={{
                                    width: "100%",
                                    padding: 6.5,
                                    border: "1.13px solid #858687"
                                }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
          }
          {!!selectedQuestion.imageURL && !selectedQuestion.directLinkEvp && shownQuestionType !== QUESTION_TYPES.CI.name &&
              <div style={{backgroundColor: "#FAF5F9", flex: 1, padding: "20px", maxHeight: "100%", height: "fit-content", marginBottom: "20px", borderRadius: "4px"}}>
                  <Spin spinning={checkImageIsLoading(selectedQuestion)}>
                      <UploadQuestionImageNew
                          selectedQuestion={selectedQuestion}
                          shownQuestionType={shownQuestionType}
                          questions={questions}
                          setQuestions={setQuestions}
                          pollKey={pollKey}
                          questionBankId={questionBankId}
                          isEditPage={isEditPage}
                          generateDefaultPollTitle={generateDefaultPollTitle}
                          pollCode={pollCode}
                          savePoll={savePoll}
                          clickable={true}
                      />
                      <div
                          style={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "4px",
                              width: "100%",
                              height: "300px",
                              alignItems: "center",
                              gap: "10px",
                              justifyContent: "center",
                              marginTop: "20px",
                              backgroundColor: "#C8C7C7"
                          }}
                      >
                          {!checkImageIsLoading(selectedQuestion) &&
                              <BaseQuestionImage
                                  selectedQuestion={questionType === shownQuestionType ? selectedQuestion : initialQuestionData(shownQuestionType)}
                                  imageAlt={imageAlt}
                              />
                          }
                      </div>

                      <div style={{display: "flex", width: "100%", marginTop: 5}}>
                          <div style={{display: "flex", flexDirection: "column", gap: "5px", width: "100%", marginTop: "5px"}}>
                              <div style={{ fontSize: 15, display: "flex", gap: "2px", fontWeight: 700}}>
                                  <span role={TEXTBOX} tabIndex={0}> {IMAGE_ALT_TEXT} </span>
                                  <YuJaTooltip text={ALT_TEXT_TOOL_TIP}  tooltipStyle={{maxWidth: 400}} placement="top">
                                     <span role={TEXTBOX} aria-label={"Tooltip text" + ALT_TEXT_TOOL_TIP}>
                                         <InfoIcon style={{width: 20, height: 20}}/>
                                     </span>
                                  </YuJaTooltip>
                              </div>
                              <YuJaTextBox
                                  value={imageAlt}
                                  maxLength={POLL_TITLE_LENGTH}
                                  onChange={handleAltTextUpdate}
                                  placeholder={"Describe the image"}
                                  aria-label={ALT_TEXT_LABEL}
                                  tabIndex={0}
                                  containerStyle={{width: "100%", padding: 6.5 , paddingLeft: 15,  border: "1.13px solid #858687"}}
                              />
                          </div>
                      </div>
                  </Spin>
              </div>
          }
          {selectedQuestion.directLinkEvp && directLinkEvp &&  shownQuestionType !== QUESTION_TYPES.CI.name &&
              <div
                  style={{
                      backgroundColor: "#FAF5F9",
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      padding: 20,
                      marginBottom: 20,
                      borderRadius: 4,
                  }}
              >
                  <UploadQuestionImageNew
                      selectedQuestion={selectedQuestion}
                      shownQuestionType={shownQuestionType}
                      questions={questions}
                      setQuestions={setQuestions}
                      pollKey={pollKey}
                      questionBankId={questionBankId}
                      isEditPage={isEditPage}
                      generateDefaultPollTitle={generateDefaultPollTitle}
                      pollCode={pollCode}
                      savePoll={savePoll}
                      clickable={true}
                  />
                  <div style={{display: "flex", flex: 1, width: "100%"}}>
                      <iframe
                          allowFullScreen
                          title={"video iFrame"}
                          style={{width: "100%",maxHeight: 400, aspectRatio: "1/1", alignSelf: "center"}}
                          src={`${selectedQuestion.directLinkEvp}`}
                      />
                  </div>
              </div>
          }
          {/*============= image area end =========================*/}

      </div>
    </div>
  );
}
