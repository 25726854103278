import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { useLoading } from "../utils/LoadingContext";
import { AUTHORIZATION_TYPE, GRADED_POLL, POLL_TITLE_TEXT, POLL_TYPE, serialNo_TEXT } from "../utils/constants";
import { formatAPIErrorLog, formatAPIResponseLog, notifyError, notifySuccess, notifyWarning } from "../utils/helpers";
import { POLL_API_URL } from "../utils/properties";
import {
    ADD_QUESTION_ERROR,
    CLOSE_ERROR,
    CLOSE_SUCCESS,
    COPY_ATTENDANCE_ERROR,
    COPY_ATTENDANCE_SUCCESS,
    COPY_MERGED_ERROR,
    COPY_MERGED_SUCCESS,
    COPY_POLL_ERROR,
    COPY_SUCCESS,
    DELETE_ATTENDANCE_ERROR,
    DELETE_ATTENDANCE_SUCCESS,
    DELETE_DRAFT_ATTENDANCE_ERROR,
    DELETE_DRAFT_ATTENDANCE_SUCCESS,
    DELETE_DRAFT_POLL_ERROR,
    DELETE_DRAFT_POLL_SUCCESS,
    DELETE_POLLS_ERROR,
    DELETE_POLLS_SUCCESS,
    DELETE_PUBLISHED_ATTENDANCE_ERROR,
    DELETE_PUBLISHED_ATTENDANCE_SUCCESS,
    DELETE_PUBLISHED_POLL_ERROR,
    DELETE_PUBLISHED_POLL_SUCCESS, DUPLICATE_IMAGE_ERROR,
    GET_ACTIVE_POLLS_ERROR,
    GET_POLLS_ERROR,
    GET_PUBLISHED_POLL_ERROR,
    INCOMPLETE_ATTENDANCE_ERROR,
    INCOMPLETE_POLL_ERROR,
    LOCK_QUESTION_ERROR,
    LOCK_QUESTION_SUCCESS,
    MISSING_UNIQUE_CODE_ERROR,
    OPEN_ERROR,
    OPEN_SUCCESS,
    PAUSE_QUESTION_ERROR,
    PAUSE_QUESTION_SUCCESS,
    QUESTIONS_SHARE_ERROR,
    QUESTIONS_SHARE_SUCCESS,
    REACTIVATE_POLL_ERROR,
    RELEASE_POLL_ERROR,
    RESTORE_ATTENDANCE_ERROR,
    RESTORE_ATTENDANCE_SUCCESS,
    RESTORE_POLL_ERROR,
    RESTORE_POLL_SUCCESS,
    RESTORE_POLLS_ERROR,
    RESTORE_POLLS_SUCCESS,
    RESUME_QUESTION_ERROR,
    RESUME_QUESTION_SUCCESS,
    SAVE_POLL_ERROR,
    SAVE_POLL_SUCCESS,
    SHARE_ATTENDANCE_ERROR,
    SHARE_ATTENDANCE_SUCCESS,
    SHARE_POLL_ERROR,
    SHARE_QUESTION_ERROR,
    SHARE_QUESTION_SUCCESS,
    SHARE_SUCCESS,
    STOP_ERROR,
    STOP_POLL_ERROR,
    STOP_POLL_SUCCESS, STOP_SCHEDULED_POLL_ERROR, STOP_SCHEDULED_POLL_SUCCESS,
    STOP_SUCCESS,
    UPDATE_POLL_DETAILS_ERROR,
    UPDATE_POLL_DETAILS_SUCCESS, UPDATE_POLL_ERROR,
    UPDATE_POLL_SUCCESS,
    UPDATE_PUBLISHED_POLL_ERROR,
    UPDATE_QUESTION_DETAILS_ERROR,
    UPLOAD_IMAGE_ERROR,
    UPLOAD_VIDEO_ERROR
} from "../utils/toast-message-constants";
import {setMHOptionsMap} from "../utils/questionUtils";

export const PollApiCalls = () => {

    const fetchWrapper = useFetchWrapper();
    const { setLoading } = useLoading();
    return {
        uploadQueImage,
        uploadOptImage,
        uploadQueBankImage,
        deleteImage,
        duplicateImagesOfQuestion,
        duplicateImagesOfQuestionBank,
        publishPoll,
        saveAndPublish,
        updateAndPublish,
        reactivatePoll,
        sharePollToUsers,
        copyPollToUser, 
        softDeleteDraftPoll,
        softDeletePublishedPoll,
        shareQuestionPresentation,
        shareQuestion,
        shareAllQuestions,
        stopQuestion,
        stopQuestionPresentation,
        stopAllQuestions,
        stopScheduledPoll,
        lockQuestion,
        pauseQuestion,
        resumeQuestion,
        getPollsFromUser,
        getDeletedPollsFromUser,
        restorePolls,
        hardDeletePolls,
        getPollsFromCourse,
        getAttendancesFromCourse,
        getPollDataFromKey,
        getPublishedPollDataFromKey,
        getPollRecordFromUniqueCode,
        getActivePollsFromUser,
        getActivePollsFromCourse,
        importBankImage,
        editPublishedPollDataFromKey,
        getUploadLinkForVideo,
        uploadVideo,
        deleteVideo,
        fakeCall,
        getPollsPPT,
        updatePollDetails,
        updateQuestionDetails,
        addQuestionToLivePoll,
        getPollDataFromKeyPPT,
        // notifySchedule
    }




    // Function to save the uploaded image temporarily
    // till the poll is saved returns the url of the image
    function uploadQueImage(pollKey, imgData, setUpFunc) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/upload/queImg`,
            body: { pollKey, fileName: imgData.name }
        }).then(result => {
            return result;
        }).then(async ({putURL: url, getURL}) =>{
            if (!url) {
                return "";
            }
            const res = await fetch(url, {
                headers: {"Content-Type": imgData.type},
                method: 'PUT',
                body: imgData
            });
            if (setUpFunc) {
                await setUpFunc(imgData.name, getURL);
            }
            return res;
        }).catch((error) => {
            notifyError(UPLOAD_IMAGE_ERROR);
            console.log(error);
            return null;
        })
    }

    // Function to save the uploaded image temporarily
    // till the poll is saved returns the url of the image
    function uploadOptImage(pollKey, imgData, setUpFunc) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/upload/optImg`,
            body: { fileName: imgData.name, pollKey}
        }).then(result => {
            return result;
        }).then(async ({putURL: url, getURL}) =>{
            if (!url) {
                return "";
            }
            const res = await fetch(url, {
                headers: {"Content-Type": imgData.type},
                method: 'PUT',
                body: imgData
            });
            if (setUpFunc) {
                setUpFunc(imgData.name, getURL);
            }
            return res;
        }).catch((error) => {
            notifyError(UPLOAD_IMAGE_ERROR);
            console.log(error);
            return null;
        });
    }


    function uploadQueBankImage(questionBankId, imgData, setUpFunc) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/upload/queBankImg`,
            body: { fileName: imgData.name, questionBankId}
        }).then(result => {
            return result;
        }).then(async ({putURL: url, getURL}) =>{
            if (!url) {
                return "";
            }
            const res = await fetch(url, {
                headers: {"Content-Type": imgData.type},
                method: 'PUT',
                body: imgData
            });
            if (setUpFunc) {
                setUpFunc(imgData.name, getURL);
            }
            return res;
        }).catch((error) => {
            notifyError(UPLOAD_IMAGE_ERROR);
            console.log(error);
            return null;
        });
    }

    function duplicateImagesOfQuestion(pollKey, selectedQuestion, resourceMapping) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/duplicate/img`,
            body: { pollKey, selectedQuestion, resourceMapping }
        }).then(result => {
            return result;
        }).catch((error) => {
            notifyError(DUPLICATE_IMAGE_ERROR);
            console.log(error);
            return null;
        })
    }

    function duplicateImagesOfQuestionBank(questionBankId, resourceMapping) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/duplicate/img`,
            body: { questionBankId, resourceMapping }
        }).then(result => {
            return result;
        }).catch((error) => {
            notifyError(DUPLICATE_IMAGE_ERROR);
            console.log(error);
            return null;
        })
    }

    // Function to delete the uploaded image
    // returns void
    function deleteImage(loc) {
        setLoading(true);
        return fetchWrapper.delete({
            url: `${POLL_API_URL}/poll/delete`,
            body: { loc }
        }).then((result) => {
            console.log(result);
        }).catch((error) => {
            // notifyError(DELETE_IMAGE_ERROR);
            console.log(error);
            return null;
        }
        ).finally(() => {
            setLoading(false);
        });
    }

    // Function to get upload link from evp for video
    function getUploadLinkForVideo() {
        return fetchWrapper.get({
            url: `${POLL_API_URL}/poll/upload/video`,
        }).then((result) => {
            return result.url;
        }).catch((error) => {
            console.log(error);
            return null;
        }).finally(() => {
        });
    }

    // Function to upload video to evp and return direct link
    function uploadVideo(fileKey, title, pollKey, serialNo) {
        return fetchWrapper.post({
            url: `${POLL_API_URL}/poll/upload/video`,
            body: {
                fileKey: fileKey,
                title: title,
                pollKey: pollKey,
                serialNo: serialNo
            }
        }).then((result) => {
            return result.url;
        }).catch((error) => {
            notifyError(UPLOAD_VIDEO_ERROR);
            console.log(error);
            return null;
        }).finally(() => {
        });
    }

    // Function to delete the uploaded image
    // Returns success if successful
    function deleteVideo(pollKey, serialNo, pollCode , showLoading = false) {
        setLoading(true);
        return fetchWrapper.delete({
            url: `${POLL_API_URL}/poll/delete/video`,
            body: { pollKey: pollKey, serialNo: serialNo, pollCode: pollCode}
        }).then((result) => {
            return result.response;
        }).catch((error) => {
            console.log(error);
            return null;
        }).finally(() => {
            if(showLoading) {
                setLoading(false);
            }
        });
    }

    // Function to soft delete a draft poll
    // returns void
    function softDeleteDraftPoll(pollKey, pollType, showToast=true) {
        const bodyObj = { pollKey: pollKey, };
        setLoading(true);
        return fetchWrapper.delete({
            url: `${POLL_API_URL}/poll/draft`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            if (showToast) {
                notifySuccess(pollType === POLL_TYPE.ATTENDANCE ? DELETE_DRAFT_ATTENDANCE_SUCCESS : DELETE_DRAFT_POLL_SUCCESS);
            }
            return result;
        }).catch((error) => {
            if (showToast) {
                notifyError(pollType === POLL_TYPE.ATTENDANCE ? DELETE_DRAFT_ATTENDANCE_ERROR : DELETE_DRAFT_POLL_ERROR);
            }
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to soft delete a published poll
    // returns void
    function softDeletePublishedPoll(pollKey, uniqueCode, pollType) {
        const bodyObj = { pollKey: pollKey, uniqueCode: uniqueCode };
        setLoading(true);
        return fetchWrapper.delete({
            url: `${POLL_API_URL}/poll/published`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(pollType === POLL_TYPE.ATTENDANCE ? DELETE_PUBLISHED_ATTENDANCE_SUCCESS : DELETE_PUBLISHED_POLL_SUCCESS);
            return result;
        }).catch((error) => {
            notifyError(pollType === POLL_TYPE.ATTENDANCE ? DELETE_PUBLISHED_ATTENDANCE_ERROR : DELETE_PUBLISHED_POLL_ERROR);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    function hardDeletePolls(pollKeys, pollCodes, showToast=true, singleDeleteType=null) {
        const bodyObj = { pollKey: pollKeys, pollCode: pollCodes};
        setLoading(true);
        return fetchWrapper.delete({
            url: `${POLL_API_URL}/poll/hardDelete`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            if (showToast && singleDeleteType) {
                notifySuccess(singleDeleteType === POLL_TYPE.ATTENDANCE ? DELETE_ATTENDANCE_SUCCESS : DELETE_POLLS_SUCCESS);
            } else if (showToast) {
                notifySuccess(DELETE_POLLS_SUCCESS);
            }
            return result;
        }).catch((error) => {
            if (showToast && singleDeleteType) {
                notifyError(singleDeleteType === POLL_TYPE.ATTENDANCE ? DELETE_ATTENDANCE_ERROR : DELETE_POLLS_ERROR);
            } else if (showToast) {
                notifyError(DELETE_POLLS_ERROR);
            }
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    function restorePolls(pollKeys, pollCodes, showToast=true, singleRestoreType=null) {
        const bodyObj = { pollKey: pollKeys, pollCode: pollCodes};
        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/restore`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            if (showToast && singleRestoreType) {
                notifySuccess(singleRestoreType === POLL_TYPE.ATTENDANCE ? RESTORE_ATTENDANCE_SUCCESS : RESTORE_POLL_SUCCESS);
            } else if (showToast) {
                notifySuccess(RESTORE_POLLS_SUCCESS);
            }
            return result;
        }).catch((error) => {
            if (showToast && singleRestoreType) {
                notifyError(singleRestoreType === POLL_TYPE.ATTENDANCE ? RESTORE_ATTENDANCE_ERROR  : RESTORE_POLL_ERROR);
            } else if (showToast) {
                notifyError(RESTORE_POLLS_ERROR);
            }
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to publish a poll, this creates a uniqueCode and deletes the draft poll
    // returns the pollKey of the poll, the uniqueCode and the expirationTime
    function publishPoll(pollToAddBody, isSilent=false, spinner=true) {
        const bodyObj = pollToAddBody;
        if (spinner) {
            setLoading(true);
        }
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/saveAndPublish`,
            body: bodyObj
        }).then((result) => {
            if (result.uniqueCode) {
                return result;
            } else if(!isSilent) {
                notifyWarning(MISSING_UNIQUE_CODE_ERROR);
            }
            return null;
        }).catch((error) => {
            if (!isSilent) {
                const {pollTitle, pollType=GRADED_POLL} = pollToAddBody;
                if (error.message && JSON.parse(error.message).errorMessage === "Incomplete Poll") {
                    notifyError(pollType === POLL_TYPE.ATTENDANCE ? INCOMPLETE_ATTENDANCE_ERROR : INCOMPLETE_POLL_ERROR);
                } else {
                    notifyError(RELEASE_POLL_ERROR.replace(POLL_TITLE_TEXT, pollTitle));
                }
            }
            console.log(error);
            return null;
        }).finally(() => {
            if (spinner) {
                setLoading(false);
            }
        });
    }


    function saveAndPublish(pollToAddBody, normalSave=true) {
        const {pollTitle, questions=[]} = pollToAddBody;
        setMHOptionsMap(questions);
        pollToAddBody.isAutomatic = !normalSave;
        pollToAddBody.saveRequired = true;
        if (normalSave) {
            setLoading(true);
        }
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/saveAndPublish`,
            body: pollToAddBody
        }).then((result) => {
            if (normalSave) {
                notifySuccess(SAVE_POLL_SUCCESS.replace(POLL_TITLE_TEXT, pollTitle));
            }
            return result;
        }).catch((error) => {
            if (normalSave) {
                notifyError(SAVE_POLL_ERROR.replace(POLL_TITLE_TEXT, pollTitle));
            }
            console.log(error);
            return null;
        }).finally(() => {
            if (normalSave) {
                setLoading(false);
            }
        });
    }


    function updateAndPublish(pollToAddBody, normalSave=true) {
        const {pollTitle, questions=[]} = pollToAddBody;
        setMHOptionsMap(questions);
        pollToAddBody.isAutomatic = !normalSave;
        pollToAddBody.saveRequired = true;
        if (normalSave) {
            setLoading(true);
        }
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/saveAndPublish`,
            body: pollToAddBody
        }).then((result) => {
            if (normalSave) {
                notifySuccess(UPDATE_POLL_SUCCESS.replace(POLL_TITLE_TEXT, pollTitle));
            }
            return result;
        }).catch((error) => {
            if (normalSave) {
                notifyError(UPDATE_POLL_ERROR.replace(POLL_TITLE_TEXT, pollTitle));
            }
            console.log(error);
            return null;
        }).finally(() => {
            if (normalSave) {
                setLoading(false);
            }
        });
    }

    // Function to reactivate a poll with new share mode and new course, this creates a new poll
    // returns the pollKey of the poll, the uniqueCode and the expirationTime
    function reactivatePoll(pollToAddBody) {
        console.log(pollToAddBody);
        const bodyObj = pollToAddBody;
        const {pollTitle} = pollToAddBody;
        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/reactivate`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            notifyError(REACTIVATE_POLL_ERROR.replace(POLL_TITLE_TEXT, pollTitle));
            console.log(error);
            return null;
        }).finally(() => {
            setLoading(false);
        });
    }

    
    // Function to share a poll to different users within the institution
    // returns the pollKeys of the new polls
    function sharePollToUsers(pollToAddBody) {
        console.log(pollToAddBody);
        const bodyObj = pollToAddBody;
        const {pollType = GRADED_POLL} = bodyObj;
        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/share/users`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(pollType === POLL_TYPE.ATTENDANCE ? SHARE_ATTENDANCE_SUCCESS : SHARE_SUCCESS);
            return result;
        }).catch((error) => {
            notifyError(pollType === POLL_TYPE.ATTENDANCE ? SHARE_ATTENDANCE_ERROR : SHARE_POLL_ERROR);
            console.log(error);
            return null;
        }).finally(() => {
            setLoading(false);
        });
    }

    function copyPollToUser(pollToAddBody) {
        console.log(pollToAddBody);
        const bodyObj = pollToAddBody;
        const {pollType = GRADED_POLL} = bodyObj;
        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/copy`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            if (pollType === POLL_TYPE.MERGED_POLL) {
                notifySuccess(COPY_MERGED_SUCCESS);
            } else if (pollType === POLL_TYPE.ATTENDANCE) {
                notifySuccess(COPY_ATTENDANCE_SUCCESS);
            } else {
                notifySuccess(COPY_SUCCESS);
            }
            return result;
        }).catch((error) => {
            if (pollType === POLL_TYPE.MERGED_POLL) {
                notifySuccess(COPY_MERGED_ERROR);
            } else if (pollType === POLL_TYPE.ATTENDANCE) {
                notifySuccess(COPY_ATTENDANCE_ERROR);
            } else {
                notifySuccess(COPY_POLL_ERROR);
            }
            console.log(error);
            return null;
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to share one specific question from a poll
    // returns liveQuestionTime
    function shareQuestion(pollKey, uniqueCode, serialNo, pollType=POLL_TYPE.MERGED_POLL) {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
            serialNo: serialNo,
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/share`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(pollType === POLL_TYPE.ATTENDANCE ? OPEN_SUCCESS : SHARE_QUESTION_SUCCESS.replace(serialNo_TEXT, serialNo));
            return result;
        }).catch((error) => {
            notifyError(pollType === POLL_TYPE.ATTENDANCE ? OPEN_ERROR : SHARE_QUESTION_ERROR.replace(serialNo_TEXT, serialNo));
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to share one specific question from a poll
    // returns liveQuestionTime
    function shareQuestionPresentation(pollKey, uniqueCode, serialNo, pollType, token='') {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
            serialNo: serialNo,
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/share`,
            body: bodyObj,
            token: token
        }).then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            notifyError(pollType === POLL_TYPE.ATTENDANCE ? OPEN_ERROR : SHARE_QUESTION_ERROR.replace(serialNo_TEXT, serialNo));
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    function shareAllQuestions(pollKey, uniqueCode) {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/share-all`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(QUESTIONS_SHARE_SUCCESS);
            return result;
        }).catch((error) => {
            notifyError(QUESTIONS_SHARE_ERROR);
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to stop the selected question
    // returns "success"
    function stopQuestion(pollKey, uniqueCode, serialNo, pollType=POLL_TYPE.MERGED_POLL) {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
            serialNo: serialNo
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/stop`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(pollType === POLL_TYPE.ATTENDANCE ? CLOSE_SUCCESS : STOP_POLL_SUCCESS.replace(serialNo_TEXT, serialNo));
            return result;
        }).catch((error) => {
            notifyError(pollType === POLL_TYPE.ATTENDANCE ? CLOSE_ERROR : STOP_POLL_ERROR.replace(serialNo_TEXT, serialNo));
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }
    
    // Function to stop the selected question
    // returns "success"
    function stopQuestionPresentation(pollKey, uniqueCode, serialNo, pollType, token='') {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
            serialNo: serialNo
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/stop`,
            body: bodyObj,
            token: token
        }).then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            notifyError(pollType === POLL_TYPE.ATTENDANCE ? CLOSE_ERROR : STOP_POLL_ERROR.replace(serialNo_TEXT, serialNo));
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }
    

    function stopAllQuestions(pollKey, uniqueCode) {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/stop-all`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(STOP_SUCCESS);
            return result;
        }).catch((error) => {
            notifyError(STOP_ERROR);
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }


    function stopScheduledPoll(pollKey, uniqueCode) {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/stop-scheduled`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(STOP_SCHEDULED_POLL_SUCCESS);
            return result;
        }).catch((error) => {
            notifyError(STOP_SCHEDULED_POLL_ERROR);
            console.log(error.code);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to stop the selected question
    // returns "success"
    function lockQuestion(pollKey, uniqueCode, serialNo) {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
            serialNo: serialNo
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/lock`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(LOCK_QUESTION_SUCCESS.replace(serialNo_TEXT, serialNo));
            return result;
        }).catch((error) => {
            notifyError(LOCK_QUESTION_ERROR.replace(serialNo_TEXT, serialNo));
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to pause the selected question
    // returns "success"
    function pauseQuestion(pollKey, uniqueCode, serialNo, timeLeft) {
        const bodyObj = {
            pollKey: pollKey,
            uniqueCode: uniqueCode,
            serialNo: serialNo,
            timeLeft: timeLeft
        }

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/pause`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(PAUSE_QUESTION_SUCCESS.replace(serialNo_TEXT, serialNo));
            return result;
        }).catch((error) => {
            notifyError(PAUSE_QUESTION_ERROR.replace(serialNo_TEXT, serialNo));
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to stop the selected question
    // returns "success"
    function resumeQuestion(pollKey, uniqueCode, currentQue, timeLeft) {
        const {serialNo, timeLimit} = currentQue;
        const bodyObj = {pollKey, uniqueCode, serialNo, timeLimit, timeLeft}

        setLoading(true);
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/question/resume`,
            body: bodyObj
        }).then((result) => {
            console.log(result);
            notifySuccess(RESUME_QUESTION_SUCCESS.replace(serialNo_TEXT, serialNo));
            return result;
        }).catch((error) => {
            notifyError(RESUME_QUESTION_ERROR.replace(serialNo_TEXT, serialNo));
            console.log(error.code);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to get all the polls that are owned by the user that is logged in
    // returns a list of polls 
    function getPollsFromUser(silent=false) {
        // console.log("Get Polls From User");
        if (!silent) setLoading(true);
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/user",
        }).then((result) => {
            // console.log(result);
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return [];
        }).finally(() => {
            if (!silent) setLoading(false);
        });
    }

    function getDeletedPollsFromUser() {
        // console.log("Get Polls From User");
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/user/delete",
        }).then((result) => {
            // console.log(result);
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return [];
        });
    }

    // Function to get all the polls inside a course
    // returns a list of polls 
    function getPollsFromCourse(courseId) {
        console.log("Get Polls Inside Course");
        setLoading(true);
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/course/" + courseId,
        }).then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return [];
        }).finally(() => {
            setLoading(false);
        });
    }

    // Function to get all attendances inside a course
    // returns a list of polls 
    function getAttendancesFromCourse(courseId) {
        console.log("Get Attendances Inside Course");
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/attendance/course/" + courseId,
        }).then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return [];
        });
    }

    // Function to get all the active polls inside a course
    // returns a list of polls 
    function getActivePollsFromCourse(courseId, silent=false) {
        console.log("Get Active Polls Inside Course");
        if (!silent) setLoading(true);
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/course/activated-polls/" + courseId,
        }).then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return [];
        }).finally(() => {
            if (!silent) setLoading(false);
        });
    }

    // Function to get all the pollData from a pollKey,
    // Returns the data of a single poll including questions, pollDesc, pollTitle, owner, etc
    function getPollDataFromKey(pollKey) {
        console.log("Get Poll Data From Key");
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/questions/" + pollKey,
        }).then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return {};
        });
    }

    // Function to get all the publishedPollData from a pollKey,
    // Returns the data of a single poll including questions, pollDesc, pollTitle, owner, etc
    function getPublishedPollDataFromKey(pollKey, pollCode) {
        console.log("Get Published Poll Data From Key");
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/published/questions/" + pollKey + "/" + pollCode,
        }).then((result) => {

            result.questions.sort((a,b) => {
                return a.serialNo - b.serialNo;
            })
            console.log(result);
            formatAPIResponseLog("getPublishedPollDataFromKey", result);
            return result;

        }).catch((error) => {
            notifyError(GET_PUBLISHED_POLL_ERROR);
            formatAPIErrorLog("getPublishedPollDataFromKey", error);
            console.log(error);
            return null;
        });
    }

    // Function to =edit the information of a published scheduled poll from a pollKey,
    // Returns the data of a single poll including questions, pollDesc, pollTitle, owner, etc
    function editPublishedPollDataFromKey(body, pollKey, pollCode) {
        console.log("Get Published Poll Data From Key");
        setLoading(true);
        return fetchWrapper.put({
            url: POLL_API_URL + "/poll/published/questions/" + pollKey + "/" + pollCode,
            body: body 
        }).then((result) => {
            console.log(result);
            return result;

        }).catch((error) => {
            notifyError(UPDATE_PUBLISHED_POLL_ERROR);
            console.log(error);
            return null;
        }).finally(() => {
            setLoading(false);
        });
    }

    function getPollRecordFromUniqueCode(pollCode, token='') {
        console.log("Get PollRecord from Poll unique Code");
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/published/record/" + pollCode,
            token: token
        }).then((result) => {
            console.log(result);
            formatAPIResponseLog("GetPollRecordFromUniqueCode", result);
            return result;
        }).catch((error) => {
            console.log(error);
            formatAPIErrorLog("GetPollRecordFromUniqueCode", error);
            return null;
        });
    }

    function getActivePollsFromUser() {
        return fetchWrapper.get({
            url: `${POLL_API_URL}/poll/user/activated-polls`,
        }).then((result) => {
            return result;
        }).catch((error) => {
            notifyError(GET_ACTIVE_POLLS_ERROR);
            console.log(error);
            return [];
        });
    }

    /**
     *
     * @param pollKey
     * @param resourceMapping ex: optImg/queImg-queBank-${queBankId}_${originalImageName} -> destImageName
     * @returns {Promise<*>}
     */
    function importBankImage(pollKey, resourceMapping) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/questionBank/import`,
            body: {pollKey, resourceMapping}
        }).then((result) => {
            return result;
        }).catch((error) => {
            notifyError(DUPLICATE_IMAGE_ERROR);
            console.log(error);
            return null;
        });
    }

    // Function to warm up lambda function
    // returns
    function fakeCall() {
        return fetchWrapper.post({
            url: `${POLL_API_URL}/poll/fake-call`,
        }).then((result) => {
            return result;
        }).catch(() => {
            return null;
        });
    }


    // Function to get all the polls that are owned by the user that is logged in
    // returns a list of polls
    function getPollsPPT() {
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/user",
        }).then((result) => {
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return [];
        })
    }

    function updatePollDetails(pollKey, pollCode, body, silent=false) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/update/${pollKey}/${pollCode}`,
            body: body
        }).then((result) => {
            if (!silent) {
                notifySuccess(UPDATE_POLL_DETAILS_SUCCESS);
            }
            return result;
        }).catch((error) => {
            if (!silent) {
                notifyError(UPDATE_POLL_DETAILS_ERROR);
            }
            console.log(error);
            return [];
        });
    }

    function updateQuestionDetails(pollKey, pollCode, serialNo, body) {
        return fetchWrapper.put({
            url: `${POLL_API_URL}/poll/update/${pollKey}/${pollCode}/${serialNo}`,
            body: body
        }).then((result) => {
            return result;
        }).catch((error) => {
            notifyError(UPDATE_QUESTION_DETAILS_ERROR);
            console.log(error);
            return [];
        });
    }

    function addQuestionToLivePoll(pollKey, pollCode, body) {
        return fetchWrapper.post({
            url: `${POLL_API_URL}/poll/add/${pollKey}/${pollCode}`,
            body: body
        }).then((result) => {
            return result;
        }).catch((error) => {
            notifyError(ADD_QUESTION_ERROR);
            console.log(error);
            return null;
        });
    }

    function getPollDataFromKeyPPT(pollKey, token) {
        const headersObj = {Authorization: `${AUTHORIZATION_TYPE} ${token}`};
        return fetchWrapper.get({
            url: POLL_API_URL + "/poll/questions/" + pollKey,
            headers: headersObj,
            authorizeRequired: false
        }).then((result) => {
            return result;
        }).catch((error) => {
            notifyError(GET_POLLS_ERROR);
            console.log(error);
            return {};
        });
    }

    // function notifySchedule(pollKey, pollCode, body) {
    //     return fetchWrapper.post({
    //         url: `${POLL_API_URL}/poll/notify/${pollKey}/${pollCode}`,
    //         body: body
    //     }).then((result) => {
    //         console.log(result);
    //         return result;
    //     }).catch((error) => {
    //         console.log(error);
    //         return null;
    //     });
    // }
}