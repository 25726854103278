import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import FormModal from "../../components/modals/FormModal";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import NormalBox from "../../public/boxes/NormalBox";
import NormalButton from "../../public/buttons/NormalButton";
import NormalText from "../../public/texts/NormalText";
import {
  ERROR_CODES,
  INVALID_POLL_CODE_ERROR,
  POLL_CODE_LENGTH,
  REQUIRED_FIELD_ERROR, VIEWER_USER_TYPE
} from "../../utils/constants";
import styles from "./styles.module.css";
import { formatErrorLog, newPageLog } from "../../utils/helpers";

export default function Expired(errObj) {
  const history = useHistory();
  const isNarrow = useMobileAccess(1200);
  const err = errObj.err;
  const { setUserNameToLocalStorage } = ResponseApiCalls();
  const [, getSession] = useLocalStorageNew("session", {});
  const session = getSession();
  const [modalShow, setModalShow] = useState(true);
  const [code, setCode] = useState("");
  const [pollCodeError, setPollCodeError] = useState("");
  const {getPollRecordFromUniqueCode} = PollApiCalls();
  const {checkMemberAccess} = CourseApiCalls();

  const clearForm = () => {
    setCode("");
    setPollCodeError("");
  };

  const validateModalForm = async () => {
    if (!code) {
      setPollCodeError(REQUIRED_FIELD_ERROR);
      formatErrorLog(REQUIRED_FIELD_ERROR);
      return false;
    } else if (code.length !== POLL_CODE_LENGTH) {
      setPollCodeError("Invalid poll code");
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return false;
    }
    const result = await getPollRecordFromUniqueCode(code);
    if(!result || !result.poll) {
      setPollCodeError("Invalid poll code");
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return false;
    }

    const courseId = result.poll.index2Pk;
    if(courseId == null) {
      return true; // this means the poll is not inside the course so no need to check if the use can access it or not
    }

    const accessibleResult = await checkMemberAccess(courseId.substring(2))
    if(accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
      setPollCodeError("User cannot access the poll");
      formatErrorLog("User cannot access the poll");
      return false;
    }

    return true;
  };

  const handleJoin = async () => {
    if (await validateModalForm()) {
      if (session.userId) {
        setUserNameToLocalStorage(code, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
      }
      history.push(`/join/${code}`);
      window.onbeforeunload = undefined;
      window.location = window.location.href;
    }
  };

  const handleJoinQR = () => {
    history.push("/qr");
  };

  const modalConfig = {
    title: "Join Poll",
    subText: "To join the Poll please enter the poll code.",
    submitButtonText: "Enter Poll",
    leftButtonText: "Scan QR",
    clearForm: clearForm,
    submit: handleJoin,
    left: handleJoinQR,
    form: [
      {
        label: "Poll Code",
        value: code,
        setFunction: setCode,
        error: pollCodeError,
        type: "number",
      },
    ],
  };

  const settings = useMemo(() => {
    return {
      shadowBoxType: "lightBlue",
      lightBoxType: "light",
      textTitle: "title",
      btnBig: "big",
    };
  }, []);

  useEffect(() => {
    newPageLog("Expired.js");
  }, []);

  const clickJoin = () => {
    setModalShow(true);
  };

  const goHome = () => {
    history.push("/");
  };

  return (
    <div id="everything">
      <div className={styles.expCenterLayout}>
        <NormalBox boxType={settings.shadowBoxType} className={`${styles.expShadow} ${styles.expCenterLayout}`}>
          <NormalBox boxType={settings.lightBoxType} className={`${styles.expCenterLayout} ${styles.expContainer}`}>
            {!isNarrow && <div className={styles.expIllustration} />}
            <NormalText>
              Entered Poll Code is {err === ERROR_CODES.EXPIRED_POLL_CODE ? "Expired" : "Invalid"}. Please select the following options to continue.
            </NormalText>
            <NormalButton
              onClick={() => clickJoin()}
              sizeType={settings.btnBig}
            >
              Join Poll
            </NormalButton>

            <NormalButton onClick={() => goHome()} sizeType={settings.btnBig}>
              {!session.userName ? "Login" : "Home"}
            </NormalButton>
            <FormModal
              show={modalShow}
              setModalShow={setModalShow}
              modalConfig={modalConfig}
            />
          </NormalBox>
        </NormalBox>
      </div>
    </div>
  );
}
