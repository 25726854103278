import classes from "./Report.module.css";
import {
    REPORT_ACTIVITY_PIE_CHART_NO_DATA_LABEL,
} from "../../utils/constants";
import {Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from "recharts";
import React, {useEffect, useRef, useState} from "react";

export default function ReportDoughnutChart({title, countData, emptyCountData}) {
    const pieRef = useRef();
    const [activeIndex, setActiveIndex] = useState(-1);


    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload} = props;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius - 2}
                    outerRadius={outerRadius + 2}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />

            </g>
        );
    };

    const PieChartTooltip = (props) => {
        const {active, payload = {}, coordinate = {}, viewBox={}} = props;
        const {top=0, bottom=0, left=0, right=0, width=0, height=0} = viewBox;
        const {x, y} = coordinate;
        const tooltipRef = useRef();
        const [offsetX, setOffsetX] = useState(0);
        const [offsetY, setOffsetY] = useState(0);
        const [isLeft, setIsLeft] = useState(true);

        useEffect(() => {
            if (activeIndex >= 0  && active) {
                const boxWidth = width + left + right;
                const boxHeight = height + top + bottom;

                let {width: tooltipWidth, height: tooltipHeight} = tooltipRef.current.getBoundingClientRect();

                if (x < boxWidth / 2) {//left
                    setIsLeft(true);
                    setOffsetX(-(tooltipWidth + 20));
                } else {//right
                    setIsLeft(false);
                    setOffsetX(0);
                }
                setOffsetY(-(tooltipHeight  + 20));

            }
        }, [active, coordinate]);

        if (activeIndex >= 0 && active && payload && payload.length) {
            return (
                <div
                    ref={tooltipRef}
                    className={`${classes.pieChartTooltips} ${isLeft ? classes.left : classes.right}`}
                    style={{transform: `translate(${offsetX}px, ${offsetY}px)`}}
                >
                    <div className={classes.chartTooltipsRow}>
                        {payload[0].name}
                    </div>

                    <div className={classes.chartTooltipsRow} style={{gap: 10}}>
                        <div className={classes.chartTooltipsDiagram}
                             style={{backgroundColor: payload[0].payload.fill}}
                        />
                        {payload[0].value}
                    </div>
                </div>
            );
        }

        return null;
    };

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };


    return (
        <>
            {countData.reduce((result, item) => result + item.value, 0) > 0 &&
                <div
                    className={classes.metricCard}
                    style={{flex: 5, justifyContent: "center"}}

                >
                    <div className={classes.pieChartLabelContainer}>
                        <span className={classes.regularLabel}>{title}</span>
                        <span className={classes.regularLabel} style={{fontWeight: "600"}}>
                            {countData.reduce((prev, current) => (current.value > prev.value) ? current : prev).name}
                        </span>
                    </div>
                    <ResponsiveContainer width={"100%"} height={100} tabIndex={0}>
                        <PieChart onMouseLeave={() => setActiveIndex(-1)}>
                            <Pie
                                ref={pieRef}
                                activeIndex={activeIndex}
                                data={countData}
                                cy={80}
                                innerRadius={50}
                                outerRadius={70}
                                startAngle={180}
                                endAngle={0}
                                paddingAngle={2}
                                dataKey="value"
                                activeShape={renderActiveShape}
                                onMouseEnter={onPieEnter}
                            >
                            </Pie>
                            <Tooltip
                                content={PieChartTooltip}
                                allowEscapeViewBox={{x: true, y: true}}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            }

            {countData.reduce((result, item) => result + item.value, 0) === 0 &&
                <div className={classes.metricCard} style={{flex: 5, justifyContent: "center"}}>
                    <div className={classes.pieChartLabelContainer}>
                            <span className={classes.regularLabel} style={{width: 100, textAlign: "center"}}>
                                {REPORT_ACTIVITY_PIE_CHART_NO_DATA_LABEL}
                            </span>
                    </div>
                    <ResponsiveContainer width={"100%"} height={100} tabIndex={0}>
                        <PieChart>
                            <Pie
                                data={emptyCountData}
                                cy={80}
                                innerRadius={50}
                                outerRadius={70}
                                startAngle={180}
                                endAngle={0}
                                dataKey="value"
                            >
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            }
        </>
    );
}