import { v4 as uuid } from 'uuid';
import {IMAGE_UPLOADING_PLACEHOLDER} from "../utils/constants";
import {useLocalStorageNew} from "./useLocalStorageNew";
import {useEffect} from "react";


const extractImagePrefix = (imageName) => {
    const regExpWithUUID = /^(.+)-Engage-\w{8}-\w{4}-\w{4}-\w{4}-\w{12}(\.\w+)$/;
    const regExpWithoutUUID = /^(.*)(\.\w+)$/;

    const groupWithUUID = regExpWithUUID.exec(imageName);
    const groupWithoutUUID = regExpWithoutUUID.exec(imageName);
    if (!!groupWithUUID) {
        return groupWithUUID[1];
    } else {
        return groupWithoutUUID[1]
    }
};

const extractImageSuffix = (imageName) => {
    const regexp = /^(.+)(\.\w+)$/;
    const group = regexp.exec(imageName);
    return group[2];
};

export const extractOriginalImageName = (imageName) => {
    if (!imageName) {
        return "";
    }
    return `${extractImagePrefix(imageName)}${extractImageSuffix(imageName)}`;
};


export const useImageLoaderManager = (questions, setQuestions) => {
    const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions-imageManager", []);

    useEffect(() => {
        setRecentQuestions(JSON.parse(JSON.stringify(questions)));
    }, [questions]);


    //==========================reset image start ==============================

    /**
     * reset image loader by image file name
     * @param image image file name
     */
    const resetImage = (image) => {
        const questionsCopy = JSON.parse(JSON.stringify(getRecentQuestions()));

        for (const q of questionsCopy) {
            const {image: queImage="", optionsImageMap={}} = q;
            if (queImage.includes(IMAGE_UPLOADING_PLACEHOLDER) && queImage.includes(image)) {
                q.image = "";
                q.imageURL = "";
                setQuestions(questionsCopy);
                return;
            }

            for (const optIdx in optionsImageMap) {
                const {image: optImage=""} = optionsImageMap[optIdx];
                if (optImage.includes(IMAGE_UPLOADING_PLACEHOLDER) && optImage.includes(image)) {
                    delete optionsImageMap[optIdx];
                    setQuestions(questionsCopy);
                    return;
                }
            }
        }
    };

    /**
     * reset multiple image loaders
     * @param images
     */
    const resetImages = (images=[]) => {
        const imageSet = new Set(images);
        const questionsCopy = JSON.parse(JSON.stringify(getRecentQuestions()));

        for (const q of questionsCopy) {
            const {image: queImageName="", optionsImageMap={}} = q;
            const actualName = queImageName.substring(0, queImageName.lastIndexOf(`_${IMAGE_UPLOADING_PLACEHOLDER}`));
            if (queImageName.includes(IMAGE_UPLOADING_PLACEHOLDER) && imageSet.has(actualName)) {
                q.image = "";
                q.imageURL = "";
            }

            for (const optIdx in optionsImageMap) {
                const {image: optImageName=""} = optionsImageMap[optIdx];
                const actualName = optImageName.substring(0, optImageName.lastIndexOf(`_${IMAGE_UPLOADING_PLACEHOLDER}`));
                if (optImageName.includes(IMAGE_UPLOADING_PLACEHOLDER) && imageSet.has(actualName)) {
                    delete optionsImageMap[optIdx];
                }
            }
        }

        setQuestions(questionsCopy);

    };

    //==========================reset image end ==============================


    //==========================set image start ==============================
    /**
     * set image and imageURL by image file name
     * @param image image file name
     * @param imageURL image file link
     */
    const setImage = (image, imageURL) => {
        const questionsCopy = JSON.parse(JSON.stringify(getRecentQuestions()));

        for (const q of questionsCopy) {
            const {image: queImageName="", optionsImageMap={}} = q;
            if (queImageName.includes(IMAGE_UPLOADING_PLACEHOLDER) && queImageName.includes(image)) {
                q.image = image;
                q.imageURL = imageURL;
                delete q.directLinkEvp;
                setQuestions(questionsCopy);
                return;
            }

            for (const optIdx in optionsImageMap) {
                const {image: optImageName=""} = optionsImageMap[optIdx];
                if (optImageName.includes(IMAGE_UPLOADING_PLACEHOLDER) && optImageName.includes(image)) {
                    optionsImageMap[optIdx].image = image;
                    optionsImageMap[optIdx].imageURL = imageURL;
                    setQuestions(questionsCopy);
                    return;
                }
            }
        }
    };

    /**
     * set multiple images and imageURLs
     * @param returnMapping imageName -> imageURL
     */
    const setImages = (returnMapping) => {
        const questionsCopy = JSON.parse(JSON.stringify(getRecentQuestions()));
        let resourceMap = new Map(Object.entries(returnMapping));
        for (const q of questionsCopy) {
            const {image: queImageName="", optionsImageMap={}} = q;
            const actualName = queImageName.substring(0, queImageName.lastIndexOf(`_${IMAGE_UPLOADING_PLACEHOLDER}`));
            if (queImageName.includes(IMAGE_UPLOADING_PLACEHOLDER) && resourceMap.has(actualName)) {
                q.image = actualName;
                q.imageURL = resourceMap.get(actualName);
            }

            for (const optIdx in optionsImageMap) {
                const {image: optImageName=""} = optionsImageMap[optIdx];
                const actualName = optImageName.substring(0, optImageName.lastIndexOf(`_${IMAGE_UPLOADING_PLACEHOLDER}`));
                if (optImageName.includes(IMAGE_UPLOADING_PLACEHOLDER) && resourceMap.has(actualName)) {
                    optionsImageMap[optIdx].image = actualName;
                    optionsImageMap[optIdx].imageURL = resourceMap.get(actualName);
                }
            }
        }
        setQuestions(questionsCopy);
    };

    //==========================set image end ==============================



    //==========================check is loading start ==============================

    /**
     * check object image if it is loading
     * @param imageObject object can be question/optionImage, both of them has same structure --- {image, imageURL, ...}
     * @returns {boolean}
     */
    const checkImageIsLoading = (imageObject) => {
        if (!imageObject) {
            return false;
        }

        const {image=""} = imageObject;

        return image.includes(IMAGE_UPLOADING_PLACEHOLDER);
    };


    const checkQueImagesIsLoading = (question) => {
        const {image: queImage="", optionsImageMap={}} = question;
        if (!!queImage && queImage.includes(IMAGE_UPLOADING_PLACEHOLDER)) {
            return true;
        }

        for (const optIdx in optionsImageMap) {
            const {image} = optionsImageMap[optIdx];
            if (!!image && image.includes(IMAGE_UPLOADING_PLACEHOLDER)) {
                return true;
            }
        }

        return false;
    };

    const checkAnyQuestionIsLoading = () => {
        const questions = getRecentQuestions();

        for (const question of questions) {
            const {image: queImage="", optionsImageMap={}} = question;
            if (!!queImage && queImage.includes(IMAGE_UPLOADING_PLACEHOLDER)) {
                return true;
            }

            for (const optIdx in optionsImageMap) {
                const {image} = optionsImageMap[optIdx];
                if (!!image && image.includes(IMAGE_UPLOADING_PLACEHOLDER)) {
                    return true;
                }
            }
        }
        return false;
    };

    //==========================check is loading end ==============================


    const getUuid = () => {
        return uuid();
    }


    const createUniqueImage = (fileName) => {
        const uuid = getUuid();
        return `${extractImagePrefix(fileName)}-Engage-${uuid}${extractImageSuffix(fileName)}`;
    };


    //==========================set image loading start ==============================

    /**
     *
     * @param serialNo question serialNo
     * @param idx option index ex: a, b, c, d
     * @param image image file name
     */
    const setOptionImageLoading = (serialNo, idx, image) => {
        const questionsCopy = JSON.parse(JSON.stringify(getRecentQuestions()));
        const questionCopy = questionsCopy.find(q => q.serialNo === serialNo);
        if (!questionCopy) {
            return;
        }

        questionCopy.optionsImageMap = !!questionCopy.optionsImageMap ? questionCopy.optionsImageMap : {};
        questionCopy.optionsImageMap[idx] = !!questionCopy.optionsImageMap.hasOwnProperty(idx) ? questionCopy.optionsImageMap[idx] : {};
        questionCopy.optionsImageMap[idx].image = `${image}_${IMAGE_UPLOADING_PLACEHOLDER}`;
        questionCopy.optionsImageMap[idx].imageURL = IMAGE_UPLOADING_PLACEHOLDER;
        setQuestions(questionsCopy);
    };


    const setQuestionImageLoading = (serialNo, image) => {
        const questionsCopy = JSON.parse(JSON.stringify(getRecentQuestions()));
        const questionCopy = questionsCopy.find(q => q.serialNo === serialNo);
        if (!questionCopy) {
            return;
        }
        questionCopy.image = `${image}_${IMAGE_UPLOADING_PLACEHOLDER}`;
        questionCopy.imageURL = IMAGE_UPLOADING_PLACEHOLDER;
        delete questionCopy.directLinkEvp;
        setQuestions(questionsCopy);
    };


    const setQuestionLoading = (duplicatedQuestion) => {
        const {image: queImage="", optionsImageMap={}, directLinkEvp=""} = duplicatedQuestion;

        // obj: originalImageName -> destImageName
        const resourceMapping = {};
        if (!!queImage && !directLinkEvp) {
            const fileName = createUniqueImage(queImage);
            duplicatedQuestion.image = `${fileName}_${IMAGE_UPLOADING_PLACEHOLDER}`;
            duplicatedQuestion.imageURL = IMAGE_UPLOADING_PLACEHOLDER;

            resourceMapping[queImage] = fileName;
        }

        for (const optIdx in optionsImageMap) {
            const {image: optImage=""} = optionsImageMap[optIdx];
            if (!!optImage) {
                const fileName = createUniqueImage(optImage);
                optionsImageMap[optIdx].image = `${fileName}_${IMAGE_UPLOADING_PLACEHOLDER}`;
                optionsImageMap[optIdx].imageURL = IMAGE_UPLOADING_PLACEHOLDER;

                resourceMapping[optImage] = fileName;
            }

        }

        return resourceMapping;
    }


    /**
     * @param queBankId
     * @param questions
     * @returns {{}} ex: original image name -> new image name
     */
    const setQuestionsLoading = (questions, queBankId) => {
        const resourceMapping = {};

        for (const q of questions) {
            const {image: queImage="", optionsImageMap={}, directLinkEvp=""} = q;

            // obj: originalImageName -> destImageName

            if (!!queImage && !directLinkEvp) {
                const fileName = createUniqueImage(queImage);
                q.image = `${fileName}_${IMAGE_UPLOADING_PLACEHOLDER}`;
                q.imageURL = IMAGE_UPLOADING_PLACEHOLDER;

                const uniqueName = `queImg-queBank-${queBankId}_${queImage}`;
                resourceMapping[uniqueName] = fileName;
            }

            for (const optIdx in optionsImageMap) {
                const {image: optImage=""} = optionsImageMap[optIdx];
                if (!!optImage) {
                    const fileName = createUniqueImage(optImage);
                    optionsImageMap[optIdx].image = `${fileName}_${IMAGE_UPLOADING_PLACEHOLDER}`;
                    optionsImageMap[optIdx].imageURL = IMAGE_UPLOADING_PLACEHOLDER;

                    const uniqueName = `optImg-queBank-${queBankId}_${optImage}`;
                    resourceMapping[uniqueName] = fileName;
                }

            }
        }


        return resourceMapping;
    }

    //==========================set image loading end ==============================


    return {
        getUuid,
        resetImage,
        resetImages,
        setImage,
        setImages,
        checkImageIsLoading,
        checkQueImagesIsLoading,
        checkAnyQuestionIsLoading,
        createUniqueImage,
        setOptionImageLoading,
        setQuestionImageLoading,
        setQuestionLoading,
        setQuestionsLoading
    };

}