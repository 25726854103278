import React from "react";
import {useToaster} from "react-hot-toast";

import styles from "./Toast.module.css";
import NormalToast from "./NormalToast";


export default function ToastCollection() {
    const {toasts, handlers} = useToaster();

    return (
        <div className={styles.mask}>
            {toasts.map((toastObj) => {
                return (<NormalToast key={toastObj.id} toastObj={toastObj} handlers={handlers}/>);
            })}
        </div>
    );

}








