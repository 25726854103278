import {useLocalStorageNew} from "./useLocalStorageNew";

export default function useHostResource(hostname) {
    const [setHostResource, getHostResource] = useLocalStorageNew(hostname, {});

    const updateImageURL = (imageURL="") => {
        const hostResource = getHostResource();
        hostResource.imageURL = imageURL;
        setHostResource(hostResource);
    }


    const updatePlatformSetting= (platformSetting={}) => {
        const hostResource = getHostResource();
        hostResource.platformSetting = platformSetting;
        setHostResource(hostResource);
    }

    const updateSSOConfig= (ssoConfig={}) => {
        const hostResource = getHostResource();
        hostResource.ssoConfig = ssoConfig;
        setHostResource(hostResource);
    }

    return {updateImageURL, updatePlatformSetting, updateSSOConfig};
}