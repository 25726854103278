import 'rc-datepicker/lib/style.css';
import { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./QuestionProfiles.module.css";
import YuJaButton from "../standardization/YuJaButton";
import {YuJaTextBox} from "../standardization/YuJaTextBox";
import {
    QUESTION_PROFILE_MODAL_SEARCH_BAR_PLACEHOLDER,
    SEARCH_BTN,
    SERACH_BOX_QUES_BANK,
    TEXTBOX
} from '../../utils/constants';

export default function QuestionBankSearch({ handleSubmit }) {
    const [keyword, setKeyword] = useState("");

    const clickSearch = () => {
        handleSubmit({ keyword: keyword});
    }

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }

    return (
        <div className={styles.searchBox} style={{margin: "0px"}}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <YuJaTextBox
                    label={SERACH_BOX_QUES_BANK}
                    containerStyle={{marginRight: 15}}
                    placeholder={QUESTION_PROFILE_MODAL_SEARCH_BAR_PLACEHOLDER}
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                >
                </YuJaTextBox>

                <YuJaButton aria-label={SEARCH_BTN} role={TEXTBOX} onClick={clickSearch}>Search</YuJaButton>
            </Form>
        </div>
    );
}