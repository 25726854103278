import "antd/dist/antd.min.css";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthenticationApiCalls } from "../../apis/AuthenticationApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import { Spin } from "../../components/standardization/YuJaLoading";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { useLoading } from "../../utils/LoadingContext";
import {
  AUDIENCE_RESTRICTION,
  CREATE_POLL_PATH,
  DEFAULT_CLASS_RESULT_TIME_LIMIT,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_GET_READY_ALL_TIME_LIMIT,
  DEFAULT_GET_READY_TIME_LIMIT,
  DEFAULT_POINTS,
  DEFAULT_POLL_BREAK_TIME_LIMIT,
  DEFAULT_POLL_LMS_SYNC,
  DEFAULT_POLL_MAX_ATTEMPTS,
  DEFAULT_POLL_RECORD_ATTEMPTS,
  DEFAULT_POLL_REQUIRED_NAME,
  DEFAULT_POLL_SHOW_CORRECT_ANS,
  DEFAULT_POLL_TIME_LIMIT,
  DEFAULT_PPT_AUTO_SHARE,
  DEFAULT_TIME_LIMIT,
  DRAFT_STATUS,
  GRADED_POLL,
  MENU_LIST,
  POLL_NAVBAR,
  POLL_SHARE_MODE,
  POLL_TIME_LIMIT_MODAL_CANCEL,
  POLL_TITLE_LENGTH,
  POLL_TYPE,
  QUESTION_TYPES,
  SURVEY,
  SWITCH_POLL_MESSAGE,
  SWITCH_POLL_MODAL_TEXT,
  SWITCH_POLL_MODAL_TITLE,
  USER_SETTINGS
} from "../../utils/constants";
import {getDefaultUserSettings, notifyError, removePrefix, updateCreateMode, validateText} from "../../utils/helpers";
import { FormatDate, addQuestion, chooseQuestion, questionError, trimQuestion } from "../../utils/questionUtils";
import { POLL_TITLE_ERROR, QUESTION_MAX_POINTS_ERROR } from "../../utils/toast-message-constants";
import CreateQuizSidebar from "./CreateQuizSidebar";
import EmptySidebar from "./EmptySidebar";
import QuestionsMain from "./QuestionsMain";
import "./styles.css";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";

export default function CreateQuizPage({
  setSidebarContent,
  setPollKeyCode,
  setPageIdentifier
}) {
  const [pollKey, setPollKey] = useState("");
  const [pollCode, setPollCode] = useState("");
  const [, getSession] = useLocalStorageNew("session", {});
  const session = getSession();
  const [userSettings, setUserSettings] = useState(getDefaultUserSettings());
  // const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [currQueDisplay, setCurrQueDisplay] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  const { fakeCall, saveAndPublish } = PollApiCalls();
  const { logout } = AuthenticationApiCalls();
  // const { getSetting } = InstitutionApiCalls();
  const history = useHistory();
  // const [pollTypeModalShow, setPollTypeModalShow] = useState(false);
  // const [desiredPollMode, setDesiredPollMode] = useState("");
  const { loading } = useLoading();
  const [pollType, setPollType] = useState(POLL_TYPE.MERGED_POLL);
  const location = useLocation();
  const [setPollTitle, getPollTitle] = useLocalStorageNew("pollTitle", "");
  const [setQues, getQues] = useLocalStorageNew("questions", []);
  const [setType, getType] = useLocalStorageNew("pollType", "");
  const [setCreateTime, getCreateTime] = useLocalStorageNew("createTime", new Date());

  const [focusMCElem, setFocusMCElem] = useState(-1);
  const [focusSAElem, setFocusSAElem] = useState(-1);
  const [focusTFElem, setFocusTFElem] = useState(-1);
  const [focusFITBElem, setFocusFITBElem] = useState(-1);
  const [focusCIElem, setFocusCIElem] = useState(-1);
  const [focusMHElem, setFocusMHElem] = useState(-1);
  const [focusRKElem, setFocusRKElem] = useState(-1);
  const [disableSave, setDisableSave] = useState(false);
  const [focusWCElem, setFocusWCElem] = useState(-1);
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false);
  const [alertShow, setAlertShow] = useState(false);
  const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
  const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
  const [setRecentTitle, getRecentTitle] = useLocalStorageNew("recentTitle", "");
  // const [setShowAnswer, getShowAnswer] = useLocalStorageNew("showAnswer", false);
  const [setPollKeyStorage, getPollKeyStorage] = useLocalStorageNew("PollKey", "");
  const [alertContent,] = useState(SWITCH_POLL_MESSAGE);
  const hostResource = useHostname(window.location.hostname);
  const [isSaving, setIsSaving] = useState(false);

  const {checkAnyQuestionIsLoading} = useImageLoaderManager(questions, setQuestions);

  const initializeQuestions = () =>  {
    const { settings = {} } = session;
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
      settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
    }
    settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
      settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
    }
    settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
      settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
    }
    settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_ENTRIES)) {
      settings[USER_SETTINGS.QUESTION_ENTRIES] = DEFAULT_ENTRIES_PER_PARTICIPANT;
    }
    settings[USER_SETTINGS.QUESTION_ENTRIES] = parseInt(settings[USER_SETTINGS.QUESTION_ENTRIES], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
      settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
    }
    settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
      settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
    }
    settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
      settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
    }
    settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true" || settings[USER_SETTINGS.POLL_REQUIRED_NAME] === true);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
      settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
    }
    settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true" || settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === true);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
      settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
      settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_BREAK_DURATION)) {
      settings[USER_SETTINGS.POLL_BREAK_DURATION] = DEFAULT_POLL_BREAK_TIME_LIMIT;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
      settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION)) {
      settings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] = DEFAULT_CLASS_RESULT_TIME_LIMIT;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.PPT_AUTO_SHARE)) {
      settings[USER_SETTINGS.PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
    } else {
      settings[USER_SETTINGS.PPT_AUTO_SHARE] = (settings[USER_SETTINGS.PPT_AUTO_SHARE] === "true" || settings[USER_SETTINGS.PPT_AUTO_SHARE] === true);
    }
    settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
    setUserSettings(settings);


    const ques = getQues();
    if (!!ques && !!ques.length) {
      setQuestions(ques);
    } else {
      const qs = addQuestion([], QUESTION_TYPES.MCSS.name, settings);
      setQuestions(qs);
      setQues(qs);
      setCurrQueDisplay(qs[0].serialNo);
    }
  }

  useEffect(() => {
    if (questions.length < 1) {
      const qs = addQuestion([], QUESTION_TYPES.MCSS.name, userSettings);
      setQuestions(qs);
      setCurrQueDisplay(qs[0].serialNo);
    }
  }, [questions]);

  useEffect(() => {
    setPageIdentifier(MENU_LIST.CREATE.name + POLL_NAVBAR);
    updateCreateMode(POLL_TYPE.MERGED_POLL);
  }, [pollType]);

  const checkChanged = () => {
    const recentQuestions = getRecentQuestions();
    const curr_ques = JSON.stringify(recentQuestions);
    const curr_saved = JSON.stringify(getQues());
   if (curr_saved === "[]") {
      console.log("curr_saved is empty");
      return false;
    }
    else {
      return curr_ques !== curr_saved;
    }
  };

  const alertConfig = {
    title: SWITCH_POLL_MODAL_TITLE,
    okText: SWITCH_POLL_MODAL_TEXT,
    cancelText: POLL_TIME_LIMIT_MODAL_CANCEL, 
    submit: async () => {
      setAlertShow(false);
      setIsConfirmationPending(true);
    },
    close:  async () => {
      setAlertShow(false);
    },
    buttonWidth: "77px"
  };
  useEffect (() => {
    setRecentQuestions(questions);
  }, [questions]);

  useEffect (() => {
    setRecentTitle(title);
  }, [title]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (!checkChanged() || getsaveExit() === true) {
        setSaveExit(false);
        resetPoll();
        if (location && location.state && location.state.logout) {
          logout();
        }
        return true;
      }
      if (location.pathname && !isConfirmationPending) {
        setNextRouterPath({ pathname: location.pathname, state: location.state });
        setAlertShow(true);
        return false;
      }
      return true;
    });

    if (isConfirmationPending && nextRouterPath.state && nextRouterPath.state.logout) {
      resetPoll();
      logout();
      unblock();
    }
    if (isConfirmationPending) {
        resetPoll();
        history.push(nextRouterPath.pathname, nextRouterPath.state);
        setIsConfirmationPending(false);
        unblock();
    }

    return () => {
      unblock();
    };
  }, [history, isConfirmationPending, nextRouterPath, pollType]);

  useEffect(() => {
    setTimeout(() => {
      if (questions.length < currQueDisplay) {
        return;
      }
      switch(questions[currQueDisplay-1].questionType) {
        case QUESTION_TYPES.MCSS.name:
          if(focusMCElem !== -1) {
            document.getElementsByName("question-text")[focusMCElem].focus();
            setFocusMCElem(-1);
          }
          break;
        case QUESTION_TYPES.SA.name:
          if(focusSAElem !== -1) {
            document.getElementsByName("question-text")[focusSAElem].focus();
            setFocusSAElem(-1);
          }
          break;
        case QUESTION_TYPES.TF.name:
          if(focusTFElem !== -1) {
            document.getElementsByName("question-text")[focusTFElem].focus();
            setFocusTFElem(-1);
          }
          break;
        case QUESTION_TYPES.FITB.name:
          if(focusFITBElem !== -1) {
            if(focusFITBElem === 0) {
              document.getElementsByClassName("notranslate public-DraftEditor-content")[focusFITBElem].focus();
            } else {
              document.getElementsByName("question-text")[focusFITBElem-1].focus();
            }
            setFocusFITBElem(-1);
          }
          break;
        case QUESTION_TYPES.CI.name:
          if(focusCIElem !== -1) {
            document.getElementsByName("question-text")[focusCIElem].focus();
            setFocusCIElem(-1);
          }
          break;

        case QUESTION_TYPES.MH.name:
          if(focusMHElem !== -1) {
            document.getElementsByName("question-text")[focusMHElem].focus();
            setFocusMHElem(-1);
          }
          break;
        case QUESTION_TYPES.RK.name:
          if (focusRKElem !== -1) {
            console.log(focusRKElem);
            document.getElementsByName("question-text")[focusRKElem].focus();
            setFocusRKElem(-1)
          }
          break;
        case QUESTION_TYPES.WC.name:
          if(focusWCElem !== -1) {
            console.log(focusWCElem);
            document.getElementsByName("question-text")[focusWCElem].focus();
            setFocusWCElem(-1);
          }
          break;
      }
    }, 500);
  }, [currQueDisplay, focusMCElem, focusSAElem, focusTFElem, focusFITBElem, focusCIElem, focusMHElem,focusWCElem, focusRKElem]);

  const validatePoll = (focusError=true) => {
    if (!validateText(title, POLL_TITLE_LENGTH)) {
      if (focusError) {
        notifyError(POLL_TITLE_ERROR);
        document.getElementsByName("pollTitle")[0].focus();
      }
      return false;
    }

    let i = 0;
    for (let q of questions) {
      i++;
      if (q.weightage > 1000) {
        if (focusError) {
          let errorWithQuestion = "Question " + i + ": " + QUESTION_MAX_POINTS_ERROR;
          notifyError(errorWithQuestion);
          setCurrQueDisplay(q.serialNo);
        }
        return false;
      }
    }

    let index = 0;
    for (let q of questions) {
      index++;
      if (focusError) setCurrQueDisplay(q.serialNo);
      let {quesError, elemIndex} = questionError(q, pollType);
      switch(q.questionType) {
        case QUESTION_TYPES.MCSS.name:
          if (focusError)  {
            setFocusMCElem(elemIndex)
          }
          break;
        case QUESTION_TYPES.SA.name:
          if (focusError) {
            setFocusSAElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.TF.name:
          if (focusError) {
              setFocusTFElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.FITB.name:
          if (focusError) {
              setFocusFITBElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.CI.name:
          if (focusError) {
              setFocusCIElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.MH.name:
          if (focusError) {
              setFocusMHElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.WC.name:
          if (focusError) {
            setFocusWCElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.RK.name: 
        if (focusError) {
            setFocusRKElem(elemIndex);
        }
        break;
      }

      if (quesError) {
        if (focusError) {
          let errorWithQuestion = "Question " + index + ": " + quesError;
          setQuestions(chooseQuestion(q.serialNo, questions));
          notifyError(errorWithQuestion);
          return false;
        }
      }
    }
    return true;
  };

  const changePollKey = (pollKeyStr) => {
    setPollKey(pollKeyStr);
    setPollKeyStorage(pollKeyStr);
  };

  const savePoll = async (updatedQuestions=[]) => {
    setDisableSave(true);
    let generatedTitle = generateDefaultPollTitle();

    let questionsCopy = JSON.parse(JSON.stringify(
        !!updatedQuestions && !!Object.entries(updatedQuestions).length
        ? updatedQuestions
        : questions
    ));

    let savedQuestions = trimQuestion(questionsCopy);

    let pollToAdd = {
      pollType: pollType,
      questions: savedQuestions,
      // ownerId: "794e0074-e325-4fb5-973c-d55750ab5324",
      pollTitle: generatedTitle,
      pollShareMode: POLL_SHARE_MODE.UNKNOWN,
      pollTimeLimit: userSettings[USER_SETTINGS.POLL_DURATION],
      getReadyAllTimeLimit: userSettings[USER_SETTINGS.POLL_COUNTDOWN],
      showAnswerOnViewer: userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS],
      // anonymousJoin: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME],
      audienceRestriction: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED,
      recordAttempt: userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS],
      lmsAttempt: userSettings[USER_SETTINGS.POLL_LMS_SYNC],
      maxAttempts: userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS],
      pptAutoShare: userSettings[USER_SETTINGS.PPT_AUTO_SHARE],
      // pollDescription: description
    };

    let pollKeyTemp = pollKey;
    if (!!pollKeyTemp) {
      pollToAdd.pollKey = pollKey;
    }

    let result = await saveAndPublish(pollToAdd, false);
    if (!result) {
      setDisableSave(false);
      return false;
    }

    const {draft={}, uniqueCode} = result;
    pollKeyTemp = removePrefix(draft.pollKey);

    if (uniqueCode) {
      setPollCode(uniqueCode);
      setPollKeyCode(`${pollKeyTemp}/${uniqueCode}`);
    } else {
      setPollKeyCode(`${pollKeyTemp}/${DRAFT_STATUS}`);
    }


    setPollTitle(generatedTitle);
    setQues(questionsCopy);
    setQuestions(questionsCopy);
    setType(pollType);
    changePollKey(pollKeyTemp);
    window.onbeforeunload = undefined;
    setDisableSave(false);
    return true;
  };




  const savePollClose = async () => {
    setDisableSave(true);

    if (!validatePoll()) {
      setDisableSave(false);
      return;
    }

    let saveQuestions = JSON.parse(JSON.stringify(questions));
    saveQuestions = trimQuestion(saveQuestions);

    let pollToAdd = {
      pollType: pollType,
      questions: saveQuestions,
      pollTitle: title,
      pollShareMode: POLL_SHARE_MODE.UNKNOWN,
      pollTimeLimit: userSettings[USER_SETTINGS.POLL_DURATION],
      getReadyAllTimeLimit: userSettings[USER_SETTINGS.POLL_COUNTDOWN],
      showAnswerOnViewer: userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS],
      // anonymousJoin: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME],
      audienceRestriction: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED,
      recordAttempt: userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS],
      lmsAttempt: userSettings[USER_SETTINGS.POLL_LMS_SYNC],
      maxAttempts: userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS],
      pollBreakDuration: userSettings[USER_SETTINGS.POLL_BREAK_DURATION],
      pptAutoShare: userSettings[USER_SETTINGS.PPT_AUTO_SHARE],
    };

    if (!!pollKey) {
      pollToAdd.pollKey = pollKey;
    }

    let result = await saveAndPublish(pollToAdd);
    if (!result) {
      setDisableSave(false);
      return;
    }

    setSaveExit(true);
    setDisableSave(false);
    window.onbeforeunload = undefined;
    history.push('/home');
  };

  useEffect(() => {
    return () => {
      window.onbeforeunload = undefined;
    };
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      setSidebarContent(
        <CreateQuizSidebar
          questions={questions}
          setQuestions={setQuestions}
          currQueDisplay={currQueDisplay}
          setCurrQueDisplay={setCurrQueDisplay}
          pollKey={pollKey}
          changePollKey={changePollKey}
          userSettings={userSettings}
        />
      );
    } else {
      setSidebarContent(
        <EmptySidebar
          title="Poll Questions"
          noContentText="No Questions added yet"
        />
      );
    }
  }, [questions, setQuestions, setSidebarContent, pollType, currQueDisplay, setCurrQueDisplay, pollKey, userSettings]);

  useEffect(() => { // query param from open in new tab
    const queryParams = new URLSearchParams(window.location.search);
    const pollTypeQuery = queryParams.get('pollType');
    console.log("pollTypeQuery", pollTypeQuery);
    if (pollTypeQuery) {
      setPollType(pollTypeQuery);
    }
  }, []);

  useEffect(async () => {
    if (location && location.state) {
      if (location.state.fromEditQuizPage && location.state.pollType) {
        changePollKey("");
        setPollType(location.state.pollType);
      } else if (location.state.fromNavbar && location.state.pollType) {
        // console.log(desiredPollMode);
        // if (window.confirm(`Changes you made may not be saved. Do you want to continue?`)) {
        setPollType(location.state.pollType);
          // resetPoll();
      }
    }
  }, [location.state]);

  // const clickOkay = () => {
  //   history.push(`/poll/published/${pollKey.current}/${pollCode.uniqueCode}`);
  // }

  const resetPoll = () => {
    // window.onbeforeunload = undefined;
    // window.location = window.location.href;
    changePollKey("");
    setDisableSave(false);
    setCreateTime(new Date()); 
    // setPollCode();
    setQuestions([]);
    setTitle("");
    // setDescription("");
    // setCurrQueDisplay("1");
    setPollTitle("");
    setQues([]);
    // setShowAnswer(false);
    setType("");
    // setType(pollType);
    // history.push({pathname: "/create", state : { pollType : pollType }})
  }

  
  useEffect(async () => {
    if (!history.location.state || !history.location.state.previous || history.location.state.previous !== location.pathname) {
        resetPoll();
        if (history.location.state && history.location.state.pollType) {
          console.log("resetting poll type", history.location.state.pollType);
          history.replace({
            pathname: CREATE_POLL_PATH,
            state: {
              pollType: [GRADED_POLL, POLL_TYPE.GRADED_POLL, SURVEY, POLL_TYPE.SURVEY].includes(history.location.state.pollType) ?
                POLL_TYPE.MERGED_POLL :
                history.location.state.pollType,
              fromNavbar: true,
              previous: CREATE_POLL_PATH
            }
          });
        } else {
          console.log("resetting to default poll type", POLL_TYPE.MERGED_POLL)
          history.replace({
            pathname: CREATE_POLL_PATH,
            state: { pollType: POLL_TYPE.MERGED_POLL, fromNavbar: true, previous: CREATE_POLL_PATH }
          })
        };
    }
    const pollType = getType();
    if (pollType && pollType !== ""){
      setPollType(pollType);
    }
    const pollTitle = getPollTitle();
    if (pollTitle && pollTitle !== "") {
      setTitle(pollTitle);
    }

    initializeQuestions();
    // const questitle = getPollTitle(); // changing poll type or creating new poll/survey should not retain title
    // if (questitle != "") {
    //   setTitle(questitle);
    // }

    // const showAnswer = getShowAnswer();
    // setShowAnswerOnViewer(showAnswer);
    if (!pollKey) {
      fakeCall().then();
    }
  }, [location]);


  const generateDefaultPollTitle = () => {
    let res = "";
    if (!title) {
      res = "Draft: " + FormatDate(new Date(getCreateTime()));
      setTitle(res);
      setPollTitle(res);
      return res;
    } else {
      return title;
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkAnyQuestionIsLoading()) {
        return;
      }

      if (title !== getPollTitle() || JSON.stringify(questions) !== JSON.stringify(getQues())) {
        // console.log("auto saving");
        setIsSaving(true);
        savePoll().finally(() => {

        });
        // setShowAnswer(showAnswerOnViewer);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [title, questions, pollType, pollKey, pollCode]);

  useEffect(() => {
    if (isSaving) {
      const gifTimer = setTimeout(() => {
        setIsSaving(false);
      }, 5000);

      return () => clearTimeout(gifTimer);
    }
  }, [isSaving]);

  return (
    <>
    <YuJaGeneralAlert
      show={alertShow}
      setModalShow={setAlertShow}
      content={alertContent}
      config={alertConfig}
    />
      <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
        <div className="question-edit-view-parent-container">
          {(questions.length > 0) &&
            <QuestionsMain
              title={title}
              setTitle={setTitle}
              questions={questions}
              setQuestions={setQuestions}
              // savePoll={savePoll}
              savePollClose={savePollClose}
              pollKey={pollKey}
              changePollKey={changePollKey}
              currQueDisplay={currQueDisplay}
              changeCurrQueDisplay={setCurrQueDisplay}
              // saveButtonDisabled={saveButtonDisabled}
              pollType={pollType}
              pollCode={pollCode}
              generateDefaultPollTitle={generateDefaultPollTitle}
              savePoll={savePoll}
              isSaving={isSaving}
              disableSave={disableSave}
              userSettings={userSettings}
              hasBlankRestriction={true}
            />}
        </div>
      </Spin>
    </>
  );
}
