import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { PollApiCalls } from "../../apis/PollApiCalls";
import NormalPagination from "../../components/pagination/NormalPagination";
import { useFetchWrapper } from "../../hooks/useFetchWrapper";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    ACTION_HEADER,
    ATTENDANCE,
    AUDIENCE_RESTRICTION,
    DATE_HEADER,
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POINTS,
    DEFAULT_POLL_LMS_SYNC,
    DEFAULT_POLL_MAX_ATTEMPTS,
    DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_ROWS_PER_PAGE,
    DEFAULT_TIME_LIMIT,
    DRAFT_STATUS,
    NO_RESULTS_FOUND,
    POLL_SHARE_MODE,
    POLL_SORT_KEY,
    POLL_STATUS,
    POLL_STATUS_HEADER,
    POLL_TYPE, POLL_TYPE_HEADER,
    READY_TO_SHARE,
    SCHEDULED,
    SHARED_BY_QUESTION,
    SHARED_ENTIRELY,
    TEXTBOX, TITLE_HEADER,
    UNFINISHED,
    USER_SETTINGS
} from "../../utils/constants";
import { getDefaultUserSettings, getPollStatus, isBefore, notifyError, removePrefix, removePrefixNew } from "../../utils/helpers";
import { INTEGRATION_API_URL } from "../../utils/properties";
import { RELEASE_INVALID_ATTENDANCE_ERROR, RELEASE_INVALID_POLL_ERROR, RELEASE_INVALID_SURVEY_ERROR } from "../../utils/toast-message-constants";
import styles from "./PollChooser.module.css";

export default React.memo(PollChooserTable);
function PollChooserTable({ data = [], columns = [], globalFilterObj = {}, secret, intType, courseMetaData, Datesort, dateSort, Titlesort, titleSort, Modesort, modeSort, Statussort, statusSort }) {
    const [, getSession] = useLocalStorageNew("session", {});
    const {publishPoll} = PollApiCalls();
    const session = getSession();
    const history = useHistory();
    const fetchWrapper = useFetchWrapper();
    const [htmlText, setHtmlText] = useState({__html: ""});
    // const [confirmShow, setConfirmShow] = useState(false);
    // const [pollKey, setPollKey] = useState("");
    // const [pollCode, setPollCode] = useState("");
    const pollDataRef = useRef([]);
    const [sortSelected, setSortSelected] = useState(0);
    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());
    const [scrollableContainer, setScrollableContainer] = useState(false);

    const {setLoading} = useLoading();
    const filterCustomize = (rows, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredStartDate = globalFilterValue.startDate;
        const filteredEndDate = globalFilterValue.endDate;
        const filteredMode = globalFilterValue.mode;
        const filteredStatus = globalFilterValue.status;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            return !!(values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword) > -1);
        }).filter(r => {
            if (!filteredStartDate && !filteredEndDate) {
                return true;
            }
            if (!filteredStartDate) {
                return isBefore(r.values.updatedDatePK, filteredEndDate);
            }
            if (!filteredEndDate) {
                return isBefore(filteredStartDate, r.values.updatedDatePK);
            }
            const values = r.values;
            return values.updatedDatePK && isBefore(filteredStartDate, r.values.updatedDatePK) && isBefore(r.values.updatedDatePK, filteredEndDate);
        }).filter(r => {
            if(!filteredMode || filteredMode.length === 0) {
                return true;
            }
            const values = r.original;
            if (values?.pollType && ((filteredMode.includes(ATTENDANCE) && values.pollType === POLL_TYPE.ATTENDANCE) || filteredMode.includes(values.pollType))) {
                return true;
            }
        }).filter(r => {
            if(!filteredStatus || filteredStatus.length === 0) {
                return true;
            }
            const values = r.values;
            if (filteredStatus.includes(UNFINISHED) && (!values.pollShareMode || values.pollSortKey === DRAFT_STATUS)) {
                return true;
            }
            if (filteredStatus.includes(READY_TO_SHARE) && values.pollShareMode === POLL_SHARE_MODE.UNKNOWN && r.original?.isValid) {
                return true;
            }
            if (filteredStatus.includes(SHARED_BY_QUESTION) && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH) {
                return true;
            }
            if (filteredStatus.includes(SHARED_ENTIRELY) && (values.pollShareMode === POLL_SHARE_MODE.SHARE_ALL || (values.pollType === POLL_TYPE.ATTENDANCE && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH))) {
                return true;
            }
            if (filteredStatus.includes(SCHEDULED) && values.pollShareMode === POLL_SHARE_MODE.SCHEDULE) {
                return true;
            }
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setLoading(true);
        setGlobalFilter(globalFilterObj);
        setLoading(false);
    }, [data, globalFilterObj]);

    useEffect(() => {
        const container = document.getElementById("poll-chooser-container");
        setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
    }, [data, pageIndex]);

    const onAddToLMS = (pollKey, uniqueCode) => {
        const redirectBody = {"secret": secret, "pollKey": pollKey, "intType": intType, "uniqueCode": uniqueCode, "apiURL": INTEGRATION_API_URL, "userId": session.userId, "courseId": courseMetaData.courseId, "courseCode": courseMetaData.courseCode};
        return fetchWrapper.post({
            url: `${INTEGRATION_API_URL}/lti/redirect`,
            body: redirectBody
        }).then(function (result) {
            const deepLinkURL = result.URL;
            const signedJWT = result.JWT;
            history.push({pathname: "/lti/deeplink/form", state : { url : deepLinkURL, jwt: signedJWT }});
        }).catch((error) => {
            console.log("Error in Redirecting", error);
        });
    };

    const handleAddToLMS = async (cell) => {
        // const disable = getDisablePollChooserEmbeddedConfirm();
        // if (!disable) {
        // if (uniqueCode === DRAFT_STATUS) {
        //     notifyError(LMS_DRAFT_INSERT_ERROR);
        //     return;
        // }
        // const pk = cell.value;
        // const uniqueCode = cell.row.values['pollSortKey'] ? cell.row.values['pollSortKey'] : DRAFT_STATUS;
        // setPollKey(pk);
        // setPollCode(uniqueCode);
        
        setLoading(true);
        // setPollData(cell.row.original);
        pollDataRef.current = cell.row.original;
        await confirmEmbed();
        setLoading(false);

        // setConfirmShow(true); // show the confirmation dialog

        // } else {
        //     onAddToLMS(pollKey, uniqueCode).then(result => setHtmlText(result));
        // }

    };

    const confirmEmbed = async () => {
        let pollKey, pollCode;
        const pollData = pollDataRef.current;
            if (pollData[POLL_SORT_KEY] === DRAFT_STATUS) {
                const pollToAdd = { ...pollData };
                pollToAdd.pollKey = removePrefix(pollToAdd.pollKey);
                pollToAdd.pollShareMode = POLL_SHARE_MODE.UNKNOWN;
                if (!pollToAdd.pollTimeLimit) {
                    pollToAdd.pollTimeLimit = userSettings[USER_SETTINGS.POLL_DURATION];
                }
                if (!pollToAdd.getReadyAllTimeLimit) {
                    pollToAdd.getReadyAllTimeLimit = userSettings[USER_SETTINGS.POLL_COUNTDOWN];
                }
                if (!pollToAdd.lmsAttempt) {
                    pollToAdd.lmsAttempt = userSettings[USER_SETTINGS.POLL_LMS_SYNC];
                }
                if (!pollToAdd.recordAttempt) {
                    pollToAdd.recordAttempt = userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS];
                }
                if (!pollToAdd.maxAttempts) {
                    pollToAdd.maxAttempts = userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS];
                }
                // if (!pollToAdd.anonymousJoin) {
                //     pollToAdd.anonymousJoin = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME];
                // }
                if (!pollToAdd.audienceRestriction) {
                    pollToAdd.audienceRestriction = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED;
                }
                if (!pollToAdd.showAnswerOnViewer) {
                    pollToAdd.showAnswerOnViewer = userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS];
                }
                if (!pollToAdd.audienceRestriction) {
                    pollToAdd.audienceRestriction = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED;
                }
                let questions = pollToAdd.questions;
                for (let i = 0; i < questions.length; i++) {
                    if (!questions[i].weightage) {
                        questions[i].weightage = userSettings[USER_SETTINGS.QUESTION_POINTS];
                    }
                    if (!questions[i].timeLimit) {
                        questions[i].timeLimit = userSettings[USER_SETTINGS.QUESTION_DURATION];
                    }
                    if (!questions[i].getReadyTimeLimit) {
                        questions[i].getReadyTimeLimit = userSettings[USER_SETTINGS.QUESTION_COUNTDOWN];
                    }
                }
                pollToAdd.questions = questions;
                const result = await publishPoll(pollToAdd, true);
                if (!result || !result.uniqueCode) {
                    notifyError(!!pollToAdd.pollType && pollToAdd.pollType.toUpperCase() === POLL_TYPE.SURVEY ? RELEASE_INVALID_SURVEY_ERROR : pollToAdd.pollType === POLL_TYPE.ATTENDANCE ? RELEASE_INVALID_ATTENDANCE_ERROR : RELEASE_INVALID_POLL_ERROR);
                    return;
                }

                const {draft={}, uniqueCode} = result;
                pollKey = draft.pollKey;
                pollCode = uniqueCode;
            } else {
                pollKey = pollData.pollKey;
                pollCode = removePrefixNew(pollData.pollSortKey);
            }
            onAddToLMS(pollKey, pollCode).then(result => setHtmlText(result));
        };


    // const confirmConfig = {
    //     submit: async () => {
    //         let pollKey, pollCode;
    //         if (pollData[POLL_SORT_KEY] === DRAFT_STATUS) {
    //             const pollToAdd = { ...pollData };
    //             pollToAdd.pollKey = removePrefix(pollToAdd.pollKey);
    //             pollToAdd.pollShareMode = POLL_SHARE_MODE.UNKNOWN;
    //             if (!pollToAdd.pollTimeLimit) {
    //                 pollToAdd.pollTimeLimit = userSettings[USER_SETTINGS.POLL_DURATION];
    //             }
    //             if (!pollToAdd.getReadyAllTimeLimit) {
    //                 pollToAdd.getReadyAllTimeLimit = userSettings[USER_SETTINGS.POLL_COUNTDOWN];
    //             }
    //             if (!pollToAdd.lmsAttempt) {
    //                 pollToAdd.lmsAttempt = userSettings[USER_SETTINGS.POLL_LMS_SYNC];
    //             }
    //             if (!pollToAdd.recordAttempt) {
    //                 pollToAdd.recordAttempt = userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS];
    //             }
    //             if (!pollToAdd.maxAttempts) {
    //                 pollToAdd.maxAttempts = userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS];
    //             }
    //             if (!pollToAdd.anonymousJoin) {
    //                 pollToAdd.anonymousJoin = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME];
    //             }
    //             if (!pollToAdd.showAnswerOnViewer) {
    //                 pollToAdd.showAnswerOnViewer = userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS];
    //             }
    //             let questions = pollToAdd.questions;
    //             for (let i = 0; i < questions.length; i++) {
    //                 if (!questions[i].weightage) {
    //                     questions[i].weightage = userSettings[USER_SETTINGS.QUESTION_POINTS];
    //                 }
    //                 if (!questions[i].timeLimit) {
    //                     questions[i].timeLimit = userSettings[USER_SETTINGS.QUESTION_DURATION];
    //                 }
    //                 if (!questions[i].getReadyTimeLimit) {
    //                     questions[i].getReadyTimeLimit = userSettings[USER_SETTINGS.QUESTION_COUNTDOWN];
    //                 }
    //             }
    //             pollToAdd.questions = questions;
    //             const publishedPollData = await publishPoll(pollToAdd, true);
    //             if (!publishedPollData) {
    //                 notifyError(!!pollToAdd.pollType && pollToAdd.pollType.toUpperCase() === POLL_TYPE.SURVEY ? RELEASE_INVALID_SURVEY_ERROR : pollToAdd.pollType === POLL_TYPE.ATTENDANCE ? RELEASE_INVALID_ATTENDANCE_ERROR : RELEASE_INVALID_POLL_ERROR);
    //                 setConfirmShow(false);
    //                 return;
    //             }
    //             pollKey = "P#" + publishedPollData.pollKey;
    //             pollCode = publishedPollData.uniqueCode;
    //         } else {
    //             pollKey = pollData.pollKey;
    //             pollCode = removePrefixNew(pollData.pollSortKey);
    //         }
    //         onAddToLMS(pollKey, pollCode).then(result => setHtmlText(result));
    //         // if (doNotShowAgain) {
    //         //     setDisablePollChooserEmbeddedConfirm(true);
    //         // }
    //         setConfirmShow(false);
    //     },
    //     close: async () => {
    //         setConfirmShow(false);
    //     },
    // };

    const handleSort = (column) => {
        if (column.render('Header') === DATE_HEADER) {
            Datesort();
            setSortSelected(0);
        } else if(column.render('Header') === TITLE_HEADER) {
            Titlesort();
            setSortSelected(1);
        } else if (column.render('Header') === POLL_TYPE_HEADER){
            Modesort();
            setSortSelected(2);
        } else if (column.render('Header') === POLL_STATUS_HEADER) {
            Statussort();
            setSortSelected(3);
        }
    };

    useEffect(() => {
        setUserSettings(() => {
            const { settings = {} } = getSession();
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
                settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
                settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            }
            settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
                settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
                settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
                settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
            }
            // settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] == "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
            }
            // settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] == "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
            }
            settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true);
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
            return settings;
        });
    }, []);

    return (
        <>
            <div className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }
                                    })}>
                                        <span tabIndex={0} role={TEXTBOX}>{column.render('Header')}</span>
                                        {(column.render('Header') === DATE_HEADER && dateSort === 0 && sortSelected === 0) || (column.render('Header') === TITLE_HEADER && titleSort === 1 && sortSelected === 1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === 1 && sortSelected === 2) || (column.render('Header') === POLL_STATUS_HEADER && statusSort === 1 && sortSelected === 3) ?
                                            <Down tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={() => handleSort(column)} />
                                            : (column.render('Header') === DATE_HEADER && dateSort === 1 && sortSelected === 0) || (column.render('Header') === TITLE_HEADER && titleSort === -1 && sortSelected === 1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === -1 && sortSelected === 2) || (column.render('Header') === POLL_STATUS_HEADER && statusSort === -1 && sortSelected === 3) ?
                                                <Up tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={() => handleSort(column)} />
                                                : (column.render('Header') === DATE_HEADER && dateSort === 0) || (column.render('Header') === TITLE_HEADER && titleSort === 1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === 1) || (column.render('Header') === POLL_STATUS_HEADER && statusSort === 1) ?
                                                    <DownGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={() => handleSort(column)} />
                                                    : (column.render('Header') === DATE_HEADER && dateSort === 1) || (column.render('Header') === TITLE_HEADER && titleSort === -1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === -1) || (column.render('Header') === POLL_STATUS_HEADER && statusSort === -1) ?
                                                        <UpGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={() => handleSort(column)}/> : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.Header === ACTION_HEADER) {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    {/* <div id={`options-${index}`}>
                                                        <div className={`${styles.tableBtn} ${styles.tablePublishBtn}`} onClick={() => handleAddToLMS(cell)} />
                                                    </div> */}
                                                    <div className={`${styles.tableBtn} ${styles.actionBtn}`} onClick={() => handleAddToLMS(cell)} />
                                                </td>
                                            );
                                        } else if (cell.column.Header === POLL_STATUS_HEADER) {
                                            return (
                                                <td role={TEXTBOX} aria-label={POLL_STATUS + getPollStatus(cell.value, cell.row.original?.isValid, true)} tabIndex={0} {...cell.getCellProps()}>
                                                    {getPollStatus(cell.value, cell.row.original?.isValid, cell.row.original?.pollType, true)}
                                                </td>
                                            );
                                        // } else if (cell.column.Header === POLL_TYPE_HEADER) {
                                        //     return (
                                        //         <td tabIndex={0} role={TEXTBOX} aria-label={MODE + cell.value + " for " + row.cells.at(index=1).value} {...cell.getCellProps()}>
                                        //             {cell.value && cell.value.substring(0,6) === GRADED_SHORT ? GRADED_MODE : cell?.value === POLL_TYPE.ATTENDANCE ? ATTENDANCE : cell.value}
                                        //         </td>
                                        //     );
                                        // } else if (cell.column.Header === 'Joining Code') {
                                        //     return (<>
                                        //         <td {...cell.getCellProps()}>
                                        //              {cell.value.startsWith('PUB#UC#') ? cell.value.replace('PUB#UC#', '') : cell.value}
                                        //          </td>
                                        //         </>
                                        //     );
                                        } else {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.value}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="4" className={styles.noData} style={{boxShadow: "none"}}><div style={{ height: 300, justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div></td></tr>}
                    </tbody>
                </Table>

                {page && page.length !== 0 ? <>
                    {!scrollableContainer && <div style={{ height: "0px", marginTop: "40px" }} />}
                    <div style={scrollableContainer ? { marginTop: "10px" } : { position: "absolute", bottom: "25px" }}>
                        <NormalPagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                        />
                    </div>
                </> : null}
            </div>

            { htmlText && htmlText !== "" && <div dangerouslySetInnerHTML={htmlText} /> }

            {/* <PollChooserEmbeddedConfirm
                show={confirmShow}
                config={confirmConfig}
            /> */}
        </>
    );
}