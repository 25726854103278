import {useHistory, useParams} from "react-router-dom";
import styles from "./PPTInsertWaitingPage.module.css";
import Lottie from "react-lottie";
import {PPT_WEBVIEW_ADDING_SLIDES_DESCRIPTION} from "../../utils/constants";
import React from "react";
import addingSlides from "./animations/addingSlides.json";

export default function PPTSSOWaitingPage() {
    const history = useHistory();
    const { appId } = useParams();


    const options = {
        loop: true,
        autoplay: true,
        animationData: addingSlides,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        }
    };

    return (
        <div className={styles.container}>
            <div style={{flex: 1}}/>
            <Lottie
                options={options}
                height={200}
                width={200}
                isClickToPauseDisabled={true}
                style={{cursor: "default"}}
            />
            <span className={styles.title}>Inserting Slides</span>
            <div style={{height: 20}}/>
            <span className={styles.description}>{PPT_WEBVIEW_ADDING_SLIDES_DESCRIPTION}</span>
            <div style={{flex: 3}}/>
        </div>
    );
}