import {
  CompositeDecorator,
  ContentState,
  EditorState
} from "draft-js";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import YuJaFITBPreviewModal from "../../components/modals/YuJaFITBPreviewModal";
import { BLANK_REGEX, BLANK_REGEX_NEW } from "../../utils/constants";
import { findWithRegex } from "../../utils/helpers";
import { handleAddAnswer, handleDeleteAnswer, handleUpdateAnswer } from "../../utils/questionUpdate";
import FITBAnswer from "./FITBAnswer";
import FITBQuestionTitle from "./FITBQuestionTitle";
import FITBRow from "./FITBRow";

export default function FITBQuestion({
  questions,
  setQuestions,
  selectedQuestion,
  pollType,
  hoverDisplay=false,
  hasBlankRestriction=false
}) {
  const { correctAnswers={}, serialNo, queTitle='', weightage } = selectedQuestion;
    const gradeEnable = !!weightage;
  const indexToChange = serialNo - 1;

  // const isNarrow = useMobileAccess(1100);

  const [previewModalShow, setPreviewModalShow] = useState(false); 
  
  function BlankStrategy(contentBlock, callback) {
    findWithRegex(hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX, contentBlock, callback);
  }

  const BlankSpan = (props) => {
    return (
      <span
        style={{
          color: "#029ce2",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "18px"
        }}
        data-offset-key={props.offsetKey}
      >
        {props.children}
      </span>
    );
  };

  const [compositeDecorator, ] = useState(new CompositeDecorator([
    {
      strategy: BlankStrategy,
      component: BlankSpan,
    },
  ]));
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(queTitle),
      compositeDecorator
    )
  );

  const modalConfig = {
    title: "Blank Preview",
    submitButtonText: "Proceed Anyways",
    close: (() => {setPreviewModalShow(false)}), 
    cancelText: "Close"
};

  return (
    <>
      {previewModalShow &&
        <YuJaFITBPreviewModal
          config={modalConfig}
          queTitle={queTitle}
          show={previewModalShow}
          setModalShow={setPreviewModalShow}
          sumbitButtonShow={false}
          hasBlankRestriction={hasBlankRestriction}
        />
      }
      <FITBQuestionTitle
        setQuestions={setQuestions}
        questions={questions}
        selectedQuestion={selectedQuestion}
        editorState={editorState}
        setEditorState={setEditorState}
        compositeDecorator={compositeDecorator}
        previewModalShow={previewModalShow}
        setPreviewModalShow={setPreviewModalShow}
        hoverDisplay={hoverDisplay}
        hasBlankRestriction={hasBlankRestriction}
      />
      <div className="FITBAnswerContainer">
        {!!gradeEnable &&
          <>{Object.keys(correctAnswers).length === 0 ? null : (
            <FITBRow noBorder={true} text="Type Answers here" showButtons={false}>
              {/* <Col className="fitb-input-col margin-vertical-auto no-padding">
               <FITBTextArea disabled={true} value="" placeholder="Enter potential answers here"/>
             </Col> */}
              <div style={{ display: 'flex', flexWrap: "wrap", gap: 20 }}>
                {Object.keys(correctAnswers).map((key, i) => (
                  <>
                    {correctAnswers[key].map((value, i) => (
                        <FITBAnswer
                            key={i}
                          index={i}
                          answerKey={key}
                          answerValue={value}
                          handleAddAnswer={(key) => handleAddAnswer(key, correctAnswers, indexToChange, questions, setQuestions)}
                          handleUpdateAnswer={(key, index, text) => handleUpdateAnswer(key, index, text, correctAnswers, indexToChange, questions, setQuestions)}
                          handleDeleteAnswer={(key, index) => handleDeleteAnswer(key, index, correctAnswers, indexToChange, questions, setQuestions, editorState, setEditorState, compositeDecorator)}
                        />
                    ))}
                  </>))}
              </div>
            </FITBRow>
          )}</>
        }
      </div>
    </>
  );
}
