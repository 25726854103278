import {Spin} from "../components/standardization/YuJaLoading";
import {ReactComponent as UploadImage} from "../images/upload_purple.svg";
import {
    COLLAPSED_SIDE_BAR_WIDTH, EXPANDED_SIDE_BAR_WIDTH,
    imageSuffixArr,
    MODAL_DELETE_TEXT,
    OPTION_UPLOAD_TITLE,
    REPLACE_TEXT,
    TEXTBOX,
    VIEW_TEXT
} from "../utils/constants";
import {ReactComponent as MenuIcon} from "../images/white_menu_icon.svg";
import {YuJaKebabMenu} from "../components/standardization/YuJaKebabMenu";
import {ReactComponent as ViewIcon} from "../images/View.svg";
import {ReactComponent as EditIcon} from "../images/pencil.svg";
import {ReactComponent as DeleteIcon} from "../images/trash.svg";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useImageLoaderManager} from "../hooks/useImageLoaderManager";
import {PollApiCalls} from "../apis/PollApiCalls";
import {notifyError} from "../utils/helpers";
import {INVALID_IMAGE_FORMAT_ERROR, SELECT_FILE_ERROR} from "../utils/toast-message-constants";
import {YuJaImageViewer} from "../components/standardization/YuJaImageViewer";
import useMobileAccess from "../hooks/useMobileAccess";
import {YuJaGlobalState} from "../utils/LoadingContext";
import {useWindowDimensions} from "react-native-web";

export default function UploadOptionImage({
                                              pollKey,
                                              changePollKey,
                                              optionData,
                                              optionsImageMap,
                                              selectedQuestion,
                                              questions,
                                              setQuestions,
                                              questionBankId,
                                              questionBuilder=false,
                                              disabled=false,
                                              setIsDraggable,
}) {

    const {
        getUuid,
        createUniqueImage,
        resetImage,
        checkImageIsLoading,
        setOptionImageLoading,
        setImage
    } = useImageLoaderManager(questions, setQuestions);

    const {sidebarCollapsed=false} = useContext(YuJaGlobalState);
    let {width: windowWidth} = useWindowDimensions();
    const isNarrow = windowWidth - (sidebarCollapsed ? COLLAPSED_SIDE_BAR_WIDTH : EXPANDED_SIDE_BAR_WIDTH)  < 1200;
    const {uploadOptImage, uploadQueBankImage} = PollApiCalls();
    const uploadInputRef = useRef();
    const [showManageImageIcon, setShowManageImageIcon] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [imageURL, setImageURL] = useState("");

    const { choice } = optionData;
    const { serialNo} = selectedQuestion;

    useEffect(() => {
        if (!optionsImageMap.hasOwnProperty(choice)) {
            setIsUploading(false);
            return;
        }

        setIsUploading(checkImageIsLoading(optionsImageMap[choice]));
    }, [questions, optionsImageMap, choice]);


    const uploadPollImg = (file) => {
        const suffix = file.name.substring(file.name.lastIndexOf('.'))?.toLowerCase();
        if (!imageSuffixArr.includes(suffix)) {
            notifyError(INVALID_IMAGE_FORMAT_ERROR);
            uploadInputRef.current.value = '';
            return;
        }
        if (!file || !file.name) { // if no image is selected
            notifyError(SELECT_FILE_ERROR);
            return;
        }
        const fileName = createUniqueImage(file.name);
        const f = new File([file], fileName);
        let uuid = pollKey;
        if (!uuid) {
            uuid = getUuid();
            changePollKey(uuid);
        }
        uploadOptImage(uuid, f, (name, url) => {
            setImage(name, url);
        }).then(res => {
            if (!res) {
                resetImage(fileName);
            }
        });

        setOptionImageLoading(serialNo, choice, fileName);
    }


    const uploadQueBankImg = (file) => {
        const suffix = file.name.substring(file.name.lastIndexOf('.'))?.toLowerCase();
        if (!imageSuffixArr.includes(suffix)) {
            notifyError(INVALID_IMAGE_FORMAT_ERROR);
            uploadInputRef.current.value = '';
            return;
        }
        if (!file || !file.name) { // if no image is selected
            notifyError(SELECT_FILE_ERROR);
            return;
        }
        const fileName = createUniqueImage(file.name);
        const f = new File([file], fileName);
        uploadQueBankImage(questionBankId, f, (name, url) => {
            setImage(name, url);
        }).then(res => {
            if (!res) {
                resetImage(fileName);
            }
        });

        setOptionImageLoading(serialNo, choice, fileName);
    }



    const handleUpload = (e) => {
        if (!questionBankId) {
            uploadPollImg(e.target.files[0]);
        } else {
            uploadQueBankImg(e.target.files[0]);
        }
        onMouseLeave();
    };

    const handleDelete = () => {
        const questionsCopy = JSON.parse(JSON.stringify(questions));
        const questionCopy = questionsCopy.find(q => q.serialNo === serialNo);
        if (!questionCopy) {
            return;
        }
        const {optionsImageMap={}} = questionCopy;
        if (optionsImageMap.hasOwnProperty(choice)) {
            delete optionsImageMap[choice];
            setQuestions(questionsCopy);
        }
    }

    const handleView = () => {
       const imageURL = optionsImageMap && optionsImageMap.hasOwnProperty(choice) ? optionsImageMap[choice].imageURL : "";
       setImageURL(imageURL);
       setShowViewModal(true);
    };

    const handleClose = () => {
        setImageURL("");
        setShowViewModal(false);
        onMouseLeave();
    };

    const onMouseEnter = () => {
        setShowManageImageIcon(true);
        setIsDraggable(false);
    };

    const onMouseLeave = () => {
        setShowManageImageIcon(false);
        setIsDraggable(!questionBuilder);
    };

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{
                width: isNarrow || questionBuilder ? 20 : 30,
                height: isNarrow || questionBuilder ? 20 : 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                backgroundColor: !isUploading && !!optionsImageMap.hasOwnProperty(choice) ? "#C8C7C7" : "transparent",
                borderRadius: 3,
            }}
        >
            <YuJaImageViewer showViewer={showViewModal} onHandleClose={handleClose} imageURL={imageURL} imageAlt={`Option ${choice} image preview`}/>
            <input
                disabled={questionBuilder && disabled}
                ref={uploadInputRef}
                className="browse-box"
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleUpload}
                aria-label="upload image"
            />
            {!!isUploading &&
                <Spin size={"medium"} spinning={true} style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}} />

            }
            {!isUploading && !optionsImageMap.hasOwnProperty(choice) &&
                <UploadImage
                    onClick={() => uploadInputRef.current.click()}
                    style={{width: isNarrow || questionBuilder ? 20 : 30, height: isNarrow || questionBuilder ? 20 : 30, cursor: "pointer"}}
                    title={OPTION_UPLOAD_TITLE}
                />
            }
            {!isUploading && !!optionsImageMap.hasOwnProperty(choice) &&
                <>
                    <img
                        tabIndex={0}
                        role={TEXTBOX}
                        alt={`Option ${choice} image preview`}
                        src={`${optionsImageMap[choice].imageURL}`}
                        style={ { maxWidth: "100%", maxHeight: "100%", cursor: "pointer"}}
                    />


                    {showManageImageIcon &&
                        <>
                            <div
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    left: 0,
                                    top: 0,
                                    background: "#000000",
                                    opacity: 0.5,
                                    borderRadius: 3,
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    left: 0,
                                    top: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <MenuIcon style={{width: 20, height: 20}}/>
                            </div>
                        </>

                    }

                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            left: 0,
                            top: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <YuJaKebabMenu iconVisible={false}>
                            <YuJaKebabMenu.Menu>
                                <YuJaKebabMenu.Item onClick={handleView}>
                                    <ViewIcon style={{width: 18, marginRight: 5}}/>{VIEW_TEXT}
                                </YuJaKebabMenu.Item>
                                <YuJaKebabMenu.Item onClick={() => uploadInputRef.current.click()}>
                                    <EditIcon style={{width: 18, marginRight: 5}} />{REPLACE_TEXT}
                                </YuJaKebabMenu.Item>
                                <YuJaKebabMenu.Item onClick={handleDelete}>
                                    <DeleteIcon style={{width: 18, marginRight: 5}}/>{MODAL_DELETE_TEXT}
                                </YuJaKebabMenu.Item>
                            </YuJaKebabMenu.Menu>
                        </YuJaKebabMenu>
                    </div>
                </>
            }
        </div>
    );
}