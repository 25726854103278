import "antd/dist/antd.min.css";
import TextArea from "antd/lib/input/TextArea";
import 'rc-datepicker/lib/style.css';
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { Spin } from "../../components/standardization/YuJaLoading";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as FeedbackComment } from "../../images/feedback-comment.svg";
import { ReactComponent as FeedbackOther } from "../../images/feedback-other.svg";
import { ReactComponent as FeedbackSuggestion } from "../../images/feedback-suggestion.svg";
import { ReactComponent as DeleteImage } from "../../images/feedbackFileDelete.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as UploadImage } from "../../images/upload_purple.svg";
import Logo from "../../images/yujalogo.svg";
import { BUTTON, CLOSE_MODAL, FEEDBACK_APPRECIATION_EDITING_TEXT, FEEDBACK_APPRECIATION_TEXT, FEEDBACK_CATEGORY, FEEDBACK_CATEGORY_COMMENT, FEEDBACK_CATEGORY_OTHER, FEEDBACK_CATEGORY_SUGGESTION, FEEDBACK_MODAL_CATEGORY, FEEDBACK_MODAL_SATISFACTION, FEEDBACK_MODAL_SATISFACTION_SUFFIX, FEEDBACK_MODAL_SUB_TITLE, FEEDBACK_MODAL_SUB_TITLE_SUFFIX, FEEDBACK_MODAL_TITLE, FEEDBACK_TEXT_PLACEHOLDER, FEEDBACK_THANK_TEXT, FEEDBACK_THOUGHT_TEXT, FEEDBACK_TITLE_PLACEHOLDER, FEEDBACK_TITLE_TEXT, LOGIN_PAGE_FOOTER_TEXT, POLL_TITLE_LENGTH, QUESTION_LENGTH, SATISFACTION_GOOD_TEXT, SATISFACTION_LABEL, SATISFACTION_POOR_TEXT, SATISFACTION_SATISFIED_TEXT, SATISFAcTION_EXCELLENT_TEXT, TEXTBOX, UPLOAD_MEDIA, fileSuffixArr } from "../../utils/constants";
import { formatFileSize, getFormattedCurrentTime, notifyError } from "../../utils/helpers";
import { INVALID_FILE_FORMAT_ERROR, SELECT_FILE_ERROR, UPLOAD_ONE_FILE_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaTextBox } from "../standardization/YuJaTextBox";
import "./modal.css";

export default function FeedBackModal({setModalShow, show, pollKey, pollUniqueCode, userId, institutionId}) {
    const isMobile = useMobileAccess(480);
    const [feedbackTitle, setFeedbackTitle] = useState("");
    const [satisfaction, setSatisfaction] = useState("");
    const [feedbackCategory, setFeedbackCategory] = useState("");
    const [feedbackText, setFeedbackText ]= useState("");
    const [feedbackDone, setFeedbackDone] = useState(false);
    const {updateFeedback, uploadFile, getPollQuestions, deleteFile} = ResponseApiCalls();
    const [fileName, setFileName] = useState("");
    const [storedFileName, setStoredFileName] = useState("");
    const [fileSize, setFileSize] = useState("");
    const ref = useRef();
    const [setGuestSession, getGuestSession] = useLocalStorageNew("guestSession", {});
    const [, getSession] = useLocalStorageNew("session", {});
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing]= useState(false);

    const handleButtonClick = async () => {
      // if(feedbackTitle === "" && satisfaction === "" && feedbackCategory === "" && feedbackText === "") {
      //   notifyError(FEEDBACK_REQUIRED_FIELD);
      //   return;
      // }
      const session = getSession();
      let userName = "";
      if (session && session.firstName && session.lastName) {
        userName = session.firstName + " " + session.lastName;
      }
      else {
        const guestSession = getGuestSession();
        if(guestSession["$"+pollUniqueCode].userName) {
          userName = guestSession["$" + pollUniqueCode].userName;
        }
        else {
          userName = "anonymous";
        }
      }
        const feedbackData = {
          title: feedbackTitle,
          satisfaction : satisfaction,
          category: feedbackCategory,
          text: feedbackText,
          fileName: fileName, 
          fileSize: fileSize, 
          storedFileName: storedFileName, 
          userName: userName, 
          date: new Date(),
        };
        setIsLoading(true);
        await updateFeedback(pollKey, pollUniqueCode, feedbackData);
        setIsLoading(false);
        setFeedbackCategory("");
        setFeedbackTitle("");
        setSatisfaction("");
        setFeedbackText("");
        setFileName("");
        setStoredFileName("");
        setFileSize("");
        setFeedbackDone(true);
    }

    const handleCloseModal = () => {
      setFeedbackCategory("");
        setFeedbackTitle("");
        setSatisfaction("");
        setFeedbackText("");
        setFileName("");
        setStoredFileName("");
        setFileSize("");
        setIsEditing(false);
        setFeedbackDone(false); 
        setModalShow(false);
    }

    const dragOver = (e) => {
      e.preventDefault();
    };
  
    const dragEnter = (e) => {
      e.preventDefault();
    };
  
    const dragLeave = (e) => {
      e.preventDefault();
    };
  
    const fileDrop = (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length > 1) {       
        notifyError(UPLOAD_ONE_FILE_ERROR);
        return;
      }
      upload(files[0]);       
    };

    const upload = async (file) => {
      console.log(file);
  
      const suffix = file.name.substring(file.name.lastIndexOf('.'));
  
      if (!fileSuffixArr.includes(suffix)) {
        notifyError(INVALID_FILE_FORMAT_ERROR);
        return;
      }
  
      if (!file || !file.name) { // if no image is selected
        notifyError(SELECT_FILE_ERROR);
        return;
      }
  
      const newFileName = `${file.name.substring(0, file.name.lastIndexOf("."))}-${getFormattedCurrentTime()}${suffix}`
      const f = new File([file], newFileName);
      setIsLoading(true);
      const url = await uploadFile(f, userId, pollKey, institutionId); 
      setIsLoading(false);
      if (ref.current) {
        ref.current.value = '';
      }
      setFileName(file.name);
      setStoredFileName(newFileName);
      setFileSize(file.size);
    };

    const handleUpload = (e) => {
        upload(e.target.files[0]);
    };

    const handleDeleteFile = async () => {
      await deleteFile( storedFileName, userId, pollKey, institutionId)
      setFileName("");
      setStoredFileName("");
      setFileSize("");
    }

    useEffect( async () => {
      if(show) {
      setIsLoading(true);
      const res = await getPollQuestions(pollUniqueCode);
      if (res && res.hasOwnProperty("userResponse") && res.userResponse.hasOwnProperty("feedback")) {
        const feedback = res.userResponse.feedback;
        if(feedback.category) {
          setFeedbackCategory(feedback.category);
        }
        if(feedback.text) {
          setFeedbackText(feedback.text);
        }
        if(feedback.title) {
          setFeedbackTitle(feedback.title);
        }
        if (feedback.satisfaction) {
          setSatisfaction(parseInt(feedback.satisfaction, 10));
        }
        if (feedback.fileName && feedback.fileSize) {
          setFileName(feedback.fileName);
          setStoredFileName(feedback.storedFileName);
          setFileSize(feedback.fileSize);
        }
        setIsEditing(true);
      }
      setIsLoading(false);
      }
    }, [show])

    return (
        <Modal
          id={isMobile ? "feedback-modal" : "form-modal"} 
          show={show}
          onHide={handleCloseModal}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop={true}
          dialogClassName= {isMobile ? "Feedback-mobile" : "ac-modal"}
        >   
        <Spin tip="Loading..." size="large" spinning={isLoading}>
        <div style={{ maxHeight: isMobile ? "90svh" : "", overflowY: "auto"}}> 
        <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={handleCloseModal} className="modal-close" style={{width: "20px", height: "20px"}}/>
        {!feedbackDone &&
        <>
        <div style={{padding: "25px 34px"}}> 
        <div tabIndex={0} role={TEXTBOX} style={{fontSize: "19px", fontWeight: 700, color: "#42296E"}}> {FEEDBACK_MODAL_TITLE} </div>
        {/* <div tabIndex={0} role={TEXTBOX} style={{marginTop: "10px", fontSize: "13px", color: "#000"}}> {FEEDBACK_MODAL_SUB_TITLE} </div> */}
        <div tabIndex={0} role={TEXTBOX} style={{marginTop: isMobile ? "10px" : "27.6px", fontSize: "13px", color: "#000"}}> {FEEDBACK_MODAL_SATISFACTION}{FEEDBACK_MODAL_SATISFACTION_SUFFIX}</div>
        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
        <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFACTION_POOR_TEXT} className={`feedback-modal-satisfaction satisfaction-poor ${satisfaction === 0 ? "satisfaction-poor-selected" : ""}`} style={{color: "#CD2F22", border: "1.46px solid #CD2F22"}} onClick={()=> {setSatisfaction(0)}}>{SATISFACTION_POOR_TEXT}</div>
        <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFACTION_SATISFIED_TEXT} className={`feedback-modal-satisfaction satisfaction-satisfied ${satisfaction === 1 ? "satisfaction-satisfied-selected" : ""}`} style={{color: "#CA9536", border: "1.46px solid #CA9536"}} onClick={()=> {setSatisfaction(1)}}> {SATISFACTION_SATISFIED_TEXT} </div>
        <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFACTION_GOOD_TEXT} className={`feedback-modal-satisfaction satisfaction-good ${satisfaction === 2 ? "satisfaction-good-selected" : ""}`} style={{color: "#61C180", border: "1.46px solid #61C180"}} onClick={()=> {setSatisfaction(2)}}> {SATISFACTION_GOOD_TEXT} </div>
        <div tabIndex={0} aria-label={SATISFACTION_LABEL + SATISFAcTION_EXCELLENT_TEXT} className={`feedback-modal-satisfaction satisfaction-excellent ${satisfaction === 3 ? "satisfaction-excellent-selected" : ""}`} style={{color: "#0B8910", border: "1.46px solid #0B8910"}} onClick={()=> {setSatisfaction(3)}}> {SATISFAcTION_EXCELLENT_TEXT} </div>
        </div>
        <div tabIndex={0} role={TEXTBOX} style={{fontSize: "13px", color: "#000", marginTop: "10px"}}> {FEEDBACK_MODAL_CATEGORY} </div>
        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
        <div tabIndex={0} aria-label={"category" + FEEDBACK_CATEGORY.Suggestion} className={`feedback-modal-satisfaction feedback-category ${feedbackCategory === FEEDBACK_CATEGORY.Suggestion ? "feedback-category-selected" : ""}`} style={{color: "#42296E", border: "1.46px solid #42296E"}} onClick={() =>{setFeedbackCategory(FEEDBACK_CATEGORY.Suggestion)}}> <div className={`${feedbackCategory === FEEDBACK_CATEGORY.Suggestion ? "Category-selected" :""}`}>  <FeedbackSuggestion style={{marginRight: "6px"}}/> </div> {FEEDBACK_CATEGORY_SUGGESTION}</div>
        <div tabIndex={0} aria-label={"category" + FEEDBACK_CATEGORY.Comment} className={`feedback-modal-satisfaction feedback-category ${feedbackCategory === FEEDBACK_CATEGORY.Comment ? "feedback-category-selected" : ""}`} style={{color: "#42296E", border: "1.46px solid #42296E"}} onClick={() =>{setFeedbackCategory(FEEDBACK_CATEGORY.Comment)}}> <div className={`${feedbackCategory === FEEDBACK_CATEGORY.Comment ? "Category-selected" :""}`}> <FeedbackComment style={{marginRight: "6px"}}/>  </div> {FEEDBACK_CATEGORY_COMMENT} </div>
        <div tabIndex={0} aria-label={"category" + FEEDBACK_CATEGORY.Other} className={`feedback-modal-satisfaction feedback-category ${feedbackCategory === FEEDBACK_CATEGORY.Other ? "feedback-category-selected" : ""}`} style={{color: "#42296E", border: "1.46px solid #42296E"}} onClick={() =>{setFeedbackCategory(FEEDBACK_CATEGORY.Other)}}> <div className={` ${feedbackCategory === FEEDBACK_CATEGORY.Other ? "Category-selected" :""}`}> <FeedbackOther style={{marginRight: "6px"}} /> </div> {FEEDBACK_CATEGORY_OTHER} </div>
        </div>
        <div tabIndex={0} role={TEXTBOX} style={{fontSize: "13px", color: "#000", marginTop: "10px"}}> {FEEDBACK_TITLE_TEXT} </div>

        <YuJaTextBox
                                containerStyle={{ fontSize: 15, marginTop: "10px"}}
                                value={feedbackTitle}
                                onChange={(e) => setFeedbackTitle(e.target.value)}
                                placeholder={FEEDBACK_TITLE_PLACEHOLDER}
                                maxLength={POLL_TITLE_LENGTH}
                            />
        <div tabIndex={0} role={TEXTBOX} style={{fontSize: "13px", color: "#000", marginTop: "10px"}}> {FEEDBACK_THOUGHT_TEXT} </div>
        <TextArea
                                style={{ fontSize: 15, marginTop: "10px", minHeight: "96px"}}
                                value={feedbackText}
                                onChange={(e) => setFeedbackText(e.target.value)}
                                placeholder={FEEDBACK_TEXT_PLACEHOLDER}
                                className="feedback-text-area"
                                maxLength={QUESTION_LENGTH}
                            />
        <div
        className="image-upload-container"
        style={{cursor: "pointer", marginTop: "16px"}}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop} 
        onClick={(e) => ref.current.click(e)}
        >
        <div className="justify-content-center"  style={{display: "flex", alignItems: "center", gap: "10px", marginLeft: "0px"}}>
          <div tabIndex={0} aria-label={UPLOAD_MEDIA} role={TEXTBOX} className="upload-icon" style={{marginLeft: "0px"}}><UploadImage style={{width: "40px", height: "40px"}}/></div>
            <p tabIndex={0} aria-label={UPLOAD_MEDIA} role={TEXTBOX} className="drag-drop-text d-flex" style={{fontSize: "13px",  width: "auto", height: "100%", margin: "0", alignItems: "center"}}><div style={{color: "#006DC7", fontSize: "13px", textDecoration: "underline",  fontWeight: "700", marginLeft: "5px", marginRight: "5px"}}> Choose Media </div> to Upload</p>
            <input
              ref={ref}
              className="browse-box"
              type="file"
              accept=".pdf, .doc, .docx, .txt, image/png, image/jpg, image/jpeg, image/jfif, image/pjpeg, image/pjp"
              aria-label="upload file"
              onChange={handleUpload}
            />
            </div>
        </div>
        {fileName && <div style={{display: "flex", gap: "5px", fontSize: "14px"}}> <div> 1.</div> <div>{fileName}</div> <div>[{formatFileSize(fileSize)}]</div> <div style={{flex: 1, display: "flex", justifyContent: "end",cursor: "pointer"}}> <DeleteImage onClick={handleDeleteFile}/> </div></div>}
        </div>
        <Modal.Footer>
        <YuJaButton type={"delete"} onClick={handleCloseModal}>Cancel</YuJaButton>
          <YuJaButton
          onClick={handleButtonClick}
          disabled={feedbackTitle === "" || satisfaction === "" || feedbackCategory === "" || feedbackText === ""}
          >
              Submit
          </YuJaButton>
        </Modal.Footer>
        </>
        }
        </div> 
        {
          feedbackDone && 
          <div style={{height: isMobile ? "90svh" : "", overflowY: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <div style={{display: "flex", flexDirection: "column", minHeight: !isMobile ? "550px" : "", justifyContent: "center", alignItems: "center"}}> 
          <img src={Logo} className="guest-login-logo" alt="YuJa Logo" />
          <div tabIndex={0} role={TEXTBOX} style={{fontSize: "24px"}}> {FEEDBACK_THANK_TEXT} </div>
          <div tabIndex={0} role={TEXTBOX} style={{fontSize: "24px"}}> {isEditing ? FEEDBACK_APPRECIATION_EDITING_TEXT: FEEDBACK_APPRECIATION_TEXT} </div>
          </div>
          <div tabIndex={0} role={TEXTBOX} style={{fontSize: "14px", color: "#464646",display: "flex", justifyContent: "center", height: "85px"}}> {LOGIN_PAGE_FOOTER_TEXT} </div>
          </div>
        }
        </Spin>
        </Modal>
    )

}