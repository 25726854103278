import React, { useEffect, useState } from "react";
import { Spin } from "../../components/standardization/YuJaLoading";
import { ACTIVE_PARTICIPANTS_TEXT, EXPAND_WINDOW_LABEL, HAS_JOINED_ATTENDANCE, HAS_JOINED_POLL, MINIMIZE_WINDOW_LABEL, POLL_TYPE, TEXTBOX, VIEW_ACTIVITY_TEXT } from "../../utils/constants";
import { ReactComponent as DropDown } from "./images/DROPDOWN_ICON.svg";
import { ReactComponent as DropUp } from "./images/DROPUP_ICON.svg";
import { ReactComponent as NotificationIcon } from "./images/NOTIFICATION_ICON.svg";
import { ReactComponent as NoActivityFeedIcon } from "./images/NO_ACTIVITY_FEED.svg";
import "./styles.css";

export default function ViewActivityFeed({data=[], localState, collapsed, pollType=POLL_TYPE.GRADED_POLL, isPresentation=false}) {
    const [minimized, setMinimized] = useState(false);
    // let sharingStates = ["SHARED_ALL", "SHARED", "GET_READY_ALL", "GET_READY"];

    return(<div style={{height: isPresentation? "40vh" : ""}}>
        {minimized && 
            <div className="smallPanelBodyNew" style={{width: "100%"}}>
                <span className="smallPanelTitle" tabIndex={collapsed ? -1: 0} role={TEXTBOX}>Activity Feed</span>
                <DropDown onMouseDown={e => e.preventDefault()} tabIndex={collapsed ? -1: 0} aria-label={EXPAND_WINDOW_LABEL} style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
            {/*<div className="smallPanelBody" style={{width: "350px"}}>
                <Spin size="medium" wrapperClassName="panelSpin" spinning={false} style={{height: "100%"}}>
                    {(!data.students || !data.students.length || !sharingStates.includes(localState)) && 
                        <div style={{display: "flex", width: "100%"}}>
                            <div style={{display: "flex", flex: 5, alignItems: "center", flexDirection: "column"}}>
                                <NoActivityFeedIconSmall/>
                                <div>No activity found</div>
                            </div>
                            <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                        </div>
                    }
                    {(!!data.students && !!data.students.length && sharingStates.includes(localState)) && <div style={{width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <span className="smallPanelTitle">Activity Feed</span>
                            <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                        </div>
                        <div className="pollCodePanelBody">
                            <div className="responsePanelNumbers" style={{color: "#268726"}}>{data.students.length}</div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span className="responselegendcircle" style={{backgroundColor: "#268726"}}></span>
                                <div className="responsePanelText">Joined</div>
                            </div>
                        </div>
                    </div>}
                </Spin>
            </div>*/}
            </div>
        }
        {!minimized && 
        <div className="smallPanelBody2" style={{ width: "100%", height: isPresentation? "100%" : ""}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span className="smallPanelTitle"  tabIndex={collapsed ? -1: 0} role={TEXTBOX}>Activity Feed</span>
                {!isPresentation && <DropUp onMouseDown={e => e.preventDefault()} tabIndex={collapsed ? -1: 0} aria-label={MINIMIZE_WINDOW_LABEL} style={{cursor: "pointer", }} onClick={() => setMinimized(true)}/>}
            </div>
            <span className="smallPanelSubtitle"  tabIndex={collapsed ? -1:0} role={TEXTBOX}>{VIEW_ACTIVITY_TEXT} </span>
            {isPresentation && <div style={{  marginTop: 12}}> {ACTIVE_PARTICIPANTS_TEXT}{data && data.students ? data.students.length : ""} </div>}
            <div className="pollCodePanelBody" style={{ overflowY: "auto"}}>
            <Spin size="large" wrapperClassName="panelSpin" spinning={false} tip="Loading..." style={{height: "100%"}}>
                {(!data.students || !data.students.length) && 
                    <>
                        <NoActivityFeedIcon style={{maxWidth: "100%", maxHeight: "100%"}}/>
                        <span tabIndex={collapsed ? -1: 0} role={TEXTBOX}>No activity found</span>
                    </>
                }
                {(!!data.students && !!data.students.length) && <>
                    <div className="activityFeedBody">
                        {[...new Map(data.students.map(obj => [obj.userId, obj])).values()].reverse().map((element, index) => {
                            return (<div key={index}>
                                {index === 0 && 
                                <div className="firstStudent" id={"ActivityFeedQ"+index}>
                                    <div className="activityFeedTab"></div>
                                    <div style={{display: "flex", flexDirection: "column", flex: 2, marginRight: "5px"}}>
                                        <p className="activityFeedNameText">{decodeURI(element.userName)}</p>
                                        <p className="activityFeedSmallText" tabIndex={collapsed ? -1: 0} aria-label={decodeURI(element.userName) + pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE : HAS_JOINED_POLL} >{pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE: HAS_JOINED_POLL}</p>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#F2F2F2", width: "40px", height: "40px",}}>
                                        <NotificationIcon/>
                                    </div>
                                </div>
                                }
                                {index !== 0 && 
                                    <div className="outerStudent" id={"ActivityFeedQ"+index}>
                                        <div className="activityFeedTab"></div>
                                        <div style={{display: "flex", flexDirection: "column", flex: 2, marginRight: "5px"}}>
                                            <p className="activityFeedNameText">{decodeURI(element.userName)}</p>
                                            <p className="activityFeedSmallText" tabIndex={collapsed ? -1: 0} aria-label={decodeURI(element.userName) + pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE : HAS_JOINED_POLL}>{pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE : HAS_JOINED_POLL}</p>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#F2F2F2", width: "40px", height: "40px", }}>
                                            <NotificationIcon/>
                                        </div>
                                    </div>
                                }
                            </div>);
                        })}
                    </div>
                </>}
            </Spin>
            </div>
        </div>}
    </div>);
}
