import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import CIQuestionOption from "./CIQuestionOption";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";


export default function CIQuestion({
                                     setQuestions,
                                     questions,
                                     selectedQuestion,
                                     pollType,
                                     pollKey,
                                     currQueDisplay,
                                     questionBankId=null, 
                                     
}) {
  const { optionsMap, serialNo } = selectedQuestion;
  const {isNarrow} = useCheckMainContainerNarrow();
  const {isPPTPage} = usePPTWebView();

  const handleAnswerOrderChange = (result) => {
    if (!result.destination) return;

    const sourceKey = result.source.index;
    const destinationKey = result.destination.index;

    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newOptionsMap = JSON.parse(JSON.stringify(q.optionsMap));
        newOptionsMap[sourceKey] = q.optionsMap[destinationKey];
        newOptionsMap[destinationKey] = q.optionsMap[sourceKey];

        let newCorrectAns = q.correctAnswers;
        const correctAnswer = q.correctAnswers[0];
        if (sourceKey === correctAnswer) {
          newCorrectAns = [destinationKey];
        } else if (destinationKey === correctAnswer) {
          newCorrectAns = [sourceKey];
        }

        let updatedQuestion = {
          ...q,
          optionsMap: newOptionsMap,
          correctAnswers: newCorrectAns,
        };

        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push(q);
      }
    });
    setQuestions(newQuestions);
  };


  return (
    <>
      <div className="container question-main-container" style={{margin: "0px", padding: "0px", maxWidth: "100%"}}>
          <DragDropContext onDragEnd={handleAnswerOrderChange}>
            <Droppable droppableId="droppable-multiple-choice-question">
              {(provided, snapshot) => (
                  <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      {...provided.dropHandleProps}
                      className="droppable-multiple-choice-question-options"
                      style={{marginTop: "10px", display: "flex", flexDirection: "column", gap: isNarrow ? 10 : 25}}
                  >
                  {optionsMap.map((option, index) => {
                    return (
                        <div key={index}>
                          <CIQuestionOption
                              optionData={option}
                              selectedQuestion={selectedQuestion}
                              questions={questions}
                              setQuestions={setQuestions}
                              index={index}
                              pollType={pollType}
                          />
                        </div>
                    );
                  })}
                  </div>
              )}
            </Droppable>
          </DragDropContext>
      </div>
    </>
  );
}
