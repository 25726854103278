import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import { COURSE_CODE, COURSE_CODE_LABEL, COURSE_NAME, COURSE_NAME_LABEL, DEFAULT_ROWS_PER_PAGE, NO_RESULTS_FOUND, TEXTBOX } from "../../utils/constants";
import styles from "./EnrolledCourses.module.css";
import { ReactComponent as NoResult } from "../../images/no_result.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";

export default React.memo(EnrolledCoursesTable);
function EnrolledCoursesTable({ data = [], columns = [], dataReady=true, globalFilterObj = {}, sortCourseName, sortCourseCode, courseNameSort, courseCodeSort }) {
    // const history = useHistory();
    const skeletonLoaderWidth = ["40px", "80px"];
    const [scrollableContainer, setScrollableContainer] = useState(false);
    const [sortSelected, setSortSelected] = useState(-1); // sorted by date but not displayed

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredDate = globalFilterValue.date;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.courseName && values.courseName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (values.courseCode && values.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        }).filter(r => {
            if (!filteredDate) {
                return true;
            }
            const values = r.values;
            return values.startDate && values.startDate === filteredDate;
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [globalFilterObj, setGlobalFilter]);

    useEffect(() => {
        const container = document.getElementById("enrolled-courses-container");
        setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
    }, [data]);

    // const onView = (courseId) => {
    //     history.push(`/course/content/${courseId}`);
    // };

    const handleSort = (column) => {
        if (column.render('Header') === COURSE_NAME) {
            sortCourseName();
            setSortSelected(0);
        } else if(column.render('Header') === COURSE_CODE) {
            sortCourseCode();
            setSortSelected(1);
        }
    };

    return (
        <>
            <div className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }
                                    })}>
                                        {column.render('Header')}
                                        {(column.render('Header') === COURSE_NAME && courseNameSort === 1 && sortSelected === 0) ||
                                            (column.render('Header') === COURSE_CODE && courseCodeSort === 1 && sortSelected === 1) ?
                                        <SortDown tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === COURSE_NAME && courseNameSort === -1 && sortSelected === 0) ||
                                            (column.render('Header') === COURSE_CODE && courseCodeSort === -1 && sortSelected === 1) ?
                                        <SortUp tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === COURSE_NAME && courseNameSort === 1) ||
                                            (column.render('Header') === COURSE_CODE && courseCodeSort === 1) ?
                                        <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === COURSE_NAME && courseNameSort === -1) ||
                                            (column.render('Header') === COURSE_CODE && courseCodeSort === -1) ?
                                        <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/> : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {dataReady && page.length!== 0 ? page.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        // if (cell.column.Header === 'View Course') {
                                        //     return (
                                        //         <td tabIndex={0} {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                        //             <div
                                        //                 className={styles.tablePublishBtn}
                                        //                 onClick={() => onView(cell.row.values['courseId'])}
                                        //             >
                                        //                 {VIEW_TEXT}
                                        //             </div>
                                        //         </td>
                                        //     );
                                        // } else {
                                            return (
                                                <td tabIndex={0} style={{textAlign: "left"}} aria-label={cell.column.Header === COURSE_CODE ? COURSE_CODE_LABEL + cell.value : COURSE_NAME_LABEL + cell.value} {...cell.getCellProps()}>
                                                    {cell.value}
                                                </td>
                                            );
                                        // }
                                    })}
                                </tr>
                            )})
                        : dataReady && page.length === 0 ? 
                        <>
                        <td align="center" colSpan="2" className={styles.tableNoPadding}> <div style={{width: "100%", height: "500px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}> <NoResult/> <div style={{marginTop: "10px", fontSize: "20px", fontWeight: 600}}>{NO_RESULTS_FOUND}</div> </div></td>
                        </>
                        : 
                        (Array.from({ length: 5 }).map((_, rowIndex) => (
                            <tr key={rowIndex}> {headerGroups.map(headerGroup => (
                                <>
                                {headerGroup.headers.map((column, colIdx) => {
                                    return (
                                    <td tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            height: 40
                                        }
                                    })}>
                                        <div className={column.Header === 'Number' ? styles.leftCell : column.Header === 'Your Answer' ? styles.centerCell : styles.tableCell} style={{textAlign: "left"}}>
                                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                <Skeleton borderRadius={10} height={14} width={skeletonLoaderWidth[colIdx]} style={{ position: "relative" }} />
                                            </SkeletonTheme>
                                        </div>
                                    </td>);
                                })}
                                </> 
                                ))}
                            </tr>
                        )))
                        }
                    </tbody>
                </Table>

                {page && page.length !== 0 ? <>
                    {!scrollableContainer && <div style={{ height: "0px", marginTop: "40px" }} />}
                    <div style={scrollableContainer ? { marginTop: "10px" } : { position: "absolute", bottom: "25px" }}>
                        <NormalPagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                        />
                    </div>
                </> : <></>}
            </div>
        </>
    );
}
