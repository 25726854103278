import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import GradientButton from "../../public/buttons/GradientButton";
import { TEXTBOX, VIEW_RESULTS, VIEW_RESULTS_TEXT } from "../../utils/constants";
import { notifySuccess, removePrefixNew } from "../../utils/helpers";
import { HOST_URL } from "../../utils/properties";
import { COPY_URL_SUCCESS } from "../../utils/toast-message-constants";
import styles from "./DashboardPage.module.css";
import { ReactComponent as Copy } from "./images/gradient_copy_icon.svg";
import { ReactComponent as ActivePollIcon } from "./images/gradient_radar_icon.svg";
import { ReactComponent as NoResultIllustration } from "./images/no_active_polls_found_illustration.svg";

export default function CreatorActivePolls({data=[], loading=true, initialized=false}) {
    const history = useHistory();
    const panelBodyRef = useRef();

    const hostname = window.location.hostname;
    let shortName = '';
    if (hostname.includes(HOST_URL) && hostname.includes("www")) {
        shortName = hostname.indexOf('.') >= 0 ?
            hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
            hostname;
    } else {
        shortName = hostname.indexOf('.') >= 0 ?
            hostname.substring(0, hostname.indexOf('.')) :
            hostname;
    }


    const viewResult = (poll) => {
        const {pk, index3Pk} = poll;
        history.push({
            pathname: `/poll/share/${removePrefixNew(pk)}/${removePrefixNew(index3Pk)}`,
            // state: { expandedAnalytics: true }
        });
    }

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    return (
        <div className={styles.panelContainer} style={{height: "38%"}}>
            <div className={styles.panelTitle}>
                <ActivePollIcon style={{marginRight: "15px"}}/>
                <div className={styles.panelSubTitle}>
                    <div role={TEXTBOX} tabIndex={0} >Active Activities</div>
                    <div role={TEXTBOX} tabIndex={0} style={{fontSize: "13px"}}>View activities that are currently live</div>
                </div>
            </div>
            {!loading ? 
            <div className={styles.panelBody} ref={panelBodyRef}>
                {/* <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}> */}
                    {initialized && (!data || !data.length) &&
                        <div style={{display: "flex",  justifyContent: "center", alignItems: "center"}}>
                            <NoResultIllustration className={styles.activePollIllustration}/>
                            There are no active activities currently.
                        </div>
                    }
                    {initialized && (!!data && !!data.length) &&
                        <div className={styles.modifiedPollContainer}>
                            {JSON.parse(JSON.stringify(data)).splice(0, 3).map((poll, index) => (
                                <div key={index}
                                     className={styles.modifiedPollUnit}
                                     style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                                >
                                    <span style={{display: "flex", width: "55%"}}>
                                        <span role={TEXTBOX} tabIndex={0} className={styles.omitableText}>{poll.pollTitle}</span>
                                        <CopyToClipboard            
                                            text={`${shortName}.${HOST_URL}/qr/${removePrefixNew(poll.index5Sk)}`}>
                                            <div onClick={() => notifySuccess(COPY_URL_SUCCESS)} role={TEXTBOX} aria-label={"Copy Join Poll Link"} tabIndex={0}>
                                                <Copy
                                                    title={"copy join poll link"}
                                                    style={{marginLeft: "5px", maxWidth: "20px", maxHeight: "20px", cursor: "pointer"}}
                                                />
                                            </div>
                                    </CopyToClipboard>
                                    </span>
                                    {/* <span
                                        tabIndex={0}
                                        aria-label={"View results summary for poll " + poll.pollTitle}
                                        role={BUTTON}
                                        title={"go to poll page"}
                                        style={{width: "45%", marginLeft: "10px", textAlign: "end", cursor: "pointer"}}
                                        className={`${styles.gradientText} ${styles.linkText}`}
                                        onClick={() => viewResult(poll)}
                                    >
                                        View Result Summary
                                    </span> */}
                                    <GradientButton
                                        role={TEXTBOX}
                                        label={VIEW_RESULTS + poll.pollTitle}
                                        style={{ marginLeft: "15px" }}
                                        onClick={() => viewResult(poll)}
                                    >
                                        {VIEW_RESULTS_TEXT}
                                    </GradientButton>
                                </div>
                            ))}
                        </div>
                    }
                {/* </Spin> */}
            </div>
            : windowSize[1] >= 870 ? 
                <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                <Skeleton  borderRadius={10} height = {14.56005859375} width = "6.5vw" style = {{position: "relative", marginTop:"25px"}} />
                <div style = {{display: "flex", justifyContent:"flex-end"}}> 
                <Skeleton  borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"25px"}} />
                </div>
                <Skeleton  borderRadius={10} height = {14.56005859375} width = "6vw"  style={{ position: "relative", marginTop:"20px"}}/> 
                <div style = {{display: "flex", justifyContent:"flex-end"}}> 
                <Skeleton  borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"20px"}} />
                </div>
                <Skeleton  borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"20px"}}/>
                <div style = {{display: "flex", justifyContent:"flex-end"}}> 
                <Skeleton  borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"20px"}} />
                </div>
                </SkeletonTheme> </div> 
            : windowSize[0] <= 630 && windowSize[1] <= 760 ? 
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                <Skeleton  borderRadius={10} height = {14.56005859375} width = "6.5vw" style = {{position: "relative", marginTop:"50px"}} />
                <div style = {{display: "flex", justifyContent:"flex-end"}}> 
                <Skeleton  borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"50px"}} />
                </div>
            </SkeletonTheme>
            </div>
            : <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "6.5vw" style = {{position: "relative", marginTop:"35px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"35px"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "6vw"  style={{ position: "relative", marginTop:"40px"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"40px"}} />
            </div>
            </SkeletonTheme> </div> }
        </div>
    );
}