import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useWindowDimensions } from "react-native-web";
import useMobileAccess from "../../hooks/useMobileAccess";
import waitingRoomBackground from "../../images/waitingRoomBackground.svg";
import waitingRoomBackgroundMobile from "../../images/waitingRoomBackgroundMobile.svg";
import {
  DIVISOR_LABEL,
  GRADED_POLL,
  POLL_TYPE,
  RELEASE_QUESTION_COUNT_TEXT,
  TEXTBOX,
  WAITING_ROOM_ACTIVITY_TEXT,
  WAITING_ROOM_POLL_TEXT,
  WAITING_ROOM_SURVEY_TEXT
} from "../../utils/constants";
import { newPageLog } from "../../utils/helpers";
import NetworkStatus from "./NetworkStatus";
import "./styles.css";

export default function WaitingRoom({ bodyText, bodyShareCount, titleText, afterQ, websocketState, loadingDelay }) {
  // document.getElementById('root').style.backgroundImage = `url(${window.innerWidth < 480 ? waitingRoomBackgroundMobile : waitingRoomBackground})`;
  // document.getElementById('root').style.backgroundPositionX= `${window.innerWidth < 480 ? "": "-200px"}`; 
  // document.getElementById('root').style.backgroundPositionY= `${window.innerWidth < 480 ? "": "105px"}`;
  // document.getElementById('root').style.backgroundRepeat = `${window.innerWidth < 480 ? "": "no-repeat"}`;
  useWindowDimensions();
  const isNarrow = useMobileAccess(1200);
  const isMobile = useMobileAccess(480);
  useEffect(() => {
    newPageLog("WaitingRoom.js");
    if (isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
    //   if(document.getElementById("viewer-navbar") !== null) {
    //     document.getElementById("viewer-navbar").style.backgroundColor = "";
    //   }

    //   document.body.classList.add("waiting-room-background");
    //   return () => {
    //     document.body.classList.remove("waiting-room-background");
    //   };
  }, []);

  return (
    <div className="waiting-room-background" style={{ backgroundImage: window.innerWidth < 480 ? `url(${waitingRoomBackgroundMobile})` : `url(${waitingRoomBackground})` }}>
      <div className="waiting-room-title-container" style={{ visibility: (!loadingDelay && titleText) ? "visible" : "hidden", height: "fit-content", marginTop: window.innerWidth < 480 ? 24 : 50, width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between" }}>
        <span tabIndex={0} role={TEXTBOX} className="waiting-room-title" style={{ wordBreak: "break-word", paddingLeft: !isNarrow ? "170px" : "10px", textAlign: "center", flex: "1" }}>{loadingDelay ? null : titleText}</span>
        {(loadingDelay || !titleText) &&
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
              <Skeleton borderRadius={10} height={20} width="60vw" style={{ position: "relative" }} />
            </SkeletonTheme>
          </div>
        }
        <div style={{ display: "flex", justifyContent: "end", paddingRight: "20px" }}>  <NetworkStatus websocketState={websocketState} /> </div>
      </div>
      <img src={require("../../images/waitingRoom.gif")} alt="loading..." style={{ height: window.innerWidth < 480 ? "180px" : "300px", width: window.innerWidth < 480 ? "180px" : "300px", marginTop: "58px" }} />
      <Row>
        <Col className="center">
          {bodyShareCount &&
            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <span className="gradientWrapQuesType" style={{ height: "45px", width: !isMobile ? "259px" : "200px", marginLeft: "0px" }}>
                <span className="questionPanelQuestionTypeContainer" style={{ width: "100%" }}>
                  <div tabIndex={bodyText ? 0 : -1} role={TEXTBOX} aria-label={bodyShareCount?.replace("/", DIVISOR_LABEL)} className="waiting-room-subtitle" style={{ fontSize: isMobile ? "13px" : "18px",  color: "#000", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {RELEASE_QUESTION_COUNT_TEXT} <div style={{ fontWeight: 700, marginLeft: "5px" }}> {bodyShareCount} </div>
                  </div>
                </span>
              </span>
            </div>
          }
          <div tabIndex={bodyText ? 0 : -1} role={TEXTBOX} className="waiting-room-subtitle" style={{ marginTop: "20px" }}>
            {bodyText}
          </div>
        </Col>
      </Row>
      {!afterQ ? (<>
        <Row>
          <Col className="center">
            <div tabIndex={0} role={TEXTBOX} className="waiting-room-bottom" style={{ marginTop: "30px" }}>
              {(loadingDelay || !titleText) ? WAITING_ROOM_ACTIVITY_TEXT : WAITING_ROOM_POLL_TEXT}
            </div>
          </Col>
        </Row>
      </>) : (<></>)}
    </div>
  );
}
