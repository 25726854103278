import React, {createContext, useContext, useEffect} from 'react';

const YuJaEventBusContext = createContext();

export function YuJaEventBusProvider({ children }) {
    const eventHandlers = [];
    const scrollEventHandlers = [];

    const addClickEventHandler = (handler) => {
        eventHandlers.push(handler);
    };

    const removeClickEventHandler = (handler) => {
        const index = eventHandlers.indexOf(handler);
        if (index !== -1) {
            eventHandlers.splice(index, 1);
        }
    };

    const handleGlobalClick = (event) => {
        for (const handler of eventHandlers) {
            handler(event);
        }
    };

    // Add a global click event listener
    useEffect(() => {
        window.addEventListener('click', handleGlobalClick);
        return () => {
            window.removeEventListener('click', handleGlobalClick);
        }
    }, [])

    return (
        <YuJaEventBusContext.Provider value={{ addClickEventHandler, removeClickEventHandler }}>
            {children}
        </YuJaEventBusContext.Provider>
    );
}

export function useYuJaEventBus() {
    return useContext(YuJaEventBusContext);
}
