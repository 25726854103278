import { useEffect, useState } from "react";
import { ANY, LANDSCAPE, PORTRAIT } from "../utils/constants";

/*
  320px — 480px for mobile devices
  481px — 768px for iPads & tablets
  769px — 1024px for small screens like laptop
  1025px — 1200px for large screens like Desktops
  1201px and above for extra large screens like TV
*/
export default function useMobileAccess(mobileSize = 480, orientation = ANY) { // Pass in the size you want to use custom mobile size; Old Value: 950
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileSize &&
                                            (orientation === ANY ||
                                            (orientation === PORTRAIT && window.innerWidth < window.innerHeight) ||
                                            (orientation === LANDSCAPE && window.innerWidth > window.innerHeight)));

  useEffect(() => {
    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth < mobileSize &&
                  (orientation === ANY ||
                  (orientation === PORTRAIT && window.innerWidth < window.innerHeight) ||
                  (orientation === LANDSCAPE && window.innerWidth > window.innerHeight)));
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [mobileSize]);

  return isMobile;
}
