import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import FilterMenu from "../../components/FilterMenu";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { ReactComponent as Filter } from "../../images/filter.svg";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { CREATE_USER, DATE_CREATED, FILTER_USERS, LAST_ACCESSED, LINKAGE_TYPE, ROLE, SEARCH_TEXT, SEARCH_USER, USERS_SEARCH } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { LOADING_FILTER_ERROR_USERS, LOADING_SEARCH_ERROR_USERS } from "../../utils/toast-message-constants";
import styles from "./ManageUserPage.module.css";

export default function UserSearchForm({ handleSubmit, handleCreate, handleDelete, showDeleteButton, roles, linkageTypes, disabled=false, collapsed=false }) {
    const filterRef = useRef();
    const filterMenuRef = useRef();
    // const windowDimensions = useWindowDimensions();
    const [keyword, setKeyword] = useState("");
    // const [date] = useState("");
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [roleFilter, setRoleFilter] = useState([]);
    const [linkageTypeFilter, setLinkageTypeFilter] = useState([]);
    const [dateCreatedFilter, setDateCreatedFilter] = useState("");
    const [lastAccessedFilter, setLastAccessedFilter] = useState("");
    const [oldCollapsed, setOldCollapsed] = useState(false);
    const [filterMenuDimensions, setFilterMenuDimensions] = useState({});
    const [filterMenuLocation, setFilterMenuLocation] = useState({x: 0, y: 0});

    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && filterMenuRef.current && !filterMenuRef.current.contains(event.target) && !filterRef.current.contains(event.target)) {
                setShowFilterMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showFilterMenu && (oldCollapsed !== collapsed || filterMenuDimensions.width === 0 || JSON.stringify(filterMenuDimensions) !== JSON.stringify(filterMenuRef?.current?.getBoundingClientRect()))) {
            setFilterMenuDimensions(filterMenuRef?.current?.getBoundingClientRect());
            setOldCollapsed(collapsed);
            resetFilterMenuPosition();
        }
    }, [collapsed, filterMenuRef?.current?.getBoundingClientRect()]);

    const clickSearch = ({key=keyword, dc=dateCreatedFilter, la=lastAccessedFilter, r=roleFilter, lt=linkageTypeFilter}) => {
        if (disabled) {
            notifyError(LOADING_SEARCH_ERROR_USERS);
            return;
        }
        handleSubmit({
            keyword: key,
            dateCreated: dc ? dc.split("-").reverse().join("-") : "",
            lastAccessed: la ? la.split("-").reverse().join("-") : "",
            role: r,
            linkageType: lt
        });
    };

    const clickFilter = () => {
        if (disabled) {
            notifyError(LOADING_FILTER_ERROR_USERS);
            return;
        }
        setShowFilterMenu(!showFilterMenu)
    };

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch({});
        }
    }

    const onApply = (filterOptions) => {
        setRoleFilter(filterOptions.optionFilter1);
        setLinkageTypeFilter(filterOptions.optionFilter2);
        setDateCreatedFilter(filterOptions.dateFilter1);
        setLastAccessedFilter(filterOptions.dateFilter2);
        clickSearch({dc: filterOptions.dateFilter1, la: filterOptions.dateFilter2, r: filterOptions.optionFilter1, lt: filterOptions.optionFilter2});
    };

    const resetFilterMenuPosition = () => {
        const filterRect = filterRef?.current?.getBoundingClientRect();
        const filterMenuRect = filterMenuRef?.current?.getBoundingClientRect();
        // console.log(filterRect, filterMenuRect, window.scrollX, window.scrollY);
        // const x = filterRect?.left + window.scrollX - ( window.innerWidth <= 1200 ? 0 :((filterMenuRect?.width - filterRect?.width) / 2 + 20 - (filterMenuRect?.width / 2) + (collapsed ? 60 : 240)));
        // const y = filterRect?.bottom + window.scrollY - 52 + (5);

        const x = -filterRect?.width - 3;
        const y = 25;
        // console.log(x, y);
        setFilterMenuLocation({x, y});
    };


    useEffect(() => {
        const handleResize = () => {
    
          if (showFilterMenu) {
            resetFilterMenuPosition();
          }
        };
    
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [showFilterMenu]);


    return (
        <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <Filter className={styles.focusIcon} tabIndex={0} aria-label={FILTER_USERS} ref={filterRef} onClick={clickFilter} style={{width: 32, height: 32}} />
                <div style={{position: "relative"}}>
                <div ref={filterMenuRef}
                    // className={styles.filterMenuBackdrop}
                    style={{
                        position: "absolute",
                        top: filterMenuLocation.y + "px",
                        left: filterMenuLocation.x + "px",
                        // width: "100%",
                        // width: "400px",
                        // height: "300px"
                    }}
                    // onClick={() => setShowFilterMenu(false)}
                >
                    <FilterMenu
                        // ref={filterMenuRef}
                        show={showFilterMenu}
                        setShow={setShowFilterMenu}
                        hasDateRange={false}
                        filterOptions={{
                            optionFilter1Name: ROLE,
                            optionFilter1: roleFilter,
                            optionsFilter1: roles,
                            optionFilter2Name: LINKAGE_TYPE,
                            optionFilter2: linkageTypeFilter,
                            optionsFilter2: linkageTypes,
                            dateFilter1Name: DATE_CREATED,
                            dateFilter1: dateCreatedFilter,
                            dateFilter2Name: LAST_ACCESSED,
                            dateFilter2: lastAccessedFilter
                        }}
                        onApply={onApply}
                        style={{
                            width: "100%"
                        }}
                    />
                </div>
                </div>
                <YuJaTextBox
                    containerStyle={{width: "40vw", fontSize: 12, marginLeft: 10}}
                    placeholder={SEARCH_USER}
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                    before={<SearchIcon style={{width: 16, marginRight: 5}}/>}
                    label={USERS_SEARCH}
                />
                {/* <DatePickerInput
                    ref={dateCreatedRef}
                    displayFormat='DD-MM-YYYY'
                    style={{paddingLeft: "10px", width: "160px"}}
                    placeholder={DATE_CREATED}
                    onChange={value => {
                        if (!isNaN(value)) {
                            setDateCreatedFilter(value.toISOString().split('T')[0]);
                        } else {
                            setDateCreatedFilter("");
                        }
                    }}
                    value={dateCreatedFilter}
                    aria-label={DATE_CREATED_LABEL}
                    onFocus={() => dateCreatedRef.current?.show()}
                    onBlur={() => dateCreatedRef.current?.hide()}
                />
                <DatePickerInput
                    ref={lastAccessedRef}
                    displayFormat='DD-MM-YYYY'
                    style={{paddingLeft: "10px", width: "160px"}}
                    placeholder={LAST_ACCESSED}
                    onChange={value => {
                        if (!isNaN(value)) {
                            setLastAccessedFilter(value.toISOString().split('T')[0]);
                        } else {
                            setLastAccessedFilter("");
                        }
                    }}
                    value={lastAccessedFilter}
                    aria-label={LAST_ACCESSED_LABEL}
                    onFocus={() => lastAccessedRef.current?.show()}
                    onBlur={() => lastAccessedRef.current?.hide()}
                />
                <YuJaDropdown
                    ariaLabel={roleFilter + ALL_ROLE_LABEL}
                    data={roles}
                    getOptionLabel={item => item}
                    getOptionValue={item => item}
                    onChange={option => setRoleFilter(option)}
                    value={roleFilter}
                    containerStyle={{paddingLeft: 10, width: 130}}
                    style={{padding: "8px 15px"}}
                />
                <YuJaDropdown
                    ariaLabel={linkageTypeFilter + ALL_LINKAGE_TYPE_LABEL}
                    data={linkageTypes}
                    getOptionLabel={item => item}
                    getOptionValue={item => item}
                    onChange={option => setLinkageTypeFilter(option)}
                    value={linkageTypeFilter}
                    containerStyle={{paddingLeft: 10, width: 110}}
                    style={{padding: "8px 15px"}}
                /> */}
                <YuJaButton style={{marginLeft: 10}} onClick={clickSearch}><div style={{fontSize: 14}}>{SEARCH_TEXT}</div></YuJaButton>
                <YuJaButton style={{marginLeft: 10}} onClick={handleCreate}><PlusIcon style={{width: 15, height: 15, marginRight: 5}}/><div style={{fontSize: 14}}>{CREATE_USER}</div></YuJaButton>
                {showDeleteButton &&
                    <div className={styles.deleteSelectedBtn}>
                        <YuJaButton type="delete" onClick={handleDelete}>Delete Selected</YuJaButton>
                    </div>
                }
            </Form>
        </div>
    );
}
