import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import FeedBackModal from "../../components/modals/FeedBackModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { Spin } from "../../components/standardization/YuJaLoading";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    BUTTON,
    CLOSE_MODAL,
    ENDED_POLL_TEXT,
    FEEDBACK_BUTTON_TEXT,
    NO_TEXT,
    REATTEMPT_MODAL_BODY,
    REATTEMPT_MODAL_TITLE,
    RETRY_TEXT,
    SEE_RESPONSES_BUTTON_TEXT,
    TEXTBOX,
    UNLIMITED_ATTEMPTS,
    USER_COMPLETE_MERGED_REATTEMPT,
    USER_COMPLETE_MERGED_RESPONSES,
    USER_COMPLETE_POLL_SUBTITLE,
    YES_TEXT
} from "../../utils/constants";
import { newPageLog, removePrefix } from "../../utils/helpers";
import ClassResultAllMerged from "./ClassResultAllMerged";
import NetworkStatus from "./NetworkStatus";
import "./styles.css";

export default function UserCompleteMergedPoll({
    stopped = false,
    websocketState,
    handleReattempt,
    allowReattempt,
    attemptsCount,
    maxAttempts,
    pollTitle,
    currentPoll,
    institutionId,
    userName,
    pollInfo, 
    pollEndTime,
    handlePollComplete
}) {
    const isNarrow = useMobileAccess(1200);
    const isMobile = useMobileAccess(480);
    const { loading } = useLoading();
    const [showClassResult, setShowClassResult] = useState(false);
    const [canReattempt, setCanReattempt] = useState(false);
    const [feedbackModalShow, setFeedBackModalShow] = useState(false);
    const [showReattemptModal, setShowReattemptModal] = useState(false);

    document.getElementById('root').style.backgroundImage = "none";

    useEffect(() => {
        newPageLog("UserCompletePoll.js");
        if (isNarrow && document.getElementById("viewer-navbar-container") !== null) {
            document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
        }

        document.body.classList.add("waiting-room-background");
        return () => {
            document.body.classList.remove("waiting-room-background");
        };
    }, []);

    useEffect(() => {
        setCanReattempt(allowReattempt && (maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts));
    }, [allowReattempt, attemptsCount, maxAttempts]);

    const onClickReattempt = () => {
        setShowReattemptModal(true);
    };

    const handleClose = () => {
        setShowReattemptModal(false);
    };

    useEffect(() => {
        if (stopped) {
            setShowReattemptModal(false);
        }
    }, [stopped]);

    useEffect(() => {
        const now = Date.now();
        if (now > pollEndTime) {
            handlePollComplete();
        }
    }, [handlePollComplete, pollEndTime]);


    return (
        <div style={{ height: "100%" }}>
            <FeedBackModal
                show={feedbackModalShow}
                setModalShow={setFeedBackModalShow}
                pollKey={removePrefix(currentPoll.questions[0].pollKey)}
                pollUniqueCode={currentPoll.questions[0].pollSortKey.split("#")[2]}
                userId={userName}
                institutionId={institutionId}
            />
            <Modal
                id="reattempt-modal"
                show={showReattemptModal}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={loading ? "static" : true}
                dialogClassName="sa-modal"
            >
                <Spin tip="Loading..." size="large" spinning={loading}>
                    <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={handleClose} className="modal-close" />
                    <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Modal.Title tabIndex={0} role={TEXTBOX}>
                            {REATTEMPT_MODAL_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body tabIndex={0} role={TEXTBOX}>
                        {REATTEMPT_MODAL_BODY}
                    </Modal.Body>
                    <Modal.Footer>
                        <YuJaButton type="secondary" onClick={handleClose}>
                            {NO_TEXT}
                        </YuJaButton>
                        <YuJaButton onClick={handleReattempt}>
                            {YES_TEXT}
                        </YuJaButton>
                    </Modal.Footer>
                </Spin>
            </Modal>
            {showClassResult &&
                <ClassResultAllMerged
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    websocketState={websocketState}
                    pollInfo={pollInfo}
                    pollTitle={pollTitle}
                    userName={userName}
                />
            }
            {!showClassResult && <>
                <div style={{ position: "fixed", height: "100vh", backgroundColor: "#FCFBFF", width: "100vw", zIndex: "-1" }} />
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <div className="waiting-room-title-container" style={{ height: "fit-content", marginTop: window.innerWidth < 480 ? 24 : 50, width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between" }}>
                        <span className="waiting-room-title" tabIndex={0} style={{ wordBreak: "break-word", paddingLeft: !isNarrow ? "170px" : "10px", textAlign: "center", flex: "1" }}>{pollTitle}</span>
                        <div style={{ display: "flex", justifyContent: "end", paddingRight: "20px" }}>  <NetworkStatus websocketState={websocketState} /> </div>
                    </div>
                    <div style={{ marginTop: "120px", width: "100%", display: "flex", justifyContent: "center" }}> <img src={require("../../images/UsercompleteSurvey.gif")} alt="loading..." style={{ height: window.innerWidth < 480 ? "180px" : "300px", width: window.innerWidth < 480 ? "180px" : "300px" }} /></div>
                    <Container>
                        <Row>
                            <Col className="center">
                                <span tabIndex={0} role={TEXTBOX} className="waiting-room-title" style={{ fontSize: isMobile ? "18px" : "30px" }}>
                                    {stopped ? ENDED_POLL_TEXT : USER_COMPLETE_POLL_SUBTITLE}
                                </span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                            <Col className="center">
                                <span tabIndex={0} role={TEXTBOX} className="waiting-room-bottom" style={{ fontSize: isMobile ? "16px" : "18px" }}>
                                    {stopped && USER_COMPLETE_MERGED_RESPONSES}
                                    {canReattempt && !stopped && USER_COMPLETE_MERGED_REATTEMPT}
                                </span>
                            </Col>
                        </Row>

                        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: "10px" }}>
                            {stopped &&
                                <YuJaButton
                                    style={{ fontSize: 14, padding: "8px, 10px, 8px, 10px" }}
                                    id="sub-btn-curr"
                                    onClick={() => setShowClassResult(true)}
                                >
                                    {SEE_RESPONSES_BUTTON_TEXT}
                                </YuJaButton>
                            }
                            {canReattempt && !stopped &&
                                <div style={{ justifyContent: "center", display: "flex" }}>
                                    <YuJaButton
                                        onClick={onClickReattempt}
                                        style={{ fontSize: 14, padding: "8px, 10px, 8px, 10px", width: 125, display: "flex", justifyContent: "center" }}
                                    >
                                        {RETRY_TEXT}
                                    </YuJaButton>
                                </div>
                            }
                        </div>
                    </Container>

                    {!isNarrow &&
                        <div style={{ display: "flex", justifyContent: "center", flex: "1" }}>
                            <div style={{ fontSize: 16, fontWeight: 700, color: "#42296E", textDecoration: "underline", display: "flex", alignItems: "end", paddingBottom: "calc(100vh - 100svh + 40px)", paddingTop: "20px" }}>
                                <div style={{ height: "fit-content", cursor: "pointer" }} onClick={() => setFeedBackModalShow(true)}> {FEEDBACK_BUTTON_TEXT} </div>
                            </div>
                        </div>
                    }
                    {isNarrow &&
                        <div style={{ display: "flex", justifyContent: "center", flex: "1" }}>
                            <div style={{ fontSize: 16, fontWeight: 700, color: "#42296E", textDecoration: "underline", cursor: "pointer", display: "flex", alignItems: "end", paddingBottom: "calc(100vh - 100svh + 20px)", paddingTop: "20px" }}>
                                <div style={{ height: "fit-content", cursor: "pointer" }} onClick={() => setFeedBackModalShow(true)}> {FEEDBACK_BUTTON_TEXT} </div>
                            </div>
                        </div>
                    }
                </div>
            </>}
        </div>
    );
}