import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-resizable/css/styles.css";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { YuJaGlobalStateProvider} from "./utils/LoadingContext";
import {YuJaEventBusProvider} from "./components/standardization/YuJaEventBusProvider";

ReactDOM.render(
    <React.StrictMode>
        <YuJaGlobalStateProvider>
            <YuJaEventBusProvider>
                <App />
            </YuJaEventBusProvider>
        </YuJaGlobalStateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// serviceWorkerRegistration.register();

// if ('serviceWorker' in navigator) {
//   alert('inside index js');
//   window.addEventListener('load', function() {
//     this.alert("registering service worker");
//     navigator.serviceWorker.register('/sw.js');
//     this.alert("registered service worker");
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
