import { React, useEffect, useState } from "react";
import { Spin } from "../../components/standardization/YuJaLoading";
import {
    COMPLETION_RATE_TITLE,
    EXPAND_WINDOW_LABEL,
    MINIMIZE_WINDOW_LABEL,
    PARTICIPANT_SUB_TITLE, PARTICIPANT_SUB_TITLE_EACH_QUESTION, PARTICIPANT_SUB_TITLE_OVER_ALL,
    POLL_SHARE_MODE,
    POLL_STATE,
    TEXTBOX
} from "../../utils/constants";
import { fillFormatText } from "../../utils/helpers";
import { ReactComponent as DropDown } from "./images/DROPDOWN_ICON.svg";
import { ReactComponent as DropUp } from "./images/DROPUP_ICON.svg";
import { ReactComponent as NoParticipantsIcon } from "./images/NO_PARTICIPANTS_ICON.svg";
import { ReactComponent as EngageIcon } from "./images/engage_icon.svg";
import "./styles.css";

export default function ViewParticipants({
                                             data=[],
                                             dataAll={},
                                             pollData=[],
                                             loading=true,
                                             initialized=false,
                                             currQues,
                                             shareMode,
                                             localState,
                                             collapsed,
                                             selectedQuestion,
}) {
    // const location = useLocation();
    const [minimized, setMinimized] = useState(true); // !location?.state?.expandedAnalytics
    const [pollWasShared, setPollWasShared] = useState(false);
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (!initialized || !!loading) {
            return;
        }

        if ([POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData?.poll?.pollShareMode) && !selectedQuestion) {
            setChartData(dataAll);
        } else {
            if (!!data && data.length > 0 && data.length >= currQues) {
                setChartData(data[currQues - 1]);
            } else {
                setChartData({});
            }
        }

    }, [data, dataAll, pollData, currQues, loading, initialized, selectedQuestion]);

    useEffect( () => {
        if (Object.entries(chartData).length === 0) {
            return;
        }


        if(document.getElementById("partsUnasTotal") !== null) {
            let width = chartData.unattempted * 100 / chartData.total;
            document.getElementById("partsUnasTotal").style.width = width + "%";
        }
        if(document.getElementById("partsAnsTotal") !== null) {
            let width = chartData.attempted * 100 /chartData.total;
            document.getElementById("partsAnsTotal").style.width = width + "%";
        }
    }, [chartData, loading, initialized, minimized]);


    const displayParticipants = () => {
        if(shareMode === POLL_SHARE_MODE.SHARE_EACH) {
            return true;
        }

        if(pollWasShared) {
            return true;
        }

        if(localState !== "START POLL") {
            setPollWasShared(true);
            return true;
        }

        if(localState !== "SHARED_ALL" && (shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE)) {
            return true;
        }
        
        if(!!pollData && !!pollData.poll && !!pollData.poll.pollState) {
            const currentTime = new Date();

            if(pollData.poll.pollState === POLL_STATE.SCHEDULED && !!pollData.poll.startTime) {
                if(currentTime > new Date(pollData.poll.startTime)) {
                    return true;
                }
            } 
            else if(pollData.poll.pollState === POLL_STATE.SHARED_ALL || pollData.poll.pollState === POLL_STATE.STOPPED_ALL) {
                return true;
            }
        }
        return false;
    };

    return(<div style={{marginBottom: "15px"}}>
        {minimized && 
            <div className="smallPanelBodyNew" style={{width: "100%"}}>
                <span className="smallPanelTitle" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>Participants</span>
                <DropDown onMouseDown={e => e.preventDefault()} tabIndex={collapsed ? -1 : 0} aria-label={EXPAND_WINDOW_LABEL} style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
            </div>
        }
        {!minimized &&
        <div className="smallPanelBody2" style={{width: "100%"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span className="smallPanelTitle" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>Participants</span>
                <DropUp onMouseDown={e => e.preventDefault()} tabIndex={collapsed ? -1 : 0} aria-label={MINIMIZE_WINDOW_LABEL} style={{cursor: "pointer"}} onClick={() => setMinimized(true)}/>
            </div>
            <span className="smallPanelSubtitle" tabIndex={collapsed ? -1 : 0} role={TEXTBOX} >
                {[POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData?.poll?.pollShareMode) && !selectedQuestion
                    ? PARTICIPANT_SUB_TITLE_OVER_ALL
                    : fillFormatText(PARTICIPANT_SUB_TITLE_EACH_QUESTION, {Question: "Q"+currQues})
                }
            </span>
            <div className="pollCodePanelBody">
            <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}>
                {!loading && initialized && (!chartData || !chartData.total || !displayParticipants()) &&
                    <>
                        <NoParticipantsIcon style={{maxWidth: "100%", maxHeight: "100%"}}/>
                        <span tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>No participants found</span>
                    </>
                }
                {!loading && initialized && displayParticipants() && chartData && chartData.total > 0 &&
                    <div style={{justifyContent: "flex-start", display: "flex", flexDirection: "column", width: "100%"}}>
                        <div className="participantsPanelText" style={{flex: 1, display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div tabIndex={collapsed ? -1 :0} role={TEXTBOX}>{localState === "START POLL" ? "Finished " : "Done "}</div>
                            }
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div style={{color: "#0277AD"}} tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    {chartData.attempted}
                                </div>
                            }
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>{localState === "START POLL" ? "Finished " : "Done "}</div>
                            }

                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div style={{color: "#0277AD"}} tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    {chartData.attempted}
                                </div>
                            }

                        </div>
                        <div className="slidecontainer">
                            <div className="slider2" id="partsAnsTotal">.</div>
                        </div>

                        <div className="participantsPanelText" style={{flex: 1, display: "flex", justifyContent: "space-between", marginTop: "20px", marginBottom: "8px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>{localState === "START POLL" ? "Incomplete " : "In Progress "}</div>
                            }
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div style={{color: "#01BEA3"}} tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    {chartData.unattempted}
                                </div>
                            }

                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>{localState === "START POLL" ? "Incomplete " : "In Progress "}</div>
                            }
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div style={{color: "#01BEA3"}} tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    {chartData.unattempted}
                                </div>
                            }


                        </div>
                        <div className="slidecontainer">
                            <div id="partsUnasTotal" className="slider">.</div>
                        </div>

                        <div className="engagementText" style={{flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "25px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>{COMPLETION_RATE_TITLE}</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>{COMPLETION_RATE_TITLE}</div>}
                            <div className="engagementButton" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}><EngageIcon/>{chartData.enagement}</div>
                        </div>
                    </div>
                }
            </Spin>
            </div>
        </div>
        }
    </div>);
}
