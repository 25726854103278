import React, {createContext, useContext, useEffect, useState} from "react";
import { Detector } from "react-detect-offline";
import {useWindowDimensions} from "react-native-web";

export const YuJaGlobalState = createContext({
    loading: false,
    setLoading: () => {},
    isTokenExpiredModalShowUp: false,
    setIsTokenExpiredModalShowUp: () => {},
    isCookieEnabled: true,
    isOnline: true,
    effectiveType: null,
    missedResponseMap: {},
    setMissedResponseMap: () => {},
    missedReactionMap: {},
    setMissedReactionMap: () => {},
});

export function YuJaGlobalStateProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [effectiveType, setEffectiveType] = useState(null);
    const [missedResponseMap, setMissedResponseMap] = useState({});
    const [missedReactionMap, setMissedReactionMap] = useState({});
    const [isCookieEnabled, setIsCookieEnabled] = useState(window.navigator.cookieEnabled);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    // let {width, height} = useWindowDimensions();
    // const [mainContainerDimension, setMainContainerDimension] = useState({width, height});
    // const [mainExisted, setMainExisted] = useState(false);

    const [isTokenExpiredModalShowUp, setIsTokenExpiredModalShowUp] = useState(false);
    const data = {
        loading,
        setLoading,
        isTokenExpiredModalShowUp,
        setIsTokenExpiredModalShowUp,
        isCookieEnabled,
        isOnline,
        effectiveType,
        missedResponseMap,
        setMissedResponseMap,
        missedReactionMap,
        setMissedReactionMap,
        sidebarCollapsed,
        setSidebarCollapsed
        // mainContainerDimension
    }

    useEffect(() => {
        const {connection, mozConnection, webkitConnection} = window.navigator;

        const conn = connection || mozConnection || webkitConnection;

        if (conn) {
            // Initial setup of effectiveType
            setEffectiveType(conn.effectiveType);

            // Add an event listener to update effectiveType when it changes
            const handleConnectionChange = () => {
                setEffectiveType(conn.effectiveType);
            };

            conn.addEventListener('change', handleConnectionChange);

            // Clean up the event listener when the component unmounts
            return () => {
                conn.removeEventListener('change', handleConnectionChange);
            };
        }
    }, []);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         const element = document.querySelector("main");  // Check if element exists
    //         if (element) {
    //             setMainExisted(true);
    //             clearInterval(intervalId);
    //         }
    //     }, 1000);
    //
    //     return () => {
    //         clearInterval(intervalId);
    //         setMainExisted(false);
    //     }
    // }, []);
    //
    // useEffect(() => {
    //     let mainEle = document.querySelector("main");
    //     const resizeObserver = new ResizeObserver((entries) => {
    //         setMainContainerDimension(entries[0].contentRect);
    //     });
    //
    //     if (mainEle) {
    //         resizeObserver.observe(mainEle);
    //     }
    //
    //     return () => resizeObserver.disconnect(); // clean up
    // }, [mainExisted]);


    return (
        <YuJaGlobalState.Provider value={data} >
            {children}
            <Detector
                //if the default api call not works try replacing with another public one
                polling={{
                    // url: 'https://ipv4.icanhazip.com',
                    // enabled: true,
                    timeout: 50000
                }}
                //
                onChange={(online) => setIsOnline(online)}
                render={() => <></>}
            />
        </YuJaGlobalState.Provider>
    );
}

export function useLoading() {
    const context = useContext(YuJaGlobalState);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}