import { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { BUTTON, MENU_LIST, DEFAULT_ATTENDANCE_MANUAL_END, DEFAULT_ATTENDANCE_TIME_LIMIT, DEFAULT_GET_READY_ALL_TIME_LIMIT, DEFAULT_GET_READY_TIME_LIMIT, DEFAULT_POINTS, DEFAULT_POLL_LMS_SYNC, DEFAULT_POLL_MAX_ATTEMPTS, DEFAULT_POLL_RECORD_ATTEMPTS, DEFAULT_POLL_REQUIRED_NAME, DEFAULT_POLL_SHOW_CORRECT_ANS, DEFAULT_POLL_TIME_LIMIT, DEFAULT_TIME_LIMIT, DRAFT_STATUS, GRADED_POLL, POLL_SHARE_MODE, POLL_TYPE, ROLES, TEXTBOX, USER_SETTINGS } from "../../utils/constants";
import { getDefaultUserSettings, transferConstantPollType, validatePoll } from "../../utils/helpers";
import CreatorActivePolls from "./CreatorActivePolls";
import CreatorModifiedPolls from "./CreatorModifiedPolls";
import CreatorRecentResults from "./CreatorRecentResults";
import CreatorSupportCenter from "./CreatorSupportCenter";
import styles from "./DashboardPage.module.css";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function CreatorDashboard({ setPageIdentifier }) {
    const history = useHistory();
    const { pathname } = useLocation();
    const [, getSession] = useLocalStorageNew("session", {});
    const isMobile = useMobileAccess(950);
    const userId = getSession().userId;
    const [setWarning, getWarning] = useLocalStorageNew("warningShowed", {});
    const [userSettings,  setUserSettings] = useState(getDefaultUserSettings());
    const { getCourseByUser } = CourseApiCalls();
    const { getPollsFromUser, getActivePollsFromUser } = PollApiCalls();


    const [activePollsLoading, setActivePollsLoading] = useState(false);
    const [modifiedPollsLoading, setModifiedPollsLoading] = useState(false);
    const [recentResultLoading, setRecentResultLoading] = useState(false);
    const [activePollsInitialized, setActivePollsInitialized] = useState(false);
    const [modifiedPollsInitialized, setModifiedPollsInitialized] = useState(false);
    const [recentResultInitialized, setRecentResultInitialized] = useState(false);
    const [draftPolls, setDraftPolls] = useState([]);
    const [activePolls, setActivePolls] = useState([]);
    const [publishedPolls, setPublishedPolls] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);

    useEffect(() => {
        setPageIdentifier(MENU_LIST.HOME.name);
    }, []);

    useEffect(() => {
        let warning = getWarning(); 
        if (isMobile && (!warning || !warning[userId])) {
            warning[userId] = true
            setWarning(warning);
            history.push("/warning");
        }
    }, [])

    const getPollInformation = async () => {
        let res = await getPollsFromUser(true);
        res = res.sort((p1, p2) => Date.parse(p2.updatedTimeSK) - Date.parse(p1.updatedTimeSK));
        const data1 = res.filter(p => (p.isValid !== false) && p.pollShareMode === POLL_SHARE_MODE.UNKNOWN);
        const data2 = res.filter(p => ((p.pollSortKey !== DRAFT_STATUS && p.pollShareMode !== POLL_SHARE_MODE.UNKNOWN) && (transferConstantPollType(p.pollType) === POLL_TYPE.MERGED_POLL || p?.pollType === POLL_TYPE.ATTENDANCE)));
        setDraftPolls(data1);
        setPublishedPolls(data2);
        setRecentResultLoading(false);
        setRecentResultInitialized(true);
    };

    const getActivePolls = async () => {
        setActivePollsLoading(true);
        let res = await getActivePollsFromUser();
        console.log(res);
        setActivePolls(res);
        setActivePollsLoading(false);
        setActivePollsInitialized(true);
    };


    const getCoursesOpts = async () => {
        const session = getSession();
        let res;
        if(session.idpType && session.intType) {
            res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
        } else {
            res = await getCourseByUser();
        }

        if(res !== undefined) {
            const options = res.filter(course => course.courseRole && course.courseRole !== ROLES.VIEWER.value)
                .map(course => ({ value: course.courseId, label: `${course.courseCode} : ${course.courseName}` }));
                setCourseOptions(options);
        } else {
            setCourseOptions();
        }
    };

    const createPoll = () => {
        history.push({
            pathname: "/create",
            state: {pollType: POLL_TYPE.MERGED_POLL, fromNavbar: true, previous: pathname}
        });
    };

    useEffect(() => {
        getActivePolls();
        setModifiedPollsLoading(true);
        setRecentResultLoading(true);
        setUserSettings(() => {
            const { settings = {} } = getSession();
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
                settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
                settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            }
            settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
                settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
                settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
                settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
            }
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] == "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
            }
            settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] == "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
            }
            settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] == "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
            }
            settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
            settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
            return settings;
        });
        Promise.all([getPollInformation(), getCoursesOpts()]).finally(() => {
            setModifiedPollsLoading(false);
            setModifiedPollsInitialized(true);
        });
    }, []);


    return (
        <div className={styles.dashBoardContainer}>
            <div className={`${styles.panelContainer} ${styles.createPollPanel}`}>
                <span tabIndex={0} role={TEXTBOX}>To create a new activity, click on the Create Poll button.</span>
                <span tabIndex={0} role={BUTTON} className={styles.creatBtn} onClick={createPoll}><BsPlusLg style={{marginRight: "10px"}}/>Create Poll</span>
            </div>
            <div style={{display: "flex", height: "80%", justifyContent: "space-between"}}>
                <div style={{width: "45%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <CreatorModifiedPolls
                        initialized={modifiedPollsInitialized}
                        loading={modifiedPollsLoading}
                        data={draftPolls}
                        courseOptions={courseOptions}
                        userSettings={userSettings}
                    />
                    <CreatorActivePolls
                        initialized={activePollsInitialized}
                        loading={activePollsLoading}
                        data={activePolls}
                    />
                </div>

                <div style={{width: "52%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <CreatorRecentResults initialized={recentResultInitialized} loading={recentResultLoading} data={publishedPolls}/>
                    <CreatorSupportCenter/>
                </div>
            </div>

        </div>
    );
}