import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { InstitutionApiCalls } from "../../../../apis/InstitutionApiCalls";
import generalIcon from "../../../../images/generalIcon.svg";
import roleMapping from "../../../../images/roleMapping.svg";
import userProvisioning from "../../../../images/userProvisioning.svg";
import crossIntegrationLTI from "../../../../images/crossIntegrationLTI.svg";
import { INTEGRATION_TYPES, GOOGLE_SSO_URL, GOOGLE_CERTIFICATE, GENERAL_SSO, LOGOUT_URL, 
    NAME_FORMAT, REMOTE_LOGOUT, ROLE_MAPPING, ROLE_ATTR, IT_MANAGER_MAPPING, INSTR_MAPPING, 
    LINKAGE_ATTR, ROLES, USER_PROVISION, GIVEN_NAME, EMAIL_ATTR, 
    FAMILY_NAME, INSTRUCTOR, CROSS_INTEGRATION, FALL_BACK_TO_EMAIL, TEXTBOX, QUES_BANK_CHECKBOX } from "../../../../utils/constants";
import styles from "./SAML.module.css";
import {YuJaTextBox} from "../../../../components/standardization/YuJaTextBox";
import { YuJaCheckbox } from "../../../../components/standardization/YuJaCheckbox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function GeneralGoogleTab({setConfigJson, handleSave, ssoURL, setSSOURL, nameIdFormat, setNameIdFormat, remoteLogoutUrl, setRemoteLogouUrl, 
    certificateFingerPrint, setCertificateFingerPrint, logoutUrl, setLogoutUrl, setSignatureEnabled, givenNameAttribute, 
    setGiveNameAttribute, familyNameAttribute, setFamilyNameAttribute, emailAttribute, setEmailAttribute, roleAttribute, 
    setRoleAttribute, itManagerMapping,  setITManagerMapping, instructorMapping, setInstructorMapping, linkageAttribute, setLinkageAttribute, fallbackToEmail, setFallbackToEmail}) {

    const {getSSODetails} = InstitutionApiCalls();
    const [loading , setLoading] = useState(false); 

    const fetchData = () => {
        setLoading(true);
        getSSODetails().then((result) => {
            let ssoMap = result.ssoConfig;
            const ssoConfigData = JSON.parse(ssoMap.hasOwnProperty(INTEGRATION_TYPES.GOOGLE) ? ssoMap.GOOGLE : "{}");
            if(ssoConfigData !== 'undefined' || ssoConfigData.propertyIsEnumerable()) {
                setSSOURL(ssoConfigData.samlSsoUrl);
                setNameIdFormat(ssoConfigData.nameIdFormat);
                setRemoteLogouUrl(ssoConfigData.remoteLogoutUrl);
                setCertificateFingerPrint(ssoConfigData.certificateFingerPrint);
                setLogoutUrl(ssoConfigData.logoutUrl);
                setSignatureEnabled(ssoConfigData.signatureEnabled);
                setGiveNameAttribute(ssoConfigData.givenNameAttribute);
                setFamilyNameAttribute(ssoConfigData.familyNameAttribute);
                setEmailAttribute(ssoConfigData.emailAttribute);
                setRoleAttribute(ssoConfigData.roleAttribute);
                setITManagerMapping(ssoConfigData.itManagerMapping);
                setInstructorMapping(ssoConfigData.instructorMapping);
                setLinkageAttribute(ssoConfigData.linkageAttribute);
                setFallbackToEmail(ssoConfigData.fallbackToEmail);
                setConfigJson(ssoConfigData);
            }
            setLoading(false);
        })
        // setLoading(false);
    }

    useEffect(() => {
        fetchData();
        if(document.getElementById("ssoLastElem") != null) {
            document.getElementById("ssoLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("integrationSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('integrationSaveBtn').focus();
                }
            });
        }
    },[]);

    const handleSSOURLChange = (e) => {
        e.preventDefault();
        setSSOURL(e.target.value);
    };

    const handleNameIDChange = (e) => {
        e.preventDefault();
        setNameIdFormat(e.target.value);
    };

    const handleRemoteLogoutChange = (e) => {
        e.preventDefault();
        setRemoteLogouUrl(e.target.value);
    }

    const handleFingerPrintChange = (e) => {
        e.preventDefault();
        setCertificateFingerPrint(e.target.value);
    }

    const handleLogoutChange = (e) => {
        e.preventDefault();
        setLogoutUrl(e.target.value);
    }

    const handleAuthReqSignChange = (e) => {
        e.preventDefault();
        setSignatureEnabled(e.target.checked);
    }

    const handleGivenNameChange = (e) => {
        e.preventDefault();
        setGiveNameAttribute(e.target.value);
    }

    const handleFamilyNameChange = (e) => {
        e.preventDefault();
        setFamilyNameAttribute(e.target.value);
    }

    const handleEmailChange = (e) => {
        e.preventDefault();
        setEmailAttribute(e.target.value);
    }

    const handleRoleChange = (e) => {
        e.preventDefault();
        setRoleAttribute(e.target.value);
    }

    const handleITManagerMappingChange = (e) => {
        e.preventDefault();
        setITManagerMapping(e.target.value);
    }

    const handleInstructorMappingChange = (e) => {
        e.preventDefault();
        setInstructorMapping(e.target.value);
    }

    const handleLinkageAttributeChange = (e) => {
        e.preventDefault();
        setLinkageAttribute(e.target.value);
    }

    const handleFallbackToEmailChange = (e) => {
        e.preventDefault();
        const input = e.currentTarget;
        setFallbackToEmail(input.checked);
    }

    return (
        <div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 20, flexWrap: "wrap"}}>
                <Card className={styles.smallBox}>
                    <Card.Header>
                    <img src={generalIcon} alt="GeneralSSODetails" width={"24px"} height={"24px"} />
                    <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{GENERAL_SSO}</span>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                        {!loading && 
                            <>
                            <Form.Group controlId="ssoURL">
                                <div className="form-labelReg">{GOOGLE_SSO_URL}*</div>
                                <YuJaTextBox
                                    name="ssoURL"
                                    placeholder={GOOGLE_SSO_URL}
                                    value={ssoURL}
                                    style={{fontSize: 14}}
                                    onChange={handleSSOURLChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="nameIdFormat">
                                <div className="form-label">{NAME_FORMAT}*</div>
                                <YuJaTextBox
                                    name="nameIdFormat"
                                    placeholder={NAME_FORMAT}
                                    value={nameIdFormat}
                                    style={{fontSize: 14}}
                                    onChange={handleNameIDChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="remoteLogoutUrl">
                                <div className="form-label">{REMOTE_LOGOUT}</div>
                                <YuJaTextBox
                                    name="remoteLogoutUrl"
                                    value={remoteLogoutUrl}
                                    style={{fontSize: 14}}
                                    onChange={handleRemoteLogoutChange}
                                    placeholder={REMOTE_LOGOUT}
                                />
                            </Form.Group>

                            <Form.Group controlId="certificateFingerPrint">
                                <div className="form-label">{GOOGLE_CERTIFICATE}</div>
                                <YuJaTextBox
                                    name="certificateFingerPrint"
                                    value={certificateFingerPrint}
                                    style={{fontSize: 14}}
                                    onChange= {handleFingerPrintChange}
                                    placeholder={GOOGLE_CERTIFICATE}
                                />
                            </Form.Group>

                            <Form.Group controlId="logoutUrl">
                                <div className="form-label">{LOGOUT_URL}</div>
                                <YuJaTextBox
                                    name="logoutUrl"
                                    value={logoutUrl}
                                    style={{fontSize: 14}}
                                    onChange={handleLogoutChange}
                                    placeholder={LOGOUT_URL}
                                />
                            </Form.Group>
                            </>
                        }
                            {
                            loading &&  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                            <div style={{display: "flex", flexDirection: "column"}}> 
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            </div>
                            </SkeletonTheme>
                        }
                        </Form>
                    </Card.Body>
                </Card>
                <Card className={styles.smallBox}>
                    <Card.Header>
                        <img src={userProvisioning} alt="UserProvisioning" width={"24px"} height={"24px"} />
                        <span tabIndex={0}  role={TEXTBOX} className={styles.cardHeader}>{USER_PROVISION}</span>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                        {!loading && 
                            <>
                            <Form.Group controlId="givenNameAttribute">
                                <div className="form-labelReg">{GIVEN_NAME}</div>
                                <YuJaTextBox
                                    name="givenNameAttribute"
                                    value={givenNameAttribute}
                                    style={{fontSize: 14}}
                                    onChange={handleGivenNameChange}
                                    placeholder={GIVEN_NAME}
                                />
                            </Form.Group>

                            <Form.Group controlId="familyNameAttribute">
                                <div className="form-label">{FAMILY_NAME}</div>
                                <YuJaTextBox
                                    name="familyNameAttribute"
                                    value={familyNameAttribute}
                                    style={{fontSize: 14}}
                                    onChange={handleFamilyNameChange}
                                    placeholder={FAMILY_NAME}
                                />
                            </Form.Group>

                            <Form.Group controlId="emailAttribute">
                                <div className="form-label">{EMAIL_ATTR}</div>
                                <YuJaTextBox
                                    name="emailAttribute"
                                    value={emailAttribute}
                                    style={{fontSize: 14}}
                                    onChange={handleEmailChange}
                                    placeholder={EMAIL_ATTR}
                                />
                            </Form.Group>

                            <Form.Group controlId="roleAttribute">
                                <div className="form-label">{ROLE_ATTR}</div>
                                <YuJaTextBox
                                    name="roleAttribute"
                                    value={roleAttribute}
                                    style={{fontSize: 14}}
                                    onChange={handleRoleChange}
                                    placeholder={ROLE_ATTR}
                                />
                            </Form.Group>
                            </>
                        }
                            {
                            loading &&  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                            <div style={{display: "flex", flexDirection: "column"}}> 
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            </div>
                            </SkeletonTheme>
                        }
                        </Form>
                    </Card.Body>
                </Card>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Card className={styles.smallBox}>
                        <Card.Header>
                            <img src={roleMapping} alt="RoleMapping" width={"24px"} height={"24px"} />
                            <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{ROLE_MAPPING}</span>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                            {!loading && 
                            <>
                                <Form.Group controlId="itManagerMapping">
                                    <div className="form-labelReg">{ROLES.IT_MANAGER.display}</div>
                                    <YuJaTextBox
                                        name="itManagerMapping"
                                        value={itManagerMapping}
                                        style={{fontSize: 14}}
                                        onChange={handleITManagerMappingChange}
                                        placeholder={IT_MANAGER_MAPPING}
                                    />
                                </Form.Group>

                                <Form.Group controlId="instructorMapping">
                                    <div className="form-label">{INSTRUCTOR}</div>
                                    <YuJaTextBox
                                        name="instructorMapping"
                                        value={instructorMapping}
                                        style={{fontSize: 14}}
                                        onChange={handleInstructorMappingChange}
                                        placeholder={INSTR_MAPPING}
                                    />
                                </Form.Group>
                                </>
                        }
                            {
                            loading &&  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                            <div style={{display: "flex", flexDirection: "column"}}> 
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            </div>
                            </SkeletonTheme>
                        }
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card className={styles.smallBox}>
                        <Card.Header>
                            <img src={crossIntegrationLTI} alt="CrossIntegrationLTI" width={"24px"} height={"24px"} />
                            <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{CROSS_INTEGRATION}</span>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                            {!loading && 
                            <>
                                <Form.Group controlId="linkageAtrributeGrp">
                                    <div className="form-labelReg">{LINKAGE_ATTR}</div>
                                    <YuJaTextBox
                                        name="linkageAttribute"
                                        value={linkageAttribute}
                                        style={{fontSize: 14}}
                                        onChange={handleLinkageAttributeChange}
                                        placeholder={LINKAGE_ATTR}
                                    />
                                </Form.Group>
                                <Form.Group controlId="fallbackToEmail" style={{margin: "10px 0 0 0", fontSize: "14px"}}>
                                    <YuJaCheckbox ariaLabel={QUES_BANK_CHECKBOX + FALL_BACK_TO_EMAIL + " " + (fallbackToEmail ? "checked" : "unchecked")} id="ssoLastElem" name="fallbackToEmail" checked={fallbackToEmail} label={FALL_BACK_TO_EMAIL} onClick={handleFallbackToEmailChange}/>
                                </Form.Group>
                                </>
                        }
                            {
                            loading &&  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                            <div style={{display: "flex", flexDirection: "column"}}> 
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                            </div>
                            </SkeletonTheme>
                        }
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}