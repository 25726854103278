import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function LTIDeepLinkingAutoSubmitForm() {
    const location = useLocation();
  const [url, setUrl] = useState("");
  const [jwt, setJwt] = useState("");

  useEffect(() => {
    if (location.state && location.state.url) {
      setUrl(location.state.url);
      setJwt(location.state.jwt);
    }
  }, [location.state]);

  useEffect(() => {
    if(url) {
        console.log(url);
        document.getElementById('deepLinkForm').submit();
    }
  }, [url]);

  return (
    <>
      {url && jwt && (
        // <html>
        // <head>
        //     <script type="text/javascript" src="./LTIDeepLinkingAutoSubmitFormScript.js">
        //         window.onload = doOnLoad();
        //     </script>
        // </head>
        // <body>
        <div id="autoSubmitForm">
          <form
            id="deepLinkForm"
            action={url}
            method="POST"
            encType="application/x-www-form-urlencoded"
          >
            <input type="hidden" name="JWT" value={jwt} />
          </form>
        </div>
        // </body>
        //  </html>
      )}
    </>
  );
}
