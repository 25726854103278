import PropTypes from "prop-types";
import React, { Component } from "react";
// import { ReactComponent as BlueCircle } from "../../images/blue_circle.svg";
// import { ReactComponent as FushiaOctagon } from "../../images/fushia_octagon.svg";
// import { ReactComponent as GreenDiamond } from "../../images/green_diamond.svg";
// import { ReactComponent as NavyPentagon } from "../../images/navy_pentagon.svg";
// import { ReactComponent as OrangeSquare } from "../../images/orange_square.svg";
// import { ReactComponent as PinkTriangle } from "../../images/pink_triangle.svg";
// import { ReactComponent as PurpleTrapezoid } from "../../images/purple_trapezoid.svg";
// import { ReactComponent as RedRectangle } from "../../images/red_rectangle.svg";
// import { ReactComponent as TealHexagon } from "../../images/teal_hexagon.svg";
import { TEXTBOX } from "../../utils/constants";
import { YuJaRadioBox } from "../standardization/YuJaRadiobox";
import "../styles.css";
import "./radio.css";

export class Radio extends Component {
  state = {};
  Pin
  render() {
    // let accessibilityIcons = [
    //   {
    //     src: <PinkTriangle style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <OrangeSquare style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <GreenDiamond style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <BlueCircle style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <NavyPentagon style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <RedRectangle style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <FushiaOctagon style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <PurpleTrapezoid style={{ width : "3.5vw"}}/>
    //   },
    //   {
    //     src: <TealHexagon style={{ width : "3.5vw"}}/>
    //   }
    // ];
    const { selected, onChange, value, ariaLabel, text, transform="", disabled=false, gradeEnable=true} = this.props;
    return (
      <div
        className="modern-radio-container"
        onClick={() => {
          if (disabled) {
              return;
          }
          onChange(value);
        }}
      >
        {!!gradeEnable &&
            <YuJaRadioBox aria-label={ariaLabel + " " + (value === selected ? "selected" : "unselected")} size={28} checked={value === selected} disabled={disabled} name={"optionRadio"} style={{ transform: transform, zIndex: 10}}/>
        }

        <span className="helper-text" role={TEXTBOX} aria-label={"Option " + text} style={{textAlign: "center", marginLeft: transform !== "" ? (!gradeEnable ? 0 : "-28px") : ""}}>{text}</span>
        {/* <div className="accessibilityIcon" id={`icon-${index}`}>
          {index !== undefined ? accessibilityIcons[index % 9].src : ""}
        </div> */}
      </div>
    );
  }
}

Radio.propTypes = {
  onChange: PropTypes.func.isRequired
};
