import { Spin } from "../../components/standardization/YuJaLoading";
import "antd/dist/antd.min.css";
import 'rc-datepicker/lib/style.css';
import { DatePickerInput } from 'rc-datepicker';
import React from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import "./modal.css";
import { validCustomPollTime } from "../../utils/questionUtils";
import useMobileAccess from "../../hooks/useMobileAccess";
import { useState, } from "react";
import YuJaButton from "../standardization/YuJaButton";
import {YuJaTextBox} from "../standardization/YuJaTextBox";
import "react-datepicker/dist/react-datepicker.css";
import {ReactComponent as TimerIcon} from "../../images/Timer.svg";
import { GRADED_POLL, BUTTON, CLOSE_MODAL, ACTIVATE_TIME, SELECT_LABEL, MODAL, TEXT_BOX_MODAL, TEXTBOX, SCHEDULE_POLL_OPTION, SCHEDULE_POLL_TIME_MESSAGE, PARTICIPANT_TIME_INTRO, POLL_LENGTH_SETTING_DESCRIPTION } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import DatePickerComponentNew from "../datePickerComponent";
import { timePollCustomToSeconds } from "../../utils/questionUtils";
import { INVALID_SCHEDULE_END_DATE_ERROR, INVALID_SCHEDULE_END_TIME_ERROR, INVALID_SCHEDULE_START_TIME_ERROR, INVALID_TIME_LIMIT_ERROR, MISSING_SCHEDULE_START_END_TIME_ERROR, SCHEDULE_POLL_START_END_GAP_ERROR } from "../../utils/toast-message-constants";


//isLoading is default to false, should be used when it requires to fetch data
export default function FormModal({ modalConfig, isLoading = false, setModalShow, show, pollTimeLimit = "00:30:00", setPollTimeLimit, setShareAll, shareAll, isWarning, startDate, endDate, setStartDate, setEndDate}) {
  const [ shareInCourse, setShareInCourse ] = useState(false);
  const [ pollTimeLimitH, setPollTimeLimitH] = useState(pollTimeLimit.substring(0,2));
  const [ pollTimeLimitM, setPollTimeLimitM] = useState(pollTimeLimit.substring(3,5));
  const [ pollTimeLimitS, setPollTimeLimitS] = useState(pollTimeLimit.substring(6));


  const isNarrow = useMobileAccess(1100);


  const handleClose = () =>{
    setModalShow(false);
    modalConfig.clearForm();
  };


  // const handlePollTimeChangeH = (e) => {
  //   let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
  //   document.getElementById("error2").innerText = "";
  //   setPollTimeLimitH(hours);

  //   if(hours === "") {
  //     hours = "00";
  //   } else if(hours.length === 1) {
  //     hours = "0"+hours;
  //   }
  //   setPollTimeLimit(hours + pollTimeLimit.substring(2));
  // };

  const handlePollTimeChangeH = (e) => {
    let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
    document.getElementById("error2").innerText = "";
    setPollTimeLimitH(hours);
  };

  const handlePollTimeonBlurH = (e) => {
    let hours = pollTimeLimitH; 
    const isValidNumber = /^\d+$/.test(pollTimeLimitH);
    if(isValidNumber && !isNaN(parseInt(pollTimeLimitH, 10)) && parseInt(pollTimeLimitH, 10) <= 24 )
    {
    
    if(hours === "") {
      hours = "00";
    } else if(hours.length === 1) {
      hours = "0"+hours;
    }
    setPollTimeLimitH(hours);
    setPollTimeLimit(hours + pollTimeLimit.substring(2));
    if(parseInt(pollTimeLimitH, 10) === 0){
        setPollTimeLimitH("00");
    }
    }
    else {
        setPollTimeLimitH("00");
    }
  }

  // const handlePollTimeChangeM = (e) => {
  //   let mins = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
  //   document.getElementById("error2").innerText = "";
  //   setPollTimeLimitM(mins);

      
  //   if(mins === "") {
  //     mins = "00";
  //   } else if(mins.length === 1) {
  //     mins = "0"+mins;
  //   }
  //   setPollTimeLimit(pollTimeLimit.substring(0,3) + mins + pollTimeLimit.substring(5));
  // };

  const handlePollTimeChangeM = (e) => {
    let mins = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
    document.getElementById("error2").innerText = "";
    setPollTimeLimitM(mins);

  };

  const handlePollTimeonBlurM = (e) => {
    let mins = pollTimeLimitM; 
    const isValidNumber = /^\d+$/.test(pollTimeLimitM);
    if(isValidNumber && !isNaN(parseInt(pollTimeLimitM, 10)))
    {        
        let unroundedMins = (mins < 10 ? "0" + mins.toString() : mins.toString());
        let hours = Math.floor(parseInt(mins, 10) / 60); 
        mins = parseInt(mins, 10) % 60; 
        if(hours + parseInt(pollTimeLimitH, 10) > 24) {
            unroundedMins = (mins < 10 ? "0" + mins.toString() : mins.toString());
        }
        hours = hours + parseInt(pollTimeLimitH, 10) > 24 ? 24 : hours + parseInt(pollTimeLimitH, 10);

        if (mins === "") {
            mins = "00";
        } else if (mins.length === 1) {
            mins = "0" + mins;
        }
        setPollTimeLimitM((mins < 10 ? "0" : "") + mins)
        setPollTimeLimitH((hours < 10 ? "0" : "") + hours);
        setPollTimeLimit(pollTimeLimit.substring(0, 3) + unroundedMins + ":00");
        if (parseInt(pollTimeLimitM, 10) === 0) {
            setPollTimeLimitM("00");
        }
    }
    else {
        setPollTimeLimitM("00");
    }
  }

  // const handlePollTimeChangeS = (e) => {
  //   let secs = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
  //   document.getElementById("error2").innerText = "";
  //   setPollTimeLimitS(secs);

  //   if(secs === "") {
  //     secs = "00";
  //   } else if(secs.length === 1) {
  //     secs = "0"+secs;
  //   }
  //   setPollTimeLimit(pollTimeLimit.substring(0,6) + secs);
  // };

  const validateForm = () => {
    if(shareInCourse) {
      if(modalConfig.form[0].value.length === 0) {
        document.getElementById("error1").innerText = "Please select a course!";
        return false;
      } else {
        document.getElementById("error1").innerText = "";
      }
    }
    if(shareAll && modalConfig.activatePollType === GRADED_POLL) {
      setPollTimeLimitH(pollTimeLimit.substring(0,2));
      setPollTimeLimitM(pollTimeLimit.substring(3,5));
      setPollTimeLimitS(pollTimeLimit.substring(6));
      if(!validCustomPollTime(pollTimeLimit)) {
        document.getElementById("error2").innerText = "Please enter a valid poll time that is 10+ seconds!";
        return false;
      }
    }
    if (!startDate && !endDate && shareAll === 2) {
        notifyError(MISSING_SCHEDULE_START_END_TIME_ERROR);
        return false;
    }
    if (shareAll === 2 && endDate < new Date()) {
      notifyError(INVALID_SCHEDULE_END_TIME_ERROR);
      return false ;
    }
    if (shareAll === 2 && startDate < new Date() ) {
      notifyError(INVALID_SCHEDULE_START_TIME_ERROR);
      return false ;
    }
    if (shareAll === 2 && endDate < startDate ) {
      notifyError(INVALID_SCHEDULE_END_DATE_ERROR);
      return false ;
    }
    if (shareAll === 2 && ((endDate > startDate.getTime() + 30 * 24 * 60 * 60 * 1000))) {
      notifyError(SCHEDULE_POLL_START_END_GAP_ERROR);
      return false ;
    }
    if (shareAll === 2 && ((endDate - startDate)/1000 <  timePollCustomToSeconds(pollTimeLimit))) {
      notifyError(INVALID_TIME_LIMIT_ERROR); 
      return false; 
    }
    return true;
  };


  return (
    <Modal
      id="form-modal"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={isLoading ? "static" : true}
      dialogClassName= {"ac-modal"}
    >
      <Spin tip="Loading..." size="large" spinning={isLoading}>
        <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={handleClose} className="modal-close" />
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title
            className="modal-title"
            id="contained-modal-title-vcenter"
            tabIndex={0}
            aria-label={modalConfig.title + MODAL}
            role={TEXTBOX}
          >
            {modalConfig.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="customized-form" onSubmit={e => e.preventDefault()}>
            {modalConfig?.form[0]?.type !== 'select5' &&
              <div style={{ marginBottom: "25px" }}>
                <Form.Text tabIndex={0} role={TEXTBOX} className="form-subtext">
                  {modalConfig.subText}
                </Form.Text>
              </div>
            }

            {modalConfig.form.map((formItem, idx) => {
              if (formItem.type === 'select2') {
                return (
                    <Form.Group style={{ marginBottom: "0.938rem" }} key={idx}>
                      <div tabIndex={0} role={TEXTBOX} className="activatePollText">{formItem.label}</div>
                      <div className="form-check" style={{marginTop: "10px"}}>
                        <input className="form-check-input course-modal-input" type="radio" id="defaultCheck0" checked={!shareInCourse} onChange={() => {setShareInCourse(false); formItem.setFunction([]);}}/>
                        <label className="form-check-label" htmlFor="defaultCheck0">{formItem.subOptions[0]}</label>
                      </div>
                      <div className="form-check" style={{marginBottom: "10px"}}>
                        <input className="form-check-input course-modal-input" type="radio" id="defaultCheck1" checked={shareInCourse} onChange={() => {setShareInCourse(true)}}/>
                        <label className="form-check-label" htmlFor="defaultCheck1">{formItem.subOptions[1]}</label>
                      </div>

                      {shareInCourse && <>
                      <Select
                          options={formItem.options}
                          onChange={formItem.setFunction}
                          value={formItem.value}
                          isMulti={formItem.isMulti}
                          isInvalid={!!formItem.error}
                      />
                      <div className="activatePollError" id="error1"></div>
                      </>}
                    </Form.Group>

                );
              } else if(formItem.type === "select3") {
                return (
                  <div style={{ marginBottom: "0.938rem", marginTop: "30px" }} key={idx}>
                    <div tabIndex={0} role={TEXTBOX} className="activatePollText">{formItem.label}</div>
                    <div className="form-check" style={{marginTop: "10px"}}>
                      <input className="form-check-input course-modal-input" type="radio" id="exampleRadios1" checked={!shareAll} onChange={() => {setShareAll(0)}}/>
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        Let me choose when to release questions
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input course-modal-input" type="radio" id="exampleRadios2" checked={shareAll === 1} onChange={() => {setShareAll(1)}}/>
                      <label className="form-check-label" htmlFor="exampleRadios2">
                        Release all questions together
                      </label>
                    </div>
                    {modalConfig.activatePollType === GRADED_POLL ?
                    <div className="form-check">
                      <input className="form-check-input course-modal-input" type="radio" id="exampleRadios3" checked={shareAll === 2} onChange={() => {setShareAll(2)}}/>
                      <label className="form-check-label" htmlFor="exampleRadios3">
                        {SCHEDULE_POLL_OPTION}
                      </label>
                    </div>
                    : null }

                    {shareAll && modalConfig.activatePollType === GRADED_POLL ? <>
                      <div className="form-group" style={{marginTop: "15px"}}>
                      { shareAll === 2?
                        <DatePickerComponentNew  startDate={startDate} endDate={endDate} setStartDate= {setStartDate} setEndDate={setEndDate}/> : <></> }
                        <div style={{width: "100%", background: "white", display: "flex", marginTop: "25px", justifyContent: "space-between"}}>
                            <div tabIndex={0} role={TEXTBOX} aria-label={ACTIVATE_TIME} style={{color: "#000",  fontSize: "13.5px", fontWeight: "400", display: "flex", alignItems: "center"}}>
                            {SCHEDULE_POLL_TIME_MESSAGE}
                            </div>
                            <div style={{width: "225px", height: "35px", borderRadius: "3.204px", border: "1px solid #D0D0D0", background: "#FFF", alignItems: "center", display: "flex", padding: "5.394px 11.549px 5.606px 11.451px"}}>
                            <input
                              aria-label={pollTimeLimitH + " hours"}
                              role={TEXTBOX}
                              className="custom-input2"
                              type="text"
                              value={pollTimeLimitH}
                              onChange={handlePollTimeChangeH}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "17px"}}
                            />
                             <div style={{alignItems: "end", justifyContent: "center", display: "flex", height: "21px"}}> : </div>
                            <input
                              aria-label={pollTimeLimitM + " minutes"}
                              role={TEXTBOX}
                              className="custom-input2"
                              type="text"
                              value={pollTimeLimitM}
                              onChange={handlePollTimeChangeM}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "17px"}}
                            />
                             {/* <div style={{alignItems: "end", justifyContent: "center", display: "flex", height: "21px"}}> : </div>
                            <input
                              role={TEXTBOX}
                              aria-label={pollTimeLimitS + " seconds"}
                              className="custom-input2"
                              type="text"
                              value={pollTimeLimitS}
                              onChange={handlePollTimeChangeS}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "17px"}}
                            /> */}
                            <div style={{flex: "1", justifyContent: "end", display: "flex"}}>
                            <TimerIcon />
                            </div>
                            </div>
                        </div>
                      </div>
                      <div className="activatePollError" id="error2"></div>
                    </>: null}
                  </div>
                );
              } else if (formItem.type === 'select4') {
                return (
                  <Form.Group style={{ marginBottom: "0.938rem" }} key={idx}>
                    <Form.Label tabIndex={0} role={TEXTBOX} className="form-label" style={{marginTop: 10}}>
                      {formItem.label}
                    </Form.Label>
                    <Select
                          aria-label={SELECT_LABEL + formItem.label}
                          options={formItem.options}
                          onChange={formItem.setFunction}
                          value={formItem.value}
                          isMulti={formItem.isMulti}
                          isInvalid={!!formItem.error}
                      />
                    {!!formItem.error && <p style={{ color: "red" }}>{formItem.error}</p>}
                  </Form.Group>
                );
              } else if(formItem.type === "select5") {
                return (
                  <div style={{ margin: "0.938rem 0" }} key={idx}>
                    <div tabIndex={0} role={TEXTBOX} className="activatePollText" style={{fontWeight: 400, fontSize: "13px"}}>{formItem.label}</div>
                    {modalConfig.activatePollType === GRADED_POLL ?
                    <div className="form-check">
                      <input className="form-check-input course-modal-input" type="radio" id="exampleRadios3" checked={shareAll === 2} onChange={() => {setShareAll(2)}}/>
                      <label className="form-check-label" htmlFor="exampleRadios3">
                        {SCHEDULE_POLL_OPTION}
                      </label>
                    </div>
                    : null }

                    {shareAll && modalConfig.activatePollType === GRADED_POLL ? <>
                      <div className="form-group" style={{marginTop: "15px"}}>
                      { shareAll === 2?
                        <DatePickerComponentNew  startDate={startDate} endDate={endDate} setStartDate= {setStartDate} setEndDate={setEndDate}/> : <></> }
                        <div style={{width: "100%", background: "white", display: "flex", marginTop: "25px", gap: "5px"}}>
                            <div tabIndex={0} role={TEXTBOX} aria-label={ACTIVATE_TIME} style={{color: "#000",  fontSize: "13.5px", fontWeight: "400", display: "flex", marginTop: "2px"}}>
                            {PARTICIPANT_TIME_INTRO}
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}> 
                            <input
                              aria-label={pollTimeLimitH + " hours"}
                              role={TEXTBOX}
                              className="custom-input-setting"
                              type="text"
                              value={pollTimeLimitH}
                              onChange={handlePollTimeChangeH}
                              onBlur={handlePollTimeonBlurH}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px"}}
                            />
                            <div style={{fontSize: "13px",  fontWeight: "400", textAlign: "center"}}> Hours </div>
                            </div> 
                             <div style={{alignItems: "end", justifyContent: "center", display: "flex", height: "21px"}}> : </div>
                             <div style={{display: "flex", flexDirection: "column"}}> 
                            <input
                              aria-label={pollTimeLimitM + " minutes"}
                              role={TEXTBOX}
                              className="custom-input-setting"
                              type="text"
                              value={pollTimeLimitM}
                              onChange={handlePollTimeChangeM}
                              onBlur={handlePollTimeonBlurM}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px"}}
                            />
                            <div style={{fontSize: "13px",  fontWeight: "400", textAlign: "center"}}> Minutes </div>
                            </div>
                             {/* <div style={{alignItems: "end", justifyContent: "center", display: "flex", height: "21px"}}> : </div> */}
                            {/* <input
                              role={TEXTBOX}
                              aria-label={pollTimeLimitS + " seconds"}
                              className="custom-input2"
                              type="text"
                              value={pollTimeLimitS}
                              onChange={handlePollTimeChangeS}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "17px"}}
                            /> */}
                            <div tabIndex={0} role={TEXTBOX} aria-label={ACTIVATE_TIME} style={{color: "#000",  fontSize: "13.5px", fontWeight: "400", display: "flex", marginTop: "2px"}}>
                            {POLL_LENGTH_SETTING_DESCRIPTION}
                            </div>
                        </div>
                      </div>
                      <div className="activatePollError" id="error2"></div>
                    </>: null}
                  </div>
                );
              } else {
                return (
                    <Form.Group style={{ marginBottom: "0.938rem" }} key={idx}>
                      <Form.Label className="form-label">
                        {formItem.label}
                      </Form.Label>
                      {formItem.type === "date" ? 
                         <DatePickerInput
                            displayFormat='DD-MM-YYYY'
                            style={{padding : "20px"}}
                            placeholder="DD-MM-YYYY"
                            onChange={value => {
                                if(!isNaN(value)){
                                  formItem.setFunction(value.toISOString().split('T')[0]);
                                } else {
                                  formItem.setFunction("");
                                }
                            }}
                            value={formItem.value || ""}
                        /> :
                          <YuJaTextBox
                              label={TEXT_BOX_MODAL + formItem.label}
                              containerStyle={{width: "90%"}}
                              value={formItem.value || ""}
                              onChange={(e) => formItem.setFunction(e.target.value)}
                              type={formItem.type}
                              isInvalid={!!formItem.error}
                              errorMsg={formItem.error}
                          />
                      }
                      <Form.Control.Feedback type="invalid">
                        {formItem.error}
                      </Form.Control.Feedback>
                    </Form.Group>
                );
              }

            })}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {isNarrow && modalConfig.leftButtonText &&
              <YuJaButton
                onClick={(e) => {
                  e.preventDefault();
                  modalConfig.left();
                }}
              >
                  {modalConfig.leftButtonText}
              </YuJaButton>
          }
          {
            modalConfig.showOtherButton &&
            <YuJaButton
              onClick={(e) => {
                e.preventDefault();
                modalConfig.otherButtonSubmit();
              }}
            >
              {modalConfig.otherButtonText}
          </YuJaButton>
          }
          <YuJaButton type="secondary"  onClick={handleClose}>Cancel</YuJaButton>
          <YuJaButton
              onClick={(e) => {
                e.preventDefault();
                if(validateForm()) {
                  modalConfig.submit();
                }
              }}
          >{modalConfig.submitButtonText}</YuJaButton>
        </Modal.Footer>
      </Spin>
    </Modal>
  );
}
