import styles from "./Home.module.css";
import stylesDashboard from "./../DashboardPage/DashboardPage.module.css";
import {ReactComponent as NoResultIllustration} from "../DashboardPage/images/no_active_polls_found_illustration.svg";
import {ReactComponent as ActivePollIcon} from "../DashboardPage/images/gradient_radar_icon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import "react-loading-skeleton/dist/skeleton.css";
import React, {useState, useEffect } from "react";
import useMobileAccess from './../../hooks/useMobileAccess';
import { useHistory} from "react-router-dom";
import WarningModal from "../../components/modals/WarningModal";
import {ACTIVE_POLLS_LABEL, ACTIVE_POLLS_LABEL_2, TEXTBOX } from "../../utils/constants";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { formatErrorLog, newPageLog, notifyError } from "../../utils/helpers";
import {PollApiCalls} from "../../apis/PollApiCalls";
import { LMS_RESTRICTED_ERROR } from "../../utils/toast-message-constants";

export default function StudentActivePolls({data=[], loading=true, initialized=false}) {
    const isMobile = useMobileAccess(950);
    const history = useHistory();

    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession();
    const gradedLink = session.gradedLink; 
    const idpType = session.idpType; 

    const {getSetting} = InstitutionApiCalls();

    const {getPollRecordFromUniqueCode} = PollApiCalls();

    const [code, setCode] = useState(null);

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
    useEffect(() => {
        newPageLog("StudentActivePolls.js");
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleClick = async (code) => {
        const result = await getPollRecordFromUniqueCode(code);
        const {lmsHomeUrl, syncLms=true} = result.poll;
        if (!!lmsHomeUrl && !gradedLink && syncLms) {
            history.push(`/lti/${code}/guide`);
        } else {
            history.push(`/join/${code}`);
        }
      };
    

    return (
        <div className={stylesDashboard.panelContainer} style={{height: "29%"}}>
            <div tabIndex={0} className={stylesDashboard.panelTitle} role={TEXTBOX}>
                <ActivePollIcon style={{marginRight: "10px"}}/> Active Activities
            </div>
            {!loading ? 
            <div className={stylesDashboard.panelBody}>
                {/* <Spin size={isMobile? "medium" : "large"} wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}> */}
                    {initialized && (!data || !data.length) &&
                        <div style={{display: "flex",  justifyContent: "center", alignItems: "center"}}>
                            <NoResultIllustration className={stylesDashboard.activePollIllustration} style={{marginRight: "10px"}} />
                            There are currently no active activities to join
                        </div>
                    }
                    {initialized && (!!data && !!data.length) &&
                        <div className={stylesDashboard.modifiedPollContainer}>
                            {data.map((poll, index) => (
                                <div key={index} className={styles.courseTitle}>
                                    <p tabIndex={0} aria-label={ACTIVE_POLLS_LABEL + poll.pollTitle} className={styles.courseTitle}>{poll.pollTitle}</p>
                                    <p tabIndex={0} aria-label={ACTIVE_POLLS_LABEL_2 + poll.index3Pk.substring(3)} className={styles.gradientText} onClick={() => handleClick(poll.index3Pk.substring(3))} 
                                    style={{ cursor: 'pointer'}}>{poll.index3Pk.substring(2)}</p>
                                </div>
                            ))}
                        </div>
                    }
                {/* </Spin> */}
            </div>
            : windowSize[0] >= 960 && windowSize[1] <= 800 ?
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6.5vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6vw"  style={{ position: "relative", marginTop:"10px"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            </SkeletonTheme> </div>

            : windowSize[0] >= 960 && windowSize[1] <= 900 ?
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6.5vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6vw"  style={{ position: "relative", marginTop:"10px"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            </SkeletonTheme> </div>
            : windowSize[0] >= 960 && windowSize[1] <= 1065 ?
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6.5vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6vw"  style={{ position: "relative", marginTop:"10px"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            </SkeletonTheme> </div>
            :  
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6.5vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375} width = "6vw"  style={{ position: "relative", marginTop:"10px"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            <Skeleton borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"10px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton borderRadius={10} height = {10} width = "6vw" style = {{position: "relative", marginTop:"10px"}} />
            </div>
            </SkeletonTheme> </div>
             }
        </div>
    );
}
