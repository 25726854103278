import {Modal} from "react-bootstrap";
import React, {useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
    LOGIN_PATH,
    YUJA_TOKEN_EXPIRED_ALERT_CONTENT,
    YUJA_TOKEN_EXPIRED_ALERT_OK_BUTTON,
    YUJA_TOKEN_EXPIRED_ALERT_TITLE
} from "../../utils/constants";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import YuJaButton from "../standardization/YuJaButton";
import { logoutOfChromeExtensions } from "../../utils/helpers";

export default function YuJaTokenExpiredAlert() {
    const history = useHistory();
    const location = useLocation();
    const {isTokenExpiredModalShowUp, setIsTokenExpiredModalShowUp} = useContext(YuJaGlobalState);
    const [setSession, ] = useLocalStorageNew("session", {});

    return (
        <Modal
            id="form-modal"
            show={isTokenExpiredModalShowUp}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={"static"}
        >
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title className="modal-title" id="contained-modal-title-vcenter">{YUJA_TOKEN_EXPIRED_ALERT_TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-subtext">{YUJA_TOKEN_EXPIRED_ALERT_CONTENT}</div>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton
                    onClick={(e) => {
                        e.preventDefault();
                        setIsTokenExpiredModalShowUp(false);
                        logoutOfChromeExtensions();
                        history.push(LOGIN_PATH, {from: location.pathname});
                        setSession({});
                    }}
                >{YUJA_TOKEN_EXPIRED_ALERT_OK_BUTTON}</YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}