import React from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { BLANK_REGEX, BLANK_REGEX_NEW, BLANK_REGEX_WRAPPED, BLANK_REGEX_WRAPPED_NEW } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";

export default function YuJaFITBPreviewModal({config, queTitle, show, sumbitButtonShow=true, hasBlankRestriction=false}) {
    const blankRegexWrapper = hasBlankRestriction ? BLANK_REGEX_WRAPPED_NEW : BLANK_REGEX_WRAPPED;
    const blankRegex = hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX;

    return (
        <Modal
            id="warning-modal"
            show={show}
            onHide={config.close}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            dialogClassName="warning-modal"
        >
            <ModalClose onClick={config.close} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                    {config.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="preview-form-subtext">{
                               queTitle.split(blankRegexWrapper).map((str, i) => { // /(\(.*?\))/g
                                // let re = new RegExp(/\([^\(\)]+\)/g);
                                if (!blankRegex.test(str)) {
                                    const oneLine = str.replace(/[\r\n]/g, " @newLine ");
                                    const wordArr = oneLine.split(" ");
                                    return (
                                        <>
                                            {wordArr.map((s, idx) => (<>
                                                {s === '@newLine' &&
                                                    <div key={idx} style={{width: "100%"}}></div>
                                                }
                                                {!!s && s !== '@newLine' &&
                                                    <div key={idx}>{s}</div>
                                                }
                                            </>))}
                                        </>
                                    );
                                } else {
                                    return (<>
                                         <div className="fitbBlankContainers" style={{minHeight: "35px", border: "1px solid #D0D0D0", borderRadius: "2.8px", margin: "0px", padding: "9.31px 20px 9.31px 20px"}}>
                                        </div>
                                </>);
                                } } )}
                                </div>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type={"secondary"} onClick={config.close}>
                    {config.cancelText}
                </YuJaButton>
                { sumbitButtonShow &&
                <YuJaButton
                    onClick={(e) => {
                        e.preventDefault();
                        config.submit();
                    }}
                >
                    {config.okText}
                </YuJaButton>
                }
            </Modal.Footer>
        </Modal>
    );
}