import React, {useEffect, useState} from "react";
import styles from "./Text.module.css"


export default React.memo(NormalText)
function NormalText({children, textType='body', alignType='center', className}) {

    const [classNamesState, setClassNamesState] = useState(className ? className.split(' ') : []);

    useEffect(() => {
        const clsNames = [className];
        switch (alignType) {
            case 'left': {
                clsNames.push(styles.alignLeft);
                break;
            }
            case 'right': {
                clsNames.push(styles.alignRight);
                break;
            }

            default:
                clsNames.push(styles.alignCenter);
        }

        switch (textType) {
            case 'title': {
                clsNames.push(styles.titleText);
                clsNames.push(styles.titleFont);
                break;
            }

            default:
                clsNames.push(styles.bodyText);
                clsNames.push(styles.bodyFont);
        }
        setClassNamesState(clsNames);

    }, [alignType, className, textType]);

    return (
        <div className={classNamesState.join(" ")}>{children}</div>
    );
}