import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { POLL_TYPE } from "../../utils/constants";
import CreateAttendancePage from "./CreateAttendancePage";
import CreateQuizPage from "./CreateQuizPage";

export default function CreateQuizPageParent({ ...props }) {
    const location = useLocation();

    useEffect(() => {
        props.setPollKeyCode(null);
    }, [location?.state?.pollType]);

    return (location?.state?.pollType === POLL_TYPE.ATTENDANCE ?
        <CreateAttendancePage {...props} /> :
        <CreateQuizPage {...props} />
    )
}