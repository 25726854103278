import * as L from 'leaflet';
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function MobileMapView() {
    const {lat, lng, rad} = useParams();

    useEffect(() => {
        console.log("initializing map; lat: " + lat + ", lng: " + lng + ", rad: " + rad + "...");
        var map = L.map('map', { zoomControl: false, scrollWheelZoom: false }).setView([lat, lng], 2); // remove zoom buttons and scroll wheel zoom; 'dragging' is true
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(map);
        map.attributionControl.setPrefix(false);

        const defaultMarker = new L.icon({
            iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('../../../node_modules/leaflet/dist/images/marker-shadow.png'),
            iconSize: [25, 41],
            shadowSize: [30, 65],
            iconAnchor: [12, 41],
            shadowAnchor: [7, 65]
        });
        L.marker([lat, lng], { icon: defaultMarker }).addTo(map);

        var circle = L.circle([lat, lng], {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
            radius: rad
        }).addTo(map);
        map.fitBounds(circle.getBounds());
    }, []);

    return (<>
        <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
            integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
            crossOrigin=""
        />
        <script
            src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
            integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
            crossOrigin=""
        />
        <div id="map" style={{ width: "100%", height: "100%" }} />
    </>);
}
