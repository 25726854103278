import GeneralAzureTab from "./GeneralAzureTab";

export default function AzureSamlForm({setConfigJson, handleSave, ssoURL, setSSOURL, nameIdFormat, setNameIdFormat, remoteLogoutUrl, setRemoteLogouUrl, 
    certificateFingerPrint, setCertificateFingerPrint, logoutUrl, setLogoutUrl, setSignatureEnabled, givenNameAttribute, 
    setGiveNameAttribute, familyNameAttribute, setFamilyNameAttribute, emailAttribute, setEmailAttribute, roleAttribute, 
    setRoleAttribute, itManagerMapping,  setITManagerMapping, instructorMapping, setInstructorMapping, linkageAttribute, setLinkageAttribute, fallbackToEmail, setFallbackToEmail}) {

    return(
        <div>
            <GeneralAzureTab 
                setConfigJson={setConfigJson} handleSave={handleSave}
                ssoURL={ssoURL} setSSOURL={setSSOURL}
                nameIdFormat={nameIdFormat} setNameIdFormat={setNameIdFormat}
                remoteLogoutUrl={remoteLogoutUrl} setRemoteLogouUrl={setRemoteLogouUrl}
                certificateFingerPrint={certificateFingerPrint} setCertificateFingerPrint={setCertificateFingerPrint}
                logoutUrl={logoutUrl} setLogoutUrl={setLogoutUrl}
                setSignatureEnabled={setSignatureEnabled}
                givenNameAttribute={givenNameAttribute} setGiveNameAttribute={setGiveNameAttribute}
                familyNameAttribute={familyNameAttribute} setFamilyNameAttribute={setFamilyNameAttribute}
                emailAttribute={emailAttribute} setEmailAttribute={setEmailAttribute}
                roleAttribute={roleAttribute} setRoleAttribute={setRoleAttribute}
                itManagerMapping={itManagerMapping} setITManagerMapping={setITManagerMapping}
                instructorMapping={instructorMapping} setInstructorMapping={setInstructorMapping}
                linkageAttribute={linkageAttribute} setLinkageAttribute={setLinkageAttribute}
                fallbackToEmail={fallbackToEmail} setFallbackToEmail={setFallbackToEmail}/>
        </div>
    );
}