import { Modal } from "react-bootstrap";
import React from "react";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as LeftArrow} from "./images/leftArrow.svg";

export default function FITBResponseModal({content, setModalShow, show, selectedOption=null}) {
    console.log(content);
    const isMobile = useMobileAccess(480); 
    return (
        <Modal
            id= {!isMobile ? "form-modal" : ""}
            show={show}
            onHide={() => {setModalShow(false)}}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            dialogClassName= {"FITB-modal"}
        >
            {!isMobile && <ModalClose tabIndex={0} onClick={() => {setModalShow(false)}} className="modal-close" />}
            {isMobile && <LeftArrow style={{position: "absolute", left: "19px", top: "25px"}} onClick={() => {setModalShow(false)}}/> }
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title tabIndex={0} className="modal-title" id="contained-modal-title-vcenter">
                    Responses
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style = {{overflowY: "auto", height: isMobile ? "100%" : "500px"}}>
                <div style={{borderTop : isMobile? "1px solid #000" : "none", opacity: isMobile? "0.12" : ""}}>
                </div> 
                { selectedOption && selectedOption !=="unanswered" &&
                <div
                    className={`questionPanelAnswersText resultAnimation`}
                    style={{
                        marginTop: "20px",
                        backgroundColor: "#C4BDD2",
                        color: "#000", borderRadius: "10px",
                        width: "fit-content",
                        padding: "15px",
                    }}
                    tabIndex={0}
                >
                    {Object.keys(selectedOption).map((key) => `"${selectedOption[key]}"`).join(", ")}
                </div>
                }
                <div>
                    {content?.map((item) =>
                        item !== "unanswered" ? (
                            <div
                                className={`questionPanelAnswersText resultAnimation`}
                                style={{
                                    marginTop: "20px",
                                    backgroundColor: "#CACACA",
                                    width: "fit-content",
                                    color: "#000",
                                    padding: "15px",
                                }}
                                tabIndex={0}
                            >
                                {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                            </div>
                        ) : <></>)}
                </div>      
            </Modal.Body>
        </Modal>
    );
}