import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useGlobalFilter, usePagination, useRowSelect, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import { YuJaKebabMenu } from "../../components/standardization/YuJaKebabMenu";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import {ReactComponent as NoResultIllustration} from "../../images/no_result.svg";
import {
    CHECKBOX_COLUMN_ID,
    DATE_CREATED,
    DEFAULT_ROWS_PER_PAGE,
    EMAIL,
    FIRST_NAME,
    LAST_ACCESSED,
    LAST_NAME,
    LINKAGE_TYPE,
    LTI_SAML,
    NO_LINKAGE,
    NO_RESULTS_FOUND,
    OPERATION,
    OPERATION_COLUMN_ID,
    OPERATION_LABEL,
    ROLE,
    ROLES,
    ROLE_LABEL,
    SAML_LTI,
    TEXTBOX,
    USERNAME,
    ACTION_HEADER, STANDALONE_TEXT
} from "../../utils/constants";
import { roleValuetoDisplay } from "../../utils/helpers";
import styles from "./ManageUserPage.module.css";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
        return (
            <input className={"form-check-input"} type="checkbox" aria-label="checkbox" ref={resolvedRef} {...rest} />
        )
    }
)

export function UserTableNew({ data = [], globalFilterObj, columns = [], pageIdx=0, handleSelectUserId, openUpdateUserModal, clickDelete, setShowDeleteButton, sortUserName, sortFirstName, sortLastName, sortRole, sortEmail, sortLinkageType, sortDateCreated, sortLastAccessed, userNameSort, firstNameSort, lastNameSort, roleSort, emailSort, linkageTypeSort, dateCreatedSort, lastAccessedSort }) {
    const [sortSelected, setSortSelected] = useState(7);
    const [scrollableContainer, setScrollableContainer] = useState(false);

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        const filteredDateCreated = globalFilterValue.dateCreated;
        const filteredLastAccessed = globalFilterValue.lastAccessed;
        const filteredRole = globalFilterValue.role;
        const filteredLinkageType = globalFilterValue.linkageType;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.userName && values.userName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }
            else if (values.firstName && values.firstName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true; 
            }
            else if (values.lastName && values.lastName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true 
            }
            else if (values.email && values.email.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true; 
            } 
            else if (values.firstName &&  values.lastName && `${values.firstName} ${values.lastName}`.toLowerCase().indexOf(filteredKeyword) > -1) {
                    return true 
            }
            return false;
        }).filter(r => {
            if (!filteredDateCreated) {
                return true;
            }
            const values = r.values;
            return values.createdDate && values.createdDate === filteredDateCreated;
        }).filter(r => {
            if (!filteredLastAccessed) {
                return true;
            }
            const values = r.values;
            return values.lastLoginTime && values.lastLoginTime === filteredLastAccessed;
        }).filter(r => {
            if (!filteredRole || filteredRole.length === 0) {
                return true;
            }
            const values = r.values;
            return values.role && filteredRole.includes(roleValuetoDisplay(values.role));
        }).filter(r => {
            if (!filteredLinkageType || filteredLinkageType.length === 0) {
                return true;
            }
            const values = r.values;
            return values.linkageType ? 
                (values.linkageType === SAML_LTI || values.linkageType === LTI_SAML ?
                    filteredLinkageType.includes(SAML_LTI) :
                    filteredLinkageType.includes(values.linkageType)
                ) :
                filteredLinkageType.includes(NO_LINKAGE);
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: CHECKBOX_COLUMN_ID,
                    // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    ),
                    width: 50,
                },
                ...columns,
            ])
        }
    );

    useEffect(() => {
        const map = selectedFlatRows.map(r => r.original.userId + "_" + r.original.institutionId);
        if(selectedFlatRows.length > 0) {
            setShowDeleteButton(true);
        } else {
            setShowDeleteButton(false);
        }
        handleSelectUserId(map, pageIndex);
    }, [selectedFlatRows.length]);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);


    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
            return;
        }

        if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }

    }, [data, pageIdx, pageOptions]);

    useEffect(() => {
        const container = document.getElementById("manage-user-page-container");
        setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
    }, [data]);

    // const handleClick = (index) => {
    //     document.getElementById(`menu-${index}`).style.display = "none";
    //     document.getElementById(`options-${index}`).style.display = "inline";
    // };

    const handleSort = (column) => {
        if (column.render('Header') === USERNAME) {
            sortUserName();
            setSortSelected(0);
        } else if(column.render('Header') === FIRST_NAME) {
            sortFirstName();
            setSortSelected(1);
        } else if (column.render('Header') === LAST_NAME) {
            sortLastName();
            setSortSelected(2);
        } else if (column.render('Header') === ROLE) {
            sortRole();
            setSortSelected(3);
        } else if (column.render('Header') === EMAIL) {
            sortEmail();
            setSortSelected(4);
        } else if (column.render('Header') === LINKAGE_TYPE) {
            sortLinkageType();
            setSortSelected(5);
        } else if (column.render('Header') === DATE_CREATED) {
            sortDateCreated();
            setSortSelected(6);
        } else if (column.render('Header') === LAST_ACCESSED) {
            sortLastAccessed();
            setSortSelected(7);
        }
    };

    return (
        <> 
            <div className={styles.tableContainer}>
                <div className={styles.tableWrapper}>
                    <Table hover {...getTableProps()} >
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={column.id === CHECKBOX_COLUMN_ID ? -1 : 0} {...column.getHeaderProps({style: {
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            textWrap: "nowrap"
                                        }})}
                                        className={column.id === CHECKBOX_COLUMN_ID ? styles.checkboxCell : column.id === OPERATION_COLUMN_ID ? styles.opHeaderCell : styles.otherHeaderCell}
                                    >
                                        <span tabIndex={0} role={TEXTBOX}>{column.render('Header')}</span>
                                        {(column.render('Header') === USERNAME && userNameSort === 1 && sortSelected === 0) ||
                                        (column.render('Header') === FIRST_NAME && firstNameSort === 1  && sortSelected === 1) ||
                                        (column.render('Header') === LAST_NAME && lastNameSort === 1 && sortSelected === 2) ||
                                        (column.render('Header') === ROLE && roleSort === 1 && sortSelected === 3) ||
                                        (column.render('Header') === EMAIL && emailSort === 1 && sortSelected === 4) ||
                                        (column.render('Header') === LINKAGE_TYPE && linkageTypeSort === 1 && sortSelected === 5) ||
                                        (column.render('Header') === DATE_CREATED && dateCreatedSort === 0 && sortSelected === 6) ||
                                        (column.render('Header') === LAST_ACCESSED && lastAccessedSort === 0 && sortSelected === 7) ?
                                            <SortDown tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => {handleSort(column)}}/>
                                            : (column.render('Header') === USERNAME && userNameSort === -1 && sortSelected === 0) ||
                                            (column.render('Header') === FIRST_NAME && firstNameSort === -1 && sortSelected === 1) ||
                                            (column.render('Header') === LAST_NAME && lastNameSort === -1 && sortSelected === 2) ||
                                            (column.render('Header') === ROLE && roleSort === -1 && sortSelected === 3) ||
                                            (column.render('Header') === EMAIL && emailSort === -1 && sortSelected === 4) ||
                                            (column.render('Header') === LINKAGE_TYPE && linkageTypeSort === -1 && sortSelected === 5) ||
                                            (column.render('Header') === DATE_CREATED && dateCreatedSort === 1 && sortSelected === 6) ||
                                            (column.render('Header') === LAST_ACCESSED && lastAccessedSort === 1 && sortSelected === 7) ?
                                                <SortUp tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => {handleSort(column)}}/>
                                                : (column.render('Header') === USERNAME && userNameSort === 1) ||
                                                (column.render('Header') === FIRST_NAME && firstNameSort === 1) ||
                                                (column.render('Header') === LAST_NAME && lastNameSort === 1) ||
                                                (column.render('Header') === ROLE && roleSort === 1) ||
                                                (column.render('Header') === EMAIL && emailSort === 1) ||
                                                (column.render('Header') === LINKAGE_TYPE && linkageTypeSort === 1) ||
                                                (column.render('Header') === DATE_CREATED && dateCreatedSort === 0) ||
                                                (column.render('Header') === LAST_ACCESSED && lastAccessedSort === 0) ?
                                                    <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => {handleSort(column)}}/>
                                                    : (column.render('Header') === USERNAME && userNameSort === -1) ||
                                                    (column.render('Header') === FIRST_NAME && firstNameSort === -1) ||
                                                    (column.render('Header') === LAST_NAME && lastNameSort === -1) ||
                                                    (column.render('Header') === ROLE && roleSort === -1) ||
                                                    (column.render('Header') === EMAIL && emailSort === -1) ||
                                                    (column.render('Header') === LINKAGE_TYPE && linkageTypeSort === -1) ||
                                                    (column.render('Header') === DATE_CREATED && dateCreatedSort === 1) ||
                                                    (column.render('Header') === LAST_ACCESSED && lastAccessedSort === -1) ?
                                                        <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => {handleSort(column)}}/> : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, rowIdx) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === ACTION_HEADER) {
                                            return (
                                                <td style={{ textAlign: "center" }} {...cell.getCellProps()} >
                                                    <div aria-label={OPERATION_LABEL} role={TEXTBOX} style={{display: "flex", justifyContent: "center"}}>
                                                        <YuJaKebabMenu>
                                                            <YuJaKebabMenu.Menu>
                                                                <YuJaKebabMenu.Item onClick={() => openUpdateUserModal(row.original, pageIndex)}>Update</YuJaKebabMenu.Item>
                                                                <YuJaKebabMenu.Item onClick={() => clickDelete(row.original.userId, row.original.institutionId, pageIndex)}>Delete</YuJaKebabMenu.Item>
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </div>
                                                </td>
                                            );
                                        }
                                        else if (cell.column.Header === ROLE) {
                                            return (
                                                <td tabIndex={0} aria-label={ROLE_LABEL + cell.value} {...cell.getCellProps()}>
                                                    {ROLES[cell.value] ? ROLES[cell.value].display : ""}
                                                </td>
                                            );
                                        } else if (cell.column.Header === DATE_CREATED || cell.column.Header === LAST_ACCESSED || cell.column.Header === LINKAGE_TYPE) {
                                            return (
                                                <td tabIndex={cell.column.id === CHECKBOX_COLUMN_ID ? -1 : 0} aria-label={cell.column.id + " " + cell.value}
                                                    className={cell.column.id === CHECKBOX_COLUMN_ID ? styles.checkboxCell : ""}
                                                    style={index !== 0 ? {overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"} : {}}
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.column.Header === LINKAGE_TYPE
                                                        ? <>{!!cell.value ? cell.value : STANDALONE_TEXT}</>
                                                        : <>{cell.value === "--" ? "" : cell.value}</>
                                                    }
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td tabIndex={cell.column.id === CHECKBOX_COLUMN_ID ? -1 : 0}
                                                    aria-label={cell.column.id + " " + cell.value}
                                                    className={cell.column.id === CHECKBOX_COLUMN_ID ? styles.checkboxCell : ""}
                                                    {...cell.getCellProps()}
                                                >
                                                    <div style={index !== 0 ? {
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                        paddingRight: "8px"
                                                    } : {}}>
                                                    {cell.render('Cell')}
                                                    </div>
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr>
                            <td colSpan="10" className={styles.noData} style={{boxShadow: "none"}}>
                                <div style={{
                                    height: 450,
                                    justifyContent: "center",
                                    marginTop: "20px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "5px"
                                }}><NoResultIllustration/>
                                    <div style={{
                                        fontSize: "20px",
                                        marginTop: "10px",
                                        fontWeight: 600
                                    }}> {NO_RESULTS_FOUND} </div>
                                </div>
                            </td>
                        </tr>}
                        </tbody>
                    </Table>
                </div>

                {page && page.length !== 0 ? <>

                    <div style={scrollableContainer ? { marginTop: "10px" } : {  bottom: "25px" }}>
                        <NormalPagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                            paddingTop="0px"
                        />
                    </div>
                </> : null}
            </div>
        </>
    );
}
