import {useCallback, useEffect} from "react";
import { COOKIE_WILL_EXPIRED_DURATION } from "../utils/constants";

export const useCookie = (key, defaultValue, expTime=COOKIE_WILL_EXPIRED_DURATION) => {

    const getCookie = useCallback(() => {
        let name = key + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) !== 0) {
                continue;
            }

            const saved = c.substring(name.length, c.length);
            if (saved == null || saved === 'undefined' || saved === 'null') {
                return defaultValue;
            }
            return JSON.parse(saved);

        }
        return defaultValue;
    }, [key, defaultValue])

    const setCookie = useCallback((value, expTimeStamp=0) => {
        let expires = '';
        if (expTimeStamp) {
            const d = new Date(expTimeStamp);
            expires = `expires=${d.toUTCString()};`;
        } else {
            const d = new Date();
            d.setTime(d.getTime() + expTime);
            expires = `expires=${d.toUTCString()};`;
        }

        if (value == null) {
            value = "";
        }
        document.cookie = `${key}=${JSON.stringify(value)};${expires}path=/`;
    }, [key])

    const deleteCookie = useCallback(() => {
        document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`
    }, [key])

    useEffect(() => {
        const storageValue = getCookie();
        // storing input name
        setCookie(storageValue);
    }, [getCookie, setCookie]);

    return [setCookie, getCookie, deleteCookie];
};