import React, { useEffect, useState, useRef } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as OpenEndedDropDown } from "../../images/openEndedArrowDown.svg";
import { ReactComponent as OpenEndedDropUp } from "../../images/openEndedArrowUp.svg";
import { ReactComponent as ThumbDown } from "../../images/openEndedThumbDown.svg";
import { ReactComponent as ThumbDownChosen } from "../../images/openEndedThumbDownHighlighted.svg";
import { ReactComponent as ThumbUp } from "../../images/openEndedThumbUp.svg";
import { ReactComponent as ThumbUpChosen } from "../../images/openEndedThumbUpHighlighted.svg";
import { FIXED_ANSWER, NO_RESPONSES_TEXT, OPEN_ENDED_DISLIKE_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_TIME_SORT, PARTICIPANT_RESPONSE_TEXT, SORT_BY_TEXT } from "../../utils/constants";

export default function OpenEndedResult({pollKey, uniqueCode, question, queTitle, openEndedExpanded, setOpenEndedExpanded}) {
    const { getClassResult, setOpenEndedReaction, getOpenEndedReaction} = ResponseApiCalls();
    const [questionData, setQuestionData ] =  useState([]);
    const [openEndedData, setOpenEndedData] =  useState([]);
    const [currentReaction, setCurrentReaction] = useState([]);
    const [loading, setLoading] = useState(false); 
    const [hoveringThumbUp, setHoveringThumbUp] = useState(false);
    const [hoveringThumbDown, setHoveringThumbDown] = useState(false);
    const [originalOpenEnded, setOriginalOpenEnded] = useState(null);
    const [sortOption, setSortOption] = useState(OPEN_ENDED_TIME_SORT); 
    const isMobile = useMobileAccess(480);
    const questionRef = useRef(question);


  useEffect(() => {
    questionRef.current = question;
  }, [question]);

    async function getQuestionsData() {
        const results = await getClassResult(pollKey, uniqueCode, questionRef.current, null,  'all');
        if (results && results.openEnded && Object.keys(results.openEnded).length >= 1 && results.openEnded) {
          setOriginalOpenEnded(results.openEnded);
          // setOpenEndedData(Object.entries(results.openEnded))
          // entries.sort(([, valueA], [, valueB]) => {
          //   const diffA = valueA["1"] - valueA["2"];
          //   const diffB = valueB["1"] - valueB["2"];
          //   return diffB - diffA; 
          // });

        } 

        // setOpenEndedData(results.openEnded);
        setQuestionData(results.responses);
      }
      
      useEffect(async () => {
        await getReaction(); 
        setTimeout(() => {
          getQuestionsData();
        }, 3000);
        
        const interval = setInterval(() => {
            getQuestionsData();
        }, 5000);
      
          return () => clearInterval(interval);
      }, []);


    useEffect(() => {
      handleSort();
    }, [sortOption, originalOpenEnded])

    const handleSort = () => {
      if (sortOption === OPEN_ENDED_TIME_SORT && originalOpenEnded) {
        setOpenEndedData(Object.entries(originalOpenEnded));
      } else if (sortOption === OPEN_ENDED_LIKE_SORT && originalOpenEnded && Object.keys(originalOpenEnded).length > 1) {
        let entries = Object.entries(originalOpenEnded);
        entries.sort(([, valueA], [, valueB]) => {
          return valueB["1"] - valueA["1"];
        });
        setOpenEndedData(entries);
      } else if (sortOption === OPEN_ENDED_DISLIKE_SORT && originalOpenEnded  && Object.keys(originalOpenEnded).length > 1) {
        let entries = Object.entries(originalOpenEnded);
        entries.sort(([, valueA], [, valueB]) => {
          return valueB["2"] - valueA["2"];
        });
        setOpenEndedData(entries);
        console.log(entries)
      }
    }


    async function getReaction() {
        const result = await getOpenEndedReaction(pollKey, uniqueCode, question.serialNo);
        if (result && result.reactions) {
            setCurrentReaction(result.reactions);
        }
    }

    async function handleReaction(userId, option) {
      if (currentReaction && currentReaction[userId] && currentReaction[userId][0] == option) {
        return; 
      }
      else {
        setLoading(true);
        await setOpenEndedReaction(pollKey, uniqueCode, userId, option, question.serialNo, queTitle);
        await getReaction();
        await getQuestionsData();
        setLoading(false);
      }
    }

    const handleMouseEnterThumbUp = (reaction, userId) => {
        if (reaction === 1) {
          setHoveringThumbUp(userId);
        }
        else if (reaction === 2) {
          setHoveringThumbDown(userId)
        }
      };

    const handleMouseLeaveThumbUp = (reaction) => {
        if (reaction === 1) {
          setHoveringThumbUp(false);
        }
        else if (reaction === 2) {
          setHoveringThumbDown(false)
        }
      };

  return (
    <div style={{
      marginTop: openEndedExpanded ? "30px" : "", backgroundColor: "#FFFFFF", minHeight: openEndedExpanded ? "60vh" : "",
      border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", width: "100%", height: "100%"
    }}>
      {!loading &&
        <div style={{ display: "flex", flexDirection: "column", flex: 1, height: openEndedExpanded ? "60vh" : "100%", }}>
          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: isMobile ? "column" : "", padding: "20px", gap: isMobile ? "8px" : 0 }}>
            <div style={{ fontSize: 16, fontWeight: 600 }}> {PARTICIPANT_RESPONSE_TEXT} </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "end", width: "100%", flex: 1 }}>
              <div style={{ fontSize: 16, color: "#0000000" }}> {SORT_BY_TEXT}</div>
              <YuJaDropdown
                data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                getOptionLabel={item => item}
                getOptionValue={item => item}
                onChange={(option) => { setSortOption(option) }}
                value={sortOption}
                containerStyle={{ width: 130 }}
                style={{ padding: "7px 10px", fontSize: 15, border: "1px solid #6F6F6F", color: "#4C4C4C" }}
              />
            </div>
          </div>
          <div style={{ overflowY: "auto", maxHeight: openEndedExpanded ? "100%" : "200px", padding: "17px", flex: 1 }}>
            {openEndedData && openEndedData?.map(([key, value], _) =>
              questionData && questionData[key] && questionData[key] !== FIXED_ANSWER.UNANSWERED ? (
                <div
                  style={{
                    marginTop: "20px",
                    border: "1px solid #E8EDF1",
                    color: "#000", borderRadius: "7.4px",
                    height: '94px', padding: 10, wordBreak: "break-word",
                    boxShadow: "0px 0px 4px 0px #00000040"

                  }}
                >
                  {`${questionData[key]["0"]}`}
                  <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between", height: 35, width: 165, marginTop: 10, padding: "5px 20px" }}>
                    <div style={{ display: "flex", gap: '5px' }}>

                      <div onMouseEnter={() => {
                        handleMouseEnterThumbUp(1, key);
                      }}
                        onClick={() => { handleReaction(key, 1) }}
                        onMouseLeave={() => {
                          handleMouseLeaveThumbUp(1);
                        }}> {hoveringThumbUp === key || (currentReaction && currentReaction[key] && currentReaction[key][0] == "1") ? <ThumbUpChosen /> : <ThumbUp style={{ cursor: "pointer" }} />}</div>
                      <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {value[1]} </div>
                    </div>

                    <div style={{ display: "flex", gap: '5px' }}>
                      <div onMouseEnter={() => {
                        handleMouseEnterThumbUp(2, key);
                      }}
                        onClick={() => { handleReaction(key, 2) }}
                        onMouseLeave={() => {
                          handleMouseLeaveThumbUp(2);
                        }}> {hoveringThumbDown === key || (currentReaction && currentReaction[key] && currentReaction[key][0] == "2") ? <ThumbDownChosen /> : <ThumbDown style={{ cursor: "pointer" }} />}</div>
                      <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {value[2]} </div>
                    </div>
                  </div>
                </div>
              ) : <></>
            )}
            {(!openEndedData || !openEndedData.filter(entry => questionData && questionData[entry[0]] && questionData[entry[0]] !== FIXED_ANSWER.UNANSWERED).length) &&
              <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                <NoResultIllustration width={192} height={100} />
                <div style={{ fontSize: 18, marginTop: 10, fontWeight: 600 }}>
                  {NO_RESPONSES_TEXT}
                </div>
              </div>
            }
          </div>

          {
            isMobile && !openEndedExpanded &&
            <>
              <div style={{ height: "40px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}> <div style={{ zIndex: 1000, display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => [setOpenEndedExpanded(true)]}> <OpenEndedDropDown /></div> </div>
            </>
          }

          {
            isMobile && openEndedExpanded &&
            <div style={{ height: "40px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}><div style={{ zIndex: 1000, display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => [setOpenEndedExpanded(false)]}> <OpenEndedDropUp /> </div></div>
          }
        </div>
      } {
        loading && <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
          <Skeleton height={94} borderRadius={8} style={{ marginTop: 20 }} />
          <Skeleton height={94} borderRadius={8} style={{ marginTop: 20 }} />
          <Skeleton height={94} borderRadius={8} style={{ marginTop: 20 }} />
          <Skeleton height={94} borderRadius={8} style={{ marginTop: 20 }} />
        </SkeletonTheme>
      }
    </div>
  )
}