import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import CancelIcon from "../../images/cancel.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import { CORRECT_ANSWER_PLACEHOLDER, POLL_TYPE, SA_ANSWER_LENGTH } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { SA_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import "../styles.css";

export default function SAQuestionOption({
  answer,
  selectedQuestion,
  handleDeleteAnswer,
  handleUpdateAnswer,
  index,
  pollType,
  editable,
  // questionBuilder
}) {
  const [showDragIcon, setShowDragIcon] = useState(false);
  const { correctAnswers } = selectedQuestion;

  return (
    <Draggable isDragDisabled={pollType === POLL_TYPE.ATTENDANCE} draggableId={String(index)} index={index} key={String(index)}>
      {(provided) => (
        <div className="question-row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Row
            onMouseEnter={() => setShowDragIcon(true)}
            onMouseLeave={() => setShowDragIcon(false)}

          >
            {pollType !== POLL_TYPE.ATTENDANCE &&
              <Col
                {...provided.dragHandleProps}
                className="drag-icon-column margin-vertical-auto"
                style={{ marginRight: "0.875rem" }}
                title={"Drag to Reorder"}
              >
                {pollType === POLL_TYPE.ATTENDANCE && showDragIcon && <img src={DragDropIcon} alt="drag drop icon" />}
              </Col>
            }

            <Col className="margin-vertical-auto no-padding">
              {/* <div
                className="text-container-with-counter"
              >
                <input
                  style={{ width: "100%" }}
                  className="poll-sub-text focus-outline-none"
                  type="text"
                  placeholder="Correct Answer"
                  value={answer}
                  name={"question-text"}
                  onChange={(e) => {
                    if (e.target.value.length <= SA_ANSWER_LENGTH) {
                      handleUpdateAnswer(index, e.target.value);
                    }
                    else {
                      notifyError(SA_ANSWER_LENGTH_EXCEEDED);
                      handleUpdateAnswer(index, e.target.value.substring(0, SA_ANSWER_LENGTH));
                    }
                  }}
                  onFocus={(e) => { e.target.parentElement.style.boxShadow = "0 0 0.3rem 0.006rem rgb(0 107 155)" }}
                  onBlur={(e) => { e.target.parentElement.style.boxShadow = "none" }}
                />
              </div> */}
              <YuJaTextBox
                disabled={!editable}
                // label={TEXT_BOX}
                containerStyle={{ width: "100%", fontSize: 18, padding: "10px 10px 10px 20px" }}
                className="poll-sub-text focus-outline-none"
                placeholder={CORRECT_ANSWER_PLACEHOLDER}
                value={answer}
                name={"question-text"}
                maxLength={SA_ANSWER_LENGTH}
                // onPaste={(e) => { handlePaste(e) }}
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value.length <= SA_ANSWER_LENGTH) {
                    handleUpdateAnswer(index, e.target.value);
                  } else {
                    notifyError(SA_ANSWER_LENGTH_EXCEEDED);
                    handleUpdateAnswer(index, e.target.value.substring(0, SA_ANSWER_LENGTH));
                  }
                }}
              />
            </Col>
            {pollType !== POLL_TYPE.ATTENDANCE &&
              <Col className="TF-delete-icon margin-vertical-auto no-padding">
                {correctAnswers.length > 1 && (
                  <img
                    onClick={() => {
                      handleDeleteAnswer(index);
                    }}
                    src={CancelIcon}
                    alt="delete icon"
                    className="questions-page-icons cursor-pointer"
                    style={{ width: "2vw", marginLeft: "0" }}
                  />
                )}
              </Col>
            }
          </Row>
        </div>
      )}
    </Draggable>
  );
}
