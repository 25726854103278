import React, {useEffect, useState} from "react";
import {TEXTBOX} from "../../utils/constants";
import classes from "./Report.module.css";
import { ReactComponent as CalendarIcon } from "../../images/calendarIcon.svg";
import DatePicker from 'react-datepicker';
import {getYearMonthStr} from "./ReportHelper";


export function MonthPicker({dateStr="", setDateStr, minDateStr, maxDateStr}) {
    const convertFormatedStrToDate = (dateStr) => {
        if (!dateStr) {
            return undefined;
        }

        let year = parseInt(dateStr.split("-")[0]);
        let month = parseInt(dateStr.split("-")[1]);
        let date = new Date();
        date.setFullYear(year);
        date.setDate(1);
        date.setMonth(month - 1);
        return date;
    }


    const [startDate, setStartDate] = useState(() => convertFormatedStrToDate(dateStr));
    const [minDate, setMinDate] = useState(() => convertFormatedStrToDate(minDateStr));
    const [maxDate, setMaxDate] = useState(() => convertFormatedStrToDate(maxDateStr));


    useEffect(() => {
        setStartDate(convertFormatedStrToDate(dateStr));
    }, [dateStr]);

    useEffect(() => {
        setMinDate(convertFormatedStrToDate(minDateStr));
    }, [minDateStr]);

    useEffect(() => {
        setMaxDate(convertFormatedStrToDate(maxDateStr));
    }, [maxDateStr]);

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
        return (
            <div style={{display: "flex", alignItems: "center", width: 150}}>
                <div
                    role={TEXTBOX}
                    tabIndex={0}
                    className={classes.datePickerInputBox}
                    onClick={onClick}
                >
                    {startDate.toLocaleString('default', { month: 'short'})}
                    -
                    {startDate.toLocaleString('default', {year: 'numeric' })}
                    <span style={{flex: 1}}/>
                    <CalendarIcon onClick={onClick} style={{ cursor: "pointer"}}/>
                </div>

            </div>
        );
    });

    return (
        <DatePicker
            popperClassName={classes.datepickerPopper}
            // disabledKeyboardNavigation
            // displayFormat='MM-YYYY'
            // placeholder='MM/YYYY'
            selected={startDate}
            onChange={date => {setDateStr(getYearMonthStr(date))}}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            shouldCloseOnSelect={true}
            customInput={<CustomInput/>}
            // strictParsing={true}
            minDate={minDate}
            maxDate={maxDate}


        />
    );
}