import React, { useEffect, useState } from "react";
import YuJaButton from "../../components/standardization/YuJaButton";
import { ReactComponent as YuJaLogo } from "../../images/YuJa_Logo_Login.svg";
import { YUJA_TITLE, MOBILE_APP_STORE_URL, MOBILE_PLAY_STORE_URL, YUJA_OPTIMIZE_TEXT, YUJA_DOWNLOAD_TEXT} from "../../utils/constants";
import styles from "./Login.module.css";
import { osName } from "react-device-detect";
import { useHistory} from "react-router-dom";


export default function CreatorWarning() {
    const history = useHistory();

    const redirectDashboard = () => {
        history.push('/home');
    }

    return (
        <div style={{backgroundColor: "#fcfbff", height: "100%", width: "100%", padding: "20px"}}> 
        <div className={styles.title} style={{fontSize: 25, color: "#000000", marginBottom: "30px", marginTop: "5%"}}><YuJaLogo style={{marginRight: "15px"}}/>{YUJA_TITLE}</div>
        <div style={{display: "flex", justifyContent: "center", fontSize: "20px", fontWeight: 700, textAlign: "center"}}> {YUJA_OPTIMIZE_TEXT} </div>
        <div style={{display: "flex", justifyContent: "center", fontSize: "14px", marginTop: '20px', marginBottom: "20px", textAlign: "center"}} > {YUJA_DOWNLOAD_TEXT} </div>
        <div className="app-download-buttons" style={{display: "flex", justifyContent: "center"}}>
                        {osName.match(/(iOS|Mac OS|iPad OS)/) ? 
                            <a href={MOBILE_APP_STORE_URL}>
                                <img
                                    src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
                                    alt="Download on the App Store"
                                    height={55}
                                />
                            </a> :
                            <a href={MOBILE_PLAY_STORE_URL}>
                                <img
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                    alt="Get it on Google Play"
                                    height={85}
                                />
                            </a>
                        }
                    </div>
                <div className="or-line">
                  OR
                </div>

                <div style={{display: "flex", justifyContent: "center"}}> <YuJaButton style={{width: '100%', padding: '15px', backgroundColor: "#42296E", color: "#FFF", fontSize: "18px", maxWidth: "450px"}} onClick={()=> {redirectDashboard()}}> Continue in Browser</YuJaButton></div>
        </div>
    )

}