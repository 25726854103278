import { useEffect } from "react";
import { useIdle } from "@react-corekit/use-idle";
import { localStorageData, logger } from "../utils/helpers";
import { UserApiCalls } from "../apis/UserApiCalls";
import { PublicApiCall } from "../apis/PublicApiCall";
import { ResponseApiCalls } from "../apis/ResponseApiCalls";

export default function LoggingSystem() {
    const {saveUserLogs} = UserApiCalls(); 
    const { getInstitutionDetails } = PublicApiCall();
    const { getShortName } = ResponseApiCalls();
    const idle = useIdle(120000);


    // When the user refreshes their page this function gets called
    const handleUnload = () => {
        if(logger.messages.length !== 0) {
            saveUserLogs();
        }
    };

    useEffect(async () => {
        let shortname = getShortName();
        if(shortname !== "") {
            getInstitutionDetails(shortname).then(res => {
                if(!!res && !!res.collectUserLogs) {
                    logger.collectUserLogs = res.collectUserLogs;
                }
            });
        }
        window.addEventListener("beforeunload", handleUnload);

        const interval = setInterval(() => {            
            if(logger.messages.length > 20000) {
                saveUserLogs();
            }
            else if(document.visibilityState !== "visible") {
                saveUserLogs();
            }
            else if(idle) {
                saveUserLogs();
            }
        }, 60000);

        return () => {
            clearInterval(interval);
            window.removeEventListener('beforeunload', handleUnload);
        }
    }, []);

    // Sets frontend local storage data on refresh
    useEffect(() => {    
        if(window.navigator.cookieEnabled) {
            const entries = Object.entries(localStorage);
            entries.forEach(([key, value]) => {
                localStorageData[key] = JSON.parse(value);
            })
        }
    }, []);

    return null;
};
