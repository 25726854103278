import React, {useContext, useEffect, useRef} from "react";
import { ReactComponent as ICIcon } from "../../images/clickable_purple.svg";
import { ReactComponent as DropDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_purple.svg";
import { ReactComponent as MHIcon } from "../../images/matching_purple.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_purple.svg";
import { ReactComponent as SAIcon } from "../../images/sa_purple.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEnded.svg";
import { ReactComponent as TFIcon } from "../../images/tf_purple.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_purple.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIcon.svg";
import {
    COLLAPSED_SIDE_BAR_WIDTH,
    CREATE_QUES_TYPE,
    EXPANDED_SIDE_BAR_WIDTH,
    QUESTION_TYPES,
    TEXTBOX
} from "../../utils/constants";
import { questionCodeToName, questionNameToCode } from "../../utils/questionUtils";
import styles from "./YuJaKebabMenu.module.css";
import QuestionTypeOption from "../../pages/CreateQuizPage/QuestionTypeOption";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import {useWindowDimensions} from "react-native-web";

export const CreatePollDropDown = ({options=[], isCreateOpen, setIsCreateOpen, questionType, setShownQuestionType, children, handleQuestionTypeUpdate, ...props }) => {
    // const [isOpen, setIsOpen] = useState(false);
    const {sidebarCollapsed=false} = useContext(YuJaGlobalState);
    const windowDimension = useWindowDimensions();
    const {width: windowWidth, height: windowHeight} = windowDimension;
    const isNarrow = windowWidth - (sidebarCollapsed ? COLLAPSED_SIDE_BAR_WIDTH : EXPANDED_SIDE_BAR_WIDTH)  < 1200;

    const containerRef = useRef();
    const iconRef = useRef();
    const menuRef = useRef();

    useEffect(() => {
        if (isCreateOpen) {
            const {left, width, height, top, } = containerRef.current?.getBoundingClientRect();
            menuRef.current.style.left = `${left}px`
            menuRef.current.style.top = `${top + height + 2}px`
            // menuRef.current.style.width = `${width}px`
        }
    }, [isCreateOpen, windowDimension]);


    const open = () => {
        setIsCreateOpen(!isCreateOpen);
    };

    const onClick = (e) => {
        setIsCreateOpen(false);
        handleQuestionTypeUpdate(e.target.innerText);
    }

    const onHover = (e) => {
        setShownQuestionType(questionNameToCode(e.target.innerText));
    }

    const onClose = () => {
        setIsCreateOpen(false);
        setShownQuestionType(questionType);
    }


    return (
        <div
            ref={containerRef}
            className={`${styles.container} ${styles.questionTypeDropdownContainer}`}
            style={{padding: 5}}
            onClick={open}
        >
            <div
                ref={iconRef}
                tabIndex={0}
                role={TEXTBOX}
                aria-label={questionCodeToName(questionType) + CREATE_QUES_TYPE}
                style={{display: "flex", justifyContent: "center", alignItems: "center", gap:  5, whiteSpace: "nowrap", cursor: "pointer"}}
            >
                {questionType === QUESTION_TYPES.MCSS.name && <MCSSIcon alt="MCSS Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.SA.name && <SAIcon alt="SA Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.OE.name && <OEIcon alt="QA Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.TF.name && <TFIcon alt="TF Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.FITB.name && <FITBIcon alt="FITB Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.CI.name && <ICIcon alt="CI Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.WC.name && <WCIcon alt="WC Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.MH.name && <MHIcon alt="MH Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.RK.name && <RKIcon alt="RK Icon" style={{ width: "24px", height: "24px" }} />}
                {" " + questionCodeToName(questionType)}
                <DropDown style={{height: isNarrow ? 12 : 17, width: isNarrow ? 12 : 17}}/>
            </div>
            {isCreateOpen &&
                    <div
                        style={{position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 10, cursor: "default"}}
                        onClick={onClose}
                    >
                        <div className={styles.createMenuContainer} ref={menuRef}>
                            <div className={`${styles.menuList}`} >
                                {options.map((item, index) =>
                                    <div key={index} className={`${styles.menuItem}`} role={TEXTBOX} style={{padding: "8px 5px"}}
                                         onClick={onClick} onMouseEnter={onHover} tabIndex={0}>
                                        <QuestionTypeOption
                                            optionData={item}
                                            handleQuestionTypeUpdate={() => {
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

            }
        </div>
    );
}
