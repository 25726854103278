import {useCallback, useEffect} from "react";
import { localStorageData } from "../utils/helpers";

export const useLocalStorageNew = (key, defaultValue) => {

    const getStorageValue = useCallback(() => {
        const saved = window.navigator.cookieEnabled ? localStorage.getItem(key) : localStorageData[key];
        if (saved == null || saved === 'undefined' || saved === 'null') {
            return defaultValue;
        }
        if (window.navigator.cookieEnabled) {
            return JSON.parse(saved);
        }
        else {
            return saved;
        }
    }, [key, defaultValue]);

    const setStorageValue = useCallback((value) => {
        if (value != null) {
            localStorageData[key] = value;
            if(window.navigator.cookieEnabled) {
                localStorage.setItem(key, JSON.stringify(value));
            }
            if(value.authorization) {
                localStorageData.authToken = value.authorization;
            }
        }
    }, [key]);

    useEffect(() => {
        const storageValue = getStorageValue();
        // storing input name
        setStorageValue(storageValue);
    }, [getStorageValue, setStorageValue]);

    return [setStorageValue, getStorageValue];
};
