import { useEffect } from "react";
import { AuthenticationApiCalls } from "../apis/AuthenticationApiCalls";
import useQuery from "../utils/queryparam";

export default function SSOLoginComponent() {
  
  const { loginSAML } = AuthenticationApiCalls();
  let ssoQueryParams = useQuery();
  console.log(ssoQueryParams);
  useEffect(() => {
    if (ssoQueryParams.get("secret") && ssoQueryParams.get("intType")) {
        loginSAML(ssoQueryParams.get("secret"), ssoQueryParams.get("intType"));
    }
  }, []);

  return null;
}
