import { useEffect, useState } from 'react';

var countDownTimeOut;

const useCountdown = (targetDate, paused = undefined) => {
    const [countDownDate, setCountDownDate] = useState(new Date(targetDate).getTime());
    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime()); // time left
    const [pausedTime, setPausedTime] = useState(); // time left

    const resetCountdown = (targetDate) => {
        const newCountDownDate = new Date(targetDate).getTime();
        setCountDownDate(newCountDownDate);
        setCountDown(newCountDownDate - new Date().getTime());
        setPausedTime(undefined);
    };

    useEffect(() => {

        if (!paused) {
            countDownTimeOut = setTimeout(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);
        }


        return () => {
            if (!!countDownTimeOut) {
                clearTimeout(countDownTimeOut);
            }
        }

    }, [countDown]);

    useEffect(() => {

        if (!paused && pausedTime) {
            // timer was just unpaused, readjust the countDownDate/endtime to account for elapsed time while paused
            let timeElapsed = new Date().getTime() - pausedTime;
            let newCountDownDate = countDownDate + timeElapsed;
            // console.log("time left: " + (newCountDownDate - new Date().getTime()))
            setCountDownDate(newCountDownDate)
            setCountDown(newCountDownDate - new Date().getTime());
        } else if (paused) {
            // timer was paused, record the time of this pausing:
            let newPausedTime = new Date().getTime();
            setPausedTime(newPausedTime);
            clearTimeout(countDownTimeOut);
        }

    }, [paused]);


    return {
        getReturnValues: getReturnValues(countDown),
        resetCountdown
    };
};

const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export { useCountdown };