import 'rc-datepicker/lib/style.css';
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from '../../components/standardization/YuJaDropdown';
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { ReactComponent as CalendarIcon } from "../../images/calendarIcon.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import {
    ALL_CATEGORY,
    ALL_CATEGORY_LABEL,
    ALL_SATISFACTION,
    ALL_SATISFACTION_LABEL,
    FEEDBACK_CATEGORY,
    SATISFACTION_GOOD_TEXT,
    SATISFACTION_POOR_TEXT,
    SATISFACTION_SATISFIED_TEXT,
    SATISFAcTION_EXCELLENT_TEXT,
    SEARCH_POLL,
    SEARCH_TEXT,
    TEXTBOX
} from '../../utils/constants';
import { notifyError } from '../../utils/helpers';
import { LOADING_SEARCH_ERROR } from '../../utils/toast-message-constants';
import styles from "./ManagePolls.module.css";
import "./styles.css";

export default function FeedbackSearchForm({handleSubmit, disabled=false}) {
    const [keyword, setKeyword] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [categoryFilter, setCategoryFilter] = useState(ALL_CATEGORY);
    const [satisfactionFilter, setSatisfactionFilter] = useState(ALL_SATISFACTION);
    const [selectedDate, setSelectedDate] = useState("");
    const [dateFilterDisplay, setDateFilterDisplay] = useState(new Date());
    const [dateFilterShow, setDateFilterShow] = useState(false);
    const clickSearch = ({key=keyword, date=selectedDate, category=categoryFilter, satisfaction=satisfactionFilter}) => {
        if (disabled) {
            notifyError(LOADING_SEARCH_ERROR);
            return;
        }
        let formattedDate = date ? date.split("-").reverse().join("-") : "";
        handleSubmit({
            keyword: key,
            date: formattedDate, 
            category: category,
            satisfaction: satisfaction
        });
    };

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }

    const CustomInputEnd = React.forwardRef((props, ref) => {
        return (
          <div className="react-datepicker__input-container">           
            <input tabIndex={-1} type="text" placeholder={"DD/MM/YYYY"} class="datepickerinputStart react-datepicker-ignore-onclickoutside feedbackDatePicker" value={selectedDate} readOnly={true}/>
            <div role={TEXTBOX} tabIndex={0} aria-label={selectedDate} onClick={() => {if(dateFilterShow){setDateFilterShow(false);} {if(!dateFilterShow){setDateFilterShow(true); props.onClick();} else {setDateFilterShow(false); props.onBlur();}}}} style={{position: "absolute", width: "140px", cursor: "pointer" , height: "36px", top:"0px"}}></div>
            <CalendarIcon  onClick={() => {{if(!dateFilterShow){setDateFilterShow(true); props.onClick();} else {setDateFilterShow(false); props.onBlur();}}}}  style={{transform: "translate(-30px, 8px)", cursor:  "pointer", position: "absolute"}}/>
          </div>
        );
      });

    return (
        <div style={{width: "100%"}}>
                    <div className={styles.searchBox}>
                        <Form className={styles.searchFeedbackForm} onSubmit={e => e.preventDefault()}>
                            <YuJaTextBox
                                label={SEARCH_POLL}
                                containerStyle={{ fontSize: 15, minWidth: 150, height: 35, padding: "3px 10px" }} // maxWidth: 500
                                placeholder={"Search"}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={enterKeyup}
                                value={keyword}
                                before={<SearchIcon style={{marginRight: 10, width: 16}}/>}
                            />

                            <YuJaDropdown
                                ariaLabel={categoryFilter + ALL_CATEGORY_LABEL}
                                data={[ALL_CATEGORY, FEEDBACK_CATEGORY.Suggestion, FEEDBACK_CATEGORY.Comment, FEEDBACK_CATEGORY.Other]}
                                getOptionLabel={item => item}
                                getOptionValue={item => item}
                                onChange={option => setCategoryFilter(option)}
                                value={categoryFilter}
                                containerStyle={{marginRight: 15, width: 165}}
                                style={{padding: "8px 15px", fontSize: 15, color: "#464646", width: 165}}
                            />
                            <YuJaDropdown
                                ariaLabel={satisfactionFilter + ALL_SATISFACTION_LABEL}
                                data={[ALL_SATISFACTION, SATISFACTION_POOR_TEXT, SATISFACTION_SATISFIED_TEXT, SATISFACTION_GOOD_TEXT, SATISFAcTION_EXCELLENT_TEXT]}
                                getOptionLabel={item => item}
                                getOptionValue={item => item}
                                onChange={option => setSatisfactionFilter(option)}
                                value={satisfactionFilter}
                                containerStyle={{marginRight: 15, width: 165}}
                                style={{padding: "8px 15px", fontSize: 15, color: "#464646", width: 165}}
                            />
                            
                            <div>
                            <DatePicker
                            showTimeInput
                            disabledKeyboardNavigation
                            displayFormat='DD-MM-YYYY'
                            className='date-picker-input'
                            placeholder='DD/MM/YYYY'
                            onChange={value => {
                                if (!isNaN(value)) {
                                    setDateFilterDisplay(value);
                                    setSelectedDate(value.toISOString().split('T')[0]);
                                } else {
                                    setSelectedDate("");
                                }
                            }}
                            selected={dateFilterDisplay}
                            customInput={<CustomInputEnd />}
                            customTimeInput={<div style={{height: 11}}> </div>}
                            shouldCloseOnSelect={true}
                            strictParsing={true}
                        />
                        </div>

                            <YuJaButton
                                onClick={clickSearch}
                                wrapperStyle={{ padding: 0 }}
                                style={{ width: 150, height: 35, padding: 8 }}
                            >
                                <div style={{ fontSize: 15 }}>{SEARCH_TEXT}</div>
                            </YuJaButton>
                        </Form>
                    </div>
        </div>
    );
}