import React, {useEffect, useState} from "react";
import styles from "./Login.module.css";
import {Form, Row} from "react-bootstrap";
import {ReactComponent as YuJaLogo} from "../../images/YuJa_Logo_Login.svg";
import {useHistory} from "react-router-dom";
import useHostname from "../../hooks/useHostname";
import defaultImage from "./images/main.jpg";
import Check from './images/BigCorrect.svg';
import {AuthenticationApiCalls} from "../../apis/AuthenticationApiCalls";
import {useLoading} from "../../utils/LoadingContext";
import {
    BACK_TO_LOGIN_ARIA_LABEL,
    BACK_TO_LOGIN_TEXT, FORGOT_PWD_DESC, FORGOT_PWD_EMAIL_INPUT_BOX_LABEL,
    FORGOT_PWD_TITLE,
    REGEX_EMAIL,
    TEXTBOX
} from "../../utils/constants";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import YuJaButton from "../../components/standardization/YuJaButton";
import {useWindowDimensions} from "react-native-web";

export default function ForgotPasswordPage() {
    const [input, setInput] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [sent, setSent] = useState(false);
    const hostResource = useHostname(window.location.hostname);
    const history = useHistory();
    const {sendResetPwdEmail} = AuthenticationApiCalls();

    const {setLoading} = useLoading();

    const {width, height} = useWindowDimensions();

    const isMobile = height / width > 1 && width < 950;

    useEffect(() => {
        const {imageURL} = hostResource;
        setImgUrl(imageURL ? imageURL : defaultImage);
    }, [hostResource])

    const validateForm = () => {
        let returnVal = true;
        if (!input || input.search(REGEX_EMAIL) < 0) {
            setAlertMsg("Please enter a valid email address.");
            returnVal = false;
        }
        return returnVal;
    };

    useEffect(() => {
        function handleResize() {
            calcWidth();
        }

        window.addEventListener('resize', handleResize)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAlertMsg("");
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        // send email
        const res = await sendResetPwdEmail(input);
        setSent(res);
        setLoading(false);
    }

    const resend = async () => {
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        // send email
        await sendResetPwdEmail(input);
        setLoading(false);
    }

    const editInput = (e) => {
        setInput(e.target.value)
        setAlertMsg("");
    }

    const goHome = () => {
        history.push({pathname: "/"});
    }


    const calcWidth = () => {
        let ele = document.getElementById("input-label-holder")
        let box = document.getElementById("input-box-holder");

        if (ele !== null && box !== null) {

            if (ele.offsetWidth > 150) {
                box.style.width = "70%"
                return "70%";
            } else if (ele.offsetWidth <= 150) {
                box.style.width = "100%"
                return "100%";
            }
        }
    }


    return (
        <>
            <Row style={{height: "100%", width: "100%", margin: 0}}>
                <div className={styles.leftWrapper} style={{width: isMobile ? '100%' : '38%'}}>
                    {sent ? (
                        <>
                            <div className={styles.titleResetPwd}><YuJaLogo />YuJa Engage</div>
                            <div className={styles.formWrapper}>
                                <div>
                                    <img src={Check} alt="check"/>
                                    <span className={styles.title2}>Email has been sent.</span>
                                </div>
                                <div className={styles.title3}>Please check your inbox and click in the received
                                    link to reset a password.
                                </div>
                                <YuJaButton style={{width: '100%', fontSize: 20}} onClick={() => goHome()}>LOGIN</YuJaButton>
                            </div>

                            <div style={{textAlign: "center", margin: "4%"}}>
                                <span className={styles.bottomTextA}>Didn't receive the email?</span>
                                <span className={styles.bottomTextB} onClick={() => resend()}>Resend</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.titleResetPwd}><YuJaLogo />YuJa Engage</div>
                            <div className={styles.formWrapper}>
                                <div className={styles.subtitle}>{FORGOT_PWD_TITLE}</div>
                                <div style={{height: 10}}/>
                                <div className={styles.title3}>{FORGOT_PWD_DESC}</div>
                                <Form style={{marginTop: 20, width: "100%"}} onSubmit={e => e.preventDefault()}>
                                    {(!!alertMsg) &&
                                        <p className={styles.alert}>Please enter a valid email address.</p>}
                                    <Form.Group className="mb-3">
                                        <div id="input-label-holder" className={styles.inputTitleBoxForgot}>
                                            <Form.Label className={styles.inputTitle}>{FORGOT_PWD_EMAIL_INPUT_BOX_LABEL}</Form.Label>
                                        </div>
                                        <div id="input-box-holder" className={styles.emailInHolder}
                                             style={{width: calcWidth()}}>
                                            <YuJaTextBox
                                                htmlSize={40}
                                                placeholder=""
                                                value={input}
                                                onChange={e => editInput(e)}
                                                isInvalid={!!alertMsg}
                                            />
                                        </div>
                                    </Form.Group>
                                    <YuJaButton style={{width: '100%', fontSize: 20}} onClick={handleSubmit}>Reset Password</YuJaButton>
                                </Form>
                                <div
                                    className={styles.bottomText}
                                    onClick={() => goHome()}
                                    tabIndex={0}
                                    role={TEXTBOX}
                                    aria-label={BACK_TO_LOGIN_ARIA_LABEL}
                                >
                                    {BACK_TO_LOGIN_TEXT}
                                </div>
                            </div>
                        </>
                    )}
                    <div style={{flex: 1}}/>
                </div>
                {!isMobile && (
                    <div style={{width: "62%", padding: "0%"}}>
                        <div style={{backgroundImage: `url(${imgUrl})`}} className={styles.container}/>
                    </div>
                )}
            </Row>
        </>
    );
}
