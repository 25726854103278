import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import NormalBox from "../../public/boxes/NormalBox";
import { ALL_ROLES, DEFAULT_ROWS_PER_PAGE, EMAIL, FIRST_NAME, LAST_NAME, NO_RESULTS_FOUND, ROLE, TEXTBOX, USERNAME } from "../../utils/constants";
import { roleDisplayToValue, roleValuetoDisplay } from "../../utils/helpers";
import styles from "./CourseContent.module.css";
import {ReactComponent as NoResultIllustration} from "../../images/no_result.svg";
export default React.memo(CourseMemberTable);
function CourseMemberTable({ data = [], columns = [], globalFilterObj = {}, sortUserName, sortFirstName, sortLastName, sortRole, sortEmail, userNameSort, firstNameSort, lastNameSort, roleSort, emailSort}) {
    const [sortSelected, setSortSelected] = useState(1);
    const [scrollableContainer, setScrollableContainer] = useState(false);

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const {keyword, role=ALL_ROLES} = globalFilterValue;
        const filteredKeyword = keyword ? keyword.toLowerCase() : "";
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.userName && values.userName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (values.firstName && values.firstName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (values.lastName && values.lastName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        }).filter(r => {
            if (role === ALL_ROLES) {
                return true;
            }
            const values = r.values;
            if (values.role && values.role === roleDisplayToValue(role)) {
                return true;
            }
            return false;
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [globalFilterObj]);

    useEffect(() => {
        const container = document.getElementById("course-content-container");
        setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
    }, [data]);

    const handleSort = (column) => {
        if (column.render('Header') === USERNAME) {
            sortUserName();
            setSortSelected(0);
        } else if(column.render('Header') === FIRST_NAME) {
            sortFirstName();
            setSortSelected(1);
        } else if (column.render('Header') === LAST_NAME) {
            sortLastName();
            setSortSelected(2);
        } else if (column.render('Header') === ROLE) {
            sortRole();
            setSortSelected(3);
        } else if (column.render('Header') === EMAIL) {
            sortEmail();
            setSortSelected(4);
        }
    };

    return (
        <>
            <div className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()} style={{margin: "0px"}}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps({
                            style: {
                            }
                        })}>
                            {headerGroup.headers.map(column => (
                                <th tabIndex={0} {...column.getHeaderProps({
                                    style: {
                                        width: column.width,
                                        minWidth: column.minWidth,
                                        // border: '1px solid #000000'
                                    }
                                })}>
                                    <div className={column.Header === USERNAME ? styles.userNameCell : styles.tableHeader}>
                                        {column.render('Header')}
                                        {(column.render('Header') === USERNAME && userNameSort === 1 && sortSelected === 0) ||
                                            (column.render('Header') === FIRST_NAME && firstNameSort === 1  && sortSelected === 1) ||
                                            (column.render('Header') === LAST_NAME && lastNameSort === 1 && sortSelected === 2) ||
                                            (column.render('Header') === ROLE && roleSort === 1 && sortSelected === 3) ||
                                            (column.render('Header') === EMAIL && emailSort === 1 && sortSelected === 4) ?
                                        <Down tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === USERNAME && userNameSort === -1 && sortSelected === 0) ||
                                            (column.render('Header') === FIRST_NAME && firstNameSort === -1 && sortSelected === 1) ||
                                            (column.render('Header') === LAST_NAME && lastNameSort === -1 && sortSelected === 2) ||
                                            (column.render('Header') === ROLE && roleSort === -1 && sortSelected === 3) ||
                                            (column.render('Header') === EMAIL && emailSort === -1 && sortSelected === 4) ?
                                        <Up tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === USERNAME && userNameSort === 1) ||
                                            (column.render('Header') === FIRST_NAME && firstNameSort === 1) ||
                                            (column.render('Header') === LAST_NAME && lastNameSort === 1) ||
                                            (column.render('Header') === ROLE && roleSort === 1) ||
                                            (column.render('Header') === EMAIL && emailSort === 1) ?
                                        <DownGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === USERNAME && userNameSort === -1) ||
                                            (column.render('Header') === FIRST_NAME && firstNameSort === -1) ||
                                            (column.render('Header') === LAST_NAME && lastNameSort === -1) ||
                                            (column.render('Header') === ROLE && roleSort === -1) ||
                                            (column.render('Header') === EMAIL && emailSort === -1) ?
                                        <UpGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/> : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page && page.length !== 0 ? page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps({
                                            style: {
                                                // width: '100%',
                                                // 
                                                // fontStyle: 'normal',
                                                // fontWeight: '400',
                                                // fontsize: '1.25rem',
                                                // lineHeight: '1.25rem',
                                                // letterSpacing: '0.035em',
                                                // height: '4rem',
                                                // overflowX: 'hidden',
                                                // whiteSpace: 'nowrap',
                                                // textOverflow: 'ellipsis',
                                                // border: '1px solid #000000'
                                                wordBreak: 'break-word'
                                            }
                                        })}>
                                            <div tabIndex={0} role={TEXTBOX} aria-label={cell.column.Header + " " + cell.value} className={cell.column.Header === USERNAME ? styles.userNameCell : styles.tableBody}>
                                                {cell.column.Header === ROLE ? roleValuetoDisplay(cell.value) : cell.value}
                                            </div>
                                        </td>
                                    );

                                })}
                            </tr>
                        )
                    }) : <tr><td colSpan="5" className={styles.noData} style={{boxShadow: "none"}}><div style={{height: 400, display: "flex", flexDirection: "column", alignItems: "center", gap: "5px", justifyContent: "center"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div></td></tr>}
                    </tbody>
                </Table>
            </div>

            {page && page.length !== 0 ? <>
                {!scrollableContainer && <div style={{height: "0px", marginTop: "40px"}}/>}
                <div style={scrollableContainer ? {marginTop: "10px"} : {position: "absolute", bottom: "25px"}}>
                    <NormalPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                        paddingTop="20px"
                    />
                </div>
            </> : <></>}
        </>
    );
}
