import styles from "./TimeLimitInputBox.module.css"

import {useEffect, useRef, useState} from "react";
import {notifyError} from "../utils/helpers";
import {QUESTION_ENTRIES_MAX_ERROR, QUESTION_ENTRIES_MIN_ERROR} from "../utils/toast-message-constants";
import {WC_MAXIMUM_ENTRIES} from "../utils/constants";


export default function EntriesInputBox({

                                           containerStyle = {},
                                           onBlur,
                                           ...props
                                          }) {
    const [temporaryVal, setTemporaryVal] = useState("");

    const initializeInput = (value) => {
        if (isNaN(value)) {
            value = props.value;
        }
        if (!!value && parseInt(value)) {
            setTemporaryVal(value);
        } else {
            setTemporaryVal("");
        }
    }

    useEffect(() => {
        initializeInput();
    }, [props.value]);

    const actualInputRef = useRef(null);

    const onClick = (e) => {
        e.stopPropagation();
        actualInputRef.current.focus();
    }

    const onChangeFunc = (e) => {
        e.stopPropagation();
        setTemporaryVal(e.target.value);
    }

    const onBlurFunc = () => {
        const entries = Number(temporaryVal);
        if (!onBlur) {
            return;
        }

        if (isNaN(entries)) {
            initializeInput();
        } else {
            if (entries === 0) {
                notifyError(QUESTION_ENTRIES_MIN_ERROR);
                initializeInput();
            } else if (entries > WC_MAXIMUM_ENTRIES) {
                notifyError(QUESTION_ENTRIES_MAX_ERROR);
                initializeInput();
            } else {
                onBlur(entries);
                initializeInput(entries);
            }

        }

    }

    return (
        <div
            className={`${styles.box} ${props.disabled ? styles.disabled : ""}`}
            style={{...containerStyle}}
            onClick={onClick}
        >
            <input
                {...props}
                ref={actualInputRef}
                value={temporaryVal}
                onChange={onChangeFunc}
                onBlur={onBlurFunc}
                placeholder={"0"}
                disabled={props.disabled}
                aria-label={"textbox"}
            />
        </div>
    );
}