import { faEnvelope, faFileText, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { PublicApiCall } from "../../apis/PublicApiCall";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import {
    BUTTON,
    EMAIL_SUPPORT_BODY,
    EMAIL_SUPPORT_PREFIX,
    EMAIL_TEXT,
    INT_PHONE,
    INT_TEXT,
    MESSAGE_SUPPORT_BODY,
    MESSAGE_TEXT,
    MODAL_CLOSE_TEXT,
    MODAL_SEND_TEXT,
    NAME_TEXT,
    NED_PHONE,
    NED_TEXT,
    PRODUCT_DOCS,
    SEND_EMAIL,
    SEND_MESSAGE,
    SEND_REQUEST_TITLE,
    SUBJECT_TEXT,
    SUPPORT_CENTER_BTN,
    SUPPORT_EMAIL_ID,
    TEXTBOX,
    TOLL_FREE_PHONE,
    UK_PHONE,
    UK_TEXT,
    US_CANADA_PHONE,
    US_CANADA_TEXT,
    YUJA_HELP,
    YUJA_HELP_MODAL,
    YUJA_HELP_TEXT,
    YUJA_SUPPORT,
    YUJA_SUPPORT_TEXT
} from "../../utils/constants";
import { notifyError, notifySuccess } from "../../utils/helpers";
import { EMAIL_REQUIRED_FIELD, MISSING_MESSAGE_ERROR, MISSING_SUBJECT_ERROR, NAME_REQUIRED_FIELD, REQUEST_SEND_SUCCESS, SUBJECT_EXCEED_LIMIT_ERROR, VALID_EMAIL_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../standardization/YuJaButton";

export default function YuJaHelpModal({setShowModal, showModal, userName, userEmail}) {
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showPhoneModal, setShowPhoneModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const { supportRequest } = PublicApiCall();
    const [name, setName] = useState(userName);
    const [email, setEmail] = useState(userEmail);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const handleChangeName = (e) => {
        setName(e.target.value);
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const handleChangeSubject = (e) => {
        if(e.target.value.length > 100) {
            notifyError(SUBJECT_EXCEED_LIMIT_ERROR);
            setSubject(e.target.value.substring(0, 100));
        }
        else {
            setSubject(e.target.value);
        }
    }
    const handleChangeMessage = (e) => {
        setMessage(e.target.value);
    }

    const validateEmail = (initEmail) => {
        return String(initEmail)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    const validateForm = () => {
        if(name === "") {
            notifyError(NAME_REQUIRED_FIELD);
            document.getElementById("inputName").focus();
            return false;
        }
        if(email === "") {
            notifyError(EMAIL_REQUIRED_FIELD);
            document.getElementById("inputEmail").focus();
            return false;
        }
        if(!validateEmail(email)) {
            notifyError(VALID_EMAIL_ERROR);
            document.getElementById("inputEmail").focus();
            return false;
        }
        if(subject === "") {
            notifyError(MISSING_SUBJECT_ERROR);
            document.getElementById("inputSubject").focus();
            return false;
        }
        if(message === "") {
            notifyError(MISSING_MESSAGE_ERROR);
            document.getElementById("inputMessage").focus();
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        await supportRequest(name, email, subject, message);
    }
    return (
        <>
        <Modal
                id="form-modal"
                show={showModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={true}

            >
                <ModalClose tabIndex={0} aria-label={SUPPORT_CENTER_BTN} role={BUTTON} onClick={() => {setShowModal(false); setName(userName); setEmail(userEmail); setMessage(""); setSubject(""); }} className="modal-close" />
                <Modal.Header
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Modal.Title tabIndex={0} aria-label={YUJA_HELP_MODAL} role={TEXTBOX} className="modal-title" id="contained-modal-title-vcenter">
                        {YUJA_HELP}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p tabIndex={0} role={TEXTBOX} className="text-center">
                        {YUJA_HELP_TEXT}
                    </p>
                    <div style = {{display: "flex", justifyContent: "space-around"}}>
                        <div
                            onClick={() => {
                                setShowModal(false);
                                setShowPhoneModal(true);
                            }}
                            style = {{ marginTop: "5px" }}
                        >
                            <FontAwesomeIcon icon={faPhone} size="3x"/>
                        </div>

                        <div
                            onClick={() => {
                                setShowModal(false);
                                setShowEmailModal(true);
                            }}
                            style = {{ marginTop: "5px" }}
                        >
                            <FontAwesomeIcon icon={faEnvelope} size="3x"/>
                        </div>
                    </div>
                    
                    <div style = {{display: "flex", justifyContent: "space-around"}}>
                        <div
                            onClick={() => {
                                setShowModal(false);
                                setShowPhoneModal(true);
                            }}
                            style = {{ marginTop: "15px", flexBasis: "29%"}}
                            tabIndex={0} role={BUTTON}
                        >
                            <h6>{TOLL_FREE_PHONE}</h6>
                        </div>

                        <div
                            onClick={() => {
                                setShowModal(false);
                                setShowEmailModal(true);
                            }}
                            style = {{ marginTop: "15px", flexBasis: "22%"}}
                            tabIndex={0} role={BUTTON}
                        >
                            <h6>{SEND_EMAIL}</h6>
                        </div>
                    </div>

                    <div style = {{display: "flex", justifyContent: "space-around"}}>
                        <div
                            onClick={() => {
                                setShowModal(false);
                                setShowMessageModal(true);
                            }}
                            style = {{ marginTop: "20px" }}
                        >
                            <FontAwesomeIcon icon={faUser} size="3x"/>
                        </div>

                        <div onClick={() => window.open("https://support.yuja.com/hc/en-us/categories/9249849656727-YuJa-Engage")} style={{ marginTop: "20px" }}>
                            <FontAwesomeIcon icon={faFileText} size="3x"/>
                        </div> 
                    </div>
                    
                    <div style = {{display: "flex", justifyContent: "space-around"}}>
                        <div
                            onClick={() => {
                                setShowModal(false);
                                setShowMessageModal(true);
                            }}
                            style = {{ marginTop: "15px", flexBasis: "26%"}}
                            tabIndex={0} role={BUTTON}
                        >
                            <h6>{SEND_MESSAGE}</h6>
                        </div>

                        <div tabIndex={0} role={BUTTON} onClick={() => window.open("https://support.yuja.com/hc/en-us/categories/9249849656727-YuJa-Engage")} style = {{ marginTop: "15px", flexBasis: "24%"}} >
                            <h6>{PRODUCT_DOCS}</h6>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <YuJaButton aria-label={SUPPORT_CENTER_BTN}
                        onClick={() => {
                            setShowModal(false);
                            setName(userName);
                            setEmail(userEmail);
                            setMessage("");
                            setSubject("");
                        }}
                    >
                        {MODAL_CLOSE_TEXT}
                    </YuJaButton>
                </Modal.Footer>
            </Modal>


            {/* TOLL-FREE MODAL */}
            <Modal
                id="phone-modal"
                show={showPhoneModal}
                onHide={() => setShowPhoneModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={true}
            >
                <ModalClose tabIndex={0} aria-label={SUPPORT_CENTER_BTN} role={TEXTBOX} onClick={() => {setShowPhoneModal(false); setName(userName); setEmail(userEmail); setMessage(""); setSubject(""); }} className="modal-close" />
                <Modal.Header
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Modal.Title tabIndex={0} role={TEXTBOX} className="modal-title" id="contained-modal-title-vcenter">
                        {YUJA_SUPPORT}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center" tabIndex={0} role={TEXTBOX}>
                        {YUJA_SUPPORT_TEXT}
                    </p>
                    
                    <div style={{display:"flex", justifyContent: "center"}}>
                        <div style={{marginRight: "30px" , flexBasis: "28%"}} >
                            <p className="text-center" tabIndex={0} role={TEXTBOX}> <b>{US_CANADA_TEXT}</b> <br/>{US_CANADA_PHONE}</p>
                        </div>

                        <div style={{flexBasis: "35%"}} >
                            <p className="text-center" tabIndex={0} role={TEXTBOX}> <b>{UK_TEXT}</b> <br/>{UK_PHONE}</p>
                        </div>
                    </div>

                    
                    <div style={{display:"flex", justifyContent: "center"}}>
                        <div style={{marginRight: "27px" , flexBasis: "30%"}} >
                            <p className="text-center" tabIndex={0} role={TEXTBOX}> <b>{NED_TEXT}</b> <br/>{NED_PHONE}</p>
                        </div>

                        <div style={{flexBasis: "35%"}} >
                            <p className="text-center" tabIndex={0} role={TEXTBOX}> <b>{INT_TEXT}</b> <br/>{INT_PHONE}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <YuJaButton
                        aria-label={SUPPORT_CENTER_BTN}
                        onClick={() => {
                            setShowPhoneModal(false);
                            setName(userName);
                            setEmail(userEmail);
                            setMessage("");
                            setSubject("");
                        }} >
                        {MODAL_CLOSE_TEXT}
                    </YuJaButton>
                </Modal.Footer>
            </Modal>


            {/* EMAIL MODAL */}
            <Modal
                id="email-modal"
                show={showEmailModal}
                onHide={() => setShowEmailModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={false}
            >
                <ModalClose tabIndex={0} aria-label={SUPPORT_CENTER_BTN} role={TEXTBOX} onClick={() => setShowEmailModal(false)} className="modal-close" />
                <Modal.Header
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Modal.Title role={TEXTBOX} tabIndex={0} className="modal-title" id="contained-modal-title-vcenter">
                        {YUJA_SUPPORT}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p tabIndex={0} role={TEXTBOX} className="text-center">{EMAIL_SUPPORT_BODY}</p>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{order: "1" , flex: "1"}} className="text-center"> <FontAwesomeIcon icon={faEnvelope} size="2x"/> </div>
                        <div tabIndex={0} role={TEXTBOX} style={{order: "2", flex: "4"}}><p>{EMAIL_SUPPORT_PREFIX} <a href="mailto: support@yuja.com">{SUPPORT_EMAIL_ID}</a> </p> </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <YuJaButton
                        role={TEXTBOX}
                        aria-label={SUPPORT_CENTER_BTN} 
                        onClick={() => setShowEmailModal(false)}
                    >
                        {MODAL_CLOSE_TEXT}
                    </YuJaButton>
                </Modal.Footer>
            </Modal>


            {/* MESSAGE MODAL */}
            <Modal
                id="message-modal"
                show={showMessageModal}
                onHide={() => setShowMessageModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={false}
            >
                <ModalClose role={TEXTBOX} tabIndex={0} aria-label={SUPPORT_CENTER_BTN} onClick={() => {setShowMessageModal(false); setName(userName); setEmail(userEmail); setMessage(""); setSubject(""); }} className="modal-close" />
                <Modal.Header
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Modal.Title role={TEXTBOX} tabIndex={0} className="modal-title" id="contained-modal-title-vcenter">
                        {YUJA_SUPPORT}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <p tabIndex={0} role={TEXTBOX} className="text-center">{MESSAGE_SUPPORT_BODY}</p>
                        <div className="form-group ">
                            <label tabIndex={0} role={TEXTBOX} htmlFor="inputName">{NAME_TEXT}</label>
                            <input defaultValue={userName} style={{marginTop: "5px"}} id="inputName" type="name" className="form-control form-control-lg" aria-describedby="nameHelp" placeholder="Enter name" onChange={handleChangeName}></input>
                        </div>
                        <div style={{marginTop: "5px"}} className="form-group">
                            <label tabIndex={0} role={TEXTBOX} htmlFor="inputEmail">{EMAIL_TEXT}</label>
                            <input defaultValue={userEmail} style={{marginTop: "5px"}} id="inputEmail" type="email" className="form-control form-control-lg" aria-describedby="emailHelp" placeholder="Enter email" onChange={handleChangeEmail}></input>
                        </div>
                        <div style={{marginTop: "5px"}} className="form-group">
                            <label tabIndex={0} role={TEXTBOX} htmlFor="inputSubject">{SUBJECT_TEXT}</label>
                            <input style={{marginTop: "5px"}} id="inputSubject" className="form-control form-control-lg" aria-describedby="subjectHelp" placeholder="Enter subject" onChange={handleChangeSubject}></input>
                        </div>
                        <div style={{marginTop: "5px"}} className="form-group">
                            <label tabIndex={0} role={TEXTBOX} htmlFor="inputMessage">{MESSAGE_TEXT}</label>
                            <textarea style={{marginTop: "5px"}} id="inputMessage" className="form-control form-control-lg" aria-describedby="messageHelp" rows="5" placeholder="Enter message" onChange={handleChangeMessage}></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <YuJaButton
                        role={TEXTBOX}
                        aria-label={SUPPORT_CENTER_BTN} 
                        type="secondary"
                        onClick={() => {
                            setShowMessageModal(false);
                            setName(userName);
                            setEmail(userEmail);
                            setMessage("");
                            setSubject("");
                        }}
                    >{MODAL_CLOSE_TEXT}</YuJaButton>
                    <YuJaButton
                        onClick={() => {
                            if(validateForm()) {
                                handleSubmit();
                                notifySuccess(REQUEST_SEND_SUCCESS, SEND_REQUEST_TITLE);
                            }
                        }}
                    >{MODAL_SEND_TEXT}</YuJaButton>
                </Modal.Footer>
            </Modal>
    </>
    )

}