import {useParams} from "react-router-dom";
import {POLL_TYPE} from "../../utils/constants";

import PPTCreateAttendancePage from "./PPTCreateAttendancePage";
import PPTCreatePollPage from "./PPTCreatePollPage";

export default function PPTCreateActivityParentPage({}) {
    const { pollType } = useParams();


    return (
        <>
            {pollType === POLL_TYPE.ATTENDANCE ?
                <PPTCreateAttendancePage />
                :
                <PPTCreatePollPage />
            }
        </>
    );

}