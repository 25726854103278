import React, {useState} from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import YuJaButton from "../../components/standardization/YuJaButton";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import {MCSS_ADD_OPTION_TEXT} from "../../utils/constants";
import { intToChar, notifyInfo } from "../../utils/helpers";
import { addOptionToQuestion } from "../../utils/questionUtils";
import { ADD_OPTIONS_INFO } from "../../utils/toast-message-constants";
import "../styles.css";
import MCQuestionOption from "./MCQuestionOption";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";

export default function MCQuestion({
  setQuestions,
  questions,
  selectedQuestion,
  pollKey,
  changePollKey,
  pollType,
  questionBankId,
  dataloading=false
}) {
  const [warningShowed, setWarningShowed] = useState(false);
  const { optionsMap, serialNo} = selectedQuestion;
  const {isNarrow} = useCheckMainContainerNarrow();

  const [showButton, setShowButton] = useState(true);

  const handleDragStart = () => {
    setShowButton(false);
  };

  const handleDragEnd = () => {
    setShowButton(true);
  };


  const handleAnswerOrderChange = (result) => {
    if (!result.destination) return;

    const sourceKey = intToChar(result.source.index);
    const destinationKey = intToChar(result.destination.index);

    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let updatedQuestion = {...q};

        let newOptionsMap = JSON.parse(JSON.stringify(q.optionsMap));
        newOptionsMap[sourceKey] = q.optionsMap[destinationKey];
        newOptionsMap[destinationKey] = q.optionsMap[sourceKey];
        updatedQuestion.optionsMap = newOptionsMap;

        let newCorrectAns = q.correctAnswers;
        const correctAnswer = q.correctAnswers[0];
        if (sourceKey === correctAnswer) {
          newCorrectAns = [destinationKey];
        } else if (destinationKey === correctAnswer) {
          newCorrectAns = [sourceKey];
        }
        updatedQuestion.correctAnswers = newCorrectAns;

        if (!!q.optionsImageMap) {
          let newOptionsImageMap = {...q.optionsImageMap};
          newOptionsImageMap[sourceKey] = q.optionsImageMap[destinationKey];
          newOptionsImageMap[destinationKey] = q.optionsImageMap[sourceKey];
          if (!newOptionsImageMap[sourceKey]) {
            delete newOptionsImageMap[sourceKey];
          }
          if (!newOptionsImageMap[destinationKey]) {
            delete newOptionsImageMap[destinationKey];
          }
          updatedQuestion.optionsImageMap = newOptionsImageMap;
        }
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push(q);
      }
    });
    setQuestions(newQuestions);
  };

  return (
        <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={(result) => {
              handleDragEnd();
              handleAnswerOrderChange(result);
            }}
        >
          {!dataloading &&
              <Droppable droppableId="droppable-multiple-choice-question">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        {...provided.dropHandleProps}
                        className="droppable-multiple-choice-question-options"
                        id="mcq-options-container"
                        style={{marginTop: "10px", display: "flex", flexDirection: "column",  gap: isNarrow ? 10 : 20}}
                    >
                      {!!optionsMap && typeof optionsMap === 'object' && Object.entries(optionsMap).map(([choice, optionValue], index) => {
                        return (
                              <MCQuestionOption
                                  pollKey={pollKey}
                                  changePollKey={changePollKey}
                                  optionData={{ choice: choice, optionValue: optionValue }}
                                  selectedQuestion={selectedQuestion}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  index={index}
                                  key={index}
                                  pollType={pollType}
                                  questionBankId={questionBankId}
                                  maxSize={Object.entries(optionsMap).length}
                              />
                        );
                      })}
                      {showButton ?
                          <div style={{width: "100%", display: "flex", justifyContent: "center", paddingBottom: 5}}>
                            <YuJaButton
                                title="Add Option"
                                onClick={() => {
                                  const newQuestions = addOptionToQuestion(
                                      selectedQuestion,
                                      questions
                                  );
                                  if (newQuestions) {
                                    setQuestions(newQuestions);
                                    setTimeout(() => {
                                      const mcqOptionsContainer = document.getElementById("mcq-options-container");
                                      mcqOptionsContainer?.scroll({ top: mcqOptionsContainer.scrollHeight, behavior: "smooth" });
                                    }, 10);
                                  } else if (!warningShowed) {
                                    setWarningShowed(true);
                                    notifyInfo(ADD_OPTIONS_INFO);
                                  }
                                }}
                            >
                              <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/>  {MCSS_ADD_OPTION_TEXT}
                            </YuJaButton>
                          </div>
                          : null}

                      {provided.placeholder}
                    </div>
                )}
              </Droppable>
          }
          {
              dataloading &&
              <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                <Skeleton height={52} borderRadius={10} style={{ position: "relative", marginTop:20 }} />
                <Skeleton height={52} borderRadius={10} style={{ position: "relative" , marginTop:20}} />
                <Skeleton height={52} borderRadius={10} style={{ position: "relative", marginTop:20 }} />
                <Skeleton height={52} borderRadius={10} style={{ position: "relative" , marginTop:20}} />
              </SkeletonTheme>
          }
        </DragDropContext>
  );
}
