import React, { useEffect, useState } from "react";
import styles from "./Button.module.css";

export default React.memo(NormalButton);
function NormalButton({
  children,
  sizeType = "mid",
  onClick,
  disabledFlag = false,
  style = {},
  className,
  title="",
  disabled = false
}) {
  const [classNamesState, setClassNamesState] = useState(
    className ? className.split(" ") : []
  );

  useEffect(() => {
    const clsNames = [className];
    if (!disabledFlag) {
      clsNames.push(styles.normalButton);
    } else {
      clsNames.push(styles.disabledButton);
    }
    switch (sizeType) {
      case "big": {
        clsNames.push(styles.bigButton);
        clsNames.push(styles.bigButtonFont);
        break;
      }
      default: {
        clsNames.push(styles.midButton);
        clsNames.push(styles.midButtonFont);
      }
    }

    setClassNamesState(clsNames);
  }, [className, disabledFlag, sizeType]);

  return (
    <>
    {disabled ? 
      <button style={style} onClick={onClick} title={title} className={classNamesState.join(" ")} disabled>
        {children}
      </button>
      : <button style={style} onClick={onClick} title={title} className={classNamesState.join(" ")}>
          {children}
        </button>}
    </>
  );
}
