import {
    ACT_ITEMS,
    ACTION_HEADER,
    DEFAULT_PAGINATION_SIZE,
    NO_RESULTS_FOUND, REPORT_COURSE_TITLE,
    REPORT_INSTITUTION_COURSE_TABLE_HEADERS,
    REPORT_INSTITUTION_TABLE_SEARCH_BOX_PLACEHOLDER,
    SEARCH_POLL,
    SEARCH_TEXT,
    TEXTBOX,
} from "../../utils/constants";

import classes from "./Report.module.css"
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import React, {useEffect, useState} from "react";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";

import YuJaButton from "../../components/standardization/YuJaButton";
import {useGlobalFilter, usePagination, useTable} from "react-table";
import styles from "../ManagePollsPage/ManagePolls.module.css";
import {Table} from "react-bootstrap";
import {YuJaKebabMenu} from "../../components/standardization/YuJaKebabMenu";
import NormalPagination from "../../components/pagination/NormalPagination";
import {useHistory} from "react-router-dom";

const columns = [
    { Header: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.description, accessor: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.name, width: 90},
    { Header: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_CODE.description, accessor: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_CODE.name, width: 120 },
    { Header: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_NAME.description, accessor: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_NAME.name, width: 500 },
    { Header: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.TOTAL_ACTIVITIES.description, accessor: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.TOTAL_ACTIVITIES.name, width: 100 },
    { Header: REPORT_INSTITUTION_COURSE_TABLE_HEADERS.ACTION.description, accessor: "", width: 90 }
];

export default function TabCourses({data, setData, reportStartDate, reportEndDate}) {
    let history = useHistory();

    const [keyword, setKeyword] = useState();
    const [pageIdx, setPageIdx] = useState(0);
    const [filter, setFilter] = useState({});
    const [sortSelected, setSortSelected] = useState(0);
    const [sortRank, setSortRank] = useState(1);
    const [sortCode, setSortCode] = useState(-1);
    const [sortName, setSortName] = useState(-1);


    const clickSearch = () => {
        setFilter({keyword});
        setPageIdx(0);
    }

    useEffect(() => {
        setGlobalFilter(filter);
    }, [data, filter]);




    const filterCustomize = (rows, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.courseName && values.courseName.toLowerCase().indexOf(filteredKeyword.toLowerCase()) > -1) {
                return true;
            }

            if (r.original.courseCode && r.original.courseCode.toLowerCase().indexOf(filteredKeyword.toLowerCase()) > -1) {
                return true;
            }

            return false;
        });
    };


    const checkDisplayDownArrow = (Header) => {
        if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.description && sortRank === -1 && sortSelected === 0) {
            return true;
        }
        else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_CODE.description && sortCode === -1 && sortSelected === 1) {
            return true;
        }
        else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_NAME.description && sortName === -1 && sortSelected === 2) {
            return true;
        }
        return false;
    }

    const checkDisplayUpArrow= (Header) => {
        if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.description && sortRank === 1 && sortSelected === 0) {
            return true;
        }
        else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_CODE.description && sortCode === 1 && sortSelected === 1) {
            return true;
        }
        else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_NAME.description && sortName === 1 && sortSelected === 2) {
            return true;
        }
        return false;
    }

    const checkDisplayGreyArrow = (Header) => {
        if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.description && sortSelected !== 0) {
            return true;
        }
        else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_CODE.description && sortSelected !== 1) {
            return true;
        }
        else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_NAME.description && sortSelected !== 2) {
            return true;
        }
        return false;
    }

    const handleSort = (Header) => {
        if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.description) {
            sortRankHelper();
            setSortSelected(0);
        } else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_CODE.description) {
            sortCodeHelper();
            setSortSelected(1);
        } else if (Header === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.COURSE_NAME.description){
            sortNameHelper();
            setSortSelected(2);
        }
    };

    const sortRankHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortRank === 1){
                copy = copy.sort(function (a, b) {
                    return a.activityCount - b.activityCount;
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return b.activityCount - a.activityCount;
                });
            }
            setData(copy);
        }
        setSortRank(-1 * sortRank);
    }

    const sortCodeHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortCode === 1){
                copy = copy.sort(function (a, b) {
                    return b.courseCode.localeCompare(a.courseCode);
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.courseCode.localeCompare(b.courseCode);
                });
            }
            setData(copy);
        }
        setSortCode(-1 * sortCode);
    }

    const sortNameHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortName === 1){
                copy = copy.sort(function (a, b) {
                    return b.courseName.localeCompare(a.courseName);
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.courseName.localeCompare(b.courseName);
                });
            }
            setData(copy);
        }
        setSortName(-1 * sortName);
    }


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize},
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_PAGINATION_SIZE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);


    const onClickViewCourseReport = (courseId) => {
        if (!!courseId) {
            document.getElementsByTagName("main")[0].scrollTo(0, 0);
            history.push({
                pathname: `/report/course/${courseId}`,
                state: { reportStartDate, reportEndDate}
            });
        }
    }

    return (
        <>
            <div className={classes.tableSearchBox}>
                <YuJaTextBox
                    label={SEARCH_POLL}
                    containerStyle={{fontSize: 15, marginLeft: 10, width: "70%"}}
                    placeholder={REPORT_INSTITUTION_TABLE_SEARCH_BOX_PLACEHOLDER}
                    onChange={e => setKeyword(e.target.value)}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                />
                <YuJaButton onClick={clickSearch}>
                    <div style={{fontSize: 14}}>{SEARCH_TEXT}</div>
                </YuJaButton>
            </div>
            <div className={classes.tableWrapper}>
                <Table hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps({
                                            style: {
                                                width: column.width,
                                                textAlign: column.render('Header') === ACTION_HEADER ? 'center' : 'left'
                                            }
                                        })}
                                    >
                                            <span
                                                tabIndex={0}
                                                role={TEXTBOX}
                                                className={column.render('Header') === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.description
                                                      ? classes.firstCell : classes.tableCell}
                                            >
                                                {column.render('Header')}
                                                {checkDisplayDownArrow(column.render('Header')) &&
                                                    <SortDown
                                                        tabIndex={0}
                                                        role={TEXTBOX}
                                                        aria-label={column.render('Header') + "descending order"}
                                                        style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => handleSort(column.render('Header'))}
                                                    />
                                                }

                                                {checkDisplayUpArrow(column.render('Header')) &&
                                                    <SortUp
                                                        tabIndex={0}
                                                        role={TEXTBOX}
                                                        aria-label={column.render('Header') + "ascending order"}
                                                        style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => handleSort(column.render('Header'))}
                                                    />
                                                }

                                                {checkDisplayGreyArrow(column.render('Header')) &&
                                                    <SortGrey
                                                        role={TEXTBOX}
                                                        tabIndex={0}
                                                        aria-label={column.render('Header') + "ascending order"}
                                                        style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => handleSort(column.render('Header'))}
                                                    />

                                                }
                                            </span>


                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 &&
                            page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr key={index}{...row.getRowProps()}>
                                        {row.cells.map((cell, index) => {
                                            if (cell.column.Header === ACTION_HEADER) {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                        <div aria-label={ACT_ITEMS + row.cells.at(index - 4).value}
                                                             style={{display: "flex", justifyContent: "center"}}>
                                                            <YuJaKebabMenu>
                                                                <YuJaKebabMenu.Menu>
                                                                    <YuJaKebabMenu.Item
                                                                        onClick={() => onClickViewCourseReport(cell.row.original.courseId)}>
                                                                        {REPORT_COURSE_TITLE}
                                                                    </YuJaKebabMenu.Item>
                                                                </YuJaKebabMenu.Menu>
                                                            </YuJaKebabMenu>
                                                        </div>
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td key={index} role={TEXTBOX}>
                                                        <div
                                                            tabIndex={0}
                                                            className={cell.column.render('Header') === REPORT_INSTITUTION_COURSE_TABLE_HEADERS.RANK.description ? classes.firstCell : classes.tableCell}>
                                                            {cell.value}
                                                        </div>
                                                    </td>
                                                );
                                            }
                                        })}
                                    </tr>
                                );
                            })
                        }


                        {(!page || page.length === 0) &&
                            <tr>
                                <td colSpan="5" className={styles.noData} style={{boxShadow: "none"}}>
                                    <div
                                        style={{
                                            justifyContent: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: 5,
                                            padding: 20
                                        }}
                                    >
                                        <NoResultIllustration/>
                                        <div style={{
                                            fontSize: "18px",
                                            marginTop: "10px",
                                            fontWeight: 600
                                        }}> {NO_RESULTS_FOUND} </div>
                                    </div>
                                </td>
                            </tr>

                        }
                    </tbody>
                </Table>
            </div>
            {page && pageCount > 1 &&
                <div style={{flex: 1, display: "flex", alignItems: "flex-end"}}>
                    <NormalPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                        paddingTop="0px"
                    />
                </div>
            }
        </>
    );
}