import React, { useEffect, useState } from "react";
import { Form, Card } from "react-bootstrap";
import useHostname from "../../hooks/useHostname";
import LoginIcon from "../../images/login_blue.svg";

import {
    DEFAULT_LOGIN_METHODS,
    LOGIN_SETTINGS_ERROR_MSG,
    LMS_GRADEBOOK_SYNC,
    PLATFORM_SETTINGS,
    YUJA_DISABLE_LABEL,
    TEXTBOX,
    QUES_BANK_CHECKBOX,
    SETT_LABEL,
    RADIAL_BTN,
    PWD_EXPIRY_LABEL,
    ATTENDANCE_DETAILS_SETTINGS,
    SAVED_LOCATIONS_TITLE,
    SAVED_LOCATIONS,
    USER_SETTINGS,
    DEFAULT_ATTENDANCE_SAVED_LOCATIONS,
    PLATFORM_SETTING_SAVE_ERROR_MSG,
    ATTENDANCE_SETTINGS,
    ATTENDANCE_SETTINGS_CARD_SUB_TITLE,
    ATTENDANCE_SETTINGS_GEO_LOCATION_SUB_TITLE,
    PLATFORM_SETTING_SAVED_LOCATIONS_MODAL_DESCRIPTION, GEOFENCED_LOCATIONS_TITLE, CONFIGURE_TEXT
} from "../../utils/constants";
import {checkAllEmpty, notifyError, notifySuccess} from "../../utils/helpers";
import styles from "./platform.module.css";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {ReactComponent as AttendanceDetailsIcon} from "../../images/attendance_details.svg";
import YuJaButton from "../../components/standardization/YuJaButton";
import SavedLocationsModal from "../../components/modals/SavedLocationsModal";
import {InstitutionApiCalls} from "../../apis/InstitutionApiCalls";
import useHostResource from "../../hooks/useHostResource";
import {SAVE_SETTINGS_SUCCESS} from "../../utils/toast-message-constants";



export default function PlatformSettingTab({platformSettings, setPlatformSettings, loading=false, setLoading}) {
    const hostResource = useHostname(window.location.hostname);
    const {updatePlatformSetting} = useHostResource(window.location.hostname);
    const {updateSetting} = InstitutionApiCalls();
    const [, setLMSAttemptSetting] = useState("");
    const [showADFS, setShowADFS] = useState(false);
    const [showSHIBBOLETH, setShowSHIBBOLETH] = useState(false);
    const [showDCAM, setShowDCAM] = useState(false);
    const [showGOOGLE, setShowGOOGLE] = useState(false);
    const [showAZURE, setShowAZURE] = useState(false);

    const [showSavedLocationModal, setShowSavedLocationModal] = useState(false);
    const [savedLocations, setSavedLocations] = useState([]);

    useEffect(() => {
        setSavedLocations(JSON.parse(platformSettings && platformSettings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS)
            ? platformSettings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS]
            : DEFAULT_ATTENDANCE_SAVED_LOCATIONS)
        );
    }, [platformSettings]);


    useEffect(() => {
        const {platformSetting, ssoConfig={}} = hostResource;
        if (platformSetting && platformSetting.hasOwnProperty(PLATFORM_SETTINGS.LMS_QUIZ_ATTEMPTS)) {
            const LMSQuizAttempts = platformSetting[PLATFORM_SETTINGS.LMS_QUIZ_ATTEMPTS];
            setLMSAttemptSetting(LMSQuizAttempts);
        }
        if(ssoConfig.ADFS && !checkAllEmpty(ssoConfig.ADFS)) {
          setShowADFS(true);
        }
        if(ssoConfig.SHIBBOLETH && !checkAllEmpty(ssoConfig.SHIBBOLETH)) {
          setShowSHIBBOLETH(true);
        }
        if(ssoConfig.DCAM && !checkAllEmpty(ssoConfig.DCAM)) {
          setShowDCAM(true);
        }
        if(ssoConfig.GOOGLE && !checkAllEmpty(ssoConfig.GOOGLE)) {
          setShowGOOGLE(true);
        }
        if(ssoConfig.AZURE && !checkAllEmpty(ssoConfig.AZURE)) {
          setShowAZURE(true);
        }
    } , [hostResource]) 


    useEffect(() => {
        if (!platformSettings) {
            return; 
        }
        var replaced = false; 
        if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_ADFS && showADFS === false) {
            replaced = true; 
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_SHI && showSHIBBOLETH === false) {
            replaced = true; 
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_DCAM && showDCAM === false) {
            replaced = true; 
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE && showGOOGLE === false) {
            replaced = true;
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_AZURE && showAZURE == false) {
            replaced = true; 
        }

        if (replaced) {
            const copy = JSON.parse(JSON.stringify(platformSettings));
            copy[PLATFORM_SETTINGS.DEFAULT] = "1";
            setPlatformSettings(copy);
        }
    }, [showADFS, showSHIBBOLETH, showDCAM, showGOOGLE, showAZURE, platformSettings]);

    const handleLoginSettingChange = (e) => {
        if (!showADFS && !showSHIBBOLETH && !showDCAM && !showGOOGLE && !showAZURE) {
            notifyError(LOGIN_SETTINGS_ERROR_MSG)
            return; 
        }
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN && input.checked) {
            if (showADFS) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_ADFS;
            } else if (showSHIBBOLETH) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_SHI;
            } else if (showDCAM) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_DCAM;
            } else if (showGOOGLE) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE;
            } else if (showAZURE) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_AZURE;
            }

        }
        copy[PLATFORM_SETTINGS.YUJA_LOGIN] = input.checked.toString();
        setPlatformSettings(copy);
    };


    const handlePwdResetChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.PWD_RESET] = input.checked.toString();
        setPlatformSettings(copy);
    };

    const handleYujaLoginNameChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.YUJA_NAME] = input.value;
        setPlatformSettings(copy);
    }; 

    const handleSSONameChangeADFS = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_ADFS] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONameChangeSHI = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_SHI] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONameChangeDCAM = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_DCAM] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONAMEChangeGOOGLE = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_GOOGLE] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONAMEChangeAZURE = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_AZURE] = input.value;
        setPlatformSettings(copy);
    }

    const handleDefaultChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.DEFAULT] = input.value;
        setPlatformSettings(copy);
    }

    const savePlatformSettings = (settings) => {
        setLoading(true);
        Promise.all([updateSetting(settings), updatePlatformSetting(settings)])
            .then(() => {
                notifySuccess(SAVE_SETTINGS_SUCCESS);
            }).finally(() => {
                setLoading(false)
            });
    };

    return (
        <>
            <SavedLocationsModal
                show={showSavedLocationModal}
                setShow={setShowSavedLocationModal}
                savedLocations={savedLocations}
                setSavedLocations={setSavedLocations}
                userSettings={platformSettings}
                setUserSettings={setPlatformSettings}
                saveSettingsCallback={savePlatformSettings}
                description={PLATFORM_SETTING_SAVED_LOCATIONS_MODAL_DESCRIPTION}
            />
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 20}}>
            <Card className={styles.smallBox} style={{minWidth: 500}}>
                <Card.Header>
                    <img src={LoginIcon} alt="LoginIcon" width={"24px"} height={"24px"} />
                    <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>Login Settings</span>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group style={{fontSize: 14}}>
                        {!loading &&
                        <>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div tabIndex={0} role={TEXTBOX} className={styles.titleText} style={{flex: 3}}>Enterprise Login</div>
                                <div style={{flex: 5}}></div>
                                <div tabIndex={0} role={TEXTBOX} className={styles.titleText} style={{flex: 3,  textAlign: "center"}}>Default</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                <div style={{flex: 3}}>YuJa Login</div>
                                <div style={{flex: 5}}>
                                    <YuJaTextBox
                                        label={"YuJa Login" + SETT_LABEL}
                                        containerStyle={{fontSize: "14px"}}
                                        disabled={platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true"}
                                        type="text"
                                        value={platformSettings[PLATFORM_SETTINGS.YUJA_NAME]}
                                        onChange={handleYujaLoginNameChange}
                                    />
                                </div>
                                <div style={{flex: 3}}>
                                    <YuJaRadioBox
                                        aria-label={"YuJa Login" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === null || platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN) ? "selected" : "unselected")}
                                        size={20}
                                        value={DEFAULT_LOGIN_METHODS.YUJA_LOGIN}
                                        disabled={platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true"}
                                        checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === null || platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN}
                                        onClick={handleDefaultChange}
                                    />
                                </div>
                            </div>
                            {showADFS &&
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                <div style={{flex: 3}}>SSO - ADFS</div>
                                <div style={{flex: 5}}>
                                    <YuJaTextBox
                                        label={"SSO - ADFS" + SETT_LABEL}
                                        containerStyle={{ fontSize: "14px" }}
                                        type="text"
                                        value={platformSettings[PLATFORM_SETTINGS.SSONAME_ADFS]}
                                        onChange={handleSSONameChangeADFS}
                                    />
                                </div>
                                <div style={{flex: 3}}>
                                    <YuJaRadioBox
                                        aria-label={"SSO - ADFS" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_ADFSN) ? "selected" : "unselected")}
                                        size={20}
                                        value={DEFAULT_LOGIN_METHODS.SSONAME_ADFS}
                                        checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_ADFS}
                                        onClick={handleDefaultChange}
                                    />
                                </div>
                            </div>}
                            {showSHIBBOLETH &&
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                <div style={{flex: 3}}>SSO - SHIBBOLETH</div>
                                <div style={{flex: 5}}>
                                    <YuJaTextBox
                                        label={"SSO - SHIBBOLETH" + SETT_LABEL}
                                        containerStyle={{ fontSize: "14px" }}
                                        type="text"
                                        value={platformSettings[PLATFORM_SETTINGS.SSONAME_SHI]}
                                        onChange={handleSSONameChangeSHI}
                                    />
                                </div>
                                <div style={{flex: 3}}>
                                    <YuJaRadioBox
                                        aria-label={"SSO - SHIBBOLETH" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_SHI) ? "selected" : "unselected")}
                                        size={20}
                                        value={DEFAULT_LOGIN_METHODS.SSONAME_SHI}
                                        checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_SHI}
                                        onClick={handleDefaultChange}
                                    />
                                </div>
                            </div>}
                            {showDCAM &&
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                <div style={{flex: 3}}>SSO - DCAM</div>
                                <div style={{flex: 5}}>
                                    <YuJaTextBox
                                        label={"SSO - DCAM" + SETT_LABEL}
                                        containerStyle={{ fontSize: "14px" }}
                                        type="text"
                                        value={platformSettings[PLATFORM_SETTINGS.SSONAME_DCAM]}
                                        onChange={handleSSONameChangeDCAM}
                                    />
                                </div>
                                <div style={{flex: 3}}>
                                    <YuJaRadioBox
                                        aria-label={"SSO - DCAM" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.SSONAME_DCAM]) ? "selected" : "unselected")}
                                        size={20}
                                        value={DEFAULT_LOGIN_METHODS.SSONAME_DCAM}
                                        checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_DCAM}
                                        onClick={handleDefaultChange}
                                    />
                                </div>
                            </div>}
                            {showGOOGLE &&
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                <div style={{flex: 3}}>SSO - GOOGLE</div>
                                <div style={{flex: 5}}>
                                    <YuJaTextBox
                                        label={"SSO - GOOGLE" + SETT_LABEL}
                                        containerStyle={{ fontSize: "14px" }}
                                        type="text"
                                        value={platformSettings[PLATFORM_SETTINGS.SSONAME_GOOGLE]}
                                        onChange={handleSSONAMEChangeGOOGLE}
                                    />
                                </div>
                                <div style={{flex: 3}}>
                                    <YuJaRadioBox
                                        aria-label={"SSO - GOOGLE" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE) ? "selected" : "unselected")}
                                        size={20}
                                        value={DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE}
                                        checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE}
                                        onClick={handleDefaultChange}
                                    />
                                </div>
                            </div>}
                            {showAZURE &&
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px"}}>
                                <div style={{flex: 3}}>SSO - AZURE</div>
                                <div style={{flex: 5}}>
                                    <YuJaTextBox
                                        label={"SSO - AZURE" + SETT_LABEL}
                                        containerStyle={{ fontSize: "14px" }}
                                        type="text"
                                        value={platformSettings[PLATFORM_SETTINGS.SSONAME_AZURE]}
                                        onChange={handleSSONAMEChangeAZURE}
                                    />
                                </div>
                                <div style={{flex: 3}}>
                                    <YuJaRadioBox
                                        aria-label={"SSO - AZURE" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_AZURE) ? "selected" : "unselected")}
                                        size={20}
                                        value={DEFAULT_LOGIN_METHODS.SSONAME_AZURE}
                                        checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_AZURE}
                                        onClick={handleDefaultChange}
                                    />
                                </div>
                            </div>}
                            <YuJaCheckbox
                                size={20}
                                style={{fontSize: 14}}
                                ariaLabel={QUES_BANK_CHECKBOX + YUJA_DISABLE_LABEL + ((platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true" || platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === true) ? "checked" : "unchecked")}
                                label={YUJA_DISABLE_LABEL}
                                checked={platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true" || platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === true}
                                onClick={handleLoginSettingChange}
                                id="platformLastElem"
                            />
                            <div style={{height: 10}}/>
                            <YuJaCheckbox
                                size={20}
                                style={{fontSize: 14}}
                                ariaLabel={PLATFORM_SETTINGS.PWD_RESET + (platformSettings[PLATFORM_SETTINGS.PWD_RESET] === "true"  ? "checked" : "unchecked")}
                                label={PWD_EXPIRY_LABEL}
                                checked={platformSettings[PLATFORM_SETTINGS.PWD_RESET] === "true"}
                                onClick={handlePwdResetChange}
                            />

                            </>
                        }

                            { loading &&
                                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                            <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                <Skeleton height={21} width={160}  inline={true} borderRadius={10} />
                                            </div>
                                            <div style={{ flex: 5 }}>
                                            </div>
                                            <div style={{ flex: 3, display: "flex", justifyContent: "center" }}>
                                                <Skeleton height={21} width={50} inline={true} borderRadius={10} />
                                            </div>
                                        </SkeletonTheme>
                                            </div>
                                            <div style={{display: "flex"}}>
                                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                            <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                <Skeleton height={19} width={90}  inline={true} borderRadius={10} />
                                            </div>
                                            <div style={{ flex: 5 }}>
                                                <Skeleton height={37} inline={true} borderRadius={10} />
                                            </div>
                                            <div style={{ flex: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true} />
                                            </div>
                                        </SkeletonTheme>
                                        </div>
                                        <div style={{display: "flex"}}>
                                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                            <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                            <Skeleton height={19} width={90}  borderRadius={10} inline={true} />
                                            </div>
                                                <div style={{ flex: 5}}>
                                                    <Skeleton height={37} borderRadius={10} inline={true} />
                                                </div>
                                                <div style={{ flex: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                                </div>
                                            </SkeletonTheme>
                                            </div>
                                            <div style={{display: "flex"}}>
                                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                <Skeleton height={19} width={110} borderRadius={10}  inline={true} />
                                                </div>
                                                <div style={{ flex: 5 }}>
                                                    <Skeleton height={37} borderRadius={10} inline={true} />
                                                </div>
                                                <div style={{ flex: 3, display: "flex", justifyContent: "center" , alignItems: "center"}}>
                                                    <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                                </div>
                                            </SkeletonTheme>
                                            </div>
                                            <div style={{display: "flex"}}>
                                                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                            <div style={{ flex: 3, display: "flex", alignItems: "center" }}>
                                                <Skeleton height={19} width={90} borderRadius={10} inline={true} />
                                            </div>
                                                    <div style={{ flex: 5 }}>
                                                        <Skeleton height={37} borderRadius={10} inline={true} />
                                                    </div>
                                                    <div style={{ flex: 3, display: "flex", justifyContent: "center" , alignItems: "center"}}>
                                                        <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                                    </div>
                                                </SkeletonTheme>
                                                </div>
                                                <div style={{display: "flex"}}>
                                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                        <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                            <Skeleton height={19} width={90}  borderRadius={10} inline={true} />
                                            </div>
                                        <div style={{ flex: 5 }}>
                                            <Skeleton height={37} borderRadius={10} inline={true} />
                                        </div>
                                        <div style={{ flex: 3, display: "flex", justifyContent: "center" , alignItems: "center"}}>
                                            <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                        </div>
                                    </SkeletonTheme>
                                    </div>
                                </div>
                                }
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            <Card className={styles.smallBox} style={{minWidth: 500}}>
                <Card.Header>
                    <AttendanceDetailsIcon style={{width: 24, height: 24}}/>
                    <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>
                        {ATTENDANCE_SETTINGS}
                    </span>
                </Card.Header>
                <Card.Body>
                    {!loading &&
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <span
                                className={styles.descriptionText}
                                style={{ marginBottom: 10}}
                            >
                                {ATTENDANCE_SETTINGS_CARD_SUB_TITLE}
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    flexWrap: "wrap"
                                }}
                            >
                                <div>
                                    <div
                                        className={styles.titleText}
                                        style={{whiteSpace: "nowrap"}}
                                         role={TEXTBOX}
                                         tabIndex={0}>
                                        {GEOFENCED_LOCATIONS_TITLE}
                                    </div>
                                    <span className={styles.descriptionText} style={{fontWeight: 400}}>
                                        {ATTENDANCE_SETTINGS_GEO_LOCATION_SUB_TITLE}
                                    </span>
                                </div>

                                <YuJaButton style={{fontSize: 14}} onClick={() => setShowSavedLocationModal(true)}>
                                    {CONFIGURE_TEXT}
                                </YuJaButton>
                            </div>
                        </div>
                    }
                    {!!loading &&
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                                <Skeleton height={19} width={"50%"} borderRadius={10} inline={true}/>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap",
                                        marginTop: 20
                                    }}
                                >
                                    <div style={{flex: 1}}>
                                        <div>
                                            <Skeleton height={19} width={100} borderRadius={10} inline={true}/>
                                        </div>
                                        <div style={{marginTop: 10}}>
                                            <Skeleton height={19} width={"50%"} borderRadius={10} inline={true}/>
                                        </div>
                                    </div>

                                    <Skeleton height={30} width={150} borderRadius={10} inline={true}/>
                                </div>


                            </SkeletonTheme>
                        </div>
                    }

                </Card.Body>
            </Card>
        </div>
        </>
    );
}