import React, { useCallback, useEffect, useState } from "react";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as NoEnrolledCourses } from "../../images/NoActivityFound.svg";
import { NO_COURSES_DESC, NO_COURSES_TITLE, TEXTBOX, MENU_LIST } from "../../utils/constants";
import { getPriority } from "../../utils/helpers";
import styles from "./EnrolledCourses.module.css";
import EnrolledCoursesSearchForm from "./EnrolledCoursesSearchForm";
import EnrolledCoursesTable from "./EnrolledCoursesTable";

export default function ViewEnrolledCoursesPage(props) {
  const [filter, setFilter] = useState({});
  const [courseData, setCourseData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [courseNameSort, setCourseNameSort] = useState(1);
  const [courseCodeSort, setCourseCodeSort] = useState(1);
  const { getCourseByStudent } = CourseApiCalls();
  const [, getSession] = useLocalStorageNew("session", {});

  useEffect(() => {
    props.setPageIdentifier(MENU_LIST.COURSES_STUDENT.name);
    
    const handleWindowResize = () => {
      props.setPageIdentifier(MENU_LIST.COURSES_STUDENT.name);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getCourses = async () => {
    setDataReady(false);
    const session = getSession();
    let res;
    if(session.idpType && session.intType) {
      res = await getCourseByStudent(true, session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
      res = await getCourseByStudent(true);
    }

    res.sort(function (a, b) {
      return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
    })
    setCourseData(res);
    setDataReady(true);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
  }, []);

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: 'Course Code', accessor: 'courseCode', width: isMobileScreen ? 100 : 120 },
    { Header: 'Course Name', accessor: 'courseName', width: isMobileScreen ? 100 : 120 },
    // { Header: 'Instructor\'s Name', accessor: 'instructorsName', width: 120 },
    // { Header: 'Start Date', accessor: 'startDate', width: isMobileScreen ? 120 : 140 },
  ];

  const sortCourseName = () => {
    if(!!courseData) {
      setCourseData(courseData.sort(function (a, b) {
        const nameA = a.courseName?.toLowerCase();
        const nameB = b.courseName?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * courseNameSort;
        if (priorityA > priorityB) return 1 * courseNameSort;

        if (nameA < nameB) return -1 * courseNameSort;
        if (nameA > nameB) return 1 * courseNameSort;
        return 0;
      }));
      setCourseNameSort(-courseNameSort);
    }
  };

  const sortCourseCode = () => {
    if(!!courseData) {
      setCourseData(courseData.sort(function (a, b) {
        const nameA = a.courseCode?.toLowerCase();
        const nameB = b.courseCode?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * courseCodeSort;
        if (priorityA > priorityB) return 1 * courseCodeSort;

        if (nameA < nameB) return -1 * courseCodeSort;
        if (nameA > nameB) return 1 * courseCodeSort;
        return 0;
      }));
      setCourseCodeSort(-courseCodeSort);
    }
  };

  return (
    <>
      {/* {courseData && courseData.length !== 0 || !dataReady ? ( */}
        <>
          <div className={styles.container} id="enrolled-courses-container">
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Student Courses</div>
            </div>
          {(dataReady && courseData.length === 0) ? <div className={styles.noResultContainer} style={{height: "70%"}}>
            <NoEnrolledCourses className={styles.noResultImage}/>
            <div className={styles.noResultTitle}>{NO_COURSES_TITLE}</div>
            <div className={styles.noResultDesc}>{NO_COURSES_DESC}</div>
          </div> : <>
            <EnrolledCoursesSearchForm handleSubmit={handleSubmit} />
            <EnrolledCoursesTable columns={columns} data={courseData} dataReady={dataReady} globalFilterObj={filter} getCourses={getCourses} courseNameSort={courseNameSort} sortCourseName={sortCourseName} courseCodeSort={courseCodeSort} sortCourseCode={sortCourseCode} />
          </>}
          </div>
        </>
      {/* ) : (
        <>
          <div className={styles.c2}>
            <div className={styles.titleBox}>
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>You are not enrolled in any courses.</div>
            </div>
            <div tabIndex={0} role={TEXTBOX} className={styles.text}>Contact a prof to be added to a course.</div>
          </div>
        </>
      )} */}
    </>
  );
}