import React, { useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { TOAST_TYPE } from "../../utils/constants";
import styles from "./Toast.module.css";
import Error from "./animations/Error.json";
import Info from "./animations/Info.json";
import Success from "./animations/Success.json";
import Warning from "./animations/Warning.json";
import { ReactComponent as ClOSE } from "./images/close.svg";
import {useYuJaEventBus} from "../../components/standardization/YuJaEventBusProvider";


export default function NormalToast({toastObj, handlers}) {
    const {startPause, endPause, calculateOffset, updateHeight} = handlers;
    const progressBarRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();

    const offset = calculateOffset(toastObj, {
        reverseOrder: false,
        gutter: 20,
    });
    const ref = (el) => {
        if (el && !toastObj.height) {
            const height = el.getBoundingClientRect().height;
            updateHeight(toastObj.id, height);
        }
    };

    const mouseEnter = useCallback(() => {
        if (!toastObj.duration || toastObj.duration === Infinity) {
            return;
        }
        startPause();
        progressBarRef.current.isPause = true;
    }, [startPause])

    const mouseLeave = useCallback(() => {
        if (!toastObj.duration || toastObj.duration === Infinity) {
            return;
        }
        endPause();
        progressBarRef.current.isPause = false;
    }, [endPause])

    //progress bar animation
    useEffect(() => {
        let prev, progress = 0;

        function increaseRate(timestamp) {
            if (!progressBarRef.current) {
                return;
            }

            const now = timestamp;
            if (!prev) {
                prev = now;
            }
            const elapsed = now - prev;

            if (progress === toastObj.duration) {
                return;
            }

            if (!progressBarRef.current.isPause) {
                progress += elapsed;
            }

            if (progress > toastObj.duration) {
                progressBarRef.current.style.width = "100%";
                toast.dismiss(toastObj.id);
                return;
            }
            if (progressBarRef.current) {
                progressBarRef.current.style.width = (progress * 100 / toastObj.duration).toFixed(2) + "%";
            }
            prev = now;
            window.requestAnimationFrame(increaseRate);
        }

        window.requestAnimationFrame(increaseRate);

    }, [toastObj.duration, toastObj.id]);

    useEffect(() => { // dismiss manual toast on click outside
        function handleClickOutside(event) {
            if (!event.target.closest(`.${styles.toast}`)) {
                var styleStr = `.${styles.toast}`
                toast.dismiss(toastObj.id);
            }
        }

        if (!!toastObj.manualDismiss) {
            addClickEventHandler(handleClickOutside);
        }

        return () => {
            removeClickEventHandler(handleClickOutside);
        };
    }, []);

    return (
        <div
            onMouseLeave={mouseLeave}
            onMouseEnter={mouseEnter}
            key={toastObj.id}
             className={`${styles.horizontalLayout} ${styles.toastContainer}`}
             style={{opacity: toastObj.visible ? 1 : 0, transform: `translateY(${offset}px)`}}
        >
            <div key={toastObj.id}
                 ref={ref}
                 className={`${styles.verticalLayout} ${styles.toast}`}
                 {...toastObj.ariaProps}
            >
                <div className={`${styles.horizontalLayout} ${styles.toastBody}`} style={{position: 'relative', }}>
                    <div className={styles.closeIcon} onClick={() => toast.dismiss(toastObj.id)}>
                        <ClOSE/>
                    </div>
                    {toastObj.toastType === TOAST_TYPE.INFO &&
                        <div style={{width: 32, height: 32, cursor: "none"}}>
                            {/* <div id="hourglass-animation"/> */}
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: Info,
                                }}
                                height={32}
                                width={32}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    }

                    {toastObj.toastType === TOAST_TYPE.SUCCESS &&
                        <div style={{width: 32, height: 32, cursor: "none"}}>
                            {/* <div id="hourglass-animation"/> */}
                            <Lottie
                                options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: Success,
                                }}
                                height={32}
                                width={32}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    }

                    {toastObj.toastType === TOAST_TYPE.WARNING &&
                        <div style={{width: 32, height: 32, cursor: "none"}}>
                            {/* <div id="hourglass-animation"/> */}
                            <Lottie
                                options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: Warning,
                                }}
                                height={32}
                                width={32}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    }
                    {toastObj.toastType === TOAST_TYPE.ERROR &&
                        <div style={{width: 32, height: 32, cursor: "none"}}>
                            {/* <div id="hourglass-animation"/> */}
                            <Lottie
                                options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: Error,
                                }}
                                height={32}
                                width={32}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    }
                    <div style={{width: 1, height: 25, marginLeft: 10, marginRight: 13, backgroundColor: "#CFCFCF"}}/>
                    <div className={styles.msg}>
                        <div style={{fontWeight: 700, fontSize: 15}}>{toastObj.title}</div>
                        {toastObj.message}
                    </div>
                </div>
                {!!toastObj.duration && toastObj.duration !== Infinity &&
                    <div style={{position: 'relative'}}>
                        {toastObj.toastType === TOAST_TYPE.INFO && <div className={`${styles.progressBar} ${styles.info}`} ref={progressBarRef}/>}
                        {toastObj.toastType === TOAST_TYPE.SUCCESS && <div className={`${styles.progressBar} ${styles.success}`} ref={progressBarRef}/>}
                        {toastObj.toastType === TOAST_TYPE.WARNING && <div className={`${styles.progressBar} ${styles.warning}`} ref={progressBarRef}/>}
                        {toastObj.toastType === TOAST_TYPE.ERROR && <div className={`${styles.progressBar} ${styles.error}`} ref={progressBarRef}/>}
                        <div style={{backgroundColor: "#CFCFCF", width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}/>
                    </div>
                }
            </div>
        </div>

    );
}