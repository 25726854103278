import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { POLL_TYPE, PPT_MENU_LIST, PPT_ROOT_PATH, PRESENTATION_PATH } from "../utils/constants";
import { PPT_MGMT_PORTAL_URL } from "../utils/properties";
import useQuery from "../utils/queryparam";
import { useLocalStorageNew } from "./useLocalStorageNew";
import { useSessionValidation } from "./useSessionValidation";

export const usePPTWebView = () => {
    const { appId } = useParams();
    const location = useLocation();
    const query = useQuery();
    const history = useHistory();
    const {checkExpired, parseToken} = useSessionValidation();
    const [setLat, getLat] = useLocalStorageNew("MacOSPluginLat", "");
    const [setLng, getLng] = useLocalStorageNew("MacOSPluginLng", "");

    function isPPTPage() {
        return (window.location.pathname.includes(PPT_ROOT_PATH) && !!appId) || window.location.pathname.includes(PRESENTATION_PATH);
    }

    function isFromSidebar() {
        const {isFromSidebar=false} = location.state ? location.state : {};
        return isFromSidebar;
    }

    function getAppId() {
        return appId;
    }

    function getToken() {
        return query.get("token");
    }

    function getQueryLat() {
        return query.get("lat");
    }

    function getQueryLng() {
        return query.get("lng");
    }

    function getGeoLocation() {
        const lat = getLat();
        const lng = getLng();
        return (isPPTPage() && !!lat && !!lng) ? {lat: lat, lng: lng} : null;
    }

    function getUserId() {
        const token = getToken();
        const {userId=""} = parseToken(token);
        return userId;
    }


    function parsePPTToken() {
        const token = getToken();
        const json = parseToken(token);
        return json ? json : {};
    }

    function checkPPTTokenExpired() {
        const token = getToken();
        return !token || !!checkExpired(token);
    }

    function goPollsPage() {
        const token = getToken();
        history.push(`/PPT/${appId}/polls?token=${token}`);
    }

    function goCreatePollPage() {
        const token = getToken();
        history.push(`/PPT/${appId}/poll/${POLL_TYPE.MERGED_POLL}${PPT_MENU_LIST.CREATE.path}?token=${token}`, {isFromSidebar: true});
    }

    function goCreateSurveyPage() {
        const token = getToken();
        history.push(`/PPT/${appId}/poll/${POLL_TYPE.SURVEY}${PPT_MENU_LIST.CREATE.path}?token=${token}`, {isFromSidebar: true});
    }

    function goCreateAttendancePage() {
        const token = getToken();
        history.push(`/PPT/${appId}/poll/${POLL_TYPE.ATTENDANCE}${PPT_MENU_LIST.CREATE.path}?token=${token}`, {isFromSidebar: true});
    }

    function goEditPage(pollKey) {
        const token = getToken();
        history.push(`/PPT/${appId}/poll/${pollKey}/edit?token=${token}`, {isFromSidebar: true});
    }

    function goPollDetailPage(pollKey, pollCode='') {
        const token = getToken();
        if (!!pollCode) {
            history.push(`/PPT/${appId}/polls/${pollKey}/${pollCode}/detail?token=${token}`);
        } else {
            history.push(`/PPT/${appId}/polls/${pollKey}/detail?token=${token}`);
        }
    }

    function goInsertWaitingPage() {
        const token = getToken();
        history.push(`/PPT/${appId}/waiting?token=${token}`);
    }

    function goAnyPage(path) {
        const token = getToken();
        history.push( `/PPT/${appId}${path}?token=${token}`);
    }

    function goLoginPage() {
        window.open(`${PPT_MGMT_PORTAL_URL}/PPT/${appId}/dispatch`, "_self");
    }

    useEffect(() => {
        if (isPPTPage()) {
            const lat = getQueryLat();
            const lng = getQueryLng();

            if (!!lat && !!lng) {
                setLat(lat);
                setLng(lng);
            }
        } else {
            setLat("");
            setLng("");
        }
    }, [appId]);



    return {
        isPPTPage,
        isFromSidebar,
        getAppId,
        getToken,
        getUserId,
        checkExpired: checkPPTTokenExpired,
        parseToken: parsePPTToken,
        goLoginPage,
        goPollsPage,
        goPollDetailPage,
        goCreatePollPage,
        goCreateSurveyPage,
        goCreateAttendancePage,
        goEditPage,
        goInsertWaitingPage,
        goAnyPage,
        getGeoLocation
    };
}