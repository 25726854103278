import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import { YuJaKebabMenu } from "../../components/standardization/YuJaKebabMenu";
import { YuJaRadioBox } from "../../components/standardization/YuJaRadiobox";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import { ReactComponent as CopyIcon } from "../../images/copyPoll.svg";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as EditIcon } from "../../images/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import {
    ATTENDANCE,
    DRAFT_STATUS,
    DUPLICATE_TEXT,
    EDIT_TEXT, MERGED_MODE,
    MODAL_DELETE_TEXT,
    NO_RESULTS_FOUND,
    POLL_SHARE_MODE,
    POLL_SORT_KEY,
    POLL_TYPE,
    POLL_TYPE_ACCESSOR,
    PPT_DEFAULT_ROWS_PER_PAGE,
    PPT_POLLS_TABLE_ACTION_HEADER,
    PPT_POLLS_TABLE_DATE_HEADER,
    PPT_POLLS_TABLE_QUESTION_HEADER,
    PPT_POLLS_TABLE_SELECT_HEADER,
    PPT_POLLS_TABLE_TITLE_HEADER,
    PPT_POLLS_TABLE_TYPE_HEADER,
    PPT_POLLS_TBLE_STATUS_HEADER,
    RADIAL_LABEL,
    READY_TO_SHARE,
    SCHEDULED,
    SHARED_BY_QUESTION,
    SHARED_ENTIRELY,
    TEXTBOX,
    UNFINISHED
} from "../../utils/constants";
import { getPollStatus, getPriority, isBefore, removePrefixNew } from "../../utils/helpers";
import styles from "./PPTPollsPage.module.css";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";


export default React.memo(PPTPollsTable);
function PPTPollsTable({ data = [], columns = [], pageIdx=0, onDuplicate, setData, onDeleteDraft, onDeletePublished, selectedPoll,  setSelectedPoll, globalFilterObj = {}}) {
    const {goEditPage, goPollDetailPage} = usePPTWebView();
    const sortedByEnum = {DATE: "date", TITLE: "title", TYPE: "type", STATUS: "status"}
    const [scrollableContainer, setScrollableContainer] = useState(false);
    const [dateSort, setDateSort] = useState(-1);
    const [typeSort, setTypeSort] = useState(1);
    const [statusSort, setStatusSort] = useState(1);
    const [titleSort, setTitleSort] = useState(1);
    const [sortedBy, setSortedBy] = useState(sortedByEnum.DATE);

    const filterCustomize = (rows, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        const filteredStartDate = globalFilterValue.startDate;
        const filteredEndDate = globalFilterValue.endDate;
        const filteredMode = globalFilterValue.mode;
        const filteredStatus = globalFilterValue.status;

        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (r.original.courseCode && r.original.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        }).filter(r => {
            if (!filteredStartDate && !filteredEndDate) {
                return true;
            }
            if (!filteredStartDate) {
                return isBefore(r.values.updatedDatePK, filteredEndDate);
            }
            if (!filteredEndDate) {
                return isBefore(filteredStartDate, r.values.updatedDatePK);
            }
            return r.values.updatedDatePK && isBefore(filteredStartDate, r.values.updatedDatePK) && isBefore(r.values.updatedDatePK, filteredEndDate);
        }).filter(r => {
            if(!filteredMode || filteredMode.length === 0) {
                return true;
            }
            const values = r.values;
            if (values.pollType &&
                ((filteredMode.includes(ATTENDANCE) && values.pollType === POLL_TYPE.ATTENDANCE)
                    || (filteredMode.includes(MERGED_MODE) && values.pollType !== POLL_TYPE.ATTENDANCE))
            ) {
                return true;
            }
        }).filter(r => {
            if(!filteredStatus || filteredStatus.length === 0) {
                return true;
            }
            const values = r.values;
            if (filteredStatus.includes(UNFINISHED) && (!values.pollShareMode || values.pollSortKey === DRAFT_STATUS)) {
                return true;
            }
            if (filteredStatus.includes(READY_TO_SHARE) && values.pollShareMode === POLL_SHARE_MODE.UNKNOWN && r.original?.isValid) {
                return true;
            }
            if (filteredStatus.includes(SHARED_BY_QUESTION) && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH && values.pollType !== POLL_TYPE.ATTENDANCE) {
                return true;
            }
            if (filteredStatus.includes(SHARED_ENTIRELY) && (values.pollShareMode === POLL_SHARE_MODE.SHARE_ALL || (values.pollType === POLL_TYPE.ATTENDANCE && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH))) {
                return true;
            }
            if (filteredStatus.includes(SCHEDULED) && values.pollShareMode === POLL_SHARE_MODE.SCHEDULE) {
                return true;
            }
            return false
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize},
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: PPT_DEFAULT_ROWS_PER_PAGE},
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);


    useEffect(() => {
        const container = document.getElementById("manage-poll-page-container");
        setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
    }, [data]);


    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
            return;
        }

        if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }
    }, [data, pageIdx, pageOptions]);


    const sortDateHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data)).sort(function (a, b) {
                const dateA = Date.parse(a.updatedTimeSK);
                const dateB = Date.parse(b.updatedTimeSK);
                if (dateA > dateB) {
                    return 1 * -dateSort;
                } else if (dateA < dateB) {
                    return -1  * -dateSort
                } else {
                    return 0;
                }
            })
            setData(copy);
        }
        setDateSort(-dateSort);
        setSortedBy(sortedByEnum.DATE);
    }

    const sortTitleHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data)).sort(function (a, b) {
                const nameA = a.pollTitle?.toLowerCase();
                const nameB = b.pollTitle?.toLowerCase();
                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);

                if (priorityA < priorityB) {
                    return -1 * titleSort;
                }
                if (priorityA > priorityB) {
                    return 1 * titleSort;
                }

                if (nameA < nameB) {
                    return -1 * titleSort;
                } else if (nameA > nameB) {
                    return 1 * titleSort;
                } else {
                    return 0;
                }
            });
            setData(copy);
        }

        setTitleSort(-titleSort);
        setSortedBy(sortedByEnum.TITLE);
    }

    const sortTypeHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data)).sort(function (a, b) {
                const nameA = a.pollType?.toLowerCase();
                const nameB = b.pollType?.toLowerCase();
                if (nameA > nameB) {
                    return 1 * -typeSort;
                } else if (nameA < nameB) {
                    return -1  * -typeSort
                } else {
                    return 0;
                }
            });

            setData(copy);
        }

        setTypeSort(-typeSort);
        setSortedBy(sortedByEnum.TYPE);
    }
    //
    const sortStatusHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data)).sort(function (a, b) {
                const nameA = getPollStatus(a.pollShareMode, a?.isValid, a.pollType).toLowerCase();
                const nameB = getPollStatus(b.pollShareMode, b?.isValid, b.pollType).toLowerCase();


                console.log("nameA", nameA, "nameB", nameB)

                if (nameA > nameB) {
                    return 1 * -statusSort;
                } else if (nameA < nameB) {
                    return -1  * -statusSort
                } else {
                    return 0;
                }
            });
            setData(copy);
        }
        setStatusSort(-statusSort);
        setSortedBy(sortedByEnum.STATUS);
    }


    const LEFT_HEADER = [
        PPT_POLLS_TABLE_TITLE_HEADER,
        PPT_POLLS_TABLE_DATE_HEADER,
        PPT_POLLS_TBLE_STATUS_HEADER,
        PPT_POLLS_TABLE_TYPE_HEADER,
        PPT_POLLS_TABLE_QUESTION_HEADER
    ];

    return (
        <>
        <div className={styles.tableContainer}>
                <Table hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <>
                                        { column.render('Header') === PPT_POLLS_TABLE_TITLE_HEADER &&
                                            <th
                                                {...column.getHeaderProps({
                                                    style: {
                                                        width: column.width,
                                                        minWidth: column.minWidth,
                                                        textAlign: 'left'
                                                    }
                                                })}
                                            >
                                                <span tabIndex={0} role={TEXTBOX} className={styles.tableCell}>
                                                        {column.render('Header')}
                                                        {sortedBy === sortedByEnum.TITLE && titleSort === -1 &&
                                                            <SortUp tabIndex={0} aria-label={"Title ascending order"} style={{width: 15, marginLeft: "5px"}} onClick={sortTitleHelper}/>
                                                        }

                                                        {sortedBy === sortedByEnum.TITLE && titleSort === 1 &&
                                                            <SortDown tabIndex={0} aria-label={"Title descending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortTitleHelper}/>
                                                        }
                                                        {((sortedBy !== sortedByEnum.TITLE && titleSort === -1) || (sortedBy !== sortedByEnum.TITLE && titleSort === 1)) &&
                                                            <SortGrey tabIndex={0} aria-label={"Title ascending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortTitleHelper}/>
                                                        }
                                                </span>

                                            </th>
                                        }


                                        { column.render('Header') === PPT_POLLS_TABLE_DATE_HEADER &&
                                            <th
                                                {...column.getHeaderProps({
                                                    style: {
                                                        width: column.width,
                                                        minWidth: column.minWidth,
                                                        textAlign: 'left'
                                                    }
                                                })}
                                            >
                                                <span tabIndex={0} role={TEXTBOX} className={styles.dateCell}>
                                                        {column.render('Header')}
                                                        {sortedBy === sortedByEnum.DATE && dateSort === 1 &&
                                                            <SortUp tabIndex={0} aria-label={"Date ascending order"} style={{ width: 15, marginLeft: "5px" }} onClick={sortDateHelper}/>
                                                        }

                                                        {sortedBy === sortedByEnum.DATE && dateSort === -1 &&
                                                            <SortDown tabIndex={0} aria-label={"Date descending order"} style={{ width: 15, marginLeft: "5px" }} onClick={sortDateHelper}/>
                                                        }
                                                        {((sortedBy !== sortedByEnum.DATE && dateSort === 1) || (sortedBy !== sortedByEnum.DATE && dateSort === -1)) &&
                                                            <SortGrey tabIndex={0} aria-label={"Date ascending order"} style={{ width: 15, marginLeft: "5px" }} onClick={sortDateHelper}/>
                                                        }
                                                </span>
                                            </th>
                                        }

                                        { column.render('Header') === PPT_POLLS_TBLE_STATUS_HEADER &&
                                            <th
                                                {...column.getHeaderProps({
                                                    style: {
                                                        width: column.width,
                                                        minWidth: column.minWidth,
                                                        textAlign: 'left'
                                                    }
                                                })}
                                            >
                                                 <span tabIndex={0} role={TEXTBOX} className={styles.tableCell}>
                                                     {column.render('Header')}
                                                     {sortedBy === sortedByEnum.STATUS && statusSort === 1 &&
                                                         <SortUp tabIndex={0} aria-label={"Status ascending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortStatusHelper}/>
                                                     }

                                                     {sortedBy === sortedByEnum.STATUS && statusSort === -1 &&
                                                         <SortDown tabIndex={0} aria-label={"Status descending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortStatusHelper}/>
                                                     }
                                                     {((sortedBy !== sortedByEnum.STATUS && statusSort === 1) || (sortedBy !== sortedByEnum.STATUS && statusSort === -1)) &&
                                                         <SortGrey tabIndex={0} aria-label={"Status ascending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortStatusHelper}/>
                                                     }
                                                 </span>
                                            </th>
                                        }

                                        { column.render('Header') === PPT_POLLS_TABLE_TYPE_HEADER &&
                                            <th
                                                {...column.getHeaderProps({
                                                    style: {
                                                        width: column.width,
                                                        minWidth: column.minWidth,
                                                        textAlign: 'left'
                                                    }
                                                })}
                                            >
                                             <span tabIndex={0} role={TEXTBOX} className={styles.tableCell}>
                                                        {column.render('Header')}
                                                 {sortedBy === sortedByEnum.TYPE && typeSort === 1 &&
                                                     <SortUp tabIndex={0} aria-label={"Type ascending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortTypeHelper}/>
                                                 }
                                                 {sortedBy === sortedByEnum.TYPE && typeSort === -1 &&
                                                     <SortDown tabIndex={0} aria-label={"Type descending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortTypeHelper}/>
                                                 }
                                                 {((sortedBy !== sortedByEnum.TYPE && typeSort === 1) || (sortedBy !== sortedByEnum.TYPE && typeSort === -1)) &&
                                                     <SortGrey tabIndex={0} aria-label={"Type descending order"} style={{width: 15 , marginLeft: "5px"}} onClick={sortTypeHelper}/>
                                                 }
                                             </span>
                                            </th>
                                        }
                                        
                                        { column.render('Header') === PPT_POLLS_TABLE_QUESTION_HEADER &&
                                        <th
                                        {...column.getHeaderProps({
                                            style: {
                                                width: column.width,
                                                minWidth: column.minWidth,
                                                textAlign: 'left'
                                            }
                                        })}
                                    >
                                        <span tabIndex={0} role={TEXTBOX} className={styles.tableCell}>
                                            {column.render('Header') !== PPT_POLLS_TABLE_SELECT_HEADER ? column.render('Header') : ""}
                                        </span>
                                    </th>
                                        }

                                        { !LEFT_HEADER.includes(column.render('Header')) &&
                                            <th
                                                {...column.getHeaderProps({
                                                    style: {
                                                        width: column.width,
                                                        minWidth: column.minWidth,
                                                        textAlign: 'center'
                                                    }
                                                })}
                                            >
                                                <span tabIndex={0} role={TEXTBOX} className={styles.tableCell}>
                                                    {column.render('Header') !== PPT_POLLS_TABLE_SELECT_HEADER ? column.render('Header') : ""}
                                                </span>
                                            </th>
                                        }

                                    </>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                         if (cell.column.Header === PPT_POLLS_TABLE_ACTION_HEADER) {
                                            return (
                                                <td width={80}>
                                                    <span style={{ display: "flex", justifyContent: "center" }}>
                                                        <YuJaKebabMenu>
                                                            <YuJaKebabMenu.Menu>
                                                                {(cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS || cell.row.original.pollShareMode === POLL_SHARE_MODE.UNKNOWN) &&
                                                                    <YuJaKebabMenu.Item onClick={() => goEditPage(removePrefixNew(cell.row.original.pollKey))}>
                                                                        <EditIcon style={{ width: 18, marginRight: 5 }} />{EDIT_TEXT}
                                                                    </YuJaKebabMenu.Item>
                                                                }
                                                                <YuJaKebabMenu.Item
                                                                    onClick={() => {
                                                                        cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS ?
                                                                            onDeleteDraft(cell.row.original.pollKey, cell.row.original[POLL_TYPE_ACCESSOR]) :
                                                                            onDeletePublished(cell.row.original.pollKey, cell.row.original[POLL_SORT_KEY], cell.row.original[POLL_TYPE_ACCESSOR], cell.row.original)
                                                                    }}
                                                                >
                                                                    <DeleteIcon style={{ width: 18, marginRight: 5 }} />{MODAL_DELETE_TEXT}
                                                                </YuJaKebabMenu.Item>
                                                                <YuJaKebabMenu.Item onClick={() => onDuplicate(cell.row.original)}>
                                                                    <CopyIcon style={{ width: 18, marginRight: 5 }} />{DUPLICATE_TEXT}
                                                                </YuJaKebabMenu.Item>
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </span>
                                                </td>
                                            )
                                         } 
                                         else if (cell.column.Header === PPT_POLLS_TBLE_STATUS_HEADER) {
                                            return (
                                            <td width={120} style={{textAlign: "left"}}>
                                            {getPollStatus(cell.value, cell.row.original?.isValid, cell.row.original?.pollType)}
                                            </td>
                                            )
                                         } 
                                         else if (cell.column.Header === PPT_POLLS_TABLE_QUESTION_HEADER) {
                                            return (
                                                <td width={80} style={{textAlign: "left"}}>{cell.value}</td>
                                            )
                                        }
                                        else if (cell.column.Header === PPT_POLLS_TABLE_TYPE_HEADER) {
                                            return (
                                                <td width={100} style={{textAlign: "left"}}>
                                                    {cell?.value === POLL_TYPE.ATTENDANCE ? ATTENDANCE : MERGED_MODE}
                                                </td>
                                            )
                                        } else if (cell.column.Header === PPT_POLLS_TABLE_TITLE_HEADER) {
                                            return (
                                                <td width={200} style={{textAlign: "left"}}>
                                                    {cell.row.original["pollType"] === POLL_TYPE.ATTENDANCE &&
                                                        <span
                                                            className={styles.pollTitleLink}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                textDecoration: "auto",
                                                                cursor: "auto",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                display: "inline-block",
                                                                width: "calc(80%)"
                                                            }}
                                                            title={cell.value}
                                                        >
                                                            {cell.value}
                                                        </span>
                                                    }
                                                    {cell.row.original["pollType"] !== POLL_TYPE.ATTENDANCE &&
                                                        <span
                                                            className={styles.pollTitleLink}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                display: "inline-block",
                                                                width: "calc(80%)"
                                                            }}
                                                            title={cell.value}
                                                            onClick={() => {
                                                                if (cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS) {
                                                                    goPollDetailPage(removePrefixNew(cell.row.original.pollKey));
                                                                } else {
                                                                    goPollDetailPage(removePrefixNew(cell.row.original.pollKey), removePrefixNew(cell.row.original[POLL_SORT_KEY]));
                                                                }
                                                            }}
                                                        >
                                                        {cell.value}
                                                    </span>
                                                    }
                                                </td>
                                            )
                                        } else if (cell.column.Header === PPT_POLLS_TABLE_DATE_HEADER) {
                                            return (
                                                <td width={120} style={{textAlign: "left"}}>{!!cell.row.original.updatedTimeSK ? cell.row.original.updatedTimeSK.substring(0, cell.row.original.updatedTimeSK.indexOf("T")) : ""}</td>
                                            )
                                        } else if (cell.column.Header === "Select") {
                                            return (
                                                <td width={50} >
                                                    <YuJaRadioBox
                                                        aria-label={RADIAL_LABEL + (selectedPoll.pollKey === cell.row.original.pollKey ? "selected" : "unselected")}
                                                        checked={selectedPoll.pollKey === cell.row.original.pollKey}
                                                        onClick={() => setSelectedPoll(cell.row.original)}
                                                    />
                                                </td>
                                            )
                                        }
                                    })}
                                </tr>
                            )
                        }) : <td colSpan={7}>  <div style={{height: 450, justifyContent: "center", display: "flex", marginTop: "20px", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div> </td>}
                        </tbody>
                    </Table>
                {page && page.length !== 0 ? <>
                    <div style={{ flex:1, display: "flex", alignItems: "end" }}>
                        <NormalPagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                            paddingTop="0px"
                        />
                    </div>
                </> : null}
            </div>
        </>
        
    )
}