import 'rc-datepicker/lib/style.css';
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import FilterMenu from '../../components/FilterMenu';
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from '../../hooks/useMobileAccess';
import { ReactComponent as Filter } from "../../images/filter.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import {
    ATTENDANCE,
    END_DATE,
    FILTER_POLLS,
    MERGED_MODE,
    POLL_TYPE_HEADER,
    READY_TO_SHARE,
    SCHEDULED,
    SEARCH_BOX_PLACEHOLDER, SEARCH_POLL,
    SEARCH_TEXT,
    SHARED_BY_QUESTION,
    SHARED_ENTIRELY,
    START_DATE,
    STATUS_LABEL,
    UNFINISHED
} from '../../utils/constants';
import { notifyError } from '../../utils/helpers';
import { LOADING_FILTER_ERROR, LOADING_SEARCH_ERROR } from '../../utils/toast-message-constants';
import styles from "./ManagePolls.module.css";
import "./styles.css";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function PollSearchForm({handleSubmit, disabled=false, collapsed=false, isPPT=false}) {
    const filterRef = useRef();
    const filterMenuRef = useRef();
    const isNarrow = useRef(useMobileAccess(1200));
    // const windowDimensions = useWindowDimensions();
    const [keyword, setKeyword] = useState("");
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [modeFilter, setModeFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [oldCollapsed, setOldCollapsed] = useState(false);
    const [filterMenuDimensions, setFilterMenuDimensions] = useState({});
    const [filterMenuLocation, setFilterMenuLocation] = useState({x: 0, y: 0});
    // const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1200);

    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && filterMenuRef.current && !filterMenuRef.current.contains(event.target) && !filterRef.current.contains(event.target)) {
                setShowFilterMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showFilterMenu && (oldCollapsed !== collapsed || filterMenuDimensions.width === 0 || JSON.stringify(filterMenuDimensions) !== JSON.stringify(filterMenuRef?.current?.getBoundingClientRect()))) {
            console.log(isNarrow.current, collapsed, filterRef?.current?.getBoundingClientRect(), filterMenuRef?.current?.getBoundingClientRect());
            setFilterMenuDimensions(filterMenuRef?.current?.getBoundingClientRect());
            setOldCollapsed(collapsed);
            resetFilterMenuPosition();
        }
    }, [collapsed, filterMenuRef?.current?.getBoundingClientRect()]);

    const clickSearch = ({key=keyword, start=startDate, end=endDate, mode=modeFilter, status=statusFilter}) => {
        if (disabled) {
            notifyError(LOADING_SEARCH_ERROR);
            return;
        }
        let formattedStartDate = start ? start.split("-").reverse().join("-") : "";
        let formattedEndDate = end ? end.split("-").reverse().join("-") : "";
        handleSubmit({
            keyword: key,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            mode: mode,
            status: status
        });
    };

    const clickFilter = () => {
        if (disabled) {
            notifyError(LOADING_FILTER_ERROR);
            return;
        }
        setShowFilterMenu(!showFilterMenu);
    };

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch({});
        }
    }

    const resetFilterMenuPosition = () => {
        const filterRect = filterRef?.current?.getBoundingClientRect();
        // const filterMenuRect = filterMenuRef?.current?.getBoundingClientRect();
        // console.log(filterRect, filterMenuRect, window.scrollX, window.scrollY);
        // const x = filterRect?.left + window.scrollX - ( window.innerWidth <= 1200 ? 0 : ((filterMenuRect?.width - filterRect?.width) / 2 + 20 - (filterMenuRect?.width / 2) + (collapsed ? 60 : 240)));
        const x = -filterRect?.width - 10;
        // const y = filterRect?.bottom + window.scrollY - 52 + (5);
        const y = 25;
        // console.log(x, y);
        setFilterMenuLocation({x, y});
    };

    const onApply = (filterOptions) => {
        setModeFilter(filterOptions.optionFilter1);
        setStatusFilter(filterOptions.optionFilter2);
        setStartDate(filterOptions.dateFilter1);
        setEndDate(filterOptions.dateFilter2);
        clickSearch({
            start: filterOptions.dateFilter1,
            end: filterOptions.dateFilter2,
            mode: filterOptions.optionFilter1,
            status: filterOptions.optionFilter2
        });
    };

    return (
        <div style={{width: "100%"}}>
            {/* {!smallScreen ?
                ( */}
                    <div className={styles.searchBox}>
                        <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}
                            //   style={{paddingLeft: "10px", paddingRight: "10px"}}
                        >
                            <div ref={filterRef} style={{position: "relative", display: "inline"}}>
                                <Filter className={`${styles.focusIcon}`} tabIndex={0} onMouseDown={e => e.preventDefault()} aria-label={FILTER_POLLS} onClick={clickFilter} style={{width: 32, height: 32}} />
                            </div>
                            <div style={{position: "relative"}}>
                            <div
                                ref={filterMenuRef}
                                style={{
                                    position: "absolute",
                                    top: filterMenuLocation.y + "px",
                                    left: filterMenuLocation.x + "px",
                                    // zIndex: 1000,
                                    // opacity: 1,
                                    // width: "400px",
                                    // height: "300px"
                                }}
                            >
                                <FilterMenu
                                    // ref={filterMenuRef}
                                    show={showFilterMenu}
                                    setShow={setShowFilterMenu}
                                    onApply={onApply}
                                    filterOptions={{
                                        optionFilter1Name: POLL_TYPE_HEADER,
                                        optionFilter1: modeFilter,
                                        optionsFilter1: [MERGED_MODE, ATTENDANCE],
                                        optionFilter2Name: STATUS_LABEL,
                                        optionFilter2: statusFilter,
                                        optionsFilter2: isPPT ? [UNFINISHED, READY_TO_SHARE, SHARED_BY_QUESTION, SHARED_ENTIRELY] : [UNFINISHED, READY_TO_SHARE, SHARED_BY_QUESTION, SHARED_ENTIRELY, SCHEDULED],
                                        dateFilter1Name: START_DATE,
                                        dateFilter1: startDate,
                                        dateFilter2Name: END_DATE,
                                        dateFilter2: endDate
                                    }}
                                    isPPT={isPPT}
                                    // style={{
                                    //     position: "absolute",
                                    //     top: filterMenuLocation.y + "px",
                                    //     left: filterMenuLocation.x + "px",
                                    //     // width: "400px",
                                    //     // height: "300px"
                                    // }}
                                />
                            </div>
                            </div>
                            <YuJaTextBox
                                label={SEARCH_POLL}
                                containerStyle={{fontSize: 12, marginLeft: 10}}
                                placeholder={SEARCH_BOX_PLACEHOLDER}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={enterKeyup}
                                value={keyword}
                                before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                            />

                            {/* <DatePickerInput
                                ref={datepickerRef}
                                displayFormat='DD-MM-YYYY'
                                style={{padding: "20px", minWidth: "160px"}}
                                placeholder={START_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setStartDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setStartDate("");
                                    }
                                }}
                                value={startDate}
                                aria-label={START_DATE_LABEL}
                                onFocus={() => datepickerRef.current?.show()}
                                onBlur={() => datepickerRef.current?.hide()}
                            />
                            <DatePickerInput
                                ref={datepickerRefEnd}
                                displayFormat='DD-MM-YYYY'
                                style={{padding: "20px", minWidth: "160px"}}
                                placeholder={END_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setEndDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setEndDate("");
                                    }
                                }}
                                value={endDate}
                                aria-label={END_DATE_LABEL}
                                onFocus={() => datepickerRefEnd.current?.show()}
                                onBlur={() => datepickerRefEnd.current?.hide()}
                            />
                            <YuJaDropdown
                                ariaLabel={modeFilter + ALL_MODE_LABEL}
                                data={[ALL_MODE, GRADED_MODE, SURVEY]}
                                getOptionLabel={item => item}
                                getOptionValue={item => item}
                                onChange={option => setModeFilter(option)}
                                value={modeFilter}
                                containerStyle={{marginRight: 15, width: 130}}
                                style={{padding: "8px 15px"}}
                            />

                            <YuJaDropdown
                                ariaLabel={statusFilter + ALL_STATUS_LABEL}
                                data={[ALL_STATUS, DRAFT_STATUS, ACTIVATED_STATUS, COURSE_STATUS]}
                                getOptionLabel={item => item}
                                getOptionValue={item => item}
                                onChange={option => setStatusFilter(option)}
                                value={statusFilter}
                                containerStyle={{marginRight: 15, width: 110}}
                                style={{padding: "8px 15px"}}
                            /> */}

                            {/*<ModeFilterDropDown modeFilter={modeFilter} setModeFilter={setModeFilter}/>*/}

                            {/*<StatusFilterDropDown statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>*/}

                            <YuJaButton onClick={clickSearch}><div style={{fontSize: 14}}>{SEARCH_TEXT}</div></YuJaButton>
                        </Form>
                    </div>
                {/* ) : (
                    <div style={{display: "flex", flexDirection: "column", height: "fit-content"}} className={styles.searchBox}>
                        <Form
                            className={styles.searchBoxForm}
                            onSubmit={e => e.preventDefault()}
                            style={{paddingLeft: "5px", paddingRight: "5px"}}
                        >
                            <YuJaTextBox
                                containerStyle={{minWidth: 250, width: "auto", fontSize: 13}}
                                placeholder={SEARCH_BOX_PLACEHOLDER}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={enterKeyup}
                                value={keyword}
                                before={<SearchIcon style={{marginRight: 5, width: 20, height: 20}}/>}
                            />

                            <DatePickerInput
                                displayFormat='DD-MM-YYYY'
                                style={{minWidth: "160px"}}
                                placeholder={START_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setStartDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setStartDate("");
                                    }
                                }}
                                value={startDate}
                            />
                            <DatePickerInput
                                displayFormat='DD-MM-YYYY'
                                style={{minWidth: "160px"}}
                                placeholder={END_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setEndDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setEndDate("");
                                    }
                                }}
                                value={endDate}
                            />
                        </Form>
                        <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}
                              style={{marginBottom: "5px", paddingLeft: "5px", paddingRight: "5px"}}>
                            <ModeFilterDropDown modeFilter={modeFilter} setModeFilter={setModeFilter}/>

                            <StatusFilterDropDown statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>

                            <YuJaButton onClick={clickSearch}>Search</YuJaButton>
                        </Form>
                    </div>
                )
            } */}
        </div>
    );
}