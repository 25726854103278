import { LoadingOutlined } from '@ant-design/icons';
import { Spin as AntdSpin} from "antd";
import React from 'react';

export const Spin = ({size="large", wrapperClassName="", spinning=false, tip="", style={}, children}) => {
    const antIcon = (
        <LoadingOutlined style={{color: "#42296E"}} spin />
    ); 

    const newStyle = {
        ...style,
       color: "#42296E"
    };

    return (
        <AntdSpin size={size} wrapperClassName={wrapperClassName} spinning={spinning} tip={""} style={newStyle} indicator={antIcon}>
            {children}
        </AntdSpin>
    );
}
