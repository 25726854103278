import React from "react";
import { Container, Row } from "react-bootstrap";

export default function EmptySidebar({ title, noContentText }) {
  return (
    <Container className="max-height">
      <Row>
        <span className="cursor-default" id="sidebar-title">
          {title}
        </span>
      </Row>
      <Row className="max-height">
        <span className="cursor-default" id="empty-sidebar-message">
          {noContentText}
        </span>
      </Row>
    </Container>
  );
}
