import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import GradientButton from "../../public/buttons/GradientButton";
import { DRAFT_STATUS, NO_RECENT_POLLS, POLL_SHARE_MODE, RECENT_POLLS, RECENT_POLLS_SUBTITLE, RELEASE, RELEASE_BUTTON, TEXTBOX, USER_SETTINGS } from "../../utils/constants";
import { notifyError, removePrefix, removePrefixNew } from "../../utils/helpers";
import { RELEASE_INVALID_POLL_ERROR } from "../../utils/toast-message-constants";
import styles from "./DashboardPage.module.css";
import { ReactComponent as ModifiedPollIcon } from "./images/gradient_history_icon.svg";
import { ReactComponent as NoResultIllustration } from "./images/no_modified_polls_found_illustration.svg";

export default function CreatorModifiedPolls({data=[], courseOptions=[], loading=true, initialized=false, userSettings={}}) {
    const { publishPoll} = PollApiCalls();
    const history = useHistory();


    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const onClickRelease = async (poll) => {
        poll.pollKey = removePrefix(poll.pollKey);
        if (poll.pollSortKey !== DRAFT_STATUS) {
            history.push(`/poll/share/${poll.pollKey}/${removePrefixNew(poll.pollSortKey)}`);
            return;
        }
        poll.pollShareMode = POLL_SHARE_MODE.UNKNOWN;
        if (!poll.pollTimeLimit) poll.pollTimeLimit = userSettings[USER_SETTINGS.POLL_DURATION];
        // if (!poll.getReadyAllTimeLimit) poll.getReadyAllTimeLimit = userSettings[USER_SETTINGS.POLL_COUNTDOWN];
        if (!poll.lmsAttempt) poll.lmsAttempt = userSettings[USER_SETTINGS.POLL_LMS_SYNC];
        if (!poll.recordAttempt) poll.recordAttempt = userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS];
        if (!poll.maxAttempts) poll.maxAttempts = userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS];
        let questions = poll.questions;
        for (let i = 0; i < questions.length; i++) {
            if (!questions[i].weightage) questions[i].weightage = userSettings[USER_SETTINGS.QUESTION_POINTS];
            if (!questions[i].timeLimit) questions[i].timeLimit = userSettings[USER_SETTINGS.QUESTION_DURATION];
            if (!questions[i].getReadyTimeLimit) questions[i].getReadyTimeLimit = userSettings[USER_SETTINGS.QUESTION_COUNTDOWN];
        }
        poll.questions = questions;
        let result = await publishPoll(poll, true);

        if (!result || !result.uniqueCode) {
            notifyError(RELEASE_INVALID_POLL_ERROR);
            return;
        }
        const {draft={}, uniqueCode} = result;
        history.push(`/poll/share/${removePrefix(draft.pollKey)}/${uniqueCode}`);
    };

    return (
        <div className={styles.panelContainer} style={{height: "56%"}}>
            <div className={styles.panelTitle}>
                <ModifiedPollIcon style={{marginRight: "15px"}}/>
                <div className={styles.panelSubTitle}>
                    <div role={TEXTBOX} tabIndex={0}>{RECENT_POLLS}</div>
                    <div role={TEXTBOX} tabIndex={0} style={{fontSize: "13px"}}>{RECENT_POLLS_SUBTITLE}</div>
                </div>
            </div>
            {!loading ? 
            <div className={styles.panelBody}>
                {/* {courseModalShow && <FormModal
                    show={courseModalShow}
                    setModalShow={setCourseModalShow}
                    modalConfig={modalConfig}
                    pollTimeLimit={pollTimeLimit}
                    setPollTimeLimit={setPollTimeLimit}
                    setShareAll={setShareAll}
                    shareAll={shareAll}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />} */}
                {/* <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}> */}
                    {initialized && (!data || !data.length) &&
                        <>
                            <NoResultIllustration style={{maxWidth: "100%", maxHeight: "100%", marginRight: "15px"}}/>
                            {NO_RECENT_POLLS}
                        </>
                    }
                    {initialized && (!!data && !!data.length) &&
                        <div className={styles.modifiedPollContainer}>
                            {JSON.parse(JSON.stringify(data)).splice(0, 6).map((poll, index) => (
                                <div key={index} className={styles.modifiedPollUnit} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <span tabIndex={0} role={TEXTBOX} className={styles.omitableText}>{poll.pollTitle}</span>
                                    <GradientButton
                                        role={TEXTBOX}
                                        label={RELEASE_BUTTON + poll.pollTitle}
                                        style={{marginLeft: "15px"}}
                                        onClick={() => onClickRelease(poll)}
                                    >
                                        {RELEASE}
                                    </GradientButton>
                                </div>
                            ))}
                        </div>
                    }
                {/* </Spin> */}
            </div>
            : windowSize[1] >= 915 ? 
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"3vh"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "9vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div> 
            </SkeletonTheme> </div> 
            : windowSize[1] >= 800 ? 
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"3vh"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "9vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div> 
            </SkeletonTheme> </div> 
            : <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"20px"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"20px"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"20px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"20px"}} />
            </div>
            <Skeleton  borderRadius={10} height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"20px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton  borderRadius={10} height = {10} width = "3vw" style = {{position: "relative", marginTop:"20px"}} />
            </div></SkeletonTheme> </div> }
        </div>
    );
}