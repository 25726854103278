import React from "react";
import { CLICKABLE_IMAGE_ALT, TEXTBOX } from "../utils/constants";


export default function BaseQuestionImage({selectedQuestion, imageAlt, questionBuilder=false}) {
    return(
        <>
            { !!selectedQuestion && !!selectedQuestion.imageURL &&
                <div className="clickable-image-container" style={{marginLeft: "0px", alignSelf: "center", maxWidth: "100%", maxHeight: "100%"}}>
                    <div style={questionBuilder ? {} : {width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img
                            tabIndex={0}
                            aria-label={imageAlt ? imageAlt : CLICKABLE_IMAGE_ALT}
                            role={TEXTBOX}
                            src={`${selectedQuestion.imageURL}`}
                            alt={`Q${selectedQuestion.image} preview`}
                            style={
                                questionBuilder ?
                                    { maxWidth: "450px", maxHeight: "100%", mixBlendMode: "normal", boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)", borderRadius: "12px" }
                                    : { maxWidth: "100%", maxHeight: "100%" }
                            }
                        />
                    </div>
                </div>
            }
        </>
    );
}