import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import useMobileAccess from "../../hooks/useMobileAccess";
import { FIXED_ANSWER, MAX_MH_RESPONSE_DISPLAY, TEXTBOX, UNANSWERED, YOUR_ANSWER_LABEL } from "../../utils/constants";
import "./styles.css";
import styles from "./styles.module.css";

export default function ClassResultMH({ chartData, correctAnswers, queResponse, isLoading = false }) {
    const isMobile = useMobileAccess(480);
    const [selectedPremise, setSelectedPremise] = useState(null);
    const [yourAnswer, setYourAnswer] = useState(null);
    const [responseCounts, setResponseCounts] = useState(null);

    useEffect(() => {
        if (!chartData || !!selectedPremise) {
            return;
        }
        // console.log("chartData", chartData);
        setSelectedPremise(Object.keys(chartData)[0]);
    }, [chartData]);

    useEffect(() => {
        if (!selectedPremise) {
            return;
        }
        // console.log("selectedPremise", selectedPremise);
        const responseCountMap = chartData[selectedPremise];
        // console.log("responseCountMap", responseCountMap);
        const sortedResponseCountMap = Object.entries(responseCountMap).sort(([, valueA], [, valueB]) => valueB - valueA);
        // console.log("sortedResponseCountMap", sortedResponseCountMap);
        setResponseCounts(sortedResponseCountMap);

        const selectedOption = queResponse?.selectedOption;
        // console.log("selectedOption", selectedOption);
        if (!selectedOption) {
            setYourAnswer(UNANSWERED);
            return;
        }
        // console.log(correctAnswers, selectedPremise);
        // const premiseIdx = Object.keys(chartData).indexOf(selectedPremise);
        const premiseIdx = correctAnswers.findIndex((premiseResponse) => premiseResponse[0] === selectedPremise);
        // console.log("premiseIdx", premiseIdx);
        // console.log("selectedOption[premiseIdx]", selectedOption[premiseIdx]);
        setYourAnswer(selectedOption === FIXED_ANSWER.UNANSWERED || !selectedOption[premiseIdx] ? UNANSWERED : selectedOption[premiseIdx]);
    }, [selectedPremise, chartData]);

    useEffect(() => {
        if (!responseCounts) {
            return;
        }
        console.log("responseCounts", responseCounts);
        const totalResponseCount = responseCounts.reduce((acc, [, count]) => acc + count, 0);
        console.log("totalResponseCount", totalResponseCount);
        setTimeout(() => {
            responseCounts.map((entry, index) => {
                const [, responseCount] = entry;
                const optionElement = document.getElementById(`option${index}`);
                if (!optionElement) {
                    return;
                }
                optionElement.style.width = `${!!totalResponseCount ? (responseCount / totalResponseCount) * 100 : 0}%`;
            });
        }, 500);
    }, [responseCounts]);

    return (
        <div className={styles.chartContainer}>
            {!!chartData && !isLoading && <>
                <YuJaDropdown
                    data={Object.keys(chartData)}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    value={selectedPremise}
                    onChange={(value) => setSelectedPremise(value)}
                />

                {yourAnswer &&
                    <div className={styles.yourAnswerCard}>
                        <div className={styles.yourAnswerLabel}>{YOUR_ANSWER_LABEL}</div>
                        <div className={styles.yourAnswerText}>{yourAnswer}</div>
                    </div>
                }

                {/* {Object.entries(chartData).map((entry, index) => {
                    console.log(entry, index);
                    const [premise, responseCountMap] = entry;
                    console.log(premise, responseCountMap);
                    const responseCounts = Object.entries(responseCountMap).sort(([, valueA], [, valueB]) => valueB - valueA);
                    console.log(responseCounts);
                    // const totalResponseCount = responseCounts.reduce((acc, [, count]) => acc + count, 0);
                    return (
                        <div className="mh-premise-container" key={index}>
                            <div className="mh-premise">{premise}</div>
                            <div className="mh-response-container"> */}
                {responseCounts && responseCounts.slice(0, Math.min(responseCounts.length, MAX_MH_RESPONSE_DISPLAY)).map((response, responseIndex) => {
                    const [responseText, responseCount] = response;
                    return (
                        <div className={styles.optionContainer} key={responseIndex}>
                            <div className={styles.option}>
                                <div tabIndex={0} style={{ textWrap: "nowrap" }} role={TEXTBOX} aria-label={`${responseText} ${responseCount}`} className={`${styles.optionIndex}`}>{responseText}</div>

                                <div id={`optionText${responseIndex}`} tabIndex={0} role={TEXTBOX} className={`${styles.optionText}`}>  {/* append premiseIdx to the id for multiple bar charts */}
                                    {responseCount}
                                </div>

                                <div
                                    id={`option${responseIndex}`} // append premiseIdx to the id for multiple bar charts
                                    className={`${styles.optionCountBar}`}
                                    // style={{ backgroundColor: yourAnswer === responseText ? "#C4BDD2" : "rgb(244, 244, 244)"}}
                                />
                            </div>
                        </div>

                    );
                })}
                            {/* </div>
                        </div>
                    );
                })} */}
            </>}
            {isLoading && Array.from({ length: 4 }).map(() => (
                <div className={styles.optionContainer}>
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                        <Skeleton height={55} borderRadius={10} width={isMobile ? "80vw" : "50vw"} style={{ position: "relative", borderRadius: "10px" }} />
                    </SkeletonTheme>
                </div>
            ))}
        </div>
    );
}