import {Modal} from "react-bootstrap";
import { ADD_TIME_MODAL_SUBMIT_TEXT, ADD_TIME_MODAL_TEXT, ADD_TIME_MODAL_TITLE_TEXT, MODAL, POLL_TIME_LIMIT_MODAL_CANCEL, TEXTBOX } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import "./modal.css";
import { useState } from "react";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import loadingGif from "../../images/LoadingAnimation.gif";

export default function AddPollTimeModal({ show, setShow, handleSubmit, AddTimeOptions, AddTimeValues, isLoading=false}) {
    const [selectedTime, setSelectedTime] = useState(""); 

    const numberDivs = AddTimeOptions.map((option, index) => (
        <div tabIndex={0} className={`${ index === selectedTime ? "AddTimeOptionsActivated": "AddTimeOptions"}`} key={index} onClick={()=> {setSelectedTime(index)}}>+{option}</div>
      ));

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <ModalClose onClick={() => setShow(false)} className="modal-close" style={{width:24, height:24}}/>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0}
                    aria-label={"Set Poll Time Limit" + MODAL}
                    role={TEXTBOX}
                >
                    {ADD_TIME_MODAL_TITLE_TEXT}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div tabIndex={0} style={{ fontWeight: 700, fontSize: "14px", lineHeight: "14px", color: "#000000", marginBottom: "15px", marginTop: "-10px"}}> {ADD_TIME_MODAL_TEXT} </div>
                <div className="AddTimeOptionsContainer">

                    {numberDivs}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton
                    style={{width: 65, height: 35, padding: "6px 10px"}}
                    type="secondary"
                    onClick={() => setShow(false)}
                >{POLL_TIME_LIMIT_MODAL_CANCEL}</YuJaButton>
                <YuJaButton
                    disabled={!!isLoading}
                    style={{width: 65, height: 35, padding: 0 }}
                    onClick={() => handleSubmit(AddTimeValues, selectedTime)}
                >
                    {!!isLoading
                        ? <img src={loadingGif} alt={"loading..."} style={{height: 31}}/>
                        : <span style={{height: 31, display: "flex", alignItems: "center"}}>{ADD_TIME_MODAL_SUBMIT_TEXT}</span>
                    }
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}