import React, {useEffect} from "react";
import { Container, Row } from "react-bootstrap";
import ClassResultsIcon from '../../images/ClassResults.svg';
import YourResultsIcon from '../../images/YourResults.svg';


import "bootstrap/dist/css/bootstrap.css";
import "./styles.css";
import { newPageLog } from "../../utils/helpers";

export default function OverallResultsPage({

}) {

  const class1 = "2/2";
  const class2 = "100%";
  const class3 = "50pts";

  const handleHome = () => {
    console.log("home");
  }

  useEffect(() => {
    newPageLog("OverallResultsPage.js");
  }, []);


  return (
    <div>
      <Container className="results-wrap">

        <div>
          <Row><p className="results-title">Overall Results</p></Row>
          <Row><p className="results-sub-title">Here are your class and personal results from the poll.</p></Row>
        </div>

        <div className="results-box-title">
          <img className="results-box-title-img" src={ClassResultsIcon}></img>
          <p className="results-box-title-text">Class Results</p>
        </div>

        <div className="results-box-body">
          <Row><div className="results-box-body-head">
            <p className="results-prop-name">Overall Score: </p>
            <p className="results-prop-val">{class1}</p>
          </div></Row>

          <Row><div className="results-box-body-head">
            <p className="results-prop-name">Grade Percentage: </p>
            <p className="results-prop-val">{class2}</p>
          </div></Row>

          <Row><div className="results-box-body-head">
            <p className="results-prop-name">Points Earned: </p>
            <p className="results-prop-val">{class3}</p>
          </div></Row>
        </div>

        <div><br></br></div>

        <div className="results-box-title">
          <img className="results-box-title-img" src={YourResultsIcon}></img>
          <p className="results-box-title-text">Your Results</p>
        </div>

        <div className="results-box-body">
          <Row><div className="results-box-body-head">
            <p className="results-prop-name">Overall Score: </p>
            <p className="results-prop-val">{class1}</p>
          </div></Row>

          <Row><div className="results-box-body-head">
            <p className="results-prop-name">Grade Percentage: </p>
            <p className="results-prop-val">{class2}</p>
          </div></Row>

          <Row><div className="results-box-body-head">
            <p className="results-prop-name">Points Earned: </p>
            <p className="results-prop-val">{class3}</p>
          </div></Row>
        </div>

        <div><br></br><br></br></div>

        <div className="results-btn-wrap">
          <Row>
            <button onClick={() => handleHome()} className="results-home-btn">
              <span className="results-home-btn-text">Home</span>
            </button>
          </Row>
        </div>

      </Container>
    </div>
  );
}
