import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import FormModal from "../../components/modals/FormModal";
import ViewPollModal from "../../components/modals/ViewPollModal";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import NormalPagination from "../../components/pagination/NormalPagination";
import { YuJaKebabMenu } from "../../components/standardization/YuJaKebabMenu";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as ViewIcon } from "../../images/View.svg";
import { ReactComponent as CopyIcon } from "../../images/copyPoll.svg";
import { ReactComponent as FeedBackIcon } from "../../images/feedback.svg";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as EditIcon } from "../../images/pencil.svg";
import { ReactComponent as ShareIcon } from "../../images/share-poll.svg";
import { ReactComponent as ReleaseIcon } from "../../images/share.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import {
    ACTION_HEADER,
    ACT_ITEMS,
    ATTENDANCE,
    AUDIENCE_RESTRICTION,
    CREATION_ROLES,
    DATE,
    DATE_HEADER,
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POINTS,
    DEFAULT_POLL_LMS_SYNC,
    DEFAULT_POLL_MAX_ATTEMPTS,
    DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_ROWS_PER_PAGE,
    DEFAULT_TIME_LIMIT,
    DELETE_ACTIVITY,
    DELETE_POLL_MODAL_BODY,
    DRAFT_STATUS,
    DUPLICATE_TEXT,
    EDIT_TEXT,
    FEEDBACK_HEADER,
    GRADED_POLL, MERGED_MODE,
    MODAL_CANCEL_TEXT,
    MODAL_DELETE_TEXT,
    MODE,
    NO_RESULTS_FOUND,
    POLL_KEY,
    POLL_SHARE_MODE,
    POLL_SORT_KEY,
    POLL_STATE,
    POLL_STATUS,
    POLL_STATUS_HEADER,
    POLL_TITLE,
    POLL_TITLE_CHARACTER_LIMIT,
    POLL_TYPE,
    POLL_TYPE_ACCESSOR,
    POLL_TYPE_HEADER,
    QUES_LABEL,
    READY_TO_SHARE,
    RELEASE,
    SCHEDULED,
    SHARED_BY_QUESTION,
    SHARED_ENTIRELY,
    SHARE_POLL_BODY,
    SHARE_SURVEY_BODY,
    SHARE_TEXT,
    SURVEY,
    TEXTBOX,
    TITLE,
    TITLE_HEADER,
    UNFINISHED,
    USER_SETTINGS,
    VIEW_FEEDBACK_TEXT,
    VIEW_TEXT
} from "../../utils/constants";
import { getDefaultUserSettings, getPollStatus, isBefore, notifyError, removePrefix, removePrefixNew } from "../../utils/helpers";
import { DELETE_ACTIVE_POLL_ERROR, RELEASE_INVALID_POLL_ERROR } from "../../utils/toast-message-constants";
import styles from "./ManagePolls.module.css";

export default React.memo(PollTable);
function PollTable({ data = [], columns = [], pageIdx=0, globalFilterObj = {}, getPollInformation, Datesort, dateSort, Titlesort, Modesort, titleSort, modeSort, Statussort, statusSort}) {
    const history = useHistory();
    // const {loading} = useLoading();
    const [, getSession] = useLocalStorageNew("session", {});
    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());
    const { publishPoll, sharePollToUsers, softDeleteDraftPoll, softDeletePublishedPoll, copyPollToUser, getPublishedPollDataFromKey} = PollApiCalls();
    const { loadUsers } = UserApiCalls();
    // const { getCourseByUser } = CourseApiCalls();
    // const [courseModalShow, setCourseModalShow] = useState(false);
    // const [courseOptions, setCourseOptions] = useState([]);
    // const [selectedCourseOpts, setSelectedCourseOpts] = useState([]);
    // const [courseData, setCourseData] = useState([]);
    // const [copyPollModal, setCopyPollModal] = useState(false);
    const [sharePollModal, setSharePollModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [scrollableContainer, setScrollableContainer] = useState(false);
    const [userData, setUserData] = useState([]);
    const [selectedMemberOpts, setSelectedMemberOpts] = useState([]);
    const [memberSelectedError, setMemberSelectedError] = useState("");
    const [pollKey, setPollKey] = useState("");
    const [pollUniqueCode, setPollUniqueCode] = useState("");
    const [pollType, setPollType] = useState("");
    const [sortSelected, setSortSelected] = useState(0);
    const [viewPollModalDataReady, setViewPollModalDataReady] = useState(false);
    const [viewPollModalShow, setViewPollModalShow] = useState(false);
    const [viewPollModalData, setViewPollModalData] = useState({});

    // const [dateModalShow, setDateModalShow] = useState(false);
    // const [schedulePollModal, setSchedulePollModal] = useState(false);
    // const [pollData, setPollData] = useState({});
    // const [ shareAll, setShareAll ] = useState(2);
    // const [ pollTimeLimit, setPollTimeLimit ] = useState("00:30:00");
    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());

    const filterCustomize = (rows, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        const filteredStartDate = globalFilterValue.startDate;
        const filteredEndDate = globalFilterValue.endDate;
        const filteredMode = globalFilterValue.mode;
        const filteredStatus = globalFilterValue.status;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (r.original.courseCode && r.original.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        }).filter(r => {
            if (!filteredStartDate && !filteredEndDate) {
                return true;
            }
            if (!filteredStartDate) {
                return isBefore(r.values.updatedDatePK, filteredEndDate);
            }
            if (!filteredEndDate) {
                return isBefore(filteredStartDate, r.values.updatedDatePK);
            }
            const values = r.values;
            return values.updatedDatePK && isBefore(filteredStartDate, r.values.updatedDatePK) && isBefore(r.values.updatedDatePK, filteredEndDate);
        }).filter(r => {
            if(!filteredMode || filteredMode.length === 0) {
                return true;
            }
            const values = r.values;
            if(filteredMode.includes(MERGED_MODE) && [POLL_TYPE.MERGED_POLL, GRADED_POLL, SURVEY].includes(values.pollType)) {
                return true;
            }

            if(filteredMode.includes(ATTENDANCE) && [POLL_TYPE.ATTENDANCE].includes(values.pollType)) {
                return true;
            }

        }).filter(r => {
            if(!filteredStatus || filteredStatus.length === 0) {
                return true;
            }
            const values = r.values;
            if (filteredStatus.includes(UNFINISHED) && (!values.pollShareMode || values.pollSortKey === DRAFT_STATUS)) {
                return true;
            }
            if (filteredStatus.includes(READY_TO_SHARE) && values.pollShareMode === POLL_SHARE_MODE.UNKNOWN && r.original?.isValid) {
                return true;
            }
            if (filteredStatus.includes(SHARED_BY_QUESTION) && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH && values.pollType !== POLL_TYPE.ATTENDANCE) {
                return true;
            }
            if (filteredStatus.includes(SHARED_ENTIRELY) && (values.pollShareMode === POLL_SHARE_MODE.SHARE_ALL || (values.pollType === POLL_TYPE.ATTENDANCE && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH))) {
                return true;
            }
            if (filteredStatus.includes(SCHEDULED) && values.pollShareMode === POLL_SHARE_MODE.SCHEDULE) {
                return true;
            }
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize},
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);


    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
            return;
        }

        if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }

        for (let i = 0; i < pageSize; i++) {
            if (document.getElementById(`menu-${i}`)) {
                document.getElementById(`menu-${i}`).style.display = "inline-block";
            }
            if (document.getElementById(`options-${i}`)) {
                document.getElementById(`options-${i}`).style.display = "none";
            }

        }
    }, [data, pageIdx, pageOptions]);

    useEffect(() => {
        const container = document.getElementById("manage-poll-page-container");
        setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
    }, [data]);

    const sharePollClearForm = () => {
        setMemberSelectedError("");
        setSelectedMemberOpts([]);
        setSharePollModal(false);
    };

    const sharePoll = async () => {
        let key = removePrefix(pollKey);
        let pollCode = null;
        if(pollUniqueCode !== "Draft") {
            pollCode = pollUniqueCode.substring(7);
        }
        if(selectedMemberOpts.length === 0) {
            setMemberSelectedError("Please select at least 1 member!");
        } else {
            setMemberSelectedError("");
            if(pollCode !== null) {
                await sharePollToUsers({'pollKey': key, 'pollType': pollType, 'pollCode': pollCode, 'users': selectedMemberOpts.map(user => user.value)});
            }
            else {
                await sharePollToUsers({'pollKey': key, 'pollType': pollType, 'users': selectedMemberOpts.map(user => user.value)});
            }
            sharePollClearForm();
        }
    };

    const sharePollModalConfig = {
        title: `Share ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`,
        subText: pollType.toUpperCase() === POLL_TYPE.SURVEY ? SHARE_SURVEY_BODY : SHARE_POLL_BODY,
        submitButtonText: SHARE_TEXT,
        clearForm: sharePollClearForm,
        submit: sharePoll,

        form: [
            {
                label: "Members",
                value: selectedMemberOpts,
                options: userData,
                isMulti: true,
                setFunction: setSelectedMemberOpts,
                type: "select4",
                error: memberSelectedError
            },
        ],
    };
    
    const getData = async () => {
        const session = getSession();
        // let res;
        // if(session.idpType && session.intType) {
        //     res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
        // } else {
        //     res = await getCourseByUser();
        // }
        // const courseOptions = res.filter(course => course.courseRole && course.courseRole !== ROLES.VIEWER.value);
        // setCourseData(courseOptions);

        let res2 = await loadUsers(true);
        res2 = res2.filter(user => user.userId !== session.userId && (user.role === CREATION_ROLES.CREATOR.value || user.role === CREATION_ROLES.IT_MANAGER.value));
        const options = res2.map(user => ({value: user.userId, label: `${user.userName} (${user.email})`}));
        setUserData(options);
        // const res = await getCourseByUser();
        // setCourseData(res);
    };
    
    useEffect(() => {
        setUserSettings(() => {
            const { settings = {} } = getSession();
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
                settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
                settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            }
            settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
                settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
                settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
                settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
            }
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true" || settings[USER_SETTINGS.POLL_REQUIRED_NAME] === true);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
            }
            settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true" || settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === true);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
            }
            settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
            }
            settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true);
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
            }
            settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
            return settings;
        });

        getData();
    }, []);
    
    // useEffect(() => {
    //     const options = courseData.map(course => ({value: course.courseId, label: `${course.courseCode} : ${course.courseName}`}));
    //     setCourseOptions(options);
    // }, [courseData]);

    // const onGo = async (pollKey) => {
    //     setPollKey(pollKey);
    //     setCourseModalShow(true);

    //     // let path = '/poll/' + key + "/" + removePrefix(res.uniqueCode);
    //     // history.push({ pathname: path });
    // }

    // const onGoCopy = async (pollKey, uniqueCode) => {
    //     setPollKey(pollKey);
    //     setPollUniqueCode(uniqueCode);
    //     setCopyPollModal(true);
    // }

    const onGoShare = async (pollKey, uniqueCode) => {
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setSharePollModal(true);
    };

    // const onView = (cell) => {
    //     let pollKey = cell.value;
    //     let uniqueCode = cell.row.original[POLL_SORT_KEY];
    //     // if (uniqueCode === DRAFT_STATUS || cell.row.original.pollShareMode === POLL_SHARE_MODE.UNKNOWN) {
    //     //     notifyError(VIEW_UNSHARED_POLL_ERROR);
    //     //     return;
    //     // }
    //     let key = removePrefix(pollKey);
    //     let pollCode = uniqueCode.substring(7);
    //     history.push(`/poll/published/${key}/${pollCode}`);
    // }
    const onFeedBack = (pollKey, uniqueCode, pollTitle) => {
        let key = removePrefix(pollKey);
        let pollCode = uniqueCode.substring(7);
        history.push({pathname: `/poll/feedback/${key}/${pollCode}`, state: {pollTitle: pollTitle}});
    }

    const onView = (data) => {
        let pk = data[POLL_KEY];
        let sk = data[POLL_SORT_KEY];
        setViewPollModalDataReady(false);
        if (sk !== DRAFT_STATUS) {
            let pollKey = removePrefix(pk);
            let pollCode = sk.substring(sk.lastIndexOf("#") + 1);
            getPublishedPollDataFromKey(pollKey, pollCode).then(res => {
                const {poll, questions=[]} = res;
                const {pollTitle} = poll;
                const obj = {};
                obj.pollTitle = pollTitle;
                obj.questions = questions;
                setViewPollModalData(obj);
            }).finally(() => {
                setViewPollModalDataReady(true);
            });
        } else {
            setViewPollModalData(data);
            setViewPollModalDataReady(true);
        }

        setViewPollModalShow(true);
    }

    const onDeleteDraft = (pollKey, pollType) => {
        // let key = removePrefix(pollKey);
        // console.log("DELETE DRAFT POLL");
        // if (!window.confirm("Are you sure you want to delete it?")) {
        //     return;
        // }
        setPollKey(pollKey);
        setPollType(pollType);
        setShowDeleteModal(true);
        // const res = await softDeleteDraftPoll(key, pollType);
        // if (res.success) {
        //     getPollInformation(pageIndex);
        // }
    }

    const onDeletePublished = (pollKey, uniqueCode, pollType, poll) => {
        // let key = removePrefix(pollKey);
        // console.log("DELETE PUBLISHED POLL");
        // if (!window.confirm("Are you sure you want to delete it?")) {
        //     return;
        // }
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setPollType(pollType);
        const pollState = poll?.pollState;
        const pollShareMode = poll?.pollShareMode;
        const liveQuestionTime = poll?.liveQuestionTime;
        const timeLimit = poll?.pollTimeLimit;
        const questionTimeLimit = poll?.qTimeLimit;
        const start = new Date(poll?.startTime);
        const end = new Date(poll?.endTime);


        const now = new Date();
        if ((pollShareMode === POLL_SHARE_MODE.SCHEDULE && start && start < now && end && now < end) ||
            (pollShareMode === POLL_SHARE_MODE.SHARE_ALL && !!pollType && !!liveQuestionTime &&
                ((pollType === SURVEY && pollState === POLL_STATE.SHARED_ALL) || ((+now) < (liveQuestionTime + (timeLimit * 1000))))
            )) {
            notifyError(DELETE_ACTIVE_POLL_ERROR);
            return;
        } else if (pollShareMode === POLL_SHARE_MODE.SHARE_EACH && !!liveQuestionTime && ((+now) < (liveQuestionTime + (questionTimeLimit * 1000)))) {
            notifyError(DELETE_ACTIVE_POLL_ERROR);
            return;
        } else if (pollType === POLL_TYPE.ATTENDANCE && pollState=== POLL_STATE.SHARED && (end == null || now < end) ) {
            notifyError(DELETE_ACTIVE_POLL_ERROR);
            return;
        }
        setShowDeleteModal(true);
        // let pollCode = uniqueCode.substring(7);
        // const res = await softDeletePublishedPoll(key, pollCode, pollType);
        // if (res.success) {
        //     getPollInformation(pageIndex);
        // }
    }

    const handleSort = (column) => {
        if (column.render('Header') === DATE_HEADER) {
            Datesort();
            setSortSelected(0);
        } else if (column.render('Header') === TITLE_HEADER) {
            Titlesort();
            setSortSelected(1);
        } else if (column.render('Header') === POLL_TYPE_HEADER){
            Modesort();
            setSortSelected(2);
        } else if (column.render('Header') === POLL_STATUS_HEADER) {
            Statussort();
            setSortSelected(3);
        }
    };

    const handleDelete = async () => {
        console.log(pollKey, pollUniqueCode, pollType);
        let key = removePrefix(pollKey);
        if (pollUniqueCode) { // delete published
            console.log("DELETE PUBLISHED POLL");
            let pollCode = pollUniqueCode.substring(7);
            const res = await softDeletePublishedPoll(key, pollCode, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        } else { // delete draft
            console.log("DELETE DRAFT POLL");
            const res = await softDeleteDraftPoll(key, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        }
    };

    const onEdit = (cell) => {
        let pollKey = cell.value;
        console.log("EDIT POLL")
        history.push({
            pathname: `/edit/${removePrefix(pollKey)}/${removePrefixNew(cell.row.original[POLL_SORT_KEY])}`,
            state: {pollType: cell.row.original.pollType}
        });
    }

    // const handleClick = (index) => {
    //     document.getElementById(`menu-${index}`).style.display = "none";
    //     document.getElementById(`options-${index}`).style.display = "inline";
    // }

    // const onPublishedShare = (pollKey, uniqueCode) => {
    //     let key = removePrefix(pollKey);
    //     let pollCode = uniqueCode.substring(7);
    //     history.push(`/poll/share/${key}/${pollCode}`);
    // };

    const deletePollConfig = {
        title: DELETE_ACTIVITY,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        }, 
    };

    const onRelease = async  (cell) => {
        let pollKey, pollCode;
        if (cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS) {
            const pollToAdd = {...cell.row.original};
            pollToAdd.pollKey = removePrefix(pollToAdd.pollKey);
            pollToAdd.pollShareMode = POLL_SHARE_MODE.UNKNOWN;
            if (!pollToAdd.pollTimeLimit) {
                pollToAdd.pollTimeLimit = userSettings[USER_SETTINGS.POLL_DURATION];
            }
            if (!pollToAdd.getReadyAllTimeLimit) {
                pollToAdd.getReadyAllTimeLimit = userSettings[USER_SETTINGS.POLL_COUNTDOWN];
            }
            if (!pollToAdd.lmsAttempt) {
                pollToAdd.lmsAttempt = userSettings[USER_SETTINGS.POLL_LMS_SYNC];
            }
            if (!pollToAdd.recordAttempt) {
                pollToAdd.recordAttempt = userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS];
            }
            if (!pollToAdd.maxAttempts) {
                pollToAdd.maxAttempts = userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS];
            }
            // if (!pollToAdd.anonymousJoin) {
            //     pollToAdd.anonymousJoin = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME];
            // }
            if (!pollToAdd.audienceRestriction) {
                pollToAdd.audienceRestriction = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED;
            }
            if (!pollToAdd.showAnswerOnViewer) {
                pollToAdd.showAnswerOnViewer = userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS];
            }
            // let questions = pollToAdd.questions;
            // for (let i = 0; i < questions.length; i++) {
            //     if (!questions[i].weightage) {
            //         questions[i].weightage = userSettings[USER_SETTINGS.QUESTION_POINTS];
            //     }
            //     if (!questions[i].timeLimit) {
            //         questions[i].timeLimit = userSettings[USER_SETTINGS.QUESTION_DURATION];
            //     }
            //     if (!questions[i].getReadyTimeLimit) {
            //         questions[i].getReadyTimeLimit = userSettings[USER_SETTINGS.QUESTION_COUNTDOWN];
            //     }
            // }
            // pollToAdd.questions = questions;
            const result = await publishPoll(pollToAdd, true);
            if (!result || !result.uniqueCode) {
                notifyError(RELEASE_INVALID_POLL_ERROR);
                return;
            }

            const {draft={}, uniqueCode} = result;

            pollKey = removePrefix(draft.pollKey);
            pollCode = uniqueCode;
        } else {
            pollKey = removePrefix(cell.row.original.pollKey);
            pollCode = removePrefixNew(cell.row.original.pollSortKey);
        }
        history.push({
            pathname: `/poll/share/${pollKey}/${pollCode}`,
            state : {
                pollData: cell.row.original
            }
        });
    };

    // const onSchedule = (cell) => {
    //     setPollData(cell.row.original);
    //     setPollKey(cell.value);
    //     setPollType(cell.row.values.pollType);
    //     setPollTimeLimit(toHHMMSS(userSettings[USER_SETTINGS.POLL_DURATION]));
    //     setSchedulePollModal(true);
    // };

    // const handleSchedule = async () => {
    //     let pollToAdd = {...pollData};
    //     if (!pollToAdd.getReadyAllTimeLimit) {
    //         pollToAdd.getReadyAllTimeLimit = userSettings[USER_SETTINGS.POLL_COUNTDOWN];
    //     }
    //     if (!pollToAdd.lmsAttempt) {
    //         pollToAdd.lmsAttempt = userSettings[USER_SETTINGS.POLL_LMS_SYNC];
    //     }
    //     if (!pollToAdd.recordAttempt) {
    //         pollToAdd.recordAttempt = userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS];
    //     }
    //     if (!pollToAdd.maxAttempts) {
    //         pollToAdd.maxAttempts = userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS];
    //     }
    //     // if (!pollToAdd.anonymousJoin) {
    //     //     pollToAdd.anonymousJoin = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME];
    //     // }
    //     if (!pollToAdd.audienceRestriction) {
    //         pollToAdd.audienceRestriction = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED;
    //     }
    //     if (!pollToAdd.showAnswerOnViewer) {
    //         pollToAdd.showAnswerOnViewer = userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS];
    //     }
    //     pollToAdd.pollKey = removePrefix(pollKey);
    //     pollToAdd.pollType = pollType;
    //     pollToAdd.pollShareMode = POLL_SHARE_MODE.SCHEDULE;
    //     if (startDate) {
    //         pollToAdd.startTime = startDate;
    //     }
    //     if(endDate) {
    //         pollToAdd.endTime = endDate;
    //     }
    //     if (shareAll && pollType === GRADED_POLL) {
    //         pollToAdd.pollTimeLimit = timePollCustomToSeconds(pollTimeLimit);
    //     }
    //     let res = await publishPoll(pollToAdd);
    //     if (res) {
    //         const { uniqueCode } = res;
    //         const trimmedUniqueCode = removePrefix(uniqueCode);
    //         history.push(`/poll/share/${removePrefix(pollKey)}/${trimmedUniqueCode}`);
    //     } else {
    //         notifyError(RELEASE_INVALID_POLL_ERROR);
    //         setSchedulePollModal(false);
    //     }
    // };

    // const scheduleModalConfig = {
    //     title: `Schedule ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`,
    //     submitButtonText: SCHEDULE_TEXT,
    //     clearForm: schedulePollClearForm,
    //     submit: handleSchedule,
    //     activatePollType: pollType,
    //     // subText: SCHEDULE_POLL_LABEL,

    //     form: [
    //         {
    //             label: SCHEDULE_POLL_LABEL,
    //             options: [],
    //             isMulti: false,
    //             type: "select5"
    //         },
    //     ],
    // };

    const onDuplicate = async (cell) => {
        let res;
        if (cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS) {
            res = await copyPollToUser({'pollKey': removePrefix(cell.value), 'pollType': cell.row.values[POLL_TYPE_ACCESSOR]});
        } else {
            res = await copyPollToUser({'pollKey': removePrefix(cell.value), 'pollType': cell.row.values[POLL_TYPE_ACCESSOR], 'pollCode': cell.row.original[POLL_SORT_KEY].substring(7)});
        }
        if (!res) {
            return;
        }
        const pollToAdd = {
            pollKey: removePrefix(res.pollKeys.substring(1, res.pollKeys.length - 1)),
            pollShareMode: POLL_SHARE_MODE.UNKNOWN,
            pollTimeLimit: cell.row.original.pollTimeLimit ? cell.row.original.pollTimeLimit : userSettings[USER_SETTINGS.POLL_DURATION],
        };
        await publishPoll(pollToAdd, true);
        getPollInformation(0);
    }

    const checkDisplayDownArrow = (Header) => {
        if (Header === DATE_HEADER && dateSort === 0 && sortSelected === 0) {
            return true;
        }
        else if (Header === TITLE_HEADER && titleSort === 1  && sortSelected === 1) {
            return true; 
        }
        else if (Header === POLL_TYPE_HEADER && modeSort === 1 && sortSelected === 2) {
            return true;
        }
        else if (Header === POLL_STATUS_HEADER && statusSort === 1 && sortSelected === 3) {
            return true; 
        }
        return false; 
    }

    const checkDisplayUpArrow= (Header) => {
        if (Header === DATE_HEADER && dateSort === 1 && sortSelected === 0) {
            return true;
        }
        else if (Header === TITLE_HEADER && titleSort === -1 && sortSelected === 1) {
            return true; 
        }
        else if (Header === POLL_TYPE_HEADER && modeSort === -1 && sortSelected === 2) {
            return true;
        }
        else if (Header === POLL_STATUS_HEADER && statusSort === -1 && sortSelected === 3) {
            return true; 
        }
        return false; 
    }

    const checkDisplayGreyDownArrow = (Header) => {
        if(Header === DATE_HEADER && dateSort === 0){
            return true; 
        } else if (Header === TITLE_HEADER && titleSort === 1) {
            return true;
        } else if (Header === POLL_TYPE_HEADER && modeSort === 1 ) {
            return true;
        } else if (Header === POLL_STATUS_HEADER && statusSort === 1) {
            return true;
        }
        return false;
    }

    const checkDisplayGreyUpArrow = (Header) => {
        if(Header === DATE_HEADER && dateSort === 1){
            return true; 
        } else if (Header === TITLE_HEADER && titleSort === -1) {
            return true;
        } else if (Header === POLL_TYPE_HEADER && modeSort === -1) {
            return true;
        } else if (Header === POLL_STATUS_HEADER && statusSort === -1) {
            return true;
        }
        return false;
    }

    const displayPollType = (data) => {
        const {pollType} = data;
        if (pollType === POLL_TYPE.ATTENDANCE) {
            return ATTENDANCE;
        } else if (pollType === GRADED_POLL) {
            return GRADED_POLL;
        } else if (pollType === SURVEY) {
            return SURVEY;
        } else {
            return MERGED_MODE;
        }
    }

    return (
        <>
            {/* {dateModalShow && <EditDateModal show={dateModalShow} setModalShow={setDateModalShow} pollKey={pollKey} pollUniqueCode={pollUniqueCode}/>} */}
            {/* {courseModalShow && <FormModal
              show={courseModalShow}
              setModalShow={setCourseModalShow}
              modalConfig={modalConfig}
              pollTimeLimit={pollTimeLimit}
              setPollTimeLimit={setPollTimeLimit}
              setShareAll={setShareAll}
              shareAll={shareAll}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />} */}
            {/* {copyPollModal && <FormModal
              show={copyPollModal}
              setModalShow={setCopyPollModal}
              modalConfig={copyPollModalConfig}
              pollTimeLimit={pollTimeLimit}
              setPollTimeLimit={setPollTimeLimit}
              setShareAll={setShareAll}
              shareAll={shareAll}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />} */}
            {sharePollModal && <FormModal
              show={sharePollModal}
              setModalShow={setSharePollModal}
              modalConfig={sharePollModalConfig}
            />}
            <ViewPollModal
                setModalShow={setViewPollModalShow}
                show={viewPollModalShow}
                pollData={viewPollModalData}
                dataReady={viewPollModalDataReady}
            />
            {/* {schedulePollModal && <FormModal
              show={schedulePollModal}
              setModalShow={setSchedulePollModal}
              modalConfig={scheduleModalConfig}
              pollTimeLimit={pollTimeLimit}
              setPollTimeLimit={setPollTimeLimit}
              setShareAll={setShareAll}
              shareAll={shareAll}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />} */}
            <YuJaGeneralAlert 
                show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={DELETE_POLL_MODAL_BODY}
                config={deletePollConfig}
                AlertType={MODAL_DELETE_TEXT}/>
            {/* <Modal
				id="delete-modal"
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				// dialogClassName="delete-modal"
            >
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setShowDeleteModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0}>
                            {pollType.toUpperCase() === POLL_TYPE.SURVEY ? DELETE_SURVEY : DELETE_POLL}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
                        <div tabIndex={0}>{DELETE_POLL_MODAL_BODY}</div>
					</Modal.Body>
                    <Modal.Footer>
						<button tabIndex={0} className="modal-button-cancel" onClick={() => setShowDeleteModal(false)}>
							{MODAL_CANCEL_TEXT}
						</button>
						<button tabIndex={0}
							className="modal-button-submit"
							onClick={handleDelete}
						>
							{MODAL_DELETE_TEXT}
						</button>
					</Modal.Footer>
				</Spin>
			</Modal> */}
            <div className={styles.tableContainer}>
                <div className={styles.tableWrapper}>
                    <Table hover {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps({
                                            style: {
                                                width: column.width,
                                                minWidth: column.minWidth,
                                                textAlign: column.render('Header') === ACTION_HEADER ? 'center' : 'left'
                                            }
                                        })}>
                                            <span tabIndex={0} role={TEXTBOX} className={column.render('Header') === DATE_HEADER ? styles.dateCell : styles.tableCell}>{column.render('Header')}</span>
                                            {(checkDisplayDownArrow(column.render('Header'))) ?
                                                <SortDown tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={() => { handleSort(column) }} />
                                                : (checkDisplayUpArrow(column.render('Header'))) ?
                                                    <SortUp tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={() => { handleSort(column) }} />
                                                    : (checkDisplayGreyDownArrow(column.render('Header'))) ?
                                                        <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={() => { handleSort(column) }} />
                                                        : (checkDisplayGreyUpArrow(column.render('Header'))) ?
                                                            <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={() => { handleSort(column) }} />
                                                            : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page && page.length !== 0 ? page.map((row, index) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => {
                                            if (cell.column.Header === ACTION_HEADER) {
                                                const isMergedOrAttendance = [POLL_TYPE.MERGED_POLL, POLL_TYPE.ATTENDANCE].includes(cell.row.original.pollType);
                                                return (
                                                    <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                        <div aria-label={ACT_ITEMS + row.cells.at(index-4).value} style={{display: "flex", justifyContent: "center"}}>
                                                            <YuJaKebabMenu >
                                                                <YuJaKebabMenu.Menu>
                                                                    {isMergedOrAttendance && (cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS || cell.row.original.pollShareMode === POLL_SHARE_MODE.UNKNOWN) &&
                                                                        <YuJaKebabMenu.Item onClick={() => onEdit(cell)}>
                                                                            <EditIcon style={{width: 18, marginRight: 5}}/>{EDIT_TEXT}
                                                                        </YuJaKebabMenu.Item>
                                                                    }

                                                                    {isMergedOrAttendance &&
                                                                        <YuJaKebabMenu.Item onClick={() => onRelease(cell)}>
                                                                            <ReleaseIcon style={{width: 18, marginRight: 5}}/>{RELEASE}
                                                                        </YuJaKebabMenu.Item>
                                                                    }

                                                                    {isMergedOrAttendance &&
                                                                        <YuJaKebabMenu.Item
                                                                            onClick={() => {
                                                                                setPollType(cell.row.values.pollType);
                                                                                onGoShare(cell.value, cell.row.original[POLL_SORT_KEY]);
                                                                            }}
                                                                        >
                                                                            <ShareIcon style={{width: 18, marginRight: 5}}/>{SHARE_TEXT}
                                                                        </YuJaKebabMenu.Item>
                                                                    }

                                                                    {/* {isMergedOrAttendance && (cell.row.original[POLL_SORT_KEY] !== DRAFT_STATUS && cell.row.original.pollShareMode !== POLL_SHARE_MODE.UNKNOWN) && cell.row.values.pollType !== POLL_TYPE.ATTENDANCE &&
                                                                        <YuJaKebabMenu.Item onClick={() => onView(cell)}>
                                                                            <ViewResultIcon style={{width: 18, marginRight: 5}}/>{RESULTS}
                                                                        </YuJaKebabMenu.Item>
                                                                    } */}

                                                                    {cell.row.original && cell.row.original.feedbackCount &&
                                                                        <YuJaKebabMenu.Item onClick={() => onFeedBack(cell.value, cell.row.original[POLL_SORT_KEY], cell.row.original[POLL_TITLE])}>
                                                                            <FeedBackIcon style={{width: 18, marginRight: 5}}/>{VIEW_FEEDBACK_TEXT}
                                                                        </YuJaKebabMenu.Item>
                                                                    }
                                                                    {!isMergedOrAttendance &&
                                                                        <YuJaKebabMenu.Item
                                                                            onClick={() => onView(cell.row.original)}
                                                                        >
                                                                            <ViewIcon style={{width: 18, marginRight: 5}}/>{VIEW_TEXT}
                                                                        </YuJaKebabMenu.Item>
                                                                    }

                                                                    <YuJaKebabMenu.Item
                                                                        onClick={() => {
                                                                            cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS ?
                                                                            onDeleteDraft(cell.value, cell.row.values[POLL_TYPE_ACCESSOR]) :
                                                                            onDeletePublished(cell.value, cell.row.original[POLL_SORT_KEY], cell.row.values[POLL_TYPE_ACCESSOR], cell.row.original)
                                                                        }}
                                                                    >
                                                                        <DeleteIcon style={{width: 18, marginRight: 5}}/>{MODAL_DELETE_TEXT}
                                                                    </YuJaKebabMenu.Item>
                                                                    <YuJaKebabMenu.Item onClick={() => onDuplicate(cell)}>
                                                                        <CopyIcon style={{ width: 18, marginRight: 5 }} />{DUPLICATE_TEXT}
                                                                    </YuJaKebabMenu.Item>
                                                                </YuJaKebabMenu.Menu>
                                                            </YuJaKebabMenu>
                                                        </div>
                                                    </td>
                                                );
                                            } else if (cell.column.Header === POLL_STATUS_HEADER) {
                                                return (
                                                    <td role={TEXTBOX} aria-label={POLL_STATUS + row.cells.at(index-3).value + getPollStatus(cell.value, cell.row.original?.isValid)} tabIndex={0} {...cell.getCellProps()}>
                                                        {getPollStatus(cell.value, cell.row.original?.isValid, cell.row.original?.pollType)}
                                                    </td>
                                                );
                                            } else if (cell.column.Header === FEEDBACK_HEADER) {
                                                return (

                                                    // Uncomment below lins to show poll code
                                                    // <td {...cell.getCellProps()}>
                                                    //      {cell.value.startsWith('PUB#UC#') ? cell.value.replace('PUB#UC#', '') : cell.value}
                                                    //  </td>
                                                    <td role={TEXTBOX} aria-label={"Feedback Count for "  + row.cells.at(index-4).value +(!cell.value ? 0 : cell.value)} tabIndex={0} {...cell.getCellProps()}>
                                                       <div style={{fontSize: "13px", textAlign: "left"}}> {!cell.value ? 0 : <div style={{fontWeight: 700}}> {cell.value} </div>} </div>
                                                    </td>

                                                );
                                            } else if (cell.column.Header === TITLE_HEADER) {
                                                return (
                                                    <td tabIndex={0} role={TEXTBOX} aria-label={TITLE + cell.value}
                                                        title={cell.value && cell.value.length > POLL_TITLE_CHARACTER_LIMIT ? cell.value : ""}
                                                        {...cell.getCellProps()}
                                                    >
                                                        <div style={{width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}> {cell.value && cell.value.length > POLL_TITLE_CHARACTER_LIMIT ?
                                                            cell.value.substring(0, POLL_TITLE_CHARACTER_LIMIT) + "..." :
                                                            cell.value ? cell.value : ""
                                                        }</div>
                                                    </td>

                                                );
                                            } else if (cell.column.Header === POLL_TYPE_HEADER) {
                                                return (
                                                    <td tabIndex={0} role={TEXTBOX} aria-label={MODE + cell.value + " for " + row.cells.at(index=1).value} {...cell.getCellProps()}>
                                                        {displayPollType(cell.row.original)}
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td tabIndex={0} role={TEXTBOX} aria-label={cell.column.Header === DATE_HEADER ? (DATE + cell.value): (QUES_LABEL + row.cells.at(index-2).value + " " + cell.value)} {...cell.getCellProps()}>
                                                        <div className={cell.column.render('Header') === DATE_HEADER ? styles.dateCell : styles.tableCell}>
                                                            {cell.value}
                                                        </div>
                                                    </td>
                                                );
                                            }
                                        })}
                                    </tr>
                                )
                            }) : <tr><td colSpan="7" className={styles.noData} style={{boxShadow: "none"}}> <div style={{height: 450, justifyContent: "center", display: "flex", marginTop: "20px", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "18px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div></td></tr>}
                        </tbody>
                    </Table>
                </div>

                {page && page.length !== 0 &&
                    <div className={styles.paginationContainer}>
                        <NormalPagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                            paddingTop="0px"
                        />
                    </div>
                }
            </div>
        </>
    );
}