import React from "react";
// import { Col, Row } from "react-bootstrap";
import { ReactComponent as ICIcon } from "../../images/clickable_purple.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_purple.svg";
import { ReactComponent as MHIcon } from "../../images/matching_purple.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_purple.svg";
import { ReactComponent as SAIcon } from "../../images/sa_purple.svg";
import { ReactComponent as TFIcon } from "../../images/tf_purple.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_purple.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIcon.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEnded.svg";
import { QUESTION_TYPES } from "../../utils/constants";
import "./styles.css";
// import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";

export default function QuestionTypeOption({
  optionData,
  // questionType,
  // index,
  handleQuestionTypeUpdate
}) {
  const {name, description} = optionData;

  return (
    <div
        onClick={() => handleQuestionTypeUpdate(description)}
        style={{display: "flex", alignItems: 'center', width: "100%", cursor: "pointer", gap: 5}}
    >
        {name === QUESTION_TYPES.MCSS.name && <MCSSIcon alt="MCSS Icon" />}
        {name === QUESTION_TYPES.SA.name && <SAIcon alt="SA Icon" />}
        {name === QUESTION_TYPES.TF.name && <TFIcon alt="TF Icon" />}
        {name === QUESTION_TYPES.FITB.name && <FITBIcon alt="FITB Icon" />}
        {name === QUESTION_TYPES.CI.name && <ICIcon alt="CI Icon" />}
        {name === QUESTION_TYPES.WC.name && <WCIcon alt="WC Icon" />}
        {name === QUESTION_TYPES.MH.name && <MHIcon alt="MH Icon" />}
        {name === QUESTION_TYPES.RK.name && <RKIcon alt="RK Icon" />}
        {name === QUESTION_TYPES.OE.name && <OEIcon alt="OE Icon" />}

    <div
        className="question-type-text"
        style={{flex: 1, minWidth: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
    >
      {description}
    </div>
    </div>
  );
}
