import React from "react";

export const LineChartTick = (props) => {
    const { x, y, payload={}, dy, activeLabel="", visibleTicksCount=1, width=0, windowWidth=0 } = props;
    const {value} = payload;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={10}
                  fill={value !== activeLabel ? '#737373' : "#2C2C2C"}
                  style={{fontSize: width < 600 ? 13 : 15}}
                  textAnchor="end"
                  transform={width / visibleTicksCount <= 40 ? "rotate(-35)" : undefined}
            >
                <tspan x="0">{payload.value.split(" ")[0]}</tspan>
                <tspan x="0" dy="20">{payload.value.split(" ")[1]}</tspan>

            </text>
        </g>
    );
}