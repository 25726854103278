import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import {ROLES} from "../../utils/constants";
import Layout from "../../layouts/Layout";
import React from "react";
import CreatorDashboard from "./CreatorDashboard";
import StudentDashboard from "../HomePage/StudentDashboard";
import useMobileAccess from './../../hooks/useMobileAccess';

export default function DashboardPage() {
    const[, getSession] = useLocalStorageNew("session", {});
    const isMobile = useMobileAccess(950);
    const session = getSession();
    const {role = ROLES.ANONYMOUS.value } = session;

    return (
        <>
            {(role === ROLES.VIEWER.value || role === ROLES.ANONYMOUS.value)
                ? <>
                {/* {isMobile &&
                    <Layout pageRoles={[ROLES.VIEWER.value]} flex={true} sideBarCollapseAble={true} hasPin={true}>
                        <StudentDashboardMobile/>
                    </Layout>
                } */}
                {
                    <Layout pageRoles={[ROLES.VIEWER.value]} flex={true} sideBarCollapseAble={false} hasPin={true}>
                        <StudentDashboard />
                    </Layout>
                }
                </>
                :
                <Layout flex={true} sideBarCollapseAble={false} hasPin={true}>
                    <CreatorDashboard/>
                </Layout>
            }
        </>
    )
}