import { React, useEffect, useState } from "react";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { LMS_GRADEBOOK_SYNC, MENU_LIST, POLL_SHARE_MODE, POLL_TYPE, UNGRADED } from "../../utils/constants";
import { hasWeightage } from "../../utils/helpers";
import EnrolledCourses from "./EnrolledCourses";
import styles from "./Home.module.css";
import JoinUsingCode from "./JoinUsingCode";
import StudentActivePolls from "./StudentActivePolls";
import StudentRecentResults from './StudentRecentResults';
import StudentSupportCenter from "./StudentSupportCenter";

export default function StudentDashboard({ setPageIdentifier }) {
  const isMobile = useMobileAccess(950);
  const [activePollsLoading, setActivePollsLoading] = useState(false);
  const [activePollsInitialized, setActivePollsInitialized] = useState(false);
  const [enrolledCourseIsLoading, setEnrolledCoursesIsLoading] = useState(false);
  const [enrolledCourselsInitialized, setEnrolledCourseslsInitialized] = useState(false);
  const [recentResultLoading, setRecentResultLoading] = useState(false);
  const [recentResultInitialized, setRecentResultInitialized] = useState(false);
  const [publishedPolls, setPublishedPolls] = useState([]);
  const [activePolls, setActivePolls] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  
  const { getCourseByStudent } = CourseApiCalls();
  const { getActivePollsFromCourse } = PollApiCalls();
  const { getAudienceResultByUser, getActivePollsByStudent } = ResponseApiCalls();

  const [, getSession] = useLocalStorageNew("session", {});
  const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});
  let institutionId = getHostResource().institutionId;
  const session = getSession();

  const getActivePolls = async (courses) => {
    const promiseArr = [];
    for(const course of courses) {
      const promise = getActivePollsFromCourse(course.value, true);
      promiseArr.push(promise);
    }

    let promise1 = Promise.all(promiseArr).then(data => data.flat());
    let promise2 = getActivePollsByStudent(institutionId, session.userId);
    let [res, takenActivePolls]
        = await Promise.all([promise1, promise2]);

    takenActivePolls.forEach(poll => { if (!res.some((p) => p.pk === poll.pk)) res.push(poll) }); // filter out polls that are in a course
    res.sort((a, b) => new Date(b.index4Sk) - new Date(a.index4Sk)); // sort by index4Sk
    setActivePolls(res);
    setActivePollsLoading(false);
    setActivePollsInitialized(true);

  };

  useEffect(() => {
    setPageIdentifier(MENU_LIST.HOME.name);
    
    const handleWindowResize = () => {
      setPageIdentifier(MENU_LIST.HOME.name);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  const getCoursesOpts = async () => {
    setEnrolledCoursesIsLoading(true);
    setActivePollsLoading(true);
    setRecentResultLoading(true);
    const session = getSession();
    let res;
    if(session.idpType && session.intType) {
        res = await getCourseByStudent(true, session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
        res = await getCourseByStudent(true);
    }

    const options = res.map(course => ({value: course.courseId, courseCode: course.courseCode, courseName: course.courseName}));
    setCourseOptions(options);
    setEnrolledCoursesIsLoading(false);
    setEnrolledCourseslsInitialized(true);
    await Promise.all([getActivePolls(options), getPollData()]);
    setFirstLoad(false);
  };

  const computeScoreNew = (poll, grade) => {
    if (poll.lmsAttempt === LMS_GRADEBOOK_SYNC.RECENT.value) {
      const entries = Object.values(grade);
      const lastEntry = entries[entries.length - 1].score;
      if(checkUnanswered(poll)) {
        return parseInt(parseFloat(lastEntry), 10);
      }
    }
    else if (poll.lmsAttempt === LMS_GRADEBOOK_SYNC.FIRST.value) {
      const entries = Object.values(grade);
      const lastEntry = entries[0].score;
      if(checkUnanswered(poll)) {
        return parseInt(parseFloat(lastEntry), 10);
      }
    }
    else if (poll.lmsAttempt === LMS_GRADEBOOK_SYNC.HIGHEST.value) {
      const entries = Object.values(grade);
      let highest = 0;
      let highest_index = 0 ; 
      for (let i = 0; i< entries.length; i ++) {
        let score = parseInt(parseFloat(entries[i].score), 10); 
        if (score > highest) {
          highest = score;
          highest_index = i; 
        }
      }
      if (checkUnanswered(poll, highest_index)) {
          return highest; 
      }
    }
    return UNGRADED; 
  }


  const checkUnanswered = (poll, index=null) => {
    if (!poll.questions) {
      return true ; 
    }
    for (const result of poll.questions) {
      let questionResponse = result;
      if (questionResponse.attempts && Object.entries(questionResponse.attempts).length > 0 && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.RECENT.value) {
        const entries = Object.values(questionResponse.attempts);
        const lastEntry = entries[entries.length - 1];
        if (lastEntry.isGraded === false) {
          return false; 
        }
      }
      else if (questionResponse.attempts && Object.entries(questionResponse.attempts).length > 0 && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.FIRST.value) {
        const entries = Object.values(questionResponse.attempts);
        const lastEntry = entries[0];
        if (lastEntry.isGraded === false) {
          return false; 
        }
      }
      else if (questionResponse.attempts && Object.entries(questionResponse.attempts).length > 0 && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.HIGHEST.value) {
        const entries = Object.values(questionResponse.attempts);
        const lastEntry = entries[index];
        if (lastEntry.isGraded === false) {
          return false; 
        }
      }
  }
  return true; 
}

  const tallyScore = (poll, grades) => {
    var earnedPoints = 0;
    var totalPoints = 0;
    let pollCode = poll.sk.split("#")[2];
    let grade = grades[pollCode];

    if (poll.pollType === POLL_TYPE.SURVEY || !hasWeightage(poll.questions)) {
      return null;
    }

    if (grade && poll.lmsAttempt) {
        return computeScoreNew(poll, grade); 
    } else {
      for (const result of poll.questions) {
        let questionResponse = result;
        if (!questionResponse.isGraded) {
          return UNGRADED; 
        }
        if (questionResponse.isCorrect) {
          earnedPoints += result.weightage;
        }
        else if (questionResponse.attempts) {
          const entries = Object.values(questionResponse.attempts);
          const lastEntry = entries[0];
          if (lastEntry.isGraded === false) {
            return UNGRADED; 
          }
          else if (lastEntry.isCorrect) {
            earnedPoints += result.weightage;
          }
        }
        totalPoints += result.weightage;
      }
      return Math.round((earnedPoints / totalPoints) * 100);
    }
  };

  const getPollData = async () => {
    let pollList = await getAudienceResultByUser(institutionId, session.userId, true);
    let grade = pollList.grades; 
    pollList = pollList.publishedPolls;
    pollList = pollList.filter(poll => (poll.pollState === "STOPPED_ALL"
      || (poll.pollShareMode !== POLL_SHARE_MODE.SCHEDULE && (poll.liveQuestionTime + (poll.pollTimeLimit * 1000)) < Date.now())
      || (poll.pollShareMode === POLL_SHARE_MODE.SCHEDULE && new Date(poll.endTime).getTime() < Date.now())));
    for(let element of pollList) {
      let score = tallyScore(element, grade);
      element.studentResult = score;
    }
    pollList = pollList.filter(poll => poll.studentResult !== UNGRADED);
    setPublishedPolls(pollList);
    setRecentResultLoading(false);
    setRecentResultInitialized(true);
  };

  useEffect(() => {
    getCoursesOpts();

    const interval = setInterval( () => {
      getCoursesOpts();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(isMobile && document.getElementById("viewer-navbar") !== null) {
      document.getElementById("viewer-navbar").style.backgroundColor = "#f4f4f4";
    }
    if(!isMobile && document.getElementById("viewer-navbar") !== null) {
      document.getElementById("viewer-navbar").style.backgroundColor = "#ffffff";
    }
  }, [isMobile])

  return (
    <>
    {
    !isMobile ?
    <div className={styles.dashBoardContainer}>
      <div style={{display: "flex", flex: 3.6, flexDirection: "column", marginRight: "30px"}}>
        <StudentActivePolls
            initialized={!firstLoad || activePollsInitialized}
            loading={firstLoad && activePollsLoading}
            data={activePolls}
        />
        <EnrolledCourses 
            courseOptions={courseOptions}
            loading={firstLoad && enrolledCourseIsLoading}
            initialized={!firstLoad || enrolledCourselsInitialized}
        />
        <JoinUsingCode/>
      </div>
      <div style={{flex: 4.4}}>
        <StudentRecentResults
            initialized={!firstLoad || recentResultInitialized}
            loading={firstLoad && recentResultLoading}
            data={publishedPolls}
        />
        <StudentSupportCenter/>
      </div>
    </div>
    :
    <div className={styles.dashBoardContainerMobile}>
    <div style={{display: "flex", flexDirection: "column"}}>
      <JoinUsingCode/>
      <StudentActivePolls
          initialized={!firstLoad || activePollsInitialized}
          loading={firstLoad && activePollsLoading}
          data={activePolls}
      />
      <EnrolledCourses 
          courseOptions={courseOptions}
          loading={firstLoad && enrolledCourseIsLoading}
          initialized={!firstLoad || enrolledCourselsInitialized}
      />
      <div style={{marginBottom: "30px"}}></div>
      <StudentRecentResults
          initialized={!firstLoad || recentResultInitialized}
          loading={firstLoad && recentResultLoading}
          data={publishedPolls}
      />
    </div>
  </div>
  }
  </>
  );
}
