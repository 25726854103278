import React, { Component } from "react";
import { ReactComponent as BlueCircle } from "../../images/blue_circle.svg";
import { ReactComponent as FushiaOctagon } from "../../images/fushia_octagon.svg";
import { ReactComponent as GreenDiamond } from "../../images/green_diamond.svg";
import { ReactComponent as NavyPentagon } from "../../images/navy_pentagon.svg";
import { ReactComponent as OrangeSquare } from "../../images/orange_square.svg";
import { ReactComponent as PinkTriangle } from "../../images/pink_triangle.svg";
import { ReactComponent as PurpleTrapezoid } from "../../images/purple_trapezoid.svg";
import { ReactComponent as RedRectangle } from "../../images/red_rectangle.svg";
import { ReactComponent as TealHexagon } from "../../images/teal_hexagon.svg";
import "../styles.css";
import "../../components/radio/radio.css";

export class PremiseRadio extends Component {
  state = {};
  Pin
  render() {
    let accessibilityIcons = [
      {
        src: <PinkTriangle style={{ width : "3.5vw"}}/>
      },
      {
        src: <OrangeSquare style={{ width : "3.5vw"}}/>
      },
      {
        src: <GreenDiamond style={{ width : "3.5vw"}}/>
      },
      {
        src: <BlueCircle style={{ width : "3.5vw"}}/>
      },
      {
        src: <NavyPentagon style={{ width : "3.5vw"}}/>
      },
      {
        src: <RedRectangle style={{ width : "3.5vw"}}/>
      },
      {
        src: <FushiaOctagon style={{ width : "3.5vw"}}/>
      },
      {
        src: <PurpleTrapezoid style={{ width : "3.5vw"}}/>
      },
      {
        src: <TealHexagon style={{ width : "3.5vw"}}/>
      }
    ];
    const {index} = this.props;
    return (
      <div
        className="modern-radio-container"
      >
        {/* <div className="accessibilityIcon" id={`icon-${index}`}>
          {index !== undefined ? accessibilityIcons[index % 9].src : ""}
        </div> */}
      </div>
    );
  }
}