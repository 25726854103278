
import { BUTTON } from "../../utils/constants";
import styles from "./Button.module.css";
export default function GradientButton({style={}, onClick, children, label=""}) {
    return (
        <div tabIndex={0} aria-label={label} role={BUTTON} className={styles.gradientWrap} style={style} onClick={onClick}>
            <span className={styles.gradientBtn}>
                {children}
            </span>
        </div>

    );
}