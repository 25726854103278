// import "font-awesome/css/font-awesome.min.css";
import * as L from 'leaflet';
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton/src/easy-button.js";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import AddButton from "../../components/buttons/AddButton";
import { YuJaCheckbox } from "../../components/standardization/YuJaCheckbox";
import { YuJaDropdown } from '../../components/standardization/YuJaDropdown';
import { YuJaTextBox } from '../../components/standardization/YuJaTextBox';
import { usePPTWebView } from "../../hooks/usePPTWebView";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import locateMe from "../../images/locateMe.svg";
import {
  ATTENDANCE_MAP_TEXT,
  DEFAULT_GEOFENCE_LAT,
  DEFAULT_GEOFENCE_LNG,
  DEFAULT_GEOFENCE_RAD,
  GEOFENCE_CHECKBOX_LABEL,
  IFRAME_PERMISSION_ERROR_MESSAGE,
  INSTRUCTOR_LOCATION_CONFIG,
  LMS_SYNC_CHECKBOX_LABEL,
  LOCATION_ACCURACY_THRESHOLD,
  LOCATION_MESSAGE,
  MAX_RADIUS,
  MIN_RADIUS,
  POLL_TYPE,
  RADIUS_LABEL,
  RADIUS_UNIT, SAVED_LOCATION_INSTRUCTION,
  SAVED_LOCATIONS_DROPDOWN_PLACEHOLDER,
  SAVED_LOCATIONS_LABEL,
  USER_SETTINGS
} from "../../utils/constants";
import { fillSlider, notifyError, notifyWarning } from '../../utils/helpers';
import { updateCorrectAnswers } from "../../utils/questionUpdate";
import { INVALID_RADIUS_ERROR } from '../../utils/toast-message-constants';
import "../styles.css";
import SAQuestionOption from "./SAQuestionOption";
import {InstitutionApiCalls} from "../../apis/InstitutionApiCalls";


export default function SAQuestion({
                                     geofence, setGeofence,
                                     syncLms, setSyncLms,
                                     questions, setQuestions,
                                     mapInitialized, setMapInitialized,
                                     selectedQuestion,
                                     pollType,
                                     questionBuilder = false,
                                     mapView = false,
                                     editable = true,
                                     userSettings={}
                                   }) {
  const {getGeoLocation} = usePPTWebView();
  const {getSetting} = InstitutionApiCalls();

  const { correctAnswers=[], serialNo=1 } = selectedQuestion;
  const indexToChange = serialNo - 1;
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const circleRef = useRef(null);
  // const [locationText, setLocationText] = useState("");
  const [customRadius, setCustomRadius] = useState(DEFAULT_GEOFENCE_RAD);
  const [initialized, setInitialized] = useState(false);
  const [savedLocations, setSavedLocations] = useState([]);
  const [platformSettings, setPlatformSettings] = useState({});
  // const [toastShown, setToastShown] = useState(false);

  const handleAddAnswer = () => {
    setQuestions(
      updateCorrectAnswers([...correctAnswers, ""], indexToChange, questions)
    );
  };

  const handleDeleteAnswer = (indexToUpdate) => {
    correctAnswers.splice(indexToUpdate, 1);
    setQuestions(
      updateCorrectAnswers([...correctAnswers], indexToChange, questions)
    );
  };

  const handleUpdateAnswer = (indexToUpdate, text) => {
    correctAnswers[indexToUpdate] = text;
    console.log(text, correctAnswers);
    setQuestions(
      updateCorrectAnswers([...correctAnswers], indexToChange, questions)
    );
  };

  const handleAnswerOrderChange = (result) => {
    if (!result.destination) return;

    const items = Array.from(correctAnswers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestions(
      updateCorrectAnswers([...items], indexToChange, questions)
    );
  };


  useEffect(() => {
    if (pollType !== POLL_TYPE.ATTENDANCE) {
      return;
    }
    getSetting().then((result) => {
      if (result !== null) {
        setPlatformSettings(result);
      }
    })

  }, [pollType]);

  useEffect(() => {
    let locations = [];

    let userSavedLocations = userSettings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS)
        ? JSON.parse(userSettings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS])
        : [];
    let platformSavedLocations = platformSettings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS)
        ? JSON.parse(platformSettings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS])
        : [];
    locations.push(...platformSavedLocations);
    locations.push(...userSavedLocations);
    setSavedLocations(locations);
  }, [userSettings, platformSettings]);

  useEffect(() => {
    if (initialized && !geofence?.rad) {
      setInitialized(false);
    }
    if (initialized || !pollType || pollType !== POLL_TYPE.ATTENDANCE || !geofence?.rad) return;
    setCustomRadius(geofence.rad);
    setInitialized(true);
  }, [geofence, pollType]);

  useEffect(() => {
    if (!initialized || !geofence || JSON.stringify(geofence) === "{}" || questionBuilder || mapInitialized) return;
    console.log("initializing map");
    const {lat, lng, rad} = geofence;
    // console.log(lat, lng);
    // if (mapRef != undefined) {
    //     lat = mapRef.getCenter().lat;
    //     lng = mapRef.getCenter().lng;
    //     mapRef.remove();
    //     map.invalidateSize();
    // }
    // console.log(lat, lng);

    // console.log(document.getElementById('map'));
    // document.getElementById('map').innerHTML = `<div id='map' style='width: ${mapView ? 400 : "100%"}; height: ${mapView ? 350 : 400};'></div>`;
    var map = L.map('map').setView([lat, lng], 2);
    mapRef.current = map;
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 2,
      maxNativeZoom: 19,
      maxZoom: 25,
      // attribution: '&copy <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);
    map.attributionControl.setPrefix(false);

    const defaultMarker = new L.icon({
        iconUrl: require('../../images/geofence_center.png'),
        // shadowUrl: require('../../../node_modules/leaflet/dist/images/marker-shadow.png'),
        iconSize:     [64, 64],
        // shadowSize:   [30, 65],
        iconAnchor:   [32, 64],
        // shadowAnchor: [7, 65]
    });

    var marker = L.marker([geofence.lat, geofence.lng], {icon: defaultMarker}).addTo(map);
    markerRef.current = marker;
    // marker.bindPopup("You are here").openPopup();

    var circle = L.circle([geofence.lat, geofence.lng], {
      color: '#42296E',
      fillColor: '#42296E',
      fillOpacity: 0.3,
      radius: rad
    }).addTo(map);
    // circle.bindPopup("You are within " + radius + " meters from this point");
    if (lat !== DEFAULT_GEOFENCE_LAT || lng !== DEFAULT_GEOFENCE_LNG || rad !== DEFAULT_GEOFENCE_RAD) { // auto zoom if geofence is not {0, 0, 0}
      map.fitBounds(circle.getBounds());
    }
    circleRef.current = circle;

    // var myLocation = 
    L.easyButton({
      id: 'leaflet-map-locate-me',
      leafletClasses: false,
      states: [{
        stateName: 'locate-me',
        icon: `<img src=${locateMe} width="30px" height="30px" alt="locate me">`, onClick: () => {
          const location = getGeoLocation();
          if (!!location) {
            const {lat, lng} = location;
            setGeofence({
              // lat: e.latlng.lat,
              // lng: e.latlng.lng,
              lat: lat,
              lng: lng,
              rad: rad
            });
            // marker.setLatLng(e.latlng);
            // circle.setLatLng(e.latlng);
            const latlng = L.latLng(lat, lng, 0);
            marker.setLatLng(latlng);
            circle.setLatLng(latlng);
            // map.flyTo(e.latlng, map.getZoom());
            map.fitBounds(circle.getBounds());
            return;
          }

          // map.locate(INSTRUCTOR_LOCATION_CONFIG).on('locationfound', function (e) {
          navigator.geolocation.getCurrentPosition((position) => {
            if (!editable) return;
            const coordinates = position?.coords;
            console.log(coordinates);
            if (coordinates.accuracy > LOCATION_ACCURACY_THRESHOLD) {
              notifyWarning(LOCATION_MESSAGE.LOWER_ACCURACY);
            }

            setGeofence({
              // lat: e.latlng.lat,
              // lng: e.latlng.lng,
              lat: coordinates.latitude,
              lng: coordinates.longitude,
              rad: rad
            });
            // marker.setLatLng(e.latlng);
            // circle.setLatLng(e.latlng);
            const latlng = L.latLng(coordinates.latitude, coordinates.longitude, 0);
            marker.setLatLng(latlng);
            circle.setLatLng(latlng);
            // map.flyTo(e.latlng, map.getZoom());
            map.fitBounds(circle.getBounds());
          // }).on('locationerror', function (e) {
          }, (e) => {
            console.log(e);
            if (e.message === IFRAME_PERMISSION_ERROR_MESSAGE) {
              notifyError(LOCATION_MESSAGE.IFRAME_PERMISSION_ERROR);
              return;
            }
            // if (toastShown) return;
            // console.log("Toast shown: ", toastShown);
            switch (e.code) {
              case 1: // PERMISSION_DENIED
                notifyError(e.message === IFRAME_PERMISSION_ERROR_MESSAGE ? LOCATION_MESSAGE.IFRAME_PERMISSION_ERROR : LOCATION_MESSAGE.PERMISSION_DENIED);
                break;
              // case 2: // POSITION_UNAVAILABLE
              // case 3: // TIMEOUT
              default:
                notifyError(LOCATION_MESSAGE.UNKNOWN_ERROR);
                break;
            }
            // setToastShown(true);
            // map.stopLocate();
          // });
          }, INSTRUCTOR_LOCATION_CONFIG);

          // return () => {
          //   map.stopLocate();
          // };
        }
      }]
    }).addTo(map);
    // console.log(myLocation);

    // var sidebar = L.control.sidebar('sidebar', {
    //   position: 'left',
    //   closeButton: true,
    //   // container: 'sidebar',
    //   // autopan: false
    // });
    // sidebar.addTo(map);
    // map.addControl(sidebar);

    // L.easyButton({
    //   id: 'leaflet-map-saved-locations',
    //   leafletClasses: false,
    //   states: [{
    //     stateName: 'saved-locations',
    //     icon: `<img src=${savedLoc} width="30px" height="30px" alt="saved locations">`, onClick: () => {
    //       if (sidebar.isVisible()) {
    //         sidebar.hide();
    //         // sidebar.close();
    //       } else {
    //         sidebar.show();
    //         // sidebar.open();
    //       }
    //     }
    //   }]
    // }).addTo(map);

    map.on('click', function (e) {
      if (!editable) return;
      // console.log(e);
      // console.log("You clicked the map at " + e.latlng);
      setGeofence({
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        rad: rad
      });
      marker.setLatLng(e.latlng);
      circle.setLatLng(e.latlng);
      map.fitBounds(circle.getBounds());
    });

    setMapInitialized(true);
    return () => {
      console.log("cleaning up");
      map.remove();
      // setMapInitialized(false);
      // map.invalidateSize();
    }
  }, [initialized, editable]);

  useEffect(() => {
    // console.log(initialized, mapInitialized, editable);
    if (!initialized || !mapInitialized) return;
    const element = document.getElementById('leaflet-map-locate-me');
    // console.log(element);
    if (!element) {
      return;
    }
    if (editable) {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  }, [initialized, mapInitialized, editable]);

  useEffect(() => {
    const slider = document.querySelector('.radius-slider');
    if (!slider) return;
    
    fillSlider(slider);
  }, [customRadius, geofence]);

  return (
    <DragDropContext onDragEnd={handleAnswerOrderChange}>
      <Droppable droppableId="droppable-short-answer-question">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dropHandleProps}
            className={pollType === POLL_TYPE.ATTENDANCE ? "question-main-container" : "container question-main-container"}
          >
            {!!pollType && pollType.toUpperCase() !== POLL_TYPE.SURVEY && !mapView && correctAnswers.map((answer, index) => {
              return (
                <div key={index}>
                  <SAQuestionOption
                    answer={answer}
                    selectedQuestion={selectedQuestion}
                    handleDeleteAnswer={handleDeleteAnswer}
                    handleUpdateAnswer={handleUpdateAnswer}
                    index={index}
                    pollType={pollType}
                    editable={editable}
                    // questionBuilder={questionBuilder}
                    // mapView={mapView}
                  />
                </div>
              );
            })}

            {(pollType !== POLL_TYPE.ATTENDANCE && !snapshot.isDraggingOver) ?
              <Row style={!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY ? { display: "none" } : {}}>
                <Col className="drag-icon-column margin-vertical-auto"></Col>
                <Col xs={9} className="margin-vertical-auto">
                  <div
                    style={{
                      height: "2.498rem",
                      marginBottom: "1.444rem",
                    }}
                  >
                    <AddButton text="Add Option" onClick={handleAddAnswer} />
                  </div>
                </Col>
                <Col className="margin-vertical-auto"></Col>
              </Row>
              : null}

            {pollType === POLL_TYPE.ATTENDANCE && <div style={{display: "flex"}}>
              <link
                rel="stylesheet"
                href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
                integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
                crossOrigin=""
              />
              <script
                src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
                integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
                crossOrigin=""
              />
              {!mapView &&
                  <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
                    <YuJaCheckbox
                      disabled={!editable}
                      style={{ marginBottom: "0.5rem" }}
                      label={LMS_SYNC_CHECKBOX_LABEL}
                      checked={syncLms}
                      onClick={(e) => setSyncLms(e.target.checked)}
                    />
                    <YuJaCheckbox
                      disabled={!editable}
                      label={GEOFENCE_CHECKBOX_LABEL}
                      checked={JSON.stringify(geofence) !== "{}"}
                      onClick={(e) => {
                        if (!e.target.checked) {
                          setMapInitialized(false);
                          // setLocationText("");
                        }
                        setGeofence(e.target.checked ? {
                          lat: DEFAULT_GEOFENCE_LAT,
                          lng: DEFAULT_GEOFENCE_LNG,
                          rad: DEFAULT_GEOFENCE_RAD
                        } : {});
                      }}
                    />

                    {!!Object.entries(geofence).length && !savedLocations.length &&
                        <div style={{fontSize: 14, color: "rgb(83, 83, 83)", fontStyle: "italic"}}>
                          {SAVED_LOCATION_INSTRUCTION}
                        </div>
                    }
                  </div>
              }


              <div style={{ display: questionBuilder || JSON.stringify(geofence) === "{}" ? "none" : "block", padding: mapView ? 0 : "0px 12px", flex: 1 }}>
                {/* <YuJaTextBox
                  placeholder={LOCATION_SEARCH_PLACEHOLDER}
                  value={locationText}
                  disabled={!editable}
                  containerStyle={{ width: mapView ? 400 : "100%", marginBottom: 10 }}
                  onChange={(e) => setLocationText(e.target.value)}
                  onBlur={() => {
                    fetch(fillFormatText(LOCATION_API_URL, { ACCESS_TOKEN: LOCATION_API_ACCESS_TOKEN, SEARCH_STRING: locationText }))
                      .then((response) => {
                        return response.json();
                      })
                      .then((data) => {
                        console.log(data);
                        const latlng = [data[0].lat, data[0].lon];
                        const rad = getRadiusFromBoundingBox(data[0].boundingbox);
                        setGeofence({
                          lat: parseFloat(latlng[0].trim()),
                          lng: parseFloat(latlng[1].trim()),
                          rad: rad
                        });
                        setCustomRadius(rad);
                        circleRef.current.setLatLng([parseFloat(latlng[0].trim()), parseFloat(latlng[1].trim())]);
                        circleRef.current.setRadius(rad);
                        mapRef.current.fitBounds(circleRef.current.getBounds());
                      })
                      .catch((error) => {
                        console.error(error);
                        notifyError(error.message);
                      });
                    // sdk.auth('pk.6b62bb0a084be9fbfd761b0629bfda16');
                    // sdk.search({ q: locationText.replace(" ", "%20") })
                    //   .then(({ data }) => {
                    //     console.log(data)
                    //   })
                    //   .catch(err => console.error(err));

                    // let latlng = [];
                    // geocode(RequestType.ADDRESS, locationText).then(({ results }) => {
                    //   console.log(results);
                    //   latlng = [results[0].geometry.location.lat(), results[0].geometry.location.lng()];
                    //   setGeofence({
                    //     lat: parseFloat(latlng[0].trim()),
                    //     lng: parseFloat(latlng[1].trim()),
                    //     rad: geofence.rad
                    //   });
                    //   circleRef.current.setLatLng([parseFloat(latlng[0].trim()), parseFloat(latlng[1].trim())]);
                    //   mapRef.current.fitBounds(circleRef.current.getBounds());
                    // }).catch((error) => {
                    //   console.error(error);
                    // });
                  }}
                /> */}
                {editable &&
                  <div className="saved-locations-row">
                    <div className="saved-locations-label">{SAVED_LOCATIONS_LABEL}</div>
                    <YuJaDropdown
                      data={savedLocations}
                      disabled={savedLocations.length === 0}
                      placeholder={SAVED_LOCATIONS_DROPDOWN_PLACEHOLDER}
                      value={savedLocations.find(location => location.lat === geofence.lat && location.lng === geofence.lng) ? `${geofence.lat}-${geofence.lng}` : null}
                      getOptionValue={(option) => `${option.lat}-${option.lng}`}
                      getOptionLabel={(option) => option.name}
                      onChange={(location) => {
                        setGeofence({
                          lat: location.lat,
                          lng: location.lng,
                          rad: location.rad
                        });
                        setCustomRadius(location.rad);
                        markerRef.current.setLatLng([location.lat, location.lng]);
                        circleRef.current.setLatLng([location.lat, location.lng]);
                        circleRef.current.setRadius(location.rad);
                        mapRef.current.fitBounds(circleRef.current.getBounds());
                      }}
                      containerStyle={{ flex: 1 }}
                    />
                  </div>
                }
                <div id="map" style={{ width: mapView ? 350 : "100%", height: mapView ? 350 : 400 }}>
                  {/* <div id="sidebar">
                    <div className="sidebar-content">
                      {savedLocations.length === 0 && <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "1rem" }}>
                        <InfoIcon style={{ minWidth: 24, minHeight: 24, marginRight: 5 }} />
                        {NO_SAVED_LOCATIONS_SIDEBAR}
                      </div>}
                      {savedLocations.map((location, index) => {
                        console.log(location, index);
                        return (
                          <div key={index} className="sidebar-item" onClick={e => {
                            e.stopPropagation();
                            setGeofence({
                              lat: location.lat,
                              lng: location.lng,
                              rad: location.rad
                            });
                            setCustomRadius(location.rad);
                            markerRef.current.setLatLng([location.lat, location.lng]);
                            circleRef.current.setLatLng([location.lat, location.lng]);
                            circleRef.current.setRadius(location.rad);
                            mapRef.current.fitBounds(circleRef.current.getBounds());
                            // sidebar.hide();
                          }}>
                            {location.name}
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
                <div className="radius-row" style={{ width: "100%", overflowY: "hidden" }}>
                  {editable ? <>
                    <label htmlFor="radius" className="radius-label">{RADIUS_LABEL}</label>
                    <input
                      type="range"
                      min={10}
                      max={10000}
                      value={customRadius}
                      // onLoad={(e) => fillSlider(e.target)}
                      onChange={(e) => {
                        setCustomRadius(e.target.value);
                        setGeofence({ ...geofence, rad: parseInt(e.target.value, 10) });
                        circleRef.current.setRadius(e.target.value);
                        mapRef.current.fitBounds(circleRef.current.getBounds());
                      }}
                      // onBlur={() => {
                      //   if (geofence.rad == customRadius) return;
                      //   notifyError(INVALID_RADIUS_ERROR);
                      //   setCustomRadius(geofence.rad);
                      // }}
                      className="radius-slider"
                    />
                    <YuJaTextBox
                      placeholder={geofence?.rad}
                      value={customRadius}
                      onChange={(e) => {
                        const r = e.target.value.replace(/[^0-9]/g, '');
                        setCustomRadius(r);
                        const rad = parseInt(r, 10);
                        if (isNaN(rad) || rad < MIN_RADIUS || rad > MAX_RADIUS) return;
                        circleRef.current.setRadius(r);
                        mapRef.current.fitBounds(circleRef.current.getBounds());
                        setGeofence({ ...geofence, rad: parseInt(r, 10) });
                      }}
                      onBlur={() => {
                        if (geofence.rad == customRadius) return;
                        console.log(customRadius, geofence.rad, geofence.rad == customRadius);
                        notifyError(INVALID_RADIUS_ERROR);
                        setCustomRadius(geofence.rad);
                      }}
                      inputAlign={"center"}
                      containerStyle={{ width: 50, marginRight: 5, fontSize: 13, padding: 6 }}
                    />
                    <span className="radius-unit"> {RADIUS_UNIT}</span>
                  </> : <>
                    <span className="radius-label" style={{ marginRight: 5 }}>{RADIUS_LABEL}</span>
                    <span className="radius-value">{geofence?.rad}</span>
                    <span className="radius-unit"> {RADIUS_UNIT}</span>
                  </>}
                </div>
              </div>
            </div>}

            {questionBuilder && editable && JSON.stringify(geofence) !== "{}" &&
              <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "1rem" }}>
                <InfoIcon style={{ minWidth: 24, minHeight: 24, marginRight: 5 }} />
                {ATTENDANCE_MAP_TEXT}
              </div>
            }

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
