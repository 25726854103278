import React from "react";
import { YuJaTextBox } from "../components/standardization/YuJaTextBox";
import { CREATE_QUES_LABEL, QUESTION_LENGTH } from "../utils/constants";
import useMobileAccess from "../hooks/useMobileAccess";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {YuJaRichContentEditBox} from "../components/standardization/YuJaRichContentEditBox";

export default function BaseQuestionTitle({
  questionTitle="",
  handleTitleUpdate,
  placeholder,
  editable,
  dataloading=false,
}) {
  const isNarrow = useMobileAccess(1100);

  return (
    <>
      {editable && !dataloading ? (
          // <YuJaTextBox
          //     aria-label={CREATE_QUES_LABEL}
          //     name={"question-text"}
          //     containerStyle={{
          //       color: "#006DC7",
          //       fontSize: isNarrow ? "18px" : "20px",
          //       borderRadius: "4.519px",
          //       border: "1.13px solid #858687",
          //       padding: isNarrow ? "8px 10px" : "13.558px 20.337px"
          //     }}
          //     placeholder= {placeholder}
          //     value={questionTitle}
          //     maxLength={QUESTION_LENGTH}
          //     onChange={handleTitleUpdate}
          //     inputClass={"PollTitleInput"}
          //     showRemaining={true}
          // />

          <YuJaRichContentEditBox
              placeholder={placeholder}
              setValue={handleTitleUpdate}
              value={questionTitle}
          />

        ) : dataloading ? 
        (
          <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                  <Skeleton height={49} borderRadius={10} style={{ position: "relative", marginBottom: 22.6}} />
                </SkeletonTheme>
        )
        : (
          <div className="text-container-without-counter">
            <span className="poll-sub-text focus-outline-none cursor-default" style={{fontSize: "18px"}}>{placeholder}</span>
          </div>
        )
      }
  </>
  );
}
