import React, { useEffect } from "react";
import useMobileAccess from "../../hooks/useMobileAccess";
import { CLOSED_GRADED_POLL, REATTEMPT_CLOSED_POLL, SUPPORT_PREFIX, TEXTBOX, YUJA_ENGAGE_SUPPORT } from "../../utils/constants";
import { newPageLog } from "../../utils/helpers";
import NetworkStatus from "./NetworkStatus";
import { ReactComponent as PollEndedIcon } from "./images/poll_ended_new.svg";
import styles from "./styles.module.css";

export default function EndedPollNew({pollTitle, websocketState}) {

    const isNarrow = useMobileAccess(1200); 

    useEffect(() => {
      newPageLog("EndedPollNew.js");
    }, []);

    return (
        <div className={styles.endedPollBackground} style={{justifyContent: "normal"}}>
            <div className="waiting-room-title-container" style={{height: "fit-content" , width: "calc(100% - 36px)", marginBottom: "50px", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}> 
            <span tabIndex={0} role={TEXTBOX} className="waiting-room-title" style={{wordBreak:"break-word", paddingLeft: !isNarrow? "170px" : "10px", textAlign: "center", flex: "1"}}>{pollTitle}</span>
            <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState}/> </div>
            </div>
            <PollEndedIcon className={styles.endedPollIcon}/>
            <p className={styles.endedPolllSubtitle}>{CLOSED_GRADED_POLL}</p>
            <p className={styles.endedPolllSubtext}>{REATTEMPT_CLOSED_POLL}</p>
            <p className={styles.endedPollText}>{SUPPORT_PREFIX} <a href="https://support.yuja.com/hc/en-us" target="_blank">{YUJA_ENGAGE_SUPPORT}</a></p>
        </div>
    );
}
